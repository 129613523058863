import { axiosService } from 'services/axiosServices';


const contractQuestionsHeaders = {
  "Content-type": 'application/json; charset=UTF-8',
  Accept: "application/json",
};

export const contracts = {
  getAllContracts: async (currentPage = 1) => {
    const res = await axiosService.get(`/api/contracts?page=${currentPage}`);
    return res.data;
  },
  getContractsByStatus: async (contractsStatus, currentPage = 1) => {
    const res = await axiosService.get(`/api/filter/contracts?status=${contractsStatus}&page=${currentPage}`);
    return res.data;
  },
  getContractFilters: async () => {
    const res = await axiosService.get('/api/filter/contracts?is_staffing_filterable=true');
    return res.data.data;
  },
  getContractTemplates: async (contractId) => {
    const res = await axiosService.get(`/api/email_templates/filter/${contractId}`);
    return res.data.data;
  },
  editContractTemplate: async (templateId, body) => {
    // Adding PUT method param is a Laravel workaround to handle form data
    // https://stackoverflow.com/questions/50691938/patch-and-put-request-does-not-working-with-form-data
    const res = await axiosService.post(`/api/email_templates/${templateId}?_method=PUT`, body);
    return res.data;
  },
  getContractById: async (contractId) => {
    const res = await axiosService.get(`/api/contracts/${contractId}`);
    return res.data.data;
  },
  getCustomerContracts: async (vendorId) => {
    const res = await axiosService.get(`/api/filter/contracts?vendor_id=${vendorId}`);
    return res.data.data;
  },
  getAllContractMetrics: async () => {
    const res = await axiosService.get(`/api/metrics/all_contract_metrics`);
    return res.data;
  },
  getContractMetrics: async (contractId) => {
    const res = await axiosService.get(`/api/metrics/contract_metrics/${contractId}`);
    return res.data;
  },
  sendEmailTemplate: async (user_id, template_id) => {
    const res = await axiosService.post('/api/send_email_template/',
      { user_id, template_id });
    return res.data;
  },
  deleteContractById: async (contractId) => {
    const res = await axiosService.delete(`/api/contracts/${contractId}`);
    return res.data;
  },
  editContractById: async (contractId, body) => {
    const res = await axiosService.put(`/api/contracts/${contractId}`, body);
    return res.data;
  },
  createContract: async (_, body) => {
    const res = await axiosService.post(`/api/contracts/`, body);
    return res.data;
  },
  getContractQuestions: async (contractId) => {
    const res = await axiosService.get(`/api/filter/application-questions?page=1&sort[column]=sort_order&sort[direction]=asc&contract_id=${contractId}`);
    return res.data.data;
  },
  createContractQuestion: async (body) => {
    const res = await axiosService.post(`/api/application-questions`, body, { headers: contractQuestionsHeaders });
    return res.data;
  },
  updateContractQuestion: async (questionId, body) => {
    const res = await axiosService.put(`/api/application-questions/${questionId}`, body, { headers: contractQuestionsHeaders });
    return res.data;
  },
  deleteContractQuestion: async (questionId) => {
    const res = await axiosService.delete(`/api/application-questions/${questionId}`);
    return res.data;
  },
  updateContractQuestionsOrder: async (body) => {
    const res = await axiosService.patch(`/api/application-questions/mass-sorting`, body);
    return res.data;
  },
}
