import React, { useEffect } from 'react';

import 'css/contractsTags.scss';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { filterTags, getTagGroups, getTags, getTagTypes } from 'store/tags/thunk';
import ContractsTagsTable from 'components/contracts/contracts-tags/contracts-tags-table';
import {
  selectedGroupSelector,
  selectedTypeSelector,
  tagGroupsSelector,
  tagTypesSelector,
} from 'store/tags/selectors';
import { setSelectedGroup, setSelectedType } from 'store/tags/slice';

export const ContractTags = () => {
  const dispatch = useAppDispatch();
  const groups = useAppSelector(tagGroupsSelector);
  const types = useAppSelector(tagTypesSelector);
  const typeId = useAppSelector(selectedTypeSelector);
  const groupId = useAppSelector(selectedGroupSelector);
  
  useEffect(() => {
    dispatch(getTagGroups());
    dispatch(getTagTypes());
  }, []);
  
  useEffect(() => {
    if (groupId || typeId) {
      dispatch(filterTags({ groupId, typeId }));
    } else {
      dispatch(getTags());
    }
  }, [groupId, typeId]);

  
  return (
    <div className="contractsTags__content">
      <div className="contractsTags__tags">
        <div className="header" style={{ marginBottom: '15px', position: 'relative' }}>
          <h2>All Tags</h2>
          <div className="contractsTags__dropdown">
            <select
              id="filter-status"
              className="contractsTags__dropdown--input"
              defaultValue={''}
              value={String(typeId) || ''}
              onChange={(e) => {
                dispatch(setSelectedType(e.target.value))
              }}
            >
              <option
                onChange={() => setSelectedType(null)}
                key={'null'}
                value=""
              >
                All Types
              </option>
              {types.map((item) => (
                <option key={item.id} value={item.id}>{item.title}</option>
              ))}
            </select>
          </div>
          <div className="contractsTags__dropdown contractsTags__dropdown-group">
            <select
              id="filter-status"
              className="contractsTags__dropdown--input"
              value={String(groupId) || ''}
              defaultValue={''}
              onChange={(e) => {
                dispatch(setSelectedGroup(e.target.value))
              }}
            >
              <option
                onChange={() => setSelectedGroup(null)}
                key={'null'}
                value=""
              >
                All Groups
              </option>
              {groups.map((item) => (
                <option key={item.id} value={item.id}>{item.title}</option>
              ))}
            </select>
          </div>
        </div>
        <div
          className="contractsTags__tags-list"
          style={{ marginBottom: '10px', height: 'calc(100vh - 296px)', overflowY: 'auto' }}
        >
          <ContractsTagsTable />
        </div>
      </div>
    </div>
  );
};
