import React, { useState } from "react";
import Table from "components/generic/table";
import TableItem from "components/generic/table-item";
import editButton from "../../assets/edit.svg";
import EditEmailTemplate from "./edit-email-template";
import { useLocation } from 'react-router-dom';

export default function EmailTemplates(props) {
  const { templates, getTemplates } = props;
  const { pathname } = useLocation();
  
  const [template, setTemplate] = useState(null);

  const formatDateTime = (timestamp) => {
    const dateTime = new Date(timestamp);
    return dateTime.toLocaleString();
  };

  const renderTemplates = () => {
    let templatesRender = [];
    templates && templates?.forEach((template) => {
      templatesRender.push(
        <TableItem key={template.id}>
          <div className="row cell auto">
            <div>{template.template_name}</div>
          </div>
          <div className="row cell auto">
            <div>{template.template_type}</div>
          </div>
          <div className="row cell auto">
            <div>{formatDateTime(template.created_at)}</div>
          </div>
          <div className="row cell auto">
            <div>{formatDateTime(template.updated_at)}</div>
          </div>
          <div className="row cell auto">
            {template.active === false ? (
              <div>Disabled</div>
            ) : (
              <div>Enabled</div>
            )}
          </div>
          <div className="row cell auto">
            {template.email_active === false ? (
              <div>Disabled</div>
            ) : (
              <div>Enabled</div>
            )}
          </div>
          <div className="row cell auto">
            <div>
              <img
                onClick={() => {
                  setTemplate(template);
                }}
                src={editButton}
                width="25px"
                height="25px"
                alt="edit-button"
              />
            </div>
          </div>
        </TableItem>
      );
    });
    return templatesRender;
  };

  return (
    <>
      {template === null ? (
        <h1 className="content-header">Set Email Templates</h1>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}>
            <button
              onClick={() => setTemplate(null)}
              style={{
                cursor: "pointer",
                background: "transparent",
                border: 0,
                color: "white",
                marginRight: "22px"
              }}
            >
              <i className="fa-solid fa-xl fa-angle-left" />
            </button>
          <h1 className="content-header">Edit Email Template</h1>
        </div>
      )}
      <div className="content-container"  style={{ padding: 0 }}>
        {template ? (
          <EditEmailTemplate
            template={template}
            setTemplate={setTemplate}
            getTemplates={getTemplates}
          />
        ) : (
          <Table
            pathname={pathname}
            key={"content-table"}
            columns={["Template Name", "Type", "Created At", "Updated At", "Active", "Email Active", "Edit"]}
          >
            {renderTemplates()}
          </Table>
        )}
      </div>
    </>
  );
}
