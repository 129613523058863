import "../../css/modal.scss";
import React from "react";
import CustomCommsDialog from "./custom-comms-dialog";
import CommsModule from "../comms/comms-module";

export default function UserComms(props) {
  const {
    handleClose,
    open,
    selectedUser,
    sidebarCollapse
  } = props;
  
  return (
    <CustomCommsDialog 
      dialogContent={
        <CommsModule
          selectedUser={selectedUser}
        />
      }
      onClose={handleClose}
      open={open}
      title={"USER"}
      titleHighlight={"COMMS"}
      sidebarCollapse={sidebarCollapse}
    />
  );
};
