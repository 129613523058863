import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import 'css/dashboadrd.scss';
import API from 'API';
import { useParams } from 'react-router-dom';
import Toast from 'components/toast/toast';
import { setRequestBody } from 'helpers/setRequestBody';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

const initialInfo = {
  template_name: '',
  template_body: '',
  is_active: true,
  id: null,
};

export const EmailTemplates = () => {
  const { id } = useParams();
  const token = JSON.parse(localStorage.getItem('token'));

  const [template, setTemplate] = useState(initialInfo);
  const [templateFromServer, setTemplateFromServer] = useState({});
  const [templates, setTemplates] = useState([]);
  const [quilBody, setQuilBody] = useState('');

  const [isCreating, setIsCreating] = useState(true);
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState('Email Template successfully created!');
  const [isError, setIsError] = useState(false);
  const [isErrorTemplates, setIsErrorTemplates] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleChangeName = (e) => {
    setTemplate({
      ...template,
      template_name: e.target.value,
    });
  };

  const handleEditClick = (value) => {
    setIsCreating(false);
    setTemplateFromServer(value);
    setTemplate(value);
    setQuilBody(value.template_body);
  };

  const getTemplates = () => {
    setLoading(true);
    let requestError = false;
    fetch(`${API.endpointURL}/api/filter/personal-email-templates?user_id=${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          requestError = true;
        }
        return res.json();
      })
      .then((res) => {
        if (requestError) {
          setIsErrorTemplates(true);
          setTemplates([]);
        } else {
          setIsErrorTemplates(false);
          setTemplates(res.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setIsErrorTemplates(true);
        setTemplates([]);
      });
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const handleCreate = () => {
    if (!template.template_name || !quilBody) {
      setIsError(true);
      setMessage('Error. Template name and body are required fields!');
    } else if (templates.some((item) =>
      item.template_name.toLowerCase() === template.template_name.toLowerCase(),
    )) {
      setIsError(true);
      setMessage('Error. Template name already exists!');
    } else {
      setIsError(false);
      try {
        setLoadingButton(true);
        let requestError = false;
        fetch(`${API.endpointURL}/api/personal-email-templates`, {
          method: 'POST',
          body: JSON.stringify({
            template_name: template.template_name,
            template_body: quilBody,
            is_active: template.is_active,
            user_id: id,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
        })
          .then((res) => {
            if (!res.ok) {
              requestError = true;
            }
            return res.json();
          })
          .then(() => {
            setLoadingButton(false);
            if (requestError) {
              setIsError(true);
              setMessage('Error. Something went wrong, try again later.');
            } else {
              setIsError(false);
              setMessage('Email Template successfully created!');
              setOpenToast(true);
              setTemplate(initialInfo);
              setQuilBody('');
              getTemplates();
            }
          });
      } catch (e) {
        setLoadingButton(false);
        setIsError(true);
        setMessage('Error. Something went wrong, try again later.');
      }
    }
  };

  const handleUpdate = () => {
    if (!template.template_name || !template.template_body) {
      setIsError(true);
      setMessage('Error. Template name and body are required fields!');
    } else if (
      templates.some((item) => {
        return item.template_name === template.template_name && item.id !== template.id;
      })
    ) {
      setIsError(true);
      setMessage('Error. Template name already exists!');
    } else {
      setIsError(false);
      try {
        setLoadingButton(true);
        let requestError = false;
        fetch(`${API.endpointURL}/api/personal-email-templates/${template.id}`, {
          method: 'PUT',
          body: JSON.stringify(setRequestBody(templateFromServer, {
            ...template,
            template_body: quilBody,
          })),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
        })
          .then((res) => {
            if (!res.ok) {
              requestError = true;
            }
            return res.json();
          })
          .then(() => {
            setLoadingButton(false);
            if (requestError) {
              setIsError(true);
              setMessage('Error. Something went wrong, try again later.');
            } else {
              setIsError(false);
              setMessage('Email Template successfully updated!');
              setOpenToast(true);
              setTemplate(initialInfo);
              setQuilBody('');
              setIsCreating(true);
              getTemplates();
            }
          });
      } catch (e) {
        setLoadingButton(false);
        setIsError(true);
        setMessage('Error. Something went wrong, try again later.');
      }
    }
  };

  const handleSubmit = () => {
    isCreating ? handleCreate() : handleUpdate();
  };

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],
      ['link'],
      [{ 'align': [] }],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: true,
    },
  };

  return (
    <>
      <h1 className="content-header">My Email Templates</h1>
      <div className="content-container email-templates" style={{ padding: 0 }}>
        <div className="email-templates__container">
          <div className="email-templates__container-top">
            <p className="email-templates__title">Edit/Create Template</p>
            {!isCreating && (
              <button
                className="create-button create-button-blue"
                onClick={() => {
                  setIsCreating(true);
                  setTemplate(initialInfo);
                  setQuilBody('');
                }}
              >
                Create
              </button>
            )}
          </div>
          <div className="email-templates__container-bottom">
            <div className="sale-form-set-half" style={{ marginBottom: 0 }}>
              <div className="sale-form-label">Template Name *</div>
              <input
                className="sale-form-input"
                placeholder="Enter template name..."
                value={template.template_name || ''}
                name="name"
                onChange={handleChangeName}
              />
            </div>
            <div>
              <div className="sale-form-label">Template Body *</div>
              <ReactQuill
                theme="snow"
                value={quilBody}
                onChange={setQuilBody}
                modules={modules}
                style={{ backgroundColor: '#EFEFEF40', color: '#EFEFEF', borderRadius: '10px', minHeight: '300px' }}
              />
            </div>
            <div className="sale-form-set sale-form-set-row" style={{ margin: 0 }}>
              <p className="sale-form-label sale-form-label-row">Active</p>
              <Switch
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#00A5CF',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#00A5CF',
                  },
                  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                    backgroundColor: '#c7c7c7',
                  },
                }}
                checked={template.is_active}
                onChange={() => {
                  setTemplate({
                    ...template,
                    is_active: !template.is_active,
                  });
                }}
              />
            </div>

            <div className="sale-form-button-container">
              {loadingButton && (
                <div
                  className="sale-form-spinner"
                  style={{ top: '-55px', right: 0 }}
                >
                  <ProgressSpinnerBlue/>
                </div>
              )}
              <button
                className="create-button create-button-blue"
                style={{ alignSelf: 'flex-end' }}
                onClick={handleSubmit}
                disabled={loadingButton}
              >
                {isCreating ? 'Create' : 'Update'}
              </button>
              {isError && (
                <p className="sale-form-error" style={{ right: '90px', top: '10px' }}>
                  {message}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="email-templates__container-thin">
          <div className="email-templates__container-top">
            <p className="email-templates__title">My Templates</p>
          </div>
          <div className="email-templates__container-box">
            {loading && (
              <div className="content-loading">
                <ProgressSpinnerBlue/>
              </div>
            )}
            {!loading && templates?.map((item) => (
              <div className="email-templates__template" key={item.id}>
                <p className="email-templates__template-title">{item.template_name}</p>
                <button
                  className="create-button create-button-blue"
                  style={{ padding: '4px 15px', borderRadius: '5px' }}
                  onClick={() => handleEditClick(item)}
                >
                  Edit
                </button>
              </div>
            ))}
            {!loading && templates?.length === 0 && (
              <p>Create new templates!</p>
            )}
            {!loading && isErrorTemplates && (
              <p className="sale-form-error" style={{ position: 'static', fontSize: '16px' }}>
                Error getting templates, try again later...
              </p>
            )}
          </div>
        </div>
      </div>
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={message}
        />
      )}
    </>
  );
};
