import { useAppSelector } from 'hooks/reduxHooks';
import {
  currentSalesTabSelector,
  salesStageSelector,
  selectedBusinessLinesSelector,
  selectedConnectionsSelector,
  selectedSalesRepsSelector,
} from 'store/salesSelectedOptions/selectors';

export const useSalesSelectedOptions = () => {
  const currentStage = useAppSelector(salesStageSelector);
  const currentTab = useAppSelector(currentSalesTabSelector);
  const businessLines = useAppSelector(selectedBusinessLinesSelector);
  const salesReps = useAppSelector(selectedSalesRepsSelector);
  const connections = useAppSelector(selectedConnectionsSelector);

  return [
    currentStage,
    currentTab,
    ...businessLines,
    ...salesReps,
    ...connections,
  ].join(', ');
}