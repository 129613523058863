import { axiosService } from 'services/axiosServices';

export const subdivisions = {
  getAllSubdivisions: async () => {
    const res = await axiosService.get('/api/sites');
    return res.data.data;
  } ,
  getSubdivisionsByDivisionId: async (divisionId) => {
    const res = await axiosService.get(`/api/filter/sites?region_id=${divisionId}`);
    return res.data.data;
  },
  getFilterableSubdivisions: async (divisionId) => {
    const res = await axiosService.get(`/api/filter/sites?region_id=${divisionId}&is_staffing_filterable=true`);
    return res.data.data;
  },
  getSubdivisionById: async (subdivisionId) => {
    const res = await axiosService.get(`/api/sites/${subdivisionId}`);
    return res.data.data;
  },
  getSubdivisionByDivisionAndContractId: async (divisionId, contractId) => {
    const res = await axiosService.get(`/api/filter/sites?region_id=${divisionId}&contract_id=${contractId}`);
    return res.data.data;
  },
  createSubdivision: async (_, body) => {
    const res = await axiosService.post(`/api/sites`, body);
    return res.data;
  },
  updateSubdivision: async (subdivisionId, body) => {
    const res = await axiosService.put(`/api/sites/${subdivisionId}`, body);
    return res.data;
  },
  deleteSubdivision: async (subdivisionId) => {
    const res = await axiosService.delete(`/api/sites/${subdivisionId}`);
    return res.data;
  }
}
