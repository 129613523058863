import React, { useState } from 'react';

import { IconButton, Tooltip } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import '../../css/contracts.scss';

import CrudContractDivisionSubdivisionModal from '../modal/crud-contract-division-subdivision';
import editButton from '../../assets/edit.png';
import deleteButton from '../../assets/delete.png';

import { FilterDropdown } from 'components/filter-fropdown/filter-fropdown';
import { DeleteModal } from 'components/modal/delete-modal';
import Toast from 'components/toast/toast';
import ProgressSpinner from 'components/progress/progress-spinner';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { deleteDivision, getDivisionsByContractId } from 'store/divisions/thunk';
import { setSelectedDivision } from 'store/divisions/slice';
import {
  divisionsByContractIdSelector,
  divisionsLoadingSelector,
  selectedDivisionSelector,
} from 'store/divisions/selectors';
import { selectedContractSelector } from 'store/contracts/selectors';

export default function DivisionsForm(props) {
  const { setCurrentDivisionId, setOpenEmailDivDialog } = props;

  const dispatch = useAppDispatch();
  const currentContract = useAppSelector(selectedContractSelector);
  const selectedDivision = useAppSelector(selectedDivisionSelector);
  const loading = useAppSelector(divisionsLoadingSelector);
  const divisions = useAppSelector(divisionsByContractIdSelector);
  
  const [addDivision, setAddDivision] = useState(false);
  const [editDivision, setEditDivision] = useState(false);
  const [deleteDivisionAction, setDeleteDivisionAction] = useState(false);
  const [divisionsFilter, setDivisionsFilter] = useState('open');
  const [message, setMessage] = useState('');
  const [openToast, setOpenToast] = useState(false);
  
  const getDivisions = () => {
    dispatch(getDivisionsByContractId({ contractId: currentContract.id }));
  };
  
  const handleDelete = async () => {
    dispatch(deleteDivision({ divisionId: selectedDivision.id }))
      .then((res) => {
        if (res.meta.requestStatus !== 'fulfilled') {
          setOpenToast(true);
          setDeleteDivisionAction(false);
          setMessage('Error deleting division. Try again later.');
        } else {
          setOpenToast(true);
          getDivisions();
          setDeleteDivisionAction(false);
          setMessage('Division successfully deleted.');
        }
      })
  };

  const openEmailModalHandler = (id) => {
    setOpenEmailDivDialog(true);
    setCurrentDivisionId(id);
  };

  const buildDivisionsList = () => {
    let divisionsList = [];
    if (divisions) {
      divisions
        .filter((division) => {
          if (divisionsFilter === 'all divisions') {
            return division;
          }
          return division.status === divisionsFilter;
        })
        .forEach((division, index) => {
          divisionsList.push(
            <div
              key={index}
              className={selectedDivision && division.id === selectedDivision.id ? 'division-subdivision selected' : 'division-subdivision'}
              onClick={() => dispatch(setSelectedDivision(division))}
            >
              {division.title}
              <Tooltip title="Edit division">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="edit-division"
                  sx={{
                    ml: 1,
                    pt: 0,
                    color: '#3e453d',
                    padding: '0px 5px 3px 0px',
                    marginLeft: '20px',
                  }}
                  onClick={() => {
                    dispatch(setSelectedDivision(division));
                    setEditDivision(true);
                  }}
                >
                  <img
                    src={editButton}
                    width="20px"
                    height="auto"
                    alt="edit-button"
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete division">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="delete-division"
                  sx={{
                    ml: 1,
                    pt: 0,
                    color: '#3e453d',
                    padding: '0px 5px 2px',
                    marginLeft: '5px',
                  }}
                  onClick={() => {
                    dispatch(setSelectedDivision(division));
                    setDeleteDivisionAction(true);
                  }}
                >
                  <img
                    src={deleteButton}
                    width="20px"
                    height="auto"
                    alt="edit-button"
                  />
                </IconButton>
              </Tooltip>

              <div className="divisions-email-btn">
                <Tooltip title="Send Email">
                  <IconButton
                    onClick={() => openEmailModalHandler(division.id)}
                    sx={{
                      padding: '0px 5px',
                      marginLeft: '5px',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <MailOutlineIcon
                      sx={{
                        width: '25px',
                        color: '#EFEFEF',
                        '&:hover': {
                          color: '#62AB37',
                        },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>,
          );
        });
    }

    return (
      <>{divisionsList}</>
    );
  };

  return (
    <div className="contract-form-section">
      <div className="contract-form-section-header">
        Divisions
        <FilterDropdown
          onChange={setDivisionsFilter}
          value={divisionsFilter}
          options={['open', 'closed', 'archived', 'all divisions']}
          style={{ position: 'absolute', top: '-5px', right: 0, width: '25%' }}
        />
      </div>
      <div className="contract-form-section-content">
        {divisions && !loading && buildDivisionsList()}
        {loading && <ProgressSpinner />}
        <button
          className="add-button"
          onClick={() => setAddDivision(true)}
        >
          + Add Division
        </button>
      </div>
      {addDivision && (
        <CrudContractDivisionSubdivisionModal
          action={'add'}
          getDivisions={getDivisions}
          handleClose={() => setAddDivision(false)}
          open={addDivision}
          selectedContract={currentContract}
          type={'Division'}
          setMessage={setMessage}
          setOpenTost={setOpenToast}
        />
      )}
      {editDivision && (
        <CrudContractDivisionSubdivisionModal
          action={'edit'}
          getDivisions={getDivisions}
          handleClose={() => setEditDivision(false)}
          open={editDivision}
          selectedContract={currentContract}
          selectedDivision={selectedDivision}
          type={'Division'}
          setMessage={setMessage}
          setOpenTost={setOpenToast}
        />
      )}
      {deleteDivisionAction && (
        <DeleteModal
          open={deleteDivisionAction}
          handleClose={() => setDeleteDivisionAction(false)}
          title="Delete"
          type="Division"
          name={selectedDivision?.title}
          handleDelete={handleDelete}
          loading={loading}
        />
      )}
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={message}
        />
      )}
    </div>
  );
};
