import "../../css/contracts.scss";
import React, { useState } from "react";
import EmailDialog from "components/email/email-dialog";
import Toast from "components/toast/toast";

export default function SendCustomEmail(props) {
  const { currentContract } = props;

  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openEmailToast, setOpenEmailToast] = useState(false);

  return (
    <>
      <h1 className="content-header">Send Custom Email</h1>
      <div className="content-container">
        <button
          className="create-button"
          onClick={() => setOpenEmailDialog(true)}
        >
          Send Email To Contract Users
        </button>
      </div>
      {openEmailDialog && (
        <EmailDialog
          handleClose={() => setOpenEmailDialog(false)}
          open={openEmailDialog}
          openEmailToast={() => setOpenEmailToast(true)}
          contract={currentContract}
          contractId={currentContract.id}
        />
      )}
      {openEmailToast && (
        <Toast
          open={openEmailToast}
          onClose={() => setOpenEmailToast(false)}
          message="Email sent"
        />
      )}
    </>
  );
};
