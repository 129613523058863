import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';

import editButton from 'assets/edit.png';
import deleteButton from 'assets/delete.png';
import saveButton from 'assets/check-3278.png';

import ProgressSpinner from 'components/progress/progress-spinner';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { contractQuestionsSelector, selectedContractSelector } from 'store/contracts/selectors';
import {
  setChangedQuestionsOrder,
  setDeleteQuestion,
  setEditQuestion,
  setNewQuestion,
  setOrderedQuestions,
} from 'store/contracts/slice';
import {
  createContractQuestion,
  deleteContractQuestion,
  getContractQuestions,
  updateContractQuestion,
  updateContractQuestionsOrder,
} from 'store/contracts/thunk';
import { ReorderIcon } from 'components/reorder/ReorderIcon';
import { StrictModeDroppable } from 'components/reorder/strict-mode-droppable';

export const ApplicationManagement = () => {
  const dispatch = useAppDispatch();
  const {
    loading,
    error,
    orderedQuestions,
    newQuestion,
    editQuestion,
    deleteQuestion,
    changedOrder,
  } = useAppSelector(contractQuestionsSelector);

  const [newQuestionError, setNewQuestionError] = useState('');
  const [buttonError, setButtonError] = useState('');
  const [editQuestionError, setEditQuestionError] = useState('');
  const [deleteQuestionError, setDeleteQuestionError] = useState('');
  const currentContract = useAppSelector(selectedContractSelector);

  const orderedQuestionsRef = useRef(orderedQuestions);
  const maxLength = 254;
  const [text, setText] = useState(editQuestion?.question || '');

  useEffect(() => {
    setText(editQuestion?.question || '');
  }, [editQuestion]);

  const getQuestions = () => {
    setButtonError('');
    dispatch(getContractQuestions({ contractId: currentContract?.id }));
  };

  const handleCreateQuestion = () => {
    if (!newQuestion.question) {
      setNewQuestionError('Please enter question.');
    } else {
      setNewQuestionError('');
      dispatch(createContractQuestion({ body: newQuestion }))
        .then(() => {
            if (orderedQuestions.length < 2) {
              getQuestions();
            } else {
              updateOrder(orderedQuestions);
            }
          },
        );
    }
  };

  const handleUpdateQuestion = () => {
    if (editQuestion && !editQuestion.question) {
      setEditQuestionError('Error. Please enter question.');
    } else {
      setEditQuestionError('');

      const body = {
        question: editQuestion.question,
        answer_type: editQuestion.answer_type,
        is_required: editQuestion.is_required,
      };
      dispatch(updateContractQuestion({ questionId: editQuestion.id, body }))
        .then(() => {
          if (orderedQuestions.length === 1) {
            getQuestions();
          } else {
            updateOrder(orderedQuestions);
          }
        });
    }
  };

  const handleDeleteQuestion = (id) => {
    setDeleteQuestionError('');
    dispatch(setDeleteQuestion(id));

    dispatch(deleteContractQuestion({ questionId: id }))
      .then(() => {
        if (orderedQuestions.length === 1) {
          getQuestions();
        } else {
          const updatedOrder = orderedQuestions.filter(item => item.id !== id);
          updateOrder(updatedOrder);
        }
      });
  };

  const updateOrder = (questions) => {
    const body = {
      sorting_data: questions.map((item, index) => ({
        question_id: item.id,
        sort_order: index,
      })),
    };
    dispatch(updateContractQuestionsOrder({ body }))
      .then(() => {
        getQuestions();
        dispatch(setChangedQuestionsOrder(false));
      });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from([...orderedQuestions]);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dispatch(setOrderedQuestions(items));
    dispatch(setChangedQuestionsOrder(true));
  };

  useEffect(() => {
    return () => {
      if (changedOrder) {
        updateOrder(orderedQuestionsRef?.current);
      }
    };
  }, [changedOrder]);

  useEffect(() => {
    orderedQuestionsRef.current = orderedQuestions;
  }, [orderedQuestions]);

  const handleEdit = (question) => {
    if (newQuestion) {
      setButtonError('Please, save created question first.');
      return;
    }
    if (editQuestion && editQuestion.id === question.id) {
      handleUpdateQuestion();
    } else {
      dispatch(setEditQuestion(question));
    }
  };

  const handleNewQuestion = () => {
    setNewQuestionError('');
    if (newQuestion) {
      setButtonError('Please, save created question first.');
    } else if (editQuestion) {
      setButtonError('Please, save edited question first.');
    } else {
      setText('');
      setButtonError('');
      const question = {
        contract_id: currentContract.id,
        question: '',
        answer_type: 'text',
        sort_order: orderedQuestions.length,
      };
      dispatch(setNewQuestion(question));
    }
  };

  return (
    <>
      <h1 className="content-header">Application Management</h1>
      <div className="create-button-container">
        <button
          className="create-button"
          onClick={handleNewQuestion}
        >
          Add Question
        </button>
        <p className="create-button-error">{buttonError}</p>
      </div>
      <div className="content-container" style={{ padding: 0 }}>
        <div className="contracts__manage-header row-border">
          <p>Question</p>
          <p>Answer Type</p>
          <p>Required</p>
          <p>Edit</p>
          <p>Delete</p>
          <p>Order</p>
        </div>
        {loading && (
          <div className="content-loading" style={{ height: 'calc(100% - 140px)' }}>
            <ProgressSpinner/>
          </div>
        )}
        {orderedQuestions?.length === 0 && !newQuestion && !loading && (
          <p className="content-placeholder" style={{ color: error ? '#a20601' : '#EFEFEF' }}>
            {error ? error : `No custom questions are currently active on the application for ${currentContract?.title}.`}
          </p>
        )}

        {orderedQuestions?.length > 0 && !loading && (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <StrictModeDroppable droppableId="orderedQuestions">
              {(provided) => (
                <div
                  className="characters"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {orderedQuestions.map((item, index) => {
                    return (
                      <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                        {(provided) => (
                          <div
                            className="contracts__manage-container"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              className="contracts__manage-row draggable-item"
                            >
                              {editQuestion?.id === item.id ? (
                                <>
                                  <div style={{
                                    fontSize: '14px',
                                    position: 'absolute',
                                    right: 410,
                                    top: 5,
                                    color: '#EFEFEF',
                                  }}>
                                    {text.length}/{maxLength}
                                  </div>
                                  <textarea
                                    className="contract-form-input"
                                    placeholder="Enter Question..."
                                    value={editQuestion?.question || ''}
                                    maxLength={maxLength}
                                    style={{ resize: 'vertical', minHeight: '40px' }}
                                    onChange={(e) => {
                                      const inputText = editQuestion?.question || e.target.value;
                                      dispatch(setEditQuestion({
                                        ...editQuestion,
                                        question: e.target.value,
                                      }));
                                      if (inputText.length <= maxLength) {
                                        setText(inputText);
                                      }
                                    }}
                                  />
                                </>
                              ) : (
                                <p>{item.question}</p>
                              )}
                              {editQuestion?.id === item.id && (
                                <span className="contracts__manage-row-error">
                                  {editQuestionError}
                                </span>
                              )}
                              {deleteQuestion === item.id && (
                                <span className="contracts__manage-row-error">
                                  {deleteQuestionError}
                                </span>
                              )}

                              {editQuestion?.id === item.id ? (
                                <div className="contract-filter">
                                  <select
                                    id="filter-status"
                                    className="contract-form-input contract-form-input-capitalized"
                                    value={editQuestion?.answer_type}
                                    onChange={(e) =>
                                      dispatch(setEditQuestion({
                                        ...editQuestion,
                                        answer_type: e.target.value,
                                      }))}
                                    style={{ width: '126px' }}
                                  >
                                    <option value={'text'}>Open-ended</option>
                                    <option value={'boolean'}>Yes/No</option>
                                  </select>
                                </div>
                              ) : (
                                <p>{item.answer_type === 'text' ? 'Open-ended' : 'Yes/No'}</p>
                              )}

                              {editQuestion?.id === item.id ? (
                                <div className="contract-filter">
                                  <select
                                    id="filter-status"
                                    className="contract-form-input contract-form-input-capitalized"
                                    value={editQuestion?.is_required}
                                    onChange={(e) =>
                                      dispatch(setEditQuestion({
                                        ...editQuestion,
                                        is_required: e.target.value === 'true',
                                      }))}
                                    style={{ width: '75px' }}
                                  >
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                  </select>
                                </div>
                              ) : (
                                <p>{item.is_required ? 'Yes' : 'No'}</p>
                              )}


                              <Tooltip title={editQuestion?.id === item.id ? 'Save changes' : 'Edit question'}>
                                <IconButton
                                  edge="start"
                                  aria-label="edit-division"
                                  sx={{
                                    padding: 0,
                                    margin: 0,
                                    color: '#3e453d',
                                    justifyContent: 'flex-start',
                                  }}
                                  onClick={() => handleEdit(item)}
                                >
                                  <img
                                    src={editQuestion?.id === item.id ? saveButton : editButton}
                                    width="20px"
                                    height="auto"
                                    alt="edit-button"
                                  />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Delete question">
                                <IconButton
                                  edge="start"
                                  aria-label="delete-division"
                                  sx={{
                                    padding: 0,
                                    margin: 0,
                                    color: '#3e453d',
                                  }}
                                  onClick={() => {
                                    dispatch(setDeleteQuestion(item.id));
                                    handleDeleteQuestion(item.id);
                                  }}
                                >
                                  <img
                                    src={deleteButton}
                                    width="20px"
                                    height="auto"
                                    alt="delete-button"
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <ReorderIcon color='#a5af96' />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        )}

        {newQuestion && !loading && (
          <div className="contracts__manage-row">
            <div style={{
              position: 'absolute',
              fontSize: '14px',
              right: 410,
              top: 5,
              color: '#EFEFEF',
            }}>
              {text.length}/{maxLength}
            </div>
            <textarea
              className="contract-form-input"
              placeholder="Enter Question..."
              maxLength={maxLength}
              style={{ resize: 'vertical', minHeight: '40px' }}
              value={newQuestion?.question || ''}
              onChange={(e) => {
                const inputText = e.target.value;
                const question = {
                  ...newQuestion,
                  question: e.target.value,
                };
                dispatch(setNewQuestion(question));
                setNewQuestionError('');
                if (inputText.length <= maxLength) {
                  setText(inputText);
                }
              }}
            />
            {newQuestionError && (
              <span className="contracts__manage-row-error">
                {newQuestionError}
              </span>
            )}
            <div className="contract-filter">
              <select
                id="filter-status"
                className="contract-form-input contract-form-input-capitalized"
                value={newQuestion.answer_type}
                onChange={(e) => {
                  const question = {
                    ...newQuestion,
                    answer_type: e.target.value,
                  };
                  dispatch(setNewQuestion(question));
                }}
                style={{ width: '126px' }}
              >
                <option value={'text'}>Open-ended</option>
                <option value={'boolean'}>Yes/No</option>
              </select>
            </div>

            <div className="contract-filter">
              <select
                id="filter-status"
                className="contract-form-input contract-form-input-capitalized"
                value={newQuestion.is_required}
                onChange={(e) => {
                  const question = {
                    ...newQuestion,
                    is_required: e.target.value === 'true',
                  };
                  dispatch(setNewQuestion(question));
                }}
                style={{ width: '75px' }}
              >
                <option value={'true'}>Yes</option>
                <option value={'false'}>No</option>
              </select>
            </div>

            <Tooltip title="Create question">
              <IconButton
                edge="start"
                aria-label="edit-division"
                sx={{
                  padding: 0,
                  margin: 0,
                  color: '#3e453d',
                  justifyContent: 'flex-start',
                }}
                onClick={handleCreateQuestion}
              >
                <img
                  src={saveButton}
                  width="20px"
                  height="auto"
                  alt="edit-button"
                />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete division">
              <IconButton
                edge="start"
                aria-label="delete-division"
                sx={{
                  padding: 0,
                  margin: 0,
                  color: '#3e453d',
                }}
                onClick={() => {
                  dispatch(setNewQuestion(null));
                  setButtonError('');
                }}
              >
                <img
                  src={deleteButton}
                  width="20px"
                  height="auto"
                  alt="delete-button"
                />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {/*{orderedQuestions.length === 0 && !newQuestion ? (*/}
        {/*  <p className="content-placeholder" style={{ color: error && '#a20601' }}>*/}
        {/*    {error ? error : `No custom questions are currently active on the application for ${currentContract?.title}.`}*/}
        {/*  </p>*/}
        {/*) : }*/}
      </div>
    </>
  );
};
