import React from 'react';
import { Button, Divider } from '@mui/material';
import fileOutlineIcon from 'assets/file_outline.svg';
import 'css/sales.scss';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';

export default function ProjectFileSidebar(props) {
  const {
    view,
    setView,
    projectFiles,
    setParamToReq,
    projectFileFolders,
    loadingFolders,
    errorLoadingFolders,
  } = props;
  
  const getMostRecentFileName = (currentType) => {
    const mostRecentFile = projectFiles?.find(file => file.type.type_name === currentType);
    if (mostRecentFile) {
      return <span>: <span style={{ color: '#A4F2F1' }}>{mostRecentFile.display_name}</span></span>;
    }
  };
  
  function renderFilesMenu(data, category) {
    return data?.map((fileType) =>
      category === fileType.category
        ? (
          <div key={fileType.id}>
            <Button
              variant="text"
              onClick={() => {
                setView(fileType.type_name);
                setParamToReq({
                  type_name: fileType.type_name,
                  category: fileType.category,
                });
              }}
              className={
                view === fileType.type_name
                  ? 'sale-filetype sale-filetype-selected'
                  : 'sale-filetype'
              }
            >
              <img
                src={fileOutlineIcon}
                alt="file-icon"
                style={{ width: '13px', height: 'auto', marginRight: '10.25px' }}
              />
              <p className="sale-filetype-typography">
                {fileType.type_name
                  .split('_')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
                {getMostRecentFileName(fileType.type_name)}
              </p>
            </Button>
          </div>
        )
        : null,
    );
  }
  
  const buildFilesMenu = (data, category) => {
    if (data && projectFiles) {
      return (
        <React.Fragment>
          {renderFilesMenu(data, category)}
          {data?.filter((fileType) =>
            category === fileType.category).length > 0 && (
            <Divider sx={{ borderColor: '#8b8b8b', marginBottom: '30px', marginTop: '30px' }} />
          )}
        </React.Fragment>
      );
    }
  };
  
  return (
    <div
      style={{
        height: '100%',
        borderRight: '1px solid #8b8b8b',
        padding: '50px 22px 22px',
        width: '30%',
        minWidth: '30%',
        overflowY: 'auto',
      }}
    >
      {loadingFolders && (
        <div className="contracts-loading-spinner">
          <ProgressSpinnerNavy/>
        </div>
      )}
      {!loadingFolders && errorLoadingFolders && (
        <p>{errorLoadingFolders}</p>
      )}
      {!loadingFolders && !errorLoadingFolders && (
        <>
          {/* Project Contract CATEGORY */}
          {projectFileFolders && buildFilesMenu(projectFileFolders, 'project_contract')}
          
          {/* Statement of Work CATEGORY */}
          {projectFileFolders && buildFilesMenu(projectFileFolders, 'statement_of_work')}
          
          {/* Custom CATEGORY */}
          <div style={{ fontFamily: 'MagistralCondW08-Medium', fontSize: '20px', marginBottom: '10px' }}>Custom</div>
          {projectFileFolders && buildFilesMenu(projectFileFolders, 'custom')}
        </>
      )}
    </div>
  );
};
