 import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import "../css/applicant-profile.scss";
import "../css/applicant-portal.scss";
import "../css/staffing.scss";
import "../css/loading.scss";
import AppBar from "../components/app-bar/updated-app-bar-component";
import ApplicantChecklist from "../components/user-portal/applicant-checklist";
import SidebarPreview from "../components/user-portal/sidebar-preview";
import PersonalData from "../components/user-portal/personal-data";
import DropBox from "../components/drop-box/drop-box";
import ProfileNotes from "../components/notes/profile-notes";
import ApplicantTags from "../components/user-portal/applicant-tags";
import Toast from "components/toast/toast";
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { applicantsErrorSelector, applicantSelector } from 'store/applicants/selectors';
import { getApplicant } from 'store/applicants/thunk';
import { getContractById, getContractTemplates } from 'store/contracts/thunk';
import { resetContract } from 'store/contracts/slice';
import { getSubdivisionById } from 'store/subdivisions/thunk';
import { resetSubdivision } from 'store/subdivisions/slice';
import { getDivisionById } from 'store/divisions/thunk';
import { resetDivision } from 'store/divisions/slice';
 import { Box, Modal, Typography } from '@mui/material';
 import Button from '@mui/material/Button';
 import { resetApplicantStore } from 'store/applicants/slice';
 import { resetUiStore } from 'store/ui/slice';

export default function ApplicantProfile() {
  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const applicant = useAppSelector(applicantSelector);
  const applicantError = useAppSelector(applicantsErrorSelector);
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const albionContractId = 108;
  
  const [view, setView] = useState('');
  const [openErrorToast, setOpenErrorToast] = useState(false);
  const [currentTab, setCurrentTab] = useState('personal information');
  const [error, setError] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [showModal, setShowModal] = useState(false);
  
  useEffect(() => {
    if (applicantError) {
      setError(applicantError);
    } else {
      setError('');
    }
  }, [applicantError]);

  useEffect(() => {
    setUserRole(loggedInUser.role);
    if (
      loggedInUser?.role === "admin" ||
      loggedInUser?.role === "staffing" ||
      loggedInUser?.role === "recruiter"
    ) {
      setView("admin");
      dispatch(getApplicant({ applicantId: id }));
      setCurrentTab("personal information");
    } else if (
      loggedInUser?.role === "applicant" &&
      loggedInUser?.id.toString() === id
    ) {
      setView("applicant");
      dispatch(getApplicant({ applicantId: id }));
      setCurrentTab("personal information");
    } else {
      setView("permission error");
    }
    }, [id]);
  
  useEffect(() => {
    if (applicant?.application) {
      if (applicant.application.contract_id) {
        if (loggedInUser?.role === "applicant") {
          setShowModal(true);
        }
        dispatch(getContractById({ contractId: applicant.application.contract_id }));
        dispatch(getContractTemplates({ contractId: applicant.application.contract_id }));
      } else if (!applicant.application.contract_id) {
        dispatch(resetContract());
        setShowModal(true);
      }
      if (applicant.application.region_id) {
        dispatch(getDivisionById({ divisionId: applicant.application.region_id }));
      } else if (!applicant.application.region_id) {
        dispatch(resetDivision());
      }

      if (applicant.application.site_id) {
        dispatch(getSubdivisionById({ subdivisionId: applicant.application.site_id }));
      } else if (!applicant.application.site_id) {
        dispatch(resetSubdivision());
      }
    }
  }, [applicant]);
  
  const handleRedirect = () => {
    handleLogout();
    window.open("https://staffing-app.responsivemanagement.org/", "_blank");
  };
  
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    
    dispatch(resetApplicantStore());
    dispatch(resetUiStore());
    setShowModal(false);
  };
  
  const showContent = () => {
    if (loggedInUser?.role === "applicant") {
      return false;
    }
    return true;
  }

  return (
    <>
      <AppBar colorPalette="blue" menu={true} title="Applicant Portal" />
      <div className="accounting-page">
        {(view === "admin" || view === "applicant") && applicant && !showModal && showContent() && (
          <>
            <div className="sidebar-content">
              <SidebarPreview
                currentTab={currentTab}
                getApplicant={() => dispatch(getApplicant({ applicantId: id }))}
                portalType={"applicant"}
                setCurrentTab={setCurrentTab}
                user={applicant}
                view={view}
              />
            </div>
            <div className="page-content" style={{ position: 'relative' }}>
              {currentTab === "personal information" && (
                <PersonalData
                  setOpenToast={setOpenToast}
                  applicant={applicant}
                  getApplicant={() => dispatch(getApplicant({ applicantId: id }))}
                  portalType={"applicant"}
                  view={view}
                />
              )}
              {currentTab === "checklist" && (
                <ApplicantChecklist />
              )}
              {/*{currentTab === "hiring timeline" && (*/}
              {/*  <HiringTimeline />*/}
              {/*)}*/}
              {currentTab === "drop box" && (
                <DropBox
                  setOpenErrorToast={setOpenErrorToast}
                  setError={setError}
                  error={error}
                  userRole={userRole}
                />
              )}
              {currentTab === "notes" && (
                <ProfileNotes />
              )}
              {loggedInUser && currentTab === "tags" && (
                <ApplicantTags />
              )}
            </div>
          </>
        )}
        {view === "permission error" && (
          <div style={{ color: "white" }}>Permission denied</div>
        )}
      </div>
      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message={error || "There was an issue on applicant portal."}
        />
      )}
      {openToast &&
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message="Applicant info saved"
        />
      }
      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#fff",
            boxShadow: 24,
            margin: "0 auto",
            padding: "32px",
            maxWidth: "700px",
            borderRadius: "8px"
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            sx={{ color: "#333", marginBottom: 2, fontFamily: "Korolev Medium" }}
          >
            Attention, {applicant?.first_name}!
          </Typography>
          <Typography
            sx={{
              color: "#00a5cf",
              fontFamily: "MagistralCondW08-Medium",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "500",
              margin: "0 auto 16px",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Management of your application has moved.
          </Typography>
          {/*{applicantContract && (*/}
          {/*  <Typography*/}
          {/*    sx={{*/}
          {/*      color: "#555",*/}
          {/*      marginBottom: 2,*/}
          {/*      fontSize: "1rem",*/}
          {/*      fontFamily: "Korolev Medium"*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Your current contract: {applicantContract?.title}*/}
          {/*  </Typography>*/}
          {/*)}*/}
          <Typography
            sx={{
              color: "#555",
              marginBottom: 2,
              fontSize: "1rem",
              fontFamily: "Korolev Medium"
            }}
          >
            Click 'okay' to log out and be redirected to our new platform.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#00a5cf",
              color: "#fff",
              marginBottom: 2,
              "&:hover": { backgroundColor: "#007b9e" },
            }}
            onClick={handleRedirect}
          >
            Okay, Redirect Me
          </Button>
          <Typography
            sx={{
              color: "#555",
              fontSize: "0.875rem",
              fontFamily: "Korolev Medium"
            }}
          >
            If you have any questions, please contact our support team at {' '}
            <br />
            <a
              style={{
                color: '#00a5cf',
                display: 'inline',
                fontWeight: 800,
                fontFamily: "Korolev Medium"
            }}
              href="mailto:rdmstaffing@responsivedeployment.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              rdmstaffing@responsivedeployment.com
            </a>.
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
