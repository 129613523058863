import { createAsyncThunk } from '@reduxjs/toolkit';
import { sales } from 'services/sales';

export const getSalesByFilter = createAsyncThunk(
  'getSalesByFilter',
  async ({ filters }, { signal, rejectWithValue }) => {
    try {
      const controller = new AbortController();
      signal.addEventListener('abort', () => {
        controller.abort();
      });

      const result = await sales.getSalesByFilter(filters, controller.signal);
      return result;
    } catch (err) {
      if (err.name === 'AbortError') {
        // Handle the canceled request as needed
        return rejectWithValue(new Error('Request canceled'));
      }
      return rejectWithValue(err.message);
    }
  }
);
export const getCompanyConnections = createAsyncThunk(
  'getCompanyConnections',
  async (_, { signal, rejectWithValue }) => {
    try {
      const controller = new AbortController();
      signal.addEventListener('abort', () => {
        controller.abort();
      });

      const result = await sales.getCompanyConnections(controller.signal);
      return result;
    } catch (err) {
      if (err.name === 'AbortError') {
        // Handle the canceled request as needed
        return rejectWithValue(new Error('Request canceled'));
      }
      return rejectWithValue(err.message);
    }
  }
);
export const getPersonalConnections = createAsyncThunk(
  'getPersonalConnections',
  async (_, { signal, rejectWithValue }) => {
    try {
      const controller = new AbortController();
      signal.addEventListener('abort', () => {
        controller.abort();
      });

      const result = await sales.getPersonalConnections(controller.signal);
      return result;
    } catch (err) {
      if (err.name === 'AbortError') {
        // Handle the canceled request as needed
        return rejectWithValue(new Error('Request canceled'));
      }
      return rejectWithValue(err.message);
    }
  }
);
export const getBusinessLines = createAsyncThunk(
  'businessLines',
  async (_, { rejectWithValue }) => {
    try {
      return await sales.getBusinessLines();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSalesReps = createAsyncThunk(
  'salesReps',
  async (_, { rejectWithValue }) => {
    try {
      return await sales.getSalesReps();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSaleQuotes = createAsyncThunk(
  'getSaleQuotes',
  async ({ saleId }, { rejectWithValue }) => {
    try {
      return await sales.getSaleQuotes(saleId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSale = createAsyncThunk(
  'getSale',
  async ({ saleId }, { rejectWithValue }) => {
    try {
      return await sales.getSale(saleId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateSale = createAsyncThunk(
  'updateSale',
  async ({ saleId, body }, { rejectWithValue }) => {
    try {
      return await sales.updateSale(saleId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createSaleQuote = createAsyncThunk(
  'createSaleQuote',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await sales.createSaleQuote(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateSaleQuote = createAsyncThunk(
  'updateSaleQuote',
  async ({ quoteId, body }, { rejectWithValue }) => {
    try {
      return await sales.updateSaleQuote(quoteId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSalesAnalyticsPerStage = createAsyncThunk(
  'salesAnalyticsPerStage',
  async (_, { rejectWithValue }) => {
    try {
      return await sales.getSalesAnalyticsPerStage();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSalesRepsAnalyticsPerStage = createAsyncThunk(
  'getSalesRepsAnalyticsPerStage',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await sales.getSalesRepsAnalyticsPerStage(id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getBidTypes = createAsyncThunk(
  'getBidTypes',
  async (_, { rejectWithValue }) => {
    try {
      return await sales.getBidTypes();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSalesDivisions = createAsyncThunk(
  'getSalesDivisions',
  async (_, { rejectWithValue }) => {
    try {
      return await sales.getSalesDivisions();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSalesServiceTypes = createAsyncThunk(
  'getSalesServiceTypes',
  async (_, { rejectWithValue }) => {
    try {
      return await sales.getSalesServiceTypes();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSalesMilestones = createAsyncThunk(
  'getSalesMilestones',
  async (_, { rejectWithValue }) => {
    try {
      return await sales.getSalesMilestones();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSaleNotes = createAsyncThunk(
  'getSaleNotes',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await sales.getSaleNotes(id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSalesMilestonesTasks = createAsyncThunk(
  'getSalesMilestonesTasks',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await sales.getSalesMilestonesTasks(id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSalesTasks = createAsyncThunk(
  'getSalesTasks',
  async ({ id, status, page }, { rejectWithValue }) => {
    try {
      const statusFilter = status.reduce((result, value) => result += '&status[]=' + value, '');
      return await sales.getSalesTasks(id, statusFilter, page);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);