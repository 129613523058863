export const userRoleSelector = (state) => state.user.userRole;
export const userSelector = (state) => state.user.user;
export const usersSelector = (state) => state.user.users;
export const userLoadingSelector = (state) => state.user.userLoading;
export const userErrorSelector = (state) => state.user.userError;
export const userEditStatusSelector = (state) => state.user.userEditStatus;
export const emailTemplatesSelector = (state) => state.user.emailTemplates;
export const templatesErrorSelector = (state) => state.user.templatesError;
export const templatesLoadingSelector = (state) => state.user.templatesLoading;
export const sendEmailStatusSelector = (state) => state.user.sendEmailStatus;
export const sendEmailErrorSelector = (state) => state.user.sendEmailError;
export const emailLoadingSelector = (state) => state.user.emailLoading;
export const resetPasswordSelector = (state) => state.user.resetPassword;
