import React, { useEffect } from 'react';

import 'css/analytics.scss';
import 'css/pages.scss';
import 'css/metrics.scss';

import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import {
  projectsAnalyticsSelector,
  projectsCoordinatorsSelector,
  projectsManagersSelector,
} from 'store/projects/selectors';
import { getProjectsAnalytics } from 'store/projects/thunk';
import { connectionsAnalyticsSelector } from 'store/connections/selectors';
import { getConnectionsAnalytics } from 'store/connections/thunk';
import { setSelectedProjectCoordinator, setSelectedProjectManager } from 'store/projects/slice';

export const ProjectsAnalytics = () => {
  const dispatch = useAppDispatch();
  const {projectsAnalytics, loadingAnalytics, errorAnalytics } = useAppSelector(projectsAnalyticsSelector);
  const {connectionsAnalytics, loadingConnectionsAnalytics, errorConnectionsAnalytics } = useAppSelector(connectionsAnalyticsSelector);
  const {
    projectCoordinators,
    loadingProjectCoordinators,
    errorProjectCoordinators,
    selectedCoordinator
  } = useAppSelector(projectsCoordinatorsSelector);
  const {
    projectManagers,
    loadingProjectManagers,
    errorProjectManagers,
    selectedManager
  } = useAppSelector(projectsManagersSelector);
  
  useEffect(() => {
    if (!connectionsAnalytics) {
      dispatch(getConnectionsAnalytics());
    }
  }, [connectionsAnalytics]);
  
  useEffect(() => {
    dispatch(getProjectsAnalytics({ selectedManager, selectedCoordinator }));
  }, [selectedCoordinator, selectedManager]);
  
  return (
    <>
      <h1 className="content-header">Projects Analytics</h1>
      <div className="metrics-content-container">
        {loadingProjectCoordinators || loadingProjectManagers || loadingConnectionsAnalytics ? (
          <div className="content-loading">
            <ProgressSpinnerNavy/>
          </div>
        ) : (
          <>
            <div className="stats-group-container">
              <div className="projects__analytics-statusContainer">
                <div className="stats-group-header" style={{ width: '50%' }}>Projects per status</div>
                {errorProjectCoordinators?.length > 0 || errorProjectManagers.length > 0 ? (
                  <>
                    {errorProjectCoordinators.length > 0 && (
                      <p className="sale-form-label projects__analytics-error">{errorProjectCoordinators}</p>
                    )}
                    {errorProjectManagers.length > 0 && (
                      <p className="sale-form-label projects__analytics-error">{errorProjectManagers}</p>
                    )}
                  </>
                ) : (
                  <div className="projects__analytics-selects">
                    <div className="sale-form-set projects__analytics-select">
                      <div className="sale-form-label">Project Manager</div>
                      <select
                        className="sale-form-input"
                        placeholder="Select Manager..."
                        value={selectedManager}
                        name="project_manager_id"
                        onChange={(e) => dispatch(setSelectedProjectManager(e.target.value))}
                        style={{
                          padding: '7px 12px',
                        }}
                      >
                        <>
                          <option value="">Select Manager...</option>
                          {projectManagers.map((element) => (
                            <option key={element.id} value={element.id}>
                              {`${element.first_name} ${element.last_name}`}
                            </option>
                          ))}
                        </>
                      </select>
                    </div>
                    <div className="sale-form-set projects__analytics-select">
                      <div className="sale-form-label">Project Coordinator</div>
                      <select
                        className="sale-form-input"
                        placeholder="Select Coordinator..."
                        value={selectedCoordinator}
                        name="project_coordinator_id"
                        onChange={(e) => dispatch(setSelectedProjectCoordinator(e.target.value))}
                        style={{
                          padding: '7px 12px',
                        }}
                      >
                        <>
                          <option value="">Select Coordinator...</option>
                          {projectCoordinators.map((element) => (
                            <option key={element.id} value={element.id}>
                              {`${element.first_name} ${element.last_name}`}
                            </option>
                          ))}
                        </>
                      </select>
                    </div>
                  </div>
                )}
              </div>
              {!loadingAnalytics && errorAnalytics?.length > 0 && (
                <p className="sale-form-label projects__analytics-error">{errorAnalytics}</p>
              )}
              {loadingAnalytics
                ? (
                  <div className="content-loading projects__analytics-loading">
                    <ProgressSpinnerNavy />
                  </div>
                ) :
                errorAnalytics?.length === 0 && (
                  <div className="contract-totals-row">
                    {['Pending', 'Current', 'Completed', 'Archived'].map((stage) => (
                        <div className="stage-stat-container" key={stage}>
                          <div className="stage-stat-header">{stage}</div>
                          <div className="stage-stat-number projects__analytics-number">
                            {projectsAnalytics && projectsAnalytics[`${stage.toLowerCase()}_count`]}
                          </div>
                          <div className="stage-stat-unit">
                            {projectsAnalytics &&
                            projectsAnalytics[`${stage.toLowerCase()}_count`] === 1
                              ? 'project'
                              : 'projects'}
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                )}
            
            </div>
            {!loadingConnectionsAnalytics && errorConnectionsAnalytics.length > 0 && (
              <p className="sale-form-label projects__analytics-error">{errorConnectionsAnalytics}</p>
            )}
            {errorConnectionsAnalytics.length === 0 && (
              <div className="stats-group-container">
                <div className="stats-group-header">Connections per type</div>
                <div className="contract-totals-row">
                  <div className="stage-stat-container">
                    <div className="stage-stat-header">Personal</div>
                    <div className="stage-stat-number projects__analytics-number">
                      {connectionsAnalytics && connectionsAnalytics?.personal_count}
                    </div>
                    <div
                      className="stage-stat-unit">
                      {connectionsAnalytics && connectionsAnalytics?.personal_count === 1 ? 'connection' : 'connections'}
                    </div>
                  </div>
                  <div className="stage-stat-container">
                    <div className="stage-stat-header">Company</div>
                    <div className="stage-stat-number projects__analytics-number">
                      {connectionsAnalytics && connectionsAnalytics?.company_count}
                    </div>
                    <div className="stage-stat-unit">
                      {connectionsAnalytics && connectionsAnalytics?.company_count === 1 ? 'connection' : 'connections'}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
