import { createSlice } from '@reduxjs/toolkit';
import {
  createQuoteCategory,
  deleteQuoteCategory,
  getQuoteCategories,
  updateQuoteCategory,
} from 'store/quotes/thunk';

const initialState = {
  categories: [],
  categoriesLoading: false,
  categoriesError: '',
};

const quotesSlice = createSlice({
  name: 'quotesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getQuoteCategories.pending, (state) => {
      state.categoriesLoading = true;
      state.categoriesError = '';
    });
    builder.addCase(getQuoteCategories.fulfilled, (state, { payload }) => {
      state.categories = payload;
      state.categoriesLoading = false;
    });
    builder.addCase(getQuoteCategories.rejected, (state) => {
      state.divisionsError = 'Error getting quote categories.';
      state.isLoading = false;
    });

    builder.addCase(createQuoteCategory.pending, (state) => {
      state.categoriesLoading = true;
      state.categoriesError = '';
    });
    builder.addCase(createQuoteCategory.fulfilled, (state) => {
      state.categoriesError = '';
    });
    builder.addCase(createQuoteCategory.rejected, (state) => {
      state.categoriesError = 'Error creating quote category.';
      state.categoriesLoading = false;
    });

    builder.addCase(updateQuoteCategory.pending, (state) => {
      state.categoriesLoading = true;
      state.categoriesError = '';
    });
    builder.addCase(updateQuoteCategory.fulfilled, (state) => {
      state.categoriesError = '';
    });
    builder.addCase(updateQuoteCategory.rejected, (state) => {
      state.categoriesLoading = false;
    });

    builder.addCase(deleteQuoteCategory.pending, (state) => {
      state.categoriesLoading = true;
      state.categoriesError = '';
    });
    builder.addCase(deleteQuoteCategory.fulfilled, (state) => {
      state.categoriesError = '';
    });
    builder.addCase(deleteQuoteCategory.rejected, (state) => {
      state.categoriesLoading = false;
    });
  },
});

export const {} = quotesSlice.actions;

export default quotesSlice.reducer;