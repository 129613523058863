import '../../css/modal.scss';
import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import CustomDialog from './custom-dialog';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import API from 'API';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

const theme = createTheme({
  palette: {
    primary: {
      main: '#a5af96',
    },
  },
});

export default function EditApplicantScreening(props) {
  const {
    applicant,
    getApplicant,
    handleClose,
    open,
    setOpenToast,
    initialQuestions,
  } = props;
  
  const token = JSON.parse(localStorage.getItem('token'));
  const [emptyAnswerErrors, setEmptyAnswerErrors] = useState([]);
  const [applicantAnswers, setApplicantAnswers] = useState({});

  useEffect(() => {
    const emptyValues = initialQuestions.filter((question) => question?.answer === null).map((item) => item?.application_question?.id);
    setEmptyAnswerErrors(emptyValues);
  }, [initialQuestions]);
  
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editedFields, setEditedFields] = useState([]);
  
  const editAnswers = async () => {
    const questionsWithoutDeleted = initialQuestions.filter(question => question.application_question !== null);
    await fetch(
      `${API.endpointURL}/api/contracts/${applicant.application.contract_id}/application-answers/mass-update`,
      {
        method: 'POST',
        body: JSON.stringify({
          answers: Object.entries(applicantAnswers).map(item => ({
            id: questionsWithoutDeleted.find(question => question.application_question.id === Number(item[0])).id,
            applicant_id: applicant.application.id,
            application_question_id: Number(item[0]),
            answer: item[1],
            is_required: false,
          })),
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json; charset=UTF-8',
          Accept: 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        if (data['errors']) {
          setError(data['errors']);
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };
  
  const handleEdit = async () => {
    setError(null);
    try {
      const filteredQuestions = initialQuestions.filter((question) =>
        question?.application_question?.is_required
      );
      
      const hasEmptyAnswers = filteredQuestions.some(
        (question) => {
         return emptyAnswerErrors.includes(question.application_question.id);
        },
      );

      if (hasEmptyAnswers) {
        setError('* Please fill out all required fields.');
        return;
      }
      
      setLoading(true);
      if (Object.keys(applicantAnswers).length > 0) {
        await editAnswers();
      }
      
      setApplicantAnswers({});
      getApplicant();
      setLoading(false);
      setOpenToast(true);
      handleClose();
    } catch (error) {
      setLoading(false);
      setError('An error occurred during editing:');
    }
  };

  const handleChange = (e, id) => {
    if (e.target.value === '') {
      setEmptyAnswerErrors((state) => [...state, id]);
    } else {
      setEmptyAnswerErrors(emptyAnswerErrors.filter(item => item !== id));
    }
    setApplicantAnswers({
      ...applicantAnswers,
      [id]: e.target.value,
    });
  };
  
  const findAnswerValue = (item) => {
    if (item.application_question.id in applicantAnswers) {
      return applicantAnswers[item.application_question.id] || '';
    } else {
      return item.answer;
    }
  };
  
  const editScreeningForm = () => {
    return (
      <ThemeProvider theme={theme}>
        {initialQuestions.length > 0 && (
          initialQuestions.map((item) => (
            item.application_question !== null &&
            <div key={`${item.id}-question`}>
              <div
                className="form-row-label"
                style={{ marginBottom: '8px', marginTop: '12px' }}
              >
                {item.question} {item.application_question.is_required && '*'}
              </div>
              {item.application_question.answer_type === 'text'
                ? (
                  <TextField
                    disabled={loading}
                    placeholder="Note here..."
                    variant="outlined"
                    value={findAnswerValue(item) === 'true' ? 'Yes' : findAnswerValue(item) === 'false' ? 'No' : findAnswerValue(item) || ''}
                    onChange={(e) => {
                      setEditedFields([...editedFields, item.application_question.id]);
                      handleChange(e, item.application_question.id);
                    }}
                    fullWidth
                    sx={{
                      backgroundColor: '#EFEFEF',
                      borderRadius: '4px',
                      color: '#3e453d',
                      fontFamily: 'Korolev Medium',
                      margin: '4px 0 8px',
                      input: {
                        color: '#3e453d',
                        fontFamily: 'Korolev Medium',
                      },
                    }}
                  />
                )
                : (
                  <RadioGroup
                    row
                    value={findAnswerValue(item)}
                    onChange={(e) => {
                      setEditedFields([...editedFields, item.application_question.id]);
                      handleChange(e, item.application_question.id);
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      disabled={loading}
                      control={
                        <Radio
                          color="primary"
                          sx={{
                            '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                              {
                                color: '#a5af96',
                              },
                          }}
                        />
                      }
                      label="Yes"
                      sx={{ color: '#3e453d' }}
                    />
                    <FormControlLabel
                      value={false}
                      disabled={loading}
                      control={
                        <Radio
                          color="primary"
                          sx={{
                            '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                              {
                                color: '#a5af96',
                              },
                          }}
                        />
                      }
                      label="No"
                      sx={{ color: '#3e453d' }}
                    />
                    {item.answer !== 'false' && item.answer !== 'true' && item.answer !== null && (
                      <FormControlLabel
                        value={item.answer}
                        disabled={loading}
                        control={
                          <Radio
                            color="primary"
                            sx={{
                              '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                                {
                                  color: '#a5af96',
                                },
                            }}
                          />
                        }
                        label={item.answer}
                        sx={{ color: '#3e453d' }}
                      />
                    )}
                  </RadioGroup>
                )
              }
            </div>
          ))
        )}
        
        {error && <div className="add-user-error">{error}</div>}
        <div className="button-container" style={{ overflow: 'hidden', paddingTop: '5px' }}>
          <Button
            variant="contained"
            className="add-user-button"
            disabled={loading}
            sx={{
              position: 'relative',
              backgroundColor: '#556155',
              color: '#EFEFEF',
              fontSize: '18px',
              width: '175px',
              '&:hover': { backgroundColor: '#a5af96', color: '#3e453d' },
            }}
            onClick={handleEdit}
          >
            Edit
            {loading && (
              <div style={{ position: 'absolute', left: -55, bottom: -10 }} className="applicant__edit-loading">
                <ProgressSpinnerBlue/>
              </div>
            )}
          </Button>
        </div>
      </ThemeProvider>
    );
  };
  
  return (
    <CustomDialog
      dialogContent={editScreeningForm()}
      onClose={handleClose}
      open={open}
      title={'EDIT'}
      titleHighlight={'SCREENING'}
    />
  );
}
