import { createSlice } from '@reduxjs/toolkit';
import {
  getBusinessLines,
  getCompanyConnections,
  getSale,
  getSalesAnalyticsPerStage,
  getSaleQuotes,
  getSalesByFilter,
  getSalesReps,
  updateSale,
  getSalesRepsAnalyticsPerStage,
  getBidTypes,
  getSalesDivisions,
  getSalesServiceTypes,
  getSalesMilestones,
  getPersonalConnections,
  getSaleNotes,
  getSalesMilestonesTasks, getSalesTasks,
} from 'store/sales/thunk';

const initialState = {
  sales: [],
  currentPage: 1,
  totalPages: 1,
  loading: false,
  salesError: '',
  businessLines: [],
  businessLinesLoading: false,
  salesReps: [],
  salesRepsLoading: false,
  companyConnections: [],
  companyConnectionsLoading: false,
  personalConnections: [],
  personalConnectionsLoading: false,

  analyticsPerStage: null,
  analyticsRepsPerStage: null,
  analyticsLoading: false,
  analyticsRepsLoading: false,
  selectedSalesRepAnalytics: '',
  isLoadedAnalytics: false,
  isLoadedUserAnalytics: false,

  sale: {
    sale: null,
    loading: false,
    error: '',
    updateLoading: false,
    updateError: '',
    milestonesTasks: [],
    milestonesTasksLoading: false,
    saleTasks: [],
    saleTasksLoading: false,
    tasksTab: 'assigned',
    tasksPage: 1,
    totalTasksPages: 1,
  },
  
  salesQuotes: {
    quotes: [],
    loading: false,
    selectedQuote: null,
  },

  management: {
    bidTypes: [],
    bitTypesLoading: false,
    divisions: [],
    divisionsLoading: false,
    serviceTypes: [],
    serviceTypesLoading: false,
    milestones: [],
    milestonesLoading: false,
  },

  notes: {
    notes: [],
    loading: false,
    error: '',
  }
};

const salesSlice = createSlice({
  name: 'salesSlice',
  initialState,
  reducers: {
    setSelectedQuote(state, { payload }) {
      state.salesQuotes.selectedQuote = payload;
    },
    setUpdateError(state, { payload }) {
      state.sale.updateError = payload;
    },
    setSelectedAnalyticsRep(state, { payload }) {
      state.selectedSalesRepAnalytics = payload;
    },
    setIsLoadedAnalytics(state, { payload }) {
      state.isLoadedAnalytics = payload;
    },
    setIsLoadedUserAnalytics(state, { payload }) {
      state.isLoadedUserAnalytics = payload;
    },
    resetCurrentPage(state) {
      state.currentPage = 1;
    },
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    setTasksTab(state, { payload }) {
      state.sale.tasksTab = payload;
      state.sale.tasksPage = 1;
    },
    setTasksPage(state, { payload }) {
      state.sale.tasksPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSalesByFilter.pending, (state) => {
      state.loading = true;
      state.sales = [];
      state.salesError = '';
    });
    builder.addCase(getSalesByFilter.fulfilled, (state, { payload }) => {
      state.sales = payload.data || [];
      state.loading = false;
      state.totalPages = payload.meta.last_page;
    });
    builder.addCase(getSalesByFilter.rejected, (state, { payload }) => {
      if (payload !== 'canceled') {
        state.loading = false;
        state.salesError = 'Error getting sales.';
      }
    });
    builder.addCase(getCompanyConnections.pending, (state) => {
      state.companyConnectionsLoading = true;
      state.companyConnections = [];
      state.salesError = '';
    });
    builder.addCase(getCompanyConnections.fulfilled, (state, { payload }) => {
      state.companyConnections = payload || [];
      state.companyConnectionsLoading = false;
    });
    builder.addCase(getCompanyConnections.rejected, (state, { payload }) => {
      if (payload !== 'canceled') {
        state.companyConnectionsLoading = false;
      }
    });
    builder.addCase(getPersonalConnections.pending, (state) => {
      state.personalConnectionsLoading = true;
      state.personalConnections = [];
    });
    builder.addCase(getPersonalConnections.fulfilled, (state, { payload }) => {
      state.personalConnections = payload || [];
      state.personalConnectionsLoading = false;
    });
    builder.addCase(getPersonalConnections.rejected, (state, { payload }) => {
      if (payload !== 'canceled') {
        state.personalConnectionsLoading = false;
      }
    });
    builder.addCase(getBusinessLines.pending, (state) => {
      state.businessLinesLoading = true;
      state.businessLines = [];
    });
    builder.addCase(getBusinessLines.fulfilled, (state, { payload }) => {
      state.businessLines = payload;
      state.businessLinesLoading = false;
    });
    builder.addCase(getBusinessLines.rejected, (state) => {
      state.businessLinesLoading = false;
    });
    builder.addCase(getSalesReps.pending, (state) => {
      state.salesRepsLoading = true;
      state.salesReps = [];
    });
    builder.addCase(getSalesReps.fulfilled, (state, { payload }) => {
      state.salesReps = payload;
      state.selectedSalesRepAnalytics = payload[0]?.id;
      state.salesRepsLoading = false;
    });
    builder.addCase(getSalesReps.rejected, (state) => {
      state.salesRepsLoading = false;
    });
    builder.addCase(getSaleQuotes.pending, (state) => {
      state.salesQuotes.loading = true;
    });
    builder.addCase(getSaleQuotes.fulfilled, (state, { payload }) => {
      state.salesQuotes.quotes = payload;
      state.salesQuotes.loading = false;
    });
    builder.addCase(getSaleQuotes.rejected, (state) => {
      state.salesQuotes.loading = false;
    });
    
    builder.addCase(getSale.pending, (state) => {
      state.sale.loading = true;
      state.sale.error = '';
    });
    builder.addCase(getSale.fulfilled, (state, { payload }) => {
      state.sale.sale = payload;
      state.sale.loading = false;
      state.sale.error = '';
    });
    builder.addCase(getSale.rejected, (state) => {
      state.sale.loading = false;
      state.sale.error = 'Error getting sale';
    });
    
    builder.addCase(updateSale.pending, (state) => {
      state.sale.updateLoading = true;
      state.sale.updateError = '';
    });
    builder.addCase(updateSale.fulfilled, (state, { payload }) => {
      state.sale.sale = payload;
      state.sale.updateLoading = false;
      state.sale.updateError = '';
    });
    builder.addCase(updateSale.rejected, (state) => {
      state.sale.updateLoading = false;
      state.sale.updateError = 'Error updating sale';
    });

    builder.addCase(getSalesAnalyticsPerStage.pending, (state) => {
      state.analyticsLoading = true;
    });
    builder.addCase(getSalesAnalyticsPerStage.fulfilled, (state, { payload }) => {
      state.analyticsPerStage = payload;
      state.analyticsLoading = false;
    });
    builder.addCase(getSalesAnalyticsPerStage.rejected, (state) => {
      state.analyticsLoading= false;
    });

    builder.addCase(getSalesRepsAnalyticsPerStage.pending, (state) => {
      state.analyticsRepsLoading = true;
    });
    builder.addCase(getSalesRepsAnalyticsPerStage.fulfilled, (state, { payload }) => {
      state.analyticsRepsPerStage = payload;
      state.analyticsRepsLoading = false;
    });
    builder.addCase(getSalesRepsAnalyticsPerStage.rejected, (state) => {
      state.analyticsRepsLoading= false;
    });

    builder.addCase(getBidTypes.pending, (state) => {
      state.management.bidTypesLoading = true;
    });
    builder.addCase(getBidTypes.fulfilled, (state, { payload }) => {
      state.management.bidTypes = payload;
      state.management.bidTypesLoading = false;
    });
    builder.addCase(getBidTypes.rejected, (state) => {
      state.management.bidTypesLoading = false;
    });

    builder.addCase(getSalesServiceTypes.pending, (state) => {
      state.management.serviceTypesLoading = true;
    });
    builder.addCase(getSalesServiceTypes.fulfilled, (state, { payload }) => {
      state.management.serviceTypes = payload;
      state.management.serviceTypesLoading = false;
    });
    builder.addCase(getSalesServiceTypes.rejected, (state) => {
      state.management.serviceTypesLoading = false;
    });

    builder.addCase(getSalesDivisions.pending, (state) => {
      state.management.divisionsLoading = true;
    });
    builder.addCase(getSalesDivisions.fulfilled, (state, { payload }) => {
      state.management.divisions = payload;
      state.management.divisionsLoading = false;
    });
    builder.addCase(getSalesDivisions.rejected, (state) => {
      state.management.divisionsLoading = false;
    });

    builder.addCase(getSalesMilestones.pending, (state) => {
      state.management.milestonesLoading = true;
    });
    builder.addCase(getSalesMilestones.fulfilled, (state, { payload }) => {
      state.management.milestones = payload;
      state.management.milestonesLoading = false;
    });
    builder.addCase(getSalesMilestones.rejected, (state) => {
      state.management.milestonesLoading = false;
    });

    builder.addCase(getSaleNotes.pending, (state) => {
      state.notes.loading = true;
      state.notes.error = '';
    });
    builder.addCase(getSaleNotes.fulfilled, (state, { payload }) => {
      state.notes.notes = payload;
      state.notes.loading = false;
      state.notes.error = '';
    });
    builder.addCase(getSaleNotes.rejected, (state) => {
      state.notes.loading = false;
      state.notes.error = 'Error getting Notes.';
    });

    builder.addCase(getSalesMilestonesTasks.pending, (state) => {
      state.sale.milestonesTasksLoading = true;
    });
    builder.addCase(getSalesMilestonesTasks.fulfilled, (state, { payload }) => {
      state.sale.milestonesTasks = payload;
      state.sale.milestonesTasksLoading = false;
    });
    builder.addCase(getSalesMilestonesTasks.rejected, (state) => {
      state.sale.milestonesTasksLoading = false;
    });

    builder.addCase(getSalesTasks.pending, (state) => {
      state.sale.saleTasksLoading = true;
    });
    builder.addCase(getSalesTasks.fulfilled, (state, { payload }) => {
      state.sale.saleTasks = payload.data;
      state.sale.totalTasksPages = payload.meta.last_page;
      state.sale.saleTasksLoading = false;
    });
    builder.addCase(getSalesTasks.rejected, (state) => {
      state.sale.saleTasksLoading = false;
    });
  },
});

export const {
  setSelectedQuote,
  setUpdateError,
  setSelectedAnalyticsRep,
  setIsLoadedAnalytics,
  setIsLoadedUserAnalytics,
  resetCurrentPage,
  setCurrentPage,
  setTasksTab,
  setTasksPage,
} = salesSlice.actions;

export default salesSlice.reducer;