import React, { useState } from "react";
import Toast from "../toast/toast";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Checkbox,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import API from "API";
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { applicantSelector } from 'store/applicants/selectors';
import { useParams } from 'react-router-dom';
import { getApplicant } from 'store/applicants/thunk';

export default function Checklist() {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const applicant = useAppSelector(applicantSelector);

  const token = JSON.parse(localStorage.getItem('token'));

  const [openNewApplicantList, setOpenNewApplicantList] = useState(false);
  const [openPreScreeningList, setOpenPreScreeningList] = useState(false);
  const [openInterviewList, setOpenInterviewList] = useState(false);
  const [openUSGDocList, setOpenUSGDocList] = useState(false);
  const [openSecurityList, setOpenSecurityList] = useState(false);
  const [openFinalizeList, setOpenFinalizeList] = useState(false);
  const [openToast, setOpenToast] = useState(false);

  const editApplication = async (field, newInput) => {
    await fetch(
      `${API.endpointURL}/api/applicants/${applicant.application.id}`,
      {
        method: "PATCH",
        body: JSON.stringify({
          [field]: newInput,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => response.json())
      // TODO: Add error handlers
      .then((data) => {
        if (data["errors"]) {
          //   this.setState({ errors: data["errors"] });
        } else {
          dispatch(getApplicant({ applicantId: id }));
        }
      });
    //   .catch((err) => {
    //     this.setState({ errors: err.messages });
    //   });
  };

  const buildTaskCheckbox = (taskField, description, disabled = false) => {
    let taskCompletionDate = applicant.application[taskField];
    return (
      <Typography
        sx={{
          fontFamily: "Korolev Medium",
          marginLeft: "8px",
          marginRight: "8px",
          textAlign: "left",
        }}
      >
        {disabled === true ? (
          <Checkbox
            checked={taskCompletionDate}
            disabled
            sx={{
              color: "#EFEFEF",
              "&:hover": { backgroundColor: "transparent" },
              "&.Mui-checked": { color: "#00A5CF" },
            }}
          />
        ) : (
          <Checkbox
            checked={taskCompletionDate}
            sx={{
              color: "#EFEFEF",
              "&:hover": { backgroundColor: "transparent" },
              "&.Mui-checked": { color: "#00A5CF" },
            }}
            onClick={() => {
              if (taskCompletionDate) {
                editApplication(taskField, null);
              } else {
                editApplication(taskField, new Date().toISOString());
              }
            }}
          />
        )}
        {taskCompletionDate
          ? `${description} on ${new Date(taskCompletionDate).toLocaleString()}`
          : `${description}`}
      </Typography>
    );
  };

  const buildNewApplicantMenu = () => {
    return (
      <Box sx={{ marginTop: 1 }}>
        {buildTaskCheckbox("resume_review_date", "Reviewed Resume")}
      </Box>
    );
  };

  const buildPreScreeningMenu = () => {
    return (
      <Box sx={{ marginTop: 1 }}>
        {buildTaskCheckbox(
          "screening_materials_sent",
          "Password Reset / Pre Screen",
          true
        )}
        {buildTaskCheckbox("background_check_date", "Pre Screen Confirmed")}
        {buildTaskCheckbox(
          "pre_screen_button_2",
          "Pre Screen Second Button",
          true
        )}
        {buildTaskCheckbox(
          "pre_screen_button_2_confirm",
          "Pre Screen Second Button Confirmed"
        )}
      </Box>
    );
  };

  const buildInterviewMenu = () => {
    return (
      <Box sx={{ marginTop: 1 }}>
        {buildTaskCheckbox(
          "interview_button_1",
          "Interview First Button Sent",
          true
        )}
        {buildTaskCheckbox(
          "interview_button_1_confirm",
          "Interview First Button Confirm"
        )}
        {buildTaskCheckbox(
          "interview_button_2",
          "Interview Second Button Sent",
          true
        )}
        {buildTaskCheckbox(
          "interview_button_2_confirm",
          "Interview Second Button Confirm"
        )}
        {buildTaskCheckbox("interview_date", "Interview Date Determined")}
        {buildTaskCheckbox("interview_status", "Interview Conducted", true)}
      </Box>
    );
  };

  const buildUSGDocMenu = () => {
    return (
      <Box sx={{ marginTop: 1 }}>
        {buildTaskCheckbox("eqip_sent", "USG First Button", true)}
        {buildTaskCheckbox(
          "usg_documents_button_1_confirm",
          "USG First Button Confirm"
        )}
        {buildTaskCheckbox("bi_packet_sent", "USG Second Button", true)}
        {buildTaskCheckbox(
          "usg_documents_button_2_confirm",
          "USG Second Button Confirm"
        )}
        {buildTaskCheckbox(
          "bi_packet_submitted",
          "Security Package Submitted to USG",
          true
        )}
        {buildTaskCheckbox("eqip_invite_sent", "USG eQIP Invite Sent", true)}
        {buildTaskCheckbox(
          "usg_corrections_1",
          "USG Correction Notice 1",
          true
        )}
        {buildTaskCheckbox(
          "usg_corrections_2",
          "USG Correction Notice 2",
          true
        )}
        {buildTaskCheckbox(
          "usg_corrections_3",
          "USG Correction Notice 3",
          true
        )}
        {buildTaskCheckbox(
          "usg_corrections_4",
          "USG Correction Notice 4",
          true
        )}
        {buildTaskCheckbox(
          "usg_corrections_5",
          "USG Correction Notice 5",
          true
        )}
        {buildTaskCheckbox("eqip_completed", "eQip Done", true)}
        {buildTaskCheckbox(
          "usg_corrections_completed_1",
          "eQIP Corrections Complete 1",
          true
        )}
        {buildTaskCheckbox(
          "usg_corrections_completed_2",
          "eQIP Corrections Complete 2",
          true
        )}
        {buildTaskCheckbox(
          "usg_corrections_completed_3",
          "eQIP Corrections Complete 3",
          true
        )}
        {buildTaskCheckbox(
          "usg_corrections_completed_4",
          "eQIP Corrections Complete 4",
          true
        )}
        {buildTaskCheckbox(
          "fingerprints_date_completed",
          "Fingerprints Submitted",
          true
        )}
        {buildTaskCheckbox("finger_prints_type", "Fingerprints Type", true)}
        {/* {buildTaskCheckbox("eqip_sent", "Sent Security File 01")}
        {buildTaskCheckbox("bi_packet_sent", "Sent Security Packet")} */}
      </Box>
    );
  };

  const buildSecurityMenu = () => {
    return (
      <Box sx={{ marginTop: 1 }}>
        {buildTaskCheckbox(
          "security_button_1",
          "Finalize First Button Sent",
          true
        )}
        {buildTaskCheckbox(
          "security_button_1_confirm",
          "Finalize First Button Confirm"
        )}
        {buildTaskCheckbox(
          "security_button_2",
          "Finalize Second Button Sent",
          true
        )}
        {buildTaskCheckbox(
          "security_button_2_confirm",
          "Finalize Second Button Confirm"
        )}
        {buildTaskCheckbox(
          "bi_packet_submitted",
          "Reviewed Security Documents"
        )}
        {buildTaskCheckbox("review_security", "Reviewed Security Award")}
        {/* TODO: security award date selection
        {buildTaskCheckbox('review_security', 'Review Security Award')} */}
      </Box>
    );
  };

  const buildFinalizeMenu = () => {
    return (
      <Box sx={{ marginTop: 1 }}>
        {buildTaskCheckbox(
          "finalize_button_1",
          "Interview First Button Sent",
          true
        )}
        {buildTaskCheckbox(
          "finalize_button_1_confirm",
          "Interview First Button Confirm"
        )}
        {buildTaskCheckbox(
          "finalize_button_2",
          "Interview Second Button Sent",
          true
        )}
        {buildTaskCheckbox(
          "finalize_button_2_confirm",
          "Interview Second Button Confirm"
        )}
        {buildTaskCheckbox("sent_to_hr", "Hired")}
      </Box>
    );
  };

  return (
    <React.Fragment>
      <List
        sx={{
          border: 0,
          color: "white",
          overflow: "auto",
          marginBottom: 1.5,
          padding: 0,
          width: "100%",
        }}
      >
        <ListItemButton
          onClick={() => setOpenNewApplicantList(!openNewApplicantList)}
          sx={
            openNewApplicantList
              ? {
                  backgroundColor: "transparent",
                  color: "#00A5CF",
                  paddingBottom: 0,
                  paddingTop: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#EFEFEF",
                  },
                }
              : {
                  backgroundColor: "transparent",
                  color: "#EFEFEF",
                  paddingBottom: 0,
                  paddingTop: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#00A5CF",
                  },
                }
          }
        >
          {openNewApplicantList ? <ExpandLess /> : <ExpandMore />}
          <ListItemText
            primary="New Applicant"
            sx={{ marginLeft: "8px" }}
            primaryTypographyProps={{
              fontFamily: "Korolev Medium",
              fontSize: "1.15rem",
            }}
          />
        </ListItemButton>
        <Collapse
          in={openNewApplicantList}
          timeout="auto"
          onExit={() => setOpenNewApplicantList(false)}
          unmountOnExit
        >
          <List component="div" disablePadding sx={{ border: 0 }}>
            {buildNewApplicantMenu()}
          </List>
        </Collapse>
      </List>
      <List
        sx={{
          border: 0,
          color: "white",
          overflow: "auto",
          marginBottom: 1.5,
          padding: 0,
          width: "100%",
        }}
      >
        <ListItemButton
          onClick={() => setOpenPreScreeningList(!openPreScreeningList)}
          sx={
            openPreScreeningList
              ? {
                  backgroundColor: "transparent",
                  color: "#00A5CF",
                  paddingBottom: 0,
                  paddingTop: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#EFEFEF",
                  },
                }
              : {
                  backgroundColor: "transparent",
                  color: "#EFEFEF",
                  paddingBottom: 0,
                  paddingTop: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#00A5CF",
                  },
                }
          }
        >
          {openPreScreeningList ? <ExpandLess /> : <ExpandMore />}
          <ListItemText
            primary="Pre-Screening"
            sx={{ marginLeft: "8px" }}
            primaryTypographyProps={{
              fontFamily: "Korolev Medium",
              fontSize: "1.15rem",
            }}
          />
        </ListItemButton>
        <Collapse
          in={openPreScreeningList}
          timeout="auto"
          onExit={() => setOpenPreScreeningList(false)}
          unmountOnExit
        >
          <List component="div" disablePadding sx={{ border: 0 }}>
            {buildPreScreeningMenu()}
          </List>
        </Collapse>
      </List>
      <List
        sx={{
          border: 0,
          color: "white",
          overflow: "auto",
          marginBottom: 1.5,
          padding: 0,
          width: "100%",
        }}
      >
        <ListItemButton
          onClick={() => setOpenInterviewList(!openInterviewList)}
          sx={
            openInterviewList
              ? {
                  backgroundColor: "transparent",
                  color: "#00A5CF",
                  paddingBottom: 0,
                  paddingTop: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#EFEFEF",
                  },
                }
              : {
                  backgroundColor: "transparent",
                  color: "#EFEFEF",
                  paddingBottom: 0,
                  paddingTop: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#00A5CF",
                  },
                }
          }
        >
          {openInterviewList ? <ExpandLess /> : <ExpandMore />}
          <ListItemText
            primary="Interview"
            sx={{ marginLeft: "8px" }}
            primaryTypographyProps={{
              fontFamily: "Korolev Medium",
              fontSize: "1.15rem",
            }}
          />
        </ListItemButton>
        <Collapse
          in={openInterviewList}
          timeout="auto"
          onExit={() => setOpenInterviewList(false)}
          unmountOnExit
        >
          <List component="div" disablePadding sx={{ border: 0 }}>
            {buildInterviewMenu()}
          </List>
        </Collapse>
      </List>
      <List
        sx={{
          border: 0,
          color: "white",
          overflow: "auto",
          marginBottom: 1.5,
          padding: 0,
          width: "100%",
        }}
      >
        <ListItemButton
          onClick={() => setOpenUSGDocList(!openUSGDocList)}
          sx={
            openUSGDocList
              ? {
                  backgroundColor: "transparent",
                  color: "#00A5CF",
                  paddingBottom: 0,
                  paddingTop: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#EFEFEF",
                  },
                }
              : {
                  backgroundColor: "transparent",
                  color: "#EFEFEF",
                  paddingBottom: 0,
                  paddingTop: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#00A5CF",
                  },
                }
          }
        >
          {openUSGDocList ? <ExpandLess /> : <ExpandMore />}
          <ListItemText
            primary="USG Documents"
            sx={{ marginLeft: "8px" }}
            primaryTypographyProps={{
              fontFamily: "Korolev Medium",
              fontSize: "1.15rem",
            }}
          />
        </ListItemButton>
        <Collapse
          in={openUSGDocList}
          timeout="auto"
          onExit={() => setOpenUSGDocList(false)}
          unmountOnExit
        >
          <List component="div" disablePadding sx={{ border: 0 }}>
            {buildUSGDocMenu()}
          </List>
        </Collapse>
      </List>
      <List
        sx={{
          border: 0,
          color: "white",
          overflow: "auto",
          marginBottom: 1.5,
          padding: 0,
          width: "100%",
        }}
      >
        <ListItemButton
          onClick={() => setOpenSecurityList(!openSecurityList)}
          sx={
            openSecurityList
              ? {
                  backgroundColor: "transparent",
                  color: "#00A5CF",
                  paddingBottom: 0,
                  paddingTop: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#EFEFEF",
                  },
                }
              : {
                  backgroundColor: "transparent",
                  color: "#EFEFEF",
                  paddingBottom: 0,
                  paddingTop: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#00A5CF",
                  },
                }
          }
        >
          {openSecurityList ? <ExpandLess /> : <ExpandMore />}
          <ListItemText
            primary="Security"
            sx={{ marginLeft: "8px" }}
            primaryTypographyProps={{
              fontFamily: "Korolev Medium",
              fontSize: "1.15rem",
            }}
          />
        </ListItemButton>
        <Collapse
          in={openSecurityList}
          timeout="auto"
          onExit={() => setOpenSecurityList(false)}
          unmountOnExit
        >
          <List component="div" disablePadding sx={{ border: 0 }}>
            {buildSecurityMenu()}
          </List>
        </Collapse>
      </List>
      <List
        sx={{
          border: 0,
          color: "white",
          overflow: "auto",
          marginBottom: 1.5,
          padding: 0,
          width: "100%",
        }}
      >
        <ListItemButton
          onClick={() => setOpenFinalizeList(!openFinalizeList)}
          sx={
            openFinalizeList
              ? {
                  backgroundColor: "transparent",
                  color: "#00A5CF",
                  paddingBottom: 0,
                  paddingTop: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#EFEFEF",
                  },
                }
              : {
                  backgroundColor: "transparent",
                  color: "#EFEFEF",
                  paddingBottom: 0,
                  paddingTop: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#00A5CF",
                  },
                }
          }
        >
          {openFinalizeList ? <ExpandLess /> : <ExpandMore />}
          <ListItemText
            primary="Finalize"
            sx={{ marginLeft: "8px" }}
            primaryTypographyProps={{
              fontFamily: "Korolev Medium",
              fontSize: "1.15rem",
            }}
          />
        </ListItemButton>
        <Collapse
          in={openFinalizeList}
          timeout="auto"
          onExit={() => setOpenFinalizeList(false)}
          unmountOnExit
        >
          <List component="div" disablePadding sx={{ border: 0 }}>
            {buildFinalizeMenu()}
          </List>
        </Collapse>
      </List>
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message="Applicant updated"
        />
      )}
    </React.Fragment>
  );
}
