import React, { useEffect, useState } from 'react';

import 'css/vendor-dropbox.scss';

import ProjectsViewFiles from 'components/project-portal/projects-view-files';
import ProjectFileSidebar from 'components/project-portal/project-file-sidebar';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import Toast from 'components/toast/toast';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { projectFilesSelector } from 'store/projects/selectors';
import { getProjectFiles, getProjectFilesByCategory, getProjectsFileFolders } from 'store/projects/thunk';

const initial = {
  type_name: '',
  category: '',
};

const ProjectsDropBoxPageContent = ({ id }) => {
  const dispatch = useAppDispatch();
  const {
    projectFiles,
    getProjectFilesLoading,
    getProjectFilesError,
    projectFilesByCategory,
    getProjectFilesByCategoryLoading,
    getProjectFilesByCategoryError,
    projectFileFolders,
    getProjectFileFoldersLoading,
    getProjectFileFoldersError
  } = useAppSelector(projectFilesSelector);

  const [view, setView] = useState('All');
  const [paramToReq, setParamToReq] = useState(initial);
  const [openDissociateToast, setOpenDissociateToast] = useState(false);
  const [message, setMessage] = useState('');
  
  // Get all files associated with a project
  const getProjectFilesInfo = () => {
    dispatch(getProjectFiles({ projectId: id }));
  };

  // Get project files sorted by categories
  const getFilteredFiles = () => {
    dispatch(getProjectFilesByCategory({ projectId: id, typeName: paramToReq.type_name, category: paramToReq.category }));
  };

  // Get folders
  const getFileFoldersForProject = () => {
    dispatch(getProjectsFileFolders({ projectId: id }))
  };

  // Get filtered files when requested
  useEffect(() => {
    if (paramToReq.type_name && paramToReq.category) {
      getFilteredFiles();
    }
  }, [paramToReq]);

  const retrieveFunction = () => {
    getProjectFilesInfo();
    if (paramToReq.type_name && paramToReq.category) {
      return getFilteredFiles();
    }
  };

  return (
    <>
      <h1 className="content-header">Dropbox</h1>
      <div className="dropbox-content-container">
        <ProjectFileSidebar
          setParamToReq={setParamToReq}
          view={view}
          setView={setView}
          projectFiles={projectFiles}
          projectFileFolders={projectFileFolders}
          loadingFolders={getProjectFileFoldersLoading}
          errorLoadingFolders={getProjectFileFoldersError}
        />
        {getProjectFilesError.length > 0 && (
          <p style={{ padding: '40px 20px' }}>{getProjectFilesError}</p>
        )}
        {getProjectFilesByCategoryError.length > 0 && (
          <p style={{ padding: '40px 20px' }}>{getProjectFilesByCategoryError}</p>
        )}
        {getProjectFilesLoading || getProjectFilesByCategoryLoading ? (
          <div className="contracts-loading-spinner">
            <ProgressSpinnerNavy />
          </div>
        ) : (
          <ProjectsViewFiles
            setParamToReq={setParamToReq}
            view={view}
            paramToReq={paramToReq}
            setView={setView}
            vendorFiles={projectFiles}
            retrieveFiles={retrieveFunction}
            retrieveAllFiles={getProjectFilesInfo}
            specificFiles={projectFilesByCategory}
            refreshFileFolders={getFileFoldersForProject}
            setOpenDissociateToast={setOpenDissociateToast}
            setMessage={setMessage}
          />
        )}
        {openDissociateToast && (
          <Toast
            open={openDissociateToast}
            onClose={() => setOpenDissociateToast(false)}
            message={message}
          />
        )}
      </div>
    </>
  );
};


export default ProjectsDropBoxPageContent;

