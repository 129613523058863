import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTab: 0,
  currentPage: 1,
  recruiterFilterId: '',
  companyFilter: '',
  siteFilterId: '',
  regionFilterId: '',
  contractFilterId: '',
  textAlertFilter: false,
  psqAlertFilter: false,
  currentSortOption: 'created_at',
  currentSortOrder: 'desc',
  selectedTags: [],
  filteredTags: [],
  selectedGroups: [],
  searchFilter: 'last_name',
  searchInput: '',
  currentFilterView: 'filters',
  isSearchedApplicants: false,
};

export const staffingSelectedOptionsSlice = createSlice({
  name: 'selectedOptions',
  initialState,
  reducers: {
    setCurrentTab(state, { payload }) {
      state.currentTab = payload;
      state.currentPage = 1;
    },
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    setCurrentSortOption (state, { payload }) {
      state.currentSortOption = payload;
      state.currentPage = 1;
    },
    setCurrentSortOrder (state, { payload }) {
      state.currentSortOrder = payload;
      state.currentPage = 1;
    },
    setSelectedTags (state, { payload }) {
      if (state.selectedTags.includes(payload) && !Array.isArray(payload)) {
        state.selectedTags = state.selectedTags.filter((item) => item !== payload);
      } else if (Array.isArray(payload)) {
        state.selectedTags = payload;
      } else {
        state.selectedTags = [...state.selectedTags, payload];
      }
      state.currentPage = 1;
    },
    clearTags(state) {
      state.selectedTags = [];
      state.selectedGroups = [];
    },
    setSelectedGroups (state, { payload }) {
      if (state.selectedGroups.includes(payload)) {
        state.selectedGroups = state.selectedGroups.filter((item) => item !== payload);
      } else {
        state.selectedGroups = [...state.selectedGroups, payload];
      }
      state.currentPage = 1;
    },
    setRecruiterFilterId (state, { payload}) {
      state.recruiterFilterId = payload;
      state.currentPage = 1;
      state.isSearchedApplicants = false;
    },
    toggleTextAlertFilter (state) {
      state.textAlertFilter = !state.textAlertFilter;
      state.currentPage = 1;
      state.isSearchedApplicants = false;
    },
    togglePsqAlertFilter (state) {
      state.psqAlertFilter = !state.psqAlertFilter;
      state.currentPage = 1;
      state.isSearchedApplicants = false;
    },
    setContractFilterId (state, { payload }) {
      if (state.contractFilterId === payload) {
        state.contractFilterId = '';
      } else {
        state.contractFilterId = payload;
      }
      state.currentPage = 1;
      state.isSearchedApplicants = false;
    },
    setRegionFilterId (state, { payload }) {
      if (state.regionFilterId === payload) {
        state.regionFilterId = '';
      } else {
        state.regionFilterId = payload;
      }
      state.currentPage = 1;
      state.isSearchedApplicants = false;
    },
    setSiteFilterId (state, { payload }) {
      if (state.siteFilterId === payload) {
        state.siteFilterId = '';
      } else {
        state.siteFilterId = payload;
      }
      state.currentPage = 1;
      state.isSearchedApplicants = false;
    },
    setCompanyFilter (state, { payload }) {
      if (state.companyFilter === payload) {
        state.companyFilter = '';
      } else {
        state.companyFilter = payload;
      }
      state.currentPage = 1;
      state.isSearchedApplicants = false;
    },
    setSearchFilter (state, { payload }) {
      state.searchFilter = payload;
    },
    setSearchInput (state, { payload }) {
      state.searchInput = payload;
    },
    clearSearchInput(state) {
      state.searchInput = '';
    },
    setCurrentFilterView(state, { payload }) {
      state.currentFilterView = payload;
    },
    setFilteredTags(state, { payload }) {
      state.filteredTags = payload;
    },
    setIsSearchedApplicants(state, { payload }) {
      state.isSearchedApplicants = payload;
    }
  },
});

export const {
  setCurrentTab,
  setCurrentSortOption,
  setCurrentSortOrder,
  setSelectedTags,
  setSelectedGroups,
  setRecruiterFilterId,
  toggleTextAlertFilter,
  togglePsqAlertFilter,
  setContractFilterId,
  setRegionFilterId,
  setSiteFilterId,
  setCompanyFilter,
  setSearchFilter,
  setSearchInput,
  clearSearchInput,
  setCurrentFilterView,
  setFilteredTags,
  setCurrentPage,
  setIsSearchedApplicants,
  clearTags,
} = staffingSelectedOptionsSlice.actions;

export default staffingSelectedOptionsSlice.reducer;
