import "../../css/modal.scss";
import React, { useState } from "react";
import { Button } from "@mui/material";
import CustomDialogRedesign from "./custom-dialog-redesign";
import API from "API";

export default function SyncApplicantRecruiters(props) {
  const { contract, handleClose, open, setOpenToast } = props;

  const token = JSON.parse(localStorage.getItem("token"));
  const [error, setError] = useState(null);

  const syncRecruiters = async () => {
    try {
      const response = await fetch(
        `${API.endpointURL}/api/staffing/contracts/sync_recruiters`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
          body: JSON.stringify({
            contract_id: contract.id,
          }),
        }
      );
      if (!response.ok) {
        const errorResponse = await response.json();
        setError("There was an issue syncing your contract. Please try again later.");
        throw new Error(errorResponse.error || "Failed to sync");
      }
      await response.json();
        setOpenToast(true);
        handleClose();
      } catch (error) {
        setError("There was an issue syncing your contract. Please try again later.");
    }
  };

  const editForm = () => {
    return (
      <React.Fragment key="edit-user-detail-form">
        <div
          style={{
            fontFamily: "Korolev Medium",
            marginTop: "25px",
            lineHeight: "20px",
            textAlign: "left",
          }}
        >
          Are you sure you want to sync all contract applicant assignments to the current recruiters list?
          <br />
          <br />
          This will remove any assignments to recruiters no longer tied to this contract and move the applicant to a new recruiter.
        </div>
        {error && <div className="add-user-error">{error}</div>}
        <div className="button-container">
          <Button
            variant="contained"
            className="add-user-button"
            sx={{
              backgroundColor: "#62AB37",
              border: "1px solid #62AB37",
              borderRadius: "10px",
              color: "#EFEFEF",
              fontFamily: "MagistralCondW08-Medium",
              fontSize: "18px",
              minHeight: "25px",
              padding: "13px 20px",
              textTransform: "capitalize",
              width: "175px",
              "&:hover": {
                backgroundColor: "transparent",
                border: "1px solid #62AB37",
                boxShadow: "none",
                color: "#62AB37",
              },
            }}
            onClick={() => syncRecruiters()}
          >
            Sync
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <CustomDialogRedesign
      colorPalette="green"
      dialogContent={editForm()}
      onClose={handleClose}
      open={open}
      title={"SYNC"}
      titleHighlight={"APPLICANT RECRUITERS"}
    />
  );
}
