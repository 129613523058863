import React from 'react';

export const SalesConnectionsModalSix = ({
  setStep,
  setIsAdding,
  isAdding,
  sale,
  project,
}) => {
  const handleClick = () => {
    setStep(1);
  }

  return (
    <div className="connection__modal-content">
      <p
        className="
          connection__modal-title
          connection__modal-title--margin
          connection__modal-title--bold
        "
      >
        How do you want to add a new connection ?
      </p>
      <div className="connection__modal-selectors" style={{ marginBottom: '153.01px' }}>
        <button
          className="connection__modal-select"
          onClick={() => setIsAdding(true)}
        >
          <div className="connection__modal-select--box">
            {isAdding && (
              <div className="connection__modal-select--circle" />
            )}
          </div>
          <p className="connection__modal-title connection__modal-title--bold">
            Link.
            {' '}
            <span className="connection__modal-title--italic">
              Establish a relationship between the current
              {sale ? ' sale ' : project ? ' project ' : ' connection '}
              and another existing connection.
            </span>
          </p>
        </button>
        <button
          className="connection__modal-select"
          onClick={() => setIsAdding(false)}
        >
          <div className="connection__modal-select--box">
            {!isAdding && (
              <div className="connection__modal-select--circle" />
            )}
          </div>
          <p className="connection__modal-title connection__modal-title--bold">
            Create.
            {' '}
            <span className="connection__modal-title--italic">
              Create a new connection in the system.
            </span>
          </p>
        </button>
      </div>

      <div className="connection__modal-buttons">
        <button
          className="connection__modal-button"
          onClick={handleClick}
        >
          Continue
        </button>
      </div>
    </div>
  )
};