import React, { useEffect, useState } from "react";
import AccountingDetailView from "../accounting-dashboard/accounting-detail-view";
import API from "API";
import { ExecutiveContainer } from 'components/employee-portal/executive-analytics/executive-container';

export const ExecutiveAnalytics = () => {

  const token = JSON.parse(localStorage.getItem("token"));

  const [accountingView, setAccountingView] = useState("overview");
  const [lastUpdate, setLastUpdate] = useState(null);

  useEffect(() => {
    getLastUpdate();
  }, []);

  const getLastUpdate = () => {
    fetch(`${API.endpointURL}/api/get_ledger_time`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setLastUpdate(json);
      })
      .catch((error) => console.log(error, "Error fetching update"));
  };

  return (
    <>
      {accountingView === "overview" && (
        <ExecutiveContainer
          setAccountingView={setAccountingView}
          getLastUpdate={getLastUpdate}
        />
      )}
      {accountingView === "income statement" && (
        <AccountingDetailView
          detailType={accountingView}
          setAccountingView={setAccountingView}
          lastUpdate={lastUpdate}
        />
      )}
      {accountingView === "balance sheet" && (
        <AccountingDetailView
          detailType={accountingView}
          setAccountingView={setAccountingView}
          lastUpdate={lastUpdate}
        />
      )}
    </>
  );
};

