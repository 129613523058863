import { createSlice } from '@reduxjs/toolkit';
import {
  editApplicant,
  getApplicant,
  getApplicantsByStage,
  getApplicantsQuestions,
  getArchivedApplicants,
  getSearchedApplicants,
} from 'store/applicants/thunk.js';

const initialState = {
  applicantState: null,
  applicantLoading: false,
  applicantError: '',
  applicantEditStatus: '',

  currentApplicant: null,

  applicantsByStage: [],
  totalApplicantsByStage: 0,
  totalApplicantsByStagePages: 0,
  applicantByStageError: '',

  archivedApplicants: [],
  archivedApplicantsPage: 1,
  totalArchivedApplicantsPages: 1,
  selectedArchivedApplicant: null,
  archivedApplicantsError: '',

  editedApplicantId: null,
  isLoading: false,
  error: '',

  index: null,
  
  applicantQuestions: {
    initialQuestions: [],
    loading: false,
    error: '',
  }
};

const applicantsSlice = createSlice({
  name: 'applicantsSlice',
  initialState,
  reducers: {
    setCurrentApplicant(state, { payload }) {
      state.currentApplicant = payload;
    },
    setEditedApplicantId(state, { payload }) {
      state.editedApplicantId = payload;
    },
    setApplicantState(state, { payload }) {
      state.applicantState = payload;
    },
    setSelectedArchivedApplicant(state, { payload }) {
      state.selectedArchivedApplicant = payload;
    },
    resetApplicantStore(state) {
      state.applicantState = null;
      state.applicantLoading = false;
      state.applicantError = '';
      state.applicantEditStatus = '';
      state.currentApplicant = null;
      state.applicantsByStage = [];
      state.totalApplicantsByStage = 0;
      state.totalApplicantsByStagePages = 0;
      state.applicantByStageError = '';
      state.archivedApplicants = [];
      state.archivedApplicantsError = '';
      state.archivedApplicantsPage = 1;
      state.isLoading = false;
      state.error = '';
      state.index = null;
      state.selectedArchivedApplicant = null;
    },
    setApplicantEditStatus (state, { payload }) {
      state.applicantEditStatus = payload;
    },
    setArchivedApplicantsPage (state, { payload }) {
      state.archivedApplicantsPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getArchivedApplicants.pending, (state) => {
      state.archivedApplicantsError = '';
      state.isLoading = true;
    });
    builder.addCase(getArchivedApplicants.fulfilled, (state, action) => {
      state.archivedApplicants = action.payload.data;
      state.totalApplicantsByStagePages = action.payload.meta.last_page;
      state.archivedApplicantsError = '';
      state.isLoading = false;
    });
    builder.addCase(getArchivedApplicants.rejected, (state, action) => {
      state.archivedApplicantsError = action.error.message;
      state.isLoading = false;
    });
    
    builder.addCase(getApplicantsByStage.pending, (state) => {
      state.applicantByStageError = '';
      state.isLoading = true;
      state.applicantEditStatus = '';
    });
    builder.addCase(getApplicantsByStage.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.applicantsByStage = action.payload.data;
        state.totalApplicantsByStage = action.payload.meta.total;
        state.totalApplicantsByStagePages = action.payload.meta.last_page;
        state.applicantByStageError = '';
        state.isLoading = false;
        state.editedApplicantId = null;
      }
    });
    builder.addCase(getApplicantsByStage.rejected, (state, action) => {
      state.applicantByStageError = action.error.message;
      state.isLoading = false;
      state.editedApplicantId = null;
    });
    
    builder.addCase(getSearchedApplicants.pending, (state) => {
      state.applicantByStageError = '';
      state.isLoading = true;
    });
    builder.addCase(getSearchedApplicants.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.applicantsByStage = action.payload.data;
        state.totalApplicantsByStage = action.payload.meta.total;
        state.totalApplicantsByStagePages = action.payload.meta.last_page;
        state.applicantByStageError = '';
        state.isLoading = false;
        state.editedApplicantId = null;
      }
    });
    builder.addCase(getSearchedApplicants.rejected, (state, action) => {
      state.applicantByStageError = action.error.message;
      state.isLoading = false;
      state.editedApplicantId = null;
    });
    
    builder.addCase(getApplicant.pending, (state) => {
      state.applicantLoading = true;
      state.applicantError = '';
    });
    builder.addCase(getApplicant.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.applicantState = action.payload.data;
        state.applicantLoading = false;
      }
    });
    builder.addCase(getApplicant.rejected, (state) => {
      state.applicantLoading = false;
      state.applicantError = 'Error fetching data.';
    });
    
    builder.addCase(editApplicant.pending, (state) => {
      state.applicantLoading = true;
      state.applicantError = '';
      state.applicantEditStatus = 'pending';
    });
    builder.addCase(editApplicant.fulfilled, (state) => {
      state.applicantLoading = false;
      state.applicantError = '';
      state.applicantEditStatus = 'fulfilled';
    });
    builder.addCase(editApplicant.rejected, (state) => {
      state.applicantLoading = false;
      state.applicantError = 'Error editing applicant.';
      state.applicantEditStatus = 'rejected';
    });
    
    builder.addCase(getApplicantsQuestions.pending, (state) => {
      state.applicantQuestions.loading = true;
      state.applicantQuestions.error = '';
    });
    builder.addCase(getApplicantsQuestions.fulfilled, (state, { payload }) => {
      state.applicantQuestions.loading = false;
      state.applicantQuestions.error = '';
      state.applicantQuestions.initialQuestions = [...payload].sort((a, b) => a.application_question?.sort_order - b.application_question?.sort_order);
    });
    builder.addCase(getApplicantsQuestions.rejected, (state) => {
      state.applicantQuestions.loading = false;
      state.applicantQuestions.initialQuestions = [];
      state.applicantQuestions.error = 'Error getting applicant questions.';
    });
  },
});

export const {
  setCurrentApplicant,
  setApplicantState,
  resetApplicantStore,
  setEditedApplicantId,
  setSelectedArchivedApplicant,
  setApplicantEditStatus,
  setArchivedApplicantsPage,
} = applicantsSlice.actions;

export default applicantsSlice.reducer;