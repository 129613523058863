import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ContractsTagsItem } from 'components/contracts/contracts-tags/contracts-tags-item';
import { IconButton, Tooltip } from '@mui/material';
import addButton from 'assets/addButton.png';
import { UpsertTagModal } from 'components/contracts/contracts-tags/upsertTag-modal';
import { useAppSelector } from 'hooks/reduxHooks';
import { allTagsLoadingSelector, tagsSelector } from 'store/tags/selectors';
import ProgressSpinner from 'components/progress/progress-spinner';
import { useLocation } from 'react-router-dom';
export default function ContractsTagsTable() {
  const allTags = useAppSelector(tagsSelector);
  const loading = useAppSelector(allTagsLoadingSelector);

  const [sortOption, setSortOption] = useState('group');
  const [sortOrder, setSortOrder] = useState('ascending');
  const [openCreateTag, setOpenCreateTag] = useState(false);
  const [sortedTags, setSortedTags] = useState([]);

  const { pathname } = useLocation();

  useEffect(() => {
    if (Array.isArray(allTags)) {
      const sortTags = [...allTags].sort((a, b) => {
        if (sortOption === 'name') {
          return sortOrder === 'ascending' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        } else if (sortOption === 'group') {
          return sortOrder === 'ascending' ? a.group?.title.localeCompare(b.group?.title) : b.group?.title.localeCompare(a.group?.title);
        } else if (sortOption === 'type') {
          return sortOrder === 'ascending' ? a.type?.title.localeCompare(b.type?.title) : b.type?.title.localeCompare(a.type?.title);
        }
        return 0;
      });

      setSortedTags(sortTags);
    } else {
      setSortedTags([]);
    }
  }, [sortOption, sortOrder, allTags]);


  const toggleSort = (option) => {
    if (option === sortOption) {
      setSortOrder(sortOrder === 'ascending' ? 'descending' : 'ascending');
    } else {
      setSortOption(option);
      setSortOrder('ascending');
    }
  };

  return (
    <table className="spreadsheet tableLayout">
      <thead
        className={classNames('tableLayout__th-div', {
          'tableLayout__th-div--darkGreen': pathname.includes('applicant'),
        })}
        >
        <tr
          className="contractsTags__tags-listItem-header"
        >
          <td />
          <td className={`contractsTags__tags-listItem-text`}>
            Group
            <i
              style={{ height: '10px', fontSize: '10px !important' }}
              className={classNames( 'fa-solid arrow', {
                'fa-arrow-up up':
                  sortOption === 'group' &&
                  sortOrder === 'ascending',
                'fa-arrow-down down':
                  sortOption !== 'group' ||
                  sortOrder !== 'ascending',
                'blue-arrow': pathname.includes('applicant'),
              })}
              onClick={() => toggleSort('group')}
            />
          </td>
          <td className={`contractsTags__tags-listItem-text`}>
            Name
            <i
              className={classNames( 'fa-solid arrow', {
                'fa-arrow-up up':
                  sortOption === 'name' &&
                  sortOrder === 'ascending',
                'fa-arrow-down down':
                  sortOption !== 'name' ||
                  sortOrder !== 'ascending',
                'blue-arrow': pathname.includes('applicant'),
              })}
              onClick={() => toggleSort('name')}
            />
          </td>
          <td className="contractsTags__tags-listItem-text">
            Type
            <i
              className={classNames( 'fa-solid arrow', {
                'fa-arrow-up up':
                  sortOption === 'type' &&
                  sortOrder === 'ascending',
                'fa-arrow-down down':
                  sortOption !== 'type' ||
                  sortOrder !== 'ascending',
                'blue-arrow': pathname.includes('applicant'),
              })}
              onClick={() => toggleSort('type')}
            />
          </td>
          <td className="contractsTags__tags-listItem-text">Description</td>
          <td>
            <Tooltip
              title={'Add Tag'}
            >
              <IconButton
                edge="start"
                aria-label="edit-division"
                sx={{
                  padding: 0,
                  margin: 0,
                  color: '#3e453d',
                  justifyContent: 'flex-start',
                }}
                onClick={() => setOpenCreateTag(true)}
              >
                <img
                  src={addButton}
                  width="20px"
                  height="auto"
                  alt="edit-button"
                  style={{
                    '&:hover': { backgroundColor: 'transparent' },
                  }}
                />
              </IconButton>
            </Tooltip>
          </td>
          <td className="contractsTags__tags-listItem-text" />
        </tr>
      </thead>
      {loading ? (
        <tbody
          style={{
            height: 'calc(100vh - 355px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <tr>
            <td>
              <ProgressSpinner/>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
        {sortedTags.length > 0 && (
          sortedTags?.map((tag) => (
            <ContractsTagsItem
              tag={tag}
              key={tag.id}
            />
          ))
        )}
        {sortedTags.length === 0 && (
          <tr className="contractsTags__tags-listItem-text"><td style={{ padding: '20px' }}>No Tags</td></tr>
        )}
        </tbody>
      )}
      {openCreateTag && (
        <UpsertTagModal open={openCreateTag} setOpen={setOpenCreateTag} />
      )}
    </table>
  );
}
