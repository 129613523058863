import "../App.scss";
import "../css/staffing.scss";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Pagination } from "@mui/material";
import AppBar from "../components/app-bar/updated-app-bar-component";
import BouncedUsersTable from "../components/table/bounced-users-table";
import UsersTable from "../components/table/users-table";
import EditUserModal from "components/modal/edit-user-modal";
import UsersSidebar from "components/sidebar/users-sidebar";
import UsersTabs from "components/tabs/users-tabs";
import Toast from "components/toast/toast";
import API from "API";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EFEFEF80",
    },
  },
});

const tabValuesToRoles = {
  0: "admin",
  1: "accounting",
  2: "staffing",
  3: "recruiter",
  4: "applicant",
  5: "Bounced Users",
  6: "sales",
  7: "project manager",
  8: "project coordinator",
};

export default function Users() {
  const token = JSON.parse(localStorage.getItem("token"));
  const locationState = useLocation().state;

  const [receivedState, setReceivedState] = useState(locationState);
  const [currentPage, setCurrentPage] = useState(
    receivedState?.currentPage ? receivedState.currentPage : 1
  );
  const [totalPages, setTotalPages] = useState(
    receivedState?.totalPages ? receivedState.totalPages : null
  );
  const [allUsers, setAllUsers] = useState(
    receivedState?.allUsers ? receivedState.allUsers : []
  );
  const [bouncedUsers, setBouncedUsers] = useState(
    receivedState?.bouncedUsers ? receivedState.bouncedUsers : []
  );
  const [searchedUsers, setSearchedUsers] = useState(
    receivedState?.searchedUsers ? receivedState.searchedUsers : null
  );
  const [searchField, setSearchField] = useState(
    receivedState?.searchField ? receivedState.searchField : ""
  );
  const [searchFilter, setSearchFilter] = useState(
    receivedState?.searchFilter ? receivedState.searchFilter : "last_name"
  );
  const [tabValue, setTabValue] = useState(
    receivedState?.tabValue ? receivedState.tabValue : 0
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [openEditUserToast, setOpenEditUserToast] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  
  const usersState = {
    page: "Users",
    currentPage,
    totalPages,
    allUsers,
    bouncedUsers,
    searchedUsers,
    searchField,
    searchFilter,
    selectedUser,
    tabValue,
  };

  useEffect(() => {
    if (!receivedState) {
      if (tabValue === 5) {
        getBouncedUsers();
      } else {
        getUsers();
      }
    } else {
      setReceivedState(null);
      window.history.replaceState({}, "");
    }
  }, []);

  useEffect(() => {
    if (!receivedState) {
      if (tabValue === 5) {
        setCurrentPage(1);
        getBouncedUsers();
      } else {
        setCurrentPage(1);
        getUsers();
      }
    }
  }, [tabValue]);

  useEffect(() => {
    if (!receivedState) {
      if (tabValue === 5) {
        getBouncedUsers();
      } else {
        getUsers();
      }
    }
  }, [currentPage]);

  const getUsers = async () => {
    setLoadingUsers(true);
    await fetch(
      `${API.endpointURL}/api/filter/users?role=${tabValuesToRoles[tabValue]}&page=${currentPage}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        setTotalPages(json.meta.last_page);
        setAllUsers(json.data);
        setLoadingUsers(false);
      })
      .catch(() => setLoadingUsers(false));
  };

  const getBouncedUsers = async () => {
    setLoadingUsers(true);
    await fetch(`${API.endpointURL}/api/bounced_users?page=${currentPage}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setTotalPages(json.meta.last_page);
        setBouncedUsers(json.data);
        setLoadingUsers(false);
      })
      .catch(() => setLoadingUsers(false));
  };

  const submitSearch = () => {
    setLoadingUsers(true);
    fetch(
      `${API.endpointURL}/api/filter/users?role=${tabValuesToRoles[tabValue]}&${searchFilter}=${searchField}&page=${currentPage}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.data) {
          setLoadingUsers(false);
          setTotalPages(json.meta.last_page);
          setSearchedUsers(json.data);
        }
      })
      .catch(() => setLoadingUsers(false));
  };

  const handleTabChange = (value) => {
    setTabValue(value);
    setSearchField("");
    setSearchedUsers(null);
  };

  const buildTable = () => {
    let component = null;
    switch (tabValue) {
      case 0: // Fallthrough
      case 1: // Fallthrough
      case 2: // Fallthrough
      case 3:
      case 6:
      case 7:
      case 8:
        if (searchedUsers) {
          component = (
            <UsersTable
              loading={loadingUsers}
              users={searchedUsers}
              setOpenEditUserModal={setOpenEditUserModal}
              setSelectedUser={setSelectedUser}
              tabValue={tabValue}
              usersState={usersState}
            />
          );
        } else {
          component = (
            <UsersTable
              loading={loadingUsers}
              users={allUsers}
              setOpenEditUserModal={setOpenEditUserModal}
              setSelectedUser={setSelectedUser}
              tabValue={tabValue}
              usersState={usersState}
            />
          );
        }
        break;
      case 4:
        if (searchedUsers) {
          component = (
            <UsersTable
              loading={loadingUsers}
              users={searchedUsers}
              setOpenEditUserModal={setOpenEditUserModal}
              setSelectedUser={setSelectedUser}
              tabValue={tabValue}
              usersState={usersState}
            />
          );
        } else {
          component = (
            <UsersTable
              loading={loadingUsers}
              users={allUsers}
              setOpenEditUserModal={setOpenEditUserModal}
              setSelectedUser={setSelectedUser}
              tabValue={tabValue}
              usersState={usersState}
            />
          );
        }
        break;
      case 5:
        component = (
          <BouncedUsersTable
            loading={loadingUsers}
            users={bouncedUsers}
            allUsers={bouncedUsers}
            updateUsersList={() => getBouncedUsers()}
            tabValue={tabValue}
          />
        );
        break;
      default:
        break;
    }
    return component;
  };

  return (
    <>
      <AppBar colorPalette="green" menu={true} title="Users" />
      <div className="staffing-page">
        <div style={{ width: "23%" }}>
          <UsersSidebar
            loading={loadingUsers}
            allUsers={allUsers}
            bouncedUsers={bouncedUsers}
            getUsers={getUsers}
            searchedUsers={searchedUsers}
            searchApplicants={submitSearch}
            searchField={searchField}
            searchFilter={searchFilter}
            setCurrentPage={setCurrentPage}
            setSearchField={setSearchField}
            setSearchFilter={setSearchFilter}
            setSearchedUsers={setSearchedUsers}
            tabValue={tabValue}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-start",
            padding: "0 25px 25px",
            width: "77%",
          }}
        >
          <UsersTabs
            currentTab={tabValue}
            handleTabChange={handleTabChange}
          />
          <ThemeProvider theme={theme}>
            {buildTable()}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "12px",
                width: "100%",
              }}
            >
              <Pagination
                count={totalPages || 1}
                page={currentPage}
                onChange={(e, value) => setCurrentPage(value)}
                color="primary"
                size="large"
              />
            </div>
          </ThemeProvider>
        </div>
      </div>
      {openEditUserModal && (
        <EditUserModal
          allUsers={allUsers}
          handleClose={() => setOpenEditUserModal(false)}
          open={openEditUserModal}
          setOpenEditToast={setOpenEditUserToast}
          user={selectedUser}
          updateUsersList={() => getUsers()}
        />
      )}
      {openEditUserToast && (
        <Toast
          open={openEditUserToast}
          onClose={() => setOpenEditUserToast(false)}
          message="User updated"
        />
      )}
    </>
  );
}
