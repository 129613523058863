import React, { useEffect, useState } from "react";
import "../../css/updated-app-bar-component.scss";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImageIcon from "assets/rd-logo.png";
import { resetApplicantStore } from 'store/applicants/slice';
import { resetUiStore } from 'store/ui/slice';
import { useAppDispatch } from 'hooks/reduxHooks';

export default function AppBar(props) {
  const { colorPalette, menu, title } = props;

  const [openMenu, setOpenMenu] = useState(false);
  const [user, setUser] = useState(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    if (loggedInUser) {
      setUser(loggedInUser);
    }
  }, []);

  let navigate = useNavigate();

  const navigateDashboard = () => {
    if (user.role === "applicant") {
      navigate(`/applicant-portal/${user.id}`);
    } else {
      navigate(`/employee-portal/${user.id}`);
    }
  };

  const navigateContractDetails = () => {
    navigate("/contracts");
  };

  const navigateVendors = () => {
    navigate("/accounting");
  };

  const navigateUsers = () => {
    navigate("/users");
  };

  const navigateStaffing = () => {
    navigate("/staffing");
  };

  const navigateSales = () => {
    navigate("/sales");
  };

  const navigateProjects = () => {
    navigate("/projects");
  };

  const handleLogout = () => {
    localStorage.clear();
    setUser(null);
    navigate("/login");

    dispatch(resetApplicantStore());
    dispatch(resetUiStore());
  };

  return (
    <div className="nav">
      <div className="nav__logo">
        <img src={ImageIcon} alt="" />
        <div className={`nav__title-${colorPalette}`}>
          {title}
        </div>
      </div>
      {menu && (
        <div className="nav__actions">
          <div className="nav__dropdown">
            <div className={openMenu ? "nav__dropdown-card-open" : "nav__dropdown-card-closed"}>
              <div className="nav__dropdown-title">{openMenu && "Menu"}</div>
              <div className="icon-button">
                <KeyboardArrowDownIcon onClick={() => setOpenMenu(!openMenu)} />
              </div>
            </div>
            {openMenu &&
              <div className="nav__dropdown-items">
                <div className="nav__dropdown-item" onClick={navigateDashboard}>
                  {`My ${user.role === "applicant" ? "Portal" : "Dashboard"}`}
                </div>
                {(user.role === "admin" || user.role === "staffing" || user.role === "accounting") &&
                  <div className="nav__dropdown-item" onClick={navigateUsers}>
                    Users
                  </div>
                }
                {(user.role === "admin" || user.role === "staffing") &&
                  <div className="nav__dropdown-item" onClick={navigateContractDetails}>
                    Staffing Contracts
                  </div>
                }
                {(user.role === "admin" || user.role === "recruiter" || user.role === "staffing") &&
                  <div className="nav__dropdown-item" onClick={navigateStaffing}>
                    Staffing
                  </div>
                }
                {(user.role === "admin" || user.role === "accounting") &&
                  <div className="nav__dropdown-item" onClick={navigateVendors}>
                    Accounting
                  </div>
                }
                {(user.role === "admin" || user.role === "sales") &&
                  <div className="nav__dropdown-item" onClick={navigateSales}>
                    Sales
                  </div>
                }
                {(user.role === "admin" || user.role === "sales" || user.role === "project coordinator" || user.role === "project manager") &&
                  <div className="nav__dropdown-item" onClick={navigateProjects}>
                    Projects
                  </div>
                }
                <div className="nav__dropdown-item" onClick={() => handleLogout()}>
                  Logout
                </div>
              </div>
            }
          </div>
        </div>
      )}
    </div>
  );
}
