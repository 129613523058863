import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

export const Search = styled("div")(() => ({
  alignItems: "center",
  backgroundColor: "#efefef40",
  border: "1px solid #efefef80",
  borderRadius: "6px",
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 12px",
  width: "100%",
  "&:hover": {
    backgroundColor: "#efefef80",
  },
}));

export const SearchIconWrapper = styled("div")(() => ({
  alignItems: "center",
  color: "#efefef",
  display: "flex",
  paddingLeft: "8px",
  pointerEvents: "none",
}));

 export const StyledInputBase = styled(InputBase)(() => ({
  fontSize: "15px",
  height: "15px",
  lineHeight: "15px",
  width: "75%",
}));
