import React, { useEffect, useState } from 'react';
import API from 'API';
import Table from 'components/generic/table';
import TableItem from "components/generic/table-item";
import { VendorGroupingsExpanded } from 'components/vendors/vendor-groupings-expanded';
import NewBaseModal from 'components/modal/new-base-modal';
import { VendorCreateGrouping } from 'components/vendors/vendor-create-grouping';

import 'css/vendor-grouping.scss';
import {ProgressSpinnerBlue} from "../progress/progress-spinner-blue";
import { useLocation } from 'react-router-dom';

export const VendorGroupings = () => {
  const { pathname } = useLocation();
  const token = JSON.parse(localStorage.getItem("token"));
  const [groupings, setGroupings] = useState([]);
  const [openCrateGrouping, setOpenCreateGrouping] = useState(false);
  const [loading, setLoading] = useState(false);

  const getGroupings = async () => {
    setLoading(true);
    await fetch(`${API.endpointURL}/api/get_groupings`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setGroupings(
          json.data
            .map((item) => ({
              ...item,
              account_numbers: item.account_numbers.sort(),
            }))
            .sort((a, b) => {
              const accountA = a.account_numbers[0];
              const accountB = b.account_numbers[0];
              if (!accountA && !accountB) {
                return a.title.localeCompare(b.title);
              } else if (!accountA) {
                return 1;
              } else if (!accountB) {
                return -1;
              }

              const diff = Number(accountA) - Number(accountB);
              if (diff !== 0) {
                return diff;
              }

              return a.title.localeCompare(b.title);
            })
        );
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e, 'Error getting groupings');
      });
  };

  useEffect(() => {
    getGroupings();
  }, [])

  return (
    <div className="vendor-grouping">
      <h1 className="content-header">Set groupings</h1>
      <button
        onClick={() => setOpenCreateGrouping(true)}
        className="vendor-grouping__create"
      >
        Create grouping
      </button>
      <div className="vendor-grouping__content">
        {loading && !groupings.length ? (
          <div className="spinner-fullScreen">
            <ProgressSpinnerBlue />
          </div>
        ) : (
          <Table pathname={pathname} columns={["Grouping name", "Account numbers"]}>
            {groupings.length > 0 && groupings.map((item) => (
              <TableItem
                key={item.id}
                panel={
                  <VendorGroupingsExpanded
                    grouping={item}
                    getGroupings={getGroupings}
                  />
                }
              >
                <p>{item.title}</p>
                <div>
                  {item.account_numbers.map((number, index) => (
                    <span key={`${index} ${number} 1`}>
                    {index + 1 < item.account_numbers.length ? number + ', ' : number}
                  </span>
                  ))}
                </div>
              </TableItem>
            ))}
          </Table>
        )}
      </div>
      {openCrateGrouping && (
        <NewBaseModal
          dialogContent={
            <VendorCreateGrouping
              groupings={groupings}
              getGroupings={getGroupings}
              close={() => setOpenCreateGrouping(false)}
            />
          }
          onClose={() => setOpenCreateGrouping(false)}
          open={openCrateGrouping}
          title={"CREATE A NEW GROUPING"}
          colorPalette="blue"
        />
      )}
    </div>
  );
};