import '../../css/applicant-profile.scss';
import React, {  useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';
import ImageIconEmail from 'assets/email.png';
import ImageIconHexagon from 'assets/user-hexagon.png';
import ImageIconPhone from 'assets/phone.png';
import EditApplicantRecruiter from 'components/modal/edit-applicant-recruiter';
import SidebarTabs from 'components/tabs/sidebar-tabs';
import Toast from 'components/toast/toast';
import { formatPhoneNumber } from 'shared/formatting';
import { BITMOTIVE_USER_ID, RICK_USER_ID, STAFFING_CONTACTS } from 'constants/constants';
import EditIcon from '@mui/icons-material/Edit';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { contractSelector } from 'store/contracts/selectors';
import { subdivisionSelector } from 'store/subdivisions/selectors';
import { divisionSelector } from 'store/divisions/selectors';
import { currentApplicantSelector } from 'store/applicants/selectors';
import { setEditedApplicantId } from 'store/applicants/slice';
import { useGetApplicantsByStage } from 'hooks/getApplicantsByStage';
import { IconButton } from '@mui/material';
import notAllowedMessages from 'assets/bxs-message-rounded-check.svg';
import allowedMessages from 'assets/bxs-message-rounded-x.svg';
import { useGetSearchedApplicants } from 'hooks/getSearchedApplicants';

import { isSearchedApplicantsSelector } from 'store/staffingSelectedOptions/selectors';

export default function SidebarPreview(props) {
  const {
    currentTab,
    getApplicant,
    portalType,
    setCurrentTab,
    user,
    view,
  } = props;

  const dispatch = useAppDispatch();
  const getApplicants = useGetApplicantsByStage();
  const getSearchedApplicants = useGetSearchedApplicants();

  const isSearchedApplicants = useAppSelector(isSearchedApplicantsSelector);
  const applicantContract = useAppSelector(contractSelector);
  const applicantDivision = useAppSelector(divisionSelector);
  const applicantSubdivision = useAppSelector(subdivisionSelector);
  const currentApplicant = useAppSelector(currentApplicantSelector);

  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  
  const navigate = useNavigate();
  const [openEditRecruiterModal, setOpenEditRecruiterModal] = useState(false);
  const [openEditRecruiterToast, setOpenEditRecruiterToast] = useState(false);

  const setTabs = () => {
    if (loggedInUser.id === RICK_USER_ID || loggedInUser.id === BITMOTIVE_USER_ID) {
      return ['personal information', 'alerts', 'executive analytics', 'my email templates'];
    } else if (loggedInUser.role === 'admin' || loggedInUser.role === 'staffing' || loggedInUser.role === 'recruiter') {
        return ['personal information', 'alerts', 'analytics', 'my email templates'];
    } else {
      return ['personal information', 'alerts'];
    }
  }
  
  const renderBackButton = () => {
    return (
      <button
        className="back-button blue-hover"
        onClick={() => {
          navigate(-1);
          if (currentApplicant?.id) {
            dispatch(setEditedApplicantId(currentApplicant.id));
            dispatch(getApplicant({ applicantId: currentApplicant?.id }));
            if (isSearchedApplicants) {
              getSearchedApplicants()
            } else {
              getApplicants();
            }
          }
        }}
      >
        
        <ArrowBackIcon
          sx={{
            marginRight: '5px',
            height: '15px',
            width: 'auto',
          }}
        />
        Go back
      </button>
    );
  };
  
  const formatLocation = (userCity, userState) => {
    const formattedCity = userCity ? userCity.charAt(0).toUpperCase() + userCity.slice(1) : '';
    const formattedState = userState ? userState.charAt(0).toUpperCase() + userState.slice(1) : '';
    
    return userCity && userState
      ? `${formattedCity}${formattedState ? ', ' + formattedState : ''}`
      : userCity
        ? formattedCity
        : userState
          ? formattedState
          : 'City, State Not Provided';
  }
  
  const renderUserInfo = () => {
    return (
      <>
        {view === 'admin'
          && portalType === 'applicant'
          && renderBackButton()}
        <div
          className={
            view === 'admin' && portalType === 'applicant'
              ? 'user-info-container back-button-padding'
              : 'user-info-container'
          }
        >
          <div style={{ alignItems: 'center' }} className="user-info-header">
            <img className="user-icon" src={ImageIconHexagon} alt="icon"/>
            <div>
              <p className="user-info-name">
                {user.first_name} {' '}
                {user.middle_name ? user.middle_name : ''} {' '}
                {user.last_name}
              </p>
              {portalType === 'employee' && (
                <p className="user-info-small-header">{user.role}</p>
              )}
              {portalType === 'applicant' && (
                <div className="user-info-small-header">
                  {formatLocation(user?.city, user?.state)}
                </div>
              )}
              <p className="user-info-small-header">
                RDM #{user.id}
              </p>
            </div>
          </div>
          <div className="row" style={{ alignItems: 'baseline' }}>
            <img className="icon" src={ImageIconPhone} alt="phone"/>
            <div className="section-data" style={{ display: 'flex', alignItems: 'baseline' }}>
              {user.phone ?
                <>
                  {formatPhoneNumber(user.phone)}
                  <Tooltip title={user.can_receive_messages ? 'Allowed' : 'Not Allowed'}>
                    <IconButton
                      sx={{
                        padding: 0,
                        ml: 1,
                        mt: 1,
                        '&:hover': { backgroundColor: 'transparent', cursor: 'inherit' },
                      }}
                    >
                      <img
                        src={user.can_receive_messages ? allowedMessages : notAllowedMessages}
                        className="profile-icon--messages"
                        alt={user.can_receive_messages ? 'Allowed' : 'Not Allowed'}
                      />
                    </IconButton>
                  </Tooltip>
                </> : "No phone number"}
            </div>
          </div>
          
          <div className="row" style={{ alignItems: 'baseline' }}>
            <img className="icon" src={ImageIconEmail} alt="email"/>
            <p
              className="section-data"
              style={{ maxWidth: 'calc(100% - 42px)', wordWrap: 'break-word' }}
            >
              {user.email ? user.email : 'No email address'}
            </p>
          </div>
          {portalType === 'applicant' && view === 'admin' && (
            <div className="row" style={{ alignItems: 'baseline' }}>
              <Tooltip title="Assigned Recruiter">
                <i className="fa-solid fa-user-group fa-icon"></i>
              </Tooltip>
              <div className="section-data">
                {user.application?.recruiter_data
                  ? `${user.application.recruiter_data.first_name} ${user.application.recruiter_data.last_name}`
                  : 'No recruiter'}
                <EditIcon
                  onClick={() => setOpenEditRecruiterModal(true)}
                  sx={{
                    color: '#fdfefe',
                    height: '20px',
                    ml: '10px',
                    '&:hover': { color: '#00A5CF' },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  };
  
  const renderApplicantPlacement = () => {
    return (
      <div className="section-container">
        <div className="section-header">Placement</div>
        <div className="section-data">
          {applicantContract?.title
            ? `Contract: ${applicantContract.title}`
            : 'Contract: None'}
        </div>
        <div className="section-data">
          {applicantDivision?.title
            ? `Division: ${applicantDivision.title}`
            : 'Division: None'}
        </div>
        <div className="section-data">
          {applicantSubdivision?.title
            ? `Subdivision: ${applicantSubdivision.title}`
            : 'Subdivision: None'}
        </div>
        <div className="section-data">
          {user?.company ? `Company: ${user.company}` : 'Company: None'}
        </div>
      </div>
    );
  };
  
  const renderApplicantStatus = () => {
    return (
      <div className="section-container">
        <div className="section-header">Status</div>
        <div className="section-data">
          USG Status:{' '}
          {user.application?.status ? `${user.application.status}` : ''}
        </div>
        <div className="section-data">
          RD Status:{' '}
          {user.application?.rd_status
            ? `${user.application.rd_status}`
            : ''}
        </div>
        <div className="section-data">
          Candidate Status:{' '}
          {user.application?.candidate_status
            ? `${user.application.candidate_status}`
            : ''}
        </div>
        <div className="section-data">
          Next Step:{' '}
          {user.application?.next_step
            ? `${user.application.next_step}`
            : ''}
        </div>
        <div className="section-data">Completed: [Action]</div>
      </div>
    );
  };
  
  const renderApplicantStatusForApplicantView = () => {
    return (
      <div className="section-container">
        <div className="section-header">Status</div>
        <div className="section-data">
          Candidate Status:{' '}
          {user.application?.candidate_status
            ? `${user.application.candidate_status}`
            : ''}
        </div>
        <div className="section-data">
          Next Step:{' '}
          {user.application?.next_step
            ? `${user.application.next_step}`
            : ''}
        </div>
      </div>
    );
  };
  
  const renderContactUsInfo = () => {
    const defaultRecruiter = {
      first_name: 'RDM',
      last_name: 'Staffing',
      phone: '9452343440',
      email: 'RDMStaffing@responsivedeployment.com',
    };
    
    const allowedDomain = /^[a-zA-Z0-9._%+-]+@responsivedeployment\.[a-zA-Z]{2,}$/;
    const recruiterData = user?.application?.recruiter_data;
    const recruiterContact = STAFFING_CONTACTS.find((contact) => contact.email === recruiterData?.email);
    const recruiter = recruiterData ? recruiterData : defaultRecruiter;

    return (
      <div className="section-container">
        <div className="section-header" style={{ marginBottom: '15px' }}>Contact Us</div>
        <div className="row" style={{ alignItems: 'baseline' }}>
          <Tooltip title="Assigned Recruiter">
            <i className="fa-solid fa-user-group fa-icon"></i>
          </Tooltip>
          <p className="section-data">{`${recruiter.first_name} ${recruiter.last_name}`}</p>
        </div>
        <div className="row" style={{ alignItems: 'baseline' }}>
          <img className="icon" src={ImageIconPhone} alt="phone"/>
          <p className="section-data">
            {recruiterContact ? formatPhoneNumber(recruiterContact?.phone) : formatPhoneNumber(recruiter?.phone)}
          </p>
        </div>
        <div className="row" style={{ alignItems: 'baseline' }}>
          <img className="icon" src={ImageIconEmail} alt="email"/>
          <a className="section-data" href={`mailto:${recruiter.email}`}>
            {allowedDomain.test(recruiter.email) ? recruiter.email : defaultRecruiter.email}
          </a>
        </div>
      </div>
    );
  };
  
  
  return (
    <div className="portal-preview">
      {renderUserInfo()}
      {view === 'admin' && portalType === 'applicant' && (
        <>
          {renderApplicantPlacement()}
          {renderApplicantStatus()}
          <div className="tabs-container">
            <SidebarTabs
              colorPalette={'blue'}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              tabs={[
                'personal information',
                'checklist',
                // 'hiring timeline',
                'drop box',
                'notes',
                'tags',
              ]}
            />
          </div>
        </>
      )}
      {view === 'applicant' && portalType === 'applicant' && (
        <>
          {renderContactUsInfo()}
          {renderApplicantStatusForApplicantView()}
        </>
      )}
      {portalType === 'employee' && (
        <div className="tabs-container">
          <SidebarTabs
            colorPalette={'blue'}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabs={setTabs()}
          />
        </div>
      )}
      {view === 'applicant' && portalType === 'applicant' && (
        <>
          <div className="tabs-container">
            <SidebarTabs
              colorPalette={'blue'}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              tabs={['personal information', 'drop box']}
            />
          </div>
        </>
      )}
      {openEditRecruiterModal && (
        <EditApplicantRecruiter
          applicant={user}
          getApplicant={getApplicant}
          handleClose={() => setOpenEditRecruiterModal(false)}
          open={openEditRecruiterModal}
          setOpenToast={setOpenEditRecruiterToast}
        />
      )}
      {openEditRecruiterToast && (
        <Toast
          open={openEditRecruiterToast}
          onClose={() => setOpenEditRecruiterToast(false)}
          message="Recruiter updated"
        />
      )}
    </div>
  );
}
