import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { TextField } from '@mui/material';

import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { projectFilesSelector } from 'store/projects/selectors';
import { createProjectsFileType } from 'store/projects/thunk';

export const ProjectsNewTypeModal = ({ refreshFileFolders, close, setOpenToast, setMessage }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { createProjectFileTypeLoading, createProjectFileTypeError } = useAppSelector(projectFilesSelector);
  
  const [newFileType, setFileTypeName] = useState('');
  const [error, setError] = useState('');
  
  const addNewFileType = async () => {
    if (newFileType === '') {
      setError('New type name field can\'t be empty');
    } else {
      const formData = new FormData();
      
      formData.append('type_name', newFileType);
      formData.append('project_id', id);
      formData.append('category', 'custom');
      
      dispatch(createProjectsFileType({ body: formData }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setError('');
            setFileTypeName('');
            setMessage('Custom type successfully added!');
            setOpenToast(true);
            close();
            refreshFileFolders();
          }
        });
    }
  };
  
  return (
    <div
      style={{
        maxHeight: '100%',
        overflow: 'auto',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
        }}
      >
        <div className="contract-form-set-full">
          <div className="form-row-label">New Folder Name</div>
          <TextField
            value={newFileType}
            onChange={(e) => {
              setError('');
              setFileTypeName(e.target.value);
            }}
            fullWidth
            sx={{
              backgroundColor: '#fff',
              border: '1px solid #a5af96',
              borderRadius: '8px',
              padding: '6px 10px 5px',
              marginBottom: 0,
            }}
            placeholder="Enter New Folder Name..."
            multiline={true}
            rows={1}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </div>
      
      </div>
      {error && (
        <div
          style={{
            color: '#a20601',
            fontFamily: 'Korolev Medium',
            fontSize: '14px',
            marginBottom: '16px',
            textAlign: 'left',
          }}
        >
          {error}
        </div>
      )}
      <div
        style={{ margin: 0 }}
        className="contractsTags__tags__modal-buttonContainer"
      >
        {createProjectFileTypeLoading && (
          <div
            className="contractsTags__tags__modal-loading"
            style={{ top: '-35px', right: '220px' }}
          >
            <ProgressSpinnerDarkNavy/>
          </div>
        )}
        {createProjectFileTypeError.length > 0 && (
          <p className="contractsTags__tags__modal-error--add">{createProjectFileTypeError}</p>
        )}
        <button
          className="connection__modal-button"
          onClick={addNewFileType}
          disabled={createProjectFileTypeLoading}
        >
          Add Custom Folder
        </button>
      </div>
    </div>
  );
};
