import React, { useEffect, useState } from 'react';

import 'css/analytics.scss';
import 'css/pages.scss';
import 'css/metrics.scss';

import FillBarSolid from 'components/widgets/fill-bar-solid';
import ProgressSpinner from 'components/progress/progress-spinner';

import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  allContractsMetricsSelector,
  contractsErrorSelector,
  contractsSelector,
} from 'store/contracts/selectors';
import { recruitersErrorSelector, recruitersSelector } from 'store/recruiters/selectors';
import { setAllContractsAnalytics } from 'store/contracts/slice';
import classNames from 'classnames';

export default function StaffingAllContracts(props) {
  const {
    currentContract,
    currentRecruiter,
    setCurrentContract,
    setCurrentRecruiter,
    color,
  } = props;
  
  const dispatch = useAppDispatch();
  const contracts = useAppSelector(contractsSelector);
  const recruiters = useAppSelector(recruitersSelector);
  const errorContracts = useAppSelector(contractsErrorSelector);
  const errorRecruiter = useAppSelector(recruitersErrorSelector);
  const {
    totalHiresPerDay,
    totalHiresPerWeek,
    totalHiresPerMonth,
    totalHiresAllTime,
    totalActiveContracts,
    totalCompletedContracts,
    totalArchivedContracts,
    totalApplicantsDispositioned,
    totalPendingApplicants,
    hiresPer,
    loading,
    error,
  } = useAppSelector(allContractsMetricsSelector);
  
  const [totalApplicants, setTotalApplicants] = useState(0);
  
  useEffect(() => {
    const updatedTotal =
      totalHiresAllTime +
      totalApplicantsDispositioned +
      totalPendingApplicants;
    setTotalApplicants(updatedTotal);
  }, [totalHiresAllTime, totalApplicantsDispositioned, totalPendingApplicants]);
  
  const contractOptions = () => (
    <>
      <option key="select-contract" value="">Select a Contract...</option>
      {contracts?.map((contract) => (
        <option key={contract.id} value={contract.id}>
          {contract.title}
        </option>
      ))}
    </>
  );
  
  const recruiterOptions = () => (
    <>
      <option key="select-recruiter" value="">Select a Recruiter...</option>
      {recruiters?.map((recruiter) => (
        <option key={recruiter.id} value={recruiter.id}>
          {`${recruiter.first_name} ${recruiter.last_name}`}
        </option>
      ))}
    </>
  );
  
  if (error.length > 0) {
    return (
      <>
        <h1 className="content-header">All Contracts Overview</h1>
        <div className="metrics-content-container">
          <div className="stats-group-header">{error}</div>
        </div>
      </>
    );
  }
  
  return (
    <>
      <h1 className="content-header">All Contracts Overview</h1>
      <div className="metrics-content-container">
        {loading ? (
          <div className="content-loading" style={{ height: 'calc(100% - 140px)' }}>
            {color === 'green' ?  <ProgressSpinner /> : <ProgressSpinnerBlue /> }
          </div>
        ) : (
          <>
            <div className="stats-group-container">
              <div className="stats-group-header">Contracts</div>
              <div className="contract-totals-row">
                <div className="stage-stat-container">
                  <div className="stage-stat-header">Active</div>
                  <div className={`stage-stat-number stage-stat-number--${color}`}>{totalActiveContracts}</div>
                  <div
                    className="stage-stat-unit">{totalActiveContracts && totalActiveContracts === 1 ? 'contract' : 'contracts'}</div>
                </div>
                <div className="stage-stat-container">
                  <div className="stage-stat-header">Completed</div>
                  <div className={`stage-stat-number stage-stat-number--${color}`}>{totalCompletedContracts}</div>
                  <div
                    className="stage-stat-unit">{totalCompletedContracts && totalCompletedContracts === 1 ? 'contract' : 'contracts'}</div>
                </div>
                <div className="stage-stat-container">
                  <div className="stage-stat-header">Archived</div>
                  <div className={`stage-stat-number stage-stat-number--${color}`}>{totalArchivedContracts}</div>
                  <div
                    className="stage-stat-unit">{totalArchivedContracts && totalArchivedContracts === 1 ? 'contract' : 'contracts'}</div>
                </div>
              </div>
            </div>
            <div className="stats-group-container">
              <div className="applicants-hired-per-header">
                Applicants Hired per
                <div className="applicants-hired-per-interval">
                  <select
                    name="timeframe" id="timeframe"
                    className="contract-form-input"
                    defaultValue={totalHiresPerDay.toString()}
                    onChange={(e) => dispatch(setAllContractsAnalytics(e.currentTarget.value))}
                  >
                    <option key={`day-${totalHiresPerDay}`} value={totalHiresPerDay.toString()}>Day</option>
                    <option key={`week-${totalHiresPerWeek}`} value={totalHiresPerWeek.toString()}>Week</option>
                    <option key={`month-${totalHiresPerMonth}`} value={totalHiresPerMonth.toString()}>Month</option>
                    <option key={`all-${totalHiresAllTime}`} value={totalHiresAllTime.toString()}>All Time</option>
                  </select>
                </div>
              </div>
              <div className={`applicants-hired-per-number applicants-hired-per-number--${color}`}>
                {hiresPer.toLocaleString()}
              </div>
            </div>
            <div className="stats-group-container">
              <div className="stats-group-header">Hiring Matrix - All Contracts</div>
              <div className="hiring-matrix-container">
                <div className="matrix-total-container">
                  <div className={`total-applicants-number total-applicants-number--${color}`}>
                    {totalApplicants.toLocaleString()}
                  </div>
                  <p className="stage-stat-header">Total Applicants</p>
                </div>
                <div className="matrix-breakdown-container">
                  <div className="fill-stat-container">
                    <div className="stage-stat-header">Applicants Hired</div>
                    {totalHiresAllTime &&
                      <FillBarSolid color={color} value={totalHiresAllTime} maxValue={totalApplicants}/>}
                  </div>
                  <div className="fill-stat-container">
                    <div className="stage-stat-header">Applicants Dispositioned/Archived</div>
                    {totalApplicantsDispositioned &&
                      <FillBarSolid color={color} value={totalApplicantsDispositioned} maxValue={totalApplicants}/>}
                  </div>
                  <div className="fill-stat-container">
                    <div className="stage-stat-header">Applicants Pending</div>
                    {totalPendingApplicants &&
                      <FillBarSolid color={color} value={totalPendingApplicants} maxValue={totalApplicants}/>}
                  </div>
                </div>
              </div>
            </div>
            {contracts && (
              <div className="stats-group-container">
                <div className="deep-dive-header">
                  Contract Deep Dive
                  <div className="deep-dive-select">
                    <select
                      disabled={contracts.length <= 1}
                      name="contract" id="contract"
                      className="contract-form-input"
                      value={currentContract || 'null'}
                      onChange={(e) => setCurrentContract(e.currentTarget.value)}
                    >
                      {contractOptions()}
                    </select>
                  </div>
                  {contracts.length <= 1 && (
                    <p
                      className={classNames({
                        'error-message': errorContracts.length > 0,
                        'stage-stat-header': errorContracts.length === 0,
                      })}
                      >
                      {errorContracts.length > 0 ? errorContracts : 'No Contracts'}
                    </p>
                  )}
                </div>
              </div>
            )}
            {recruiters && (
              <div className="stats-group-container">
                <div className="deep-dive-header">
                  Recruiter Deep Dive
                  <div className="deep-dive-select">
                    <select
                      name="recruiter" id="recruiter"
                      className="contract-form-input"
                      value={currentRecruiter || 'null'}
                      onChange={(e) => setCurrentRecruiter(e.currentTarget.value)}
                    >
                      {recruiterOptions()}
                    </select>
                  </div>
                  {recruiters.length <= 1 && (
                    <p
                      className={classNames({
                        'error-message': errorContracts.length > 0,
                        'stage-stat-header': errorContracts.length === 0,
                      })}
                    >
                      {errorRecruiter.length > 0 ? errorRecruiter : 'No Recruiters'}
                    </p>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
