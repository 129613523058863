import React from 'react';

export const SalesConnectionsModalOne = ({
  connectionType,
  setConnectionType,
  setStep,
  isCreating,
  isAdding,
}) => {
  const handleClick = () => {
    if (!isAdding) {
      connectionType === 'PERSONAL'
        ? setStep(2)
        : setStep(4);
    } else {
      setStep(7);
    }
  }

  return (
    <div className="connection__modal-content">
      <p
        className="
          connection__modal-title
          connection__modal-title--margin
          connection__modal-title--bold
        "
      >
        What Kind of Connection would you like to make?
      </p>
      <div className="connection__modal-selectors">
        <button
          className="connection__modal-select"
          onClick={() => setConnectionType('PERSONAL')}
        >
          <div className="connection__modal-select--box">
            {connectionType === 'PERSONAL' && (
              <div className="connection__modal-select--circle" />
            )}
          </div>
          <p className="connection__modal-title connection__modal-title--bold">
            Personal Connection.
            {' '}
            <span className="connection__modal-title--italic">
              Use when the connection is a person.
            </span>
          </p>
        </button>
        <button
          className="connection__modal-select"
          onClick={() => setConnectionType('COMPANY')}
        >
          <div className="connection__modal-select--box">
            {connectionType === 'COMPANY' && (
              <div className="connection__modal-select--circle" />
            )}
          </div>
          <p className="connection__modal-title connection__modal-title--bold">
            Company Connection.
            {' '}
            <span className="connection__modal-title--italic">
              Use when the connection is a company or organization.
            </span>
          </p>
        </button>
      </div>

      <div className="connection__modal-buttons">
        {!isCreating && (
          <button
            className="connection__modal-button connection__modal-button--back"
            onClick={() => setStep(6)}
          >
            Back
          </button>
        )}
        <button
          className="connection__modal-button"
          onClick={handleClick}
        >
          Continue
        </button>
      </div>
    </div>
  )
};