import React from 'react';
import CustomDialog from 'components/modal/custom-dialog';
import { Button } from '@mui/material';

export const DeleteModal = ({
  open,
  handleClose,
  title,
  type,
  name,
  handleDelete,
  loading,
  errorMessage,
}) => {

  const dialogContent = () => (
    <div className="delete-modal-content">
      <p>
        Are you sure you want to permanently delete
        <br />
        <span>
          {name}
        </span>
        ?
      </p>
      <div className="delete-modal-buttons">
        <Button
          variant="contained"
          className="add-user-button"
          sx={{
            backgroundColor: "#a20601",
            color: "#EFEFEF",
            fontSize: "18px",
            width: "175px",
            transition: 'all 0.3s',
            "&:hover": { backgroundColor: "#da8683", color: "#3e453d" },
          }}
          onClick={handleDelete}
          disabled={loading}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          className="add-user-button"
          sx={{
            backgroundColor: "#556155",
            color: "#EFEFEF",
            fontSize: "18px",
            width: "175px",
            transition: 'all 0.3s',
            "&:hover": { backgroundColor: "#a5af96", color: "#3e453d" },
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        {errorMessage && (
          <p className="delete-modal-error">
            Error deleting {name}, try again later.
          </p>
        )}
      </div>
    </div>
  );

  return (
    <CustomDialog
      dialogContent={dialogContent()}
      onClose={handleClose}
      open={open}
      title={title}
      titleHighlight={type}
    />
  );
};
 