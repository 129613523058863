import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00A5CF',
    },
  },
});

export const ProgressSpinnerBlue = () => {
  return (
    <ThemeProvider theme={theme}>
      <CircularProgress
        color="primary"
        sx={{ my: 5 }}
      />
    </ThemeProvider>
  );
};