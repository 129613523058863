import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';

import API from 'API';
import ProgressSpinner from 'components/progress/progress-spinner';
import { useAppSelector } from 'hooks/reduxHooks';
import { currentApplicantSelector } from 'store/applicants/selectors';

export default function AlertsModule() {

  const selectedUser = useAppSelector(currentApplicantSelector);
  
  const token = JSON.parse(localStorage.getItem('token'));
  
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState('');
  const [loadingMessages, setLoadingMessages] = useState(false);
  const alertsEndRef = useRef(null);

  useEffect(() => {
    if (selectedUser) {
      retrieveActions(selectedUser.application.id);
    }
  }, []);

  const scrollToBottom = () => {
    alertsEndRef.current?.scrollIntoView({ behavior: "smooth", block: 'end' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [alertsEndRef?.current, messages]);
  
  const retrieveActions = async (id) => {
    setLoadingMessages(true);
    try {
      const response = await fetch(`${API.endpointURL}/api/actions/filter/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      
      if (!response.ok) {
        setError('Error getting alerts.');
        setLoadingMessages(false);
        return;
      }
      
      const json = await response.json();

      if (json.data.length > messages.length) {
        setMessages([...json.data].sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);

          return dateA - dateB;
        }));
      }
      setLoadingMessages(false);
    } catch (error) {
      setError('Error getting alerts.');
      setLoadingMessages(false);
    }
  };
  
  
  const formatDateTime = (timestamp) => {
    const dateTime = new Date(timestamp);
    return dateTime.toLocaleString();
  };
  
  const buildActionHistory = () => {
    let textBubbles = [];
    if (error.length > 0) {
      return (
        <div className="scroll" style={{ padding: '10px', width: '100%' }}>
          {error}
        </div>
      );
    }
    
    if (messages.length === 0) {
      return (
        <Typography
          sx={{
            border: 0,
            color: '#EFEFEF',
            fontFamily: 'Korolev Medium',
            padding: '10px 16px',
            overflowWrap: 'break-word',
            textAlign: 'center'
          }}
        >
          No Alerts!
        </Typography>
      )
    }
    
    messages.length > 0 && messages.forEach((message) => {
      let bubble = <div></div>;
      bubble = (
        <Box
          key={message.id}
          display="flex"
          flexDirection="column"
          sx={{ marginBottom: '8px', textAlign: 'left', width: 'auto' }}
        >
          <Typography
            sx={{
              bgcolor: '#EFEFEF40',
              border: 0,
              borderRadius: '6px',
              color: '#EFEFEF',
              fontFamily: 'Korolev Medium',
              padding: '10px 16px',
              overflowWrap: 'break-word',
            }}
          >
            {message.body}
          </Typography>
          <Typography
            sx={{
              color: '#EFEFEF',
              fontSize: '9.5px',
              marginTop: '2px',
              paddingRight: '8px',
              textAlign: 'right',
            }}
          >
            {formatDateTime(message.created_at)}
          </Typography>
        </Box>
      );
      textBubbles.push(bubble);
    });
    
    return (
      <div
        className="scroll"
        style={{
          boxShadow: 0,
          height: '275px',
          overflowY: 'scroll',
          padding: '10px',
          width: '100%',
        }}
      >
        {textBubbles}

        <div ref={alertsEndRef} />
      </div>
    );
  };
  

  if (loadingMessages) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ProgressSpinner/>
      </div>
    )
  }
  
  return (
    <>
      {messages && buildActionHistory()}
    </>
  );
}
