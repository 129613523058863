import React from 'react';


export default function VendorsSidebar(props) {
  const { currentView, setCurrentView } = props;

  return (
    <div className="sidebar">
      <h1>Option Panel</h1>
      <div
        className={currentView === "Vendors" ? "sidebar__item-selected" : "sidebar__item"}
        onClick={() => setCurrentView("Vendors")}
      >
        <p>Vendors</p>
        <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
      </div>
      <div
        className={currentView === "Customers" ? "sidebar__item-selected" : "sidebar__item"}
        onClick={() => setCurrentView("Customers")}
      >
        <p>Customers</p>
        <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
      </div>
      <div
        className={currentView === "Create New Vendor" ? "sidebar__item-selected" : "sidebar__item"}
        onClick={() => setCurrentView("Create New Vendor")}
      >
        <p>Create New Vendor</p>
        <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
      </div>
      <div
        className={currentView === "Set Email Templates" ? "sidebar__item-selected" : "sidebar__item"}
        onClick={() => setCurrentView("Set Email Templates")}
      >
        <p>Set Email Templates</p>
        <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
      </div>
      <div
        className={currentView === "Groupings" ? "sidebar__item-selected" : "sidebar__item"}
        onClick={() => setCurrentView("Groupings")}
      >
        <p>Groupings</p>
        <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
      </div>
      <div
        className={currentView === "Budgets" ? "sidebar__item-selected" : "sidebar__item"}
        onClick={() => setCurrentView("Budgets")}
      >
        <p>Budgets</p>
        <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
      </div>
      <div
        className={currentView === "Analytics" ? "sidebar__item-selected" : "sidebar__item"}
        onClick={() => setCurrentView("Analytics")}
      >
        <p>Analytics</p>
        <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
      </div>
    </div>
  );
}
