import React, { useState, useEffect } from "react";
import "../../css/vendor-FAR-tab.scss";

import API from "API";

const VendorFARPageContent = (props) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const vendor = props.vendor;
  const accountingRole =
    loggedInUser.role === "accounting" || loggedInUser.role === "admin";
  const [editMode, setEditMode] = useState(false);
  const [isVerified, setIsVerified] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [minorityBE_Cert, setMinorityBE_Cert] = useState(
    vendor.minority_business_enterprise
  );
  const [smallDbCert, setSmallDbCert] = useState(
    vendor.small_disadvantaged_business
  );
  const [smallBeCert, setSmallBeCert] = useState(vendor.small_business);
  const [veteranObCert, setVeteranObCert] = useState(vendor.veteran_owned);
  const [hubZoneCert, setHubZoneCert] = useState(vendor.hub_zone_business);
  const [womenObCert, setWomenObCert] = useState(vendor.women_owned_business);
  const [disadvantagedOBECert, setDisadvantagedOBECert] = useState(
    vendor.disadvantage_owned_business
  );
  const [serviceDVOBECert, setServiceDVOBECert] = useState(
    vendor.service_disabled_veteran_owned_business
  );
  const [womenOSBCert, setWomenOSBCert] = useState(
    vendor.woman_owned_small_business
  );
  const [minorityBusinessEnterprise, setMinorityBusinessEnterprise] = useState(
    vendor.minority_business_enterprise !== null
  );
  const [smallDisadvantagedBusiness, setSmallDisadvantagedBusiness] = useState(
    vendor.small_disadvantaged_business !== null
  );
  const [smallBusinessEnterprise, setSmallBusinessEnterprise] = useState(
    vendor.small_business !== null
  );
  const [veteranOwnedBusiness, setVeteranOwnedBusiness] = useState(
    vendor.veteran_owned !== null
  );
  const [hubZoneBusiness, setHubZoneBusiness] = useState(
    vendor.hub_zone_business !== null
  );
  const [womenOwnedBusiness, setWomenOwnedBusiness] = useState(
    vendor.women_owned_business !== null
  );
  const [womenOwnedSmallBusiness, setWomenOwnedSmallBusiness] = useState(
    vendor.woman_owned_small_business !== null
  );
  const [
    disadvantagedOwnedBusinessEnterprise,
    setDisadvantagedOwnedBusinessEnterprise,
  ] = useState(vendor.disadvantage_owned_business !== null);
  const [
    serviceDisabledVeteranOwnedBusinessEnterprise,
    setServiceDisabledVeteranOwnedBusinessEnterprise,
  ] = useState(
    vendor.service_disabled_veteran_owned_business !== null
  );

  const checkInput = (e, input) => {
    const letters = "abcdefghijklmnopqrstuvwxyz";
    const regex = /^[0-9]|[^a-zA-Z]/g;
    const inputData = e.currentTarget.value;

    if (
      letters.includes(inputData) ||
      letters.toUpperCase().includes(inputData)
    ) {
      if (inputData === "") {
        switch (input) {
          case 1:
            setMinorityBE_Cert("");
            break;
          case 2:
            setSmallDbCert("");
            break;
          case 3:
            setSmallBeCert("");
            break;
          case 4:
            setVeteranObCert("");
            break;
          case 5:
            setHubZoneCert("");
            break;
          case 6:
            setWomenObCert("");
            break;
          case 7:
            setDisadvantagedOBECert("");
            break;
          case 8:
            setServiceDVOBECert("");
            break;
          case 9:
            setWomenOSBCert("");
            break;
        }
      }
    } else {
      switch (input) {
        case 1:
          setMinorityBE_Cert(inputData.match(regex).join(""));
          break;
        case 2:
          setSmallDbCert(inputData.match(regex).join(""));
          break;
        case 3:
          setSmallBeCert(inputData.match(regex).join(""));
          break;
        case 4:
          setVeteranObCert(inputData.match(regex).join(""));
          break;
        case 5:
          setHubZoneCert(inputData.match(regex).join(""));
          break;
        case 6:
          setWomenObCert(inputData.match(regex).join(""));
          break;
        case 7:
          setDisadvantagedOBECert(inputData.match(regex).join(""));
          break;
        case 8:
          setServiceDVOBECert(inputData.match(regex).join(""));
          break;
        case 9:
          setWomenOSBCert(inputData.match(regex).join(""));
          break;
      }
    }
  };

  const handleSubmit = () => {
    fetch(`${API.endpointURL}/api/update_vendor`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        vendor_id: vendor.id,
        minority_business_enterprise: minorityBusinessEnterprise
          ? minorityBE_Cert || vendor.minority_business_enterprise
          : null,
        small_disadvantaged_business: smallDisadvantagedBusiness
          ? smallDbCert || vendor.small_disadvantaged_business
          : null,
        small_business: smallBusinessEnterprise
          ? smallBeCert || vendor.small_business
          : null,
        veteran_owned: veteranOwnedBusiness
          ? veteranObCert || vendor.veteran_owned
          : null,
        hub_zone_business: hubZoneBusiness
          ? hubZoneCert || vendor.hub_zone_business
          : null,
        women_owned_business: womenOwnedBusiness
          ? womenObCert || vendor.women_owned_business
          : null,
        disadvantage_owned_business: disadvantagedOwnedBusinessEnterprise
          ? disadvantagedOBECert || vendor.disadvantage_owned_business
          : null,
        woman_owned_small_business: womenOwnedSmallBusiness
          ? womenOSBCert || vendor.woman_owned_small_business
          : null,
        service_disabled_veteran_owned_business:
          serviceDisabledVeteranOwnedBusinessEnterprise
            ? serviceDVOBECert || vendor.service_disabled_veteran_owned_business
            : null,
      }),
    })
      .then((res) => {
        props.getVendor();
        setEditMode(!editMode);
        setLoading(true);
      })
      .catch((error) =>
        console.log(
          "An error has occured while updating your vendors information. Please try again",
          error
        )
      );
  };

  const getVerifiedDetails = () => {
    fetch(
      `${API.endpointURL}/api/far19_verification?vendor_id=${vendor.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setIsVerified(res);
      })
      .catch((error) =>
        console.log("An Error occured getting your verified data.", error)
      );
  };

  useEffect(() => {
    getVerifiedDetails();
  }, []);

  useEffect(() => {
    let timer;
    if (loading) {
      timer = setTimeout(() => {
        setLoading(false);
      }, "1000");

      return () => clearTimeout(timer);
    }

    if (submitted) {
      timer = setTimeout(() => {
        setSubmitted(false);
      }, "1000");
      return () => clearTimeout(timer);
    }
  });

  const handleVerify = () => {
    fetch(
      `${API.endpointURL}/api/verify_vendor_far19?vendor_id=${vendor.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          minority_business_enterprise:
            minorityBE_Cert && minorityBusinessEnterprise ? true : false,
          small_disadvantaged_business:
            smallDbCert && smallDisadvantagedBusiness ? true : false,
          small_business: smallBeCert && smallBusinessEnterprise ? true : false,
          veteran_owned: veteranObCert && veteranOwnedBusiness ? true : false,
          hub_zone_business: hubZoneCert && hubZoneBusiness ? true : false,
          woman_owned_business:
            womenObCert && womenOwnedBusiness ? true : false,
          disadvantage_owned_business:
            disadvantagedOBECert && disadvantagedOwnedBusinessEnterprise
              ? true
              : false,
          service_disabled_veteran_owned_business:
            serviceDVOBECert && serviceDisabledVeteranOwnedBusinessEnterprise
              ? true
              : false,
          woman_owned_small_business:
            womenOSBCert && womenOwnedSmallBusiness ? true : false,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setSubmitted(true);
        setIsVerified(res);
      })
      .catch((error) =>
        console.log(
          "An Error has occured while submitting your verification information. --> ",
          error
        )
      );
  };

  return (
    <>
      <div className="heading-container">
        <h1 className="content-header">FAR 19.7</h1>
        {submitted ? <div className="submitted-text">Verifying...</div> : ""}
        {loading ? <div className="submitted-text">Submitting...</div> : ""}

        {editMode && accountingRole ? (
          <>
            <div className="helper">
              <div className="verified-circle pass enlarged"></div>
              <h6>Verified</h6>
            </div>
            <div className="helper">
              <div className="verified-circle fail enlarged"></div>
              <h6>Unverified</h6>
            </div>
            <div className="helper">
              <div className="verified-circle pending enlarged"></div>
              <h6>Pending</h6>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="all-content end">
        <div className="buttons">
          <button
            className={`btn ${!editMode ? "edit" : "submit"}`}
            onClick={
              !editMode ? () => setEditMode(!editMode) : () => handleSubmit()
            }
          >
            {!editMode ? "Edit" : "Submit"}
          </button>
          <button
            className={`btn cancel ${!editMode ? "hide" : ""}`}
            onClick={() => setEditMode(!editMode)}
          >
            Cancel
          </button>
          {editMode && accountingRole ? (
            <i
              className="fa fa-check-circle checkbox"
              aria-hidden="true"
              onClick={() => handleVerify()}
            ></i>
          ) : (
            ""
          )}
        </div>
        <div className="row border">
          <p className="row-title">Certification Number</p>

          <div
            className="choices"
            style={{ width: `${editMode && accountingRole ? "10%" : "12%"}` }}
          >
            <p>Yes</p>
            <p>No</p>
          </div>
        </div>
        <div className="row border">
          <p className="row-title">Minority Business Enterprise</p>
          <div className="choices">
            {editMode && minorityBusinessEnterprise ? (
              <>
                {accountingRole ? (
                  <div
                    className={`verified-circle ${
                      isVerified.minority_business_enterprise ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
                <input
                  maxLength={32}
                  disabled={!editMode}
                  className={
                    editMode === !minorityBusinessEnterprise ? "hide" : "show"
                  }
                  type="text"
                  placeholder="Certification #"
                  value={minorityBE_Cert}
                  onChange={(e) => checkInput(e, 1)}
                />
              </>
            ) : (
              <>
                <input disabled={!editMode} className="hide" type="text" />
                {editMode && accountingRole ? (
                  <div className={`verified-circle pending`}></div>
                ) : (
                  ""
                )}
              </>
            )}

            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setMinorityBusinessEnterprise(true)}
              checked={minorityBusinessEnterprise}
            />
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setMinorityBusinessEnterprise(false)}
              checked={!minorityBusinessEnterprise}
            />
          </div>
        </div>
        <div className="row border">
          <p className="row-title">Small Disadvantaged Business</p>
          <div className="choices">
            {editMode && smallDisadvantagedBusiness ? (
              <>
                {editMode && accountingRole ? (
                  <div
                    className={`verified-circle ${
                      isVerified.small_disadvantaged_business ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
                <input
                  maxLength={32}
                  disabled={!editMode}
                  className={
                    editMode === !smallDisadvantagedBusiness ? "hide" : "show"
                  }
                  type="text"
                  placeholder="Certification #"
                  value={smallDbCert}
                  onChange={(e) => checkInput(e, 2)}
                />
              </>
            ) : (
              <>
                <input disabled={!editMode} className="hide" type="text" />
                {editMode && accountingRole ? (
                  <div className={`verified-circle pending`}></div>
                ) : (
                  ""
                )}
              </>
            )}

            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setSmallDisadvantagedBusiness(true)}
              checked={smallDisadvantagedBusiness}
            />
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setSmallDisadvantagedBusiness(false)}
              checked={!smallDisadvantagedBusiness}
            />
          </div>
        </div>
        <div className="row border">
          <p className="row-title">Small Business Enterprise</p>
          <div className="choices">
            {editMode && smallBusinessEnterprise ? (
              <>
                {accountingRole ? (
                  <div
                    className={`verified-circle ${
                      isVerified.small_business ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
                <input
                  maxLength={32}
                  disabled={!editMode}
                  className={
                    editMode === !smallBusinessEnterprise ? "hide" : "show"
                  }
                  type="text"
                  placeholder="Certification #"
                  value={smallBeCert}
                  onChange={(e) => checkInput(e, 3)}
                />
              </>
            ) : (
              <>
                <input disabled={!editMode} className="hide" type="text" />
                {editMode && accountingRole ? (
                  <div className={`verified-circle pending`}></div>
                ) : (
                  ""
                )}
              </>
            )}

            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setSmallBusinessEnterprise(true)}
              checked={smallBusinessEnterprise}
            />
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setSmallBusinessEnterprise(false)}
              checked={!smallBusinessEnterprise}
            />
          </div>
        </div>
        <div className="row border">
          <p className="row-title">Veteran-owned Business</p>
          <div className="choices">
            {editMode && veteranOwnedBusiness ? (
              <>
                {accountingRole ? (
                  <div
                    className={`verified-circle ${
                      isVerified.veteran_owned_business ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
                <input
                  maxLength={32}
                  className={
                    editMode === !veteranOwnedBusiness ? "hide" : "show"
                  }
                  disabled={!editMode}
                  type="text"
                  placeholder="Certification #"
                  value={veteranObCert}
                  onChange={(e) => checkInput(e, 4)}
                />
              </>
            ) : (
              <>
                <input disabled={!editMode} className="hide" type="text" />
                {editMode && accountingRole ? (
                  <div className={`verified-circle pending`}></div>
                ) : (
                  ""
                )}
              </>
            )}

            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setVeteranOwnedBusiness(true)}
              checked={veteranOwnedBusiness}
            />
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setVeteranOwnedBusiness(false)}
              checked={!veteranOwnedBusiness}
            />
          </div>
        </div>
        <div className="row border">
          <p className="row-title">HUBZone Business Enterprise</p>
          <div className="choices">
            {editMode && hubZoneBusiness ? (
              <>
                {accountingRole ? (
                  <div
                    className={`verified-circle ${
                      isVerified.hub_zone_business ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
                <input
                  maxLength={32}
                  className={editMode === !hubZoneBusiness ? "hide" : "show"}
                  disabled={!editMode}
                  type="text"
                  placeholder="Certification #"
                  value={hubZoneCert}
                  onChange={(e) => checkInput(e, 5)}
                />
              </>
            ) : (
              <>
                <input disabled={!editMode} className="hide" type="text" />
                {editMode && accountingRole ? (
                  <div className={`verified-circle pending`}></div>
                ) : (
                  ""
                )}
              </>
            )}
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setHubZoneBusiness(true)}
              checked={hubZoneBusiness}
            />
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setHubZoneBusiness(false)}
              checked={!hubZoneBusiness}
            />
          </div>
        </div>
        <div className="row border">
          <p className="row-title">Women-owned Business</p>
          <div className="choices">
            {editMode && womenOwnedBusiness ? (
              <>
                {accountingRole ? (
                  <div
                    className={`verified-circle ${
                      isVerified.woman_owned_business ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
                <input
                  maxLength={32}
                  className={!editMode === womenOwnedBusiness ? "hide" : "show"}
                  disabled={!editMode}
                  type="text"
                  placeholder="Certification #"
                  value={womenObCert}
                  onChange={(e) => checkInput(e, 6)}
                />
              </>
            ) : (
              <>
                <input disabled={!editMode} className="hide" type="text" />
                {editMode && accountingRole ? (
                  <div className={`verified-circle pending`}></div>
                ) : (
                  ""
                )}
              </>
            )}
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setWomenOwnedBusiness(true)}
              checked={womenOwnedBusiness}
            />
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setWomenOwnedBusiness(false)}
              checked={!womenOwnedBusiness}
            />
          </div>
        </div>
        <div className="row border">
          <p className="row-title">Disadvantaged-owned business enterprise</p>
          <div className="choices">
            {editMode && disadvantagedOwnedBusinessEnterprise ? (
              <>
                {accountingRole ? (
                  <div
                    className={`verified-circle ${
                      isVerified.disadvantage_owned_business ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
                <input
                  maxLength={32}
                  className={
                    !editMode === disadvantagedOwnedBusinessEnterprise
                      ? "hide"
                      : "show"
                  }
                  disabled={!editMode}
                  type="text"
                  placeholder="Certification #"
                  value={disadvantagedOBECert}
                  onChange={(e) => checkInput(e, 7)}
                />
              </>
            ) : (
              <>
                <input disabled={!editMode} className="hide" type="text" />
                {editMode && accountingRole ? (
                  <div className={`verified-circle pending`}></div>
                ) : (
                  ""
                )}
              </>
            )}
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setDisadvantagedOwnedBusinessEnterprise(true)}
              checked={disadvantagedOwnedBusinessEnterprise}
            />
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setDisadvantagedOwnedBusinessEnterprise(false)}
              checked={!disadvantagedOwnedBusinessEnterprise}
            />
          </div>
        </div>
        <div className="row border">
          <p className="row-title">
            Service-disabled Veteran-owned Business Enterprise
          </p>
          <div className="choices">
            {editMode && serviceDisabledVeteranOwnedBusinessEnterprise ? (
              <>
                {accountingRole ? (
                  <div
                    className={`verified-circle ${
                      isVerified.service_disabled_veteran_owned_business
                        ? "pass"
                        : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
                <input
                  maxLength={32}
                  className={
                    !editMode === serviceDisabledVeteranOwnedBusinessEnterprise
                      ? "hide"
                      : "show"
                  }
                  disabled={!editMode}
                  type="text"
                  placeholder="Certification #"
                  value={serviceDVOBECert}
                  onChange={(e) => checkInput(e, 8)}
                />
              </>
            ) : (
              <>
                <input disabled={!editMode} className="hide" type="text" />
                {editMode && accountingRole ? (
                  <div className={`verified-circle pending`}></div>
                ) : (
                  ""
                )}
              </>
            )}
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() =>
                setServiceDisabledVeteranOwnedBusinessEnterprise(true)
              }
              checked={serviceDisabledVeteranOwnedBusinessEnterprise}
            />
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() =>
                setServiceDisabledVeteranOwnedBusinessEnterprise(false)
              }
              checked={!serviceDisabledVeteranOwnedBusinessEnterprise}
            />
          </div>
        </div>
        <div className="row">
          <p className="row-title">Women-owned Small Business</p>
          <div className="choices">
            {editMode && womenOwnedSmallBusiness ? (
              <>
                {accountingRole ? (
                  <div
                    className={`verified-circle ${
                      isVerified.woman_owned_small_business ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
                <input
                  maxLength={32}
                  disabled={!editMode}
                  className={
                    !editMode === womenOwnedSmallBusiness ? "hide" : "show"
                  }
                  type="text"
                  placeholder="Certification #"
                  value={womenOSBCert}
                  onChange={(e) => checkInput(e, 9)}
                />
              </>
            ) : (
              <>
                <input disabled={!editMode} className="hide" type="text" />
                {editMode && accountingRole ? (
                  <div className={`verified-circle pending`}></div>
                ) : (
                  ""
                )}
              </>
            )}
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setWomenOwnedSmallBusiness(true)}
              checked={womenOwnedSmallBusiness}
            />
            <input
              disabled={!editMode}
              type="checkbox"
              onClick={() => setWomenOwnedSmallBusiness(false)}
              checked={!womenOwnedSmallBusiness}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorFARPageContent;
