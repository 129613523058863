import "../../css/modal.scss";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

export default function CustomDialogRedesign(props) {
  const {
    colorPalette,
    dialogContent,
    onClose,
    open,
    title,
    titleHighlight
  } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            borderRadius: "28px",
            minWidth: "425px",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", padding: "30px 35px 20px" }}>
          <div className={`dialog-title-${colorPalette}`}>
            {title}
            <span className={`dialog-title-highlight-${colorPalette}`}> {titleHighlight}</span>
          </div>
          <IconButton
            onClick={onClose}
            sx={{
              padding: 0,
              top: "-20px",
              "&:hover": { backgroundColor: "transparent", color: "#a5af96" },
            }}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0 35px 35px" }}>
          {dialogContent}
        </DialogContent>
      </Dialog>
    </>
  );
}
