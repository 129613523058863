import React from 'react';

export const SalesConnectionsModalFive = ({
  connectionType,
  leftColumn,
  selectedLeftTags,
  handleSelectLeft,
  handleMoveRight,
  handleMoveLeft,
  rightColumn,
  selectedRightTags,
  handleSelectRight,
  setStep,
  topColumn,
  handleMoveTop,
  handleMoveBottom,
  selectedRightTopColumn,
  setSelectedRightTopColumn,
}) => {
  const handleBack = () => {
    connectionType === "PERSONAL"
      ? setStep(2)
      : setStep(4);
  };

  return (
    <div className="connection__modal-content">
      <p className="connection__modal-title connection__modal-title--italic">
        Assign any categorical tags you want to your
        <span className="connection__modal-title--bold">
          {`  ${connectionType}  `}
        </span>
        {connectionType !== 'COMPETITOR' && 'connection'}
      </p>
      <div className="connection__modal-tags">
        <div className="connection__modal-tags--box">
          <p className="connection__modal-tags--title">Available Tags</p>
          <div className="connection__modal-tags--layout">
            <div className="connection__modal-tags--container">
              {leftColumn.map(tag => (
                <button
                  className={`
                      connection__modal-tag 
                      ${selectedLeftTags.includes(tag) && 'connection__modal-tag--active'}
                    `}
                  onClick={() => handleSelectLeft(tag)}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="connection__modal-tags--arrows">
          <div className="connection__modal-tags--arrows-box">
            <i
              className="fa-solid fa-right-long add add--top"
              onClick={() => selectedLeftTags.length && handleMoveTop()}
            />
            <i
              className="fa-solid fa-left-long remove remove--top"
              onClick={() => selectedRightTopColumn && handleMoveBottom()}
            />
          </div>
          <div className="connection__modal-tags--arrows-box">
            <i
              className="fa-solid fa-right-long add"
              onClick={() => selectedLeftTags.length && handleMoveRight()}
            />
            <i
              className="fa-solid fa-left-long remove"
              onClick={() => selectedRightTags.length && handleMoveLeft()}
            />
          </div>
        </div>
        <div className="connection__modal-tags--box">
          <p className="connection__modal-tags--title">Assigned Service Tag</p>
          <div className="connection__modal-tags--layout">
            <div className="connection__modal-tags--container connection__modal-tags--container--top">
              {topColumn && (
                <button
                  className={`
                    connection__modal-tag 
                    ${selectedRightTopColumn && 'connection__modal-tag--active'}
                  `}
                  onClick={() =>
                    setSelectedRightTopColumn(
                      selectedRightTopColumn
                        ? ''
                        : topColumn
                    )
                  }
                >
                  {topColumn}
                </button>
              )}
            </div>
          </div>
          <p className="connection__modal-tags--title">Assigned Other Tags</p>
          <div className="connection__modal-tags--layout">
            <div className="connection__modal-tags--container connection__modal-tags--container--bottom">
              {rightColumn.map(tag => (
                <button
                  className={`
                      connection__modal-tag 
                      ${selectedRightTags.includes(tag) && 'connection__modal-tag--active'}
                    `}
                  onClick={() => handleSelectRight(tag)}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="connection__modal-buttons">
        <button
          className="connection__modal-button connection__modal-button--back"
          onClick={handleBack}
        >
          Back
        </button>
        <button
          className="connection__modal-button"
          onClick={() => setStep(2)}
        >
          Create
        </button>
      </div>
    </div>
  )
};
