import React, { useEffect } from 'react';

import CustomDialogRedesign from 'components/modal/custom-dialog-redesign';
import ProgressSpinner from 'components/progress/progress-spinner';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  deleteTagSelector,
  selectedGroupSelector,
  selectedTagSelector,
  selectedTypeSelector,
} from 'store/tags/selectors';
import { deleteTag, filterTags, getTags } from 'store/tags/thunk';
import { setDeleteTagStatus } from 'store/tags/slice';

export const ContractsTagRemove = ({ deleteTagOpenModal, setDeleteTagOpenModal }) => {
  const dispatch = useAppDispatch();
  const selectedTag = useAppSelector(selectedTagSelector);
  const typeId = useAppSelector(selectedTypeSelector);
  const groupId = useAppSelector(selectedGroupSelector);
  const { tagDeleteError, tagDeleteLoading, tagDeleteStatus } = useAppSelector(deleteTagSelector);
  const handleCloseModal = () => {
    setDeleteTagOpenModal(false);
  };
  
  useEffect(() => {
    if (tagDeleteStatus === 'fulfilled') {
      dispatch(setDeleteTagStatus(''));
      handleCloseModal();
      if (groupId || typeId) {
        dispatch(filterTags({ groupId, typeId }));
      } else {
        dispatch(getTags());
      }
    }
  }, [tagDeleteStatus]);
  
  const removeTag = () => {
    return (
      <>
        <div
          style={{
            fontFamily: 'Korolev Medium',
            lineHeight: '25px',
            marginTop: '30px',
            textAlign: 'center',
          }}
        >
          This will permanently remove <b style={{ color: '#62AB37' }}>{selectedTag?.name}</b> from the tags
          list.
          <br/>
          Are you sure you want to remove this tag?
        </div>
        {tagDeleteError && <div className="add-user-error">{tagDeleteError}</div>}
        <div className="contractsTags__tags__modal-buttonContainer">
          {tagDeleteLoading && (
            <div className="contractsTags__tags__modal-loading">
              <ProgressSpinner/>
            </div>
          )}
          <button
            className="create-button contractsTags__tags__modal-button"
            onClick={() => {
              dispatch(deleteTag({ tagId: selectedTag.id }));
            }}
            disabled={tagDeleteLoading}
          >
            Remove
          </button>
        </div>
      </>
    );
  };
  
  return (
    <CustomDialogRedesign
      colorPalette="green"
      dialogContent={removeTag()}
      onClose={handleCloseModal}
      open={deleteTagOpenModal}
      title={'DELETE'}
      titleHighlight={'TAG'}
    />
  );
};
