import { createAsyncThunk } from '@reduxjs/toolkit';
import { projects } from 'services/projects';
export const getProjectsByStatus = createAsyncThunk(
  'getProjectsByStatus',
  async ({ status, currentPage }, { rejectWithValue }) => {
    try {
      return await projects.getProjectsByStatus(status, currentPage);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjectsAnalytics = createAsyncThunk(
  'getProjectsAnalytics',
  async ({ selectedManager, selectedCoordinator }, { rejectWithValue }) => {
    try {
      return await projects.getProjectsAnalytics(selectedManager, selectedCoordinator);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjectsCoordinators = createAsyncThunk(
  'getProjectsCoordinators',
  async (_, { rejectWithValue }) => {
    try {
      return await projects.getProjectsCoordinators();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjectsManagers = createAsyncThunk(
  'getProjectsManagers',
  async (_, { rejectWithValue }) => {
    try {
      return await projects.getProjectsManagers();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTotalActiveProjects = createAsyncThunk(
  'getTotalActiveProjects',
  async (_, { rejectWithValue }) => {
    try {
      return await projects.getTotalActiveProjects();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTotalProjectsValue = createAsyncThunk(
  'getTotalProjectsValue',
  async (_, { rejectWithValue }) => {
    try {
      return await projects.getTotalProjectsValue();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createProject = createAsyncThunk(
  'createProject',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await projects.createProject(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadProjectFile = createAsyncThunk(
  'uploadProjectFile',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await projects.uploadProjectFile(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProject = createAsyncThunk(
  'getProject',
  async ({ projectId }, { rejectWithValue }) => {
    try {
      return await projects.getProject(projectId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateProject = createAsyncThunk(
  'updateProject',
  async ({ projectId, body }, { rejectWithValue }) => {
    try {
      return await projects.updateProject(projectId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjectTasks = createAsyncThunk(
  'getProjectTasks',
  async ({ projectId, status, page }, { rejectWithValue }) => {
    try {
      const statusFilter = status.reduce((result, value) => result += '&status[]=' + value, '');
      return await projects.getProjectTasks(projectId, statusFilter, page);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjectFiles = createAsyncThunk(
  'getProjectFiles',
  async ({ projectId }, { rejectWithValue }) => {
    try {
      return await projects.getProjectFiles(projectId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjectFilesByCategory = createAsyncThunk(
  'getProjectFilesByCategory',
  async ({ projectId, typeName, category }, { rejectWithValue }) => {
    try {
      return await projects.getProjectFilesByCategory(projectId, typeName, category);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjectsFileFolders = createAsyncThunk(
  'getProjectsFileFolders',
  async ({ projectId }, { rejectWithValue }) => {
    try {
      return await projects.getProjectsFileFolders(projectId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProjectsFileTypes = createAsyncThunk(
  'getProjectsFileTypes',
  async ({ projectId }, { rejectWithValue }) => {
    try {
      return await projects.getProjectsFileTypes(projectId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createProjectsFileType = createAsyncThunk(
  'createProjectsFileType',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await projects.createProjectsFileType(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteProjectFile = createAsyncThunk(
  'deleteProjectFile',
  async ({ fileId }, { rejectWithValue }) => {
    try {
      return await projects.deleteProjectFile(fileId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editProjectFile = createAsyncThunk(
  'editProjectFile',
  async ({ fileId, body }, { rejectWithValue }) => {
    try {
      return await projects.editProjectFile(fileId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);