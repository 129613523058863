import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import deleteButton from 'assets/delete.png';
import editButton from 'assets/edit.png';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setSelectedTag } from 'store/tags/slice';
import { selectedTagSelector } from 'store/tags/selectors';
import { ContractsTagRemove } from 'components/contracts/contracts-tags/contracts-tag-remove';
import { UpsertTagModal } from 'components/contracts/contracts-tags/upsertTag-modal';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

export const ContractsTagsItem = ({ tag }) => {
  const dispatch = useAppDispatch();
  const selectedTag = useAppSelector(selectedTagSelector);
  const [openEditTag, setOpenEditTag] = useState(false);
  const [deleteTagOpenModal, setDeleteTagOpenModal] = useState(false);
  const { pathname } = useLocation();

  return (
    <tr
      className={classNames('contractsTags__tags-listItem', {
        'contractsTags__tags-listItem--active': selectedTag?.id === tag?.id,
        'contractsTags__tags-listItem--active--blue': pathname.includes('applicant') && selectedTag?.id === tag?.id,
      })}
      key={tag?.id}
      
      onClick={() => {
        dispatch(setSelectedTag(tag));
      }}
    >
      <td
        style={{
          backgroundColor: tag?.group?.color,
          width: "5px",
          height: '100%',
        }}
      ></td>
      <td
        style={{
          backgroundColor: "transparent",
          width: "100%",
        }}
      >
        {tag?.group?.title}
      </td>
      <td>{tag?.name}</td>
      <td>{tag?.type?.title}</td>
      <td>{tag?.description}</td>
      <td style={{ height: '100%', display: 'flex' }}>
        <Tooltip
          title={'Edit Tag'}
        >
          <IconButton
            edge="start"
            sx={{
              padding: 0,
              margin: 0,
              color: '#3e453d',
              justifyContent: 'flex-start',
            }}
            onClick={() => {
              setOpenEditTag(true);
              dispatch(setSelectedTag(tag));
            }}
          >
            <img
              src={editButton}
              width="20px"
              height="auto"
              alt="edit-button"
              style={{
                '&:hover': { backgroundColor: 'transparent' },
              }}
            />
          </IconButton>
        </Tooltip>
      </td>
      <td>
        <Tooltip title="Delete Tag">
          <IconButton
            edge="start"
            sx={{
              padding: 0,
              margin: 0,
              color: '#3e453d',
              justifyContent: 'flex-start',
            }}
            onClick={() => {
              setDeleteTagOpenModal(true);
            }}
          >
            <img
              src={deleteButton}
              width="20px"
              height="auto"
              alt="delete-button"
            />
          </IconButton>
        </Tooltip>
      </td>
      {deleteTagOpenModal && (
        <ContractsTagRemove
          deleteTagOpenModal={deleteTagOpenModal}
          setDeleteTagOpenModal={setDeleteTagOpenModal}
        />
      )}
      {openEditTag && (
        <UpsertTagModal isEditing={true} open={openEditTag} setOpen={setOpenEditTag} />
      )}
    </tr>
  );
}
