import { Navigate } from "react-router-dom";

// TODO: Decide fallback routes for each role type

export const ProtectedRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};

export const ProtectedInternalRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isInternal = user && user.role !== "applicant";
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  } else if (user && !isInternal) {
    // user is not an internal user
    return <Navigate to={`/applicant-portal/${user.id}`} />;
  }
  return children;
};

export const ProtectedAdminRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isAdmin = user && user.role === "admin";
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  } else if (user && !isAdmin) {
    // user is not an admin
    return <Navigate to={`/applicant-portal/${user.id}`} />;
  }
  return children;
};

export const ProtectedAccountingRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isAllowed = user && (user.role === "admin" || user.role === "accounting");
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  } else if (user && !isAllowed) {
    // user does not have permissions
    if (user.role === "applicant") {
      return <Navigate to={`/applicant-portal/${user.id}`} />;
    } else {
      return <Navigate to={`/employee-portal/${user.id}`} />;
    }
  }
  return children;
};

export const ProtectedAccountingStaffingRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isAllowed = user && (user.role === "admin" || user.role === "accounting" || user.role === "staffing");
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  } else if (user && !isAllowed) {
    // user does not have permissions
    if (user.role === "applicant") {
      return <Navigate to={`/applicant-portal/${user.id}`} />;
    } else {
      return <Navigate to={`/employee-portal/${user.id}`} />;
    }
  }
  return children;
};

export const ProtectedRecruiterStaffingRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isAllowed = user && (user.role === "admin" || user.role === "recruiter" || user.role === "staffing");
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  } else if (user && !isAllowed) {
    // user does not have permissions
    if (user.role === "applicant") {
      return <Navigate to={`/applicant-portal/${user.id}`} />;
    } else {
      return <Navigate to={`/employee-portal/${user.id}`} />;
    }
  }
  return children;
};

export const ProtectedSalesRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isAllowed = user && (user.role === "admin" || user.role === "sales");
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  } else if (user && !isAllowed) {
    // user does not have permissions
    if (user.role === "applicant") {
      return <Navigate to={`/applicant-portal/${user.id}`} />;
    } else {
      return <Navigate to={`/employee-portal/${user.id}`} />;
    }
  }
  return children;
};

export const ProtectedStaffingRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isAllowed = user && (user.role === "admin" || user.role === "staffing");
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  } else if (user && !isAllowed) {
    // user does not have permissions
    if (user.role === "applicant") {
      return <Navigate to={`/applicant-portal/${user.id}`} />;
    } else {
      return <Navigate to={`/employee-portal/${user.id}`} />;
    }
  }
  return children;
};

export const ProtectedVendorRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isAllowed = user && (user.role === "admin" || user.role === "accounting" || user.role === "vendor");
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  } else if (user && !isAllowed) {
    // user does not have permissions
    if (user.role === "applicant") {
      return <Navigate to={`/applicant-portal/${user.id}`} />;
    } else {
      return <Navigate to={`/employee-portal/${user.id}`} />;
    }
  }
  return children;
};

export const ProtectedProjectsRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isAllowed = user && (user.role === "admin" || user.role === "project manager" || user.role === "project coordinator");
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  } else if (user && !isAllowed) {
    // user does not have permissions
    if (user.role === "applicant") {
      return <Navigate to={`/applicant-portal/${user.id}`} />;
    } else {
      return <Navigate to={`/employee-portal/${user.id}`} />;
    }
  }
  return children;
};
