import React, { useEffect, useState } from 'react';

import '../../css/modal.scss';
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import CustomDialog from './custom-dialog';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { contractsLoadingSelector, contractsSelector } from 'store/contracts/selectors';
import { divisionsByContractIdSelector, divisionsLoadingSelector } from 'store/divisions/selectors';
import { subdivisionsByDivisionAndContractIdSelector, subdivisionsLoadingSelector } from 'store/subdivisions/selectors';
import { applicantErrorSelector, applicantLoadingSelector } from 'store/applicants/selectors';
import { editApplicant } from 'store/applicants/thunk';
import { getSubdivisionByDivisionAndContractId } from 'store/subdivisions/thunk';
import { getDivisionsByContractId } from 'store/divisions/thunk';
import { getContracts } from 'store/contracts/thunk';

export default function EditApplicantDetail(props) {
  const { applicant, field, getApplicant, handleClose, open, setOpenToast } =
    props;
  
  const fieldToLabel = {
    contract_id: 'Contract',
    region_id: 'Division',
    site_id: 'Subdivision',
    felony_note: 'Felony Conviction Note',
    ec_primary_name: `Primary Contact's Name`,
    ec_primary_relation: `Primary Contact's Relationship`,
    ec_primary_phone: `Primary Contact's Phone Number`,
    ec_secondary_name: `Secondary Contact's Name`,
    ec_secondary_relation: `Secondary Contact's Relationship`,
    ec_secondary_phone: `Secondary Contact's Phone Number`,
  };
  
  const dispatch = useAppDispatch();
  const contracts = useAppSelector(contractsSelector);
  const divisions = useAppSelector(divisionsByContractIdSelector);
  const subdivisions = useAppSelector(subdivisionsByDivisionAndContractIdSelector);
  
  const contractsLoading = useAppSelector(contractsLoadingSelector);
  const divisionsLoading = useAppSelector(divisionsLoadingSelector);
  const subdivisionsLoading = useAppSelector(subdivisionsLoadingSelector);
  const editLoading = useAppSelector(applicantLoadingSelector);
  const editError = useAppSelector(applicantErrorSelector);
  
  const [options, setOptions] = useState([]);
  const [updatedDetail, setUpdatedDetail] = useState(null);
  
  useEffect(() => {
    if (field === 'contract_id') {
      dispatch(getContracts({ currentPage: 1 }));
    } else if (field === 'region_id') {
      if (applicant.application.contract_id) {
        dispatch(getDivisionsByContractId({ contractId: applicant.application.contract_id }));
      }
    } else if (field === 'site_id') {
      if (applicant.application.region_id && applicant.application.contract_id) {
        dispatch(getSubdivisionByDivisionAndContractId(
          {
            divisionId: applicant.application.region_id,
            contractId: applicant.application.contract_id,
          },
        ));
      }
    }
    
    let currentDetail = null;
    if (field.includes('ec_') || field.includes('_note')) {
      currentDetail = applicant.application[field];
    } else {
      currentDetail = applicant.application[field]
        ? applicant.application[field]
        : 0;
    }
    setUpdatedDetail(currentDetail);
  }, [applicant, field]);
  
  useEffect(() => {
    if (field === 'contract_id' && contracts.length > 0) {
      setOptions(contracts);
    } else if (field === 'region_id') {
      setOptions(divisions);
    } else if (field === 'site_id') {
      setOptions(subdivisions);
    }
  }, [contracts, divisions, subdivisions]);
  
  const editUser = async () => {
    let requestBody = {};
    if (
      field === 'contract_id' &&
      updatedDetail !== applicant.application.contract_id
    ) {
      requestBody = {
        contract_id: updatedDetail,
        region_id: null,
        site_id: null,
        recruiter_id: null,
      };
    } else if (
      field === 'region_id' &&
      updatedDetail !== applicant.application.region_id
    ) {
      requestBody = {
        region_id: updatedDetail,
        site_id: null,
      };
    } else {
      requestBody[field] = updatedDetail;
    }
    
    dispatch(editApplicant({ applicantId: applicant.application.id, body: requestBody }))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          getApplicant();
          handleClose();
          setOpenToast(true);
        }
      });
  };
  
  const editUserDetailForm = () => {
    let optionsList = [];
    if (options) {
      options.forEach((option) => {
        optionsList.push(
          <MenuItem key={option.id} value={option.id}>
            {option.title ? option.title : 'No Title'}
          </MenuItem>,
        );
      });
    }
    optionsList.push(
      <MenuItem key={'none'} value={''}>
        None
      </MenuItem>,
    );
    if (optionsList.length === 1) {
      optionsList.push(
        <MenuItem key={'empty'} value={'empty'} disabled>
          {(contractsLoading || divisionsLoading || subdivisionsLoading )? 'Loading...' : 'No ' + fieldToLabel[field]}
        </MenuItem>,
      );
    }
    
    return (
      <React.Fragment key="edit-user-detail-form">
        <div style={{ textAlign: 'left' }} className="form-row-label">
          {fieldToLabel[field]}
          {(contractsLoading || divisionsLoading || subdivisionsLoading) && (
            <div className="form-row-spinner">
              <ProgressSpinnerBlue/>
            </div>
          )}
        </div>
        {field.includes('ec_') || field.includes('_note') ? (
          <TextField
            fullWidth
            id="edit-applicant-detail"
            key="edit-applicant-detail"
            placeholder={fieldToLabel[field]}
            variant="outlined"
            margin="dense"
            required
            value={updatedDetail}
            onChange={(e) => setUpdatedDetail(e.currentTarget.value)}
          />
        ) : (
          <FormControl fullWidth>
            <Select
              id="edit-user-detail"
              key="edit-user-detail"
              displayEmpty
              onChange={(event) => {
                setUpdatedDetail(event.target.value);
              }}
              sx={{
                backgroundColor: '#EFEFEF',
                border: 0,
                borderRadius: '8px',
                fontFamily: 'Korolev Medium',
                textAlign: 'left',
              }}
              value={updatedDetail || ''}
            >
              {optionsList}
            </Select>
          </FormControl>
        )}
        {editError && <div className="add-user-error">{editError}</div>}
        <div className="button-container" style={{ overflow: 'hidden', paddingTop: '5px' }}>
          <Button
            variant="contained"
            className="add-user-button"
            disabled={editLoading}
            sx={{
              backgroundColor: "#556155",
              color: "#EFEFEF",
              fontSize: "18px",
              width: "175px",
              "&:hover": { backgroundColor: "#a5af96", color: "#3e453d" },
            }}
            onClick={() => editUser()}
          >
            Edit
            {editLoading && (
              <div style={{ position: 'absolute', left: -55 }}>
                <ProgressSpinnerBlue/>
              </div>
            )}
          </Button>
        </div>
      </React.Fragment>
    );
  };
  
  return (
    <CustomDialog
      dialogContent={editUserDetailForm()}
      onClose={handleClose}
      open={open}
      title={'EDIT'}
      titleHighlight={`${fieldToLabel[field]}`}
    />
  );
}
