import React, { Component } from 'react';

class TabView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            index: 0,
        };
    }

    renderTabButtons() {
        let c = [];
        React.Children.forEach(this.props.children, (child, i) => {
            c.push(
                <div key={i} onClick={() => this.setState({index: i})} className={"tabview__tab" + (i == this.state.index ? " tabview__tab--selected" : "")}>
                    <p>{child.props.title}</p>
                </div>
            );
        });

        return c;
    }

    renderTab() {
        let c = [];
        if(this.props.children.length > 0) {
            return this.props.children[this.state.index];
        }

        return (
            <div>
                No tab.
            </div>
        );
    }

    render() { 
        return (
            <div style={this.props.style} className={(this.props.small ? 'tabview tabview--small' : 'tabview')}>
                <div className="tabview__buttons">
                    {this.renderTabButtons()}
                </div>
                <div className="tabview__content">
                    {this.renderTab()}
                </div>
            </div>
        );
    }
}
 
export default TabView;