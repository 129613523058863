import React, { useEffect, useState } from "react";
import ImageIconEmail from "assets/email.png";
import ImageIconPhone from "assets/phone.png";
import ImageIconUser from "assets/executiveUser.png";
import ImageIconBuilding from "assets/building-icon.svg";
import { formatPhoneNumber } from "shared/formatting";
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';

export default function SalesProfilePanel(props) {
  const { sale, loading } = props;
  const [primaryConnection, setPrimaryConnection] = useState(null);
  const [companyPrimaryConnection, setCompanyPrimaryConnection] = useState(null);

  useEffect(() => {
    let primary = null;
    let companyPrimary = null;

    if (sale?.id) {
      if (sale.connections?.length > 0) {
        sale.connections.forEach((connection) => {
          // Check for primary connections
          if (connection.is_primary) {
            // If primary personal connection, set primary
            if (connection.connection_type === "personal") {
              primary = connection;

            } else if (connection.connection_type === 'company') {
              companyPrimary = connection;
            }
          }
        })
      }
    }
    setPrimaryConnection(primary);
    setCompanyPrimaryConnection(companyPrimary);
  }, [sale]);

  return loading ? (
    <div style={{ height: '378px' }} className="sidebar-loading sidebar-loading-portal">
      <ProgressSpinnerNavy />
    </div>
  ) : (
    <div style={{ paddingTop: '25px' }} className="user-info-container">

      <div className="user-info-header">
        <div>
          <div className="user-info-name">
            Project Name:
            {' '}
            {sale?.project_name ? sale.project_name : "N/A"}
          </div>
          <div className="user-info-small-header">
            Opportunity Name:
            {' '}
            {sale?.opportunity_name ? sale.opportunity_name : "N/A"}
          </div>
        </div>
      </div>
      {/* PRIMARY PERSONAL CONNECTION DATA */}
      <div className="row">
        <img className="icon" src={ImageIconUser} alt=""/>
        <div className="section-data">
          {primaryConnection?.full_name ? primaryConnection.full_name : "N/A"}
        </div>
      </div>

      <div className="row">
        <img className="icon" src={ImageIconPhone} alt=""/>
        <div className="section-data">
          {primaryConnection?.phone_number ? formatPhoneNumber(primaryConnection.phone_number) : "N/A"}
        </div>
      </div>
      <div className="row">
        <img className="icon" src={ImageIconEmail} alt="" />
        <div
          className="section-data"
          style={{ maxWidth: 'calc(100% - 42px)', wordWrap: 'break-word' }}
        >
          {primaryConnection?.email ? primaryConnection.email : "N/A"}
        </div>
      </div>

      {/* WHITE HORIZONAL LINE THAT SEPARATES TWO BLOCKS */}
      <div style={{ height: '1.5px', backgroundColor: 'rgba(239, 239, 239, 0.5019607843)', 'marginBottom': '13px' , 'marginRight' : '33px'}}></div>

      {/* PRIMARY COMPANY CONNECTION DATA */}
      <div className="section-data">
          <img className="icon" src={ImageIconBuilding} alt=""/>
          {companyPrimaryConnection?.full_name ? companyPrimaryConnection.full_name : "N/A"}
      </div>
      <div className="row">
        <img className="icon" src={ImageIconPhone} alt="" />
        <div className="section-data">
          {companyPrimaryConnection?.phone_number ? formatPhoneNumber(companyPrimaryConnection.phone_number) : "N/A"}
        </div>
      </div>
      <div className="row">
        <img className="icon" src={ImageIconEmail} alt="" />
        <div
          className="section-data"
          style={{ maxWidth: 'calc(100% - 42px)', wordWrap: 'break-word' }}
        >
          {companyPrimaryConnection?.email ? companyPrimaryConnection.email : "N/A"}
        </div>
      </div>
    </div>
  );
}
