import React, { useState, useEffect } from 'react';
import { Divider, Typography } from '@mui/material';
import '../../css/pages.scss';
import AppBar from '../../components/app-bar/updated-app-bar-component';
import SidebarTabs from '../../components/tabs/sidebar-tabs';
import { SalesBusinessDevelopmentTable } from 'components/sales/sales-business-development-table';
import { SalesConnections } from 'components/sales/sales-connections';
import { SalesAnalytics } from 'components/sales/sales-analytics';
import Toast from 'components/toast/toast';
import SalesPipeline from 'components/sales/sales-pipeline';
import { SalesCreateLead } from 'components/sales/sales-create-lead';
import { ManageSalesMenu } from 'components/sales/manage-sales-menu';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { currentSalesTabSelector } from 'store/salesSelectedOptions/selectors';
import { useGetSalesByFilter } from 'hooks/getSalesByFilter';
import {
  isLoadedSalesAnalyticsSelector, isLoadedSalesUserAnalyticsSelector, salesCurrentPageSelector,
  salesErrorSelector,
  salesLoadingSelector,
  salesSelector,
  selectedSalesRepAnalyticsSelector,
} from 'store/sales/selectors';
import {
  getBidTypes,
  getBusinessLines,
  getCompanyConnections,
  getPersonalConnections,
  getSalesAnalyticsPerStage,
  getSalesDivisions,
  getSalesMilestones,
  getSalesReps,
  getSalesRepsAnalyticsPerStage,
  getSalesServiceTypes,
} from 'store/sales/thunk';
import { SalesFilter } from 'components/sales/sales-filter';
import { useSalesSelectedOptions } from 'hooks/salesSelectedOptions';
import { resetCurrentPage, setIsLoadedAnalytics, setIsLoadedUserAnalytics } from 'store/sales/slice';
import { getConnectionsAnalytics } from 'store/connections/thunk';

export default function Sales() {
  const [openErrorToast, setOpenErrorToast] = useState(false);
  const [openCreateToast, setOpenCreateToast] = useState(false);
  const [openUpdateToast, setOpenUpdateToast] = useState(false);
  const [total, setTotal] = useState(0);
  const [probability, setProbability] = useState('');
  const [isLoadedBidTypes, setIsLoadedBidTypes] = useState(false);
  const [isLoadedServiceTypes, setIsLoadedServiceTypes] = useState(false);
  const [isLoadedDivisions, setIsLoadedDivisions] = useState(false);
  const [isLoadedMilestones, setIsLoadedMilestones] = useState(false);
  const [isPersonalConnectionsLoaded, setIsPersonalConnectionsLoaded] = useState(false);
  const [isCompanyConnectionsLoaded, setIsCompanyConnectionsLoaded] = useState(false);

  const getSales = useGetSalesByFilter();
  const selectedOptions = useSalesSelectedOptions();

  const dispatch = useAppDispatch();

  const currentTab = useAppSelector(currentSalesTabSelector);
  const currentPage = useAppSelector(salesCurrentPageSelector);
  const allSales = useAppSelector(salesSelector);
  const loading = useAppSelector(salesLoadingSelector);
  const error = useAppSelector(salesErrorSelector);

  const selectedSalesRep = useAppSelector(selectedSalesRepAnalyticsSelector);
  const isLoadedAnalytics = useAppSelector(isLoadedSalesAnalyticsSelector);
  const isLoadedUserAnalytics = useAppSelector(isLoadedSalesUserAnalyticsSelector);

  useEffect(() => {
    if (currentTab === 'analytics' && !isLoadedAnalytics) {
      dispatch(getSalesAnalyticsPerStage())
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(setIsLoadedAnalytics(true));
          }
        });
      dispatch(getConnectionsAnalytics());
    }
    if (currentTab === 'create new sale') {
      if (!isPersonalConnectionsLoaded) {
        dispatch(getPersonalConnections())
          .then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
              setIsPersonalConnectionsLoaded(true);
            }
          });
      }
      if (!isCompanyConnectionsLoaded) {
        dispatch(getCompanyConnections())
          .then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
              setIsCompanyConnectionsLoaded(true);
            }
          });
      }
    }
    if (!isLoadedBidTypes) {
      dispatch(getBidTypes())
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setIsLoadedBidTypes(true);
          }
        });
    }
    if (!isLoadedServiceTypes) {
      dispatch(getSalesServiceTypes())
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setIsLoadedServiceTypes(true);
          }
        });
    }
    if (!isLoadedDivisions) {
      dispatch(getSalesDivisions())
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setIsLoadedDivisions(true);
          }
        });
    }
    if (!isLoadedMilestones) {
      dispatch(getSalesMilestones())
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setIsLoadedMilestones(true);
          }
        });
    }
  }, [currentTab]);

  useEffect(() => {
    if (selectedSalesRep && currentTab === 'analytics' && !isLoadedUserAnalytics) {
      dispatch(getSalesRepsAnalyticsPerStage({ id: selectedSalesRep }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(setIsLoadedUserAnalytics(true));
          }
        });
    }
  }, [selectedSalesRep, currentTab, isLoadedUserAnalytics])

  useEffect(() => {
    if ([
      'direct sales pipeline',
      'rental sales pipeline',
      'business development',
    ].includes(currentTab)) {
      getSales();
    }
  }, [selectedOptions, currentPage]);

  useEffect(() => {
    getTotal();
  }, [allSales]);

  useEffect(() => {
    dispatch(getBusinessLines());
    dispatch(getSalesReps());
    dispatch(getCompanyConnections());
    dispatch(resetCurrentPage());
  }, []);

  const getTotal = () => {
    let sum = 0;
    let probabilitySum = 0;
    let probabilityCount = 0;
    if (allSales && allSales.length > 0) {
      allSales.forEach((sale) => {
        if (sale.value !== null) {
          sum += parseFloat(sale.value);
        }
        if (sale.probability_of_deal !== null) {
          probabilitySum += parseFloat(sale.probability_of_deal);
          probabilityCount++;
        }
      });
    }
    const averageProbability =
      probabilityCount > 0 ? probabilitySum / probabilityCount : 0;
    setTotal(sum.toLocaleString());
    setProbability(averageProbability.toFixed(2));
  };

  return (
    <>
      <AppBar colorPalette="navy" menu={true} title="Sales"/>
      <div className="sales-page">
        <div className="sidebar-content">
          <div className="portal-preview">
            {loading ? (
              <div className="sidebar-loading">
                <ProgressSpinnerNavy />
              </div>
            ) : (
              <div style={{ padding: '16px 25px 0' }}>
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#a4f2f1',
                      fontFamily: 'MagistralCondW08-Medium',
                      fontSize: '35px',
                    }}
                  >
                    ${total}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#EFEFEF',
                      fontFamily: 'Korolev Medium',
                      fontSize: '16px',
                    }}
                  >
                    Expected Total
                  </Typography>
                </div>
                <Divider
                  sx={{
                    borderColor: '#EFEFEF80',
                    marginBottom: '20px',
                    marginTop: '20px',
                  }}
                />
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#a4f2f1',
                      fontFamily: 'MagistralCondW08-Medium',
                      fontSize: '35px',
                    }}
                  >
                    {probability}%
                  </Typography>
                  <Typography
                    sx={{
                      color: '#EFEFEF',
                      fontFamily: 'Korolev Medium',
                      fontSize: '16px',
                    }}
                  >
                    Expected Probability
                  </Typography>
                </div>
                <Divider sx={{ borderColor: '#EFEFEF80', marginTop: '20px' }}/>
              </div>
            )}
            <div className="user-info-container" style={{ paddingTop: '37px' }}>
              <div
                style={{
                  color: '#EFEFEF',
                  fontFamily: 'MagistralCondW08-Medium',
                  fontSize: '35px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                }}
              >
                Option Panel
              </div>
            </div>
            {[
              'direct sales pipeline',
              'rental sales pipeline',
              'business development',
            ].includes(currentTab) && (
              <SalesFilter />
            )}
            <div className="tabs-container">
              <SidebarTabs
                colorPalette={'navy'}
                currentTab={currentTab}
                tabs={[
                  'direct sales pipeline',
                  'rental sales pipeline',
                  'business development',
                  'connections',
                  'analytics',
                  'create new sale',
                  'manage',
                ]}
                page="sales"
              />
            </div>
          </div>
        </div>
        {(currentTab.includes('sales pipeline') || currentTab === 'connections')
          ? (
            <div className="page-content" style={{ paddingTop: '0px' }}>
              {currentTab === 'direct sales pipeline' && <SalesPipeline />}
              {currentTab === 'rental sales pipeline' && <SalesPipeline />}
              {currentTab === 'connections' && <SalesConnections createButtonNeeded={true} searchBar={true} />}
            </div>
          ) : (
            <div className="page-content">
              {currentTab === 'business development' && <SalesBusinessDevelopmentTable />}
              {currentTab === 'analytics' && <SalesAnalytics />}
              {currentTab === 'create new sale' && (
                <SalesCreateLead />
              )}
              {currentTab === 'manage' && <ManageSalesMenu />}
            </div>
          )
        }
      </div>
      {openCreateToast && (
        <Toast
          open={openCreateToast}
          onClose={() => setOpenCreateToast(false)}
          message="Sale Added to Responsive Deployment"
        />
      )}
      {openUpdateToast && (
        <Toast
          open={openUpdateToast}
          onClose={() => setOpenUpdateToast(false)}
          message="Sale Updated"
        />
      )}
      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message={`ERROR: ${error}`}
        />
      )}
    </>
  );
}
