import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { formatPhoneNumber } from "shared/formatting";
import ProgressSpinner from 'components/progress/progress-spinner';

export default function UsersTable(props) {
  const {
    setOpenEditUserModal,
    setSelectedUser,
    tabValue,
    users,
    usersState,
    loading,
  } = props;

  const navigate = useNavigate();

  const [sortedUsers, setSortedUsers] = useState(null);
  const [sortBy, setSortBy] = useState('last_name');
  const [sortOrder, setSortOrder] = useState('ascend');

  useEffect(() => {
    if (users) {
      sortUsers(users);
    }
  }, [users, sortBy, sortOrder]);
  
  const sortUsers = (usersList) => {
    if (usersList) {
      if (sortOrder === 'ascend') {
        let myData = [...usersList];
        myData.sort(function (a, b) {
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) return -1;
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) return 1;
          return 0;
        });
        setSortedUsers(myData);
      } else if (sortOrder === 'descend') {
        const myData = [...usersList];
        myData.sort(function (a, b) {
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) return -1;
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) return 1;
          return 0;
        });
        setSortedUsers(myData);
      }
    }
  };

  const renderUsers = () => {
    let selectedUsers = [];
    if (sortedUsers && sortedUsers.length > 0) {
      sortedUsers.forEach((user, index) => {
        selectedUsers.push(
          <tr
            key={index}
            className={index === sortedUsers.length - 1 ? "contract-row" : "contract-row row-border" }
          >
            <td className="contract-data">
              {user.first_name} {user.last_name}
            </td>
            <td className="contract-data">
              {user.email}
            </td>
            <td className="contract-data">
              {user.phone && formatPhoneNumber(user.phone)}
            </td>
            <td className="contract-data" style={{ textAlign: "center" }}>
              <i
                className="fa-solid fa-pencil edit-icon"
                style={{ marginLeft: "0" }}
                onClick={() => {
                  setSelectedUser(user);
                  setOpenEditUserModal(true);
                }}
              />
            </td>
            {tabValue < 5 && (
              <td className="contract-data">
                <i 
                  className={`fa-solid fa-angle-right tab-arrow-icon green-arrow`}
                  onClick={() => {
                    if (tabValue < 4) {
                      navigate(`/employee-portal/${user.id}`)
                    } else if (tabValue === 4) {
                      setSelectedUser(user);
                      navigate(`/applicant-portal/${user.id}`, {
                        state: usersState,
                      });
                    }
                  }}
                />
              </td>
            )}
          </tr>
        );
      });
    }
    return selectedUsers;
  };
  
  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        className="content-container no-content-padding condensed"
      >
        <ProgressSpinner />
      </div>
    );
  }

  return (
    <div className="content-container no-content-padding condensed">
      <table className="contracts-table tableLayout">
        <thead>
          <tr className="row-border">
            <th scope="col" className="contracts-header tableLayout__th">
              <Button
                sx={{
                  color: '#EFEFEF',
                  fontFamily: 'MagistralCondW08-Medium',
                  fontSize: '1.25rem',
                  justifyContent: 'flex-start',
                  maxHeight: '24px',
                  padding: '0',
                  textAlign: 'left',
                  textTransform: 'capitalize',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  }
                }}
                onClick={() => {
                  if (sortBy === "last_name") {
                    setSortBy("first_name");
                  } else if (sortBy === "first_name") {
                    setSortBy("last_name");
                  }
                  setSortOrder("ascend");
                }}
              >
                {sortBy === "last_name" && "Last Name"}
                {sortBy === "first_name" && "First Name"}
              </Button>
              {sortBy && sortOrder === "ascend" && (
                <i
                  className="fa-solid fa-arrow-down edit-icon green-arrow"
                  style={{ marginLeft: "12px" }}
                  onClick={() => setSortOrder("descend")}
                />
              )}
              {sortBy && sortOrder === "descend" && (
                <i
                  className="fa-solid fa-arrow-up edit-icon green-arrow"
                  style={{ marginLeft: "12px" }}
                  onClick={() => setSortOrder("ascend")}
                />
              )}
            </th>
            <th scope="col" className="contracts-header url tableLayout__th">Email</th>
            <th scope="col" className="contracts-header personnel tableLayout__th">Phone Number</th>
            <th scope="col" className="contracts-header deadline tableLayout__th" style={{ textAlign: "center" }}>Edit</th>
            {tabValue < 5 && <th scope="col" className="arrow tableLayout__th"/>}
          </tr>
        </thead>
        <tbody>
          {renderUsers()}
        </tbody>
      </table>
    </div>
  );
};
