import React, { useEffect, useState } from 'react';
import Toast from 'components/toast/toast';
import API from 'API';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';

const initialErrors = {
  full_name: false,
  phone_number: false,
  email: false,
  address: false,
  city: false,
  state: false,
  zip_code: false,
  server_error: false,
};

export default function ConnectionsInfo({ connection, getConnection, loading }) {
  const token = JSON.parse(localStorage.getItem('token'));

  const [information, setInformation] = useState({});
  const [primaryPersonal, setPrimaryPersonal] = useState('');
  const [primaryCompany, setPrimaryCompany] = useState('');
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const personalConnections = connection?.personal_connections;
  const companyConnections = connection?.company_connections;

  const handleUpdate = () => {
    if (
      !information.full_name || 
      !information.phone_number ||
      !information.email ||
      !information.address ||
      !information.city ||
      !information.state ||
      !information.zip_code
      ) {
      setErrors({
        ...errors,
        full_name: !information.full_name,
        phone_number: !information.phone_number,
        email: !information.email,
        address: !information.address,
        city: !information.city,
        state: !information.state,
        zip_code: !information.zip_code,
      });
    } else {
      const prevPersonalPrimary = personalConnections.find((item) => item.is_primary)?.id;
      const prevCompanyPrimary = companyConnections.find((item) => item.is_primary)?.id;
      if (primaryPersonal !== prevPersonalPrimary) {
        updatePrimary(prevPersonalPrimary, primaryPersonal, 'personal');
      }
      if (primaryCompany !== prevCompanyPrimary) {
        updatePrimary(prevCompanyPrimary, primaryCompany, 'company');
      }
      try {
        setLoadingUpdate(true);
        let requestError = false;
        fetch(`${API.endpointURL}/api/connections/${connection.id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            full_name: information.full_name,
            phone_number: information.phone_number,
            email: information.email,
            address: information.address,
            city: information.city,
            state: information.state,
            zip_code: information.zip_code,
            title: information.title
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
        })
          .then((res) => {
            if (!res.ok) {
              requestError = true;
            }
            return res.json();
          })
          .then(() => {
            setLoadingUpdate(false);
            setOpenToast(true);
            if (requestError) {
              setErrors({
                ...errors,
                server_error: true,
              });
            } else {
              getConnection();
              setErrors({
                ...errors,
                server_error: false,
              });
            }
          });
      } catch (e) {
        setLoadingUpdate(false);
        setOpenToast(true);
        setErrors({
          ...errors,
          server_error: true,
        });
      }
    }
  };

  const updatePrimary = (prevPrimary, currentPrimary, type) => {
    const primaryValue = currentPrimary || prevPrimary;
    const personalKey = connection.connection_type === type
      ? `linked_${type}_connection_id` : `${type}_connection_id`;
    try {
      let requestError = false;
      fetch(`${API.endpointURL}/api/connections/${connection.connection_type}`
        + `-connections/${connection.id}/attach-${
          type === 'personal' ? type + '-connection' : type
        }`, {
        method: 'PATCH',
        body: JSON.stringify({
          [personalKey]: primaryValue,
          is_primary: Boolean(currentPrimary),
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json; charset=UTF-8',
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (!res.ok) {
            requestError = true;
          }
          return res.json();
        })
        .then(() => {
          if (requestError) {
            setOpenToast(true);
            setErrors({
              ...errors,
              server_error: true,
            });
          }
        });
    } catch (e) {
      setOpenToast(true);
    }
  };

  const handleChange = (event) => {
    setInformation({
      ...information,
      [event.target.name]: event.target.value,
    });
    if (!event.target.value.length) {
      setErrors({
        ...errors,
        [event.target.name]: true,
      });
    } else {
      setErrors({
        ...errors,
        [event.target.name]: false,
      });
    }
  };

  useEffect(() => {
    setInformation(connection);
    setPrimaryPersonal(personalConnections?.find((item) => item.is_primary)?.id);
    setPrimaryCompany(companyConnections?.find((item) => item.is_primary)?.id);
  }, [connection]);

  return (
    <section>
      <h1 className="content-header">General Information</h1>
      <div className="content-container">
        {loading ? (
          <div className="content-loading">
            <ProgressSpinnerNavy />
          </div>
        ) : (
          <>
            <div className="contract-form-section-header" style={{ marginBottom: '10px' }}>
              General Information
            </div>
            <div className="sale-form-section-content">
              <div className="sale-form-set">
                <div className="sale-form-label">Full Name</div>
                <input
                  className="sale-form-input"
                  placeholder="N/A"
                  value={information?.full_name || ''}
                  name="full_name"
                  onChange={handleChange}
                />
                {errors.full_name && <p className="sale-form-error">* Please enter data</p>}
              </div>
              <div className="sale-form-set">
                <div className="sale-form-label">Phone Number</div>
                <input
                  className="sale-form-input"
                  placeholder="N/A"
                  value={information?.phone_number || ''}
                  name="phone_number"
                  onChange={handleChange}
                />
                {errors.phone_number && <p className="sale-form-error">* Please enter data</p>}
              </div>
              <div className="sale-form-set">
                <div className="sale-form-label">Email</div>
                <input
                  className="sale-form-input"
                  placeholder="N/A"
                  value={information?.email || ''}
                  name="email"
                  onChange={handleChange}
                />
                {errors.email && <p className="sale-form-error">* Please enter data</p>}
              </div>
              <div className="sale-form-set">
                <div className="sale-form-label">Address</div>
                <input
                  className="sale-form-input"
                  placeholder="N/A"
                  value={information?.address || ''}
                  name="address"
                  onChange={handleChange}
                />
                {errors.address && <p className="sale-form-error">* Please enter data</p>}
              </div>
              <div className="sale-form-set">
                <div className="sale-form-label">City</div>
                <input
                  className="sale-form-input"
                  placeholder="N/A"
                  value={information?.city || ''}
                  name="city"
                  onChange={handleChange}
                />
                {errors.city && <p className="sale-form-error">* Please enter data</p>}
              </div>
              <div className="sale-form-set">
                <div className="sale-form-label">State</div>
                <input
                  className="sale-form-input"
                  placeholder="N/A"
                  value={information?.state || ''}
                  name="state"
                  onChange={handleChange}
                />
                {errors.state && <p className="sale-form-error">* Please enter data</p>}
              </div>
              <div className="sale-form-set">
                <div className="sale-form-label">Zip Code</div>
                <input
                  className="sale-form-input"
                  placeholder="N/A"
                  value={information?.zip_code || ''}
                  name="zip_code"
                  onChange={handleChange}
                />
                {errors.zip_code && <p className="sale-form-error">* Please enter data</p>}
              </div>

              {connection.connection_type === 'personal' && (
                <div className="sale-form-set">
                  <div className="sale-form-label">Title</div>
                  <input
                    className="sale-form-input"
                    placeholder="N/A"
                    value={information?.title || ''}
                    name="title"
                    onChange={handleChange}
                  />
                </div>
              )}
            </div>
            <div className="contract-form-section-header" style={{ marginBottom: '10px' }}>
              Primary Connections
            </div>
            <div className="sale-form-section-content">
              {connection.connection_type === 'personal' ? (
                <div className="sale-form-set">
                  <div className="sale-form-label">Primary Company on the Sale</div>
                  <select
                    className="sale-form-input"
                    placeholder="Select Primary Connection..."
                    value={primaryCompany || ''}
                    onChange={(e) => setPrimaryCompany(e.target.value)}
                    style={{ color: !primaryCompany ? '#EFEFEF80' : '#EFEFEF' }}
                  >
                    <option value="">Select Primary Connection...</option>
                    {companyConnections?.length > 0 ? companyConnections.map((connection) => (
                      <option key={connection.id} value={connection.id}>
                        {`${connection.full_name}`}
                      </option>
                    )) : (
                      <option value="" disabled>No company connections</option>
                    )}
                  </select>
                </div>
              ) : (
                <div className="sale-form-set">
                  <div className="sale-form-label">Primary Personal Connection</div>
                  <select
                    className="sale-form-input"
                    placeholder="Select Primary Connection..."
                    value={primaryPersonal || ''}
                    onChange={(e) => setPrimaryPersonal(e.target.value)}
                    style={{ color: !primaryPersonal ? '#EFEFEF80' : '#EFEFEF' }}
                  >
                    <option value="">Select Primary Connection...</option>
                    {personalConnections?.length > 0 ? personalConnections.map((connection) => (
                      <option key={connection.id} value={connection.id}>
                        {`${connection.full_name}`}
                      </option>
                    )) : (
                      <option value="" disabled>No personal connections</option>
                    )}
                  </select>
                </div>
              )}
              <div className="sale-form-button-container">
                {loadingUpdate && (
                  <div className="sale-form-spinner">
                    <ProgressSpinnerNavy />
                  </div>
                )}
                <button
                  className="create-button create-button--dark"
                  onClick={handleUpdate}
                >
                  Update
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={errors.server_error
            ? 'Something went wrong, try again later...'
            : 'Connection information successfully updated'
          }
        />
      )}
    </section>
  );
}
