import { createSlice } from '@reduxjs/toolkit';
import {
  createSubdivision, deleteSubdivision,
  getFilterableSubdivisions, getSubdivisionByDivisionAndContractId,
  getSubdivisionById,
  getSubdivisions,
  getSubdivisionsByDivisionId, updateSubdivision,
} from 'store/subdivisions/thunk';

const initialState = {
  subdivisions: [],
  filterableSubdivisions: [],
  filterableSubdivisionsLoading: false,
  filterableSubdivisionsError: '',
  subdivisionsError: '',
  subdivisionsMessage: '',
  isLoading: false,
  subdivisionsByDivisionId: [],
  
  subdivision: null,
  
  subdivisionsByDivisionAndContractId: [],
  selectedSubdivision: null,
};

const subdivisionsSlice = createSlice({
  name: 'subdivisionsSlice',
  initialState,
  reducers: {
    resetSubdivisionsByDivisionId (state) {
      state.subdivisionsByDivisionId = [];
    },
    resetSubdivision (state) {
      state.subdivision = null;
    },
    setSelectedSubdivision (state, { payload }) {
      state.selectedSubdivision = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSubdivisions.pending, (state) => {
      state.subdivisionsError = '';
      state.isLoading = true;
    });
    builder.addCase(getSubdivisions.fulfilled, (state, action) => {
      state.subdivisions = action.payload;
      state.subdivisionsError = '';
      state.isLoading = false;
    });
    builder.addCase(getSubdivisions.rejected, (state) => {
      state.subdivisionsError = 'Error getting subdivisions.';
      state.isLoading = false;
    });
    builder.addCase(getSubdivisionsByDivisionId.pending, (state) => {
      state.subdivisionsError = '';
      state.isLoading = true;
    });
    builder.addCase(getSubdivisionsByDivisionId.fulfilled, (state, action) => {
      state.subdivisionsByDivisionId = action.payload;
      state.subdivisionsError = '';
      state.isLoading = false;
    });
    builder.addCase(getSubdivisionsByDivisionId.rejected, (state) => {
      state.subdivisionsError = 'Error getting subdivisions.';
      state.isLoading = false;
    });
    builder.addCase(getSubdivisionById.pending, (state) => {
      state.subdivisionsError = '';
      state.isLoading = true;
    });
    builder.addCase(getSubdivisionById.fulfilled, (state, action) => {
      state.subdivision = action.payload;
      state.subdivisionsError = '';
      state.isLoading = false;
    });
    builder.addCase(getSubdivisionById.rejected, (state) => {
      state.subdivisionsError = 'Error getting subdivision.';
      state.isLoading = false;
    });
    builder.addCase(getFilterableSubdivisions.pending, (state) => {
      state.filterableSubdivisionsError = '';
      state.filterableSubdivisionsLoading = true;
    });
    builder.addCase(getFilterableSubdivisions.fulfilled, (state, action) => {
      state.filterableSubdivisions = action.payload;
      state.filterableSubdivisionsError = '';
      state.filterableSubdivisionsLoading = false;
    });
    builder.addCase(getFilterableSubdivisions.rejected, (state) => {
      state.filterableSubdivisionsError = 'Error getting subdivisions.';
      state.filterableSubdivisionsLoading = false;
    });
    
    builder.addCase(getSubdivisionByDivisionAndContractId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubdivisionByDivisionAndContractId.fulfilled, (state, action) => {
      state.subdivisionsByDivisionAndContractId = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getSubdivisionByDivisionAndContractId.rejected, (state) => {
      state.subdivisionsMessage = 'Error getting subdivisions.';
      state.isLoading = false;
    });
    
    builder.addCase(createSubdivision.pending, (state) => {
      state.isLoading = true;
      state.subdivisionsMessage = '';
    });
    builder.addCase(createSubdivision.fulfilled, (state, action) => {
      state.selectedSubdivision = action.payload;
      state.isLoading = false;
      state.subdivisionsMessage = 'Subdivision successfully created.';
    });
    builder.addCase(createSubdivision.rejected, (state) => {
      state.subdivisionsMessage = 'Error getting creating subdivision.';
      state.isLoading = false;
    });
    
    builder.addCase(updateSubdivision.pending, (state) => {
      state.isLoading = true;
      state.subdivisionsMessage = '';
    });
    builder.addCase(updateSubdivision.fulfilled, (state, action) => {
      state.selectedSubdivision = action.payload;
      state.isLoading = false;
      state.subdivisionsMessage = 'Subdivision successfully updated.';
    });
    builder.addCase(updateSubdivision.rejected, (state) => {
      state.subdivisionsMessage = 'Error updating subdivision.';
      state.isLoading = false;
    });
    
    builder.addCase(deleteSubdivision.pending, (state) => {
      state.isLoading = true;
      state.subdivisionsMessage = '';
    });
    builder.addCase(deleteSubdivision.fulfilled, (state) => {
      state.selectedSubdivision = null;
      state.isLoading = false;
      state.subdivisionsMessage = 'Subdivision successfully deleted.';
    });
    builder.addCase(deleteSubdivision.rejected, (state) => {
      state.subdivisionsMessage = 'Error deleting subdivision. Try again later.';
      state.isLoading = false;
      state.selectedSubdivision = null;
    });
  },
});

export const {
  resetSubdivisionsByDivisionId,
  resetSubdivision,
  setSelectedSubdivision,
} = subdivisionsSlice.actions;

export default subdivisionsSlice.reducer;