import React from "react";

export default function EmployeeAlerts() {
  return (
    <>
      <h1 className="content-header">Alerts</h1>
      <div className="content-container">
        Alerts coming soon!
      </div>
    </>
  );
};
