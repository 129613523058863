import React, { useState, Fragment } from 'react';
import API from 'API';
import Toast from 'components/toast/toast';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { bidTypesLoadingSelector, bidTypesSelector } from 'store/sales/selectors';
import { getBidTypes } from 'store/sales/thunk';

const initialErrors = {
  validation: false,
  server: false,
};

export const ManageBidTypes = () => {

  const token = JSON.parse(localStorage.getItem('token'));
  const dispatch = useAppDispatch();

  const [inputTypeName, setInputTypeName] = useState('');
  const [bidTypeName, setBidTypeName] = useState({});
  const [message, setMessage] = useState('Please enter bid type name');
  const [isCreating, setIsCreating] = useState(true);
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const [loading, setLoading] = useState(false);

  const bidTypeList = useAppSelector(bidTypesSelector);
  const bidTypeLoading = useAppSelector(bidTypesLoadingSelector);

  const handleItemClick = (item) => {
    setIsCreating(false);
    setBidTypeName(item);
    setInputTypeName(item.name);
  };

  const handleInputChange = (e) => {
    if (!e.target.value) {
      setMessage('Please enter bid type name');
      setErrors({
        ...errors,
        validation: true,
      });
    } else if (bidTypeList?.find(item => item.name === e.target.value)) {
      setMessage('Bid type already exists');
      setErrors({
        ...errors,
        validation: true,
      });
    } else {
      setMessage(`Bid type is successfully ${isCreating ? 'created' : 'updated'}`);
      setErrors({
        ...errors,
        validation: false,
      });
    }
    setInputTypeName(e.target.value);
  };

  const handleCreate = () => {
    if (isCreating) {
      if (inputTypeName && !errors.validation) {
        setLoading(true);
        createBidType({ name: inputTypeName });
        setInputTypeName('');
      } else {
        setOpenToast(true);
        if (!inputTypeName) {
          setMessage('Please enter valid bid type name...');
        }
      }
    } else {
      setIsCreating(true);
      setInputTypeName('');
    }
  };

  const handleUpdate = () => {
    setOpenToast(true);
    if (!errors.validation) {
      setIsCreating(true);
      setLoading(true);
      updateBidType({ name: inputTypeName, id: bidTypeName.id });
      setInputTypeName('');
    }
  };

  const updateBidType = (data) => {
    let requestError = false;

    try {
      fetch(`${API.endpointURL}/api/bid_types/${data.id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((res) => {
        if (!res.ok) {
          requestError = true;
        }
        return res;
      })
      .then((res) => res.json())
      .then(() => {
        !requestError && dispatch(getBidTypes());
      })
      .then(() => {
        setOpenToast(true);
        setLoading(false);
        if (requestError) {
          setErrors({
            ...errors,
            server: true,
          });
        } else {
          setErrors({
            ...errors,
            server: false,
          });
        }
      });
    } catch (e) {
      setMessage(e.message);
      setLoading(false);
    }
  };

  const createBidType = (data) => {
    setLoading(true);
    let requestError = false;
    try {
      fetch(`${API.endpointURL}/api/bid_types`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            requestError = true;
          }
          return res;
        })
        .then((res) => res.json())
        .then(() => {
          !requestError && dispatch(getBidTypes());
        })
        .then(() => {
          setOpenToast(true);
          if (requestError) {
            setLoading(false);
            setErrors({
              ...errors,
              server: true,
            });
          } else {
            setLoading(false);
            setErrors({
              ...errors,
              server: false,
            });
          }
        })
        .catch((error) => {
          console.log(error, 'Error create Bid Type.');
        });
    } catch (e) {
      setMessage(e.message);
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', gap: '10px' }}>
        <div className="content-container" style={{ height: 'calc(100vh - 236px)' }}>
          <div
            className="service-buttons"
            style={{ display: 'flex', alignItems: 'center', padding: '0 0 15px', gap: '10px' }}
          >
            <h3>Choose option:</h3>
            {!isCreating && (
              <button
                className="create-button"
                onClick={handleUpdate}
              >
                Update
              </button>
            )}
            <button
              className="create-button"
              onClick={handleCreate}
            >
              {isCreating ? 'Create' : 'Create new one'}
            </button>
          </div>

          <input
            className="sale-form-input"
            placeholder="Enter bid type name..."
            value={inputTypeName || ''}
            name="bid_name"
            onChange={handleInputChange}
            style={{ width: '100%' }}
          />
        </div>
        <div className="content-container" style={{ height: 'calc(100vh - 236px)' }}>
          {(loading || bidTypeLoading) ? (
            <div className="content-loading">
              <ProgressSpinnerNavy />
            </div>
          ) : (
            <>
              {bidTypeList?.length ? (
                <h3 style={{ margin: '0 10px 10px' }}>All bid types:</h3>
              ) : <h3>Create a bid type</h3>}
              {bidTypeList?.map((item) => (
                <div
                  onClick={() => handleItemClick(item)}
                  key={item?.id}
                  className="sales-division"
                >
                  <h3>{item.name}</h3>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={message}
        />
      )}
    </>
  );
};
