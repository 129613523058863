import { useAppSelector } from 'hooks/reduxHooks';
import {
  companyFilterSelector,
  contractFilterIdSelector, currentPageSelector,
  currentSortOptionSelector,
  currentSortOrderSelector, currentTabSelector,
  psqAlertFilterSelector,
  recruiterFilterIdSelector,
  regionFilterIdSelector,
  siteFilterIdSelector,
  textAlertFilterSelector,
} from 'store/staffingSelectedOptions/selectors';

export const useSelectedOptions = () => {
  const currentPage = useAppSelector(currentPageSelector);
  const currentTab = useAppSelector(currentTabSelector);
  const recruiterFilterId = useAppSelector(recruiterFilterIdSelector);
  const companyFilter = useAppSelector(companyFilterSelector);
  const siteFilterId = useAppSelector(siteFilterIdSelector);
  const regionFilterId = useAppSelector(regionFilterIdSelector);
  const contractFilterId = useAppSelector(contractFilterIdSelector);
  const textAlertFilter = useAppSelector(textAlertFilterSelector);
  const psqAlertFilter = useAppSelector(psqAlertFilterSelector);
  const currentSortOption = useAppSelector(currentSortOptionSelector);
  const currentSortOrder = useAppSelector(currentSortOrderSelector);

  return [
    currentTab,
    recruiterFilterId,
    companyFilter,
    siteFilterId,
    regionFilterId,
    contractFilterId,
    textAlertFilter,
    psqAlertFilter,
    currentSortOption,
    currentSortOrder,
    currentPage,
  ]
}