export const salesSelector = (state) => state.sales.sales;
export const salesCurrentPageSelector = (state) => state.sales.currentPage;
export const salesTotalPagesSelector = (state) => state.sales.totalPages;
export const saleSelector = (state) => state.sales.sale;
export const salesQuotesSelector = (state) => state.sales.salesQuotes;
export const salesLoadingSelector = (state) => state.sales.loading;
export const salesErrorSelector = (state) => state.sales.salesError;
export const businessLinesSelector = (state) => state.sales.businessLines;
export const businessLinesLoadingSelector = (state) => state.sales.businessLinesLoading;
export const companyConnectionsSelector = (state) => state.sales.companyConnections;
export const companyConnectionsLoadingSelector = (state) => state.sales.companyConnectionsLoading;
export const personalConnectionsSelector = (state) => state.sales.personalConnections;
export const personalConnectionsLoadingSelector = (state) => state.sales.personalConnectionsLoading;
export const salesRepsSelector = (state) => state.sales.salesReps;
export const salesRepsLoadingSelector = (state) => state.sales.salesRepsLoading;
export const salesAnalyticsPerStageSelector = (state) => state.sales.analyticsPerStage;
export const salesAnalyticsLoadingSelector = (state) => state.sales.analyticsLoading;
export const selectedSalesRepAnalyticsSelector = (state) => state.sales.selectedSalesRepAnalytics;
export const salesRepStageAnalyticsSelector = (state) => state.sales.analyticsRepsPerStage;
export const salesRepStageAnalyticsLoadingSelector = (state) => state.sales.analyticsRepsLoading;
export const isLoadedSalesAnalyticsSelector = (state) => state.sales.isLoadedAnalytics;
export const isLoadedSalesUserAnalyticsSelector = (state) => state.sales.isLoadedUserAnalytics;
export const bidTypesSelector = (state) => state.sales.management.bidTypes;
export const bidTypesLoadingSelector = (state) => state.sales.management.bidTypesLoading;
export const serviceTypesSelector = (state) => state.sales.management.serviceTypes;
export const serviceTypesLoadingSelector = (state) => state.sales.management.serviceTypesLoading;
export const salesDivisionsSelector = (state) => state.sales.management.divisions;
export const salesDivisionsLoadingSelector = (state) => state.sales.management.divisionsLoading;
export const milestonesSelector = (state) => state.sales.management.milestones;
export const milestonesLoadingSelector = (state) => state.sales.management.milestonesLoading;
export const salesNotesSelector = (state) => state.sales.notes;
