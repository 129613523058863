import { axiosService } from 'services/axiosServices';

const serializableHeaders = {
  "Content-type": "application/json; charset=UTF-8",
  Accept: "application/json",
};
const formDataHeaders = {
  "Content-type": 'multipart/form-data',
  Accept: "application/json",
  Connection: "keep-alive",
};

export const files = {
  getApplicantFiles: async (id) => {
    const res = await axiosService.get(`/api/files?user_id=${id}`);
    return res.data.data;
  },
  approveFiles: async (id, type) => {
    const res = await axiosService.put(
      `/api/files/approve_all_type/${id}`,
      {
        user_id: id,
        approved: true,
        type: type,
      },
      { headers: serializableHeaders });
    return res.data.data;
  },
  approveAllFiles: async (id) => {
    const res = await axiosService.post(
      `/api/files/approve_all/${id}`,
      {
        user_id: id,
      },
      { headers: serializableHeaders });
    return res.data.data;
  },
  uploadFile: async (body) => {
    const res = await axiosService.post(
      `/api/upload`,
      body,
      { headers: formDataHeaders });
    return res.data.data;
  },
}
