import React, { useEffect, useState } from "react";

import StaffingDashboard from 'components/contracts-analytics/staffing/staffing-dashbord';
import RecruiterDashboard from 'components/contracts-analytics/recruiter/recruiter-dashboard';

import { getRecruiters } from 'store/recruiters/thunk';
import { useAppDispatch } from 'hooks/reduxHooks';

export default function AllContractsAnalytics({ color }) {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  
  const dispatch = useAppDispatch();
  
  const [view, setView] = useState("");
  const [currentRecruiter, setCurrentRecruiter] = useState(null);

  useEffect(() => {
    if (loggedInUser.role === "admin" || loggedInUser.role === "staffing" || loggedInUser.role === "accounting") {
      setView("staffing");
      dispatch(getRecruiters());
    } else {
      setView("recruiter");
      setCurrentRecruiter(loggedInUser.id);
    }
  }, []);
  
  return (
    <>
      {view === "staffing" && (
        <StaffingDashboard
          color={color}
          currentRecruiter={currentRecruiter}
          setCurrentRecruiter={setCurrentRecruiter}
        />
      )}
      {view === "recruiter" && (
        <RecruiterDashboard
          color={color}
          currentRecruiter={currentRecruiter}
          setCurrentRecruiter={setCurrentRecruiter}
        />
      )}
    </>
  );
};
