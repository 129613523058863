import '../../css/staffing.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material';
import FinalizeAutomation from '../automation/finalize-automation';
import InterviewAutomation from '../automation/interview-automation';
import PreScreeningAutomation from '../automation/pre-screening-automation';
import SecurityAutomation from '../automation/security-automation';
import USGDocsAutomation from '../automation/usg-docs-automation';
import DispositionDialog from '../modal/disposition-modal';
import EmailDialog from '../email/email-dialog';
import UserComms from '../modal/user-comms';
import StaffingDropBox from '../drop-box/staffing-drop-box';
import StaffingModuleTabs from '../tabs/staffing-module-tabs';
import NotesModule from '../notes/notes';
import Toast from '../toast/toast';
import { formatPhoneNumber, formatUTCDate } from 'shared/formatting';
import {
  GrayAutoStyledButton,
  HighlightGreenAutoStyledButton,
} from '../buttons/button-styles';
import AlertsModule from 'components/alerts/alerts';
import StaffingDocumentsModal from 'components/modal/staffing-documents-modal';
import HiredAutomation from 'components/automation/hired-automation';

import API from 'API';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getContractTemplates } from 'store/contracts/thunk';
import { contractTemplatesSelector } from 'store/contracts/selectors';
import { setContractTemplates } from 'store/contracts/slice';
import {
  applicantLoadingSelector,
  applicantSelector,
  currentApplicantSelector,
} from 'store/applicants/selectors';
import { getApplicant } from 'store/applicants/thunk';
import { setEditedApplicantId } from 'store/applicants/slice';
import { sidebarCollapseSelector } from 'store/ui/selectors';
import ProgressSpinner from 'components/progress/progress-spinner';
import NewApplicantsAutomation from 'components/automation/new-applicants-automation';
import { useGetApplicantsByStage } from 'hooks/getApplicantsByStage';
import notAllowedMessages from 'assets/bxs-message-rounded-check.svg';
import allowedMessages from 'assets/bxs-message-rounded-x.svg';
import { isSearchedApplicantsSelector } from 'store/staffingSelectedOptions/selectors';
import { useGetSearchedApplicants } from 'hooks/getSearchedApplicants';

export default function StaffingProfile(props) {
  const {
    updateApplicant,
    currentUser,
  } = props;
  
  const dispatch = useAppDispatch();
  const sidebarCollapse = useAppSelector(sidebarCollapseSelector);
  const templates = useAppSelector(contractTemplatesSelector);
  const currentApplicant = useAppSelector(currentApplicantSelector);
  const applicantData = useAppSelector(applicantSelector);
  const loadingApplicant = useAppSelector(applicantLoadingSelector);
  
  const token = JSON.parse(localStorage.getItem('token'));
  const navigate = useNavigate();
  const isSearched = useAppSelector(isSearchedApplicantsSelector);
  const [currentTab, setCurrentTab] = useState(0);
  const [openCommsDialog, setOpenCommsDialog] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [openDispositionDialog, setOpenDispositionDialog] = useState(false);
  const [openDispositionToast, setOpenDispositionToast] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openEmailToast, setOpenEmailToast] = useState(false);
  const [loadingSendEmail, setLoadingSetEmail] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const getApplicants = useGetApplicantsByStage();
  const searchApplicants = useGetSearchedApplicants();
  
  useEffect(() => {
    setCurrentTab(0);
    if (!applicantData?.application?.contract_id || !currentApplicant?.application?.contract_id ) {
      dispatch(setContractTemplates([]));
    } else {
      getTemplates();
    }
  }, [applicantData, applicantData?.application?.contract_id]);
  
  const getTemplates = () => {
    if (!currentApplicant && !applicantData){
      return;
    }
    
    const contractId = applicantData?.application?.contract_id;
    if (contractId) {
      dispatch(getContractTemplates({ contractId }));
    }
  };
  
  useEffect(() => {
    const stage = applicantData?.application?.stage;
    const templateTypes = {
      1: 'new_applicant',
      2: 'pre_screening',
      3: 'interview',
      4: 'usg_documents',
      5: 'security',
      6: 'finalize',
      7: 'hired',
    };
    const types = templateTypes[stage];
    if (!types) return;
    setFilteredTemplates(templates?.filter(item => item.template_type.includes(types)));
  }, [templates]);
  
  const handleApproveFile = async (
    fileId,
    approvalStatus,
    approveAllFilesOfType,
    type,
    userId,
  ) => {
    await fetch(`${API.endpointURL}/api/files`, {
      method: 'PUT',
      body: JSON.stringify({
        file_id: fileId,
        approved: approvalStatus,
        approveAllFilesOfType: approveAllFilesOfType,
        type: type,
        user_id: userId,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => {
      response.json();
      if (response.status === 202) {
        dispatch(getApplicant({ applicantId: currentApplicant.id }));
      }
    });
  };
  
  const reviewFile = (item, value, type) => {
    updateApplicant(item, value);
    handleApproveFile(null, value, true, type, currentApplicant.id);
  };
  
  const handleSendEmail = async (
    type,
    templateIndex,
    applicantKey,
    setOpenErrorToast,
  ) => {
    setLoadingSetEmail(true);
    setBlockLoading(true);
    if (
      filteredTemplates[templateIndex].email_active === true &&
      filteredTemplates[templateIndex].active === true
    ) {
      let url, body;
      url = `${API.endpointURL}/api/send_email_template/`;
      body = {
        user_id: currentApplicant.id,
        template_id: Number(filteredTemplates[templateIndex].id),
      };
      
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then((response) => {
        if (response.status === 200) {
          setOpenEmailToast(true);
          updateApplicant(applicantKey, new Date().toISOString());
        } else {
          setOpenErrorToast(true);
        }
        setLoadingSetEmail(false);
      });
    } else {
      updateApplicant(applicantKey, new Date().toISOString());
      setLoadingSetEmail(false);
    }
  };
  
  const setButton = (
    templateIndex,
    updateKey,
    updateKey2,
    setOpenErrorToast,
  ) => {
    let button = <div></div>;
    let confirmation = <div></div>;
    
    if (filteredTemplates[templateIndex]?.active === true) {
      applicantData.application[updateKey]
        ? (button = (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' }}>
            <Checkbox
              key={`${updateKey}CheckDisabled`}
              defaultChecked
              onClick={() => updateApplicant(updateKey, null)}
              sx={{
                color: '#EFEFEF',
                '&.Mui-checked': {
                  color: '#62AB37',
                },
                '&.Mui-disabled': {
                  color: '#EFEFEF40',
                },
              }}
            />
            {filteredTemplates[templateIndex]?.email_active === true ? (
              <GrayAutoStyledButton disabled>
                Sent Email For {filteredTemplates[templateIndex].template_name}
              </GrayAutoStyledButton>
            ) : (
              <GrayAutoStyledButton disabled>
                {filteredTemplates[templateIndex].template_name}
              </GrayAutoStyledButton>
            )}
          </div>
        ))
        : (button = (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox
              key={`${updateKey}CheckEnabled`}
              disabled
              sx={{
                color: '#EFEFEF',
                '&.Mui-checked': {
                  color: '#62AB37',
                },
                '&.Mui-disabled': {
                  color: '#EFEFEF40',
                },
              }}
            />
            {filteredTemplates[templateIndex]?.email_active === true ? (
              updateKey === 'pre_screen_button_1' ? (
                <GrayAutoStyledButton
                  disabled={loadingSendEmail}
                  onClick={() => {
                    handleSendEmail(
                      'preScreen',
                      templateIndex,
                      updateKey,
                      setOpenErrorToast,
                    );
                  }
                  }
                >
                  Send email for {filteredTemplates[templateIndex].template_name}
                </GrayAutoStyledButton>
              ) : (
                <GrayAutoStyledButton
                  disabled={loadingSendEmail}
                  onClick={() =>
                    handleSendEmail(
                      'emailTemplate',
                      templateIndex,
                      updateKey,
                      setOpenErrorToast,
                    )
                  }
                >
                  Send email for {filteredTemplates[templateIndex].template_name}
                </GrayAutoStyledButton>
              )
            ) : (
              <GrayAutoStyledButton
                onClick={() =>
                  updateApplicant(updateKey, new Date().toISOString())
                }
              >
                {filteredTemplates[templateIndex].template_name}
              </GrayAutoStyledButton>
            )}
          </div>
        ));
    }
    if (filteredTemplates[templateIndex]?.file_active === true) {
      applicantData.application[updateKey2]
        ? (confirmation = (
          <div style={{ fontFamily: 'Korolev Medium', textAlign: 'left' }}>
            <Checkbox
              key={`${updateKey2}CheckDisabled`}
              defaultChecked
              onClick={() =>
                reviewFile(
                  updateKey2,
                  null,
                  filteredTemplates[templateIndex].template_type,
                )
              }
              sx={{
                color: '#EFEFEF',
                '&.Mui-checked': {
                  color: '#62AB37',
                },
                '&.Mui-disabled': {
                  color: '#EFEFEF40',
                },
              }}
            />
            Reviewed file for {filteredTemplates[templateIndex].template_name}
          </div>
        ))
        : (confirmation = (
          <div style={{ fontFamily: 'Korolev Medium', textAlign: 'left' }}>
            <Checkbox
              key={`${updateKey2}CheckEnabled`}
              onClick={() =>
                reviewFile(
                  updateKey2,
                  new Date().toISOString(),
                  filteredTemplates[templateIndex].template_type,
                )
              }
              sx={{
                color: '#EFEFEF',
                '&.Mui-checked': {
                  color: '#62AB37',
                },
                '&.Mui-disabled': {
                  color: '#EFEFEF40',
                },
              }}
            />
            Review file for {filteredTemplates[templateIndex].template_name}
          </div>
        ));
    }
    
    const { stage } = applicantData.application;
    
    return (
      <div>
        {button}
        {stage <= 3 && stage >= 7 && confirmation}
      </div>
    );
  };
  
  const buildAutomation = () => {
    switch (applicantData?.application?.stage) {
      case '1':
        return (
          <NewApplicantsAutomation
            loadingSendEmail={loadingSendEmail}
            reviewFile={reviewFile}
            setButton={setButton}
            setBlockLoading={setBlockLoading}
            applicantData={applicantData}
          />
        );
      case '2':
        return (
          <PreScreeningAutomation setButton={setButton}/>
        );
      case '3':
        return (
          <InterviewAutomation
            setButton={setButton}
            updateApplicant={updateApplicant}
          />
        );
      case '4':
        return (
          <USGDocsAutomation
            setButton={setButton}
            handleUpdateApplicants={() => dispatch(getApplicant({ applicantId: currentApplicant.id }))}
          />
        );
      case '5':
        return (
          <SecurityAutomation
            setButton={setButton}
            updateApplicant={updateApplicant}
            handleUpdateApplicants={() => dispatch(getApplicant({ applicantId: currentApplicant.id }))}
          />
        );
      case '6':
        return (
          <FinalizeAutomation
            setButton={setButton}
            handleSendEmail={handleSendEmail}
            updateApplicant={updateApplicant}
          />
        );
      case '7':
        return <HiredAutomation setButton={setButton}/>;
      default:
        return '';
    }
  };
  
  return (
    <div
      className={classNames('staffing__profile', {
        'staffing__profile--collapsed': sidebarCollapse,
      })}
    >
      <div className="staffing-profile-header">
        {currentApplicant &&
          `${currentApplicant?.first_name} ${currentApplicant?.last_name}`}
      </div>
      <Card
        sx={{
          height: '100%',
          border: '1px solid #EFEFEF80',
          borderRadius: '0',
          borderBottomRightRadius: '10px',
          backgroundColor: 'transparent',
          color: '#EFEFEF',
          boxShadow: 'none',
          overflow: 'auto',
        }}
      >
        {(loadingApplicant && !blockLoading) ? (
          <div className="content-loading">
            <ProgressSpinner />
          </div>
        ) : (
          <>
            <CardContent sx={{ padding: '24px 24px 0' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="column">
                  <div className="profile-section-header">Info</div>
                  <div className="profile-info">
                    <div className="profile-key">
                      Email
                    </div>
                    <a
                      href="#"
                      onClick={() => setOpenEmailDialog(true)}
                      className="profile-key-value">
                      {applicantData
                        ? ` ${applicantData?.email}`
                        : ' Select a User'}
                    </a>
                  </div>
                  <div className="profile-info">
                    <div className="profile-key">
                      Phone
                    </div>
                    <a
                      href="#"
                      onClick={() => {
                        setOpenCommsDialog(true);
                        setBlockLoading(true);
                      }}
                      className="profile-key-value"
                    >
                      {applicantData
                        ? <>
                          {formatPhoneNumber(applicantData?.phone)}
                          <Tooltip title={applicantData.can_receive_messages ? 'Allowed' : 'Not Allowed'}>
                            <IconButton
                              sx={{
                                padding: 0,
                                ml: 1,
                                mb: 1,
                                "&:hover": { backgroundColor: "transparent", cursor: 'inherit' },
                              }}
                            >
                              <img
                                src={applicantData.can_receive_messages ? allowedMessages : notAllowedMessages}
                                className="profile-icon--messages"
                                alt={applicantData.can_receive_messages ? 'Allowed' : 'Not Allowed'}
                              />
                            </IconButton>
                          </Tooltip>
                        </>
                        : ' Select a User'}
                    </a>
                  </div>
                  <div>
                    <div className="profile-key">Recruiter</div>
                    <div className="profile-key-value">
                      {applicantData &&
                        (applicantData?.application?.recruiter_data?.id
                          ? `${applicantData?.application.recruiter_data?.first_name} ${applicantData?.application?.recruiter_data?.last_name}`
                          : 'Not Assigned')}
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="profile-section-header">Metrics</div>
                  <div className="row">
                    <div className="profile-metrics">
                      <div className="profile-key">Date entered stage</div>
                      <div className="profile-key-value">
                        {applicantData?.application?.applicant_metrics
                          ?.date_entered_stage
                          ? `${formatUTCDate(
                            applicantData.application?.applicant_metrics
                              ?.date_entered_stage,
                          )}
                        - ${applicantData.application?.applicant_metrics
                            ?.days_spent_in_stage
                          } days`
                          : 'N/A'}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="profile-metrics">
                      <div className="profile-key">Date applied</div>
                      <div className="profile-key-value">
                        {applicantData?.application?.applicant_metrics
                          ?.date_entered_app
                          ? `${formatUTCDate(
                            applicantData.application?.applicant_metrics
                              ?.date_entered_app,
                          )}
                        - ${applicantData.application?.applicant_metrics
                            ?.days_spent_in_app
                          } days`
                          : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Divider sx={{ borderColor: '#EFEFEF80', margin: '8px 0' }}/>
              <div
                style={{
                  display: 'flex',
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                <div className="profile-status profile-info">
                  <div className="profile-key">RD Status</div>
                  <div className="profile-key-value">
                    {applicantData?.application?.rd_status
                      ? ` ${applicantData.application?.rd_status}`
                      : ' None'}
                  </div>
                </div>
                <div className="profile-status">
                  <div className="profile-key">Candidate Status (Public)</div>
                  <div className="profile-key-value">
                    {applicantData?.application?.candidate_status
                      ? ` ${applicantData.application?.candidate_status}`
                      : ' None'}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                <div className="profile-status profile-info">
                  <div className="profile-key">USG Status</div>
                  <div className="profile-key-value">
                    {applicantData?.application?.status
                      ? ` ${applicantData?.application?.status}`
                      : ' None'}
                  </div>
                </div>
                <div className="profile-status">
                  <div className="profile-key">Next Step (Public)</div>
                  <div className="profile-key-value">
                    {applicantData?.application?.next_step
                      ? ` ${applicantData?.application?.next_step}`
                      : ' None'}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  margin: '16px 0',
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                <div>
                  <HighlightGreenAutoStyledButton
                    onClick={() => {
                      navigate(`/applicant-portal/${applicantData?.id}`);
                    }}
                  >
                    Applicant Portal
                  </HighlightGreenAutoStyledButton>
                </div>
                <div>
                  <Tooltip title="Status">
                <span>
                  <IconButton
                    onClick={() => setOpenDispositionDialog(true)}
                    disabled={!applicantData}
                    sx={{
                      paddingTop: '2',
                      width: '40px',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                  <SwapHorizIcon
                    sx={{
                      color: '#EFEFEF',
                      '&:hover': {
                        color: '#62AB37',
                      },
                    }}
                  />
                </IconButton>
                </span>
                  </Tooltip>
                  <Tooltip title="Documents">
                <span>
                    <IconButton
                      onClick={() => {
                        setOpenDocuments(true);
                        setBlockLoading(true);
                      }}
                      disabled={!applicantData}
                      sx={{
                        color: '#EFEFEF',
                        width: '40px',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          color: '#62AB37',
                        },
                      }}
                    >
                  <FolderOpenIcon
                    sx={
                      applicantData?.application?.document_alert
                        ? {
                          color: '#DFB727',
                          '&:hover': {
                            color: '#62AB37',
                          },
                        }
                        : {
                          color: '#EFEFEF',
                          '&:hover': {
                            color: '#62AB37',
                          },
                        }
                    }
                  />
                </IconButton>
                </span>
                  </Tooltip>
                  <Tooltip title="Text Messages">
                <span>
                  <IconButton
                    onClick={() => {
                      setOpenCommsDialog(true);
                      setBlockLoading(true);
                    }}
                    disabled={!applicantData}
                    sx={{
                      width: '40px',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                  <ChatBubbleOutlineIcon
                    sx={
                      applicantData?.application?.text_alert
                        ? {
                          color: '#B84A32',
                          '&:hover': {
                            color: '#62AB37',
                          },
                        }
                        : {
                          color: '#EFEFEF',
                          '&:hover': {
                            color: '#62AB37',
                          },
                        }
                    }
                  />
                </IconButton>
                </span>
                  </Tooltip>
                  <Tooltip title="Send Email">
                <span>
                  <IconButton
                    onClick={() => setOpenEmailDialog(true)}
                    disabled={!applicantData}
                    sx={{
                      width: '40px',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                  <MailOutlineIcon
                    sx={{
                      color: '#EFEFEF',
                      '&:hover': {
                        color: '#62AB37',
                      },
                    }}
                  />
                </IconButton>
                </span>
                  </Tooltip>
                </div>
                {openDocuments && (
                  <StaffingDocumentsModal
                    open={openDocuments}
                    handleClose={() => {
                      setOpenDocuments(false);
                      setBlockLoading(false);
                      dispatch(setEditedApplicantId(currentApplicant.id));
                      getApplicants();
                    }}
                    user={currentUser}
                  />
                )}
                {openCommsDialog && (
                  <UserComms
                    handleClose={() => {
                      setOpenCommsDialog(false);
                      setBlockLoading(false);
                      if (currentApplicant?.application?.text_alert) {
                        dispatch(setEditedApplicantId(currentApplicant.id));
                        if (isSearched) {
                          searchApplicants();
                        } else {
                          getApplicants();
                        }
                      }
                    }}
                    open={openCommsDialog}
                    selectedUser={applicantData}
                    sidebarCollapse={sidebarCollapse}
                  />
                )}
                {openEmailDialog && (
                  <EmailDialog
                    handleClose={() => setOpenEmailDialog(false)}
                    open={openEmailDialog}
                    openEmailToast={() => setOpenEmailToast(true)}
                    currentApplicant={applicantData}
                    sidebarCollapse={sidebarCollapse}
                  />
                )}
                {openDispositionDialog && (
                  <DispositionDialog
                    handleClose={() => setOpenDispositionDialog(false)}
                    open={openDispositionDialog}
                    setOpenToast={() => setOpenDispositionToast(true)}
                    setBlockLoading={setBlockLoading}
                  />
                )}
              </div>
            </CardContent>
            <CardActions sx={{ padding: '16px 0 0 0' }}>
              <StaffingModuleTabs
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
              />
            </CardActions>
            <div className="profile-automation-container">
              {currentTab === 0 && (
                <div style={{ padding: '16px', textAlign: 'left' }}>
                  {buildAutomation()}
                </div>
              )}
              {currentTab === 1 && (
                <div style={{ padding: '16px' }}>
                  <NotesModule
                    selectedUser={applicantData}
                    currentUser={currentUser}
                  />
                </div>
              )}
              {currentTab === 2 && (
                <div style={{ fontFamily: 'Korolev Medium', paddingTop: '24px' }}>
                  <AlertsModule/>
                </div>
              )}
              {currentTab === 3 && (
                <StaffingDropBox
                  setOpenDocuments={setOpenDocuments}
                />
              )}
            </div>
          </>
        )}
      </Card>
      {openDispositionToast && (
        <Toast
          open={openDispositionToast}
          onClose={() => setOpenDispositionToast(false)}
          message="Applicant updated"
        />
      )}
      {openEmailToast && (
        <Toast
          open={openEmailToast}
          onClose={() => setOpenEmailToast(false)}
          message="Email sent"
        />
      )}
    </div>
  );
}
