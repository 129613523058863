import { axiosService } from 'services/axiosServices';
import axios from 'axios';

let cancelTokenSales = null;
let cancelTokenConnections = null;
let cancelTokenConnectionsPersonal = null;

export const sales = {
  getSalesByFilter: async (filters) => {
    // Cancel previous request (if any)
    if (cancelTokenSales) {
      cancelTokenSales.cancel('Request canceled');
    }

    // Create a new cancel token source
    cancelTokenSales = axiosService.CancelToken.source();
    try {
      const res = await axiosService.get(
        `/api/filter/sales?${filters}`,
        {
          cancelToken: cancelTokenSales.token,
        },
      );
      return res.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Request was canceled
        throw new Error('canceled');
      } else {
        throw error;
      }
    }
  },
  getCompanyConnections: async () => {
    // Cancel previous request (if any)
    if (cancelTokenConnections) {
      cancelTokenConnections.cancel('Request canceled');
    }

    // Create a new cancel token source
    cancelTokenConnections = axiosService.CancelToken.source();
    try {
      const res = await axiosService.get(
        `/api/filter/connections?connection_type=company`,
        {
          cancelToken: cancelTokenConnections.token,
        },
      );
      return res.data.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Request was canceled
        throw new Error('canceled');
      } else {
        throw error;
      }
    }
  },
  getPersonalConnections: async () => {
    // Cancel previous request (if any)
    if (cancelTokenConnectionsPersonal) {
      cancelTokenConnectionsPersonal.cancel('Request canceled');
    }

    // Create a new cancel token source
    cancelTokenConnectionsPersonal = axiosService.CancelToken.source();
    try {
      const res = await axiosService.get(
        `/api/filter/connections?connection_type=personal`,
        {
          cancelToken: cancelTokenConnectionsPersonal.token,
        },
      );
      return res.data.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Request was canceled
        throw new Error('canceled');
      } else {
        throw error;
      }
    }
  },
  getBusinessLines: async () => {
    const res = await axiosService.get('/api/sales_division');
    return res.data;
  },
  getSalesReps: async () => {
    const res = await axiosService.get('/api/filter/users?role=sales');
    return res.data.data;
  },
  getSaleQuotes: async (saleId) => {
    const res = await axiosService.get(`/api/filter/quotes?sales_id=${saleId}`);
    return res.data.data;
  },
  createSaleQuote: async (body) => {
    const res = await axiosService.post('/api/quotes', body);
    return res.data.data;
  },
  updateSaleQuote: async (quoteId, body) => {
    const res = await axiosService.patch(`/api/quotes/${quoteId}`, body);
    return res.data.data;
  },
  getSale: async (saleId) => {
    const res = await axiosService.get(`/api/sales/${saleId}`);
    return res.data;
  },
  updateSale: async (saleId, body) => {
    const res = await axiosService.patch(`/api/sales/${saleId}`, body);
    return res.data;
  },
  getSalesAnalyticsPerStage: async () => {
    const res = await axiosService.get(`/api/metrics/sales/per_stage`);
    return res.data;
  },
  getSalesRepsAnalyticsPerStage: async (id) => {
    const res = await axiosService.get(`/api/metrics/sales/per_stage?sales_rep_id=${id}`);
    return res.data;
  },
  getBidTypes: async () => {
    const res = await axiosService.get(`/api/bid_types`);
    return res.data;
  },
  getSalesDivisions: async () => {
    const res = await axiosService.get(`/api/sales_division`);
    return res.data;
  },
  getSalesServiceTypes: async () => {
    const res = await axiosService.get(`/api/service_types`);
    return res.data.data;
  },
  getSalesMilestones: async () => {
    const res = await axiosService.get(`/api/milestone_templates`);
    return res.data;
  },
  getSaleNotes: async (id) => {
    const res = await axiosService.get(`/api/sales_notes/${id}`);
    return res.data.data;
  },
  getSalesMilestonesTasks: async (id) => {
    const res = await axiosService.get(`/api/filter/tasks?taskable_id=${id}&taskable_type=sales&status[]=assigned&status[]=in progress&status[]=requested approval&status[]=returned&status[]=blocked&status[]=completed`);
    return res.data.data;
  },
  getSalesTasks: async (id, statusFilter, page) => {
    const res = await axiosService.get(`/api/filter/tasks?taskable_id=${id}&taskable_type=sales${statusFilter}&page=${page}`);
    return res.data;
  },
}
