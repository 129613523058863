import React from "react";
import "react-datepicker/dist/react-datepicker.css";

import "css/analytics.scss";
import "css/pages.scss";
import "css/metrics.scss";

import { ExecutiveAccounting } from 'components/employee-portal/executive-analytics/executive-accounting';
import { ExecutiveContracts } from 'components/employee-portal/executive-analytics/executive-contracts';
import { ExecutiveConnections } from 'components/employee-portal/executive-analytics/executive-connections';
import { ExecutiveSales } from 'components/employee-portal/executive-analytics/executive-sales';
import { ExecutiveProjects } from 'components/employee-portal/executive-analytics/executive-projects';

export const ExecutiveContainer = ({
  setAccountingView,
  getLastUpdate,
}) => {

  return (
    <>
      <div className="content-header content-header-flex">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1>Executive Analytics</h1>
        </div>
      </div>
      <div className="metrics-content-container">
        <ExecutiveAccounting
          setAccountingView={setAccountingView}
          getLastUpdate={getLastUpdate}
        />
        <ExecutiveContracts />
        <ExecutiveConnections />
        <ExecutiveProjects />
        <ExecutiveSales />
      </div>
    </>
  );
};
