import { createSlice } from '@reduxjs/toolkit';
import { checkUniqueness } from 'store/auth/thunk';

const initialState = {
  uniqueness: {
    emailUnique: false,
    phoneUnique: false,
    emailError: '',
    phoneError: '',
    loadingUniqueness: false,
    status: '',
  }
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setStatus(state, action) {
      state.uniqueness.status = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(checkUniqueness.pending, (state) => {
      state.uniqueness.emailError = '';
      state.uniqueness.phoneError = '';
      state.uniqueness.loadingUniqueness = true;
      state.uniqueness.status = 'pending';
    });
    builder.addCase(checkUniqueness.fulfilled, (state, action) => {
      state.uniqueness.emailUnique = action.payload.is_email_unique;
      state.uniqueness.phoneUnique = action.payload.is_phone_unique;
      state.uniqueness.emailError = action.payload.is_email_unique ? '' : 'User is already registered with this email address';
      state.uniqueness.phoneError = action.payload.is_phone_unique ? '' : 'User is already registered with this phone number';
      state.uniqueness.loadingUniqueness = false;
      state.uniqueness.status = action.payload.is_email_unique && action.payload.is_phone_unique ? 'fulfilled' : '';
    });
    builder.addCase(checkUniqueness.rejected, (state) => {
      state.uniqueness.emailError = '';
      state.uniqueness.phoneError = '';
      state.uniqueness.loadingUniqueness = false;
      state.uniqueness.status = 'rejected';
    });
  },
});

export const {
  setStatus,
} = authSlice.actions;

export default authSlice.reducer;
