import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "../../../css/analytics.scss";
import "../../../css/pages.scss";
import "../../../css/metrics.scss";

import API from "API";

import { HighlightBlueAutoStyledButton } from "components/buttons/button-styles";
import { ProgressSpinnerBlue } from "../../progress/progress-spinner-blue";

export default function AccountingOverview(props) {

  const { setAccountingView, getLastUpdate, lastUpdate } = props;

  const token = JSON.parse(localStorage.getItem("token"));

  const [incomeNetChange, setIncomeNetChange] = useState(null);
  const [incomeBalance, setIncomeBalance] = useState(null);
  const [balanceSheetNetChange, setBalanceSheetNetChange] = useState(null);
  const [balanceSheetBalance, setBalanceSheetBalance] = useState(null);
  const [loading, setLoading] = useState(false);

  const today = new Date();
  const maxDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [firstMonth, setFirstMonth] = useState(null);
  const [lastMonth, setLastMonth] = useState(today);

  const handleMonthSelect = (date, setter) => {
    if (setter === setFirstMonth) {
      const selectedMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      setFirstMonth(selectedMonth);
    } else if (setter === setLastMonth) {
      let selectedMonth;
      if (today.getMonth() === date.getMonth()) {
        selectedMonth = new Date();
      } else {
        selectedMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }
      setLastMonth(selectedMonth);
    }
  };

  const formatDate = (date, setter) => {
    return date != null ? `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + (setter === setFirstMonth ? 1 : date.getDate())).slice(-2)}/${date.getFullYear()}` : "";
  };

  useEffect(() => {
    const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    setFirstMonth(firstOfMonth);
    filterLedger(firstOfMonth, today);
  }, []);

  const filterLedger = (start, end) => {
    const startDate = start.toISOString().split('T')[0];
    const endDate = end.toISOString().split('T')[0];
    getIncomeStatement(startDate, endDate);
    getBalanceSheet(startDate, endDate);
  };

  const getIncomeStatement = (startDate, endDate) => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/ledger_filter`, {
      method: "POST",
      body: JSON.stringify({
        "type": "Income Statement",
        "start_date": startDate,
        "end_date": endDate,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        setIncomeNetChange(json["Net Change"]);
        setIncomeBalance(json["Balance"]);
      })
      .catch((error) => {
        console.log(error, "Error fetching totals");
        setLoading(false);
      });
  };

  const getBalanceSheet = (startDate, endDate) => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/ledger_filter`, {
      method: "POST",
      body: JSON.stringify({
        "type": "Balance Sheet",
        "start_date": startDate,
        "end_date": endDate,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        setBalanceSheetNetChange(json["Net Change"]);
        setBalanceSheetBalance(json["Balance"]);
      })
      .catch((error) => console.log(error, "Error fetching totals"));
  };

  const syncLedger = () => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/create_ledger`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        getLastUpdate();
        getIncomeStatement();
        getBalanceSheet();
      })
      .catch((error) => console.log(error, "Error syncing ledger"));
  };

  return (
    <>
      {loading ? (
        <div className="spinner-fullScreen">
          <ProgressSpinnerBlue />
        </div>
      ) : (
        <>
          <div className="content-header content-header-flex">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h1>Accounting and Financials</h1>
            </div>
            <div
              style={{
                fontFamily: "Korolev Medium",
                fontSize: "14px",
                textAlign: "right",
                textTransform: "none",
                width: "25%",
                color: '#efefef',
              }}
            >
              Last updated on {new Date(lastUpdate).toLocaleString()}
            </div>
          </div>
          <div className="metrics-content-container">
            <div style={{ display: "flex", justifyContent: "space-between", margin: "0 20px 30px" }}>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", alignItems: "center", marginRight: "24px" }}>
                  <div style={{ fontFamily: "Korolev Medium", marginRight: "12px" }}>From:</div>
                  <DatePicker
                    wrapperClassName="date-picker"
                    selected={firstMonth}
                    onChange={(date) => handleMonthSelect(date, setFirstMonth)}
                    showMonthYearPicker
                    dateFormat={formatDate(firstMonth, setFirstMonth)}
                    dropdownMode="select"
                    placeholderText="Select first month"
                    maxDate={lastMonth}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontFamily: "Korolev Medium", marginRight: "12px" }}>To:</div>
                  <DatePicker
                    wrapperClassName="date-picker"
                    selected={lastMonth}
                    onChange={(date) => handleMonthSelect(date, setLastMonth)}
                    showMonthYearPicker
                    dateFormat={formatDate(lastMonth, setLastMonth)}
                    dropdownMode="select"
                    placeholderText="Select last month"
                    minDate={firstMonth}
                    maxDate={maxDate}
                  />
                </div>
                <HighlightBlueAutoStyledButton
                  onClick={() => filterLedger(firstMonth, lastMonth)}
                  disabled={!firstMonth || !lastMonth}
                  style={{ marginLeft: "30px", width: "15%" }}
                >
                  Apply
                </HighlightBlueAutoStyledButton>
              </div>
              <HighlightBlueAutoStyledButton
                onClick={() => syncLedger()}
                style={{ width: "15%" }}
              >
                Sync Ledger
              </HighlightBlueAutoStyledButton>
            </div>
            <div className="contract-form-section">
              <div className="contract-form-section-header header-row">
                <button
                  onClick={() => setAccountingView("balance sheet")}
                  style={{
                    background: "transparent",
                    border: 0,
                    color: "#EFEFEF",
                    fontFamily: "MagistralCondW08-Medium",
                    fontSize: "25px",
                    lineHeight: "34px",
                  }}
                >
                  Balance Sheet
                  <i
                    className="fa-solid fa-angle-right blue-arrow"
                    style={{ marginLeft: "12px", color: "#00A5CF" }}
                  />
                </button>
              </div>
              <div className="contract-form-section-content">
                <div className="stage-stat-container" style={{ width: "auto" }}>
                  <div className="stage-stat-header">Net Change</div>
                  <div className="stage-stat-number">
                    ${balanceSheetNetChange && balanceSheetNetChange.toLocaleString('en', { useGrouping: true })}
                  </div>
                </div>
                <div className="stage-stat-container" style={{ width: "auto" }}>
                  <div className="stage-stat-header">Balance</div>
                  <div className="stage-stat-number">
                    ${balanceSheetBalance && balanceSheetBalance.toLocaleString('en', { useGrouping: true })}
                  </div>
                </div>
              </div>
            </div>
            <div className="contract-form-section">
              <div className="contract-form-section-header header-row">
                <button
                  onClick={() => setAccountingView("income statement")}
                  style={{
                    background: "transparent",
                    border: 0,
                    color: "#EFEFEF",
                    fontFamily: "MagistralCondW08-Medium",
                    fontSize: "25px",
                    lineHeight: "34px",
                  }}
                >
                  Income Statement
                  <i
                    className="fa-solid fa-angle-right blue-arrow"
                    style={{ marginLeft: "12px", color: "#00A5CF" }}
                  />
                </button>
              </div>
              <div className="contract-form-section-content">
                <div className="matrix-total-container" style={{ border: 0 }}>
                  <div className="total-applicants-number" style={{ lineHeight: "45px" }}>
                    Coming soon!
                  </div>
                  <div className="stage-stat-header" style={{ marginTop: "12px" }}>Budget</div>
                </div>
                <div className="stage-stat-container" style={{ width: "auto" }}>
                  <div className="stage-stat-header">Net Change</div>
                  <div className="stage-stat-number">
                    ${incomeNetChange && incomeNetChange.toLocaleString('en', { useGrouping: true })}
                  </div>
                </div>
                <div className="stage-stat-container" style={{ width: "auto" }}>
                  <div className="stage-stat-header">Balance</div>
                  <div className="stage-stat-number">
                    ${incomeBalance && incomeBalance.toLocaleString('en', { useGrouping: true })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
