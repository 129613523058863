import React, { useEffect, useState } from 'react';
import NewBaseModal from 'components/modal/new-base-modal';

import 'css/vendor-grouping.scss';
import API from 'API';
import { VendorCreateBudget } from 'components/vendors/vendor-create-budget';
import {ProgressSpinnerBlue} from "../progress/progress-spinner-blue";

export const VendorBudgets = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const [budgets, setBudgets] = useState([]);
  const [openCrateBudget, setOpenCreateBudget] = useState(false);
  const [editedBudgets, setEditedBudgets] = useState({});
  const [loading, setLoading] = useState(false);
  const regex = /^[0-9\b]*$/;

  const getBudgets = async () => {
    setLoading(true);
    await fetch(`${API.endpointURL}/api/get_budget_entries`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setBudgets(json[0].sort((a, b) => a.accountNumber.localeCompare(b.accountNumber)))
        setLoading(false);
      })
      .catch((e) => {
        console.log(e, 'Error getting budgets');
        setLoading(false);
      });
  };

  const updateBudget = async (id, numbers) => {
    await fetch(`${API.endpointURL}/api/update_budget_entry`, {
      method: "POST",
      body: JSON.stringify({
        budget_entry_id: id,
        monthly_budgets: numbers,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(() => {
        getBudgets();
      })
      .catch((e) => {
        console.log(e, 'Error updating budget');
      });
  };

  const deleteBudget = async (id) => {
    await fetch(`${API.endpointURL}/api/delete_budget_entry`, {
      method: "POST",
      body: JSON.stringify({
        budget_entry_id: id,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(() => {
        getBudgets();
      })
      .catch((e) => {
        console.log(e, 'Error delete budget');
      });
  };

  useEffect(() => {
    getBudgets();
  }, [])

  const isEdit = (item) => {
    return editedBudgets.hasOwnProperty(item.id);
  }

  const handleChange = (value, id, month) => {
    if (regex.test(value)) {
      setEditedBudgets({
        ...editedBudgets,
        [id]: {
          ...editedBudgets[id],
          [month]: value,
        }
      })
    }
  }

  const handleEdit = (budget) => {
    if (!editedBudgets.hasOwnProperty(budget.id)) {
      setEditedBudgets({
        ...editedBudgets,
        [budget.id]: {
          January: budget.monthly_budgets.January,
          February: budget.monthly_budgets.February,
          March: budget.monthly_budgets.March,
          April: budget.monthly_budgets.April,
          May: budget.monthly_budgets.May,
          June: budget.monthly_budgets.June,
          July: budget.monthly_budgets.July,
          August: budget.monthly_budgets.August,
          September: budget.monthly_budgets.September,
          October: budget.monthly_budgets.October,
          November: budget.monthly_budgets.November,
          December: budget.monthly_budgets.December,
        }
      });
    } else {
      updateBudget(budget.id, editedBudgets[budget.id]);
      const sortedObject = {};

      for (let key in editedBudgets) {
        if (key !== String(budget.id)) {
          sortedObject[key] = editedBudgets[key];
        }
      }

      setEditedBudgets(sortedObject);
    }
  }

  const handleDelete = (item) => {
    if (isEdit(item)) {
      const sortedObject = {};

      for (let key in editedBudgets) {
        if (key !== String(item.id)) {
          sortedObject[key] = editedBudgets[key];
        }
      }

      setEditedBudgets(sortedObject);
    } else {
      deleteBudget(item.id);
    }
  }

  return (
    <div className="vendor-budgets">
      <h1 className="content-header">Set budgets</h1>
      <button
        onClick={() => setOpenCreateBudget(true)}
        className="vendor-grouping__create"
      >
        Create budget
      </button>
      <div className="vendor-grouping__content">
        <div className="vendor-budgets__table-head">
          <p className="vendor-budgets__table-head--item">Account number</p>
          <p className="vendor-budgets__table-head--item">BU</p>
          <p className="vendor-budgets__table-head--item">GOVT AG</p>
          <p className="vendor-budgets__table-head--item">STATE</p>
          <p className="vendor-budgets__table-head--item">TASK</p>
          <p className="vendor-budgets__table-head--item">Total</p>
          <p className="vendor-budgets__table-head--item">Month</p>
          <p className="vendor-budgets__table-head--item">Edit / Delete</p>
        </div>
        {loading && !budgets.length ? (
          <div className="spinner-fullScreen">
            <ProgressSpinnerBlue />
          </div>
        ) :
          budgets.map((budget) => (
            <div className="vendor-budgets__table-row">
              <p className="vendor-budgets__table-row--item">
                {budget.accountNumber}
              </p>
              <p  className="vendor-budgets__table-row--item">
                {budget.dimension1 || 'N/A'}
              </p>
              <p className="vendor-budgets__table-row--item">
                {budget.dimension2 || 'N/A'}
              </p>
              <p className="vendor-budgets__table-row--item">
                {budget.dimension3 || 'N/A'}
              </p>
              <p className="vendor-budgets__table-row--item">
                {budget.dimension4 || 'N/A'}
              </p>
              <p className="vendor-budgets__table-row--item">
                {Number(budget.total).toLocaleString('en', { useGrouping:true })}
              </p>
              <div className="vendor-budgets__table-row--item vendor-budgets__table-row--container">
                {[
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December'
                ].map((month) => (
                  <div
                    className={`
                    vendor-budgets__table-row--month 
                    ${isEdit(budget) && 'vendor-budgets__table-row--month--open'}
                  `}
                    key={`${month} ${budget.accountNumber}`}
                  >
                    <span>{month.slice(0, 3) + ':'}</span>
                    {isEdit(budget)
                      ? <input
                        value={editedBudgets[budget.id][month]}
                        onChange={(e) => {
                          handleChange(e.target.value, budget.id, month)
                        }}
                        className="vendor-budgets__table-row--input"
                      />
                      : budget.monthly_budgets[month].toLocaleString('en', { useGrouping:true })
                    }
                  </div>
                ))}
              </div>
              <div className="vendor-budgets__table-row--item vendor-budgets__table-buttons">
                <button
                  onClick={() => handleEdit(budget)}
                  className={`
                  vendor-budgets__table-button 
                  ${isEdit(budget)
                    ? 'vendor-budgets__table-submit'
                    : 'vendor-budgets__table-edit'
                  }
                `}
                >
                  {isEdit(budget) ? 'Submit' : 'Edit'}
                </button>
                <button
                  onClick={() => handleDelete(budget)}
                  className='vendor-budgets__table-button vendor-budgets__table-delete'
                >
                  {isEdit(budget) ? 'Cancel' : 'Delete'}
                </button>
              </div>
            </div>
          ))
        }
      </div>
      {openCrateBudget && (
        <NewBaseModal
          dialogContent={
            <VendorCreateBudget
              getBudgets={() => getBudgets()}
              close={() => setOpenCreateBudget(false)}
            />
          }
          onClose={() => setOpenCreateBudget(false)}
          open={openCrateBudget}
          title={"CREATE A NEW BUDGET"}
          colorPalette="blue"
        />
      )}
    </div>
  );
};