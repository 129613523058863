import React, { useState } from 'react';
import { ContractsTagsTypes } from 'components/contracts/contracts-tag-types';
import { ContractsTagsGroups } from 'components/contracts/contracts-tag-groups';
import { ContractTags } from 'components/contracts/contracts-tags/contract-tags';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

export const ContractsTagSystem = ({ onClick }) => {
  const [view, setView] = useState("menu");
  const { pathname } = useLocation();

  return (
    <>
      <h1 className="content-header">
        {view === 'menu' ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {onClick && (
              <i
                className={`fa-solid fa-angle-left blue-arrow analytics-back-button`}
                onClick={onClick}
              />
            )}
            <h1>
              Tag system
            </h1>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className={classNames(
                'fa-solid fa-angle-left analytics-back-button', {
                  'blue-arrow': pathname.includes('applicant'),
                  'green-arrow': pathname.includes('contract'),
                }
              )}
              onClick={() => setView("menu")}
            />
            <h1>
              {view === "Groups" && 'Groups'}
              {view === "Types" && 'Types'}
              {view === "Tags" && 'Tags'}
            </h1>
          </div>
        )}
      </h1>
      <div
        className="metrics-content-container"
        style={{ overflowY: view === 'Tags' ? 'hidden' : 'auto' }}
      >
        {view === "menu" && (
          <>
            <button
              onClick={() => setView("Groups")}
              className="sales__manage-button"
            >
              Groups
              <i
                className={classNames(
                  'fa-solid fa-angle-right', {
                    'blue-arrow': pathname.includes('applicant'),
                    'green-arrow': pathname.includes('contract'),
                  }
                )}
                style={{ marginLeft: "12px" }} 
              />
            </button>
            <button
              onClick={() => setView("Tags")}
              className="sales__manage-button"
            >
              Tags
              <i
                className={classNames(
                  'fa-solid fa-angle-right', {
                    'blue-arrow': pathname.includes('applicant'),
                    'green-arrow': pathname.includes('contract'),
                  }
                )}
                style={{ marginLeft: "12px" }} 
              />
            </button>
            <button
              onClick={() => setView("Types")}
              className="sales__manage-button"
            >
              Types
              <i
                className={classNames(
                  'fa-solid fa-angle-right', {
                    'blue-arrow': pathname.includes('applicant'),
                    'green-arrow': pathname.includes('contract'),
                  }
                )}
                style={{ marginLeft: "12px" }}
              />
            </button>
          </>
        )}
        {view === "Types" && <ContractsTagsTypes />}
        {view === "Groups" && <ContractsTagsGroups />}
        {view === "Tags" && <ContractTags />}
      </div>
    </>
  );
};
