import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: '#62AB37',
    },
    secondary: {
      main: '#531CB3',
    }
  },
});


export default function ProgressSpinner(props) {
  return (
    <ThemeProvider theme={theme}>
      <CircularProgress
        color={props.color ? props.color : 'primary'}
        sx={{ my: 5, margin: props.margin ? props.margin : '' }}
      />
    </ThemeProvider>
  );
};
