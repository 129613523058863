import React, { useState } from "react";
import "../../css/email-template.scss";
import API from "API";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import fileIcon from "assets/uploaded_file.svg";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'color': [] }, { 'background': [] }],
    ['link'],
    [{ 'align': [] }],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
  history: {
    delay: 1000,
    maxStack: 50,
    userOnly: true,
  },
};


export default function EditEmailTemplate(props) {
  const { template, setTemplate, getTemplates } = props;

  const token = JSON.parse(localStorage.getItem("token"));

  const [templateName, setTemplateName] = useState(template.template_name);
  const [templateActive, setTemplateActive] = useState(template.active);
  const [templateEmailActive, setTemplateEmailActive] = useState(
    template.email_active
  );
  const [emailHeader, setEmailHeader] = useState(template.email_header);
  const [emailBody, setEmailBody] = useState(template.email_body);

  const [emailSignOff, setEmailSignOff] = useState(template.email_sign_off);
  const [currentFiles, setCurrentFiles] = useState(template.files);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [file1, setFile1] = useState(null);
  const [file1Name, setFile1Name] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file2Name, setFile2Name] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file3Name, setFile3Name] = useState(null);
  const [file4, setFile4] = useState(null);
  const [file4Name, setFile4Name] = useState(null);
  const [file5, setFile5] = useState(null);
  const [file5Name, setFile5Name] = useState(null);
  const [error, setError] = useState(null);

  const retrievePdf = (file) => {
    window.open(
      `${API.endpointURL}/api/cloud_location?location=${file.file_name}`
    );
  };

  const handleDeleteFile = (file, index) => {
    // Remove file from current files list
    let currentFilesCopy = [...currentFiles];
    currentFilesCopy.splice(index, 1);
    setCurrentFiles(currentFilesCopy);

    // Add file to list of files to be deleted
    let filesToDeleteCopy = [...filesToDelete];
    filesToDeleteCopy.push(file);
    setFilesToDelete(filesToDeleteCopy);
  };

  const handleUndoDeleteFile = (file, index) => {
    // Remove file from list of files to be deleted
    let filesToDeleteCopy = [...filesToDelete];
    filesToDeleteCopy.splice(index, 1);
    setFilesToDelete(filesToDeleteCopy);

    // Add file back to current files list
    let currentFilesCopy = [...currentFiles];
    currentFilesCopy.push(file);
    setCurrentFiles(currentFilesCopy);
  };

  const validateForm = () => {
    let validForm = true;
    if (currentFiles.length < 5 && file1Name && !file1) validForm = false;
    if (currentFiles.length < 5 && !file1Name && file1) validForm = false;
    if (currentFiles.length < 4 && file2Name && !file2) validForm = false;
    if (currentFiles.length < 4 && !file2Name && file2) validForm = false;
    if (currentFiles.length < 3 && file3Name && !file3) validForm = false;
    if (currentFiles.length < 3 && !file3Name && file3) validForm = false;
    if (currentFiles.length < 2 && file4Name && !file4) validForm = false;
    if (currentFiles.length < 2 && !file4Name && file4) validForm = false;
    if (currentFiles.length < 1 && file5Name && !file5) validForm = false;
    if (currentFiles.length < 1 && !file5Name && file5) validForm = false;

    return validForm;
  };

  const deactivateTemplate = () => {
    setTemplateActive(false);
    setTemplateEmailActive(false);
  };

  const handleSubmit = (id) => {
    const validForm = validateForm();
    if (validForm) {
      const formData = new FormData();
      formData.append("template_name", templateName);
      formData.append("email_header", emailHeader);
      formData.append("email_body", emailBody ? emailBody : "");
      formData.append("active", templateActive);
      formData.append("email_active", templateEmailActive);
      if (filesToDelete.length > 0) {
        for (var i = 0; i < filesToDelete.length; i++) {
          formData.append("files_to_delete[]", filesToDelete[i].id);
        }
      }
      if (file1Name && file1 && currentFiles.length < 5) {
        formData.append("file_1", file1);
        formData.append("file_1_name", file1Name);
      }
      if (file2Name && file2 && currentFiles.length < 4) {
        formData.append("file_2", file2);
        formData.append("file_2_name", file2Name);
      }
      if (file3Name && file3 && currentFiles.length < 3) {
        formData.append("file_3", file3);
        formData.append("file_3_name", file3Name);
      }
      if (file4Name && file4 && currentFiles.length < 2) {
        formData.append("file_4", file4);
        formData.append("file_4_name", file4Name);
      }
      if (file5Name && file5 && currentFiles.length < 1) {
        formData.append("file_5", file5);
        formData.append("file_5_name", file5Name);
      }
      // Adding PUT method param is a Laravel workaround to handle form data
      // https://stackoverflow.com/questions/50691938/patch-and-put-request-does-not-working-with-form-data
      fetch(`${API.endpointURL}/api/email_templates/${id}?_method=PUT`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((json) => {
          setTemplate(null);
          getTemplates();
        })
        .catch((error) => console.log(error, "Error editing template.."));
    } else {
      setError("Please provide a file name and file for each attachment.");
    }
  };

  const buildCurrentFilesList = () => {
    let filesList = [];
    currentFiles.forEach((file, index) => {
      filesList.push(
        <div
          key={index}
          style={{
            marginBottom: "16px",
            marginRight: "16px",
            textAlign: "center",
            width: "100px",
          }}
        >
          <IconButton
            onClick={() => handleDeleteFile(file, index)}
            sx={{
              color: "#EFEFEF",
              float: "right",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#a20601",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            onClick={() => retrievePdf(file)}
            sx={{
              flexDirection: "column",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <img
              src={fileIcon}
              width="57px"
              height="auto"
              alt="open-file-button"
            />
            <Typography
              sx={{
                color: "#EFEFEF",
                fontFamily: "Korolev Medium",
                fontSize: "0.85rem",
                margin: "8px auto 0",
                width: "100%",
              }}
            >
              {file.display_name}
            </Typography>
          </IconButton>
        </div>
      );
    });

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {filesList}
      </div>
    );
  };

  const buildDeleteFilesList = () => {
    let filesList = [];
    filesToDelete.forEach((file, index) => {
      filesList.push(
        <div
          key={index}
          style={{
            marginBottom: "16px",
            marginRight: "16px",
            textAlign: "center",
            width: "100px",
          }}
        >
          <IconButton
            onClick={() => handleUndoDeleteFile(file, index)}
            sx={{
              color: "#EFEFEF",
              float: "right",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#60992d",
              },
            }}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => retrievePdf(file)}
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
          >
            <img
              src={fileIcon}
              width="57px"
              height="auto"
              alt="open-file-button"
            />
          </IconButton>
          <Typography
            key={index}
            sx={{
              color: "#EFEFEF",
              fontFamily: "Korolev Medium",
              fontSize: "0.85rem",
              margin: "0 auto",
            }}
          >
            {file.display_name}
          </Typography>
        </div>
      );
    });

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {filesList}
      </div>
    );
  };

  const buildAddFilesInputs = () => {
    return (
      <React.Fragment>
        {currentFiles.length < 5 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "8px",
            }}
          >
            <TextField
              value={file1Name}
              onChange={(e) => setFile1Name(e.currentTarget.value)}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 1,
                marginRight: "2%",
                width: "50%",
              }}
              placeholder="File 1 name..."
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            <div className="belles-file-upload" style={{ width: "48%" }}>
              <input
                type="file"
                name="file"
                onChange={(event) => setFile1(event.target.files[0])}
                sx={{ marginTop: "8px" }}
              />
            </div>
          </Box>
        )}
        {currentFiles.length < 4 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <TextField
              value={file2Name}
              onChange={(e) => setFile2Name(e.currentTarget.value)}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 1,
                marginRight: "2%",
                width: "50%",
              }}
              placeholder="File 2 name..."
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            <div className="belles-file-upload" style={{ width: "48%" }}>
              <input
                type="file"
                name="file"
                onChange={(event) => setFile2(event.target.files[0])}
                sx={{ marginTop: "8px" }}
              />
            </div>
          </Box>
        )}
        {currentFiles.length < 3 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <TextField
              value={file3Name}
              onChange={(e) => setFile3Name(e.currentTarget.value)}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 1,
                marginRight: "2%",
                width: "50%",
              }}
              placeholder="File 3 name..."
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            <div className="belles-file-upload" style={{ width: "48%" }}>
              <input
                type="file"
                name="file"
                onChange={(event) => setFile3(event.target.files[0])}
                sx={{ marginTop: "8px" }}
              />
            </div>
          </Box>
        )}
        {currentFiles.length < 2 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <TextField
              value={file4Name}
              onChange={(e) => setFile4Name(e.currentTarget.value)}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 1,
                marginRight: "2%",
                width: "50%",
              }}
              placeholder="File 4 name..."
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            <div className="belles-file-upload" style={{ width: "48%" }}>
              <input
                type="file"
                name="file"
                onChange={(event) => setFile4(event.target.files[0])}
                sx={{ marginTop: "8px" }}
              />
            </div>
          </Box>
        )}
        {currentFiles.length < 1 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <TextField
              value={file5Name}
              onChange={(e) => setFile5Name(e.currentTarget.value)}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 1,
                marginRight: "2%",
                width: "50%",
              }}
              placeholder="File 5 name..."
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            <div className="belles-file-upload" style={{ width: "48%" }}>
              <input
                type="file"
                name="file"
                onChange={(event) => setFile5(event.target.files[0])}
                sx={{ marginTop: "8px" }}
              />
            </div>
          </Box>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="main-container">
        {template.template_type === "pre_screening_1" && (
          <h1>This template will send a password reset to the user</h1>
        )}

        <div className="email-template-info-row">
          <Box
            sx={{
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <div>
              <label className="template-label">
                Active
                <label class="switch">
                  {templateActive === true ? (
                    <input
                      type="checkbox"
                      checked
                      onChange={(e) => deactivateTemplate()}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      onChange={(e) => setTemplateActive(!templateActive)}
                    />
                  )}
                  <span class="slider round"></span>
                </label>
              </label>
            </div>
            <div>
              <label className="template-label">
                Email Active
                <label class="switch">
                  {templateEmailActive === true ? (
                    <input
                      type="checkbox"
                      checked
                      onChange={(e) => setTemplateEmailActive(false)}
                    />
                  ) : templateActive === true ? (
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        setTemplateEmailActive(!templateEmailActive)
                      }
                    />
                  ) : (
                    <input type="checkbox" disabled />
                  )}

                  <span class="slider round"></span>
                </label>
              </label>
            </div>
          </Box>
        </div>
        <div className="email-template-info">
          <div className="email-template-info-row">
            <label className="template-label">
              Template Name
              <input
                className="field"
                type="text"
                placeholder="Template Name"
                defaultValue={templateName}
                onChange={(e) => setTemplateName(e.currentTarget.value)}
                style={{width:'calc(100% - 22px)', margin: '5px 0px 30px 0px'}}
              />
            </label>
            <label className="template-label">
              Email Subject
              <input
                className="field"
                type="text"
                placeholder="Email Subject"
                defaultValue={emailHeader}
                onChange={(e) => setEmailHeader(e.currentTarget.value)}
                style={{width:'calc(100% - 22px)', margin: '5px 0px 30px 0px'}}
              />
            </label>
          </div>
        </div>

        <div className="contract-rich-editor">
          <label className="template-label">Email Body</label>
          <ReactQuill
            theme="snow"
            value={emailBody}
            onChange={setEmailBody}
            modules={modules}
            style={{ 
              width:'calc(100% - 22px)', 
              margin: '5px 0px 30px 0px', 
              backgroundColor: '#EFEFEF40', 
              color: '#EFEFEF', 
              borderRadius: '10px',
              height: "325px"
            
            }}
            />
        </div>
        
        <div className="email-template-files">
          <b>Email File Attachments (max 5)</b>
          <div className="files">
            <label className="files-label">
              {`Current Files (${currentFiles.length})`}
            </label>
            {currentFiles.length > 0 && buildCurrentFilesList()}
          </div>
          <div className="files">
            <label className="files-label">
              {`Files to Delete (${filesToDelete.length})`}
            </label>
            {filesToDelete.length > 0 && buildDeleteFilesList()}
          </div>
          <div className="files">
            <label className="files-label">Add Files</label>

            {currentFiles.length < 5 && buildAddFilesInputs()}
          </div>
        </div>
        {error && (
          <div
            style={{
              color: "#a20601",
              fontFamily: "Korolev Medium",
              fontSize: "14px",
              marginBottom: "25px",
              textAlign: "center",
            }}
          >
            {error}
          </div>
        )}
        <div className="email-template-submit">
          <button
            className="submit-btn"
            onClick={() => handleSubmit(template.id)}
          >
            Submit
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
