import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IconButton, TextField, Tooltip } from '@mui/material';

import NewBaseModal from 'components/modal/new-base-modal';
import Toast from 'components/toast/toast';
import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';
import editButton from 'assets/edit.png';
import deleteButton from 'assets/delete.png';
import saveButton from 'assets/check-3278.png';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  quoteCategoriesErrorSelector,
  quoteCategoriesLoadingSelector,
  quoteCategoriesSelector,
} from 'store/quotes/selectors';
import {
  createQuoteCategory,
  deleteQuoteCategory,
  getQuoteCategories,
  updateQuoteCategory
} from 'store/quotes/thunk';

export const SalesQuoteManageModal = ({ open, onClose, getQuotes }) => {
  const { pathname } = useLocation();
  const saleId = Number(pathname.slice(14));

  const [isCreating, setIsCreating] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const [editItem, setEditItem] = useState(null);
  const [editInput, setEditInput] = useState('');
  const [editError, setEditError] = useState('');
  const [deleteError, setDeleteError] = useState({});

  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState('');

  const dispatch = useAppDispatch();
  const categories = useAppSelector(quoteCategoriesSelector);
  const loading = useAppSelector(quoteCategoriesLoadingSelector);
  const error = useAppSelector(quoteCategoriesErrorSelector);

  const handleCreate = () => {
    if (!inputValue) {
      setInputError('Please enter category name.');
    } else if (categories.some((category) => category.category_name === inputValue)) {
      setInputError('Category name already exists.')
    } else {
      setInputError('');
      dispatch(createQuoteCategory({
        body: {
          sales_id: saleId,
          category_name: inputValue,
        },
      }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(getQuoteCategories({ saleId }));
            setInputValue('');
            setIsCreating(false);
            setOpenToast(true);
            setMessage('Category is successfully created.');
          }
        });
    }
  };

  const handleUpdate = (id) => {
    if (!editInput) {
      setEditError('Please enter category name.');
    } else if (categories.some((category) =>
      category.category_name === editInput
        && category.id !== id
    )) {
      setEditError('Category name already exists.');
    } else {
      setEditError('');
      dispatch(updateQuoteCategory({
        categoryId: id,
        body: {
          category_name: editInput,
        }
      }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(getQuoteCategories({ saleId }));
            setEditItem(null);
            setEditInput('');
            setOpenToast(true);
            setMessage('Category is successfully updated.');
          }
        });
    }
  };

  const quoteCategories = () => {
    return (
      <div className="sales__quote--modal">
        {loading && (
          <div className="content-loading" style={{ height: '200px' }}>
            <ProgressSpinnerDarkNavy />
          </div>
        )}

        {!loading && error && (
          <p className="sales__quote--error">
            {error}
          </p>
        )}

        {!loading && !categories.length && (
          <>
            <p className="sales__quote--category sales__quote--category-template">
              No quote categories for this sale.
            </p>
            {isCreating ? (
              <div className="sales__quote--box">
                <TextField
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  fullWidth
                  sx={{
                    backgroundColor: '#fff',
                    border: '1px solid #a5af96',
                    borderRadius: '8px',
                    padding: '6px 10px 5px',
                    '& textarea': {
                      borderRadius: 0,
                    },
                  }}
                  placeholder="Category name..."
                  multiline={false}
                  rows={1}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <Tooltip title="Create category">
                  <IconButton
                    edge="start"
                    aria-label="edit-category"
                    sx={{
                      padding: 0,
                      margin: 0,
                      color: '#3e453d',
                      justifyContent: 'center',
                      width: '40px',
                      height: '40px',

                    }}
                    onClick={handleCreate}
                  >
                    <img
                      src={saveButton}
                      width="20px"
                      height="auto"
                      alt="edit-button"
                    />
                  </IconButton>
                </Tooltip>
                {inputError && (
                  <p className="sales__quote--box-error">{inputError}</p>
                )}
              </div>
            ) : (
              <button
                className="sales__quote--add"
                onClick={() => setIsCreating(true)}
              >
                + Add Quote Category
              </button>
            )}
          </>
        )}

        {!loading && categories.length > 0 && (
          <>
            {categories.map((category) => (
              <div className="sales__quote--category-row" key={category.id}>
                {editItem === category.id ? (
                  <div className="sales__quote--box">
                    <TextField
                      value={editInput}
                      onChange={(e) => setEditInput(e.target.value)}
                      fullWidth
                      sx={{
                        backgroundColor: '#fff',
                        border: '1px solid #a5af96',
                        borderRadius: '8px',
                        padding: '6px 10px 5px',
                        '& textarea': {
                          borderRadius: 0,
                        },
                      }}
                      placeholder="Category name..."
                      multiline={false}
                      rows={1}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                    <Tooltip title="Update category">
                      <IconButton
                        edge="start"
                        aria-label="edit-category"
                        sx={{
                          padding: 0,
                          margin: 0,
                          color: '#3e453d',
                          justifyContent: 'center',
                          width: '40px',
                          height: '40px',
                        }}
                        onClick={() => handleUpdate(category.id)}
                      >
                        <img
                          src={saveButton}
                          width="20px"
                          height="auto"
                          alt="edit-button"
                        />
                      </IconButton>
                    </Tooltip>
                    {editItem === category.id && editError && (
                      <p className="sales__quote--box-error">{editError}</p>
                    )}
                  </div>
                ) : (
                  <>
                    <p className="sales__quote--category" style={{ textTransform: 'capitalize' }}>
                      {category.category_name}
                    </p>
                    {editItem !== category.id && deleteError?.id === category.id && (
                      <p
                        className="sales__quote--box-error"
                        style={{
                          bottom: '2px',
                          left: '0',
                          fontSize: '12px',
                        }}
                      >
                        {deleteError?.message}
                      </p>
                    )}
                    <Tooltip title="Edit Category">
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="edit-category"
                        sx={{
                          pt: 0,
                          color: '#3e453d',
                          padding: '0',
                          marginBottom: '8px',
                          width: '35px',
                          height: '35px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: '10px',
                        }}
                        onClick={() => {
                          setEditItem(category.id);
                          setEditInput(category.category_name);
                        }}
                      >
                        <img
                          src={editButton}
                          width="20px"
                          height="auto"
                          alt="edit-button"
                        />
                      </IconButton>
                    </Tooltip>

                    {!category?.is_default && (
                      <Tooltip title="Delete Category">
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="delete-category"
                          sx={{
                            pt: 0,
                            color: '#3e453d',
                            padding: '0px 5px',
                            marginBottom: '7px',
                            width: '35px',
                            height: '35px',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            dispatch(deleteQuoteCategory({ categoryId: category.id }))
                              .then((res) => {
                                if (res.meta.requestStatus === 'fulfilled') {
                                  dispatch(getQuoteCategories({ saleId }));
                                  setDeleteError({});
                                  setOpenToast(true);
                                  setMessage('Category is successfully deleted.');
                                  getQuotes();
                                } else {
                                  setDeleteError({
                                    id: category.id,
                                    message: 'Error deleting category, try again later.',
                                  });
                                }
                              })
                          }}
                        >
                          <img
                            src={deleteButton}
                            width="20px"
                            height="auto"
                            alt="edit-button"
                          />
                        </IconButton>
                      </Tooltip>
                    )}

                  </>
                )}
              </div>
            ))}
            {isCreating ? (
              <div className="sales__quote--box">
                <TextField
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  fullWidth
                  sx={{
                    backgroundColor: '#fff',
                    border: '1px solid #a5af96',
                    borderRadius: '8px',
                    padding: '6px 10px 5px',
                    '& textarea': {
                      borderRadius: 0,
                    },
                  }}
                  placeholder="Category name..."
                  multiline={false}
                  rows={1}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <Tooltip title="Create category">
                  <IconButton
                    edge="start"
                    aria-label="edit-category"
                    sx={{
                      padding: 0,
                      margin: 0,
                      color: '#3e453d',
                      justifyContent: 'center',
                      width: '40px',
                      height: '40px',
                    }}
                    onClick={handleCreate}
                  >
                    <img
                      src={saveButton}
                      width="20px"
                      height="auto"
                      alt="edit-button"
                    />
                  </IconButton>
                </Tooltip>
                {inputError && (
                  <p className="sales__quote--box-error">{inputError}</p>
                )}
              </div>
            ) : (
              <button
                className="sales__quote--add"
                onClick={() => setIsCreating(true)}
              >
                + Add Quote Category
              </button>
            )}
          </>
        )}
        {openToast && (
          <Toast
            open={openToast}
            message={message}
            onClose={() => setOpenToast(false)}
          />
        )}
      </div>
    )
  }
  return (
    <NewBaseModal
      open={open}
      onClose={onClose}
      title="Manage Quote Categories"
      colorPalette="navy"
      dialogContent={quoteCategories()}
    />
  );
};
