import React, { useState } from "react";
import "../../css/spreadsheet.scss";
import FileToast from "components/toast/FileToast";
import VendorFileCorrectionsDialog from "components/email/vendor-file-corrections-dialog";
import Toast from "components/toast/toast";
import { uploadTypeToFile } from "shared/vendor-data";

import API from "API";

export default function VendorSpreadSheet(props) {
  const { files, vendor, vendorId, retrieveFiles } = props;
  const token = JSON.parse(localStorage.getItem("token"));
  const [openUploadSuccessToast, setOpenUploadSuccessToast] = useState(false);
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState(false);
  const [openRequestCorrectionsModal, setOpenRequestCorrectionsModal] =
    useState(false);
  const [focusedFile, setFocusedFile] = useState(null);
  const [openEmailToast, setOpenEmailToast] = useState(false);

  let mostRecentFiles = files.reduce((acc, file) => {
    const fileType = file.type;
    const fileDate = new Date(file.created_at).getTime();
    if (
      !acc[fileType] ||
      fileDate > new Date(acc[fileType].created_at).getTime()
    ) {
      acc[fileType] = file;
    }
    return acc;
  }, {});

  const verificationFormatter = (file) => {
    fetch(`${API.endpointURL}/api/verify?vendor_id=${vendor.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        [file.type]: true,
        file_id: file.id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setToastType(true);
        setMessage(`"${file.type}" has been approved.`);
        setOpenUploadSuccessToast(true);
        props.retrieveFiles();
      })
      .catch((error) =>
        console.log(
          "An error occured while submitting your File Verification.",
          error
        )
      );
  };

  const reOrderedFiles = () => {
    const temp = [];

    Object.values(mostRecentFiles).forEach((file) => {
      switch (file.type) {
        case "certificate_of_insurance":
          temp[0] = file;
          break;
        case "general_liability":
          temp[1] = file;
          break;
        case "auto_liability":
          temp[2] = file;
          break;
        case "workers_comp_waiver":
          temp[3] = file;
          break;
        case "notice_of_cancellation":
          temp[4] = file;
          break;
        case "w9":
          temp[5] = file;
      }
    });

    return temp;
  };

  return (
    <div className="content-wrapper">
      <table className="spreadsheet">
        <thead>
          <tr className="ss-headers">
            <td className="header-left">Type</td>
            <td>Name</td>
            <td>Upload Date </td>
            <td className="last-item header-right">Verification </td>
          </tr>
        </thead>
        <tbody>
          {reOrderedFiles().map((file, index) => {
            const pending =
              file.approved === null && file.corrections_requested === null
                ? "pending"
                : "";

            if (index <= 4) {
              return (
                <tr
                  className={`ss-content ${index === 0 ? "first-row" : ""}`}
                  key={file.name}
                >
                  <td>{uploadTypeToFile[file.type]}</td>
                  <td>{file.display_name}</td>
                  <td>{new Date(file.created_at).toDateString()}</td>
                  <td
                    className={`last-item test ${
                      file.approved && !file.corrections_requested
                        ? "pass"
                        : "fail"
                    }  ${pending}`}
                  >
                    {!file.approved || file.corrections_requested ? (
                      <i
                        onClick={() => verificationFormatter(file)}
                        class="fa fa-check-circle checkbox"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        class="fa fa-check-circle checkbox disabled"
                        aria-hidden="true"
                      ></i>
                    )}
                    <i
                      class="fa-regular fa-pen-to-square edit-btn"
                      onClick={() => {
                        setFocusedFile(file);
                        setOpenRequestCorrectionsModal(true);
                      }}
                    ></i>
                  </td>
                </tr>
              );
            } else if (index === 5) {
              return (
                <tr className={`ss-content last-row`} key={file.name}>
                  <td>{uploadTypeToFile[file.type]}</td>
                  <td>{file.display_name}</td>
                  <td>{new Date(file.created_at).toDateString()}</td>
                  <td
                    className={`last-item ${
                      file.approved && !file.corrections_requested
                        ? "pass"
                        : "fail"
                    } ${pending}`}
                  >
                    {!file.approved || file.corrections_requested ? (
                      <i
                        onClick={() => verificationFormatter(file)}
                        class="fa fa-check-circle checkbox"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        class="fa fa-check-circle checkbox disabled"
                        aria-hidden="true"
                      ></i>
                    )}
                    <i
                      class="fa-regular fa-pen-to-square edit-btn"
                      onClick={() => {
                        setFocusedFile(file);
                        setOpenRequestCorrectionsModal(true);
                      }}
                    ></i>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>

      <FileToast
        type={toastType}
        open={openUploadSuccessToast}
        onClose={() => setOpenUploadSuccessToast(false)}
        message={message}
      />

      {openRequestCorrectionsModal && (
        <VendorFileCorrectionsDialog
          handleClose={() => setOpenRequestCorrectionsModal(false)}
          open={openRequestCorrectionsModal}
          pocUserId={vendor.poc_id}
          file={focusedFile}
          vendorId={vendorId}
          retrieveFiles={retrieveFiles}
          setOpenEmailToast={() => setOpenEmailToast(true)}
        />
      )}
      {openEmailToast && (
        <Toast
          open={openEmailToast}
          onClose={() => setOpenEmailToast(false)}
          message="Corrections email sent"
        />
      )}
    </div>
  );
}
