import "../../css/contracts.scss";
import React from "react";
import ContractVariablesForm from "components/contracts/contract-variables-form";

export default function ContractVariables() {
  return (
    <>
      <h1 className="content-header">Contract Variables</h1>
      <div className="content-container">
        <ContractVariablesForm />
      </div>
    </>
  );
}
