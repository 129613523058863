import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import "App.scss";
import "css/rd.scss";
import "css/fa.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import {
  ProtectedRoute,
  ProtectedAdminRoute,
  ProtectedAccountingRoute,
  ProtectedAccountingStaffingRoute,
  ProtectedInternalRoute,
  ProtectedRecruiterStaffingRoute,
  ProtectedSalesRoute,
  ProtectedStaffingRoute,
  ProtectedVendorRoute, ProtectedProjectsRoute,
} from 'components/login/auth';
import ApplicantProfile from "./routes/applicant-profile";
import EmployeePortal from "routes/employee-portal";
import LoginPage from "./routes/login.component";
import SplashScreen from "./routes/splash-screen";
import Users from "./routes/users";
import ResetPassword from "./routes/reset-password";
import Sales from "routes/sales/sales";
import SalesPortal from "routes/sales/sales-portal";
import Staffing from "./routes/staffing";
import VendorPortal from "./routes/vendors/vendor-portal";
import ManageVendors from "routes/vendors/manage-vendors";
import GeneralApplication from "./routes/applications/general-application";
import ICFApplication from "./routes/applications/icf-application";
import CBPApplication from "./routes/applications/cbp-processing-application";
import ElPasoCPCApplication from "./routes/applications/el-paso-cpc-application";
import TrailbossEnterpriseApplication from "./routes/applications/trailboss-enterprise-application";
import UCTransportLogsApplication from "routes/applications/uc-transport-logs-application";
import FileViewer from "./components/drop-box/file-viewer";
import ContractPortal from "routes/contracts/contract-portal";
import Contracts from "routes/contracts/contracts";
import Demo from "routes/demo";
import RDInternalApplication from "routes/applications/rd-internal-application";
import ContractApplication from "routes/applications/contract-application";
import { ConnectionPortal } from 'routes/connections/connection-portal';
import { Projects } from 'routes/projects/projects';
import { ProjectPortal } from 'routes/projects/project-portal';
import SalesFileViewer from "components/drop-box/sales-file-viewer";
import ProjectsFileViewer from 'components/project-portal/projects-file-viewer';
import PrivacyPolicy from 'components/general/privacy-policy';
import TermsOfService from 'components/general/terms-of-service';

export default function App() {
  return (
    <Routes>
      <Route path="/demo" element={<Demo />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/general-application" element={<GeneralApplication />} />
      <Route path="/application/:alias" element={<ContractApplication />} />

      {/* Depreciate route - when this route is visited redirects user to dynamic application */}
      <Route
        path="/rd-internal-application"
        element={<RDInternalApplication />}
      />
      {/* Depreciate route - when this route is visited redirects user to dynamic application */}
      <Route path="/cbp-processing-application" element={<CBPApplication />} />

      <Route path="/icf-application" element={<ICFApplication />} />

      {/* USED FOR ALL FILES EXCEPT FOR SALE FILE */}
      <Route
        path="/viewer/:fileID"
        element={
          <ProtectedRoute>
            <FileViewer />
          </ProtectedRoute>
        }
      />
      
      {/* USED FOR SALE-RELATED FILES */}
      <Route
        path="/sales-viewer/:fileID"
        element={
          <ProtectedSalesRoute>
            <SalesFileViewer />
          </ProtectedSalesRoute>
        }
      />
      <Route
        path="/projects-viewer/:fileID"
        element={
          <ProtectedProjectsRoute>
            <ProjectsFileViewer />
          </ProtectedProjectsRoute>
        }
      />
      {/* <Route
        path="/elpaso-cpc-application"
        element={<ElPasoCPCApplication />}
      /> */}
      
      {/* Depreciate route - when this route is visited redirects user to dynamic application */}
      <Route
        path="/trailboss-enterprise-application"
        element={<TrailbossEnterpriseApplication />}
      />
      <Route
        path="/uc-transport-logs-application"
        element={<UCTransportLogsApplication />}
      />
      <Route
        path="/staffing"
        element={
          <ProtectedRecruiterStaffingRoute>
            <Staffing />
          </ProtectedRecruiterStaffingRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedAccountingStaffingRoute>
            <Users />
          </ProtectedAccountingStaffingRoute>
        }
      />
      <Route
        path="/contracts"
        element={
          <ProtectedStaffingRoute>
            <Contracts />
          </ProtectedStaffingRoute>
        }
      />
      <Route
        path="/contract-portal/:id"
        element={
          <ProtectedStaffingRoute>
            <ContractPortal />
          </ProtectedStaffingRoute>
        }
      />
      <Route
        path="/sales"
        element={
          <ProtectedSalesRoute>
            <Sales />
          </ProtectedSalesRoute>
        }
      />
      <Route
        path="/sales-portal/:id"
        element={
          <ProtectedSalesRoute>
            <SalesPortal />
          </ProtectedSalesRoute>
        }
      />
      <Route
        path="/connection-portal/:id"
        element={
          <ProtectedSalesRoute>
            <ConnectionPortal />
          </ProtectedSalesRoute>
        }
      />
      <Route
        path="/projects"
        element={
          <ProtectedProjectsRoute>
            <Projects />
          </ProtectedProjectsRoute>
        }
      />
      <Route
        path="/project-portal/:id"
        element={
          <ProtectedProjectsRoute>
            <ProjectPortal />
          </ProtectedProjectsRoute>
        }
      />
      <Route
        path="/accounting"
        element={
          <ProtectedAccountingRoute>
            <ManageVendors />
          </ProtectedAccountingRoute>
        }
      />
      <Route
        path="/vendor-portal/:id"
        element={
          <ProtectedVendorRoute>
            <VendorPortal />
          </ProtectedVendorRoute>
        }
      />
      <Route
        path="/applicant-portal/:id"
        element={
          <ProtectedRoute>
            <ApplicantProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/employee-portal/:id"
        element={
          <ProtectedRoute>
            <EmployeePortal />
          </ProtectedRoute>
        }
      />
      <Route
        path="/welcome"
        element={
          <ProtectedInternalRoute>
            <SplashScreen />
          </ProtectedInternalRoute>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <PrivacyPolicy />
        }
      />
      <Route
        path="/terms-of-service"
        element={
          <TermsOfService />
        }
      />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}
