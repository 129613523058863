import 'css/contracts.scss';

export const FilterDropdown = ({ value, onChange, options, style }) => (
  <div className="contract-filter">
    <select
      id="filter-status"
      className="contract-form-input contract-form-input-capitalized"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      style={style}
    >
      {options.map(item => (
        <option key={item} value={item}>{item}</option>
      ))}
    </select>
  </div>
);