import React, { useState } from "react";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import UserComms from "../modal/user-comms";
import EditUserModal from "../modal/edit-user-modal";
import Toast from "../toast/toast";
import { formatPhoneNumber } from 'shared/formatting';
import API from "API";
import { Checkbox } from "@mui/material";

export default function Row(props) {
  const {
    firstUser,
    updateApplicantAlerts,
    updateUsersList,
    allUsers,
    bounced,
  } = props;

  const token = JSON.parse(localStorage.getItem("token"));

  const [open, setOpen] = useState(false);

  const [openCommsDialog, setOpenCommsDialog] = useState(false);
  const [openEditToast, setOpenEditToast] = useState(false);
  const [openDeleteToast, setOpenDeleteToast] = useState(false);
  const [user, setUser] = useState(props.user ?? null);
  const [checked, setChecked] = useState(user.checked);

  const createNameStyles = () => {
    let styles = {
      borderBottom: "0",
      borderTop: "1px solid #EFEFEF80",
      color: "white",
      fontFamily: "Korolev Medium",
      paddingLeft: "25px",
      textTransform: "capitalize",
    };

    if (firstUser) {
      styles.borderTop = "0";
    }

    if (user?.application?.text_alert) {
      styles.color = "#B84A32";
    }

    return styles;
  };

  const handleClearTextAlert = async () => {
    await fetch(`${API.endpointURL}/api/applicants/${user.application.id}`, {
      method: "PUT",
      body: JSON.stringify({
        text_alert: false,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["errors"]) {
          console.log(data["errors"]);
        } else {
          let userCopy = user;
          userCopy.application.text_alert = false;
          setUser(userCopy);
          updateApplicantAlerts();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleCheckBouncedUsers = async () => {
    await fetch(`${API.endpointURL}/api/bounced_users/${user.id}`, {
      method: "PUT",
      body: JSON.stringify({
        checked: !checked,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["errors"]) {
          console.log(data["errors"]);
        } else {
          let userCopy = user;
          userCopy.checked = !checked;
          setUser(userCopy);
          setChecked(!checked);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <React.Fragment>
      <TableRow
        sx={
          open ? {
            backgroundColor: "#EFEFEF40",
            "&:hover": {
              backgroundColor: "#EFEFEF40",
              cursor: "pointer",
            }
          } : {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "#EFEFEF40",
              cursor: "pointer",
            }
          }
        }
      >
        <TableCell
          sx={
            firstUser
              ? { borderBottom: "0", borderTop: "0" }
              : { borderBottom: "0", borderTop: "1px solid #EFEFEF80" }
          }
        >
          <IconButton
            sx={{
              color: "#EFEFEF",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#dae0bc",
              },
            }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open
              ? <KeyboardArrowUpIcon sx={{ "&:hover": { color: "#62AB37"} }} />
              : <KeyboardArrowDownIcon sx={{ "&:hover": { color: "#62AB37"} }} />
            }
          </IconButton>
        </TableCell>
        <TableCell align="left" sx={createNameStyles()}>
          {user.first_name &&
            user.last_name &&
            user.company &&
            `${user.first_name} ${user.last_name} - ${user.company}`}
          {user.first_name &&
            user.last_name &&
            !user.company &&
            `${user.first_name} ${user.last_name}`}
          {user.first_name && !user.last_name && `${user.first_name}`}
          {!user.first_name && !user.last_name && "N/A"}
        </TableCell>
        <TableCell
          align="left"
          sx={
            firstUser
              ? {
                  borderBottom: "0",
                  borderTop: "0",
                  color: "white",
                  fontFamily: "Korolev Medium",
                  paddingLeft: "25px",
                  textTransform: "capitalize",
                }
              : {
                  borderBottom: "0",
                  borderTop: "1px solid #EFEFEF80",
                  color: "white",
                  fontFamily: "Korolev Medium",
                  paddingLeft: "25px",
                  textTransform: "capitalize",
                }
          }
        >
          {user.role ?? "N/A"}
        </TableCell>
        {bounced === true && (
          <TableCell
            align="center"
            sx={
              firstUser
                ? {
                    borderBottom: "0",
                    borderTop: "0",
                    color: "white",
                    fontFamily: "Korolev Medium",
                    textTransform: "capitalize",
                  }
                : {
                    borderBottom: "0",
                    borderTop: "1px solid #EFEFEF80",
                    color: "white",
                    fontFamily: "Korolev Medium",
                    textTransform: "capitalize",
                  }
            }
          >
            {user.failed_row ?? "N/A"}
          </TableCell>
        )}
        {bounced === true && (
          <TableCell
            align="center"
            sx={
              firstUser
                ? {
                    borderBottom: "0",
                    borderTop: "0",
                    color: "white",
                    fontFamily: "Korolev Medium",
                    textTransform: "capitalize",
                  }
                : {
                    borderBottom: "0",
                    borderTop: "1px solid #EFEFEF80",
                    color: "white",
                    fontFamily: "Korolev Medium",
                    textTransform: "capitalize",
                  }
            }
          >
            {user.report_type ?? "N/A"}
          </TableCell>
        )}

        <TableCell
          align="center"
          sx={
            firstUser
              ? { borderBottom: "0", borderTop: "0" }
              : { borderBottom: "0", borderTop: "1px solid #EFEFEF80" }
          }
        >
          {bounced === true ? (
            <Checkbox
              type="checkbox"
              checked={checked}
              onChange={() => handleCheckBouncedUsers()}
              sx={{
                color: "#EFEFEF",
                "&.Mui-checked": {
                  color: "#EFEFEF",
                },
              }}
            ></Checkbox>
          ) : (
            <EditUserModal
              user={user}
              onEditComplete={(user) => setUser(user)}
              setOpenDeleteToast={setOpenDeleteToast}
              setOpenEditToast={setOpenEditToast}
              allUsers={allUsers}
              updateUsersList={updateUsersList}
            />
          )}

          {openDeleteToast && (
            <Toast
              open={openDeleteToast}
              onClose={() => setOpenDeleteToast(false)}
              message="User deleted"
            />
          )}
          {openEditToast && (
            <Toast
              open={openEditToast}
              onClose={() => setOpenEditToast(false)}
              message="User saved"
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow
        sx={
          open
            ? { backgroundColor: "#EFEFEF40" }
            : { backgroundColor: "transparent" }
        }
      >
        <TableCell
          style={{ border: "0", paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, mb: 2.5, color: "white" }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        borderBottom: "1px solid #EFEFEF80",
                        color: "#EFEFEF",
                        fontFamily: "Korolev Medium",
                        fontSize: "1.05rem",
                      }}
                    >
                      Phone Number
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderBottom: "1px solid #EFEFEF80",
                        color: "#EFEFEF",
                        fontFamily: "Korolev Medium",
                        fontSize: "1.05rem",
                      }}
                    >
                      Email
                    </TableCell>
                    {bounced !== true && (
                      <TableCell
                        align="center"
                        sx={{
                          borderBottom: "1px solid #EFEFEF80",
                          color: "#EFEFEF",
                          fontFamily: "Korolev Medium",
                          fontSize: "1.05rem",
                        }}
                      >
                        Text
                      </TableCell>
                    )}
                    {bounced !== true ? (
                      <TableCell
                        align="left"
                        sx={{
                          borderBottom: "1px solid #EFEFEF80",
                          color: "#EFEFEF",
                          fontFamily: "Korolev Medium",
                          fontSize: "1.05rem",
                        }}
                      >
                        Last Contacted
                      </TableCell>
                    ) : (
                      <TableCell
                        align="left"
                        sx={{
                          borderBottom: "1px solid #EFEFEF80",
                          color: "#EFEFEF",
                          fontFamily: "Korolev Medium",
                          fontSize: "1.05rem",
                        }}
                      >
                        Date Bounced
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={user.phone}>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #EFEFEF80",
                        color: "white",
                        fontFamily: "Korolev Medium",
                      }}
                    >
                      {user.phone ? formatPhoneNumber(user.phone) : "N/A"}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid #EFEFEF80",
                        color: "white",
                        fontFamily: "Korolev Medium",
                      }}
                    >
                      {user.email ?? "N/A"}
                    </TableCell>
                    {bounced !== true && (
                      <TableCell
                        align="center"
                        sx={{ borderBottom: "1px solid #EFEFEF80" }}
                      >
                        <Button
                          onClick={() => setOpenCommsDialog(true)}
                          disabled={user.role !== "applicant"}
                          sx={
                            user?.application?.text_alert
                              ? {
                                  color: "#B84A32",
                                  "&:hover": {
                                    color: "#dae0bc",
                                  },
                                }
                              : {
                                  color: "#EFEFEF",
                                  "&:hover": {
                                    color: "#dae0bc",
                                  },
                                }
                          }
                        >
                          <ChatBubbleOutlineIcon />
                        </Button>
                      </TableCell>
                    )}

                    <TableCell
                      sx={{
                        borderBottom: "1px solid #EFEFEF80",
                        color: "white",
                        fontFamily: "Korolev Medium",
                      }}
                    >
                      {user.created_at ?? "N/A"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {bounced === true && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          borderBottom: "1px solid #EFEFEF80",
                          color: "#EFEFEF",
                          fontFamily: "Korolev Medium",
                          fontSize: "1.05rem",
                        }}
                      >
                        Error Message
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={user.error}>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid #EFEFEF80",

                          color: "white",
                          fontFamily: "Korolev Medium",
                        }}
                      >
                        {user.error ?? "N/A"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {openCommsDialog && (
        <UserComms
          clearTextAlert={() => handleClearTextAlert()}
          handleClose={() => setOpenCommsDialog(false)}
          open={openCommsDialog}
          selectedUser={user}
        />
      )}
    </React.Fragment>
  );
}
