import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const GrayAutoStyledButton = styled(Button)({
  backgroundColor: "#EFEFEF80",
  border: "1px solid #EFEFEF80",
  borderRadius: "10px",
  color: "#EFEFEF",
  fontFamily: "MagistralCondW08-Medium",
  minHeight: "25px",
  padding: "13px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "transparent",
    border: "1px solid #EFEFEF80",
  },
});

export const HighlightGreenAutoStyledButton = styled(Button)({
  backgroundColor: "#62AB37",
  border: "1px solid #62AB37",
  borderRadius: "10px",
  color: "#EFEFEF",
  fontFamily: "MagistralCondW08-Medium",
  minHeight: "25px",
  padding: "13px 20px",
  textTransform: "capitalize",
  width: "100%",
  "&:hover": {
    backgroundColor: "transparent",
    border: "1px solid #EFEFEF80",
  },
});

export const HighlightBlueAutoStyledButton = styled(Button)({
  backgroundColor: "#00A5CF",
  border: "1px solid #00A5CF",
  borderRadius: "10px",
  color: "#EFEFEF",
  fontFamily: "MagistralCondW08-Medium",
  minHeight: "25px",
  padding: "13px 20px",
  textTransform: "capitalize",
  width: "100%",
  "&:hover": {
    backgroundColor: "transparent",
    border: "1px solid #EFEFEF80",
  },
});

export const HighlightPurpleAutoStyledButton = styled(Button)({
  backgroundColor: "#531CB3",
  border: "1px solid #531CB3",
  borderRadius: "10px",
  color: "#EFEFEF",
  fontFamily: "MagistralCondW08-Medium",
  minHeight: "25px",
  padding: "13px 20px",
  textTransform: "capitalize",
  width: "100%",
  "&:hover": {
    backgroundColor: "transparent",
    border: "1px solid #EFEFEF80",
  },
});

export const HighlightNavyAutoStyledButton = styled(Button)({
  backgroundColor: "#141A66",
  border: "0.5px solid #bbbbbb",
  borderRadius: "10px",
  color: "#EFEFEF",
  fontFamily: "MagistralCondW08-Medium",
  minHeight: "25px",
  padding: "13px 20px",
  textTransform: "capitalize",
  width: "100%",
  "&:hover": {
    backgroundColor: "transparent",
    border: "0.5px solid #bbbbbb",
  },
});
