import React, { useState } from 'react';

import DatePicker from 'react-datepicker';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton, TextField, Typography } from '@mui/material';

import API from 'API';

import Toast from 'components/toast/toast';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { numberWithCommas } from 'shared/formatting';
import NewBaseModal from 'components/modal/new-base-modal';
import { ProjectsUploadSingleModal } from 'components/modal/project-upload-single-modal';
import CloseIcon from '@mui/icons-material/Close';
import fileIcon from 'assets/uploaded_file.svg';
import { useAppSelector } from 'hooks/reduxHooks';
import { projectsCoordinatorsSelector, projectsManagersSelector } from 'store/projects/selectors';

const initialInfo = {
  project_name: '',
  project_manager_id: '',
  project_coordinator_id: '',
  description: '',
  estimated_value: '',
  status: '',
  project_start_date: '',
  project_end_date: '',
};

const initialFile = {
  file: null,
  name: '',
};

export const CreateProjectTab = ({ getProjects }) => {
  const token = JSON.parse(localStorage.getItem('token'));

  const [projectForm, setProjectForm] = useState(initialInfo);
  const [openToast, setOpenToast] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [toastMessage, setToastMessage] = useState('Error. Please enter Project Name.');
  const [openUploadModal, setOpenUploadModal] = useState(false);
  
  const [contractFile, setContractFile] = useState(initialFile);
  const [statementFile, setStatementFile] = useState(initialFile);
  const [uploadType, setUploadType] = useState('Contract');
  const [loadingContract, setLoadingContract] = useState(false);
  const [loadingStatement, setLoadingStatement] = useState(false);
  
  const { projectManagers, loadingProjectManagers, errorProjectManagers } = useAppSelector(projectsManagersSelector);
  const { projectCoordinators, loadingProjectCoordinators, errorProjectCoordinators } = useAppSelector(projectsCoordinatorsSelector);

  const setMessage = () => {
    const {
      project_name,
      project_manager_id,
      project_coordinator_id,
      description,
      estimated_value,
      status,
      project_start_date,
      project_end_date,
    } = projectForm;

    let error;

    if (!project_name) {
      setToastMessage('Error. Please enter Project Name.');
      error = true;
    } else if (project_name.length > 255) {
      setToastMessage('Error. Project Name max length is 255 characters.');
      error = true;
    } else if (!project_manager_id) {
      setToastMessage('Error. Please select Project Manager.');
      error = true;
    } else if (!project_start_date) {
      setToastMessage('Error. Please enter Project Start Date.');
      error = true;
    } else if (!project_end_date) {
      setToastMessage('Error. Please enter Project End Date.');
      error = true;
    } else {
      setToastMessage('New Project Successfully Created.');
      error = false;
    }

    return error;
  };

  const handleCreate = () => {
    if (setMessage()) {
      setOpenToast(true);
    } else {
      try {
        let requestError = false;
        setLoadingCreate(true);

        const body = {
          project_name: projectForm.project_name,
          project_manager_id: projectForm.project_manager_id,
          project_coordinator_id: projectForm.project_coordinator_id,
          description: projectForm.description,
          estimated_value: projectForm.estimated_value,
          status: 'current',
          project_start_date: projectForm.project_start_date,
          project_end_date: projectForm.project_end_date,
        };

        if (projectForm.estimated_value) {
          body.estimated_value = Number(projectForm.estimated_value);
        }
        if (projectForm.project_manager_id) {
          body.project_manager_id = parseInt(projectForm.project_manager_id);
        }
        if (projectForm.project_coordinator_id) {
          body.project_coordinator_id = parseInt(projectForm.project_coordinator_id);
        }

        fetch(`${API.endpointURL}/api/projects`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
        })
          .then((res) => {
            if (!res.ok) {
              requestError = true;
            }
            return res.json();
          })
          .then((res) => {
            if (requestError) {
              setOpenToast(true);
              setLoadingCreate(false);
              if (res.message?.includes('already')) {
                setToastMessage('Error. A Project with the same name already exists.');
              } else {
                setToastMessage('Something went wrong, try again later...');
              }
            } else {
              setProjectForm(initialInfo);
              if (contractFile.name) {
                uploadFile(
                  res.data.id,
                  contractFile.name,
                  contractFile.file,
                  res.data.file_types[0].id,
                  setLoadingContract,
                  setContractFile,
                );
              }
              if (statementFile.name) {
                uploadFile(
                  res.data.id,
                  statementFile.name,
                  statementFile.file,
                  res.data.file_types[1].id,
                  setLoadingStatement,
                  setStatementFile,
                );
              }
              if (!statementFile.name && !contractFile.name) {
                setLoadingCreate(false);
                setToastMessage('New Project Successfully Created.');
                setOpenToast(true);
                getProjects();
              }
            }
          });
      } catch (e) {
        setLoadingCreate(false);
        setOpenToast(true);
        setToastMessage('Something went wrong, try again later...');
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    
    setProjectForm({
      ...projectForm,
      [name]: value,
    });
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;

    // Process the input value
    const decimalCount = (value.match(/\./g) || []).length;
    let rawNumericValue;

    if (decimalCount <= 1) {
      rawNumericValue = value.replace(/[^\d.]/g, '');
    } else {
      rawNumericValue = value.replace(/[^\d.]/g, '').replace(/\.(?=.*\.)/g, '');
    }

    // Trim if there are more than two characters after the dot
    if (rawNumericValue.includes('.')) {
      const parts = rawNumericValue.split('.');
      if (parts[1].length > 2) {
        rawNumericValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
      }
    }

    setProjectForm({ ...projectForm, estimated_value: rawNumericValue });
  };

  const renderFetchedOptions = (type) => {
    let loadingOptions;
    let errorOptions;
    let fetchedData;

    if (type === 'Coordinator') {
      loadingOptions = loadingProjectCoordinators;
      errorOptions = errorProjectCoordinators;
      fetchedData = projectCoordinators;
    } else if (type === 'Manager') {
      loadingOptions = loadingProjectManagers;
      errorOptions = errorProjectManagers;
      fetchedData = projectManagers;
    }

    if (!loadingOptions && errorOptions.length > 0) {
      return <option value="">Error loading {type}s...</option>;
    } else if (loadingOptions && !errorOptions) {
      return <option value="">Loading {type}s...</option>;
    } else if (!loadingOptions && !errorOptions && fetchedData) {
      return (
        <>
          <option value="">Select {type}...</option>
          {fetchedData.map((element) => (
            <option key={element.id} value={element.id}>
              {`${element.first_name} ${element.last_name}`}
            </option>
          ))}
        </>
      );
    }
    return '';
  };

  const uploadFile = (id, name, file, type, setLoading, setFile) => {
    setLoading(true);
    const formData = new FormData();

    formData.append('display_name', name);
    formData.append('file', file);
    formData.append('type_id', type);
    formData.append('project_id', id);
    fetch(`${API.endpointURL}/api/projects_files/upload`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        Connection: 'keep-alive',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then(() => {
        setLoading(false);
        setOpenToast(true);
        setToastMessage('New Project successfully created!');
        setFile(initialFile);
        setLoadingCreate(false);
      })
      .catch(() => {
        setLoading(false);
        setToastMessage('Project is created. Error uploading files. Check project drop-box.');
      });
  };

  return (
    <section>
      <h1 className="content-header">Create New Project</h1>
      <div className="content-container">
        {(loadingProjectManagers || loadingProjectCoordinators) ? (
          <div className="content-loading">
            <ProgressSpinnerNavy />
          </div>
        ) : (
          <div className="sale-form-section-content">
            <div className="sale-form-set">
              <div className="sale-form-label">Project Name *</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={projectForm.project_name || ''}
                name="project_name"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="sale-form-label">Project Manager *</div>
              <select
                className="sale-form-input"
                placeholder="Select Manager..."
                value={projectForm.project_manager_id || ''}
                name="project_manager_id"
                onChange={handleChange}
                style={{
                  color: !projectForm.project_manager_id ? '#EFEFEF80' : '#EFEFEF',
                  padding: '7px 12px',
                }}
              >
                {renderFetchedOptions('Manager')}
              </select>
            </div>

            <div className="sale-form-set">
              <div className="sale-form-label">Project Coordinator *</div>
              <select
                className="sale-form-input"
                placeholder="Select Coordinator..."
                value={projectForm.project_coordinator_id || ''}
                name="project_coordinator_id"
                onChange={handleChange}
                style={{
                  color: !projectForm.project_coordinator_id ? '#EFEFEF80' : '#EFEFEF',
                  padding: '7px 12px',
                }}
              >
                {renderFetchedOptions('Coordinator')}
              </select>
            </div>

            <div className="sale-form-set">
              <div className="sale-form-label">Project Start Date *</div>
              <DatePicker
                className="sale-form-input"
                selected={
                  projectForm.project_start_date
                    ? new Date(projectForm.project_start_date.slice(0, 10))
                    : null
                }
                placeholderText={projectForm.project_start_date ? undefined : 'N/A'}
                onChange={(date) => {
                  if (date) {
                    const dd = String(date.getDate()).padStart(2, '0');
                    const mm = String(date.getMonth() + 1).padStart(2, '0');
                    const yyyy = date.getFullYear();
                    const dateString = yyyy + '-' + mm + '-' + dd;
                    setProjectForm({
                      ...projectForm,
                      project_start_date: dateString,
                    });
                  }
                }}
                dateFormat="MM/dd/yyyy"
              />
            </div>

            <div className="sale-form-set">
              <div className="sale-form-label">Project End Date *</div>
              <DatePicker
                className="sale-form-input"
                selected={
                  projectForm.project_end_date
                    ? new Date(projectForm.project_end_date.slice(0, 10))
                    : null
                }
                placeholderText={projectForm.project_end_date ? undefined : 'N/A'}
                onChange={(date) => {
                  if (date) {
                    const dd = String(date.getDate()).padStart(2, '0');
                    const mm = String(date.getMonth() + 1).padStart(2, '0');
                    const yyyy = date.getFullYear();
                    const dateString = yyyy + '-' + mm + '-' + dd;
                    setProjectForm({
                      ...projectForm,
                      project_end_date: dateString,
                    });
                  }
                }}
                dateFormat="MM/dd/yyyy"
              />
            </div>

            <div className="sale-form-set">
              <div className="sale-form-label sale-form-label">Estimated Value</div>
              <TextField
                value={numberWithCommas(projectForm.estimated_value) || ''}
                name="cost_to_company"
                onChange={handleNumberChange}
                sx={{
                  backgroundColor: '#EFEFEF40',
                  border: '1px solid #EFEFEF',
                  borderRadius: '6px',
                  padding: '2px 10px 1px',
                  marginBottom: 0,
                  width: '95%',
                  color: '#EFEFEF',
                  '& .MuiInputBase-input': {
                    color: '#EFEFEF',
                    borderRadius: 0,
                    fontFamily: 'Korolev Medium',
                  },
                }}
                placeholder="N/A"
                multiline={false}
                rows={1}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start" sx={{ '& p': { color: '#EFEFEF' } }}>
                      $
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="sale-form-set sale-form-set-full">
              <div className="sale-form-label">Project Description</div>
              <textarea
                className="sale-form-input sale-form-input-textarea"
                placeholder="N/A"
                value={projectForm.description || ''}
                name="description"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set" style={{ display: 'flex', margin: 0, alignItems: 'flex-start' }}>
              {contractFile.name ? (
                <div className={'projects__dropbox-files-file'}>
                  <Typography
                    sx={{
                      color: '#EFEFEF',
                      fontFamily: 'Korolev Medium',
                      fontSize: '15px',
                      margin: '0',
                    }}
                  >
                    Contract
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setContractFile(initialFile);
                    }}
                    sx={{
                      position: 'absolute',
                      top: '18px',
                      right: '-10px',
                      color: '#EFEFEF',
                      justifyContent: 'flex-end',
                      padding: '0',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#a20601',
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <IconButton
                    sx={{ '&:hover': { backgroundColor: 'transparent', cursor: 'default' } }}
                  >
                    <img
                      src={fileIcon}
                      width="57px"
                      height="75px"
                      alt="open-file-button"
                    />
                  </IconButton>
                  <Typography
                    sx={{
                      color: '#EFEFEF',
                      fontFamily: 'Korolev Medium',
                      fontSize: '0.85rem',
                      margin: '0 auto',
                    }}
                  >
                    {contractFile.name}
                  </Typography>
                </div>
              ) : (
                <button
                  className="create-button"
                  onClick={() => {
                    setUploadType('Contract');
                    setOpenUploadModal(true);
                  }}
                >
                  Add Project Contract File
                </button>
              )}
            </div>

            <div className="sale-form-set" style={{ display: 'flex', margin: 0, alignItems: 'flex-start' }}>
              {statementFile.name ? (
                <div className={'projects__dropbox-files-file'} style={{ display: 'flex', width: '73px' }}>
                  <Typography
                    sx={{
                      color: '#EFEFEF',
                      fontFamily: 'Korolev Medium',
                      fontSize: '15px',
                      margin: '0 auto',
                      width: '145px',
                    }}
                  >
                    Statement Of Work
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setStatementFile(initialFile);
                    }}
                    sx={{
                      position: 'absolute',
                      top: '18px',
                      right: '-10px',
                      color: '#EFEFEF',
                      justifyContent: 'flex-end',
                      padding: '0',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#a20601',
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <IconButton
                    sx={{ '&:hover': { backgroundColor: 'transparent', cursor: 'default' } }}
                  >
                    <img
                      src={fileIcon}
                      width="57px"
                      height="75px"
                      alt="open-file-button"
                    />
                  </IconButton>
                  <Typography
                    sx={{
                      color: '#EFEFEF',
                      fontFamily: 'Korolev Medium',
                      fontSize: '0.85rem',
                      margin: '0 auto',
                    }}
                  >
                    {statementFile.name}
                  </Typography>
                </div>
              ) : (
                <button
                  className="create-button"
                  onClick={() => {
                    setUploadType('Statement Of Work');
                    setOpenUploadModal(true);
                  }}
                >
                  Add Statement Of Work File
                </button>
              )}
            </div>

            <div className="sale-form-button-container" style={{ margin: 0 }}>
              {(loadingCreate || loadingContract || loadingStatement) && (
                <div className="sale-form-spinner">
                  <ProgressSpinnerNavy />
                </div>
              )}
              <button
                className="create-button"
                onClick={handleCreate}
                disabled={loadingCreate}
              >
                Create
              </button>
            </div>
          </div>
        )}

      </div>
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={toastMessage}
        />
      )}
      <NewBaseModal
        dialogContent={
          <ProjectsUploadSingleModal
            onUpload={uploadType === 'Contract' ? setContractFile : setStatementFile}
            onClose={() => setOpenUploadModal(false)}
          />
        }
        onClose={() => setOpenUploadModal(false)}
        open={openUploadModal}
        title={`Add ${uploadType} `}
        colorPalette="navy"
      />
    </section>
  );
};
