import React from 'react';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import fileIcon from 'assets/uploaded_file.svg';
import { uploadTypeToFile } from 'shared/vendor-data';

export const ProjectFile = ({ file, setSelectedFile, setOpenDissociateModal, setOpenEditFileModal }) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };
  return (
    <div className={'projects__dropbox-files-file'} style={{ width: '73px' }}>
      <IconButton
        onClick={() => {
          setSelectedFile(file);
          setOpenDissociateModal(true);
        }}
        sx={{
          position: 'absolute',
          top: '-10px',
          right: '-20px',
          color: '#EFEFEF',
          justifyContent: 'flex-end',
          padding: '0',
          width: '100%',
          '&:hover': {
            backgroundColor: 'transparent',
            color: '#a20601',
          },
        }}
      >
        <CloseIcon/>
      </IconButton>
      <IconButton
        onClick={() => {
          setSelectedFile(file);
          setOpenEditFileModal(true);
        }}
        sx={{
          position: 'absolute',
          top: '20px',
          right: '-18px',
          color: '#EFEFEF',
          justifyContent: 'flex-end',
          padding: '0',
          width: '100%',
          '&:hover': {
            backgroundColor: 'transparent',
            color: '#60992d',
          },
        }}
      >
        <BorderColorIcon fontSize="small"/>
      </IconButton>
      <IconButton
        sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        onClick={() => openInNewTab('/projects-viewer/' + file.id)}
      >
        <img
          src={fileIcon}
          width="57px"
          height="75px"
          alt="open-file-button"
        />
      </IconButton>
      <Typography
        onClick={() => openInNewTab('/projects-viewer/' + file.id)}
        sx={{
          color: '#EFEFEF',
          fontFamily: 'Korolev Medium',
          fontSize: '0.85rem',
          margin: '0 auto',
          maxWidth: '73px',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        {file.display_name
          ? file.display_name
          : `${uploadTypeToFile[file.type]} v${file.version}`}
      </Typography>
    </div>
  );
};
