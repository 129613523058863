import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  currentSalesTabSelector, isSearchedSalesSelector, salesSearchInputSelector, salesSearchTypeSelector,
  salesStageSelector,
  selectedBusinessLinesSelector, selectedConnectionsSelector, selectedSalesRepsSelector,
} from 'store/salesSelectedOptions/selectors';
import { getSalesByFilter } from 'store/sales/thunk';
import { salesCurrentPageSelector } from 'store/sales/selectors';

export const useGetSalesByFilter = () => {
  const dispatch = useAppDispatch();
  const currentStage = useAppSelector(salesStageSelector);
  const currentTab = useAppSelector(currentSalesTabSelector);
  const businessLines = useAppSelector(selectedBusinessLinesSelector);
  const salesReps = useAppSelector(selectedSalesRepsSelector);
  const connections = useAppSelector(selectedConnectionsSelector);
  const searchInput = useAppSelector(salesSearchInputSelector);
  const searchType = useAppSelector(salesSearchTypeSelector);
  const isSearched = useAppSelector(isSearchedSalesSelector);
  const currentPage = useAppSelector(salesCurrentPageSelector);

  const getSales = (option = '') => {
    const stage = `stage=${currentStage}`;
    let type = '';
    let search = '';
    let businessFilter = '';
    let salesRepsFilter = '';
    let connectionsFilter = '';

    if (currentTab === 'direct sales pipeline') {
      type = '&type=direct';
    } else if (currentTab === 'rental sales pipeline') {
      type = '&type=rental';
    }

    if (businessLines.length > 0) {
      businessLines.forEach(item => {
        businessFilter += `&division_id[]=${item.id}`
      });
    }

    if (salesReps.length > 0) {
      salesReps.forEach(item => {
        salesRepsFilter += `&sales_rep_id[]=${item.id}`
      })
    }

    if (connections.length > 0) {
      connections.forEach(item => {
        connectionsFilter += `&connection_id[]=${item.id}`
      });
    }

    if (isSearched) {
      search = `&${searchType}=${searchInput}`;
    }
    if (option === 'search') {
      search = `&${searchType}=${searchInput}`;
    }
    if (option === 'clear') {
      search = '';
    }

    dispatch(getSalesByFilter({
      filters: `${stage}${type}${businessFilter}${salesRepsFilter}${connectionsFilter}${search}&page=${currentPage}`
    }));
  }

  return getSales;
}