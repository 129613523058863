import { createSlice } from '@reduxjs/toolkit';
import {
  addActiveTagToApplicant, createTag,
  createTagGroup,
  createTagType, deleteGroup,
  deleteTag, deleteType, filterTags,
  getActiveApplicantTags,
  getAvailableApplicantTags,
  getTag,
  getTagGroups,
  getTags,
  getTagTypes,
  removeActiveTagFromApplicant,
  updateTag,
  updateTagGroup,
  updateTagType,
} from 'store/tags/thunk';

const initialState = {
  tags: [],
  tagsLoading: false,
  tag: null,
  selectedTag: null,
  tagLoading: false,
  tagError: '',
  selectedType: null,
  selectedGroup: null,
  
  deleteTag: {
    tagDeleteError: '',
    tagDeleteLoading: false,
    tagDeleteStatus: '',
  },
  
  createTag: {
    tagCreateError: '',
    tagCreateLoading: false,
  },
  
  editTag: {
    tagEditError: '',
    tagEditLoading: false,
  },
  
  tagsError: '',
  isLoading: false,
  deleteLoading: false,
  types: [],
  groups: [],
  createTypeStatus: '',
  updateTypeStatus: '',
  deleteTypeStatus: '',
  deleteTypeError: '',
  createGroupStatus: '',
  updateGroupStatus: '',
  deleteGroupStatus: '',
  deleteGroupError: '',
  
  applicantTags: {
    activeTags: [],
    availableTags: [],
    loadingTags: false,
    errorTags: '',
    tagsStatus: '',
  }
};

const tagsSlice = createSlice({
  name: 'tagsSlice',
  initialState,
  reducers: {
    setCreateTypeStatus(state, { payload }) {
      state.createTypeStatus = payload;
    },
    setUpdateTypeStatus(state, { payload }) {
      state.updateTypeStatus = payload;
    },
    setDeleteTypeStatus(state, { payload }) {
      state.deleteTypeStatus = payload;
    },
    setCreateGroupStatus(state, { payload }) {
      state.createGroupStatus = payload;
    },
    setUpdateGroupStatus(state, { payload }) {
      state.updateGroupStatus = payload;
    },
    setDeleteGroupStatus(state, { payload }) {
      state.deleteGroupStatus = payload;
    },
    setTagStatus(state, { payload }) {
      state.applicantTags.tagsStatus = payload;
    },
    setSelectedTag(state, { payload }) {
      state.selectedTag = payload;
    },
    setDeleteTagStatus(state, { payload }) {
      state.deleteTag.tagDeleteStatus = payload;
    },
    setSelectedType(state, { payload }) {
      state.selectedType = payload;
    },
    setSelectedGroup(state, { payload }) {
      state.selectedGroup = payload;
    },
    setTag(state, { payload }) {
      state.tag = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTags.pending, (state) => {
      state.tagsError = '';
      state.tagsLoading = true;
    });
    builder.addCase(getTags.fulfilled, (state, { payload }) => {
      state.tags = payload;
      state.tagsError = '';
      state.tagsLoading = false;
    });
    builder.addCase(getTags.rejected, (state, action) => {
      state.tagsError = action.error.message;
      state.tagsLoading = false;
    });
    
    builder.addCase(getTagTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTagTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.types = payload;
    });
    builder.addCase(getTagTypes.rejected, (state) => {
      state.isLoading = false;
    });
    
    builder.addCase(updateTagType.pending, (state) => {
      state.isLoading = true;
      state.updateTypeStatus = 'pending';
    });
    builder.addCase(updateTagType.fulfilled, (state) => {
      state.isLoading = false;
      state.updateTypeStatus = 'fulfilled';
    });
    builder.addCase(updateTagType.rejected, (state) => {
      state.isLoading = false;
      state.updateTypeStatus = 'rejected';
    });
    
    builder.addCase(deleteType.pending, (state) => {
      state.deleteLoading = true;
      state.deleteTypeError = '';
      state.deleteTypeStatus = 'pending';
    });
    builder.addCase(deleteType.fulfilled, (state) => {
      state.deleteTypeError = '';
      state.deleteLoading = false;
      state.deleteTypeStatus = 'fulfilled';
    });
    builder.addCase(deleteType.rejected, (state) => {
      state.deleteTypeError = 'Error deleting Tag Type';
      state.deleteLoading = false;
      state.deleteTypeStatus = 'rejected';
    });
    
    builder.addCase(createTagType.pending, (state) => {
      state.isLoading = true;
      state.createTypeStatus = 'pending';
    });
    builder.addCase(createTagType.fulfilled, (state) => {
      state.isLoading = false;
      state.createTypeStatus = 'fulfilled';
    });
    builder.addCase(createTagType.rejected, (state) => {
      state.isLoading = false;
      state.createTypeStatus = 'rejected';
    });
   
    builder.addCase(getTagGroups.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTagGroups.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.groups = payload;
    });
    builder.addCase(getTagGroups.rejected, (state) => {
      state.isLoading = false;
    });
   
    builder.addCase(updateTagGroup.pending, (state) => {
      state.isLoading = true;
      state.updateGroupStatus = 'pending';
    });
    builder.addCase(updateTagGroup.fulfilled, (state) => {
      state.isLoading = false;
      state.updateGroupStatus = 'fulfilled';
    });
    builder.addCase(updateTagGroup.rejected, (state) => {
      state.isLoading = false;
      state.updateGroupStatus = 'rejected';
    });
  
    builder.addCase(createTagGroup.pending, (state) => {
      state.isLoading = true;
      state.createGroupStatus = 'pending';
    });
    builder.addCase(createTagGroup.fulfilled, (state) => {
      state.isLoading = false;
      state.createGroupStatus = 'fulfilled';
    });
    builder.addCase(createTagGroup.rejected, (state) => {
      state.isLoading = false;
      state.createGroupStatus = 'rejected';
    });
    
    builder.addCase(deleteGroup.pending, (state) => {
      state.deleteGroupError = '';
      state.deleteLoading = true;
      state.deleteGroupStatus = 'pending';
    });
    builder.addCase(deleteGroup.fulfilled, (state) => {
      state.deleteGroupError = '';
      state.deleteLoading = false;
      state.deleteGroupStatus = 'fulfilled';
    });
    builder.addCase(deleteGroup.rejected, (state) => {
      state.deleteGroupError = 'Error deleting Tag Group';
      state.deleteLoading = false;
      state.deleteGroupStatus = 'rejected';
    });
    
    builder.addCase(getActiveApplicantTags.pending, (state) => {
      state.applicantTags.errorTags = '';
      state.applicantTags.loadingTags = true;
    });
    builder.addCase(getActiveApplicantTags.fulfilled, (state, action) => {
      state.applicantTags.activeTags = action.payload;
      state.applicantTags.errorTags = '';
      state.applicantTags.loadingTags = false;
    });
    builder.addCase(getActiveApplicantTags.rejected, (state) => {
      state.applicantTags.errorTags = 'error getting tags';
      state.applicantTags.loadingTags = false;
    });
    
    builder.addCase(getAvailableApplicantTags.pending, (state) => {
      state.applicantTags.errorTags = '';
      state.applicantTags.loadingTags = true;
    });
    builder.addCase(getAvailableApplicantTags.fulfilled, (state, action) => {
      state.applicantTags.availableTags = action.payload;
      state.applicantTags.errorTags = '';
      state.applicantTags.loadingTags = false;
    });
    builder.addCase(getAvailableApplicantTags.rejected, (state) => {
      state.applicantTags.errorTags = 'error getting tags';
      state.applicantTags.loadingTags = false;
    });
    
    builder.addCase(addActiveTagToApplicant.pending, (state) => {
      state.applicantTags.tagsStatus = 'pending';
      state.applicantTags.errorTags = '';
      state.applicantTags.loadingTags = true;
    });
    builder.addCase(addActiveTagToApplicant.fulfilled, (state) => {
      state.applicantTags.tagsStatus = 'fulfilled';
      state.applicantTags.errorTags = '';
      state.applicantTags.loadingTags = false;
    });
    builder.addCase(addActiveTagToApplicant.rejected, (state) => {
      state.applicantTags.tagsStatus = 'rejected';
      state.applicantTags.errorTags = 'error adding tag';
      state.applicantTags.loadingTags = false;
    });
    
    builder.addCase(removeActiveTagFromApplicant.pending, (state) => {
      state.applicantTags.tagsStatus = 'pending';
      state.applicantTags.errorTags = '';
      state.applicantTags.loadingTags = true;
    });
    builder.addCase(removeActiveTagFromApplicant.fulfilled, (state) => {
      state.applicantTags.tagsStatus = 'fulfilled';
      state.applicantTags.errorTags = '';
      state.applicantTags.loadingTags = false;
    });
    builder.addCase(removeActiveTagFromApplicant.rejected, (state) => {
      state.applicantTags.tagsStatus = 'rejected';
      state.applicantTags.errorTags = 'error adding tag';
      state.applicantTags.loadingTags = false;
    });
    
    builder.addCase(createTag.pending, (state) => {
      state.createTag.tagCreateError = '';
      state.createTag.tagCreateLoading = true;
    });
    builder.addCase(createTag.fulfilled, (state, { payload }) => {
      state.createTag.tagCreateError = '';
      state.createTag.tagCreateLoading = false;
      state.selectedTag = payload;
    });
    builder.addCase(createTag.rejected, (state, action) => {
      state.createTag.tagCreateLoading = false;
      if (action.payload.response.data.message === "The name has already been taken.") {
        state.createTag.tagCreateError = 'The name has already been taken.';
      } else {
        state.createTag.tagCreateError = 'Error Creating Tag';
      }
    });
    
    builder.addCase(deleteTag.pending, (state) => {
      state.deleteTag.tagDeleteError = '';
      state.deleteTag.tagDeleteLoading = true;
      state.deleteTag.tagDeleteStatus = 'pending';
    });
    builder.addCase(deleteTag.fulfilled, (state) => {
      state.deleteTag.tagDeleteError = '';
      state.selectedTag = null;
      state.deleteTag.tagDeleteLoading = false;
      state.deleteTag.tagDeleteStatus = 'fulfilled';
    });
    builder.addCase(deleteTag.rejected, (state) => {
      state.deleteTag.tagDeleteError = 'Error Deleting Tag';
      state.deleteTag.tagDeleteStatus = 'rejected';
      state.deleteTag.tagDeleteLoading = false;
    });
    
    builder.addCase(updateTag.pending, (state) => {
      state.editTag.tagEditError = '';
      state.editTag.tagEditLoading = true;
    });
    builder.addCase(updateTag.fulfilled, (state, { payload }) => {
      state.editTag.tagEditError = '';
      state.editTag.tagEditLoading = false;
      state.selectedTag = payload;
    });
    builder.addCase(updateTag.rejected, (state) => {
      state.editTag.tagEditError = 'Error Editing Tag';
      state.editTag.tagEditLoading = false;
    });
    
    builder.addCase(getTag.pending, (state) => {
      state.tagError = '';
      state.tagLoading = true;
    });
    builder.addCase(getTag.fulfilled, (state, { payload }) => {
      state.tagError = '';
      state.tagLoading = false;
      state.tag = payload;
      state.selectedTag = payload;
    });
    builder.addCase(getTag.rejected, (state) => {
      state.tagError = 'Error Getting Tag';
      state.tagLoading = false;
    });
    
    builder.addCase(filterTags.pending, (state) => {
      state.tagsError = '';
      state.tagsLoading = true;
    });
    builder.addCase(filterTags.fulfilled, (state, { payload }) => {
      state.tags = payload;
      state.tagsError = '';
      state.tagsLoading = false;
    });
    builder.addCase(filterTags.rejected, (state) => {
      state.tagsError = 'Error getting Tags';
      state.tagsLoading = false;
    });
  },
});

export const {
  setCreateTypeStatus,
  setUpdateTypeStatus,
  setCreateGroupStatus,
  setUpdateGroupStatus,
  setTagStatus,
  setSelectedTag,
  setDeleteTagStatus,
  setSelectedType,
  setSelectedGroup,
  setTag,
  setDeleteTypeStatus,
  setDeleteGroupStatus,
} = tagsSlice.actions;


export default tagsSlice.reducer;