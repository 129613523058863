import { axiosService } from 'services/axiosServices';

const formDataHeaders = {
  'cache-control': 'no-cache, private',
  "Content-type": 'multipart/form-data',
  Accept: "application/json",
};
const contentTypeHeaders = {
  'cache-control': 'no-cache, private',
  "Content-type": 'application/json; charset=UTF-8',
  Accept: "application/json",
};

export const user = {
  getEmailTemplatesByUserId: async (id) => {
    const res = await axiosService.get(`/api/filter/personal-email-templates?user_id=${id}`);
    return res.data.data;
  },
  sendEmail: async (url, body) => {
    const res = await axiosService.post(url, body, { headers: formDataHeaders });
    return res.data;
  },
  getCurrentUserData: async () => {
    const res = await axiosService.get(`/api/check`);
    return res.data;
  },
  getUsers: async () => {
    const res = await axiosService.get(`/api/users`);
    return res.data.data;
  },
  editUser: async (id, body) => {
    const res = await axiosService.patch(`/api/users/${id}`, body, { headers: contentTypeHeaders });
    return res.data;
  },
}
