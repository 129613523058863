import React, { useEffect, useRef, useState } from 'react';

import ReactQuill from 'react-quill';
import classNames from 'classnames';
import { Checkbox, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import 'css/email-template.scss';
import 'react-quill/dist/quill.snow.css';

import ProgressSpinner from 'components/progress/progress-spinner';
import fileIcon from 'assets/uploaded_file.svg';
import API from 'API';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { currentContractTemplateSelector, loadingTemplatesSelector } from 'store/contracts/selectors';
import { editContractTemplate } from 'store/contracts/thunk';

const modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'color': [] }, { 'background': [] }],
    ['link'],
    [{ 'align': [] }],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
  history: {
    delay: 1000,
    maxStack: 50,
    userOnly: true,
  },
};

export default function EditContractEmailTemplate({ handleTemplateUpdate }) {
  const myElementRef = useRef(null);
  const blockRef = useRef(null);
  const [distanceToTop, setDistanceToTop] = useState(0);
  
  const dispatch = useAppDispatch();
  const currentTemplate = useAppSelector(currentContractTemplateSelector);
  const loading = useAppSelector(loadingTemplatesSelector);
  
  const [templateName, setTemplateName] = useState(currentTemplate?.template_name);
  const [templateActive, setTemplateActive] = useState(currentTemplate?.active);
  const [templateEmailActive, setTemplateEmailActive] = useState(currentTemplate?.email_active);
  const [templateFileActive, setTemplateFileActive] = useState(currentTemplate?.file_active);
  const [templateFilePublic, setTemplateFilePublic] = useState(currentTemplate?.is_file_public);
  const [emailHeader, setEmailHeader] = useState(currentTemplate?.email_header);
  const [emailBody, setEmailBody] = useState(currentTemplate?.email_body);
  const [emailSender, setEmailSender] = useState(currentTemplate?.email_sender ? currentTemplate.email_sender : 'rdmstaffing@responsivedeployment.com');
  const [currentFiles, setCurrentFiles] = useState(currentTemplate?.files);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [file1, setFile1] = useState(null);
  const [file1Name, setFile1Name] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file2Name, setFile2Name] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file3Name, setFile3Name] = useState(null);
  const [file4, setFile4] = useState(null);
  const [file4Name, setFile4Name] = useState(null);
  const [file5, setFile5] = useState(null);
  const [file5Name, setFile5Name] = useState(null);
  const [error, setError] = useState(null);
  
  const handleScroll = () => {
    const element = myElementRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const distance = rect.top;
      
      setDistanceToTop(distance);
    }
  };
  
  useEffect(() => {
    handleScroll();
    
    const blockElement = blockRef.current;
    if (blockElement) {
      blockElement.addEventListener('scroll', handleScroll);
    }
    
    return () => {
      if (blockElement) {
        blockElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  
  useEffect(() => {
    if (currentTemplate) {
      setTemplateName(currentTemplate?.template_name);
      setTemplateActive(currentTemplate?.active);
      setTemplateEmailActive(currentTemplate?.email_active);
      setTemplateFileActive(currentTemplate?.file_active);
      setTemplateFilePublic(currentTemplate?.is_file_public);
      setEmailHeader(currentTemplate?.email_header);
      setEmailBody(currentTemplate?.email_body);
      setEmailSender(currentTemplate?.email_sender ? currentTemplate.email_sender : 'rdmstaffing@responsivedeployment.com');
      setCurrentFiles(currentTemplate?.files);
    }
  }, [currentTemplate]);
  
  const retrievePdf = (file) => {
    window.open(
      `${API.endpointURL}/api/cloud_location?location=${file.file_name}`,
      '_blank',
      'noopener,noreferrer',
    );
  };
  
  const handleDeleteFile = (file, index) => {
    let currentFilesCopy = [...currentFiles];
    currentFilesCopy.splice(index, 1);
    setCurrentFiles(currentFilesCopy);
    
    let filesToDeleteCopy = [...filesToDelete];
    filesToDeleteCopy.push(file);
    setFilesToDelete(filesToDeleteCopy);
  };
  
  const handleUndoDeleteFile = (file, index) => {
    let filesToDeleteCopy = [...filesToDelete];
    filesToDeleteCopy.splice(index, 1);
    setFilesToDelete(filesToDeleteCopy);
    
    let currentFilesCopy = [...currentFiles];
    currentFilesCopy.push(file);
    setCurrentFiles(currentFilesCopy);
  };
  const validateForm = () => {
    let validForm = true;
    if (currentFiles.length < 5 && file1Name && !file1) validForm = false;
    if (currentFiles.length < 5 && !file1Name && file1) validForm = false;
    if (currentFiles.length < 4 && file2Name && !file2) validForm = false;
    if (currentFiles.length < 4 && !file2Name && file2) validForm = false;
    if (currentFiles.length < 3 && file3Name && !file3) validForm = false;
    if (currentFiles.length < 3 && !file3Name && file3) validForm = false;
    if (currentFiles.length < 2 && file4Name && !file4) validForm = false;
    if (currentFiles.length < 2 && !file4Name && file4) validForm = false;
    if (currentFiles.length < 1 && file5Name && !file5) validForm = false;
    if (currentFiles.length < 1 && !file5Name && file5) validForm = false;
    
    return validForm;
  };
  
  const deactivateTemplate = () => {
    setTemplateActive(false);
    setTemplateEmailActive(false);
    setTemplateFileActive(false);
  };


  const file1input = useRef();
  const file2input = useRef();
  const file3input = useRef();
  const file4input = useRef();
  const file5input = useRef();
  const handleSubmit = (id) => {
    const validForm = validateForm();
    if (!emailHeader) {
      return setError('Please fill in the Email Subject!');
    }
    if (!templateName) {
      return setError('Please fill in the Email Template Name!');
    }
    if (validForm) {
      setError('');
      const formData = new FormData();
      formData.append('template_name', templateName);
      formData.append('email_header', emailHeader);
      formData.append('email_sender', emailSender);
      formData.append('email_body', emailBody ? emailBody : '');
      formData.append('active', templateActive);
      formData.append('email_active', templateEmailActive);
      formData.append('file_active', templateFileActive);
      formData.append('is_file_public', templateFilePublic);
      if (filesToDelete.length > 0) {
        for (var i = 0; i < filesToDelete.length; i++) {
          formData.append('files_to_delete[]', filesToDelete[i].id);
        }
      }
      if (file1Name && file1 && currentFiles.length < 5) {
        formData.append('file_1', file1);
        formData.append('file_1_name', file1Name);
      }
      if (file2Name && file2 && currentFiles.length < 4) {
        formData.append('file_2', file2);
        formData.append('file_2_name', file2Name);
      }
      if (file3Name && file3 && currentFiles.length < 3) {
        formData.append('file_3', file3);
        formData.append('file_3_name', file3Name);
      }
      if (file4Name && file4 && currentFiles.length < 2) {
        formData.append('file_4', file4);
        formData.append('file_4_name', file4Name);
      }
      if (file5Name && file5 && currentFiles.length < 1) {
        formData.append('file_5', file5);
        formData.append('file_5_name', file5Name);
      }

      dispatch(editContractTemplate({ templateId: id, body: formData }))
        .then(() => {
          handleTemplateUpdate();
          setFile1Name('');
          setFile1(null);
          setFile2Name('');
          setFile2(null);
          setFile3Name('');
          setFile3(null);
          setFile4Name('');
          setFile4(null);
          setFile5Name('');
          setFile5(null);
          file1input.current.value = null;
          file2input.current.value = null;
          file3input.current.value = null;
          file4input.current.value = null;
          file5input.current.value = null;
        });
    } else {
      setError('Please provide a file name and file for each attachment.');
    }
  };
  
  const buildCurrentFilesList = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {currentFiles.map((file, index) => (
        <div
          key={index}
          style={{
            marginBottom: '16px',
            marginRight: '16px',
            textAlign: 'center',
            width: '100px',
          }}
        >
          <IconButton
            onClick={() => handleDeleteFile(file, index)}
            sx={{
              color: '#EFEFEF',
              float: 'right',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#a20601',
              },
            }}
          >
            <CloseIcon/>
          </IconButton>
          <IconButton
            onClick={() => retrievePdf(file)}
            sx={{
              flexDirection: 'column',
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            <img
              src={fileIcon}
              width="57px"
              height="auto"
              alt="open-file-button"
            />
            <Typography
              sx={{
                color: '#EFEFEF',
                fontFamily: 'Korolev Medium',
                fontSize: '0.85rem',
                margin: '8px auto 0',
                width: '100%',
              }}
            >
              {file.display_name}
            </Typography>
          </IconButton>
        </div>
      ))}
    </div>
  );
  
  const buildDeleteFilesList = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {filesToDelete.map((file, index) => (
          <div
            key={index}
            style={{
              marginBottom: '16px',
              marginRight: '16px',
              textAlign: 'center',
              width: '100px',
            }}
          >
            <IconButton
              onClick={() => handleUndoDeleteFile(file, index)}
              sx={{
                color: '#EFEFEF',
                float: 'right',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: '#60992d',
                },
              }}
            >
              <AddIcon/>
            </IconButton>
            <IconButton
              onClick={() => retrievePdf(file)}
              sx={{ '&:hover': { backgroundColor: 'transparent' } }}
            >
              <img
                src={fileIcon}
                width="57px"
                height="auto"
                alt="open-file-button"
              />
            </IconButton>
            <Typography
              key={index}
              sx={{
                color: '#EFEFEF',
                fontFamily: 'Korolev Medium',
                fontSize: '0.85rem',
                margin: '0 auto',
              }}
            >
              {file.display_name}
            </Typography>
          </div>
        ))}
      </div>
    );
  };
  
  const FileInput = (index, fileName, setFileName) => {
    return (
      <div className="half-width" style={{ marginRight: '15px' }}>
        <TextField
          fullWidth
          sx={{
            backgroundColor: '#efefef40',
            borderRadius: '4px',
            color: '#EFEFEF',
            fontFamily: 'Korolev Medium',
            input: {
              padding: '10px',
              color: '#EFEFEF',
              fontFamily: 'Korolev Medium',
              '&:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 1000px #3e453d inset',
                WebkitTextFillColor: '#EFEFEF',
              },
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#62AB37',
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: 'gray',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#62AB37',
              },
            },
          }}
          className="contractsTags__tags__modal-input"
          placeholder={`Enter File ${index} Name...`}
          variant="outlined"
          required
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
        />
      </div>
    );
  };
  
  const TextInput = (placeholder, textName, setTextName) => {
    return (
      <TextField
        fullWidth
        sx={{
          backgroundColor: '#efefef40',
          borderRadius: '4px',
          color: '#EFEFEF',
          fontFamily: 'Korolev Medium',
          input: {
            padding: '10px',
            color: '#EFEFEF',
            fontFamily: 'Korolev Medium',
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px #3e453d inset',
              WebkitTextFillColor: '#EFEFEF',
            },
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#62AB37',
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#62AB37',
            },
          },
        }}
        className="contractsTags__tags__modal-input"
        placeholder={`Enter ${placeholder}...`}
        variant="outlined"
        required
        value={textName || ''}
        onChange={(e) => setTextName(e.target.value)}
      />
    );
  };
  const buildAddFilesInputs = () => {
    return (
      <>
        {currentFiles.length < 5 && (
          <div className="file-row">
            {FileInput(1, file1Name, setFile1Name)}
            <div className="file-selection">
              <input
                ref={file1input}
                type="file"
                name="file"
                onChange={(event) => setFile1(event.target.files[0])}
              />
            </div>
          </div>
        )}
        {currentFiles.length < 4 && (
          <div className="file-row">
            {FileInput(2, file2Name, setFile2Name)}
            <div className="file-selection">
              <input
                ref={file2input}
                type="file"
                name="file"
                onChange={(event) => setFile2(event.target.files[0])}
              />
            </div>
          </div>
        )}
        {currentFiles.length < 3 && (
          <div className="file-row">
            {FileInput(3, file3Name, setFile3Name)}
            <div className="file-selection">
              <input
                ref={file3input}
                type="file"
                name="file"
                onChange={(event) => setFile3(event.target.files[0])}
              />
            </div>
          </div>
        )}
        {currentFiles.length < 2 && (
          <div className="file-row">
            {FileInput(4, file4Name, setFile4Name)}
            <div className="file-selection">
              <input
                ref={file4input}
                type="file"
                name="file"
                onChange={(event) => setFile4(event.target.files[0])}
              />
            </div>
          </div>
        )}
        {currentFiles.length < 1 && (
          <div className="file-row">
            {FileInput(5, file5Name, setFile5Name)}
            <div className="file-selection">
              <input
                ref={file5input}
                type="file"
                name="file"
                onChange={(event) => setFile5(event.target.files[0])}
              />
            </div>
          </div>
        )}
      </>
    );
  };
  
  return (
    <div className="emailTemplates__edit" ref={blockRef}>
      <div className="emailTemplates__edit-info">
        <div className="emailTemplates__edit-header">
          <h2 className="emailTemplates__edit-infoName">Email Information</h2>
        </div>
      </div>
      <div className="emailTemplates__edit-button">
        {loading && (
          <div className="sale-form-spinner" style={{ right: '128px' }}>
            <ProgressSpinner/>
          </div>
        )}
        {error && (
          <div className="emailTemplates__edit-error">{error}</div>
        )}
        <button
          className="create-button"
          disabled={loading}
          onClick={() => handleSubmit(currentTemplate?.id)}
        >
          Update
        </button>
      </div>
      <div className="emailTemplates__edit-container">
        {(currentTemplate?.template_type === 'has_resume' || currentTemplate?.template_type === 'no_resume') && (
          <div className="emailTemplates__edit-infoName emailTemplates__edit-infoName--small">
            * This template will send a password reset to the user
          </div>
        )}
        <div className="emailTemplates__form">
          <div className="emailTemplates__form-top">
            <div className="emailTemplates__form-top--left">
              <div className="emailTemplates__form-checkBox">
                <Checkbox
                  checked={templateActive}
                  sx={{
                    color: '#EFEFEF',
                    padding: 0,
                    paddingRight: '10px',
                    '&.Mui-checked': {
                      color: '#62AB37',
                    },
                    '&.Mui-disabled': {
                      color: '#EFEFEF40',
                    },
                  }}
                  onClick={() => {
                    if (templateActive) {
                      deactivateTemplate();
                    } else {
                      setTemplateActive(true);
                    }
                  }}
                />
                <div className="active-label">Active</div>
              </div>
              <div className="emailTemplates__form-checkBox">
                <Checkbox
                  checked={templateEmailActive}
                  sx={{
                    color: '#EFEFEF',
                    padding: 0,
                    paddingRight: '10px',
                    '&.Mui-checked': {
                      color: '#62AB37',
                    },
                    '&.Mui-disabled': {
                      color: '#EFEFEF40',
                    },
                  }}
                  onClick={() => {
                    if (templateEmailActive) {
                      setTemplateEmailActive(false);
                    } else {
                      setTemplateEmailActive(true);
                    }
                  }}
                />
                <div className="active-label">Email Active</div>
              </div>
              <div className="emailTemplates__form-checkBox">
                <Checkbox
                  checked={templateFileActive}
                  sx={{
                    color: '#EFEFEF',
                    padding: 0,
                    paddingRight: '10px',
                    '&.Mui-checked': {
                      color: '#62AB37',
                    },
                    '&.Mui-disabled': {
                      color: '#EFEFEF40',
                    },
                  }}
                  onClick={() => {
                    if (templateFileActive) {
                      setTemplateFileActive(false);
                      setTemplateFilePublic(false);
                    } else {
                      setTemplateFileActive(true);
                    }
                  }}
                />
                <div className="active-label">File Active</div>
              </div>
              <div className="emailTemplates__form-checkBox">
                <Checkbox
                  checked={templateFilePublic || false}
                  disabled={!templateFileActive}
                  sx={{
                    color: '#EFEFEF',
                    padding: 0,
                    paddingRight: '10px',
                    '&.Mui-checked': {
                      color: '#62AB37',
                    },
                    '&.Mui-disabled': {
                      color: '#EFEFEF40',
                    },
                  }}
                  onClick={() => {
                    setTemplateFilePublic(!templateFilePublic);
                  }}
                />
                <div className="active-label">File Public</div>
              </div>
            </div>
            <div className="emailTemplates__form-top--right">
              <div className="emailTemplates__form-field">
                <div className="active-label">Email Subject</div>
                {TextInput('Email Subject', emailHeader, setEmailHeader)}
              </div>
              <div className="emailTemplates__form-field">
                <div className="active-label">Template Name</div>
                {TextInput('Template Name', templateName, setTemplateName)}
              </div>
              <div className="emailTemplates__form-field">
                <div className="active-label">Select email address</div>
                <Select
                  displayEmpty
                  value={emailSender || ''}
                  onChange={(e) => setEmailSender(e.target.value)}
                  sx={{
                    color: '#EFEFEF',
                    backgroundColor: '#efefef40',
                    border: 0,
                    borderRadius: '8px',
                    fontFamily: 'Korolev Medium',
                    textAlign: 'left',
                    width: '100%',
                    '& .MuiSelect-outlined.MuiOutlinedInput-input': {
                      padding: '10px',
                    },
                    '&.MuiOutlinedInput-root': {
                      padding: 0,
                      '&:hover fieldset': {
                        borderColor: 'gray',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#62AB37',
                      },
                    },
                  }}
                >
                  <MenuItem key="select-option" value="rdmstaffing@responsivedeployment.com">
                    rdmstaffing@responsivedeployment.com
                  </MenuItem>
                  <MenuItem key="select-default-option" value={'solutions@responsivedeployment.com'}>
                    solutions@responsivedeployment.com
                  </MenuItem>
                  <MenuItem key="recruiter" value="recruiter">
                    Recruiter
                  </MenuItem>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="contract-form-section-content">
          <div className="contract-rich-editor">
            <ReactQuill
              theme="snow"
              value={emailBody}
              onChange={setEmailBody}
              modules={modules}
              style={{ backgroundColor: '#EFEFEF40', color: '#EFEFEF', borderRadius: '10px', height: '325px' }}
            />
          </div>
        </div>
      </div>
      <div
        ref={myElementRef}
        className={classNames('emailTemplates__edit-info', {
          'emailTemplates__edit-info--transparent': distanceToTop > 217,
        })}
        style={{ zIndex: 6 }}
      >
        <div className="emailTemplates__edit-header">
          <h2 className="emailTemplates__edit-infoName">
            Email File Attachments (max 5)
          </h2>
        </div>
      </div>
      <div className="emailTemplates__edit-container">
        <div className="contract-form-section-content" style={{ marginTop: '20px' }}>
          <div className="contract-form-set-half" style={{ marginBottom: '0' }}>
            <div className="contract-form-label">{`Current Files (${currentFiles?.length})`}</div>
            {currentFiles?.length > 0 && buildCurrentFilesList()}
          </div>
          <div className="contract-form-set-half" style={{ marginBottom: '20px' }}>
            <div className="contract-form-label">{`Files to Delete (${filesToDelete?.length})`}</div>
            {filesToDelete?.length > 0 && buildDeleteFilesList()}
          </div>
          {currentFiles?.length < 5 && (
            <div style={{ width: '100%' }}>
              <div className="contract-form-label">Add Files</div>
              {buildAddFilesInputs()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
