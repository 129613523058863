import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarCollapse: true,
  openApplicantCards: ['general', 'address', 'placement', 'screening', 'emergency'],
  openTagGroupFilter: false,
  openTagFilter: false,
  openCardsOnSalePortal: ['form', 'remove', 'active'],
  openBusinessFilter: false,
  openConnectionsFilter: false,
  openSalesRepsFilter: false,
};

const uiSlice = createSlice({
  name: 'uiSlice',
  initialState,
  reducers: {
    setSidebarCollapse(state, { payload }) {
      state.sidebarCollapse = payload;
    },
    setOpenApplicantCards(state, { payload }) {
      state.openApplicantCards = payload;
    },
    toggleTagGroupFilter(state) {
      state.openTagGroupFilter = !state.openTagGroupFilter;
    },
    toggleTagFilter(state) {
      state.openTagFilter = !state.openTagFilter;
    },
    resetUiStore(state) {
      state.sidebarCollapse = true;
      state.openApplicantCards = ['general', 'address', 'placement', 'screening', 'emergency'];
      state.openTagGroupFilter = false;
      state.openTagFilter = false;
    },
    setOpenCardsOnSalePortal(state, { payload }) {
      state.openCardsOnSalePortal = payload;
    },
    setOpenBusinessFilter(state, { payload }) {
      state.openBusinessFilter = payload;
    },
    setOpenConnectionsFilter(state, { payload }) {
      state.openConnectionsFilter = payload;
    },
    setOpenSalesRepsFilter(state, { payload }) {
      state.openSalesRepsFilter = payload;
    },
  },
  extraReducers: () => {},
});

export const {
  setSidebarCollapse,
  setOpenApplicantCards ,
  toggleTagGroupFilter,
  toggleTagFilter,
  resetUiStore,
  setOpenCardsOnSalePortal,
  setOpenBusinessFilter,
  setOpenConnectionsFilter,
  setOpenSalesRepsFilter,
} = uiSlice.actions;

export default uiSlice.reducer;
