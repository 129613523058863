export const STAFFING_CONTACTS = [
  {
    name: 'RDM Staffing',
    email: 'RDMStaffing@responsivedeployment.com',
    phone: '945-234-3440',
  },
  {
    name: 'Justin Vickers',
    email: 'JVickers@responsivedeployment.com',
    phone: '945-234-3446',
  },
  {
    name: 'Kelly Moss',
    email: 'KMoss@responsivedeployment.com',
    phone: '945-234-3447',
  },
  {
    name: 'Chris Evans',
    email: 'CEvans@responsivedeployment.com',
    phone: '945-234-3466',
  },
  {
    name: 'Edith Cardenas',
    email: 'ECardenas@responsivedeployment.com',
    phone: '945-234-3467',
  },
  {
    name: 'Jennie Vega',
    email: 'JVega@responsivedeployment.com',
    phone: '945-234-3452',
  },
  {
    name: 'Connie Castillo',
    email: 'CCastillo@responsivedeployment.com',
    phone: '945-234-3439',
  },
  {
    name: 'Isabel Rodriguez',
    email: 'IRodriguez@responsivedeployment.com',
    phone: '945-234-3443',
  },
  {
    name: 'Jennifer Seabaugh',
    email: 'JSeabaugh@responsivedeployment.com',
    phone: '945-234-3445',
  },
];

export const RICK_USER_ID = 3;
export const BITMOTIVE_USER_ID = 198;
