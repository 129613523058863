import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import 'css/task.scss';
import 'css/projects.scss';

import Table from 'components/generic/table';
import TableItem from 'components/generic/table-item';
import ProjectsTaskModal from 'components/modal/projects-task-modal';
import ProjectsTabs from 'components/tabs/projects-tabs';
import Toast from 'components/toast/toast';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { HighlightNavyAutoStyledButton } from 'components/buttons/button-styles';
import NewBaseModal from 'components/modal/new-base-modal';
import CreateNewTask from 'components/project-portal/create-new-task';
import { formatUTCDate } from 'shared/formatting';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { projectSelector, projectTasksSelector } from 'store/projects/selectors';
import { setSelectedTask } from 'store/tasks/slice';
import { taskSelector } from 'store/tasks/selectors';
import { Pagination, Stack } from '@mui/material';
import { setCurrentTaskPage } from 'store/projects/slice';

export default function ProjectTasks({ resetTasks }) {
  const scrollRef = useRef(null);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { project, getProjectLoading, getProjectError } = useAppSelector(projectSelector);
  const {
    projectTasks,
    getProjectTasksLoading,
    getProjectTasksError,
    page,
    totalPages,
    currentTab,
  } = useAppSelector(projectTasksSelector);
  const { selectedTask } = useAppSelector(taskSelector);
  
  const [openToast, setOpenToast] = useState(false);
  const [error, setError] = useState(null);
  
  const [openTaskCreateModal, setOpenTaskCreateModal] = useState(false);
  const [openTaskEditModal, setOpenTaskEditModal] = useState(false);

  useEffect(() => {
    if (selectedTask) {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  }, [scrollRef.current, selectedTask]);
  
  const renderTable = () => {
    return [...projectTasks]
      .sort((a, b) => {
        if (!a.date_due && !b.date_due) {
          return 0;
        } else if (!a.date_due) {
          return 1;
        } else if (!b.date_due) {
          return -1;
        }
        return b.date_due.localeCompare(a.date_due);
      })
      .map((task) => (
        <TableItem
          key={task.id}
          classValue={selectedTask?.id === task?.id ? 'projects__tasks-task--selected' : ''}
        >
          <p style={{ paddingLeft: '20px' }}>{task.name ? task.name : 'N/A'}</p>
          <p>{task.date_assigned ? formatUTCDate(task.date_assigned) : 'N/A'}</p>
          <p>{task.date_due ? formatUTCDate(task.date_due) : 'N/A'}</p>
          <p>
            {
              task.personnel_assigned_data && (task.personnel_assigned_data.first_name || task.personnel_assigned_data.last_name)
                ? `${task.personnel_assigned_data.first_name || 'N/A'} ${task.personnel_assigned_data.last_name || 'N/A'}`
                : 'N/A'
            }

          </p>
          <div className="cell auto">
            <button
              ref={selectedTask?.id !== task?.id ? null : scrollRef}
              className="navy-button"
              onClick={() => {
                dispatch(setSelectedTask(task));
                setOpenTaskEditModal(true);
              }}
            >
              Manage
            </button>
          </div>
        </TableItem>
      ));
  };
  
  const renderTasksPanel = () => {
    if (
      (!getProjectLoading && !getProjectTasksLoading) &&
      (getProjectTasksError.length > 0 || getProjectError.length > 0)
    ) {
      return (
        <div className="content-loading">
          {getProjectError}
          {getProjectTasksError}
        </div>
      );
    } else if (
      (getProjectLoading || getProjectTasksLoading) &&
      (getProjectTasksError.length === 0 && getProjectError.length === 0)
    ) {
      return (
        <div className="content-loading">
          <ProgressSpinnerNavy/>
        </div>
      );
    } else if (
      (!getProjectLoading && !getProjectTasksLoading) &&
      (getProjectTasksError.length === 0 && getProjectError.length === 0) &&
      (project && projectTasks.length > 0)
    ) {
      return (
        <Table
          pathname={pathname}
          columns={[
            'Task Name',
            'Date Assigned',
            'Date Due',
            'Personnel Assigned',
            '',
          ]}
        >
          {projectTasks.length > 0 && renderTable()}
        </Table>
      );
    } else {
      return '';
    }
  };
  
  return (
    <>
      <NewBaseModal
        dialogContent={
        <CreateNewTask
          refreshTasks={resetTasks}
          onClose={() => setOpenTaskCreateModal(false)}
        />
      }
        onClose={() => setOpenTaskCreateModal(false)}
        open={openTaskCreateModal}
        title={'Create New Task'}
        colorPalette="purple"
        full_width
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ProjectsTabs
          currentTab={currentTab}
          tabs={['assigned', 'current', 'completed', 'archived']}
        />
        <div className="sales-page__connection-buttons">
          <div style={{ width: '155px' }}>
            <HighlightNavyAutoStyledButton
              onClick={() => setOpenTaskCreateModal(true)}
            >
              Create New Task
            </HighlightNavyAutoStyledButton>
          </div>
        </div>
      </div>
      
      <div className="content-container content-container-tasks no-content-padding">
        {renderTasksPanel()}
      </div>
      <Stack
        sx={{
          alignItems: 'center',
          marginTop: 1.5,
          marginBottom: '-16px',
        }}
      >
        <Pagination
          count={+totalPages}
          page={+page}
          onChange={(e, value) => dispatch(setCurrentTaskPage(value))}
          color="primary"
          size="large"
        />
      </Stack>
      {openTaskEditModal && (
        <ProjectsTaskModal
          handleClose={() => {
            setOpenTaskEditModal(false);
          }}
          open={openTaskEditModal}
          setServerError={setError}
          getTasks={resetTasks}
        />
      )}
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={error
            ? 'Something went wrong, try again later...'
            : 'Successfully Updated'
          }
        />
      )}
    </>
  );
}
