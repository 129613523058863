import React, { useEffect, useState } from 'react';
import API from 'API';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';
import classNames from 'classnames';

export const ExecutiveProjects = () => {
  const [projectsAnalytics, setProjectsAnalytics] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem('token'));

  useEffect(() => {
    getSaleAnalytics();
  }, []);

  const getSaleAnalytics = async () => {
    setLoading(true);
    await fetch(`${API.endpointURL}/api/metrics/projects/per-status`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setProjectsAnalytics(res);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <div style={{ position: 'relative' }}>
      <h2 className="content-header">Projects</h2>
      {loading && (
        <div className="spinner-analytics" style={{ height: '100%' }}>
          <ProgressSpinnerBlue/>
        </div>
      )}
      <div
        className={classNames('metrics-content-container', {
          'metrics-content-container-transparent': loading,
        })}
        style={{ height: 'fit-content' }}
      >
        <div className="stats-group-container">
          <div className="contract-totals-row">
            <div className="stage-stat-container">
              <div className="stage-stat-header">Pending</div>
              <div className="stage-stat-number">{projectsAnalytics ? projectsAnalytics.pending_count : 'N/A'}</div>
              <div className="stage-stat-unit">
                {projectsAnalytics && projectsAnalytics.pending_count === 1 ? 'project' : 'projects'}
              </div>
            </div>
            <div className="stage-stat-container">
              <div className="stage-stat-header">Current</div>
              <div className="stage-stat-number">{projectsAnalytics ? projectsAnalytics.current_count : 'N/A'}</div>
              <div className="stage-stat-unit">
                {projectsAnalytics && projectsAnalytics.current_count === 1 ? 'project' : 'projects'}
              </div>
            </div>
            <div className="stage-stat-container">
              <div className="stage-stat-header">Completed</div>
              <div className="stage-stat-number">{projectsAnalytics ? projectsAnalytics.completed_count : 'N/A'}</div>
              <div className="stage-stat-unit">
                {projectsAnalytics && projectsAnalytics.completed_count === 1 ? 'project' : 'projects'}
              </div>
            </div>
            <div className="stage-stat-container">
              <div className="stage-stat-header">Archived</div>
              <div className="stage-stat-number">{projectsAnalytics ? projectsAnalytics.archived_count : 'N/A'}</div>
              <div className="stage-stat-unit">
                {projectsAnalytics && projectsAnalytics.archived_count === 1 ? 'project' : 'projects'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
