import { createAsyncThunk } from '@reduxjs/toolkit';
import { quotes } from 'services/quotes';
export const getQuoteCategories = createAsyncThunk(
  'getQuoteCategories',
  async ({ saleId }, { rejectWithValue }) => {
    try {
      return await quotes.getQuoteCategories(saleId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createQuoteCategory = createAsyncThunk(
  'createQuoteCategory',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await quotes.createQuoteCategory(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateQuoteCategory = createAsyncThunk(
  'updateQuoteCategory',
  async ({ categoryId, body }, { rejectWithValue }) => {
    try {
      return await quotes.updateQuoteCategory(categoryId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteQuoteCategory = createAsyncThunk(
  'deleteQuoteCategory',
  async ({ categoryId }, { rejectWithValue }) => {
    try {
      return await quotes.deleteQuoteCategory(categoryId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
