import React, { useState } from 'react';
import { Divider, Typography } from '@mui/material';
import { HighlightGreenAutoStyledButton } from '../buttons/button-styles';
import Filter from 'components/filter-items/filter';
import TagFilter from 'components/filter-items/tag-filter';
import AddUserModal from '../../components/modal/add-user-modal';
import ProgressSpinner from 'components/progress/progress-spinner';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { recruitersSelector } from 'store/recruiters/selectors';
import { recruiterFilterIdSelector } from 'store/staffingSelectedOptions/selectors';
import { setRecruiterFilterId } from 'store/staffingSelectedOptions/slice';
import { applicantsLoadingSelector, totalApplicantsByStageSelector } from 'store/applicants/selectors';

export default function StaffingSidebar({ getApplicantsByStage }) {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(applicantsLoadingSelector);
  const allRecruiters = useAppSelector(recruitersSelector);
  const recruiterFilterId = useAppSelector(recruiterFilterIdSelector);
  const totalApplicants = useAppSelector(totalApplicantsByStageSelector);
  
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  
  const buildRecruiterFilter = () => {
    let sortedRecruiters = [];
    if (allRecruiters?.length > 0) {
      let myData = [...allRecruiters];
      myData.sort(function (a, b) {
        if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) return -1;
        if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) return 1;
        return 0;
      });
      sortedRecruiters = myData;
    }
    
    let options = [];
    options.push(
      <option key={'all'} value={''}>
        All Recruiters
      </option>,
    );

    // Adding null filter (for applicants who are not assigned to any recruiter)
    options.push(
      <option key={'none'} value={'null'}>
        Not Assigned
      </option>
    );

    sortedRecruiters.forEach((recruiter) => {

      // We only display recruiters which have 
      // 'active' column set to true, meaning their
      // user is active
      const isRecruiterActive = recruiter.active === 'true' || recruiter.active === true;
      if (isRecruiterActive) {
        options.push(
        <option key={recruiter.id} value={recruiter.id}>
          {recruiter.first_name} {recruiter.last_name}
        </option>,
        );
      }
    });
    
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
        <select
          className="contract-form-input"
          style={{ marginTop: '16px', width: '90%' }}
          value={recruiterFilterId || ''}
          onChange={(e) =>
            dispatch(setRecruiterFilterId(e.target.value))
          }
        >
          {options}
        </select>
      </div>

    );
  };
  
  return (
    <div
      style={{
        backgroundColor: 'transparent',
        color: '#EFEFEF',
        borderRight: '1px solid #EFEFEF80',
        height: 'calc(100vh - 80px)',
        padding: '0 25px',
        overflow: 'auto',
        textAlign: 'center',
        width: '100%',
      }}
    >
      {buildRecruiterFilter()}
      <div>
        <Typography
          sx={{
            color: '#62AB37',
            fontFamily: 'MagistralCondW08-Medium',
            fontSize: '40px',
            marginTop: 2,
            position: 'relative',
            minHeight: '60px'
          }}
        >
          {loading
            ? (
              <span style={{ position: 'absolute', top: -30, left: '43%' }}>
                <ProgressSpinner/>
              </span>
            ) :
            totalApplicants ? totalApplicants.toLocaleString() : '0'}
        </Typography>
        <Typography
          sx={{
            color: '#EFEFEF',
            fontFamily: 'Korolev Medium',
            fontSize: '16px',
          }}
        >
          {!totalApplicants || (totalApplicants && totalApplicants !== 1)
            ? 'Applicants'
            : 'Applicant'}
        </Typography>
      </div>
      <Divider sx={{ borderColor: '#EFEFEF80', marginTop: '20px' }}/>
      <div className="filter-header">Option Panel</div>
      
      <Filter getApplicantsByStage={getApplicantsByStage} />
      <TagFilter />
      
      <Divider sx={{ borderColor: '#EFEFEF80', marginTop: '30px' }}/>
      <HighlightGreenAutoStyledButton
        variant="contained"
        onClick={() => setOpenAddUserModal(true)}
        sx={{
          margin: '30px 0',
        }}
      >
        Add applicant
      </HighlightGreenAutoStyledButton>
      {openAddUserModal && (
        <AddUserModal
          open={openAddUserModal}
          handleClose={() => setOpenAddUserModal(false)}
          updateUsersList={getApplicantsByStage}
          uploadType={'applicant'}
        />
      )}
    </div>
  );
}
