import React, { useEffect, useState } from 'react';
import NewBaseModal from './new-base-modal';
import API from 'API';

import 'css/task.scss';
import { MenuItem, Select, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import DatePicker from 'react-datepicker';
import Toast from 'components/toast/toast';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { saleSelector, salesRepsSelector } from 'store/sales/selectors';
import { getSalesMilestonesTasks, getSalesTasks } from 'store/sales/thunk';
import { useParams } from 'react-router-dom';

const tabs = [
  'description',
  'update',
];

const status = [
  'assigned',
  'in progress',
  'requested approval',
  'returned',
  'blocked',
  'completed',
  'archived',
];

const initialTask = {
  status: '',
  description: '',
  date_due: '',
  personnel_assigned_id: '',
  file_active: false,
  milestone_active: false,
};

const initialErrors = {
  description: '',
  date_due: '',
  personnel_assigned_id: '',
  validation: '',
};

const SalesTaskModal = ({
  handleClose,
  open,
  task,
  setServerError,
}) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const { id } = useParams();

  const [taskForm, setTaskForm] = useState(initialTask);
  const [activeTab, setActiveTab] = useState('description');
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const salesReps = useAppSelector(salesRepsSelector);
  const { tasksTab, tasksPage } = useAppSelector(saleSelector);
  const dispatch = useAppDispatch();

  const handleChange = (event) => {
    setTaskForm({
      ...taskForm,
      [event.target.name]: event.target.value,
    });
    if (!event.target.value) {
      setErrors({
        ...errors,
        [event.target.name]: true,
      });
    } else {
      setErrors({
        ...errors,
        [event.target.name]: false,
        validation: false,
      });
    }
  };

  useEffect(() => {
    setTaskForm({
      status: task.status || '',
      description: task.description || '',
      date_due: task.date_due ? parseDateString(task.date_due) : '',
      personnel_assigned_id: task.personnel_assigned_id || '',
      file_active: task.file_active || false,
      milestone_active: task.milestone_active || false,
    });
  }, []);

  const closeDocumentModal = () => {
    handleClose();
  };

  const handleUpdate = () => {

    /** Validation */
    let isError = false;
    let newErrors = {
      ...initialErrors,
    };

    if (!taskForm.description || taskForm.description.length > 255) {
      isError = true;
      newErrors.description = taskForm.description.length > 255
        ? 'Max length is 255 characters.'
        : 'Please, enter description.';
    }

    if (!taskForm.date_due) {
      isError = true;
      newErrors.date_due = 'Please, set due date.';
    }

    if (!taskForm.personnel_assigned_id) {
      isError = true;
      newErrors.personnel_assigned_id = 'Please, select personnel assigned.';
    }

    setErrors(newErrors);

    if (isError) {
      console.log('User validation error. Cannot perform request.');
    }

    /* API REQUEST */
    else {
      try {
        let requestError = false;
        fetch(`${API.endpointURL}/api/tasks/${task.id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            personnel_assigned_id: taskForm.personnel_assigned_id,
            description: taskForm.description,
            milestone_active: taskForm.milestone_active,
            file_active: taskForm.file_active,
            status: taskForm.status,
            date_due: taskForm.date_due,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
        })
          .then((res) => {
            if (!res.ok) {
              requestError = true;
            }
            return res.json();
          })
          .then(() => {
            setOpenToast(true);
            if (requestError) {
              setServerError(true);
            } else {
              if (tasksTab === 'current') {
                dispatch(getSalesTasks({
                  id: id,
                  status: [
                    'in progress',
                    'requested approval',
                    'returned',
                    'blocked',
                  ],
                  page: tasksPage,
                }));
              } else {
                dispatch(getSalesTasks({
                  id: id,
                  status: [tasksTab],
                  page: tasksPage,
                }));
              }
              dispatch(getSalesMilestonesTasks({ id: id }));
              handleClose();
              setServerError(false);
            }
          });
      } catch (e) {
        setServerError(true);
        setOpenToast(true);
      }
    }
  };

  /**
   * Parse date into the format that can
   * be displayed in js
   */
  function parseDateString(dateString) {

    if (dateString === null) {
      return '';
    }
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  }

  const buildTable = () => {
    return (
      <div className="task-modal">
        <div className="task-modal__tabs">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={
                activeTab === tab
                  ? 'task-modal__tab task-modal__tab--active'
                  : 'task-modal__tab'
              }
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="task-modal__content">
          {activeTab === 'description' && (
            <>
              <div className="form-row-label" style={{ textAlign: 'left' }}>
                Description
              </div>
              <TextField
                value={taskForm.description}
                name="description"
                onChange={handleChange}
                fullWidth
                disabled={task.type === 'milestone'}
                sx={{
                  backgroundColor: '#fff',
                  border: '1px solid #a5af96',
                  borderRadius: '8px',
                  padding: '6px 10px 5px',
                  marginBottom: 1,
                  '& textarea': {
                    borderRadius: 0,
                  },
                }}
                placeholder="Message..."
                multiline={true}
                rows={8}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
              {!taskForm.description && (
                <p
                  className="sale-form-error"
                  style={{ top: '177px' }}
                >
                  * Please enter Description...
                </p>
              )}
              {taskForm.description.length > 255 && (
                <p
                  className="sale-form-error"
                  style={{ top: '177px' }}
                >
                  * Max length is 255 characters...
                </p>
              )}
            </>
          )}
          {activeTab === 'update' && (
            <div className="task-modal__container">
              <div className="task-modal__info">
                <div className="task-modal__info-field">
                  <div className="form-row-label" style={{ textAlign: 'left' }}>
                    Personnel assigned *
                  </div>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={taskForm.personnel_assigned_id || ''}
                    name="personnel_assigned_id"
                    onChange={handleChange}
                    placeholder="Select sales rep..."
                    sx={{
                      backgroundColor: '#fff',
                      border: 0,
                      borderRadius: '8px',
                      fontFamily: 'Korolev Medium',
                      marginBottom: '8px',
                      textAlign: 'left',
                      width: '100%',

                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                      },

                      '& .MuiOutlinedInput-input.MuiInputBase-input': {
                        padding: '7px 12px',
                      },
                    }}
                  >
                    {salesReps?.map((rep) => (
                      <MenuItem value={rep.id} key={rep.id}>
                        {`${rep.first_name} ${rep.last_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="task-modal__info-field">
                  <div className="form-row-label" style={{ textAlign: 'left' }}>
                    Due Date *
                  </div>
                  <DatePicker
                    className="contract-form-input"
                    selected={
                      taskForm.date_due
                        ? (typeof taskForm.date_due === 'string' ? new Date(taskForm.date_due) : taskForm.date_due)
                        : null
                    }
                    placeholderText={taskForm.date_due ? taskForm.date_due : 'N/A'}
                    onChange={(date) => {
                      if (date) {
                        const dd = String(date?.getDate()).padStart(2, '0');
                        const mm = String(date?.getMonth() + 1).padStart(2, '0');
                        const yyyy = date?.getFullYear();
                        const dateString = yyyy + '-' + mm + '-' + dd;
                        setTaskForm({
                          ...taskForm,
                          date_due: dateString,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="form-row-label" style={{ textAlign: 'left' }}>
                Status
              </div>
              <div className="task-modal__status">
                {status.map((item) => (
                  <button
                    key={item}
                    className="task-modal__radio"
                    onClick={() => setTaskForm({
                      ...taskForm,
                      status: item,
                    })}
                  >
                    <div
                      className="task-modal__radio-border">
                      {taskForm.status === item && (
                        <div className="task-modal__radio-border--active"/>
                      )}
                    </div>
                    <p className="task-modal__radio-name">
                      {item}
                    </p>
                  </button>
                ))}
              </div>

              <div className="task-modal__info">
                <div className="task-modal__toggle">
                  <p>Milestone active</p>
                  <Switch
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#141A66',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#141A66',
                      },
                      '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                        backgroundColor: '#c7c7c7',
                      },
                    }}
                    checked={taskForm.milestone_active}
                    onChange={() => setTaskForm({
                      ...taskForm,
                      milestone_active: !taskForm.milestone_active,
                    })}
                  />
                </div>
                <div className="task-modal__toggle">
                  <p>File Active</p>
                  <Switch
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#141A66',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#141A66',
                      },
                      '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                        backgroundColor: '#c7c7c7',
                      },
                    }}
                    checked={taskForm.file_active}
                    onChange={() => setTaskForm({
                      ...taskForm,
                      file_active: !taskForm.file_active,
                    })}
                  />
                </div>
              </div>

            </div>
          )}
        </div>

        {/* ERRORS */}
        {errors.date_due && (
          <p
            className="sale-form-error"
            style={{ left: '0', top: '470px' }}
          >
            Please, set due date.
          </p>
        )}

        {errors.personnel_assigned_id && (
          <p
            className="sale-form-error"
            style={{ left: '0', top: '455px' }}
          >
            Please, select personnel assigned.
          </p>
        )}

        {errors.description && (
          <p
            className="sale-form-error"
            style={{ left: '0', top: '440px' }}
          >
            Please, enter valid description. 255 is max length.
          </p>
        )}

        {/* Only display UPDATE button for 'Update' tab */}
        {activeTab === 'update' && (
          <button className="task-modal__submit" onClick={handleUpdate}>
            Update
          </button>
        )}

        {/* Display toast with needed toast message */}
        {openToast && (
          <Toast
            open={openToast}
            onClose={() => setOpenToast(false)}
            message={errors.date_due || errors.description || errors.personnel_assigned_id
              ? 'Something went wrong, try again later.'
              : 'Information was successfully updated'
            }
          />
        )}
      </div>
    );
  };

  return (
    <NewBaseModal
      dialogContent={buildTable()}
      onClose={closeDocumentModal}
      open={open}
      title={'MANAGE TASK'}
      colorPalette="navy"
    />
  );
};

export default SalesTaskModal;
