import "../../css/modal.scss";
import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import CustomDialogRedesign from "./custom-dialog-redesign";
import { useAppDispatch } from 'hooks/reduxHooks';
import { editContractById } from 'store/contracts/thunk';

export default function EditContractURL(props) {
  const { contract, getContracts, handleClose, open, setOpenToast } =
    props;
  
  const dispatch = useAppDispatch();
  const [contractURL, setContractURL] = useState(contract.contract_alias ? contract.contract_alias : "");
  const [error, setError] = useState(null);
  
  function startsWithNumber(inputString) {
    const pattern = /^[0-9]/;
    return pattern.test(inputString);
  }

  const editUser = async () => {
      if (!contractURL) {
        setError("Please provide a unique URL for your contract application.");
      } else if (contractURL.includes(' ')) {
        setError("URL can not contain spaces.");
      } else if (startsWithNumber(contractURL)) {
        setError("URL can not begin with a number.");
      }else if (contractURL.length > 50) {
        setError("URL can not exceed 50 characters.");
      } else {
        const body = {
          contract_alias: contractURL,
        };
        dispatch(editContractById({ contractId: contract.id, body }))
          .then((res) => {
            if (res.meta.requestStatus !== 'fulfilled') {
              if (res.payload.response.data.error_key === 'contract_alias already exists') {
                setError('This URL is already associated with another contract.')
              }
            } else {
              setError(null);
              handleClose();
              setOpenToast(true);
              if (getContracts) {
                getContracts();
              }
            }
          })
      }
  };
  
  const editForm = () => {
    return (
      <React.Fragment key="edit-user-detail-form">
        <div
          style={{
            backgroundColor: "#EFEFEFE6",
            borderRadius: "10px",
            fontFamily: "Korolev Medium",
            lineHeight: "25px",
            margin: "15px 0 25px",
            padding: "16px",
            textAlign: "left",
          }}
        >
          Updating an existing contract's application URL will require you to edit any references/postings to this application link.
        </div>
        <div style={{ textAlign: "left" }} className="form-row-label">
          Application URL
        </div>
        <TextField
          fullWidth
          id="edit-user-detail"
          key="edit-user-detail"
          placeholder={"Enter Application URL..."}
          variant="outlined"
          margin="dense"
          required
          value={contractURL}
          onChange={(e) => setContractURL(e.currentTarget.value)}
        />
        <div
          style={{
            color: "#62AB37",
            fontFamily: "Korolev Medium",
            fontSize: "18px",
            marginTop: "8px",
            textAlign: "center",
          }}
        >
          https://responsivemanagement.org/application/{contractURL}
        </div>
        <div
          style={{
            fontFamily: "Korolev Medium",
            marginTop: "25px",
            lineHeight: "20px",
            textAlign: "left",
          }}
        >
          Requirements:
          <br />
          - Must be unique from other contracts
          <br />
          - Cannot exceed 50 characters
          <br />
          - Cannot begin with a number
          <br />
          - Cannot contain spaces
          <br />
        </div>
        {error && <div className="add-user-error">{error}</div>}
        <div className="button-container">
          <Button
            variant="contained"
            className="add-user-button"
            sx={{
              backgroundColor: "#62AB37",
              border: "1px solid #62AB37",
              borderRadius: "10px",
              color: "#EFEFEF",
              fontFamily: "MagistralCondW08-Medium",
              fontSize: "18px",
              minHeight: "25px",
              padding: "13px 20px",
              textTransform: "capitalize",
              width: "175px",
              "&:hover": {
                backgroundColor: "transparent",
                border: "1px solid #62AB37",
                boxShadow: "none",
                color: "#62AB37",
              },
            }}
            onClick={() => editUser()}
          >
            Update
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <CustomDialogRedesign
      colorPalette="green"
      dialogContent={editForm()}
      onClose={handleClose}
      open={open}
      title={"UPDATE"}
      titleHighlight={"APPLICATION URL"}
    />
  );
}
