import React from "react";
import Checklist from "../checklist/checklist";

export default function ApplicantChecklist() {
  return (
    <>
      <h1 className="content-header">Applicant Checklist</h1>
      <div className="content-container">
        <Checklist />
      </div>
    </>
  );
}
