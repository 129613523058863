export default class API {
  // static endpointURL = "http://localhost";
  static endpointURL = "https://api.responsivemanagement.org";

  // Those are the IDs of specifc contracts on prod.
  static cbpContractId = 1;
  static tbContractId = 4;
  static generalContractId = 38;

  static createBlobFromResumeFileName(filename) {
    return new Promise((resolve, reject) => {
      fetch(`${this.endpointURL}/api/cloud_location?location=${filename}`)
        .then((response) => response.blob())
        .then((blob) => {
          resolve(window.URL.createObjectURL(blob));
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }
  
  static createBlobFromSaleFile(filename) {
    return new Promise((resolve, reject) => {
      fetch(`${this.endpointURL}/api/sales_files/download?location=${filename}`)
        .then((response) => response.blob())
        .then((blob) => {
          resolve(window.URL.createObjectURL(blob));
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  static getFile(id) {
    return new Promise((resolve, reject) => {
      const token = JSON.parse(localStorage.getItem("token"));

      fetch(`${this.endpointURL}/api/files/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        });
    });
  }

  static getSaleFile(saleFileId) {
    return new Promise((resolve, reject) => {

      const token = JSON.parse(localStorage.getItem("token"));
      fetch(`${this.endpointURL}/api/sales_files/${saleFileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        },
      })
      .then((response) => response.json())
      .then((json) => {
        resolve(json)
      });
    });
  }

  static getProjectFile(projectFileId) {
    return new Promise((resolve, reject) => {

      const token = JSON.parse(localStorage.getItem("token"));
      fetch(`${this.endpointURL}/api/projects_files/${projectFileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        },
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json)
        });
    });
  }

  static checkEnv() {
    if (this.endpointURL == false) {
      throw "ENV: Endpoint URL is not set.";
    }
  }
}
