import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import '../../css/modal.scss';

import { Box, Button, Checkbox } from '@mui/material';

import CustomDialog from './custom-dialog';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { editUser } from 'store/user/thunk';
import { userErrorSelector, userLoadingSelector } from 'store/user/selectors';

export default function EditUserTextOptIn(props) {
  const { applicant, getApplicant, handleClose, open, setOpenToast, view } = props;
  
  const dispatch = useAppDispatch();
  const loading = useAppSelector(userLoadingSelector);
  const error = useAppSelector(userErrorSelector);
  
  const [canReceiveMessages, setCanReceiveMessage] = useState(applicant.can_receive_messages);
  
  const editApplicant = async () => {
    if (view === 'applicant') {
      const body = { can_receive_messages: canReceiveMessages };
      dispatch(editUser({ id: applicant.id, body }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            getApplicant();
            handleClose();
            setOpenToast(true);
          }
        });
    }
  };
  
  if (view !== 'applicant') {
    handleClose();
    return;
  }
  
  const editInfoForm = () => {
    return (
      <React.Fragment>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.9em', marginTop: '0.6em' }}>
            <Checkbox
              disabled={false}
              checked={canReceiveMessages === true}
              onChange={(e) => setCanReceiveMessage(e.target.checked)}
              sx={{
                width: '20px',
                height: '20px',
                color: '#3e453d',
                padding: 0,
                '&.Mui-checked': {
                  color: '#60992d',
                },
                '&.Mui-disabled': {
                  color: '#EFEFEF40',
                },
              }}
            />
            <div style={{ lineHeight: '20px' }}>
              I agree to receive text messages from Responsive Management at the phone number provided above related to
              my job application.
              I understand message and data rates may apply. Message frequency varies. Reply STOP to unsubscribe.
              
              For more informatiom, please visit our{' '}
              <Link
                to="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#3e453d',
                  display: 'inline',
                  textDecoration: 'underline',
                }}
              >
                <b>Privacy Policy</b>
              </Link>{' '}
              and{' '}
              <Link
                to="/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#3e453d',
                  display: 'inline',
                  textDecoration: 'underline',
                }}
              >
                <b>Terms & Conditions</b>.
              </Link>
            </div>
          </div>
        </Box>
        {error.length > 0 && <div className="add-user-error">{error}</div>}
        <div className="button-container" style={{ overflow: 'hidden', paddingTop: '5px' }}>
          <Button
            variant="contained"
            className="add-user-button"
            disabled={loading}
            sx={{
              backgroundColor: '#556155',
              color: '#EFEFEF',
              fontSize: '18px',
              width: '175px',
              '&:hover': { backgroundColor: '#a5af96', color: '#3e453d' },
            }}
            onClick={() => editApplicant()}
          >
            Edit
            {loading && (
              <div style={{ position: 'absolute', left: -55 }}>
                <ProgressSpinnerBlue/>
              </div>
            )}
          </Button>
        </div>
      </React.Fragment>
    );
  };
  
  return (
    <CustomDialog
      dialogContent={editInfoForm()}
      onClose={handleClose}
      open={open}
      title={'EDIT'}
      titleHighlight={'TEXT Opt-In'}
    />
  );
}
