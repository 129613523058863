import React, { useState, useEffect } from 'react';
import 'css/applicant-profile.scss';
import NewBaseModal from 'components/modal/new-base-modal';

import { MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  createTagSelector,
  editTagSelector,
  selectedGroupSelector,
  selectedTagSelector,
  selectedTypeSelector,
  tagGroupsSelector,
  tagLoadingSelector,
  tagSelector,
  tagTypesSelector,
} from 'store/tags/selectors';
import {
  createTag,
  filterTags,
  getTag,
  getTagGroups,
  getTags,
  getTagTypes,
  updateTag
} from 'store/tags/thunk';
import ProgressSpinner from 'components/progress/progress-spinner';
import { setTag } from 'store/tags/slice';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

export const UpsertTagModal = ({ open, setOpen, isEditing }) => {
  const dispatch = useAppDispatch();
  const selectedTag = useAppSelector(selectedTagSelector);
  const tag = useAppSelector(tagSelector);
  const tagLoading = useAppSelector(tagLoadingSelector);
  const { tagCreateError, tagCreateLoading } = useAppSelector(createTagSelector);
  const { tagEditError, tagEditLoading } = useAppSelector(editTagSelector);
  const { pathname } = useLocation();
  const [editTag, setEditTag] = useState({
    name: '',
    description: '',
    type_id: null,
    group_id: null,
    applicants: [],
    vendors: [],
    projects: [],
  });
  
  const [newTag, setNewTag] = useState({
    name: '',
    description: '',
    type_id: null,
    group_id: null,
    applicants: [],
    vendors: [],
    projects: [],
  });
  const [errors, setErrors] = useState({
    name: false,
    description: false,
    group_id: false,
    type_id: false,
  });
  
  const typeList = useAppSelector(tagTypesSelector);
  const groupList = useAppSelector(tagGroupsSelector);
  const typeId = useAppSelector(selectedTypeSelector);
  const groupId = useAppSelector(selectedGroupSelector);
  
  useEffect(() => {
    dispatch(getTagTypes());
    dispatch(getTagGroups());
  }, []);
  
  useEffect(() => {
    if (selectedTag?.id && isEditing) {
      dispatch(getTag({ tagId: selectedTag?.id }))
    }
  }, [selectedTag?.id]);
  
  useEffect(() => {
    if (tag) {
      setEditTag({
        name: tag?.name,
        description: tag?.description,
        type_id: tag?.type?.id,
        group_id: tag?.group?.id,
        applicants: tag?.applicants?.map((applicant) => applicant.id),
        vendors: tag?.vendors,
        projects: tag?.projects,
      })
    }
  }, [tag]);
  
  const handleInputChange = (name, value) => {
    const updatedErrors = { ...errors };
    updatedErrors[name] = value === null || value === '' || (typeof value === 'number' && isNaN(value));
    
    if (isEditing) {
      setEditTag({
        ...editTag,
        [name]: value,
      });
    } else {
      setNewTag({
        ...newTag,
        [name]: value,
      });
    }
    setErrors(updatedErrors);
  };
  
  const checkForErrors = () => {
    const updatedErrors = { ...errors };
    let hasErrors = false;
    const tag = isEditing ? editTag : newTag;
    
    for (const key in updatedErrors) {
      if (tag[key]?.length === 0 || !tag[key]) {
        updatedErrors[key] = true;
        hasErrors = true;
      }
    }
    
    setErrors(updatedErrors);
    return hasErrors;
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    const hasErrors = checkForErrors();
    
    if (!hasErrors) {
      if (!isEditing) {
        dispatch(createTag({ body: newTag }))
          .then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
              if (groupId || typeId) {
                dispatch(filterTags({ groupId, typeId }));
              } else {
                dispatch(getTags());
              }
              handleClose();
            }
          })
      } else {
        dispatch(updateTag({ tagId: selectedTag?.id ,body: editTag }))
          .then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
              if (groupId || typeId) {
                dispatch(filterTags({ groupId, typeId }));
              } else {
                dispatch(getTags());
              }
              handleClose();
            }
          })
      }
    }
  };
  
  const handleClose = () => {
    setOpen(false);
    dispatch(setTag(null));
  };

  const setValue = (editValue, newValue) => {
    if (isEditing) {
      return editValue || '';
    }
    return newValue || '';
  }
  
  const buildModel = () => {
    if (tagLoading) {
      return (
        <div
          className="tag-modal-container"
          style={{
            height: '459px' ,
            width: '500px',
            display: 'flex',
            justifyContent: 'center',
        }}>
          <ProgressSpinner />
        </div>
      )
    }
    
    return (
      <div className="tag-modal-container">
        <div className="tag-modal">
          <div className="modal-content">
            
            <div style={{ width: '100%', position: 'relative' }}>
              <div style={{ textAlign: 'left' }} className="form-row-label">
                Name *
              </div>
              <TextField
                fullWidth
                id="Name"
                key="Name"
                sx={{
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#62AB37',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: errors.name ? '#a20601' : 'inherit',
                    },
                    '&:hover fieldset': {
                      borderColor: 'gray',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: errors.name ? '#a20601' : '#62AB37',
                    },
                  },
                }}
                className="contractsTags__tags__modal-input"
                placeholder="Enter Tag Name"
                variant="outlined"
                required
                value={setValue(editTag?.name, newTag?.name)}
                onChange={(event) => {
                  handleInputChange('name', event.target.value)
                }}
              />
              {errors.name && (
                <p className="contractsTags__tags__modal-error">Enter tag name</p>
              )}
            </div>
            
            <div style={{ width: '100%', position: 'relative' }}>
              <div style={{ textAlign: 'left' }} className="form-row-label">
                Description *
              </div>
              <TextField
                fullWidth
                id="edit-first-name"
                key="edit-first-name"
                placeholder="Enter Tag Description"
                variant="outlined"
                required
                value={setValue(editTag?.description, newTag?.description)}
                onChange={(event) => {
                  handleInputChange('description', event.target.value)
                }}
                sx={{
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#62AB37',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: errors.description ? '#a20601' : 'inherit',
                    },
                    '&:hover fieldset': {
                      borderColor: 'gray',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: errors.description ? '#a20601' : '#62AB37',
                    },
                  },
                }}
              />
              {errors.description && (
                <p className="contractsTags__tags__modal-error">Enter tag description</p>
              )}
            </div>
            
            <div style={{ width: '100%', position: 'relative' }}>
              <div style={{ textAlign: 'left' }} className="form-row-label">Type *</div>
              <Select
                id="type"
                key="type"
                displayEmpty
                onChange={(event) => {
                  handleInputChange('type_id', event.target.value)
                }}
                sx={{
                  backgroundColor: '#EFEFEF',
                  border: 0,
                  borderRadius: '8px',
                  fontFamily: 'Korolev Medium',
                  textAlign: 'left',
                  width: '100%',
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: errors.type_id ? '#a20601' : "inherit"
                    },
                    "&:hover fieldset": {
                      borderColor: "gray"
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: errors.type_id ? '#a20601' : '#62AB37'
                    }
                  }
                }}
                value={setValue(editTag?.type_id, newTag?.type_id)}

              >
                <MenuItem disabled={true} key={'null'} value={''}>Select Type</MenuItem>
                {typeList.map((type) => (
                  <MenuItem key={type?.id} value={type?.id}>{type?.title}</MenuItem>
                ))}
              </Select>
              {errors.type_id && (
                <p className="contractsTags__tags__modal-error">Select tag type</p>
              )}
            </div>
            
            <div style={{ width: '100%', position: 'relative' }}>
              <div style={{ textAlign: 'left' }} className="form-row-label">Group *</div>
              <Select
                id="type"
                key="type"
                displayEmpty
                onChange={(event) => {
                  handleInputChange('group_id', event.target.value)
                }}
                sx={{
                  backgroundColor: '#EFEFEF',
                  border: 0,
                  borderRadius: '8px',
                  fontFamily: 'Korolev Medium',
                  textAlign: 'left',
                  width: '100%',
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: errors.group_id ? '#a20601' : "inherit"
                    },
                    "&:hover fieldset": {
                      borderColor: "gray"
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: errors.group_id ? '#a20601' : '#62AB37'
                    }
                  }
                }}
                value={setValue(editTag?.group_id, newTag?.group_id)}
              >
                <MenuItem disabled={true} key={'null'} value={''}>Select Group</MenuItem>
                {groupList.map((group) => (
                  <MenuItem key={group?.id} value={group?.id}>
                    <p
                      style={{
                        borderLeft: `20px solid ${group?.color}`,
                        paddingLeft: '5px',
                      }}>
                      {group?.title}
                    </p>
                  </MenuItem>
                ))}
              </Select>
              {errors.group_id && (
                <p className="contractsTags__tags__modal-error">Select tag group</p>
              )}
            </div>
          </div>
          
          <div className="contractsTags__tags__modal-buttonContainer">
            {tagCreateLoading && (
              <div className="contractsTags__tags__modal-loading">
                <ProgressSpinner />
              </div>
            )}
            {tagEditLoading && (
              <div className="contractsTags__tags__modal-loading">
                <ProgressSpinner />
              </div>
            )}
            {tagCreateError.length > 0  && (
              <p className="contractsTags__tags__modal-error--add">{tagCreateError}</p>
            )}
            {tagEditError.length > 0  && (
              <p className="contractsTags__tags__modal-error--add">{tagEditError}</p>
            )}
            <button
              className={classNames('contractsTags__tags__modal-button', {
                'contractsTags__tags__modal-button-blue': pathname.includes('applicant'),
              })}
              onClick={handleSubmit}
              disabled={tagCreateLoading || tagEditLoading}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <NewBaseModal
      dialogContent={buildModel()}
      onClose={handleClose}
      open={open}
      title={isEditing ? 'Edit Tag' : 'Add New Tag'}
      colorPalette={pathname.includes('applicant') ? 'blue' : 'green'}
    />
  );
};
