import React, { useEffect, useState } from "react";

import ContractIdAnalytics from 'components/contracts-analytics/contractId-analytics';
import StaffingAllContracts from 'components/contracts-analytics/staffing/staffing-all-contracts';
import RecruiterDashboard from 'components/contracts-analytics/recruiter/recruiter-dashboard';

export default function StaffingDashboard(props) {
  const { currentRecruiter, setCurrentRecruiter, color } = props;
  
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  
  const [view, setView] = useState("");
  const [currentContract, setCurrentContract] = useState(null);
  
  useEffect(() => {
    if (loggedInUser.role === "accounting") {
      setView("accounting");
    } else {
      setView("all contracts");
    }
  }, []);
  
  useEffect(() => {
    if (currentContract) {
      setView("contract analytics");
    } else if (currentRecruiter) {
      setView("recruiter analytics");
    } else {
      setView("all contracts");
    }
  }, [currentContract, currentRecruiter]);
  
  return (
    <>
      {view === "all contracts" && (
        <StaffingAllContracts
          color={color}
          currentContract={currentContract}
          currentRecruiter={currentRecruiter}
          setCurrentContract={setCurrentContract}
          setCurrentRecruiter={setCurrentRecruiter}
        />
      )}
      {view === "contract analytics" && (
        <ContractIdAnalytics
          color={color}
          currentContractId={currentContract}
          setCurrentContract={setCurrentContract}
        />
      )}
      {view === "recruiter analytics" && (
        <RecruiterDashboard
          color={color}
          currentRecruiter={currentRecruiter}
          setCurrentRecruiter={setCurrentRecruiter}
        />
      )}
    </>
  );
};
