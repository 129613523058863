import '../../css/contracts.scss';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';
import Toast from '../toast/toast';
import EditContractURL from 'components/modal/edit-contract-url';

import ReactQuill from 'react-quill';
import { Tooltip } from '@mui/material';
import ProgressSpinner from 'components/progress/progress-spinner';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  contractsLoadingSelector,
  editContractErrorSelector,
} from 'store/contracts/selectors';
import { createContract, editContractById } from 'store/contracts/thunk';
import { setEditContractError } from 'store/contracts/slice';

export default function ContractGeneralInfoForm({ getContracts, currentContract }) {
  const dispatch = useAppDispatch();
  const error = useAppSelector(editContractErrorSelector);
  const loading = useAppSelector(contractsLoadingSelector);
  
  const [contractTitle, setContractTitle] = useState('');
  const [contractPersonnel, setContractPersonnel] = useState('');
  const [contractSecurity, setContractSecurity] = useState('');
  const [contractStart, setContractStart] = useState(null);
  const [contractDeadline, setContractDeadline] = useState(null);
  const [contractEnd, setContractEnd] = useState(null);
  const [contractURL, setContractURL] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [openURLModal, setOpenURLModal] = useState(false);
  const [openURLToast, setOpenURLToast] = useState(false);
  const [openCopyToast, setOpenCopyToast] = useState(false);
  const [description, setDescription] = useState('<p><br></p>');
  
  useEffect(() => {
    if (currentContract) {
      setContractTitle(currentContract.title);
      setContractPersonnel(currentContract.personel_need);
      setContractSecurity(currentContract.security);
      setContractURL(currentContract.contract_alias);
      if (currentContract.contract_start) {
        setContractStart(parseISO(currentContract.contract_start));
      }
      if (currentContract.contract_deadline) {
        setContractDeadline(parseISO(currentContract.contract_deadline));
      }
      if (currentContract.contract_end) {
        setContractEnd(parseISO(currentContract.contract_end));
      }
      if (currentContract.contract_description) {
        setDescription(currentContract.contract_description);
      }
    }
  }, [currentContract]);


  const createNewContract = async () => {
    if (!contractURL) {
      dispatch(setEditContractError('Please provide a unique URL for your contract application.'));
    } else if (contractURL.length >= 50 || !isNaN(contractURL.charAt(0)) || contractURL.indexOf(' ') >= 0) {
      dispatch(setEditContractError('There was an error saving the URL. Please confirm the URL does not exceed 50 characters, does not begin with a number and does not contain spaces.'));
    } else if (!contractTitle) {
      dispatch(setEditContractError('Contract title is required field!'));
    } else if (!contractSecurity) {
      dispatch(setEditContractError('Contract security is required field!'));
    } else {

      if (contractSecurity == 'None') {
        setContractSecurity(null);
      }
      
      const body =  {
        title: contractTitle,
        personel_need: contractPersonnel,
        security: contractSecurity,
        contract_alias: contractURL,
        contract_start: contractStart ? new Date(contractStart).toISOString() : null,
        contract_deadline: contractDeadline ? new Date(contractDeadline).toISOString() : null,
        contract_end: contractEnd ? new Date(contractEnd).toISOString() : null,
        contract_description: description,
      };
      
      dispatch(createContract({ body }))
        .then(() => {
          setOpenToast(true);
          if (getContracts) {
            getContracts();
          }
        })
    }
  };

  const updateContract = async () => {
    if (!contractURL) {
      dispatch(setEditContractError('Please provide a unique URL for your contract application.'));
    } else if (contractURL.length >= 50 || !isNaN(contractURL.charAt(0)) || contractURL.indexOf(' ') >= 0) {
      dispatch(setEditContractError('There was an error saving the URL. Please confirm the URL does not exceed 50 characters, does not begin with a number and does not contain spaces.'));
    } else if (!contractTitle) {
      dispatch(setEditContractError('Contract title is required field!'));
    } else if (!contractSecurity) {
      dispatch(setEditContractError('Contract security is required field!'));
    } else {
      if (contractSecurity == 'None') {
        setContractSecurity(null);
      }
      const body = {
        title: contractTitle,
        personel_need: contractPersonnel,
        security: contractSecurity,
        contract_alias: contractURL,
        contract_start: contractStart,
        contract_deadline: contractDeadline,
        contract_end: contractEnd,
        contract_description: description,
      };
      dispatch(editContractById({ contractId: currentContract.id, body }))
        .then(() => {
          setOpenToast(true)
        });
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],
      ['link'],
      [{ 'align': [] }],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: true,
    },
  };

  return (
    <div className="contract-form-section">
      <div className="contract-form-section-header">General Information</div>
      <div className="contract-form-section-content" style={{ gap: 0 }}>
        <div className="contract-form-set">
          <div className="contract-form-label">Contract Title</div>
          <input
            className="contract-form-input"
            placeholder="Enter Contract Title..."
            value={contractTitle || ''}
            onChange={(e) => setContractTitle(e.target.value)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Personnel Needed</div>
          <input
            className="contract-form-input"
            placeholder="Enter Personnel Needed..."
            type="number"
            min="0"
            value={contractPersonnel || ''}
            onChange={(e) => setContractPersonnel(e.target.value)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Security Level</div>
          <select
            className="contract-form-input"
            placeholder="Enter Security Level..."
            value={contractSecurity || ''}
            onChange={(e) => setContractSecurity(e.currentTarget.value)}
          >
            <option value="">Select Security Level...</option>
            <option value="None">None</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
            <option value="4">Four</option>
            <option value="5">Five</option>
          </select>
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Contract Start</div>
          <DatePicker
            className="contract-form-input"
            selected={contractStart}
            onChange={(date) => setContractStart(date)}
            dateFormat="MM/dd/yyyy"
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Contract Deadline</div>
          <DatePicker
            className="contract-form-input"
            selected={contractDeadline}
            onChange={(date) => setContractDeadline(date)}
            dateFormat="MM/dd/yyyy"
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Contract End</div>
          <DatePicker
            className="contract-form-input"
            selected={contractEnd}
            onChange={(date) => setContractEnd(date)}
            dateFormat="MM/dd/yyyy"
          />
        </div>
        <div className="contract-form-set-full contract-form-quill">
          <div className="sale-form-label">Contract Description</div>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            modules={modules}
            style={{
              backgroundColor: '#EFEFEF40',
              color: '#EFEFEF',
              borderRadius: '10px',
              width: '97.35%',
              height: "325px"
            }}
          />
        </div>
        {currentContract ? (
          <div className="contract-form-set-full">
            <div className="contract-form-label">Application URL</div>
            {currentContract.contract_alias ? (
              <div style={{ fontFamily: 'Korolev Medium', fontSize: '15px' }}>
                {`https://responsivemanagement.org/application/${currentContract.contract_alias}`}
                <Tooltip title="Edit link">
                  <i
                    className="fa-solid fa-pencil edit-icon"
                    onClick={() => setOpenURLModal(true)}
                  />
                </Tooltip>
                <Tooltip title="Copy link">
                  <i
                    className="fa-solid fa-copy edit-icon copy-icon"
                    onClick={() => {
                      setOpenCopyToast(true);
                      navigator.clipboard.writeText(`https://responsivemanagement.org/application/${contractURL}`);
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              <>
                <input
                  className="contract-form-input"
                  placeholder="Enter Application URL..."
                  value={contractURL || ''}
                  onChange={(e) => setContractURL(e.target.value)}
                  style={{ width: '97.35%' }}
                />
                <div
                  style={{
                    color: '#EFEFEF',
                    fontFamily: 'Korolev Medium',
                    fontSize: '14px',
                    marginLeft: '13px',
                    marginTop: '10px',
                  }}
                >
                  https://responsivemanagement.org/application/{contractURL}
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="contract-form-set-full">
            <div className="contract-form-label">Application URL</div>
            <input
              className="contract-form-input"
              placeholder="Enter Application URL..."
              value={contractURL}
              onChange={(e) => setContractURL(e.target.value)}
              style={{ width: '97.35%' }}
            />
            <div
              style={{
                color: '#EFEFEF',
                fontFamily: 'Korolev Medium',
                fontSize: '14px',
                marginLeft: '13px',
                marginTop: '10px',
              }}
            >
              https://responsivemanagement.org/application/{contractURL}
            </div>
          </div>
        )}
        <div
          style={{
            fontFamily: 'Korolev Medium',
            textAlign: 'center',
            color: '#a20601',
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        >
          {error}
        </div>
      </div>
      {currentContract ? (
        <div className="contract-form-button-container">
          {loading && (
            <div className="sale-form-spinner" style={{ right: '128px' }}>
              <ProgressSpinner />
            </div>
          )}
          <button
            className="create-button"
            disabled={loading}
            onClick={() => {
              updateContract();
              setOpenToast(true);
            }}
          >
            Update
          </button>
        </div>
      ) : (
        <div className="contract-form-button-container">
          <button
            className="create-button"
            onClick={() => createNewContract()}
          >
            Create
          </button>
        </div>
      )}
      {openURLModal && (
        <EditContractURL
          contract={currentContract}
          getContracts={getContracts}
          handleClose={() => setOpenURLModal(false)}
          open={openURLModal}
          setOpenToast={() => setOpenURLToast(true)}
        />
      )}
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message="Contract saved"
        />
      )}
      {openURLToast && (
        <Toast
          open={openURLToast}
          onClose={() => setOpenURLToast(false)}
          message="Application URL saved"
        />
      )}
      {openCopyToast && (
        <Toast
          open={openCopyToast}
          onClose={() => setOpenCopyToast(false)}
          message="Application URL copied"
        />
      )}
    </div>
  );
};
