import React, { useState } from "react";
import { Divider, Typography } from "@mui/material";
import { HighlightGreenAutoStyledButton } from "../buttons/button-styles";
import AddUserModal from "../../components/modal/add-user-modal";
import BellesReportModal from "components/modal/belles-report-modal";
import SearchMenu from "components/search-box/search";
import Toast from "components/toast/toast";
import ProgressSpinner from 'components/progress/progress-spinner';

export default function UsersSidebar(props) {
  const {
    allUsers,
    bouncedUsers,
    getUsers,
    searchedUsers,
    searchApplicants,
    searchField,
    searchFilter,
    setCurrentPage,
    setSearchField,
    setSearchFilter,
    setSearchedUsers,
    tabValue,
    loading,
  } = props;

  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openAddUserToast, setOpenAddUserToast] = useState(false);
  const [openAddUSGModal, setOpenAddUSGModal] = useState(false);
  const [openAddUSGToast, setOpenAddUSGToast] = useState(false);

  const createUserHighlight = () => {
    let numberHighlight = '0';
    let userHighlight = 'Users';
    if (tabValue === 5) {
      if (bouncedUsers && bouncedUsers.length > 0) {
        numberHighlight = bouncedUsers.length.toLocaleString();
        if (bouncedUsers.length === 1) {
          userHighlight = 'User';
        }
      }
    } else {
      if (searchedUsers) {
        if (searchedUsers.length > 0) {
          numberHighlight = searchedUsers.length.toLocaleString();
          if (searchedUsers.length === 1) {
            userHighlight = 'User';
          }
        }
      } else {
        if (allUsers.length > 0) {
          numberHighlight = allUsers.length.toLocaleString();
          if (allUsers.length === 1) {
            userHighlight = 'User';
          }
        }
      }
    }

    return (
      <>
        {loading ? (
          <div style={{ height: '76px', position: 'relative' }}>
            <span style={{ position: 'absolute', top: -15, left: '43%' }}>
              <ProgressSpinner />
            </span>
          </div>
        ) : (
          <Typography
            sx={{
              color: '#62AB37',
              fontFamily: 'MagistralCondW08-Medium',
              fontSize: '40px',
              marginTop: 2,
            }}
          >
            {numberHighlight}
          </Typography>
        )}
        <Typography
          sx={{
            color: "#EFEFEF",
            fontFamily: "Korolev Medium",
            fontSize: "16px",
          }}
        >
          {userHighlight}
        </Typography>
      </>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "transparent",
        color: "#EFEFEF",
        borderRight: "1px solid #EFEFEF80",
        height: "calc(100vh - 80px)",
        padding: "0 25px",
        overflow: "auto",
        textAlign: "center",
        width: "100%",
      }}
    >
      {createUserHighlight()}
      <Divider sx={{ borderColor: "#EFEFEF80", marginTop: "20px" }} />
      <div className="filter-header">Option Panel</div>
      <SearchMenu
        getUsers={getUsers}
        searchApplicants={searchApplicants}
        searchField={searchField}
        searchFilter={searchFilter}
        setCurrentPage={setCurrentPage}
        setSearchField={setSearchField}
        setSearchFilter={setSearchFilter}
        setSearchedUsers={setSearchedUsers}
      />
      <Divider sx={{ borderColor: "#EFEFEF80", marginTop: "30px" }} />
      <HighlightGreenAutoStyledButton
        variant="contained"
        onClick={() => setOpenAddUserModal(true)}
        sx={{
          margin: "30px 0 20px",
        }}
      >
        Add user
      </HighlightGreenAutoStyledButton>
      <HighlightGreenAutoStyledButton
        variant="contained"
        onClick={() => setOpenAddUSGModal(true)}
        sx={{
          margin: "0 0 30px",
        }}
      >
        Upload USG status
      </HighlightGreenAutoStyledButton>
      {openAddUserModal && (
        <AddUserModal
          open={openAddUserModal}
          openSuccessToast={() => setOpenAddUserToast(true)}
          handleClose={() => setOpenAddUserModal(false)}
          updateUsersList={() => getUsers()}
          uploadType={"all"}
        />
      )}
      {openAddUserToast && (
        <Toast
          open={openAddUserToast}
          onClose={() => setOpenAddUserToast(false)}
          message="User saved"
        />
      )}
      {openAddUSGModal && (
        <BellesReportModal
          open={openAddUSGModal}
          openSuccessToast={() => setOpenAddUSGToast(true)}
          handleClose={() => setOpenAddUSGModal(false)}
        />
      )}
      {openAddUSGToast && (
        <Toast
          open={openAddUSGToast}
          onClose={() => setOpenAddUSGToast(false)}
          message="Users saved"
        />
      )}
    </div>
  );
}
