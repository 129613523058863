import React from 'react';

export default function StatusRow(props) {
    const {
        hasMessage,
        hasFiles,
    } = props;

    return (
        <div className="status-row">
            <div className={hasMessage ? "status status--red" : "status"}></div>
            <div className={hasFiles ? "status status--yellow" : "status"}></div>
        </div>
    );
}