import React, { Component } from 'react';
import AppBar from 'components/app-bar/updated-app-bar-component';

import Gauge from 'components/widgets/gauge';

class Demo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamic: 0,
        }
        this.timerInterval = setInterval(() => {
            this.setState({dynamic: Math.round(100 * Math.random())});
        }, 1000);
    }
    render() { 
        return (
            <>
                <AppBar menu={true} />
                <div className="demo" style={{backgroundColor: "rgba(35, 35, 35, 1)", minHeight: '100vh'}}>
                    <div className="content">
                        <div className="sidebar" style={{height: '100vh'}}>

                        </div>
                        <div className="page-content">
                            <div>
                                <h1>Demo Board</h1>
                                <p>This is a demonstration of components with working examples contained with routes/demo.jsx.</p>
                            </div>

                            <h2>gauge.jsx</h2>
                            <div className="row">
                                {/* // maxValue, scaleMidPercent, scaleHighPercent, value, subtitle */}
                                <Gauge value={20} />
                                <Gauge value={60} maxValue={300} scaleMidPercent={20} scaleHighPercent={60} />
                                <Gauge value={95} />
                                <Gauge value={this.state.dynamic} subtitle='requests per second' />
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Demo;