import { createAsyncThunk } from '@reduxjs/toolkit';
import { subdivisions } from 'services/subDivisions';
export const getSubdivisions = createAsyncThunk(
  'subdivisions',
  async (_, { rejectWithValue }) => {
    try {
      return await subdivisions.getAllSubdivisions();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSubdivisionsByDivisionId = createAsyncThunk(
  'subdivisionsByDivisionId',
  async ({ divisionId }, { rejectWithValue }) => {
    try {
      return await subdivisions.getSubdivisionsByDivisionId(divisionId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getFilterableSubdivisions = createAsyncThunk(
  'filterableSubdivisions',
  async ({ divisionId }, { rejectWithValue }) => {
    try {
      return await subdivisions.getFilterableSubdivisions(divisionId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSubdivisionById = createAsyncThunk(
  'subdivisionById',
  async ({ subdivisionId }, { rejectWithValue }) => {
    try {
      return await subdivisions.getSubdivisionById(subdivisionId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSubdivisionByDivisionAndContractId = createAsyncThunk(
  'subdivisionByDivisionAndContractId',
  async ({ divisionId, contractId }, { rejectWithValue }) => {
    try {
      return await subdivisions.getSubdivisionByDivisionAndContractId(divisionId, contractId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createSubdivision = createAsyncThunk(
  'createSubdivision',
  async ({ _, body }, { rejectWithValue }) => {
    try {
      return await subdivisions.createSubdivision(_, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateSubdivision = createAsyncThunk(
  'updateSubdivision',
  async ({ subdivisionId, body }, { rejectWithValue }) => {
    try {
      return await subdivisions.updateSubdivision(subdivisionId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteSubdivision = createAsyncThunk(
  'deleteSubdivision',
  async ({ subdivisionId }, { rejectWithValue }) => {
    try {
      return await subdivisions.deleteSubdivision(subdivisionId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
