import "../../css/create-new-vendor.scss";
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import { Checkbox } from "@mui/material";
import API from 'API';
import Toast from 'components/toast/toast';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { useAppSelector } from 'hooks/reduxHooks';
import { saleSelector, salesRepsLoadingSelector, salesRepsSelector } from 'store/sales/selectors';

const initialTask = {
  name: '',
  description: '',
  date_assigned: '',
  date_due: '',
  personnel_assigned_id: '',
  goal_to_achieve: '',
  file_active: false,
  milestone_active: false,
};

const initialErrors = {
  name: false,
  description: false,
  date_assigned: false,
  date_due: false,
  personnel_assigned_id: false,
  goal_to_achieve: false,
  server_error: false,
}

export default function CreateNewTask({ resetTasks }) {
  const token = JSON.parse(localStorage.getItem("token"));

  const { sale } = useAppSelector(saleSelector);

  const [taskForm, setTaskForm] = useState(initialTask);
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const salesUsers = useAppSelector(salesRepsSelector);
  const loadingReps = useAppSelector(salesRepsLoadingSelector);

  const handleChange = (event) => {
    setTaskForm({
      ...taskForm,
      [event.target.name]: event.target.value,
    })
    if (!event.target.value.length) {
      setErrors({
        ...errors,
        [event.target.name]: true,
      })
    } else {
      setErrors({
        ...errors,
        [event.target.name]: false,
      })
    }
  }


  const handleCreate = () => {
    let requestError = false;
    const isValidLength =
      Object
        .entries(taskForm)
        .every((item) => {
          if (!item[0].includes('date') && !item[0].includes('active')) {
            return item[1].length < 256;
          }
          return true;
        });
    const isValidFields =
      Object
        .entries(taskForm)
        .every((item) => {
          if (!item[0].includes('active')) {
            return item[1];
          }
          return true;
        });

    if (!isValidFields) {
      setErrors({
        name: !taskForm.name,
        description: !taskForm.description,
        date_assigned: !taskForm.date_assigned,
        date_due: !taskForm.date_due,
        personnel_assigned_id: !taskForm.personnel_assigned_id,
        goal_to_achieve: !taskForm.goal_to_achieve,
        file_active: !taskForm.file_active,
        milestone_active: !taskForm.milestone_active,
      });
    }

    if (isValidFields && isValidLength) {
      setLoading(true);
      try {
        fetch(`${API.endpointURL}/api/tasks`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
            "Accept": "application/json",
          },
          body: JSON.stringify({
            name: taskForm.name,
            personnel_assigned_id: taskForm.personnel_assigned_id,
            description: taskForm.description,
            goal_to_achieve: taskForm.goal_to_achieve,
            date_assigned: taskForm.date_assigned,
            date_due: taskForm.date_due,
            milestone_active: taskForm.milestone_active,
            file_active: taskForm.file_active,
            status: 'assigned',
            sales_id: sale.id,
          })
        })
          .then((res) => {
            if (!res.ok) {
              requestError = true;
            }
            return res.json();
          })
          .then(() => {
            setLoading(false);
            setOpenToast(true);
            if (requestError) {
              setErrors({
                ...errors,
                server_error: true,
              });
            } else {
              resetTasks();
              setTaskForm(initialTask);
              setErrors({
                ...errors,
                validation: false,
                server_error: false,
              });
            }
          });
      } catch (e) {
        setLoading(false);
        setErrors({
          ...errors,
          server_error: true,
        });
        setOpenToast(true);
      }
    }
  };

  return (
    <>
      <h1 className="content-header">Create New Task</h1>
      <div className="content-container">
        {loadingReps ? (
          <div className="content-loading">
            <ProgressSpinnerNavy />
          </div>
        ) : (
          <div className="contract-form-section">
            <div className="sale-form-section-content" style={{ marginTop: 0 }}>
              <div className="contract-form-set">
                <div className="contract-form-label">Task Name</div>
                <input
                  className="contract-form-input"
                  placeholder="Enter Task Name..."
                  value={taskForm.name}
                  name="name"
                  onChange={handleChange}
                />
                {taskForm.name.length > 255 && (
                  <p className="sale-form-error">* Max length is 255 characters...</p>
                )}
                {errors.name && (
                  <p className="sale-form-error">* Please enter data...</p>
                )}
              </div>
              <div
                className="contract-form-set"
                style={{
                  width: '203px',
                  marginRight: '0.5%',
                }}
              >
                <div className="contract-form-label">Date Assigned</div>
                <DatePicker
                  className="contract-form-input"
                  selected={taskForm.date_assigned}
                  name="date_assigned"
                  placeholderText={taskForm.date_assigned ? undefined : "MM/dd/yyyy"}
                  onChange={(date) => {
                    setTaskForm({
                      ...taskForm,
                      date_assigned: date,
                    });
                    setErrors({
                      ...errors,
                      date_assigned: date === null,
                    })
                  }}
                  dateFormat="MM/dd/yyyy"
                  autoComplete="off"
                />
                {errors.date_assigned && (
                  <p className="sale-form-error" style={{ right: '4%' }}>
                    * Please enter data...
                  </p>
                )}
              </div>
              <div className="contract-form-set">
                <div className="contract-form-label">Date Due</div>
                <DatePicker
                  className="contract-form-input"
                  selected={taskForm.date_due}
                  name="date_due"
                  placeholderText={taskForm.date_due ? undefined : "MM/dd/yyyy"}
                  onChange={(date) => {
                    setTaskForm({
                      ...taskForm,
                      date_due: date,
                    });
                    setErrors({
                      ...errors,
                      date_due: date === null,
                    })
                  }}
                  dateFormat="MM/dd/yyyy"
                  autoComplete="off"
                />
                {errors.date_due && (
                  <p className="sale-form-error" style={{ left: '89px' }}>
                    * Please enter data...
                  </p>
                )}
              </div>
              <div className="contract-form-set-full">
                <div className="contract-form-label">Task Description</div>
                <textarea
                  className="contract-form-input"
                  style={{ width: "100%" }}
                  placeholder="Enter Task Description..."
                  value={taskForm.description}
                  name="description"
                  onChange={handleChange}
                  rows={5}
                />
                {taskForm.description.length > 255 && (
                  <p className="sale-form-error">* Max length is 255 characters...</p>
                )}
                {errors.description && (
                  <p className="sale-form-error">* Please enter data...</p>
                )}
              </div>
              <div className="contract-form-set-half">
                <div className="contract-form-label">Personnel Assigned</div>
                <select
                  className="contract-form-input"
                  placeholder="Select Personnel Assigned..."
                  value={taskForm.personnel_assigned_id}
                  name="personnel_assigned_id"
                  onChange={handleChange}
                  style={{ color: taskForm.personnel_assigned_id ? '#EFEFEF' : '#D0D0D0B5' }}
                >
                  <option value="">Select Personnel Assigned...</option>
                  {salesUsers?.length && salesUsers.map((user) => (
                    <option key={user.id} value={user.id}>
                      {`${user.first_name} ${user.last_name}`}
                    </option>
                  ))}
                </select>
                {errors.personnel_assigned_id && (
                  <p className="sale-form-error">* Please enter data...</p>
                )}
              </div>
              <div className="contract-form-set-half">
                <div className="contract-form-label">Task Goal</div>
                <select
                  className="contract-form-input"
                  placeholder="Select Goal to Achieve..."
                  value={taskForm.goal_to_achieve}
                  name="goal_to_achieve"
                  onChange={handleChange}
                  style={{ color: taskForm.goal_to_achieve ? '#EFEFEF' : '#D0D0D0B5' }}
                >
                  <option value="">Select Goal to Achieve...</option>
                  <option value="opportunity">Opportunity</option>
                  <option value="contract">Contract</option>
                  <option value="award">Award</option>
                </select>
                {errors.goal_to_achieve && (
                  <p className="sale-form-error">* Please enter data...</p>
                )}
              </div>
              <div className="file-row half-width">
                <Checkbox
                  checked={taskForm.file_active}
                  sx={{
                    color: "#EFEFEF",
                    padding: 0,
                    paddingRight: "10px",
                    "&.Mui-checked": {
                      color: "#A4F2F1",
                    },
                    "&.Mui-disabled": {
                      color: "#EFEFEF40",
                    },
                  }}
                  onClick={() => setTaskForm({
                    ...taskForm,
                    file_active: !taskForm.file_active,
                  })}
                />
                <div className="active-label">Create New File Folder</div>
              </div>
              <div className="file-row half-width">
                <Checkbox
                  checked={taskForm.milestone_active}
                  sx={{
                    color: "#EFEFEF",
                    padding: 0,
                    paddingRight: "10px",
                    "&.Mui-checked": {
                      color: "#A4F2F1",
                    },
                    "&.Mui-disabled": {
                      color: "#EFEFEF40",
                    },
                  }}
                  onClick={() => setTaskForm({
                    ...taskForm,
                    milestone_active: !taskForm.milestone_active,
                  })}
                />
                <div className="active-label">Add to Milestone Tracking</div>
              </div>
            </div>
            <div className="contract-form-button-container">
              {loading && (
                <div className="sale-form-spinner">
                  <ProgressSpinnerNavy />
                </div>
              )}
              <button
                className="create-button create-button--dark"
                style={{
                  backgroundColor: "#141A66",
                  border: "1px solid #bbbbbb",
                  ':hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={handleCreate}
                disabled={loading}
              >
                Create
              </button>
            </div>
          </div>
        )}
      </div>
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={errors.server_error
            ? 'Something went wrong, try again later...'
            : 'New Task Successfully Created'
          }
        />
      )}
    </>
  );
}
