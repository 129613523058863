import { createAsyncThunk } from '@reduxjs/toolkit';
import { divisions } from 'services/divisions';
export const getDivisions = createAsyncThunk(
  'divisions',
  async (_, { rejectWithValue }) => {
    try {
      return await divisions.getAllDivisions();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDivisionsByContractId = createAsyncThunk(
  'divisionsByContractId',
  async ({ contractId }, { rejectWithValue }) => {
    try {
      return await divisions.getDivisionsByContractId(contractId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getFilterableDivisions = createAsyncThunk(
  'filterableDivisions',
  async ({ contractId }, { rejectWithValue }) => {
    try {
      return await divisions.getFilterableDivisions(contractId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDivisionById = createAsyncThunk(
  'divisionById',
  async ({ divisionId }, { rejectWithValue }) => {
    try {
      return await divisions.getDivisionById(divisionId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createDivision = createAsyncThunk(
  'createDivision',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await divisions.createDivision(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateDivision = createAsyncThunk(
  'updateDivision',
  async ({ divisionId, body }, { rejectWithValue }) => {
    try {
      return await divisions.updateDivision(divisionId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteDivision = createAsyncThunk(
  'deleteDivision',
  async ({ divisionId }, { rejectWithValue }) => {
    try {
      return await divisions.deleteDivision(divisionId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
