import { createSlice } from '@reduxjs/toolkit';
import {
  assignRecruiter, dissociateRecruiter,
  getAssignedRecruitersByContractId,
  getRecruiterContracts,
  getRecruiters,
} from 'store/recruiters/thunk';

const initialState = {
  recruiters: [],
  recruitersError: '',
  isLoading: false,
  
  recruiterContracts: [],
  recruiterContractsLoading: false,
  recruiterContractsError: '',
  
  assignedRecruitersByContractId: [],
};

const recruitersSlice = createSlice({
  name: 'recruitersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRecruiters.pending, (state) => {
      state.recruitersError = '';
      state.isLoading = true;
    });
    builder.addCase(getRecruiters.fulfilled, (state, action) => {
      state.recruiters = action.payload;
      state.recruitersError = '';
      state.isLoading = false;
    });
    builder.addCase(getRecruiters.rejected, (state) => {
      state.recruitersError = 'Error fetching recruiters.';
      state.isLoading = false;
    });
    
    builder.addCase(getRecruiterContracts.pending, (state) => {
      state.recruiterContractsError = '';
      state.recruiterContractsLoading = true;
    });
    builder.addCase(getRecruiterContracts.fulfilled, (state, action) => {
      state.recruiterContracts = action.payload;
      state.recruiterContractsError = '';
      state.recruiterContractsLoading = false;
    });
    builder.addCase(getRecruiterContracts.rejected, (state) => {
      state.recruiterContractsError = 'Error fetching contracts.';
      state.recruiterContractsLoading = false;
    });
    
    builder.addCase(getAssignedRecruitersByContractId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAssignedRecruitersByContractId.fulfilled, (state, action) => {
      state.assignedRecruitersByContractId = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAssignedRecruitersByContractId.rejected, (state) => {
      state.isLoading = false;
      state.assignedRecruitersByContractId = null;
    });
    builder.addCase(assignRecruiter.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignRecruiter.fulfilled, () => {});
    builder.addCase(assignRecruiter.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(dissociateRecruiter.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(dissociateRecruiter.fulfilled, () => {});
    builder.addCase(dissociateRecruiter.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default recruitersSlice.reducer;