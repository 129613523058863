import React, { useEffect, useState } from 'react';

import '../../css/application.scss';
import '../../css/general-info-form.scss';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { NavigateButtons } from './navigateButtons';
import API from 'API';
import ProgressSpinner from 'components/progress/progress-spinner';

const theme = createTheme({
  palette: {
    primary: {
      main: '#dae0bc',
    },
  },
});

export default function ScreeningConsent(props) {
  const token = JSON.parse(localStorage.getItem('token'));
  
  const {
    setView,
    contractId,
    applicantAnswers,
    setApplicantAnswers,
  } = props;
  const [formError, setFormError] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  
  const setAnswers = async () => {
    const initialAnswers = {};
    await questions.forEach((item) => initialAnswers[item.id] = '')
    setApplicantAnswers(initialAnswers)
  }

  useEffect(() => {
    setAnswers();
  }, [questions])
  
  const getQuestions = async () => {
    setError('');
    setLoading(true);
    let requestError = false;
    try {
      await fetch(`${API.endpointURL}/api/filter/application-questions?page=1&sort[column]=sort_order&sort[direction]=asc&contract_id=${contractId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (!res.ok) {
            requestError = true;
          }
          return res.json();
        })
        .then((json) => {
          setLoading(false);
          if (requestError) {
            setQuestions([]);
            setError('Error getting questions.');
          } else {
            setQuestions(json.data);
          }
        });
    } catch (e) {
      setLoading(false);
      setError('Error getting questions.');
    }
  };
  
  useEffect(() => {
    getQuestions();
  }, []);
  
  const checkAnswers = (obj) => {
    const notRequiredQuestionIds = questions
      .filter((question) => question.is_required === false)
      .map((question) => question.id);
    
    const filteredQuestions = questions.filter((question) =>
      !notRequiredQuestionIds.includes(question.id)
    );
    
    const filteredAnswers = Object.entries(obj).filter(
      (answer) => !notRequiredQuestionIds.includes(+answer[0])
    );
    
    if (filteredQuestions.length > 0 && filteredAnswers.length === 0) {
      return true;
    } else if (filteredQuestions.length !== filteredAnswers.length) {
      return true;
    }

    return filteredAnswers.some(answer => {
      return (
        answer[1] === null ||
        answer[1] === undefined ||
        (typeof answer[1] === 'string' && answer[1].trim() === '')
      );
    });
  };
  
  const handleBack = () => {
    setFormError(false);
    setView('screeningForm');
  };
  
  const handleSaveForm = () => {
    const errorAnswers = checkAnswers(applicantAnswers);
    if (!errorAnswers) {
      setFormError(false);
      setView('files');
    } else {
      setFormError(true);
    }
  };
  
  const handleChange = (e, id) => {
    setApplicantAnswers({
      ...applicantAnswers,
      [id]: e.target.value !== null ? e.target.value : ' ',
    });
  };
  
  return (
    <ThemeProvider theme={theme}>
      <section className="infoForm-container">
        <h1 className="infoForm-h1">Screening</h1>
        {error && !loading && (
          <p className="infoForm-p" style={{ color: '#a20601', marginTop: '12px', height: '264px' }}>
            {error}
          </p>
        )}
        {loading ? (
          <div className="content-loading" style={{ height: '264px' }}>
            <ProgressSpinner/>
          </div>
        ) : (
          <>
            <FormControl>
              {questions.length > 0 && questions?.map((question) => (
                <div className="infoForm-row" style={{ alignItems: 'center' }} key={question.id}>
                  <p className="infoForm-p" style={{ marginTop: '6px', lineHeight: '20px' }}>
                    {question.question} {question.is_required && '*'}
                  </p>
                  {question.answer_type === 'text' ? (
                    <TextField
                      placeholder="Note here..."
                      variant="outlined"
                      // value={applicantAnswers[question.id] || ''}
                      onChange={(e) => handleChange(e, question.id)}
                      sx={{
                        backgroundColor: '#3e453d',
                        borderRadius: '4px',
                        color: '#EFEFEF',
                        fontFamily: 'Korolev Medium',
                        margin: '0 0 8px 0',
                        maxWidth: '30%',
                        input: {
                          color: '#EFEFEF',
                          fontFamily: 'Korolev Medium',
                        },
                      }}
                    />
                  ) : (
                    <RadioGroup
                      row
                      // value={applicantAnswers[question.id] || ''}
                      onChange={(e) => handleChange(e, question.id)}
                      style={{ maxWidth: '30%' }}
                    >
                      <FormControlLabel
                        value={true}
                        control={
                          <Radio
                            color="primary"
                            sx={{
                              '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                                color: '#dae0bc',
                              },
                            }}
                          />
                        }
                        label="Yes"
                        sx={{ color: '#EFEFEF' }}
                      />
                      <FormControlLabel
                        value={false}
                        control={
                          <Radio
                            color="primary"
                            sx={{
                              '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                                color: '#dae0bc',
                              },
                            }}
                          />
                        }
                        label="No"
                        sx={{ color: '#EFEFEF' }}
                      />
                    </RadioGroup>
                  )}
                </div>
              ))}
            </FormControl>
            <NavigateButtons
              error={formError}
              back={() => handleBack()}
              next={() => handleSaveForm()}
            />
          </>
        )}
      </section>
    </ThemeProvider>
  );
}
