import "../../css/modal.scss";
import React, { useState } from "react";
import { Button } from "@mui/material";
import CustomDialogRedesign from "./custom-dialog-redesign";
import API from "API";

export default function BellesReportModal(props) {
  const token = JSON.parse(localStorage.getItem("token"));

  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [error, setError] = useState("");

  //   const handleErrors = (errors) => {
  //     console.log(errors);
  //     if (
  //       errors["email"].length === 1 &&
  //       errors["email"][0] === "The email has already been taken."
  //     ) {
  //       setError("This email is already associated with another user.");
  //     } else {
  //       setError("Please enter a valid first name, last name and email address.");
  //     }
  //   };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleSubmission = () => {
    const formData = new FormData();

    formData.append("csv", selectedFile);

    fetch(`${API.endpointURL}/api/belles`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        Connection: "keep-alive",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if (props.openSuccessToast) {
          props.openSuccessToast();
        }
        props.handleClose();
      })
      .catch((error) => {
        props.handleClose();
        // console.error("Error:", error);
        // setError("Please select a file and try again.");
      });
  };

  const uploadBelles = () => {
    return (
      <React.Fragment key="add-user-form">
        {/* <Link to={"/manage"} sx={{ textDecoration: "none" }}> */}
        <div className="belles-file-upload">
          <input type="file" name="file" onChange={changeHandler} />
        </div>
        {error && <div className="file-error">{error}</div>}
        <div className="button-container">
          <div>
            <Button
              variant="contained"
              className="add-user-button"
              sx={{
                backgroundColor: "#62AB37",
                border: "1px solid #62AB37",
                borderRadius: "10px",
                color: "#EFEFEF",
                fontFamily: "MagistralCondW08-Medium",
                fontSize: "18px",
                minHeight: "25px",
                padding: "13px 20px",
                textTransform: "capitalize",
                width: "175px",
                "&:hover": {
                  backgroundColor: "transparent",
                  border: "1px solid #62AB37",
                  boxShadow: "none",
                  color: "#62AB37",
                },
              }}
              onClick={handleSubmission}
            >
              Submit
            </Button>
          </div>
        </div>
        {/* </Link> */}
      </React.Fragment>
    );
  };

  return (
    <CustomDialogRedesign
      colorPalette="green"
      dialogContent={uploadBelles()}
      onClose={props.handleClose}
      open={props.open}
      title={"UPLOAD"}
      titleHighlight={"BELLES"}
    />
  );
}
