import React from 'react';
import Tabs from '@mui/material/Tabs';
import { StyledTab } from './tab-styles';

export default function UsersTabs(props) {

  const {
    currentTab,
    handleTabChange,
  } = props;

  const handleChange = (event, newValue) => {
    handleTabChange(newValue);
  };

  return (
    <Tabs
      value={currentTab}
      onChange={handleChange}
      variant='scrollable'
      scrollButtons="auto"
      TabIndicatorProps={{
        style: { display: 'none' }
      }}
      TabScrollButtonProps={{
        style: {
          margin: "0 10px",
          width: "10px",
        }
      }}
    >
      <StyledTab label="Admins" value={0} />
      <StyledTab label="Accounting" value={1} />
      <StyledTab label="Staffing" value={2} />
      <StyledTab label="Recruiters" value={3} />
      <StyledTab label="Applicants" value={4} />
      <StyledTab label="Sales" value={6} />
      <StyledTab label="Project managers" value={7} />
      <StyledTab label="Project coordinators" value={8} />
      <StyledTab label="Bounced Users" value={5} />
    </Tabs>
  );
};
