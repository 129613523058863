import "../../css/modal.scss";
import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import CustomDialog from "./custom-dialog";
import API from "API";

export default function EditApplicantPlacement(props) {
  const { applicant, getApplicant, handleClose, open, setOpenToast } = props;

  const token = JSON.parse(localStorage.getItem("token"));

  const [company, setCompany] = useState(
    applicant.company ? applicant.company : null
  );
  const [contract, setContract] = useState(
    applicant.application?.contract_id
      ? applicant.application.contract_id
      : null
  );
  const [division, setDivision] = useState(
    applicant.application?.region_id ? applicant.application.region_id : null
  );
  const [subdivision, setSubdivision] = useState(
    applicant.application?.site_id ? applicant.application.site_id : null
  );
  const [error, setError] = useState(null);

  //   const editUser = async () => {
  //     await fetch(`${API.endpointURL}/api/users/${applicant.id}`, {
  //       method: "PUT",
  //       body: JSON.stringify({
  //       }),
  //       headers: {
  //         "Authorization": `Bearer ${token}`,
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data["errors"]) {
  //           setError(data["errors"]);
  //         } else {
  //           getApplicant();
  //           handleClose();
  //           setOpenToast(true);
  //         }
  //       })
  //       .catch((err) => {
  //         setError(err.message);
  //       });
  //   };

  const editPlacementForm = () => {
    return (
      <React.Fragment>
        {error && <div className="add-user-error">{error}</div>}
        <div className="button-container">
          <Button
            variant="contained"
            className="add-user-button"
            sx={{
              backgroundColor: "#556155",
              color: "#EFEFEF",
              fontSize: "18px",
              width: "175px",
              "&:hover": { backgroundColor: "#a5af96", color: "#3e453d" },
            }}
            // onClick={() => editUser()}
          >
            Edit
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <CustomDialog
      dialogContent={editPlacementForm()}
      onClose={handleClose}
      open={open}
      title={"EDIT"}
      titleHighlight={"PLACEMENT"}
    />
  );
}
