import React, { useEffect, useState } from "react";

import "../../css/application.scss";
import "../../css/normalizeMuiLibrary.scss";
import "../../css/general-info-form.scss";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import API from "API";
import { ProgressSpinnerGreen } from "../progress/progress-spinner-green";
import { NavigateButtons } from "./navigateButtons";
import ReactQuill from "react-quill";

const theme = createTheme({
  palette: {
    primary: {
      main: "#dae0bc",
    },
  },
});

export default function ScreeningForm(props) {
  const { applicantData, setApplicantData, setView, contractId } = props;

  const [contractDivisions, setContractDivisions] = useState([]);
  const [withoutDivision, setWithoutDivision] = useState(false);
  const [withoutSubdivision, setWithoutSubdivision] = useState(false);
  const [divisionsLoading, setDivisionsLoading] = useState(false);
  const [regionSubdivisions, setRegionSubdivisions] = useState([]);
  const [division, setDivision] = useState(null);
  const [subdivision, setSubdivision] = useState(null);
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getDivisions(contractId);
  }, []);

  useEffect(() => {
    if (division) {
      getSubdivisions(division);
    }
  }, [division, contractDivisions]);

  useEffect(() => {
    setSubdivision(null);
    if (applicantData) {
      applicantData.region_id && setDivision(applicantData.region_id);
      applicantData.site_id && setSubdivision(applicantData.site_id);
    }
  }, [applicantData]);

  const findDescription = (id, array) => {
    return array.map(i => i.id === id ? i.job_description : null)
      .find(job_description => job_description !== null);
  }

  const getDivisions = async (id) => {
    setDivisionsLoading(true);
    try {
      const response = await fetch(`${API.endpointURL}/api/filter/regions?contract_id=${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        setContractDivisions([]);
        return;
      }

      const json = await response.json();
      setContractDivisions(json.data.filter((i) => i.status === 'open'));
      setDivisionsLoading(false);
    } catch (error) {
      setContractDivisions([]);
      setDivisionsLoading(false);
    }
  }

  const getSubdivisions = async (id) => {
    try {
      const response = await fetch(`${API.endpointURL}/api/filter/sites?region_id=${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        setRegionSubdivisions([]);
        return;
      }

      const json = await response.json();
      setRegionSubdivisions(json.data.filter((i) => i.status === 'open'));
    } catch (error) {
      setRegionSubdivisions([]);
    }
  }

  const saveData = () => {
    const updatedData = {
      ...applicantData,
      region_id: division,
      site_id: subdivision,
      contract_id: contractId,
    };
    setApplicantData(updatedData);
  };

  const handleBack = () => {
    saveData();
    setView("generalInfoForm");
  };

  const handleSaveForm = () => {
    saveData();
    setView("screeningConsent");
  };

  const stripHtmlTags = (htmlString) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || '';
  };

  if (divisionsLoading) {
    return (
      <div className="infoForm-container">
        <div className="spinner-fullScreen">
          <ProgressSpinnerGreen />
        </div>
      </div>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <section className="infoForm-container">
        <h1 className="infoForm-h1">Screening Form</h1>
        <p className="infoForm-p" style={{ maxWidth: "100%", textAlign: "center" }}>
          Please fill out all fields below and click "Next" to continue.
        </p>
        <article
          className="infoForm-column"
          style={{ gap: '15px', marginTop: '15px' }}
        >
          <div className="infoForm-column" style={{ gap: '20px' }}>
            <div className="infoForm-row" style={{ gap: '20px', alignItems: 'center' }}>
              <p className="infoForm-p">
                Select location you are interested in applying for:
              </p>
              <FormControl sx={{ width: '40%' }}>
                {!division && (
                  <InputLabel>
                    {contractDivisions.length === 0 ? 'N/A' : 'Choose division'}
                  </InputLabel>
                )}
                <Select
                  disabled={contractDivisions.length === 0}
                  value={withoutDivision ? 'No Division' : division ? division : ''}
                  onChange={(e) => {
                    if (e.target.value === 'No Division') {
                      setWithoutDivision(true);
                      setDivision(null);
                      setSubdivision(null);
                    } else {
                      setWithoutDivision(false);
                      setDivision(e.target.value);
                    }
                  }}
                  sx={{
                    backgroundColor: "#EFEFEF",
                    borderColor: '#262a25',
                    fontFamily: "Korolev Medium",
                    width: "100%",
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent'
                    },
                    "&.MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent"
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent"
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent"
                      }
                    }
                  }}
                >
                  {contractDivisions.length !== 0 &&
                    contractDivisions.map((contractDivision) =>
                    contractDivision.status === 'open' && (
                      <MenuItem
                        key={contractDivision.id}
                        value={contractDivision.id}
                        sx={{ fontFamily: "Korolev Medium" }}
                      >
                        {contractDivision.title
                          ? contractDivision.title
                          : "No Title"}
                      </MenuItem>
                    ))}
                  <MenuItem
                    value={'No Division'}
                    sx={{ fontFamily: "Korolev Medium" }}
                  >
                    No Division
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            {division &&
              contractDivisions &&
              stripHtmlTags(findDescription(division, contractDivisions)) !== "undefined" &&
              stripHtmlTags(findDescription(division, contractDivisions)).length !== 0 && (
                <ReactQuill
                  readOnly
                  theme="snow"
                  value={findDescription(division, contractDivisions)}
                  modules={{toolbar: false}}
                  className='quill-readOnly'
                  style={{
                    backgroundColor: '#262a25',
                    color: '#EFEFEF',
                  }}
                />
              )
            }
          </div>
          <Box className="infoForm-row" style={{ gap: '20px', alignItems: 'center' }}>
            <p className="infoForm-p">
              Select position you are interested in applying for:
            </p>
            <FormControl sx={{width: '40%'}}>
              {!subdivision && (
                <InputLabel>
                  {regionSubdivisions.length === 0 ? 'N/A' : 'Choose subdivision'}
                </InputLabel>
              )}

              <Select
                value={withoutSubdivision ? 'No Subdivision' : subdivision ? subdivision : ''}
                disabled={regionSubdivisions.length === 0}
                onChange={(e) => {
                  if (e.target.value === 'No Subdivision') {
                    setWithoutSubdivision(true);
                    setSubdivision(null);
                  } else {
                    setWithoutSubdivision(false);
                    setSubdivision(e.target.value);
                  }
                }}
                sx={{
                  backgroundColor: "#EFEFEF",
                  borderColor: '#262a25',
                  fontFamily: "Korolev Medium",
                  width: "100%",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent'
                  },
                  "&.MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent"
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent"
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent"
                    }
                  }
                }}
              >
                {regionSubdivisions.map((regionSubdivision) =>
                  regionSubdivision.status === 'open' &&
                  (
                    <MenuItem
                      key={regionSubdivision.id}
                      value={regionSubdivision.id}
                      sx={{ fontFamily: "Korolev Medium" }}
                    >
                      {regionSubdivision.title
                        ? regionSubdivision.title
                        : "No Title"}
                    </MenuItem>
                  )
                )}
                <MenuItem
                  value={'No Subdivision'}
                  sx={{ fontFamily: "Korolev Medium" }}
                >
                  No Subdivision
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {subdivision &&
            regionSubdivisions &&
            stripHtmlTags(findDescription(subdivision, regionSubdivisions)) !== "undefined" &&
            stripHtmlTags(findDescription(subdivision, regionSubdivisions)).length !== 0 && (
              <ReactQuill
                readOnly
                theme="snow"
                value={findDescription(subdivision, regionSubdivisions)}
                modules={{toolbar: false}}
                className='quill-readOnly'
                style={{
                  backgroundColor: '#262a25',
                  color: '#EFEFEF',
                }}
              />
            )
          }
        </article>
        <NavigateButtons
          back={() => handleBack()}
          next={() => handleSaveForm()}
        />
      </section>
    </ThemeProvider>
  );
}
