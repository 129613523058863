import "../../css/modal.scss";
import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import CustomDialog from "../modal/custom-dialog";
import { uploadTypeToFile } from "shared/vendor-data";

import API from "API";

export default function VendorFileCorrectionsDialog(props) {
  const {
    handleClose,
    open,
    pocUserId,
    file,
    vendorId,
    retrieveFiles,
    setOpenEmailToast,
  } = props;

  const token = JSON.parse(localStorage.getItem("token"));
  const fileType = uploadTypeToFile[file.type];

  const [emailSubject, setEmailSubject] = useState(
    `Document Revision Requested: ${fileType}`
  );
  const [emailBody, setEmailBody] = useState(
    `Good Morning, We are contacting you regarding a document you recently submitted during our data verification process. Please login to your vendor portal and make the following revisions to your ${fileType} document. (Please enter Revision Here) Thank you!`
  );
  const [error, setError] = useState(null);

  const validateForm = () => {
    let validForm = true;
    if (emailBody === "") validForm = false;
    if (emailSubject === "") validForm = false;

    return validForm;
  };

  const handleSendEmail = async () => {
    const validForm = validateForm();
    if (validForm) {
      const formData = new FormData();
      formData.append("addressee_id", pocUserId);
      formData.append("body", emailBody);
      formData.append("subject", emailSubject);

      await fetch(`${API.endpointURL}/api/send_email/`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            requestFileCorrections();
            setOpenEmailToast();
            handleClose();
          }
        })
        .then((data) => {
          if (data["errors"]) {
            setError(data["errors"]);
          } else {
            requestFileCorrections();
            setOpenEmailToast();
            handleClose();
          }
        })
        .catch((err) => {
          setError(err.messages);
        });
    } else {
      setError("Please provide an email subject and body.");
    }
  };

  const requestFileCorrections = () => {
    fetch(`${API.endpointURL}/api/request_correction?file_id=${file.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json",
      },
      body: JSON.stringify({
        vendor_id: vendorId,
        corrections_requested: true,
        file_id: file.id,
      }),
    })
      .then((res) => res.json())
      .then(() => {
        retrieveFiles();
      });
  };

  const buildEmailForm = () => {
    return (
      <Box>
        <div className="form-row-label" style={{ textAlign: "left" }}>
          Email Subject
        </div>
        <TextField
          value={emailSubject}
          onChange={(e) => setEmailSubject(e.currentTarget.value)}
          fullWidth
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #a5af96",
            borderRadius: "8px",
            padding: "6px 10px 5px",
            marginBottom: 1,
          }}
          placeholder="Subject..."
          multiline={true}
          rows={1}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <div className="form-row-label" style={{ textAlign: "left" }}>
          Email Body
        </div>
        <TextField
          value={emailBody}
          onChange={(e) => setEmailBody(e.currentTarget.value)}
          fullWidth
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #a5af96",
            borderRadius: "8px",
            padding: "6px 10px 5px",
            marginBottom: 1,
          }}
          placeholder="Message..."
          multiline={true}
          rows={5}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
        {error && <div className="add-user-error">{error}</div>}
        <div className="button-container">
          <Button
            variant="contained"
            className="add-user-button"
            sx={{
              backgroundColor: "#556155",
              color: "#EFEFEF",
              fontSize: "18px",
              width: "175px",
              "&:hover": { backgroundColor: "#a5af96", color: "#3e453d" },
            }}
            onClick={() => handleSendEmail()}
          >
            Send
          </Button>
        </div>
      </Box>
    );
  };

  return (
    <CustomDialog
      dialogContent={buildEmailForm()}
      onClose={handleClose}
      open={open}
      title={"REQUEST"}
      titleHighlight={"CORRECTIONS"}
    />
  );
}
