import React from 'react';
import Tabs from '@mui/material/Tabs';
import { StyledSalesTab } from './tab-styles';
import { useAppDispatch } from 'hooks/reduxHooks';
import { setCurrentTaskTab } from 'store/projects/slice';


export default function ProjectsTabs(props) {

  const { currentTab, tabs } = props;
  const dispatch = useAppDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setCurrentTaskTab(newValue));
  };

  return (
    <Tabs
      value={currentTab}
      onChange={handleChange}
      variant='scrollable'
      scrollButtons="auto"
      TabIndicatorProps={{
        style: { display: 'none' }
      }}
      TabScrollButtonProps={{
        style: {
          margin: "0 10px",
          width: "10px",
        }
      }}
    >
      {tabs.map(tab => (
        <StyledSalesTab label={tab} value={tab} key={tab} />
      ))}
    </Tabs>
  );
};
