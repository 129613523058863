import { axiosService } from 'services/axiosServices';

export const quotes = {
  getQuoteCategories: async (saleId) => {
    const res = await axiosService.get(`/api/filter/quote-categories?sales_id=${saleId}`);
    return res.data.data;
  },
  createQuoteCategory: async (body) => {
    const res = await axiosService.post(`/api/quote-categories`, body);
    return res.data;
  },
  updateQuoteCategory: async (categoryId, body) => {
    const res = await axiosService.patch(`/api/quote-categories/${categoryId}`, body);
    return res.data;
  },
  deleteQuoteCategory: async (categoryId) => {
    const res = await axiosService.delete(`/api/quote-categories/${categoryId}`);
    return res.data;
  },
}
