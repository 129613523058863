import "../../css/modal.scss";
import React, { useState } from "react";
import { Button } from "@mui/material";
import CustomDialogRedesign from "./custom-dialog-redesign";
import API from "API";

export default function DissociateFile(props) {
  const { handleClose, open, retrieveFiles, selectedFile, setOpenToast, setMessage } = props;

  const token = JSON.parse(localStorage.getItem("token"));

  const [error, setError] = useState(null);

  const disassociateFile = async () => {
    try {
      const response = await fetch(
        `${API.endpointURL}/api/files/disassociate_file`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
          body: JSON.stringify({
            file_id: selectedFile.id
          }),
        }
      );
      if (!response.ok) {
        const errorResponse = await response.json();
        setError(errorResponse || "Failed to delete file");
        throw new Error(errorResponse || "Failed to delete file");
      }
      await response.json();
      handleClose();
      setOpenToast(true);
      setMessage('Document removed');
      retrieveFiles();
    } catch (error) {
       setError(error.error_key || "Failed to delete file");
    }
  };

  const removeForm = () => {
    return (
      <>
        <div
          style={{
            fontFamily: "Korolev Medium",
            lineHeight: "25px",
            marginTop: "30px",
            textAlign: "left",
          }}
        >
          This will permanently remove {selectedFile.display_name} from the applicant's drop box.
          <br />
          Are you sure you want to remove this file?
        </div>
        {error && <div className="add-user-error">{error}</div>}
        <div className="button-container">
          <Button
            variant="contained"
            className="add-user-button"
            sx={{
              backgroundColor: "#00A5CF",
              border: "1px solid #00A5CF",
              borderRadius: "10px",
              color: "#EFEFEF",
              fontFamily: "MagistralCondW08-Medium",
              fontSize: "18px",
              minHeight: "25px",
              padding: "13px 20px",
              textTransform: "capitalize",
              width: "175px",
              "&:hover": {
                backgroundColor: "transparent",
                border: "1px solid #00A5CF",
                boxShadow: "none",
                color: "#00A5CF",
              },
            }}
            onClick={() => disassociateFile()}
          >
            Remove
          </Button>
        </div>
      </>
    );
  };

  return (
    <CustomDialogRedesign
      colorPalette="blue"
      dialogContent={removeForm()}
      onClose={handleClose}
      open={open}
      title={"REMOVE"}
      titleHighlight={"DOCUMENT"}
    />
  );
}
