import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import UploadFiles from "./upload-files";
import UploadFilesForm from "./upload-files-form";
import ViewFiles from "./view-files";
import { GrayAutoStyledButton } from "components/buttons/button-styles";
import { fileToUploadType } from "shared/applicant-data";
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { contractTemplatesSelector } from 'store/contracts/selectors';
import { applicantSelector } from 'store/applicants/selectors';
import { approveAllFiles, getApplicantFiles } from 'store/files/thunk';
import { applicantFilesSelector, filesLoadingSelector } from 'store/files/selectors';

export default function DropBox(props) {
  const { setError, setOpenErrorToast, userRole } = props;

  const dispatch = useAppDispatch();
  const applicant = useAppSelector(applicantSelector);
  const applicantFiles = useAppSelector(applicantFilesSelector);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const loading = useAppSelector(filesLoadingSelector);
  
  const templates = useAppSelector(contractTemplatesSelector);

  const [view, setView] = useState("all");

  useEffect(() => {
    retrieveFiles();
  }, []);

  const retrieveFiles = () => {
    dispatch(getApplicantFiles({ id: applicant?.id }));
  };

  const approveFiles = () => {
    dispatch(approveAllFiles({ id: applicant.id }))
  };

  const determineViewTypeHeader = () => {
    let header = view;
    const fileType = fileToUploadType[view];
    if (fileType) {
      let template = templates.find((template) => template.template_type === fileType);
      if (template) {
        header = template.template_name;
      }
    }
    return header;
  };

  return (
    <>
      <h1 className="content-header">Drop Box</h1>
      <div className="dropbox-content-container">
        <div
          style={{
            height: "100%",
            borderRight: "1px solid #efefef80",
            padding: "33px 22px 22px",
            width: "30%",
            overflowY: "auto",
          }}
        >
          <div>
            <div
              style={{
                fontFamily: "MagistralCondW08-Medium",
                fontSize: "25px",
              }}
            >
              Documents Menu
            </div>
            <Divider
              sx={{
                borderColor: "#efefef80",
                marginBottom: "25px",
                marginTop: "25px",
              }}
            />
            {(currentUser !== null && userRole !== 'applicant') && (
              <GrayAutoStyledButton
                sx={{
                  marginBottom: "16px",
                  width: "100%",
                }}
                onClick={approveFiles}
              >
                Mark All As Reviewed
              </GrayAutoStyledButton>
            )}
          </div>

          {loading ? (
            <ProgressSpinnerBlue />
          ) : (
            <UploadFiles
              applicant={applicant}
              applicantFiles={applicantFiles}
              setView={setView}
              view={view}
              templates={templates}
              currentUser={currentUser}
              showDeprecated={true}
              userRole={userRole}
            />
          )}

        </div>
        <div
          style={{
            height: "100%",
            overflowY: "auto",
            padding: "33px 45px 22px",
            position: "relative",
            width: "70%",
          }}
        >
          <div
            style={{
              fontFamily: "MagistralCondW08-Medium",
              fontSize: "25px",
            }}
          >
            {view === "all" && "View All Documents"}
            {view === "upload" && "Upload Document"}
            {view !== "all" && view !== "upload" && determineViewTypeHeader()}
          </div>
          <Divider
            sx={{
              borderColor: "#efefef80",
              marginBottom: "35px",
              marginTop: "25px",
            }}
          />
          {view !== "upload" ? (
            <ViewFiles
              applicantFiles={applicantFiles}
              setOpenErrorToast={setOpenErrorToast}
              setError={setError}
              view={view}
              currentUser={currentUser}
              retrieveFiles={retrieveFiles}
              userRole={userRole}
              loading={loading}
              templates={templates}
            />
          ) : (
            <UploadFilesForm
              applicant={applicant}
              retrieveFiles={retrieveFiles}
              templates={templates}
              userRole={userRole}
            />
          )}
          <div
            style={{
              position: "absolute",
              bottom: "22px",
              right: "45px",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {view !== "all" && (
              <GrayAutoStyledButton
                onClick={() => setView("all")}
                style={{ width: "165px" }}
              >
                View All
              </GrayAutoStyledButton>
            )}
            {view !== "upload" && (
              <GrayAutoStyledButton
                onClick={() => setView("upload")}
                style={{
                  marginLeft: "12px",
                  width: "165px"
                }}
              >
                Upload Document
              </GrayAutoStyledButton>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
