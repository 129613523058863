import React, { useEffect, useRef } from 'react';

import '../../css/table.scss';

import classNames from 'classnames';
import {
  Box,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  StyledTableContainer,
  StyledTableHeaderCell,
  StyledTableRow,
  StyledTableBodyCell,
} from './table-styles';
import StatusRow from './status-row';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import { currentPageSelector, currentTabSelector } from 'store/staffingSelectedOptions/selectors';
import {
  applicantsByStageSelector,
  currentApplicantSelector, editedApplicantIdSelector,
  totalApplicantsByStagePagesSelector,
} from 'store/applicants/selectors';
import { setApplicantState, setCurrentApplicant } from 'store/applicants/slice';
import { sidebarCollapseSelector } from 'store/ui/selectors';
import { setCurrentPage } from 'store/staffingSelectedOptions/slice';
import ProgressSpinner from 'components/progress/progress-spinner';

const stages = {
  1: 'New Applicants',
  2: 'Pre-Screening',
  3: 'Interview',
  4: 'USG Documents',
  5: 'Security',
  6: 'Finalize',
  7: 'Hired',
  8: 'Disposition',
};

const theme = createTheme({
  palette: {
    error: {
      main: '#B84A32',
    },
    primary: {
      main: '#EFEFEF80',
    },
    warning: {
      main: '#DFB727',
    },
  },
});

export default function StaffingApplicantTable() {
  const scrollRef = useRef(null);
  
  const dispatch = useAppDispatch();
  const applicants = useAppSelector(applicantsByStageSelector);
  const totalPages = useAppSelector(totalApplicantsByStagePagesSelector);
  const sidebarCollapse = useAppSelector(sidebarCollapseSelector);
  const currentTab = useAppSelector(currentTabSelector);
  const currentApplicant = useAppSelector(currentApplicantSelector);
  const currentPage = useAppSelector(currentPageSelector);
  const editedApplicantId = useAppSelector(editedApplicantIdSelector);
  
  useEffect(() => {
    if (currentApplicant) {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    } else {
      dispatch(setCurrentApplicant(applicants[0]));
    }
  }, [scrollRef?.current]);
  
  useEffect(() => {
    let applicantsData = applicants;
    if (applicantsData && applicantsData.length > 0) {
      if (currentApplicant) {
        const applicantIndex = applicantsData.findIndex(
          (applicant) => applicant.id === currentApplicant.id,
        );
        if (applicantIndex < 0) {
          dispatch(setCurrentApplicant(applicantsData[0]));
        }
      }
    } else if ((!applicants || applicants.length === 0) && currentApplicant) {
      dispatch(setCurrentApplicant(null));
      dispatch(setApplicantState(null));
    }
  }, [applicants, currentApplicant]);
  
  const buildApplicantList = (applicantsData) => {
    let list = [];
    
    applicantsData.forEach((applicant, index) => {
      const currentDivision = applicant.application?.region_title || 'No Division';
      const currentSubdivision = applicant.application?.site_title || 'No Subdivision';
      
      const textAlert = applicant.application?.text_alert;
      const psqAlert = applicant.application?.document_alert;
      
      list.push(
        <StyledTableRow
          key={applicant.id}
          onClick={() => dispatch(setCurrentApplicant(applicant))}
          selected={currentApplicant && applicant.id === currentApplicant.id}
          ref={currentApplicant && applicant.id === currentApplicant.id ? scrollRef : null}
          fullWidth={sidebarCollapse}
        >
          <StyledTableBodyCell
            firstcell={'true'}
            lastrow={index === applicantsData.length - 1 ? 'true' : 'false'}
          >
            <StatusRow hasMessage={textAlert} hasFiles={psqAlert}/>
          </StyledTableBodyCell>
          
          <StyledTableBodyCell
            lastrow={index === applicantsData.length - 1 ? 'true' : 'false'}
            sx={{ fontSize: '1.15rem', fontWeight: '500' }}
          >
            {`${applicant.first_name} ${applicant.last_name}`}
          </StyledTableBodyCell>
          <StyledTableBodyCell lastrow={index === applicantsData.length - 1 ? 'true' : 'false'}>
            {currentApplicant && editedApplicantId === applicant.id
              ? <></>
              : applicant.company ? `${applicant.company}` : 'None'
            }
          </StyledTableBodyCell>
          <StyledTableBodyCell
            style={{ position: 'relative', height: '90px', width: '185px' }}
            lastcell={currentTab !== 0 ? 'true' : 'false'}
            lastrow={index === applicantsData.length - 1 ? 'true' : 'false'}
          >
            {currentApplicant && editedApplicantId === applicant.id ? (
              <span style={{ position: 'absolute', top: -20, left: '40%', minWidth: '180px' }}>
                <ProgressSpinner />
              </span>
            ) : (
              <>
                {currentDivision}
                <Divider sx={{ borderColor: '#EFEFEF80', margin: '2px 0 5px' }}/>
                {currentSubdivision}
              </>
            )}
          </StyledTableBodyCell>
          {currentTab === 0 && (
            <StyledTableBodyCell
              lastcell="true"
              lastrow={index === applicantsData.length - 1 ? 'true' : 'false'}
            >
              {currentApplicant && editedApplicantId === applicant.id
                ? <></>
                : applicant.application?.stage &&
                stages[applicant.application.stage]
              }
            </StyledTableBodyCell>
          )}
        </StyledTableRow>,
      );
    });
    return list;
  };
  
  return (
    <ThemeProvider theme={theme}>
      <Box className={classNames('staffing__table', {
        'staffing__table--collapsed': sidebarCollapse,
      })}>
        <StyledTableContainer
          sx={{ height: 'calc(100vh - 234px)' }}
          className="scroll"
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell firstcell={'true'}/>
                <StyledTableHeaderCell sx={{ minWidth: '151px' }}>Name</StyledTableHeaderCell>
                <StyledTableHeaderCell>Vendor</StyledTableHeaderCell>
                <StyledTableHeaderCell
                  sx={{ minWidth: '180.75px' }}
                  lastcell={currentTab !== 0 ? 'true' : 'false'}
                >
                  Placement
                </StyledTableHeaderCell>
                {currentTab === 0 && (
                  <StyledTableHeaderCell lastcell="true">Stage</StyledTableHeaderCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {applicants && buildApplicantList(applicants)}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Stack
          sx={{
            alignItems: 'center',
            marginTop: 1.5,
          }}
        >
          <Pagination
            count={+totalPages}
            page={+currentPage}
            onChange={(e, value) => dispatch(setCurrentPage(value))}
            color="primary"
            size="large"
          />
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
