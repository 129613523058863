import React, { useState } from 'react';

import classNames from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MailLockIcon from '@mui/icons-material/MailLock';
import TopicIcon from '@mui/icons-material/Topic';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import Tooltip from '@mui/material/Tooltip';

import Toast from 'components/toast/toast';
import EditContractEmailTemplate from 'components/contract-portal/edit-contract-email-template';
import ProgressSpinner from 'components/progress/progress-spinner';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setCurrentContractTemplate } from 'store/contracts/slice';
import {
  contractTemplatesSelector,
  currentContractTemplateSelector,
  loadingTemplatesSelector,
} from 'store/contracts/selectors';

export default function ContractEmailTemplates({ getTemplates, loadedTemplates }) {
  const templates = useAppSelector(contractTemplatesSelector);
  
  const categories = [
    {
      name: 'Application',
      stages: [...templates].filter(template => template.template_type.startsWith('has_resume') || template.template_type.startsWith('no_resume')),
    },
    {
      name: 'New Applicant',
      stages: [...templates].filter(template => template.template_type.startsWith('new_applicant')),
    },
    {
      name: 'Pre-Screening',
      stages: [...templates].filter(template => template.template_type.startsWith('pre_screening')),
    },
    {
      name: 'Interview',
      stages: [...templates].filter(template => template.template_type.startsWith('interview')),
    },
    {
      name: 'USG Documents',
      stages: [...templates].filter(template => template.template_type.startsWith('usg_documents')),
    },
    {
      name: 'Security',
      stages: [...templates].filter(template => template.template_type.startsWith('security')),
    },
    {
      name: 'Finalize',
      stages: [...templates].filter(template => template.template_type.startsWith('finalize')),
    },
    {
      name: 'Hired',
      stages: [...templates].filter(template => template.template_type.startsWith('hired')),
    },
  ];
  
  const dispatch = useAppDispatch();
  const currentTemplate = useAppSelector(currentContractTemplateSelector);
  const loadingTemplates = useAppSelector(loadingTemplatesSelector);
  
  const [openCards, setOpenCards] = useState(categories.map((category) => category.name));
  
  const [openTemplateToast, setOpenTemplateToast] = useState(false);
  
  const handleTemplateUpdate = () => {
    setOpenTemplateToast(true);
    getTemplates();
  };
  
  const toggleCard = (name) => {
    if (openCards.includes(name)) {
      setOpenCards(openCards.filter((cardId) => cardId !== name));
    } else {
      setOpenCards([...openCards, name]);
    }
  };
  
  return (
    <>
      <h1 className="content-header">Email Templates</h1>
      <section className="emailTemplates">
        <div className="emailTemplates__templates">
          {loadingTemplates && !loadedTemplates ? (
            <div style={{ margin: 'auto' }}>
              <ProgressSpinner />
            </div>
          ) : (
            categories?.map((category) => (
              <div
                className="emailTemplates__categories"
                key={category.name}
                style={{ maxHeight: openCards.includes(category.name) ? 'inherit' : '45px' }}
              >
                <div className="emailTemplates__categories-header">
                  <p className="emailTemplates__templates-category">{category.name}</p>
                  <IconButton
                    edge="start"
                    color="inherit"
                    sx={{ color: '#fdfefe', padding: 0, width: '25px' }}
                    onClick={() => toggleCard(category.name)}
                  >
                    <KeyboardArrowDownIcon
                      sx={{
                        transform: openCards.includes(category.name) ? 'rotate(180deg)' : '',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />
                  </IconButton>
                </div>
                <div
                  className={classNames({
                    'emailTemplates__stages': openCards.includes(category.name),
                    'emailTemplates__stages--hidden': !openCards.includes(category.name),
                  })}
                >
                  {category.stages.map((stage) => (
                    <div
                      onClick={() => {
                        dispatch(setCurrentContractTemplate(stage));
                      }}
                      className={classNames('emailTemplates__stage', {
                        'emailTemplates__stage-active': currentTemplate?.id === stage.id,
                      })}
                      key={stage.id}
                    >
                      <div className="emailTemplates__stage-images">
                        <Tooltip title={stage.active ? 'Active - Enabled' : 'Active - Disabled'}>
                          <IconButton
                            sx={{
                              padding: 0,
                              '&:hover': { backgroundColor: 'transparent', cursor: 'inherit' },
                            }}
                          >
                            {stage.active ? (
                              <CheckCircleIcon sx={{ color: '#62AB37' }}/>
                            ) : (
                              <DoNotDisturbOnIcon sx={{ color: '#a20601' }}/>
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={stage.email_active ? 'Email - Enabled' : 'Email - Disabled'}>
                          <IconButton
                            sx={{
                              padding: 0,
                              '&:hover': { backgroundColor: 'transparent', cursor: 'inherit' },
                            }}
                          >
                            {stage.email_active ? (
                              <MarkEmailReadIcon sx={{ color: '#62AB37' }}/>
                            ) : (
                              <MailLockIcon sx={{ color: '#a20601' }}/>
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={stage.file_active ? 'File - Enabled' : 'File - Disabled'}>
                          <IconButton
                            sx={{
                              padding: 0,
                              '&:hover': { backgroundColor: 'transparent', cursor: 'inherit' },
                            }}
                          >
                            <TopicIcon sx={{ color: stage.file_active ? '#62AB37' : '#a20601' }}/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={stage.is_file_public ? 'File Public - Enabled' : 'File Public - Disabled'}>
                          <IconButton
                            sx={{
                              padding: 0,
                              '&:hover': { backgroundColor: 'transparent', cursor: 'inherit' },
                            }}
                          >
                            <PeopleAltIcon sx={{ color: stage.is_file_public ? '#62AB37' : '#a20601' }}/>
                          </IconButton>
                        </Tooltip>
                      </div>
                      <Tooltip title={stage.template_type}>
                        <p className="emailTemplates__templates-stage">{stage.template_name}</p>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
        {currentTemplate && (
          <EditContractEmailTemplate handleTemplateUpdate={handleTemplateUpdate}/>
        )}
      </section>
      {openTemplateToast && (
        <Toast
          open={openTemplateToast}
          onClose={() => setOpenTemplateToast(false)}
          message="Email template saved"
        />
      )}
    </>
  );
};
