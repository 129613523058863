import "../../../css/analytics.scss";
import "../../../css/pages.scss";
import "../../../css/metrics.scss";
import React, { useState } from 'react';

export default function AccountingGroupingView(props) {

  const {
    breakdown,
    lastUpdate,
    selectedGrouping,
    setSelectedGrouping
  } = props;
  const [openedTables, setOpenedTables] = useState([]);

  const handleSeeMore = (key) => {
    if (openedTables.includes(key)) {
      setOpenedTables(openedTables.filter((item) => item !== key));
    } else {
      setOpenedTables([...openedTables, key]);
    }
  }

  const renderLedgerEntries = (ledgerEntries) => {
    let entryRows = [];
    if (ledgerEntries) {
      {
        ledgerEntries.forEach((entry, index) => {
          let dimensions = "";
          if (entry.dimensions) {
            for (const [key, value] of Object.entries(entry.dimensions)) {
              if (dimensions) {
                dimensions += `, ${key}: ${value}`;
              } else {
                dimensions = `${key}: ${value}`;
              }
            }
          }
          entryRows.push(
            <tr key={index} className="row-border">
              <td className="contract-data" style={{ paddingLeft: "0" }}>{entry.date}</td>
              <td className="contract-data">{entry.accountName}</td>
              <td className="contract-data">
                ${entry.balance && entry.balance.toLocaleString('en', { useGrouping:true })}
              </td>
              <td className="contract-data">{entry.description}</td>
              <td className="contract-data">{entry.documentNumber}</td>
              <td className="contract-data">{dimensions}</td>
              <td className="contract-data">{entry.balAccountNumber || 'N/A'}</td>
            </tr>
          );
        });
      }
    }
    return <>{entryRows}</>;
  }

  const renderBreakdown = () => {
    let accountBreakdown = [];
    if (breakdown && breakdown["accounts"]) {
      for (const [key, value] of Object.entries(breakdown["accounts"])) {
        accountBreakdown.push(
          <div className="contract-form-section-content">
            <div
              style={{
                backgroundColor: "#EFEFEF40",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                fontFamily: "MagistralCondW08-Medium",
                fontSize: "23px",
                justifyContent: "space-between",
                padding: "20px",
                width: "100%",
              }}
            >
              {key}
              <div className="stage-stat-header" style={{ marginBottom: "0" }}>
                Net Change: ${value["netChange"] && value["netChange"].toLocaleString('en', { useGrouping:true })}
              </div>
              <div className="stage-stat-header" style={{ marginBottom: "0" }}>
                Total: ${value["total"] && value["total"].toLocaleString('en', { useGrouping:true })}
              </div>

            </div>
            {value["ledgerEntries"].length > 0 ? (
              <table
                className={`contracts-table ${openedTables.includes(key) && 'contracts-table-open'}`}
              >
                <thead>
                  <tr className="row-border">
                    <th scope="col" className="contracts-header" style={{ fontSize: "18px", paddingLeft: "0" }}>
                      Posting Date
                    </th>
                    <th scope="col" className="contracts-header" style={{ fontSize: "18px" }}>
                      Account Name
                    </th>
                    <th scope="col" className="contracts-header" style={{ fontSize: "18px" }}>
                      Amount
                    </th>
                    <th scope="col" className="contracts-header" style={{ fontSize: "18px" }}>
                      Description
                    </th>
                    <th scope="col" className="contracts-header" style={{ fontSize: "18px" }}>
                      Document No.
                    </th>
                    <th scope="col" className="contracts-header" style={{ fontSize: "18px" }}>
                      Dimensions
                    </th>
                    <th scope="col" className="contracts-header" style={{ fontSize: "18px" }}>
                      Bal. Account No.
                    </th>
                  </tr>
                </thead>
                {value["ledgerEntries"].length > 4 ? (
                  <>
                    <tbody
                      className={`
                    contracts-tale-body 
                    ${openedTables.includes(key) && 'contracts-tale-body-open'}
                  `}
                    >
                    {openedTables.includes(key)
                      ? renderLedgerEntries([...value["ledgerEntries"]]
                        .sort((a, b) => b.date.localeCompare(a.date)))
                      : renderLedgerEntries([...value["ledgerEntries"]]
                        .sort((a, b) => b.date.localeCompare(a.date))
                        .slice(0, 3))

                    }
                    </tbody>
                  </>
                ) : (
                  <tbody>
                  {renderLedgerEntries([...value["ledgerEntries"]]
                    .sort((a, b) => b.date.localeCompare(a.date)))}
                  </tbody>
                )}
              </table>
            ) : (
              <div style={{ fontFamily: "Korolev Medium", marginTop: "25px" }}>
                No ledger entries are available for {key}.
              </div>
            )}

            {value["ledgerEntries"].length > 4 && (
              <button
                onClick={() => handleSeeMore(key, value["ledgerEntries"].length)}
                className="contracts-button"
              >
                {openedTables.includes(key) ? (
                  <>
                    See less
                    <i
                      className="fa-solid fa-angle-up blue-arrow"
                      style={{ marginLeft: "12px" }}
                    />
                  </>
                ) : (
                  <>
                    See more
                    <i
                      className="fa-solid fa-angle-down blue-arrow"
                      style={{ marginLeft: "12px" }}
                    />
                  </>
                )}
              </button>
            )}
          </div>
        );
      }
    }
    return (
      <>{accountBreakdown}</>
    );
  };

  return (
    <>
      <div className="content-header content-header-flex">
        <div style={{ display: "flex", alignItems: "center" }}>
          <i
            className={`fa-solid fa-angle-left blue-arrow analytics-back-button`}
            onClick={() => setSelectedGrouping(null)}
          />
          <h1>{selectedGrouping}</h1>
        </div>
        <div
          style={{
            fontFamily: "Korolev Medium",
            fontSize: "14px",
            textAlign: "right",
            textTransform: "none",
            width: "25%",
            color: '#efefef',
          }}
        >
          Last updated on {new Date(lastUpdate).toLocaleString()}
        </div>
      </div>
      <div className="metrics-content-container">
        <div className="contract-form-section">
          <div className="contract-form-section-content">
            <div className="stage-stat-container" style={{ width: "auto" }}>
              <div className="stage-stat-header">Net Change</div>
              <div className="stage-stat-number">
                ${breakdown["netChange"] && breakdown["netChange"].toLocaleString('en', { useGrouping:true })}
              </div>
            </div>
            <div className="stage-stat-container" style={{ width: "auto" }}>
              <div className="stage-stat-header">Total</div>
              <div className="stage-stat-number">
                ${breakdown["total"] && breakdown["total"].toLocaleString('en', { useGrouping:true })}
              </div>
            </div>
          </div>
        </div>
        <div className="contract-form-section">
          <div className="contract-form-section-header header-row">
            Breakdown
          </div>
          {breakdown && renderBreakdown()}
        </div>
      </div>
    </>
  );
};
