import React, { useState, useEffect } from 'react';

import { TextField, Select, MenuItem } from '@mui/material';

import API from 'API';

import { applicantFilesList, uploadTypeToFile } from 'shared/applicant-data';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

export const EditFileModal = ({
  fileId,
  type,
  name,
  retrieveFiles,
  close,
  setMessage,
  setOpenToast,
  templates,
  applicantFiles,
  userRole,
}) => {
  const token = JSON.parse(localStorage.getItem('token'));

  const [fileDisplayName, setFileDisplayName] = useState(name || '');
  const [fileType, setFileType] = useState(type);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dropboxUploadKey, setDropboxUploadKey] = useState(Date.now());
  const [deprecatedFolders, setDeprecatedFolders] = useState([]);

  const filteredTemplates = templates.filter((template) => {
    if (userRole === 'applicant') {
      return template.is_file_public
        && template.active
        && template.file_active === true
        && template.template_type !== "has_resume"
        && template.template_type !== "no_resume"
    } else {
      return template.active
        && template.file_active === true
        && template.template_type !== "has_resume"
        && template.template_type !== "no_resume"
    }
  });

  useEffect(() => {
    const filteredFiles = applicantFiles.filter((item) =>
      applicantFilesList['Legacy Information'].includes(uploadTypeToFile[item.type]));
    if (filteredFiles.length) {
      setDeprecatedFolders(filteredFiles);
    }
  }, [applicantFiles])

  const editFile = async () => {
    setLoading(true);
    const body = {};
    if (fileDisplayName !== name) {
      body.display_name = fileDisplayName;
    }
    if (fileType !== type) {
      body.type = fileType;
    }

    await fetch(
      `${API.endpointURL}/api/edit-file/${fileId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json; charset=UTF-8',
          Accept: 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        if (data['errors']) {
          setError(data['errors']);
        } else {
          setDropboxUploadKey(Date.now());
          setLoading(false);
          retrieveFiles();
          close();
          setOpenToast(true);
          setMessage('Document successfully updated!');
        }
      })
      .catch(() => {
        setError('Something went wrong, please try again.');
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        maxHeight: '100%',
        overflow: 'auto',
        width: '100%',
      }}
    >
      {loading ? (
        <div className="contracts-loading-spinner" style={{ height: '265px' }}>
          <ProgressSpinnerBlue />
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
            }}
          >
            <div className="contract-form-set-full">
              <div className="form-row-label">Document Name</div>
              <TextField
                value={fileDisplayName}
                onChange={(e) => setFileDisplayName(e.target.value)}
                fullWidth
                sx={{
                  backgroundColor: '#fff',
                  border: '1px solid #a5af96',
                  borderRadius: '8px',
                  padding: '6px 10px 5px',
                  marginBottom: 0,
                }}
                placeholder="Enter Document Type..."
                key={`file-name-${dropboxUploadKey}`}
                multiline={true}
                rows={1}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="contract-form-set-full">
              <div className="form-row-label">Document Type</div>
              <Select
                key={`file-type-${dropboxUploadKey}`}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fileType}
                onChange={(e) => setFileType(e.target.value)}
                sx={{
                  backgroundColor: '#fff',
                  border: 0,
                  borderRadius: '8px',
                  textAlign: 'left',
                  width: '100%',
                  '& .MuiSelect-outlined.MuiOutlinedInput-input': {
                    paddingTop: '10.8px',
                    paddingBottom: '11px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #a5af96',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #a5af96',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#a5af96',
                  },
                }}
                displayEmpty
              >
                <MenuItem value="resume">Resume</MenuItem>
                <MenuItem value="other">Other</MenuItem>

                {filteredTemplates.map((template) => (
                  <MenuItem key={template.template_type} value={template.template_type}>
                    {template.template_name}
                  </MenuItem>
                ))}
                {deprecatedFolders.map((file) => (
                  <MenuItem key={file.type} value={file.type}>
                    {uploadTypeToFile[file.type]}
                  </MenuItem>
                ))}
              </Select>

            </div>
          </div>
          {error && (
            <div
              style={{
                color: '#a20601',
                fontFamily: 'Korolev Medium',
                fontSize: '14px',
                marginBottom: '16px',
                textAlign: 'left',
              }}
            >
              {error}
            </div>
          )}
          <div
            style={{
              textAlign: 'left',
              width: '100%',
            }}
          >

            <button
              className="create-button"
              style={{
                marginRight: '14px',
                backgroundColor: '#00A5CF',
                borderColor: '#00A5CF',
              }}
              onClick={editFile}
            >
              Save
            </button>

          </div>
        </>
      )}
    </div>
  );
};
