export const vendorFilesList = {
  "General Documents": ["W9", "Services Information", "General Miscellaneous"],
  "Insurance Documents": [
    "Certificate of Insurance",
    "General Liability",
    "Auto Liability",
    "Insurance Miscellaneous",
    "Workers Comp Waiver",
    "30-Day Notice of Cancellation",
  ],
  "Additional Documents": [
    "Miscellaneous 01",
    "Miscellaneous 02",
    "Miscellaneous 03",
  ],
};

export const fileToUploadType = {
  W9: "w9",
  "Services Information": "services_info",
  "General Miscellaneous": "general_misc",
  "Certificate of Insurance": "certificate_of_insurance",
  "General Liability": "general_liability",
  "Auto Liability": "auto_liability",
  "Insurance Miscellaneous": "insurance_misc",
  "Workers Comp Waiver": "workers_comp_waiver",
  "30-Day Notice of Cancellation": "notice_of_cancellation",
  "Miscellaneous 01": "misc_1",
  "Miscellaneous 02": "misc_2",
  "Miscellaneous 03": "misc_3",
};

export const uploadTypeToFile = {
  w9: "W9",
  services_info: "Services Information",
  general_misc: "General Miscellaneous",
  certificate_of_insurance: "Certificate of Insurance",
  general_liability: "General Liability",
  auto_liability: "Auto Liability",
  insurance_misc: "Insurance Miscellaneous",
  workers_comp_waiver: "Workers Comp Waiver",
  notice_of_cancellation: "30-Day Notice of Cancellation",
  misc_1: "Miscellaneous 01",
  misc_2: "Miscellaneous 02",
  misc_3: "Miscellaneous 03",
};
