import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, Typography } from '@mui/material';
import { adminErrorMessages } from 'shared/error-messages';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';

export default function ApplicationError(props) {
  const { errors, setView } = props;
  const navigate = useNavigate();

  const determineErrorMessage = () => {
    let errorMessage = '';
    let emailError = errors['email'] ? adminErrorMessages[errors['email']] : '';
    let phoneError = errors['phone'] ? adminErrorMessages[errors['phone']] : '';
    if (emailError === 'This email is already linked to another user' || phoneError === 'This phone number is already linked to another user') {
      errorMessage = `You already have a profile with us!`;
    } else if (emailError || phoneError) {
      if (emailError) {
        errorMessage += `${emailError}. `;
      }
      if (phoneError) {
        errorMessage += `${phoneError}. `;
      }
    }else{
      errorMessage += `${errors}. `;
    }
    return errorMessage.length > 0 ? errorMessage : 'There was an error processing your application.';
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <ErrorIcon
        sx={{
          color: '#a5af96',
          fontSize: '56px',
          margin: '15px 0',
        }}
      />
      <Typography
        sx={{
          color: '#dae0bc',
          fontFamily: 'MagistralCondW08-Medium',
          fontSize: '35px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '50px',
          textTransform: 'uppercase',
        }}
      >
        We're Sorry!
      </Typography>
      <Typography
        sx={{
          color: '#EFEFEF',
          fontFamily: 'Korolev Medium',
          fontSize: '16px',
          lineHeight: '35px',
          margin: '30px auto 75px',
          maxWidth: '80%',
        }}
      >
        {
          errors 
          ? (
            <>
              {determineErrorMessage()}
              <br/>
              Please {' '}
              <Link
                sx={{
                  color: '#a5af96',
                  transition: 'all, 0.3s',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                  ':not(.ql-editor a)': {
                    display: 'inline'
                  }
                }}
                onClick={() => navigate("/login")}
              >
                <b>login</b>
              </Link>,
              try again or email us at {' '}
              <Link
                sx={{
                  color: '#a5af96',
                  transition: 'all, 0.3s',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                  ':not(.ql-editor a)': {
                    display: 'inline'
                  }
                }}
                href="mailto:rdmstaffing@responsivedeployment.com"
                target="_blank"
                rel="noreferrer noopener"
              ><b>rdmstaffing@responsivedeployment.com </b></Link>
               if the issue continues.
            </>
          ) : (
            <>
              There was an error processing your application.
              <br/>
              Please try again or email us at {' '}
              <Link
                sx={{
                  color: '#a5af96',
                  transition: 'all, 0.3s',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                  ':not(.ql-editor a)': {
                    display: 'inline'
                  }
                }}
                href="mailto:rdmstaffing@responsivedeployment.com"
                target="_blank"
                rel="noreferrer noopener"
              ><b>rdmstaffing@responsivedeployment.com </b></Link>
              if the issue continues.
            </>
          )
        }
      </Typography>
      <div style={{ height: 'calc(100vh - 500px)' }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#dae0bc",
            color: "#3e453d",
            fontFamily: 'MagistralCondW08-Medium',
            fontSize: "18px",
            width: "175px",
            "&:hover": { backgroundColor: "#a5af96", color: "#3e453d" },
          }}
          onClick={() => setView('generalInfoForm')}
        >
          Try again
        </Button>
      </div>
    </Box>
  );
};
