import { createSlice } from '@reduxjs/toolkit';
import { createTask, updateTask } from 'store/tasks/thunk';

const initialState = {
  task: {
    selectedTask: null,
    updateTaskLoading: false,
    updateTaskError: '',
    createTaskLoading: false,
    createTaskError: '',
  }
};

const tasksSlice = createSlice({
  name: 'tasksSlice',
  initialState,
  reducers: {
    setSelectedTask(state, { payload }) {
      state.task.selectedTask = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateTask.pending, (state) => {
      state.task.updateTaskLoading = true;
      state.task.updateTaskError = '';
    });
    builder.addCase(updateTask.fulfilled, (state, { payload }) => {
      state.task.updateTaskLoading = false;
      state.task.updateTaskError = '';
      state.task.selectedTask = payload;
    });
    builder.addCase(updateTask.rejected, (state) => {
      state.task.updateTaskLoading = false;
      state.task.updateTaskError = 'Error Updating Task';
    });
    
    builder.addCase(createTask.pending, (state) => {
      state.task.createTaskLoading = true;
      state.task.createTaskError = '';
    });
    builder.addCase(createTask.fulfilled, (state, { payload }) => {
      state.task.createTaskLoading = false;
      state.task.createTaskError = '';
      state.task.selectedTask = payload;
    });
    builder.addCase(createTask.rejected, (state) => {
      state.task.createTaskLoading = false;
      state.task.createTaskError = 'Error Creating Task';
    });
  },
});

export const { setSelectedTask } = tasksSlice.actions;

export default tasksSlice.reducer;
