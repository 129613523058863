import React, { useState } from 'react';

import 'css/sales.scss';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { useAppSelector } from 'hooks/reduxHooks';
import { saleSelector, salesRepsLoadingSelector, salesRepsSelector } from 'store/sales/selectors';

const goalsToAchieve = ['lead', 'opportunity', 'contract', 'award'];

export const SalesMilestones = () => {

  const { saleTasks, saleTasksLoading } = useAppSelector(saleSelector);
  const [activeTask, setActiveTask] = useState(null);
  const salesReps = useAppSelector(salesRepsSelector);
  const loadingReps = useAppSelector(salesRepsLoadingSelector);

  const filterTasks = (goal) => {
    return saleTasks
      ?.filter((task) => task.goal_to_achieve === goal);
  };

  const completeCheck = (goal) => {
    return filterTasks(goal)?.every((task) => task.status === 'completed');
  };

  const findSaleRep = (id) => {
    const currentRep = salesReps?.find((rep) => rep.id === id);
    return `${currentRep.first_name} ${currentRep.last_name}`;
  };

  const sortTasks = (goal) => {
    return filterTasks(goal)
      ?.sort((a, b) => {
        if (!a.date_due && !b.date_due) {
          return 0;
        } else if (!a.date_due) {
          return 1;
        } else if (!b.date_due) {
          return -1;
        }
        return b.date_due.localeCompare(a.date_due);
      });
  };

  return (
    <div>
      <h1 className="content-header">Milestone Tracking System</h1>
      <div className="sales__milestone">
        {saleTasksLoading ? (
          <div className="content-loading">
            <ProgressSpinnerNavy/>
          </div>
        ) : (
          <>
            <div className="sales__milestone__side-bar">
              {goalsToAchieve?.map((goal) => (
                <div className="sales__milestone__tasks" key={goal} style={{ gap: '20px' }}>
                  <p
                    className={`sales__milestone__stage`}
                  >
                    <span className="sales__milestone__check--big">
                      {completeCheck(goal) && <span className="sales__milestone__box--big"/>}
                    </span>
                    <span className="sales__milestone__title">{goal}</span>
                  </p>
                  {sortTasks(goal)
                    ?.map((task) => (
                      <button
                        key={task.id}
                        onClick={() => {
                          setActiveTask(task);
                        }}
                        className={`
                          sales__milestone__task
                          ${activeTask && activeTask.id === task.id && 'sales__milestone--active'}
                        `}
                      >
                        <span className="sales__milestone__check">
                          {task.status === 'completed' && <span className="sales__milestone__box"/>}
                        </span>
                        <p className="sales__milestone__title">{task.name}</p>
                      </button>
                    ))
                  }
                </div>
              ))}
            </div>
            <div className="sales__milestone__task-description">
              {(loadingReps) ? (
                <div className="content-loading">
                  <ProgressSpinnerNavy/>
                </div>
              ) : activeTask && (
                <div className="sales__milestone__task-info">
                  <h2>{activeTask.name}</h2>
                  {activeTask.description && (
                    <p>
                      <b>
                        Description:
                      </b>
                      <br/>
                      {' ' + activeTask.description}
                    </p>
                  )}
                  {activeTask.status && (
                    <p>
                      <b>
                        Status:
                      </b>
                      <br/>
                      <span style={{ textTransform: 'capitalize' }}>
                      {' ' + activeTask.status}
                    </span>
                    </p>
                  )}
                  {activeTask.date_due && (
                    <p>
                      <b>
                        Task due date:
                      </b>
                      <br/>
                      {' ' + activeTask.date_due}
                    </p>
                  )}
                  {activeTask.personnel_assigned_id && (
                    <p>
                      <b>
                        Personnel assigned:
                      </b>
                      <br/>
                      {' ' + findSaleRep(activeTask.personnel_assigned_id)}
                    </p>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};