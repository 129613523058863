import React from "react";
import { Button, Divider } from "@mui/material";
import fileOutlineIcon from "assets/file_outline.svg";
import { vendorFilesList } from "shared/vendor-data";

export default function VendorFilesSidebar(props) {
  const { view, setView } = props;

  const buildFilesMenu = (category) => {
    let filesMenu = [];
    let files = vendorFilesList[category];

    files.forEach((file, fileIndex) => {
      filesMenu.push(
        <div key={fileIndex}>
          <Button
            variant="text"
            onClick={() => setView(file)}
            style={
              view === file
              ? { 
                background: "linear-gradient(90deg,rgba(255, 255, 255, 0.2) 0%,rgba(255, 255, 255, 0) 100%)",
                color: "white",
                fontFamily: "Korolev Medium",
                justifyContent: "left",
                textTransform: "none",
                width: "100%",
                "&:hover": { backgroundColor: "transparent" }
              }
              : { 
                color: "white",
                fontFamily: "Korolev Medium",
                justifyContent: "left",
                textTransform: "none",
                width: "100%",
                "&:hover": { backgroundColor: "transparent" }
              }
            }
          >
            <img src={fileOutlineIcon} alt="file-icon" style={{ width: "13px", height: "auto", marginRight: "10.25px" }} />
            {file}
          </Button>
        </div>
      );
    });
    return (
      <React.Fragment>
        {filesMenu}
      </React.Fragment>
    );
  };

  return (
    <div
      style={{
        height: "100%",
        borderRight: "1px solid #8b8b8b",
        padding: "33px 22px 22px",
        width: "30%",
        overflowY: "auto"
      }}
    >
      <div style={{ fontFamily: "MagistralCondW08-Medium", fontSize: "25px" }}>Upload Files</div>
      <Divider sx={{ borderColor: "#8b8b8b", marginBottom: "40px", marginTop: "25px" }} />
      <div style={{ fontSize: "20px" }}>General Documents</div>
      <Divider sx={{ borderColor: "#8b8b8b", marginBottom: "20px", marginTop: "15px" }} />
      {buildFilesMenu("General Documents")}
      <div style={{ fontSize: "20px", marginTop: "20px" }}>Insurance Documents</div>
      <Divider sx={{ borderColor: "#8b8b8b", marginBottom: "20px", marginTop: "15px" }} />
      {buildFilesMenu("Insurance Documents")}
      <div style={{ fontSize: "20px", marginTop: "20px" }}>Additional Documents</div>
      <Divider sx={{ borderColor: "#8b8b8b", marginBottom: "20px", marginTop: "15px" }} />
      {buildFilesMenu("Additional Documents")}
    </div>
  );
};
