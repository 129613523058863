import "css/analytics.scss";
import React, { useEffect, useState } from "react";
import FillBarSolid from "components/widgets/fill-bar-solid";
import API from "API";
import ProgressSpinner from 'components/progress/progress-spinner';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

export default function RecruiterStats({ recruiter, color }) {
  const token = JSON.parse(localStorage.getItem("token"));
  
  const [currentTimeInterval, setCurrentTimeInterval] = useState(12);
  const [actionsCreated, setActionsCreated] = useState(null);
  const [totalRecruiterApplicants, setTotalRecruiterApplicants] = useState(null);
  const [applicantsPerStage, setApplicantsPerStage] = useState(null);
  const [timePerStage, setTimePerStage] = useState(null);
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  useEffect(() => {
    if (recruiter) {
      fetchData();
    }
  }, [recruiter, currentTimeInterval]);
  
  useEffect(() => {
    if (applicantsPerStage) {
      let totalApplicants =
        applicantsPerStage["stage_1"] +
        applicantsPerStage["stage_2"] +
        applicantsPerStage["stage_3"] +
        applicantsPerStage["stage_4"] +
        applicantsPerStage["stage_5"] +
        applicantsPerStage["stage_6"] +
        applicantsPerStage["stage_7"] +
        applicantsPerStage["stage_8"] +
        applicantsPerStage["stage_9"];
      
      if (totalApplicants !== totalRecruiterApplicants) {
        setTotalRecruiterApplicants(totalApplicants);
      }
    }
  }, [applicantsPerStage]);
  
  const fetchData = async () => {
    setLoading(true);
    try {
      let timeframe = "";
      if (currentTimeInterval) {
        timeframe = `&timeframe=${currentTimeInterval}`;
      }
      
      const actionsCreatedPromise = fetch(`${API.endpointURL}/api/metrics/actions_created?recruiter_id=${recruiter}${timeframe}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      }).then((response) => response.json());
      
      const applicantsPerStagePromise = fetch(`${API.endpointURL}/api/metrics/all_applicants_per_recruiter?recruiter_id=${recruiter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      }).then((response) => response.json());
      
      const timePerStagePromise = fetch(`${API.endpointURL}/api/metrics/average_spent_per_recruiter?recruiter_id=${recruiter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      }).then((response) => response.json());
      
      const [actionsCreatedData, applicantsPerStageData, timePerStageData] = await Promise.all([
        actionsCreatedPromise,
        applicantsPerStagePromise,
        timePerStagePromise,
      ]);
      
      setActionsCreated(actionsCreatedData);
      setApplicantsPerStage(applicantsPerStageData);
      setTimePerStage(timePerStageData);
      setLoading(false);
    } catch (error) {
      setError(`Error fetching My Stats data`)
      setLoading(false);
    }
  };
  
  const stageTitles = {
    1: "New Applicants",
    2: "Pre-Screening",
    3: "Interview",
    4: "USG Documents",
    5: "Security",
    6: "Finalize",
  };
  
  const renderStatContainer = (header, dataKey) => {
    return (
      <div className="fill-stat-container">
        <div className="stage-stat-header">{header}</div>
        {applicantsPerStage && (
          <FillBarSolid
            color={color}
            value={applicantsPerStage[dataKey]}
            maxValue={totalRecruiterApplicants}
          />
        )}
      </div>
    );
  };
  
  if (loading) {
    return (
      <div className="recruiter-stats-container">
        <div className="content-loading" style={{ height: '100vh' }}>
          {color === 'green' ?  <ProgressSpinner /> : <ProgressSpinnerBlue /> }
        </div>
      </div>
    )
  }
  
  if (error.length > 0) {
    return (
      <div className="stats-group-header">{error}</div>
    );
  }
  
  return (
    <div className="recruiter-stats-container">
      <div className="stats-group-container">
        <div className="stats-group-subheader">
          Actions completed
          <select
            className="contract-form-input"
            style={{ width: "35%" }}
            placeholder="Select Interval"
            value={currentTimeInterval}
            onChange={(e) => setCurrentTimeInterval(e.currentTarget.value)}
          >
            <option key={'12h'} value={12}>Last 12 Hours</option>
            <option key={'24h'} value={24}>Last 24 Hours</option>
            <option key={'168h'} value={168}>Last 7 Days</option>
            <option key={'all'} value="">All Time</option>
          </select>
        </div>
        <div className="stage-stat-container">
          <div className={`stage-stat-number stage-stat-number--${color}`}>
            {actionsCreated && actionsCreated["actions_made"]?.toLocaleString()}
          </div>
          <div className="stage-stat-unit">
            {actionsCreated && actionsCreated["actions_made"] === 1 ? "action" : "actions"}
          </div>
        </div>
      </div>
      <div className="stats-group-container">
        <div className="stats-group-subheader">
          Average time spent per stage
        </div>
        <div className="contract-totals-row">
          {Object.keys(stageTitles).map((stage) => (
            <div className="stage-stat-container" key={`${stage}-${stageTitles[stage]}-time`}>
              <p className="stage-stat-header">{stageTitles[stage]}</p>
              <div className={`stage-stat-number stage-stat-number--${color}`}>
                {timePerStage && timePerStage.averages[stage] ? (Math.round(timePerStage.averages[stage] * 100) / 100) : 0}
              </div>
              <p className="stage-stat-unit">
                {timePerStage && timePerStage.averages[stage] === 1 ? "day" : "days"}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="stats-group-container">
        <div className="stats-group-subheader">
          Applicants per stage
        </div>
        <div className="contract-totals-row">
          {Object.keys(stageTitles).map((stage) => (
            <div className="stage-stat-container" key={`${stage}-${stageTitles[stage]}-applicants`}>
              <p className="stage-stat-header">{stageTitles[stage]}</p>
              <div className={`stage-stat-number stage-stat-number--${color}`}>
                {applicantsPerStage && applicantsPerStage[`stage_${stage}`]}
              </div>
              <p className="stage-stat-unit">
                {applicantsPerStage && applicantsPerStage[`stage_${stage}`] === 1 ? "applicant" : "applicants"}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="stats-group-container">
        <div className="stats-group-subheader">
          Hired, Archived, and Dispositioned
        </div>
        {renderStatContainer("Applicants Hired", "stage_7")}
        {renderStatContainer("Applicants Dispositioned", "stage_8")}
        {renderStatContainer("Applicants Archived", "stage_9")}
      </div>
    </div>
  );
};
