import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppBar from 'components/app-bar/updated-app-bar-component';
import SidebarTabs from 'components/tabs/sidebar-tabs';
import { FilePanel as ConnectionsProfilePanel } from 'components/connections/ConnectionsProfilePanel';
import { SalesConnections } from 'components/sales/sales-connections';
import ConnectionsInfo from 'components/connections/connections-info';
import ConnectionsNotes from 'components/notes/connections-notes';
import API from "API";
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const ConnectionPortal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));
  const [currentTab, setCurrentTab] = useState("information");
  const [connection, setConnection] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getConnection();
    }
  }, [id]);

  const getConnection = () => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/connections/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setConnection(json);
        setLoading(false);
      });
  };

  return (
    <>
      <AppBar
        colorPalette="navy"
        menu={true}
        title="Connection Portal"
      />
      <div className="sales-page">
        {connection && (
          <>
            <div className="sidebar-content">
              <button
                className="back-button navy-hover"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon
                  sx={{
                    marginRight: '5px',
                    height: '15px',
                    width: 'auto',
                  }}
                />
                Go back
              </button>
              <div className="portal-preview">
                {loading ? (
                  <div className="sidebar-loading sidebar-loading-connection">
                    <ProgressSpinnerNavy />
                  </div>
                ) : (
                  <ConnectionsProfilePanel connection={connection} />
                )}

                <div className="tabs-container">
                  <SidebarTabs
                    colorPalette={"navy"}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    tabs={["information", "connections", "notes"]}
                  />
                </div>
              </div>
            </div>
            <div
              className="page-content"
              style={{ paddingTop: currentTab === "connections" ? '0' : '30px' }}
            >
              {currentTab === "information" && (
                <ConnectionsInfo
                  connection={connection}
                  getConnection={getConnection}
                  loading={loading}
                />
              )}
              {currentTab === "notes" && <ConnectionsNotes id={connection.id} notes={connection.notes} />}
              {currentTab === "connections"
                && <SalesConnections
                  getConnection={getConnection}
                  connection={connection}
                  loadingProp={loading}
                  title='LINK CONNECTION TO THE CONNECTION'
                />
              }
            </div>
          </>
        )}
      </div>
    </>
  );
};
