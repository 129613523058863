import React, { useState } from "react";

import { Box, Button, TextField } from "@mui/material";

import "../../css/modal.scss";

import CustomDialog from "./custom-dialog";
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { editUser } from 'store/user/thunk';
import { userErrorSelector, userLoadingSelector } from 'store/user/selectors';

export default function EditUserAddress(props) {
  const { applicant, getApplicant, handleClose, open, setOpenToast } = props;

  const dispatch = useAppDispatch();
  const loading = useAppSelector(userLoadingSelector);
  const error = useAppSelector(userErrorSelector);

  const [address, setAddress] = useState(
    applicant.address ? applicant.address : null
  );
  const [city, setCity] = useState(applicant.city ? applicant.city : null);
  const [state, setState] = useState(applicant.state ? applicant.state : null);
  const [zip, setZip] = useState(applicant.zip ? applicant.zip : null);
  
  const editInfo = async () => {
    const body = {};
    if (applicant.address !== address) {
      body.address = address;
    }
    if (applicant.city !== city) {
      body.city = city;
    }
    if (applicant.state !== state) {
      body.state = state;
    }
    if (applicant.zip !== zip) {
      body.zip = zip;
    }
    dispatch(editUser({ id: applicant.id, body }))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          getApplicant();
          handleClose();
          setOpenToast(true);
        }
      });
  };

  const editAddressForm = () => {
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <div style={{ textAlign: "left" }} className="form-row-label">
              Address
            </div>
            <TextField
              fullWidth
              id="edit-address"
              key="edit-address"
              placeholder="Street Address"
              variant="outlined"
              margin="dense"
              required
              value={address}
              onChange={(e) => setAddress(e.currentTarget.value)}
            />
          </Box>
          <Box sx={{ width: "48%" }}>
            <div style={{ textAlign: "left" }} className="form-row-label">
              City
            </div>
            <TextField
              fullWidth
              id="edit-city"
              key="edit-city"
              placeholder="City"
              variant="outlined"
              margin="dense"
              required
              value={city}
              onChange={(e) => setCity(e.currentTarget.value)}
            />
          </Box>
          <Box sx={{ width: "48%" }}>
            <div style={{ textAlign: "left" }} className="form-row-label">
              State
            </div>
            <TextField
              fullWidth
              id="edit-state"
              key="edit-state"
              placeholder="State"
              variant="outlined"
              margin="dense"
              required
              value={state}
              onChange={(e) => setState(e.currentTarget.value)}
            />
          </Box>
          <Box sx={{ width: "48%" }}>
            <div style={{ textAlign: "left" }} className="form-row-label">
              ZIP Code
            </div>
            <TextField
              fullWidth
              id="edit-zip-code"
              key="edit-zip-code"
              placeholder="ZIP Code"
              variant="outlined"
              margin="dense"
              required
              value={zip}
              onChange={(e) => setZip(e.currentTarget.value)}
            />
          </Box>
        </Box>
        {error && <div className="add-user-error">{error}</div>}
        <div className="button-container" style={{ overflow: 'hidden', paddingTop: '5px' }}>
          <Button
            variant="contained"
            className="add-user-button"
            sx={{
              backgroundColor: "#556155",
              color: "#EFEFEF",
              fontSize: "18px",
              width: "175px",
              "&:hover": { backgroundColor: "#a5af96", color: "#3e453d" },
            }}
            onClick={() => editInfo()}
          >
            Edit
            {loading && (
              <div style={{ position: 'absolute', left: -55 }}>
                <ProgressSpinnerBlue/>
              </div>
            )}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <CustomDialog
      dialogContent={editAddressForm()}
      onClose={handleClose}
      open={open}
      title={"EDIT"}
      titleHighlight={"HOME ADDRESS"}
    />
  );
}
