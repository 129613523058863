import React from 'react';
import Tabs from '@mui/material/Tabs';
import { StyledTab } from './tab-styles';
import { sortOptions } from 'shared/applicant-data';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  currentSortOptionSelector,
  currentSortOrderSelector,
  currentTabSelector,
} from 'store/staffingSelectedOptions/selectors';
import { setCurrentSortOption, setCurrentSortOrder, setCurrentTab } from 'store/staffingSelectedOptions/slice';
import { setCurrentApplicant } from 'store/applicants/slice';
import { applicantsByStageSelector } from 'store/applicants/selectors';

export default function StaffingTabs() {
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(currentTabSelector);
  const currentSortOption = useAppSelector(currentSortOptionSelector);
  const currentSortOrder = useAppSelector(currentSortOrderSelector);

  const handleChange = (event, newValue) => {
    dispatch(setCurrentTab(newValue));
    dispatch(setCurrentApplicant(null));
  };

  const buildSortFilter = () => {
    let options = [];
    for (const [key, value] of Object.entries({
      created_at: sortOptions.created_at,
      email: sortOptions.email,
      first_name: sortOptions.first_name,
      last_name: sortOptions.last_name,
      contract_id: sortOptions.contract_id
    })) {
      options.push(
        <option key={key} value={key}>
          {value}
        </option>
      )
    }
    return (
      <select
        className="contract-form-input"
        value={currentSortOption || ''}
        onChange={(e) => dispatch(setCurrentSortOption(e.currentTarget.value))}
      >
        {options}
      </select>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        width: '100%',
        maxWidth: "100%",
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Tabs
        value={currentTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons="auto"
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
        TabScrollButtonProps={{
          style: {
            margin: "0 10px",
            width: "10px",
          }
        }}
      >
        <StyledTab label='All Applicants' value={0} />
        <StyledTab label='New Applicants' value={1} />
        <StyledTab label='Pre-Screening' value={2} />
        <StyledTab label='Interview' value={3} />
        <StyledTab label='USG Documents' value={4} />
        <StyledTab label='Security' value={5} />
        <StyledTab label='Finalize' value={6} />
        <StyledTab label='Hired' value={7} />
        <StyledTab label='Disposition' value={8} />
        <StyledTab label='Archived' value={9} />
      </Tabs>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "8px",
          width: "25%",
          maxWidth: '190px',
        }}
      >
        {buildSortFilter()}
        {currentSortOrder === "asc" && (
          <i
            className="fa-solid fa-arrow-down edit-icon green-arrow"
            style={{ marginLeft: "12px" }}
            onClick={() => dispatch(setCurrentSortOrder("desc"))}
          />
        )}
        {currentSortOrder === "desc" && (
          <i
            className="fa-solid fa-arrow-up edit-icon green-arrow"
            style={{ marginLeft: "12px" }}
            onClick={() => dispatch(setCurrentSortOrder("asc"))}
          />
        )}
      </div>
    </div>
  );
};
