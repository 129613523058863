import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import 'css/projects.scss';
import 'css/updated-app-bar-component.scss';

import AppBar from 'components/app-bar/updated-app-bar-component';
import SidebarTabs from 'components/tabs/sidebar-tabs';
import { ProjectInformation } from 'components/project-portal/project-information';
import { PortalProfilePanel } from 'components/project-portal/portal-profile-panel';
import ProjectsDropBoxPageContent from 'components/project-portal/projects-drop-box-page-content';
import ProjectTasks from 'components/project-portal/project-tasks';
import ProjectNotes from 'components/project-portal/project-notes';
import { ManageVendorStatus } from 'components/project-portal/project-vendor-status';
import { SalesConnections } from 'components/sales/sales-connections';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { projectSelector, projectTasksSelector } from 'store/projects/selectors';
import {
  getProject,
  getProjectFiles,
  getProjectsCoordinators,
  getProjectsFileFolders,
  getProjectsManagers,
  getProjectTasks,
} from 'store/projects/thunk';
import { setCurrentTaskPage, setCurrentTaskTab } from 'store/projects/slice';

export const ProjectPortal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { project, getProjectLoading } = useAppSelector(projectSelector);
  const { page, currentTab: tasksTab } = useAppSelector(projectTasksSelector);
  const [currentTab, setCurrentTab] = useState('general information');
  const [loadedFiles, setLoadedFiles] = useState(false);
  const [loadedFolders, setLoadedFolders] = useState(false);

  const resetTasks = () => {
    if (tasksTab === 'current') {
      dispatch(getProjectTasks({
        projectId: id,
        status: [
          'in progress',
          'requested approval',
          'returned',
          'blocked',
        ],
        page: page,
      }));
    } else {
      dispatch(getProjectTasks({
        projectId: id,
        status: [tasksTab],
        page: page,
      }));
    }
  };

  useEffect(() => {
    resetTasks();
  }, [tasksTab, page]);
  
  const getProjectInfo = () => {
    dispatch(getProject({ projectId: id }));
  };
  
  useEffect(() => {
    if (id) {
      getProjectInfo();
      dispatch(setCurrentTaskPage(1));
      dispatch(setCurrentTaskTab('assigned'));
    }
  }, [id]);
  
  useEffect(() => {
    dispatch(getProjectsCoordinators());
    dispatch(getProjectsManagers());
  }, []);
  
  useEffect(() => {
    if (currentTab === 'drop box') {
      if (!loadedFiles) {
        dispatch(getProjectFiles({ projectId: id }))
          .then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
              setLoadedFiles(true);
            }
          });
      }
      if (!loadedFolders) {
        dispatch(getProjectsFileFolders({ projectId: id }))
          .then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
              setLoadedFolders(true);
            }
          });
      }
    }
  }, [currentTab]);
  
  return (
    <>
      <AppBar colorPalette="navy" menu={true} title="Project Portal"/>
      <div className="projects-page">
        <div className="sidebar-content">
          <div className="portal-preview">
            <button
              className="back-button navy-hover"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon
                sx={{
                  marginRight: '5px',
                  height: '15px',
                  width: 'auto',
                }}
              />
              Go back
            </button>
            <div className="projects__sidebar">
              <div className="projects__sidebar-container">
                <PortalProfilePanel/>
              </div>
              <div
                style={{
                  color: '#EFEFEF',
                  fontFamily: 'MagistralCondW08-Medium',
                  fontSize: '35px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                }}
              >
                Option Panel
              </div>
            </div>
            <div className="tabs-container">
              <SidebarTabs
                colorPalette={'navy'}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                tabs={[
                  'general information',
                  'connections',
                  'vendor status',
                  'tasking',
                  'drop box',
                  'notes',
                ]}
              />
            </div>
          </div>
        </div>
        <div
          className="projects__content"
          style={{
            padding:
              (currentTab === 'connections' || currentTab === 'tasking')
                ? '0 30px 30px' : '30px',
          }}
        >
          {currentTab === 'general information' &&
            <ProjectInformation />
          }
          {currentTab === 'tasking' &&
            <ProjectTasks resetTasks={resetTasks} />
          }
          {currentTab === 'drop box' && <ProjectsDropBoxPageContent id={id} />}
          
          {currentTab === 'notes' &&
            <ProjectNotes id={id} retrieveNotes={getProjectInfo} />
          }
          {currentTab === 'vendor status' &&
            <ManageVendorStatus />
          }
          {currentTab === 'connections' && (
            <SalesConnections
              project={project}
              getProject={getProjectInfo}
              loadingProp={getProjectLoading}
              title="LINK CONNECTION TO THE PROJECT"
            />
          )}
        </div>
      </div>
    </>
  );
};