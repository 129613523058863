import React, { Component } from "react";
import { IconButton } from "@mui/material";
import EqipModal from "../modal/eqip-modal";
import trackButton from "../../assets/track_button.png";

export default class eqipButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isEqipOpen: false,
    };
    this.handleOpenEqip = this.handleOpenEqip.bind(this);
  }

  handleOpenEqip(value){
    this.setState({isEqipOpen: value})
  }

  render() {
    return (
      <div>
        <IconButton
          onClick={() => this.handleOpenEqip(true)}
          sx={{
            paddingTop: 0,
            paddingBottom: 0,
            "&:hover": { backgroundColor: "transparent" },
          }}
        >
          <img
            src={trackButton}
            width="100px"
            height="auto"
            alt="track-button"
          />
        </IconButton>

        {this.state.isEqipOpen && (
          <EqipModal
            user = {this.props.user}
            open={this.state.isEqipOpen}
            handleClose={() => this.handleOpenEqip(false).bind(this)}
            handleUpdateApplicants={this.props.handleUpdateApplicants}
          />
        )}
      </div>
    );
  }
}
