import React, { useState } from 'react';
import { Box, IconButton, MenuItem, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import { colors } from 'shared/colors';

export const ColorPicker = ({ inputColor, setInputColor }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleColorChange = (color) => {
    setInputColor(color);
  };
  
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Choose color">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            sx={{
              border: '1px solid #fff',
              borderRadius: '10px',
            }}
          >
            <div
              style={{
                backgroundColor: inputColor,
                width: '40px',
                height: '20px',
                padding: '5px',
                borderRadius: '5px',
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          '.MuiList-root': {
            border: 0,
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflowY: 'auto',
            overflowX: 'hidden',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            backgroundColor: '#fff',
            border: '1px solid #EFEFEF',
            width: '410px',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(13, 1fr)', gap: '2px' }}>
          {colors.map((color) => (
            <MenuItem
              key={color}
              onClick={() => {
                handleColorChange(color);
                handleClose();
              }}
              sx={{
                padding: 0,
                width: 'auto',
                height: '30px',
                backgroundColor: color,
                border: '1px solid black',
              }}
            >
              {inputColor === color && (
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    margin: 'auto',
                    borderRadius: '50%',
                    position: 'relative',
                  }}>
                  <div
                    style={{
                      backgroundColor: '#FFFFFF',
                      width: '5px',
                      height: '5px',
                      borderRadius: '50%',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </div>
              )}
            </MenuItem>
          ))}
        </div>
      </Menu>
    </>
  );
};
