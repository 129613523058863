import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import Search from '../../assets/icon-bar-search.png'
import API from 'API';
import Switch from '@mui/material/Switch';
import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';

export const SalesConnectionsModalSeven = ({
  connectionType,
  setStep,
  sale,
  setOpenToast,
  getSale,
  handleClose,
  connection,
  getConnection,
  isCompetitor,
  setIsCompetitor,
  project,
  getProject,
}) => {
  const token = JSON.parse(localStorage.getItem("token"));

  const [connections, setConnections] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [error, setError] = useState(false);

  const [noteBody, setNoteBody] = useState('');
  const [searchValue, setSearchValue] = useState(null)
  const [dataSearchFromReq, setDataSearchFromReq] = useState([])
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleChangeSearchBar = (e) => {
    const inputValue = e.target.value;
    if (inputValue === '') {
      setSearchValue(null);
      setDataSearchFromReq([])
      setLoading(false);
    } else {
      setLoading(true);
      setSearchValue(inputValue);
    }

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newDebounceTimer = setTimeout(() => {
      if (inputValue) {
        getSearchedName(inputValue)
      }
    }, 1000);
    setDebounceTimer(newDebounceTimer);
  }

  const getSearchedName = (name) => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/filter/connections?connection_type=${connectionType.toLowerCase()}&full_name=${name}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setDataSearchFromReq(res.data);
        setLoading(false);
      })
      .catch(() =>
        setLoading(false)
      );
  };

  const getConnections = () => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/filter/connections?connection_type=${connectionType.toLowerCase()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (sale) {
          const filteredConnections = res.data.filter(obj1 => {
            return !sale.connections.some(obj2 => obj2.id === obj1.id);
          });
          setConnections(filteredConnections);
        } else if (project) {
          const filteredConnections = res.data.filter(obj1 => {
            return !project.connections.some(obj2 => obj2.id === obj1.id);
          });
          setConnections(filteredConnections);
        } else if (connection) {
          setConnections(
            res.data
              .filter(item => item.id !== connection.id)
              .filter(obj1 => {
                return !connection.personal_connections.some(obj2 => obj2.id === obj1.id)
                  && !connection.company_connections.some(obj2 => obj2.id === obj1.id)
                  && !connection.competitor_connections.some(obj2 => obj2.id === obj1.id);
              })
          );
        } else {
          setConnections(res.data);
        }
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getConnections();
  }, []);

  const handleSelect = (value) => {
    setError(false);
    setSelectedConnection(value);
  };

  const handleLinkSaleOrProject = (noteId = 0) => {
    if (!selectedConnection) {
      setError(true);
    } else {
      setLoadingButton(true);
      try {
        let requestError = false;
        const requestBody = {
          connection_id: selectedConnection.id,
          is_primary: false,
        };
        if (sale) {
          requestBody.stage = sale?.stage;
        }

        if (noteId > 0) {
          requestBody.note_id = noteId;
        }

        if (isCompetitor && connectionType === 'COMPANY') {
          requestBody.is_competitor = true;
        }
        fetch(`${API.endpointURL}/api/${
          sale ? 'sales' : 'projects'
        }/${sale ? sale.id : project?.id}/connections`, {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
            Accept: 'application/json'
          },
        })
          .then((res) => {
            if (!res.ok) {
              requestError = true;
            }
            return res.json();
          })
          .then(() => {
            setLoadingButton(false);
            setOpenToast(true);
            if (requestError) {
              setError(true);
            } else {
              if (sale) {
                getSale();
              } else {
                getProject();
              }
              handleClose();
              setError(false);
            }
          });
      } catch (e) {
        setOpenToast(true);
        setError(true);
        setLoadingButton(false);
      }
    }
  };

  const handleLinkConnection = (noteId = 0) => {
    const parentType = connection.connection_type;
    let childType = selectedConnection.connection_type;
    let connectionKey = `${childType}_connection_id`;
    if (parentType === childType) {
      connectionKey = `linked_${childType}_connection_id`
    }
    if (selectedConnection.connection_type === 'personal') {
      childType = 'personal-connection';
    }

    if (!selectedConnection) {
      setError(true);
    } else {
      setLoadingButton(true);
      try {
        let requestError = false;
        const requestBody = {
          [connectionKey]: selectedConnection.id,
          is_primary: false,
        };

        if (isCompetitor && connectionType === 'COMPANY') {
          requestBody.is_competitor = true;
        }

        if (noteId > 0) {
          requestBody.note_id = noteId;
        }
        fetch(`${API.endpointURL}/api/connections/${parentType}-connections/`
          + `${connection.id}/attach-${childType}`, {
          method: "PATCH",
          body: JSON.stringify(requestBody),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
            Accept: 'application/json'
          },
        })
          .then((res) => {
            if (!res.ok) {
              requestError = true;
            }
            return res.json();
          })
          .then(() => {
            setLoadingButton(false);
            setOpenToast(true);
            if (requestError) {
              setError(true);
            } else {
              getConnection();
              handleClose();
              setError(false);
            }
          });
      } catch (e) {
        setOpenToast(true);
        setError(true);
        setLoadingButton(false);
      }
    }
  };

  const createNote = () => {
    setLoadingButton(true);
    let requestError = false;
    return fetch(`${API.endpointURL}/api/notes`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
        Accept: 'application/json',
      },
      body: JSON.stringify({
        body: noteBody,
      })
    })
      .then((res) => {
        if (!res.ok) {
          requestError = true;
        }
        return res.json();
      })
      .then((res) => {
        if (!requestError) {
          return res.id;
        } else {
          setLoadingButton(false);
          setError(true);
        }
      })
      .catch(() => {
          setLoading(false);
          setError(true);
        }
      );
  };

  const connectSaleOrProjectNote = async () => {
    const noteID = await createNote();
    if (noteID) {
      await handleLinkSaleOrProject(noteID);
    }
  };

  const connectConnectionNote = async () => {
    const noteID = await createNote();
    if (noteID) {
      await handleLinkConnection(noteID);
    }
  };

  const handleSubmit = () => {
    if (sale || project) {
      noteBody ? connectSaleOrProjectNote() : handleLinkSaleOrProject();
    } else {
      noteBody ? connectConnectionNote() : handleLinkConnection();
    }
  }

  return (
    <div className="connection__modal-content">
      <p
        className="
          connection__modal-title
          connection__modal-title--margin
          connection__modal-title--bold
        "
        style={{ margin: '0 0 -10px' }}
      >
        Select connection:
      </p>
      <div style={{ position: 'relative' }}>
        <TextField
          value={searchValue || ""}
          name="full_name"
          onChange={(e) => handleChangeSearchBar(e)}
          fullWidth
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #a5af96",
            borderRadius: "8px",
            padding: "6px 10px 5px",
            marginBottom: 0,
          }}
          placeholder="Search Name..."
          multiline={false}
          rows={1}
          variant="standard"
          onKeyPress={handleKeyPress}
          InputProps={{
            disableUnderline: true,
          }}
        />
        <img src={Search} alt="ff" style={{
          top: '5px',
          position: 'absolute',
          right: '20px',
          height: '33px'
        }} />
      </div>

      <div className="connection__modal-connections">
        {loading ? (
          <div className="content-loading">
            <ProgressSpinnerDarkNavy />
          </div>
        ) : (
          <>
            {!!dataSearchFromReq.length ? (dataSearchFromReq.map((item) => (
              <button
                className={`
              connection__modal-connection 
              ${(selectedConnection && selectedConnection.id === item.id)
                && 'connection__modal-connection-active'}
            `}
                key={item.id}
                onClick={() => handleSelect(item)}
              >
                <p>{item.full_name}</p>
                <p>{item.phone_number}</p>
              </button>
            ))) : (dataSearchFromReq.length === 0 && searchValue !== null ? (
              <p style={{
                position: 'absolute',
                left: '10px',
                top: '130px'
              }}>No results found</p>
            ) : (connections.map((item) => (
              <button
                className={`
              connection__modal-connection 
              ${(selectedConnection && selectedConnection.id === item.id)
                && 'connection__modal-connection-active'}
            `}
                key={item.id}
                onClick={() => handleSelect(item)}
              >
                <p>{item.full_name}</p>
                <p>{item.phone_number}</p>
              </button>
            ))))
            }
          </>
        )}
      </div>

      <TextField
        value={noteBody || ""}
        onChange={(e) => setNoteBody(e.target.value)}
        fullWidth
        sx={{
          backgroundColor: "#fff",
          border: "1px solid #a5af96",
          borderRadius: "8px",
          padding: "6px 10px 5px",
          marginBottom: '-4px',
        }}
        placeholder="Add relationship to your connection..."
        multiline={false}
        rows={1}
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
      />

      {connectionType === 'COMPANY' && (

        <div className="sale-form-set sale-form-set-row sale-form-competitor" style={{ bottom: '20px' }}>
          <p className="form-row-label" style={{ textAlign: 'left' }}>
            Competitor
          </p>
          <Switch
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#141A66',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#141A66',
              },
              '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                backgroundColor: '#c7c7c7',
              },
            }}
            checked={isCompetitor}
            onChange={() => setIsCompetitor(!isCompetitor)}
          />
        </div>
      )}

      {error && (
        <p className="sale-form-error" style={{ top: '72px', right: '2%' }}>
          * Please select connection...
        </p>
      )}

      <div className="connection__modal-buttons">
        {loadingButton && (
          <div className="connection__modal-spinner">
            <ProgressSpinnerDarkNavy />
          </div>
        )}
        <button
          className="connection__modal-button connection__modal-button--back"
          onClick={() => setStep(1)}
        >
          Back
        </button>
        <button
          className="connection__modal-button"
          onClick={handleSubmit}
        >
          Link
        </button>
      </div>
    </div>
  );
};