import { axiosService } from 'services/axiosServices';

export const auth = {
  checkUniqueness: async (body) => {
    const res = await axiosService.get('/api/uniqueness-check', {
      params: body
    });
    return res.data;
  }
}
