import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import API from "API";
import AppBar from "components/app-bar/updated-app-bar-component";
import { uploadTypeToFile } from "shared/applicant-data";
import { useParams } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FileViewer = (props) => {
  const [file, setFile] = useState(null); //object
  const [fileName, setFileName] = useState(null); // string
  const [error, setError] = useState(false); // error

  const [numberOfPages, setNumberOfPages] = useState(0); // int

  const [blobURL, setBlobURL] = useState(null); // string
  
  const [ext, setExt] = useState(null); // string

  let {fileID} = useParams();

  useEffect(() => {
    API.getFile(fileID).then(
        (success) => {
          let f = success[0];

          setFile(f);
          setFileName(f.display_name);
          setExt(f.file_name.split('.').pop().toLowerCase());

          API.createBlobFromResumeFileName(f.file_name).then(
            (success) => {
              setBlobURL(success);
            }
          );
        }
    );
    
  }, []);

  const downloadFile = () => {
    let downloadName = fileName;
    
    if (!downloadName) {
      downloadName = file.type + `-${file.id}`;
    }

    const link = document.createElement("a");

    link.href = blobURL;
    link.setAttribute("download", downloadName);

    document.body.appendChild(link);

    link.click();
  };

  const handleLoadSuccess = (pdf) => {
    setNumberOfPages(pdf.numPages);
  }

  const renderPages = () => {
    let c = [];

    const pageParams = {
      renderAnnotationLayer: false,
      renderInteractiveForms: false,
      customTextRenderer: () => (<div></div>)
    }

    for(let i = 0; i < numberOfPages; i++) {
      const frozenIndex = i;
      c.push(
        <Page key={frozenIndex + "_f"} pageNumber={frozenIndex+1} {...pageParams} />
      );
    }
    return c;
  }

  const renderSidebar = () => {
    if(file) {
      return (
        <div className="sidebar__pocket-box">
          <div className="sidebar__pocket">
            <div className="row">
            <h2>{file.display_name ? file.display_name : `${uploadTypeToFile[file.type]} v${file.version}`}</h2>
            <b className="badge" style={{marginLeft: "5px"}}>{file.file_name.split(".").pop()}</b>
            </div>
            <p>Created {new Date(file.created_at).toLocaleString()}</p>
            {/* <pre>{JSON.stringify(file, null, 4)}</pre> */}

            <div className="divider"></div>
            <div className="file-inputs">
              <div className="input">
                <input
                  type="text"
                  id="displayName"
                  placeholder="Set file name on download..."
                  onChange={(e) =>
                    setFileName(
                      e.target.value === "" ? file.display_name : e.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (<div></div>)
  }

  const renderViewer = () => {
    if(file && blobURL) {
      if(ext === 'pdf') {
        return (
          <div className="pdf-viewer">
            {error ? (
              <div className="pdf-error">Only PDFs can be viewed in this window. Please download to view your file.</div>
            ) : (
              <Document
                key={blobURL}
                file={blobURL}
                onLoadError={(e) => {setError(e);console.error(e);console.error(`${API.endpointURL}/api/cloud_location?location=${file.file_name}`)}}
                onLoadSuccess={(pdf) => handleLoadSuccess(pdf)}
                className={"pdf"}
              >
                {renderPages()}
              </Document>
            )}
          </div>
        );
      } else if(ext === 'docx') {
        return (
          <div>DOCX is not supported yet. Download to view this file.</div>
        );
      } else if(ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
        return (
          <div className="image-viewer">
            <img style={{maxHeight: "calc(100vh - 80px)"}} src={`${API.endpointURL}/api/cloud_location?location=${file.file_name}`} className="image-viewer__image" />
          </div>
        );
      }
      return (
        <div>Filetype is not previewable. Download to view this file.</div>
      );
    }

    return (
      <div className="loader" style={{height: "calc(100vh - 150px)"}}>
        <div className="loading-spinner">
          <div className="loading-spinner__fill"></div>
          <div className="loading-spinner__bg"></div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <AppBar
        colorPalette="blue"
        menu={true}
        title="Document Viewer"
      />
      <div className="accounting-page">
        <div className="sidebar">
          {renderSidebar()}
          <div
            className={"sidebar__item"}
            style={{backgroundColor: 'rgba(50, 168, 82, 0.1)'}}
            onClick={() => downloadFile()}
          >
            <p>Download</p>
            <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
          </div>
        </div>
        <div className="page-content" style={{maxHeight: "calc(100% - 150px)"}}>
          {renderViewer()}
        </div>
      </div>
    </div>
  );
};

export default FileViewer;
