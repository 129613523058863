import React, { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import { TextField } from '@mui/material';
import Switch from '@mui/material/Switch';

import 'css/task.scss';

import NewBaseModal from './new-base-modal';
import Toast from 'components/toast/toast';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { projectsCoordinatorsSelector, projectsManagersSelector } from 'store/projects/selectors';
import { taskSelector } from 'store/tasks/selectors';
import { updateTask } from 'store/tasks/thunk';
import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';

const tabs = [
  'description',
  'update',
];

const status = [
  'assigned',
  'in progress',
  'requested approval',
  'returned',
  'blocked',
  'completed',
  'archived',
];

const initialTask = {
  status: '',
  description: '',
  date_due: '',
  personnel_assigned_id: '',
  file_active: false,
  milestone_active: false,
};

const initialErrors = {
  description: '',
  date_due: '',
  personnel_assigned_id: '',
  validation: '',
};

const ProjectsTaskModal = ({ handleClose, open, setServerError, getTasks }) => {
  const dispatch = useAppDispatch();
  const { projectCoordinators } = useAppSelector(projectsCoordinatorsSelector);
  const { projectManagers } = useAppSelector(projectsManagersSelector);
  const { updateTaskLoading, updateTaskError, selectedTask } = useAppSelector(taskSelector);
  
  const [taskForm, setTaskForm] = useState(initialTask);
  const [activeTab, setActiveTab] = useState('description');
  const [personnel, setPersonnel] = useState([]);
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  
  const getPersonnel = () => {
    setPersonnel([...projectManagers, ...projectCoordinators]);
  };
  
  const handleChange = (event) => {
    setTaskForm({
      ...taskForm,
      [event.target.name]: event.target.value,
    });
    if (!event.target.value) {
      setErrors({
        ...errors,
        [event.target.name]: true,
      });
    } else {
      setErrors({
        ...errors,
        [event.target.name]: false,
        validation: false,
      });
    }
  };
  
  useEffect(() => {
    getPersonnel();
    setTaskForm({
      status: selectedTask.status || '',
      description: selectedTask.description || '',
      date_due: selectedTask.date_due ? parseDateString(selectedTask.date_due) : '',
      personnel_assigned_id: selectedTask.personnel_assigned_id || '',
      file_active: selectedTask.file_active || false,
    });
  }, []);
  
  const closeDocumentModal = () => {
    handleClose();
  };
  
  const handleUpdate = () => {
    let isError = false;
    let newErrors = {
      ...initialErrors,
    };
    
    if (!taskForm.description || taskForm.description.length > 255) {
      isError = true;
      newErrors.description = taskForm.description.length > 255
        ? 'Max length is 255 characters.'
        : 'Please, enter description.';
    }
    
    if (!taskForm.date_due) {
      isError = true;
      newErrors.date_due = 'Please, set due date.';
    }
    
    if (!taskForm.personnel_assigned_id) {
      isError = true;
      newErrors.personnel_assigned_id = 'Please, select personnel assigned.';
    }
    
    setErrors(newErrors);
    
    if (!isError) {
      const body = {
        personnel_assigned_id: taskForm.personnel_assigned_id,
        description: taskForm.description,
        file_active: taskForm.file_active,
        status: taskForm.status,
        date_due: taskForm.date_due,
      };
      dispatch(updateTask({ taskId: selectedTask.id, body }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setOpenToast(true);
            getTasks();
            handleClose();
            setServerError(false);
          } else {
            setOpenToast(true);
            setServerError(true);
          }
        })
    }
  };
  
  function parseDateString(dateString) {
    if (dateString === null) {
      return '';
    }
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  }
  
  const renderFetchedOptions = (type) => {
    return (
      <>
        <option value="">Select {type}...</option>
        {personnel.map((element) => (
          <option key={element.id} value={element.id}>
            {`${element.first_name} ${element.last_name}`}
          </option>
        ))}
      </>
    );
  };
  
  const buildTable = () => {
    return (
      <div className="task-modal">
        <div className="task-modal__tabs">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={
                activeTab === tab
                  ? 'task-modal__tab task-modal__tab--active'
                  : 'task-modal__tab'
              }
              style={{ backgroundColor: activeTab === tab ? '#141A66' : '' }}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="task-modal__content">
          {activeTab === 'description' && (
            <>
              <div className="form-row-label" style={{ textAlign: 'left' }}>
                Description
              </div>
              <TextField
                value={taskForm.description}
                name="description"
                onChange={handleChange}
                fullWidth
                disabled={selectedTask.type === 'milestone'}
                sx={{
                  backgroundColor: '#fff',
                  border: '1px solid #a5af96',
                  borderRadius: '8px',
                  padding: '6px 10px 5px',
                  marginBottom: 1,
                  '& textarea': {
                    borderRadius: 0,
                  },
                }}
                placeholder="Message..."
                multiline={true}
                rows={8}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
              {!taskForm.description && (
                <p
                  className="sale-form-error"
                  style={{ top: '177px' }}
                >
                  * Please enter Description...
                </p>
              )}
              {taskForm.description.length > 255 && (
                <p
                  className="sale-form-error"
                  style={{ top: '177px' }}
                >
                  * Max length is 255 characters...
                </p>
              )}
            </>
          )}
          {activeTab === 'update' && (
            <div className="task-modal__container">
              <div className="task-modal__info">
                <div className="task-modal__info-field">
                  <div className="form-row-label" style={{ textAlign: 'left' }}>
                    Personnel assigned *
                  </div>
                  <select
                    className="contract-form-input"
                    placeholder="Select Personnel Assigned..."
                    value={taskForm.personnel_assigned_id || ''}
                    name="personnel_assigned_id"
                    onChange={handleChange}
                    style={{
                      color: !taskForm.personnel_assigned_id ? '#b5b5b5' : '#171717',
                      backgroundColor: 'white',
                      border: '1px solid #a5af96',
                    }}
                  >
                    {renderFetchedOptions('Personnel')}
                  </select>
                </div>
                
                <div className="task-modal__info-field">
                  <div className="form-row-label" style={{ textAlign: 'left' }}>
                    Due Date *
                  </div>
                  <div style={{ backgroundColor: 'white', border: '1px solid #a5af96', borderRadius: '6px' }}>
                    <DatePicker
                      className="contract-form-input"
                      selected={
                        taskForm.date_due
                          ? (typeof taskForm.date_due === 'string' ? new Date(taskForm.date_due) : taskForm.date_due)
                          : null
                      }
                      placeholderText={taskForm.date_due ? taskForm.date_due : 'N/A'}
                      
                      onChange={(date) => {
                        if (date) {
                          const dd = String(date?.getDate()).padStart(2, '0');
                          const mm = String(date?.getMonth() + 1).padStart(2, '0');
                          const yyyy = date?.getFullYear();
                          const dateString = yyyy + '-' + mm + '-' + dd;
                          setTaskForm({
                            ...taskForm,
                            date_due: dateString,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-row-label" style={{ textAlign: 'left' }}>
                Status
              </div>
              <div className="task-modal__status">
                {status.map((item) => (
                  <button
                    key={item}
                    className="task-modal__radio"
                    onClick={() => setTaskForm({
                      ...taskForm,
                      status: item,
                    })}
                  >
                    <div
                      className="task-modal__radio-border">
                      {taskForm.status === item && (
                        <div className="task-modal__radio-border--active" style={{ backgroundColor: '#141A66' }}/>
                      )}
                    </div>
                    <p className="task-modal__radio-name">
                      {item}
                    </p>
                  </button>
                ))}
              </div>
              
              <div className="task-modal__info">
                
                <div className="task-modal__toggle">
                  <p>File Active</p>
                  <Switch
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#141A66',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#141A66',
                      },
                      '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                        backgroundColor: '#c7c7c7',
                      },
                    }}
                    checked={taskForm.file_active}
                    onChange={() => setTaskForm({
                      ...taskForm,
                      file_active: !taskForm.file_active,
                    })}
                  />
                </div>
              </div>
            </div>
          )}
          {activeTab === 'update' && (
            <div
              style={{ margin: 0 }}
              className="contractsTags__tags__modal-buttonContainer"
            >
              {updateTaskLoading && (
                <div
                  className="contractsTags__tags__modal-loading"
                  style={{ top: '-35px', right: '140px' }}
                >
                  <ProgressSpinnerDarkNavy/>
                </div>
              )}
              {updateTaskError.length > 0 && (
                <p className="contractsTags__tags__modal-error--add">{updateTaskError}</p>
              )}
              <button
                className="connection__modal-button"
                onClick={handleUpdate}
                disabled={updateTaskLoading}
              >
                Update
              </button>
            </div>
          )}
        </div>
        
        {/* ERRORS */}
        {errors.date_due && (
          <p
            className="sale-form-error"
            style={{ left: '0', top: '470px' }}
          >
            Please, set due date.
          </p>
        )}
        
        {errors.personnel_assigned_id && (
          <p
            className="sale-form-error"
            style={{ left: '0', top: '455px' }}
          >
            Please, select personnel assigned.
          </p>
        )}
        
        {errors.description && (
          <p
            className="sale-form-error"
            style={{ left: '0', top: '440px' }}
          >
            Please, enter valid description. 255 is max length.
          </p>
        )}
        
        {/* Display toast with needed toast message */}
        {openToast && (
          <Toast
            open={openToast}
            onClose={() => setOpenToast(false)}
            message={errors.date_due || errors.description || errors.personnel_assigned_id
              ? 'Something went wrong, try again later.'
              : 'Information was successfully updated'
            }
          />
        )}
      </div>
    );
  };
  
  return (
    <NewBaseModal
      dialogContent={buildTable()}
      onClose={closeDocumentModal}
      open={open}
      title={'MANAGE TASK'}
      colorPalette="purple"
    />
  );
};

export default ProjectsTaskModal;
