import '../../css/modal.scss';
import React, { useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import CustomDialogRedesign from './custom-dialog-redesign';
import { adminErrorMessages } from 'shared/error-messages';
import API from 'API';
import ProgressSpinner from 'components/progress/progress-spinner';

export default function EditUserModal(props) {
  const {
    allUsers,
    handleClose,
    onEditComplete,
    open,
    setOpenEditToast,
    updateUsersList,
    user,
  } = props;
  
  const token = JSON.parse(localStorage.getItem('token'));
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  
  const [firstName, setFirstName] = useState(user.first_name ?? '');
  const [middleName, setMiddleName] = useState(user.middle_name ?? '');
  const [lastName, setLastName] = useState(user.last_name ?? '');
  const [email, setEmail] = useState(user.email ?? '');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(user.phone ?? '');
  const [role, setRole] = useState(user.role ?? '');
  const [active, setActive] = useState(user.active ?? false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  const handleOnEditComplete = (user) => {
    if (onEditComplete) {
      onEditComplete(user);
    }
  };
  
  const editUser = async () => {
    if (password.length > 0 && password.length < 9) {
      setError('Please enter a password with at least 9 characters.');
    } else if (!firstName || !lastName || !email || !phoneNumber) {
      setError('* Please fill out all required fields.');
    } else {
      let requestBody = {};
      if (user) {
        if (user?.first_name !== firstName) {
          requestBody.first_name = firstName;
        }
        if (user?.middle_name !== middleName) {
          requestBody.middle_name = middleName;
        }
        if (user?.last_name !== lastName) {
          requestBody.last_name = lastName;
        }
        if (user?.phone !== phoneNumber) {
          requestBody.phone = phoneNumber;
        }
        if (user?.email !== email) {
          requestBody.email = email;
        }
        if (user?.active !== active) {
          requestBody.active = active;
        }
        if (user?.role !== role) {
          requestBody.role = role;
        }
      } else {
        requestBody = {
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          phone: phoneNumber,
          email: email,
          active: active,
          role: role,
        };
      }
      if (password.length > 0) {
        requestBody.password = password;
      }
      
      setLoading(true);
      await fetch(`${API.endpointURL}/api/users/${user.id}`, {
        method: 'PUT',
        body: JSON.stringify(requestBody),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data['error']) {
            const dataError = data['error'];
            determineErrorMessage(dataError);
          } else {
            setPassword('');
            setError('');
            handleOnEditComplete(data);
            handleClose();
            setOpenEditToast(true);
            if (updateUsersList) {
              updateUsersList();
            }
          }
          setLoading(false);
        })
        .catch(() => {
          setError('Error updating user.');
          setLoading(false);
        });
    }
  };
  
  const determineErrorMessage = (errors) => {
    let errorMessage = '';
    let emailError = errors['email'] ? adminErrorMessages[errors['email']] : '';
    let emailUser = null;
    let phoneError = errors['phone'] ? adminErrorMessages[errors['phone']] : '';
    let phoneUser = null;
    if (
      emailError &&
      emailError === 'This email is already linked to another user'
    ) {
      emailUser = allUsers.find((person) => person.email === email);
    }
    if (
      phoneError &&
      phoneError === 'This phone number is already linked to another user'
    ) {
      phoneUser = allUsers.find((person) => person.phone === phoneNumber);
    }
    if (emailUser && phoneUser && emailUser.id === phoneUser.id) {
      errorMessage = `This email and phone number are already linked to another user: ${emailUser.first_name} ${emailUser.last_name}.`;
    } else {
      if (emailError) {
        if (emailUser) {
          errorMessage += `${emailError}: ${emailUser.first_name} ${emailUser.last_name}. `;
        } else {
          errorMessage += `${emailError}. `;
        }
      }
      if (phoneError) {
        if (phoneUser) {
          errorMessage += `${phoneError}: ${phoneUser.first_name} ${phoneUser.last_name}.`;
        } else {
          errorMessage += `${phoneError}.`;
        }
      }
    }
    setError(errorMessage);
  };
  
  const editUserForm = () => {
    return (
      <>
        <TextField
          fullWidth
          className="login-form-inputs"
          id="outlined-basic"
          label="First Name *"
          variant="outlined"
          margin="dense"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          fullWidth
          className="login-form-inputs"
          id="outlined-basic"
          label="Middle Initial "
          variant="outlined"
          margin="dense"
          value={middleName}
          onChange={(e) => setMiddleName(e.target.value)}
        />
        <TextField
          fullWidth
          className="login-form-inputs"
          id="outlined-basic"
          label="Last Name *"
          variant="outlined"
          margin="dense"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          fullWidth
          className="login-form-inputs"
          id="outlined-basic"
          label="Email *"
          variant="outlined"
          margin="dense"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          className="login-form-inputs"
          id="outlined-basic"
          label="Password"
          variant="outlined"
          margin="dense"
          value={password}
          error={password.length > 0 && password.length < 9}
          onChange={(e) => setPassword(e.currentTarget.value)}
          helperText={
            password.length > 0 && password.length < 9
            && 'Password must be at least 9 characters.'
          }
        />
        <TextField
          fullWidth
          className="login-form-inputs"
          id="outlined-basic"
          label="Phone Number *"
          variant="outlined"
          margin="dense"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <Grid container columns={2} spacing={3} mb={1}>
          <Grid item sx={{ width: '50%' }}>
            <FormControl
              sx={{ margin: '8px 0 4px', width: '100%' }}
              size="small"
            >
              <InputLabel id="demo-select-small">Role</InputLabel>
              <Select
                labelId="demo-select-small"
                id="edit-user-role"
                key="edit-user-role"
                value={role}
                label="Role"
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value={'accounting'}>Accounting</MenuItem>
                {loggedInUser.role === 'admin' && <MenuItem value={'admin'}>Admin</MenuItem>}
                <MenuItem value={'applicant'}>Applicant</MenuItem>
                <MenuItem value={'recruiter'}>Recruiter</MenuItem>
                <MenuItem value={'staffing'}>Staffing</MenuItem>
                <MenuItem value={'sales'}>Sales</MenuItem>
                <MenuItem value={'project manager'}>Project Manager</MenuItem>
                <MenuItem value={'project coordinator'}>Project Coordinator</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ margin: '8px 0 4px', width: '50%' }}>
            <FormControl sx={{ width: '100%' }} size="small">
              <InputLabel id="demo-select-small">Active</InputLabel>
              <Select
                labelId="demo-select-small"
                id="edit-user-active"
                key="edit-user-active"
                value={active}
                label="Active"
                onChange={(e) => setActive(e.target.value)}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {error && <div className="add-user-error">{error}</div>}
        <div className="button-container">
          {loading && (
            <div style={{ position: 'relative', width: '100px', overflow: 'hidden' }}>
              <div style={{ position: 'absolute', top: -30 }}><ProgressSpinner /></div>
            </div>
          )}
          <Button
            variant="contained"
            className="add-user-button"
            disabled={loading}
            sx={{
              backgroundColor: '#62AB37',
              border: '1px solid #62AB37',
              borderRadius: '10px',
              color: '#EFEFEF',
              fontFamily: 'MagistralCondW08-Medium',
              fontSize: '18px',
              minHeight: '25px',
              padding: '13px 20px',
              textTransform: 'capitalize',
              width: '175px',
              '&:hover': {
                backgroundColor: 'transparent',
                border: '1px solid #62AB37',
                boxShadow: 'none',
                color: '#62AB37',
              },
            }}
            onClick={() => editUser()}
          >
            Update
          </Button>
        </div>
      </>
    );
  };
  
  return (
    <CustomDialogRedesign
      colorPalette="green"
      dialogContent={editUserForm()}
      onClose={handleClose}
      open={open}
      title={'UPDATE'}
      titleHighlight={'USER'}
    />
  );
}
