import { createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from 'services/auth';

export const checkUniqueness = createAsyncThunk(
  'checkUniqueness',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await auth.checkUniqueness(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
