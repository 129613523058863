import React, { useState } from 'react';
import API from 'API';
import Toast from 'components/toast/toast';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getSalesDivisions } from 'store/sales/thunk';
import { salesDivisionsLoadingSelector, salesDivisionsSelector } from 'store/sales/selectors';

const initialErrors = {
  validation: false,
  server: false,
};

export const SalesDivisions = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  const dispatch = useAppDispatch();

  const [inputNameDivision, setInputNameDivision] = useState('');
  const [divisionName, setDivisionName] = useState({});
  const [message, setMessage] = useState('Please enter division name');
  const [isCreating, setIsCreating] = useState(true);
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const [loading, setLoading] = useState(false);

  const divisionList = useAppSelector(salesDivisionsSelector);
  const divisionLoading = useAppSelector(salesDivisionsLoadingSelector);

  // click on right table
  const handleItemClick = (item) => {
    setIsCreating(false);
    setDivisionName(item);
    setInputNameDivision(item.name);
  };

  const handleInputChange = (e) => {
    if (!e.target.value) {
      setMessage('Please enter division name');
      setErrors({
        ...errors,
        validation: true,
      });
    } else if (divisionList?.find(item => item.name === e.target.value)) {
      setMessage('Division already exists');
      setErrors({
        ...errors,
        validation: true,
      });
    } else {
      setMessage(`Division is successfully ${isCreating ? 'created' : 'updated'}`);
      setErrors({
        ...errors,
        validation: false,
      });
    }
    setInputNameDivision(e.target.value);
  };

  const handleCreate = () => {
    if (isCreating) {
      if (!errors.validation && inputNameDivision) {
        setLoading(true);
        createDivision({ name: inputNameDivision });
        setInputNameDivision('');
      } else {
        if (!inputNameDivision) {
          setMessage('Please enter valid division name...')
        }
        setOpenToast(true);
      }
    } else {
      setIsCreating(true);
      setInputNameDivision('');
    }
  };

  const handleUpdate = () => {
    setOpenToast(true);
    if (!errors.validation) {
      setLoading(true);
      setIsCreating(true);
      updateDivision({ name: inputNameDivision, id: divisionName.id });
      setInputNameDivision('');
    }
  };

  const updateDivision = (data) => {
    let requestError = false;

    try {
      fetch(`${API.endpointURL}/api/sales_division/${data.id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            requestError = true;
          }
          return res;
        })
        .then((res) => res.json())
        .then(() => {
          !requestError && dispatch(getSalesDivisions());
        })
        .then(() => {
          setOpenToast(true);
          setLoading(false);
          if (requestError) {
            setErrors({
              ...errors,
              server: true,
            });
          } else {
            setErrors({
              ...errors,
              server: false,
            });
          }
        });
    } catch (e) {
      setMessage(e.message);
      setLoading(false);
    }
  };

  const createDivision = (data) => {
    setLoading(true);
    let requestError = false;
    try {
      fetch(`${API.endpointURL}/api/sales_division`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then((res) => {
        if (!res.ok) {
          requestError = true;
        }
        return res;
      })
      .then((res) => res.json())
      .then(() => {
        !requestError && dispatch(getSalesDivisions());
      })
      .then(() => {
        setOpenToast(true);
        if (requestError) {
          setLoading(false);
          setErrors({
            ...errors,
            server: true,
          });
        } else {
          setLoading(false);
          setErrors({
            ...errors,
            server: false,
          });
        }
      })
      .catch((error) => {
        console.log(error, 'Error create Service.');
      });
    } catch (e) {
      setMessage(e.message);
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', gap: '10px' }}>
        <div className="content-container" style={{ height: 'calc(100vh - 236px)' }}>
          <div
            className="service-buttons"
            style={{ display: 'flex', alignItems: 'center', padding: '0 0 15px', gap: '10px' }}
          >
            <h3>Choose option:</h3>
            {!isCreating && (
              <button
                className="create-button"
                onClick={handleUpdate}
              >
                Update
              </button>
            )}
            <button
              className="create-button create-button--dark"
              onClick={handleCreate}
            >
              {isCreating ? 'Create' : 'Create new one'}
            </button>
          </div>

          <input
            className="sale-form-input"
            placeholder="Enter division name..."
            value={inputNameDivision || ''}
            name="division_name"
            onChange={handleInputChange}
            style={{ width: '100%' }}
          />
        </div>
        <div className="content-container" style={{ height: 'calc(100vh - 236px)' }}>
          {(loading || divisionLoading) ? (
            <div className="content-loading">
              <ProgressSpinnerNavy />
            </div>
          ) : (
            <>
              {divisionList?.length ? (
                <h3 style={{ margin: '0 10px 10px' }}>All divisions:</h3>
              ) : <h3>First, you need to create division</h3>}
              {divisionList?.map((item) => (
                <div
                  onClick={() => handleItemClick(item)}
                  key={item?.id}
                  className="sales-division"
                >
                  <h3>{item.name}</h3>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={message}
        />
      )}
    </>
  );
};
