import React from "react";
import { Button } from "@mui/material";

export const NavigateButtons = (props) => {
  const { back, next, error } = props;
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
      {error && (
        <p className="infoForm-p" style={{ color: '#a20601', marginTop: '12px' }}>
          * Please fill out all required fields.
        </p>
      )}
      {back && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            color: "#a5af96",
            fontFamily: "MagistralCondW08-Medium",
            fontSize: "18px",
            padding: "5px",
            width: "150px",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
              color: "#dae0bc",
            },
          }}
          onClick={back}
        >
          Back
        </Button>
      )}
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#dae0bc",
          color: "#3e453d",
          fontFamily: "MagistralCondW08-Medium",
          fontSize: "18px",
          width: "175px",
          "&:hover": { backgroundColor: "#a5af96", color: "#3e453d" },
        }}
        onClick={next}
      >
        Next
      </Button>
    </div>
  )
}
