import React, { useEffect, useState } from "react";
import "../../css/application.scss";
import "../../css/normalizeReactQuillLibrary.scss";
import "../../css/general-info-form.scss";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { ProgressSpinnerGreen } from "../progress/progress-spinner-green";
import { InputField } from "./inputField";
import { NavigateButtons } from "./navigateButtons";
import { referralTypesArray } from "./data";
import ReactQuill from "react-quill";
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { checkUniqueness } from 'store/auth/thunk';
import { uniqueSelector } from 'store/auth/selectors';
import { setStatus } from 'store/auth/slice';
import { Checkbox } from "@mui/material";
import { Link } from "react-router-dom";


export default function GeneralInfoForm(props) {
  const { applicantData, nextView, setApplicantData, setView, contractDescription, loading } = props;
  
  const dispatch = useAppDispatch();
  const { emailUnique, phoneUnique, emailError, phoneError, loadingUniqueness, status } = useAppSelector(uniqueSelector);
  
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    referralType: '',
    referralInput: '',
    can_receive_messages: false,
  });
  const handleInputChange = (field, value) => {
    setFormErrors({ ...formErrors, [field]: false });
    setFormData({ ...formData, [field]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) {
      errors.firstName = 'First name is required';
    }

    if (!formData.lastName) {
      errors.lastName = 'Last name is required';
    }

    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email';
    }

    if (!formData.phone) {
      errors.phone = 'Phone is required';
    }

    if (!formData.address) {
      errors.address = 'Street Address is required';
    }

    if (!formData.city) {
      errors.city = 'City is required';
    }

    if (!formData.state) {
      errors.state = 'State is required';
    }

    if (!formData.zip) {
      errors.zip = 'ZIP code is required';
    }

    if (!formData.referralType) {
      errors.referralType = 'Field is required';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (applicantData) {
      const {
        first_name,
        middle_name,
        last_name,
        email,
        phone,
        address,
        city,
        state,
        zip,
        referral_input,
        referral_type,
        can_receive_messages
      } = applicantData;

      setFormData((prevFormData) => ({
        ...prevFormData,
        firstName: first_name || '',
        middleName: middle_name || '',
        lastName: last_name || '',
        email: email || '',
        phone: phone || '',
        address: address || '',
        city: city || '',
        state: state || '',
        zip: zip || '',
        referralInput: referral_input || '',
        referralType: referral_type || '',
        can_receive_messages: can_receive_messages || false,
      }));
    }
  }, [applicantData]);
  
  useEffect(() => {
    if (status === 'fulfilled') {
      const validForm = validateForm();
      if (validForm) {
        if (emailUnique && phoneUnique) {
          const updatedData = {
            ...applicantData,
            first_name: formData.firstName,
            middle_name: formData.middleName,
            last_name: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            referral_type: formData.referralType,
            referral_input: formData.referralInput,
            can_receive_messages: formData.can_receive_messages,
          };
          setApplicantData(updatedData);
        }
        setView(nextView);
        dispatch(setStatus(''));
      }
    }
  }, [status]);
  
  const handleSaveForm = async () => {
    const validForm = validateForm();
 
    if (validForm) {
      const body = {
        email: formData.email,
        phone: formData.phone,
      }
      await dispatch(checkUniqueness({ body }))
    }
  };

  if (loading) {
    return (
      <div className="infoForm-container">
        <div className="spinner-fullScreen"><ProgressSpinnerGreen /></div>
      </div>
    )
  }

  return (
    <div className="infoForm-container">
      <h1 className="infoForm-h1">Application Form</h1>
      <p className="infoForm-p" style={{ maxWidth: "100%", textAlign: "center" }}>
        Please fill out all fields below and click "Next" to continue.
      </p>
      {contractDescription && (
        <ReactQuill
          readOnly
          theme="snow"
          value={contractDescription}
          modules={{toolbar: false}}
          className='quill-readOnly'
          style={{
            backgroundColor: '#262a25',
            color: '#EFEFEF',
          }}
        />
      )}
      <FormControl className="infoForm-column" sx={{ gap: "8px" }} fullWidth={true}>
        <h2 className="infoForm-h2">General</h2>
        <section className="infoForm-column" style={{ gap: '8px' }}>
          <div className="infoForm-row" style={{ gap: '20px' }}>
            <InputField
              name={'First Name'}
              value={formData.firstName}
              onChange={(e) => handleInputChange('firstName', e.target.value)}
              error={formErrors.firstName}
            />
            <InputField
              name={'Middle Initial'}
              value={formData.middleName}
              onChange={(e) => handleInputChange('middleName', e.target.value)}
            />
            <InputField
              name={'Last Name'}
              value={formData.lastName}
              onChange={(e) => handleInputChange('lastName', e.target.value)}
              error={formErrors.lastName}
            />
          </div>
          <div className="infoForm-row" style={{ gap: '20px' }}>
            <InputField
              name={'Email Address'}
              value={formData.email}
              onChange={(e) => handleInputChange('email', e.target.value.toLowerCase().trim())}
              error={formErrors.email}
              type="email"
            />
            <InputField
              name={'Phone Number'}
              value={formData.phone}
              onChange={(e) => handleInputChange('phone', e.target.value.trim().replace(/[^0-9+]+/g, ''))}
              error={formErrors.phone}
              type="tel"
            />
          </div>
        </section>
        <h2 className="infoForm-h2">Home Address</h2>
        <section className="infoForm-column" style={{ gap: '8px' }}>
          <div className="infoForm-row" style={{ gap: '20px' }}>
            <InputField
              name={'Street Address'}
              value={formData.address}
              onChange={(e) => handleInputChange('address', e.target.value)}
              error={formErrors.address}
            />
            <InputField
              name={'City'}
              value={formData.city}
              onChange={(e) => handleInputChange('city', e.target.value)}
              error={formErrors.city}
            />
          </div>
          <div className="infoForm-row" style={{ gap: '20px' }}>
            <InputField
              name={'State'}
              value={formData.state}
              onChange={(e) => handleInputChange('state', e.target.value)}
              error={formErrors.state}
            />
            <InputField
              name={'ZIP Code'}
              value={formData.zip}
              onChange={(e) => handleInputChange('zip', e.target.value)}
              error={formErrors.zip}
            />
          </div>
        </section>
        <h2 style={{ marginTop: '15px' }} className="infoForm-h2">Referral</h2>
        <section className="infoForm-row" style={{ gap: '15px', alignItems: 'end' }}>
          <div className="infoForm-inputContainer">
            <p className="infoForm-fieldName">How did you hear about us?</p>
            <Select
              displayEmpty
              value={formData.referralType}
              error={!!formErrors.referralType}
              onChange={(e) => handleInputChange('referralType', e.target.value)}
              sx={{
                backgroundColor: "#EFEFEF",
                border: 0,
                borderRadius: "8px",
                fontFamily: "Korolev Medium",
                width: "100%",
              }}
            >
              {referralTypesArray.map((referralType) => (
                <MenuItem
                  key={referralType.value}
                  value={referralType.value}
                  sx={{ fontFamily: "Korolev Medium" }}
                >
                  {referralType.value}
                </MenuItem>
              ))}
            </Select>
            {formErrors.referralType &&
              <p className="infoForm-error">{formErrors.referralType}</p>
            }
          </div>
          <div className="infoForm-inputContainer">
            {(formData.referralType === "Referral" || formData.referralType === "Other") && (
              <TextField
                fullWidth={true}
                placeholder={formData.referralType === "Referral" ? "Name" : "Please elaborate..."}
                variant="outlined"
                value={formData.referralInput}
                onChange={(e) => handleInputChange('referralInput', e.target.value)}
                sx={{
                  backgroundColor: "#3e453d",
                  borderRadius: "4px",
                  color: "#EFEFEF",
                  fontFamily: "Korolev Medium",
                  margin: "0 0 0 8px",
                  width: "100%",
                  input: {
                    color: "#EFEFEF",
                    fontFamily: "Korolev Medium",
                  },
                }}
              />
            )}
          </div>
        </section>
      </FormControl>
      <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.9em', marginTop: '0.6em'}}>
        <Checkbox
          style={{ marginBottom: "-1.3em"  }}
          disabled={false}
          checked={formData.can_receive_messages}
          onChange={(e) => handleInputChange('can_receive_messages', e.target.checked)}
          sx={{
            width: "20px",
            height: "20px",
            color: "#EFEFEF",
            padding: 0,
            "&.Mui-checked": {
              color: "#62AB37",
            },
            "&.Mui-disabled": {
              color: "#EFEFEF40",
            },
          }}
        />
        <span style={{ marginLeft: '20px', marginTop: '0.6em', marginBottom: "-1em"}}>
          I agree to receive text messages from Responsive Management at the phone number provided above related to my job application. 
          I understand message and data rates may apply. Message frequency varies. Reply STOP to unsubscribe.

          For more informatiom, please visit our <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: '#a5af96', display: 'inline' }}>Privacy Policy</Link> and <Link to="/terms-of-service" target="_blank" rel="noopener noreferrer" style={{ color: '#a5af96', display: 'inline' }}>Terms & Conditions</Link>.
        </span>
      </div>
      <div style={{ position: 'relative' }}>
        {loadingUniqueness &&
          <div style={{ position: 'absolute', right: '200px', top: '-8px' }}>
            <ProgressSpinnerGreen />
          </div>
        }
        {emailError && <p
          style={{ fontSize: '14px', top: phoneError ? '-23.5px' : '-1.5px' }}
          className="infoForm-error">
          {emailError}
        </p>
        }
        {phoneError && <p style={{ fontSize: '14px' }} className="infoForm-error">{phoneError}</p>}
        <NavigateButtons next={() => handleSaveForm()} />
      </div>
    </div>
  );
}
