import { createSlice } from '@reduxjs/toolkit';
import { getConnectionsAnalytics } from 'store/connections/thunk';

const initialState = {
  connectionsAnalytics: {
    connectionsAnalytics: null,
    loadingConnectionsAnalytics: false,
    errorConnectionsAnalytics: '',
  }
};

const connectionsSlice = createSlice({
  name: 'connectionsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConnectionsAnalytics.pending, (state) => {
      state.connectionsAnalytics.loadingConnectionsAnalytics = true;
      state.connectionsAnalytics.errorConnectionsAnalytics = '';
    });
    builder.addCase(getConnectionsAnalytics.fulfilled, (state, { payload }) => {
      state.connectionsAnalytics.loadingConnectionsAnalytics = false;
      state.connectionsAnalytics.errorConnectionsAnalytics = '';
      state.connectionsAnalytics.connectionsAnalytics = payload;
    });
    builder.addCase(getConnectionsAnalytics.rejected, (state) => {
      state.connectionsAnalytics.loadingConnectionsAnalytics = false;
      state.connectionsAnalytics.errorConnectionsAnalytics = 'Error Getting Connections Analytics';
      state.connectionsAnalytics.connectionsAnalytics = null;
    });
  },
});

export const {} = connectionsSlice.actions;

export default connectionsSlice.reducer;
