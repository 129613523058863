import React, { useState, useEffect } from 'react';
import Toast from 'components/toast/toast';
import ProgressSpinner from 'components/progress/progress-spinner';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { createTagGroup, getTagGroups, updateTagGroup } from 'store/tags/thunk';
import {
  createTagGroupStatusSelector,
  tagGroupsSelector,
  tagsLoadingSelector,
  updateTagGroupStatusSelector,
} from 'store/tags/selectors';
import { setCreateGroupStatus, setUpdateGroupStatus } from 'store/tags/slice';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ContractTagsDelete } from 'components/contracts/contracts-tags/contract-tags-delete';
import { IconButton, Tooltip } from '@mui/material';
import deleteButton from 'assets/delete.png';
import { ColorPicker } from 'components/widgets/ColorPicker';

const initialErrors = {
  validation: false,
  server: false,
};

export const ContractsTagsGroups = () => {
  const [inputNameGroup, setInputNameGroup] = useState('');
  const [inputColor, setInputColor] = useState('#ffffff');
  const [selectedGroup, setSelectedGroup] = useState({});
  const [message, setMessage] = useState('');
  const [isCreating, setIsCreating] = useState(true);
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const [deleteTagOptionOpenModal, setDeleteTagOptionOpenModal] = useState(false);

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();
  const loading = useAppSelector(tagsLoadingSelector);
  const createStatus = useAppSelector(createTagGroupStatusSelector);
  const updateStatus = useAppSelector(updateTagGroupStatusSelector);
  const groupList = useAppSelector(tagGroupsSelector);

  useEffect(() => {
    dispatch(getTagGroups());
  }, []);

  useEffect(() => {
    if (updateStatus === 'fulfilled') {
      setMessage('Tags Group is successfully updated.');
      setOpenToast(true);
      dispatch(setUpdateGroupStatus(''));
      dispatch(getTagGroups());
      setIsCreating(true);
      setInputNameGroup('');
      setInputColor('#ffffff');
    } else if (updateStatus === 'rejected') {
      setErrors({
        ...errors,
        server: true,
      });
      setMessage('Something went wrong. Please try again');
      dispatch(setUpdateGroupStatus(''));
    } else if (updateStatus === '') {
      setErrors({
        ...errors,
        server: false,
      });
    }
  },[updateStatus]);

  useEffect(() => {
    if (createStatus === 'fulfilled') {
      setInputNameGroup('');
      setInputColor('#ffffff');
      setOpenToast(true);
      setMessage('New Tags Group successfully created.');
      dispatch(setCreateGroupStatus(''));
      dispatch(getTagGroups());
    } else if (createStatus === 'rejected') {
      setErrors({
        ...errors,
        server: true,
      });
      setMessage('Something went wrong. Please try again');
      dispatch(setCreateGroupStatus(''));
    } else if (createStatus === '') {
      setErrors({
        ...errors,
        server: false,
      });
    }
  }, [createStatus]);

  const handleItemClick = (item) => {
    setIsCreating(false);
    setSelectedGroup(item);
    setInputNameGroup(item.title);
    setInputColor(item.color);
    setErrors({
      server: false,
      validation: false,
    });
    setMessage('');
  };

  const handleInputChange = (e) => {
    if (!e.target.value) {
      setMessage('Please enter group name.');
      setErrors({
        ...errors,
        validation: true,
      });
    } else if (
      isCreating
      && groupList.find(item => item.title === e.target.value)
    ) {
      setMessage('Tag Group already exists');
      setErrors({
        ...errors,
        validation: true,
      });
    } else if (
      !isCreating
      && groupList.find(item => {
        return item.title === e.target.value && item.id !== selectedGroup.id;
      })
    ) {
      setMessage('Tags Group already exists');
      setErrors({
        ...errors,
        validation: true,
      });
    } else {
      setMessage(`Tags Group is successfully ${isCreating ? 'created' : 'updated'}`);
      setErrors({
        ...errors,
        validation: false,
      });
    }
    setInputNameGroup(e.target.value);
  };

  const handleCreate = () => {
    if (isCreating) {
      if (!inputNameGroup || errors.validation) {
        setMessage('Please enter valid group name.');
        setErrors({
          ...errors,
          validation: true,
        });
      } else {
        dispatch(createTagGroup({
          body: {
            title: inputNameGroup,
            color: inputColor,
          },
        }));
      }
    } else {
      setIsCreating(true);
      setInputNameGroup('');
      setInputColor('#ffffff');
    }
  };

  const handleUpdate = () => {
    if (!errors.validation) {
      setIsCreating(true);
      dispatch(updateTagGroup({
        body: {
          title: inputNameGroup,
          color: inputColor,
        },
        id: selectedGroup.id,
      }))
      setInputNameGroup('');
      setInputColor('#ffffff');
    }
  };

  const clearInput = () => {
    setIsCreating(true);
    setInputNameGroup('');
    setInputColor('#ffffff');
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '10px' }}>
        <div className="content-container" style={{ height: 'calc(100vh - 236px)' }}>
          <div className="manage__group">
            <h3>Choose option:</h3>
            {!isCreating && (
              <button
                className={classNames('create-button', {
                  'create-button-blue': pathname.includes('applicant'),
                })}
                style={{ padding: '12px 20px' }}
                onClick={handleUpdate}
                disabled={loading}
              >
                Update
              </button>
            )}
            <button
              className={classNames('create-button', {
                'create-button-blue': pathname.includes('applicant'),
              })}
              style={{ padding: '12px 20px' }}
              onClick={handleCreate}
              disabled={loading}
            >
              {isCreating ? 'Create' : 'Create new one'}
            </button>

            {Object.values(errors).includes(true) && (
              <p className="manage__error">
                {message}
              </p>
            )}
          </div>

          <input
            className="sale-form-input"
            placeholder="Enter group name..."
            value={inputNameGroup || ''}
            onChange={handleInputChange}
            style={{ width: '100%' }}
          />
          <div className="sales-division-color-container">
            <p>Group color:</p>
            <ColorPicker inputColor={inputColor} setInputColor={setInputColor} />
          </div>
        </div>
        <div
          className="content-container"
          style={{
            height: 'calc(100vh - 236px)',
          }}
        >
          {loading ? (
            <div className="content-loading">
              <ProgressSpinner />
            </div>
          ) : (
            <>
              {groupList?.length ? (
                <h3 style={{ margin: '0 10px 10px' }}>All Groups:</h3>
              ) : <h3>First, you need to add groups</h3>}
              {groupList?.map((item) => (
                <div
                  onClick={() => handleItemClick(item)}
                  key={item?.id}
                  className="sales__tagType-item"
                  style={{ paddingLeft: '30px' }}
                >
                  <div
                    className="sales-division-color"
                    style={{ backgroundColor: item.color }}
                  />
                  <div className="sales__tagGroup-item">
                    <h3>{item.title}</h3>
                    <Tooltip title="Delete tag group">
                      <IconButton
                        edge="start"
                        aria-label="delete-group"
                        sx={{
                          padding: 0,
                          margin: 0,
                          color: '#3e453d',
                        }}
                        onClick={() => {
                          setDeleteTagOptionOpenModal(true);
                        }}
                      >
                        <img
                          src={deleteButton}
                          width="20px"
                          height="auto"
                          alt="delete-button"
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={message}
        />
      )}
      {deleteTagOptionOpenModal && (
        <ContractTagsDelete
          deleteTagOptionOpenModal={deleteTagOptionOpenModal}
          setDeleteTagOptionOpenModal={setDeleteTagOptionOpenModal}
          selected={selectedGroup}
          setSelected={setSelectedGroup}
          clear={clearInput}
          setMessage={setMessage}
          setOpenToast={setOpenToast}
        />
      )}
    </>
  );
};
