import '../../css/contracts.scss';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import Toast from '../toast/toast';
import EditContractURL from 'components/modal/edit-contract-url';

import API from 'API';
import ReactQuill from 'react-quill';
import { Tooltip } from '@mui/material';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';

export default function CreateStaffingRequest(props) {
  const { currentContract, getContracts, setCurrentContract, id } = props;
  
  const token = JSON.parse(localStorage.getItem('token'));
  
  const [contractTitle, setContractTitle] = useState('');
  const [contractPersonnel, setContractPersonnel] = useState('');
  const [contractSecurity, setContractSecurity] = useState('');
  const [contractStart, setContractStart] = useState(null);
  const [contractDeadline, setContractDeadline] = useState(null);
  const [contractEnd, setContractEnd] = useState(null);
  const [contractURL, setContractURL] = useState('');
  const [error, setError] = useState(null);
  const [openToast, setOpenToast] = useState(false);
  const [openURLModal, setOpenURLModal] = useState(false);
  const [openURLToast, setOpenURLToast] = useState(false);
  const [openCopyToast, setOpenCopyToast] = useState(false);
  const [description, setDescription] = useState('<p><br></p>');
  
  const [loading, setLoading] = useState(false);
  
  const createContract = async () => {
    if (!contractURL) {
      setError('Please, provide a unique URL for your contract application.');
    } else if (contractURL.length >= 50 || !isNaN(contractURL.charAt(0)) || contractURL.indexOf(' ') >= 0) {
      setError('There was an error saving the URL. Please confirm the URL does not exceed 50 characters, does not begin with a number and does not contain spaces.');
    } else if (!contractTitle) {
      setError('Contract title is a required field.');
    } else if (!contractSecurity) {
      setError('Contract security is a required field.');
    } else {
      
      if (contractSecurity == 'None') {
        setContractSecurity(null);
      }
      
      try {
        setLoading(true);
        const response = await fetch(`${API.endpointURL}/api/contracts/`, {
          method: 'POST',
          body: JSON.stringify({
            title: contractTitle,
            personel_need: contractPersonnel,
            security: contractSecurity,
            contract_alias: contractURL,
            contract_start: contractStart ? new Date(contractStart).toISOString() : null,
            contract_deadline: contractDeadline ? new Date(contractDeadline).toISOString() : null,
            contract_end: contractEnd ? new Date(contractEnd).toISOString() : null,
            contract_description: description,
            vendor_id: id,
            
            // All contract that are being created from staffing request
            // tab, should be marked as "Pending" until staffing team
            // sets different status
            status: 'pending',
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          },
        });
        
        const data = await response.json();
        
        if (!response.status >= 200 && !response.status < 300) {
          setError('There was an error saving the contract. Please refresh the page and try again.');
          setLoading(false);
          return;
        }
        
        if (data.error_key === 'contract_alias already exists') {
          setError('This URL is already associated with another contract.');
          setLoading(false);
          return;
        }
        
        setLoading(false);
        setError(null);
        setOpenToast(true);
        setContractTitle('');
        setContractPersonnel('');
        setContractSecurity('');
        setContractStart(null);
        setContractDeadline(null);
        setContractEnd(null);
        setContractURL('');
        setDescription('<p><br></p>');
      } catch (err) {
        setLoading(false);
      }
    }
  };
  
  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],
      ['link'],
      [{ 'align': [] }],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: true,
    },
  };
  
  return (
    <>
      <h1 className="content-header">Create Staffing Request</h1>
      <div className="content-container">
        <div className="contract-form-section">
          <div className="contract-form-section-header">General Information</div>
          <div className="contract-form-section-content" style={{ gap: 0 }}>
            <div className="contract-form-set">
              <div className="contract-form-label">Contract Title</div>
              <input
                className="contract-form-input"
                placeholder="Enter Contract Title..."
                value={contractTitle || ''}
                onChange={(e) => setContractTitle(e.target.value)}
              />
            </div>
            <div className="contract-form-set">
              <div className="contract-form-label">Personnel Needed</div>
              <input
                className="contract-form-input"
                placeholder="Enter Personnel Needed..."
                type="number"
                min="0"
                value={contractPersonnel || ''}
                onChange={(e) => setContractPersonnel(e.target.value)}
              />
            </div>
            <div className="contract-form-set">
              <div className="contract-form-label">Security Level</div>
              <select
                className="contract-form-input"
                placeholder="Enter Security Level..."
                value={contractSecurity || ''}
                onChange={(e) => setContractSecurity(e.currentTarget.value)}
              >
                <option value="">Select Security Level...</option>
                <option value="None">None</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
                <option value="4">Four</option>
                <option value="5">Five</option>
              </select>
            </div>
            <div className="contract-form-set">
              <div className="contract-form-label">Contract Start</div>
              <DatePicker
                className="contract-form-input"
                selected={contractStart}
                onChange={(date) => setContractStart(date)}
                dateFormat="MM/dd/yyyy"
              />
            </div>
            <div className="contract-form-set">
              <div className="contract-form-label">Contract Deadline</div>
              <DatePicker
                className="contract-form-input"
                selected={contractDeadline}
                onChange={(date) => setContractDeadline(date)}
                dateFormat="MM/dd/yyyy"
              />
            </div>
            <div className="contract-form-set">
              <div className="contract-form-label">Contract End</div>
              <DatePicker
                className="contract-form-input"
                selected={contractEnd}
                onChange={(date) => setContractEnd(date)}
                dateFormat="MM/dd/yyyy"
              />
            </div>
            <div className="contract-form-set-full contract-form-quill">
              <div className="sale-form-label">Contract Description</div>
              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                modules={modules}
                style={{
                  backgroundColor: '#EFEFEF40',
                  color: '#EFEFEF',
                  borderRadius: '10px',
                  width: '97.35%',
                  height: '325px',
                }}
              />
            </div>
            {currentContract ? (
              <div className="contract-form-set-full">
                <div className="contract-form-label">Application URL</div>
                {currentContract.contract_alias ? (
                  <div style={{ fontFamily: 'Korolev Medium', fontSize: '15px' }}>
                    {`https://responsivemanagement.org/application/${currentContract.contract_alias}`}
                    <Tooltip title="Edit link">
                      <i
                        className="fa-solid fa-pencil edit-icon"
                        onClick={() => setOpenURLModal(true)}
                      />
                    </Tooltip>
                    <Tooltip title="Copy link">
                      <i
                        className="fa-solid fa-copy edit-icon copy-icon"
                        onClick={() => {
                          setOpenCopyToast(true);
                          navigator.clipboard.writeText(`https://responsivemanagement.org/application/${contractURL}`);
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <>
                    <input
                      className="contract-form-input"
                      placeholder="Enter Application URL..."
                      value={contractURL || ''}
                      onChange={(e) => setContractURL(e.target.value)}
                      style={{ width: '97.35%' }}
                    />
                    <div
                      style={{
                        color: '#EFEFEF',
                        fontFamily: 'Korolev Medium',
                        fontSize: '14px',
                        marginLeft: '13px',
                        marginTop: '10px',
                      }}
                    >
                      https://responsivemanagement.org/application/{contractURL}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="contract-form-set-full">
                <div className="contract-form-label">Application URL</div>
                <input
                  className="contract-form-input"
                  placeholder="Enter Application URL..."
                  value={contractURL}
                  onChange={(e) => setContractURL(e.target.value)}
                  style={{ width: '97.35%' }}
                />
                <div
                  style={{
                    color: '#EFEFEF',
                    fontFamily: 'Korolev Medium',
                    fontSize: '14px',
                    marginLeft: '13px',
                    marginTop: '10px',
                  }}
                >
                  https://responsivemanagement.org/application/{contractURL}
                </div>
              </div>
            )}
            {error && (
              <div
                style={{
                  fontFamily: 'Korolev Medium',
                  textAlign: 'center',
                  color: '#a20601',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                }}
              >
                {error}
              </div>
            )}
          </div>
          <div className="contract-form-button-container">
            {loading && (
              <div className="sale-form-spinner">
                <ProgressSpinnerNavy />
              </div>
            )}
            <button
              style={{
                backgroundColor: '#808080',
                color: 'white',
                border: '2px solid #808080',
                outline: 'none',
              }}
              className="create-button"
              disabled={loading}
              onClick={() => createContract()}
            >
              Create
            </button>
          </div>
          {openURLModal && (
            <EditContractURL
              contract={currentContract}
              setCurrentContract={setCurrentContract}
              getContracts={getContracts}
              handleClose={() => setOpenURLModal(false)}
              open={openURLModal}
              setOpenToast={() => setOpenURLToast(true)}
            />
          )}
          {openToast && (
            <Toast
              open={openToast}
              onClose={() => setOpenToast(false)}
              message="Contract saved"
            />
          )}
          {openURLToast && (
            <Toast
              open={openURLToast}
              onClose={() => setOpenURLToast(false)}
              message="Application URL saved"
            />
          )}
          {openCopyToast && (
            <Toast
              open={openCopyToast}
              onClose={() => setOpenCopyToast(false)}
              message="Application URL copied"
            />
          )}
        </div>
      </div>
    </>
  );
};
