import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SalesConnectionCreate } from 'components/modal/sales-connection-create';
import ConnectionsTabs from 'components/tabs/connections-tabs';
import { HighlightNavyAutoStyledButton } from 'components/buttons/button-styles';
import Search from '../../assets/icon-bar-search.png';
import API from 'API';
import NewBaseModal from 'components/modal/new-base-modal';
import ViewNotes from 'components/modal/view-notes-modal';
import DocumentIcon from 'assets/document-icon.svg';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { numberWithCommas } from 'shared/formatting';
import classNames from 'classnames';

export const SalesConnections = (props) => {
  const {
    connection,
    sale,
    getSale,
    createButtonNeeded = false,
    getConnection,
    searchBar,
    loadingProp = false,
    title,
    project,
    getProject,
  } = props;
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const token = JSON.parse(localStorage.getItem('token'));
  const [currentTab, setCurrentTabState] = useState('Personal');
  const [openConnectionModal, setOpenConnectionModal] = useState(false);
  const [connections, setConnections] = useState([]);
  const [personalConnections, setPersonalConnections] = useState([]);
  const [companyConnections, setCompanyConnections] = useState([]);
  const [projects, setProjects] = useState([]);
  const [creatingStep, setCreatingStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [dataSearchFromReq, setDataSearchFromReq] = useState([]);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [sales, setSales] = useState([]);
  const [loadingSales, setLoadingSales] = useState(false);
  const [errorLoadingSales, setErrorLoadingSales] = useState('');

  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [currentConnection, setCurrentConnection] = useState(false);


  const getConnectionSales = async () => {
    setErrorLoadingSales('');
    setLoadingSales(true);
    try {
      const response = await fetch(`${API.endpointURL}/api/filter/sales?connection_id=${connection.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        setSales([]);
        setLoadingSales(false);
        setErrorLoadingSales('Error getting sales.');
        return;
      }

      const json = await response.json();
      setSales(json);
      setLoadingSales(false);
      setErrorLoadingSales('');
    } catch (error) {
      setSales([]);
      setLoadingSales(false);
      setErrorLoadingSales('Error getting sales.');
    }
  };

  const handleChangeSearchBar = (e) => {
    const inputValue = e.target.value;
    if (inputValue === '') {
      setSearchValue(null);
      setDataSearchFromReq([]);
      setLoading(false);
    } else {
      setSearchValue(inputValue);
      setLoading(true);
    }

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newDebounceTimer = setTimeout(() => {
      if (inputValue) {
        getSearchedName(inputValue);
      }
    }, 1000);

    setDebounceTimer(newDebounceTimer);
  };

  const clearSearch = () => {
    setSearchValue(null);
  };

  const setCurrentTab = (tab) => {
    setCurrentTabState(tab);
    clearSearch();
  };

  const handleAddClick = () => {
    setOpenConnectionModal(true);
    setCreatingStep(6);
  };

  const handleCreateClick = () => {
    setOpenConnectionModal(true);
    setCreatingStep(1);
  };

  useEffect(() => {
    if (connection) {
      getConnectionSales();
      setProjects(connection.projects);
    }
  }, [connection]);

  useEffect(() => {
    buildConnectionRows();
  }, [dataSearchFromReq]);

  useEffect(() => {
    if (sale) {
      setConnections([...sale?.connections]?.reverse());
    } else if (project) {
      setConnections([...project?.connections]?.reverse());
    } else if (connection) {
      setPersonalConnections(connection.personal_connections);
      setCompanyConnections(connection.company_connections);
    }
  }, [sale, connection, project]);

  useEffect(() => {
    if (sale || project) {
      filterConnections();
    } else if (!connection) {
      getAllConnections();
    }
  }, [connections]);

  const getAllConnections = () => {
    getCompanyConnections();
    getPersonalConnections();
  };

  const getPersonalConnections = () => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/filter/connections?connection_type=personal`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setPersonalConnections(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  const getCompanyConnections = () => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/filter/connections?connection_type=company`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setCompanyConnections(res.data);
      })
      .catch(() => setLoading(false));
  };

  const getSearchedName = (name) => {
    let tabParam;
    if (currentTab === 'Personal') tabParam = 'personal';
    if (currentTab === 'Companies') tabParam = 'company';

    fetch(`${API.endpointURL}/api/filter/connections?connection_type=${tabParam}&full_name=${name}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setDataSearchFromReq(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  /* FILTER CONNECTIONS BASED ON THEIR TYPE: PERSONAL, BUSINESS, COMPETITOR */
  const filterConnections = () => {
    let personal = [];
    let company = [];

    if (connections && connections.length > 0) {
      connections?.forEach((connection) => {
        if (connection.connection_type?.toLowerCase() === 'personal') {
          personal.push(connection);
        } else  {
          company.push(connection);
        }
      });
    }

    setPersonalConnections(personal);
    setCompanyConnections(company);
  };

  /* BUTTON OPENS MODAL WITH ALL NOTES */
  const noteButton = (connection) => {
    if (connection.notes.length > 0) {
      return (
        <div className="document-icon-btn active" onClick={() => {
          setOpenNotesModal(true);
          setCurrentConnection(connection);
        }}>
          <img src={DocumentIcon} alt="" />
        </div>
      );
    } else {
      return (
        <div className="document-icon-btn disabled">
          <img src={DocumentIcon} alt="" />
        </div>
      );
    }
  };

  /* BUILD HEADERS FOR CONNECTION TABLE */
  const buildTableHeader = () => {
    return (
      <tr className="row-border">
        <th
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') ||
              pathname.includes('connection-portal') ||
              pathname.includes('sales'),
          })}
          scope="col"
        >
          Connection Name
        </th>
        <th
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') ||
              pathname.includes('connection-portal') ||
              pathname.includes('sales'),
          })}
          scope="col"
        >
          Phone Number
        </th>
        <th
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') ||
              pathname.includes('connection-portal') ||
              pathname.includes('sales'),
          })}
          scope="col"
        >
          Email
        </th>
        {(pathname === '/sales' || pathname === '/projects') ? (
          <th
            className={classNames('contracts-header tableLayout__th', {
              'tableLayout__th--blue': pathname.includes('project') ||
                pathname.includes('connection-portal') ||
                pathname.includes('sales'),
            })}
            scope="col"
          >
            Type
          </th>
        ) : (
          <>
            <th
              className={classNames('contracts-header tableLayout__th', {
                'tableLayout__th--blue': pathname.includes('project') ||
                  pathname.includes('connection-portal') ||
                  pathname.includes('sales'),
              })}
              scope="col"
            >
              Relationship
            </th>
            <th
              className={classNames('contracts-header tableLayout__th', {
                'tableLayout__th--blue': pathname.includes('project') ||
                  pathname.includes('connection-portal') ||
                  pathname.includes('sales'),
              })}
              scope="col"
            >
              Notes
            </th>
          </>
        )}
        <th
          scope="col"
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') ||
              pathname.includes('connection-portal') ||
              pathname.includes('sales'),
          })}
        />
      </tr>
    );
  };

  /* BUILD HEADERS FOR SALES TABLE */
  const buildSalesTableHeader = () => {
    return (
      <tr className="row-border">
        <th
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') || pathname.includes('connection-portal'),
          })}
          scope="col"
        >
          Opportunity Name
        </th>
        <th
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') || pathname.includes('connection-portal'),
          })}
          scope="col"
        >
          Division
        </th>
        <th
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') || pathname.includes('connection-portal'),
          })}
          scope="col"
        >
          Company name
        </th>
        <th
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') || pathname.includes('connection-portal'),
          })}
          scope="col"
        >
          Description
        </th>
        <th
          className={classNames('arrow tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') || pathname.includes('connection-portal'),
          })}
          scope="col"
        />
      </tr>
    );
  };

  /* BUILD HEADERS FOR SALES TABLE */
  const buildProjectTableHeader = () => {
    return (
      <tr className="row-border">
        <th
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') || pathname.includes('connection-portal'),
          })}
          scope="col"
        >
          Project Name
        </th>
        <th
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') || pathname.includes('connection-portal'),
          })}
          scope="col"
        >
          Project Manager
        </th>
        <th
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') || pathname.includes('connection-portal'),
          })}
          scope="col"
        >
          Project Start Date
        </th>
        <th
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') || pathname.includes('connection-portal'),
          })}
          scope="col"
        >
          Project End Date
        </th>
        <th
          className={classNames('contracts-header tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') || pathname.includes('connection-portal'),
          })}
          scope="col"
        >
          Estimated Value
        </th>
        <th
          className={classNames('arrow tableLayout__th', {
            'tableLayout__th--blue': pathname.includes('project') || pathname.includes('connection-portal'),
          })}
          scope="col"
        />
      </tr>
    );
  };

  /* BUILD ROWS FOR SALES TABLE */
  const buildSalesRows = () => {
    if (loadingSales && !errorLoadingSales) {
      return (
        <tr>
          <td colSpan={5} style={{ textAlign: 'center' }}>
            <ProgressSpinnerNavy />
          </td>
        </tr>
      );
    } else if (!loadingSales && errorLoadingSales) {
      return (
        <tr>
          <td colSpan={5} style={{ textAlign: 'center', padding: '30px' }}>
            {errorLoadingSales}
          </td>
        </tr>
      );
    } else if (!loadingSales && !errorLoadingSales && sales && sales.length > 0) {
      return sales.map(sale => (
        <tr
          key={sale.id}
          className={'contract-row'}
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/sales-portal/${sale.id}`)}
        >
          <td className="contract-data">
            {sale.opportunity_name || 'N/A'}
          </td>
          <td className="contract-data">
            {sale.division?.name || 'N/A'}
          </td>
          <td className="contract-data">
            {sale.company_name || 'N/A'}
          </td>
          <td className="contract-data" style={{ maxWidth: '300px' }}>
            {sale.description || 'N/A'}
          </td>
          <td className="contract-data">
            <i className={`fa-solid fa-angle-right tab-arrow-icon navy-arrow`} />
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={5} style={{ textAlign: 'center', padding: '30px' }}>
            No results found
          </td>
        </tr>
      );
    }
  };

  /* BUILD ROWS FOR SALES TABLE */
  const buildProjectsRows = () => {
    if (projects.length > 0) {
      return projects.map(item => (
        <tr
          key={item.id}
          className={'contract-row'}
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/project-portal/${item.id}`)}
        >
          <td className="contract-data">
            {item.project_name || 'N/A'}
          </td>
          <td className="contract-data">
            {`${item.project_manager_data?.first_name} ${item.project_manager_data?.last_name}` || 'N/A'}
          </td>
          <td className="contract-data">
            {item.project_start_date || 'N/A'}
          </td>
          <td className="contract-data">
            {item.project_end_date || 'N/A'}
          </td>
          <td className="contract-data">
            {item?.estimated_value ? numberWithCommas(item?.estimated_value) : "N/A"}
          </td>
          <td className="contract-data">
            <i className={`fa-solid fa-angle-right tab-arrow-icon navy-arrow`} />
          </td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={5} style={{ textAlign: 'center', padding: '30px' }}>
            No results found
          </td>
        </tr>
      );
    }
  };

  /* BUILD ACTUAL ROWS OF CONNECTION TABLE (BASED ON DATA FROM RESPONSE) */
  const buildConnectionRows = () => {
    let connectionRows = [];
    let dataToDisplay;

    if (searchValue !== null) {
      dataToDisplay = dataSearchFromReq;
      if (dataSearchFromReq?.length === 0 && searchValue !== '') {
        dataToDisplay = [];
      }
    } else {
      if (currentTab === 'Personal') {
        dataToDisplay = personalConnections;
      } else if (currentTab === 'Companies') {
        dataToDisplay = companyConnections;
      }
    }

    if (dataToDisplay && dataToDisplay.length > 0) {
      {
        dataToDisplay?.forEach((connection, index) => {
          let relationship = 'N/A';
          if (connection.relationship_notes && connection.relationship_notes[0]) {
            relationship = connection.relationship_notes[0].body;
          }

          connectionRows.push(
            <tr
              key={index}
              className={
                index === dataToDisplay.length - 1
                  ? 'contract-row'
                  : 'contract-row row-border'
              }
              style={{
                cursor: (pathname === '/sales' || pathname === '/projects')
                  ? 'pointer'
                  : 'initial',
              }}
              onClick={
                (pathname === '/sales' || pathname === '/projects')
                  ? (() => navigate(`/connection-portal/${connection.id}`))
                  : undefined
              }
            >
              <td className="contract-data">
                {connection.full_name}
              </td>
              <td className="contract-data">
                {connection.phone_number}
              </td>
              <td className="contract-data">
                {connection.email}
              </td>
              {(pathname === '/sales' || pathname === '/projects') ? (
                <td className="contract-data" style={{ textTransform: 'capitalize' }}>
                  {connection.connection_type}
                </td>
              ) : (
                <>
                  <td className="contract-data" style={{ maxWidth: '300px' }}>
                    {relationship}
                  </td>
                  <td className="contract-data">
                    {noteButton(connection)}
                  </td>
                </>
              )}
              <td
                className="contract-data"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/connection-portal/${connection.id}`)}
              >
                <i className={`fa-solid fa-angle-right tab-arrow-icon navy-arrow`} />
              </td>
            </tr>,
          );
        });
      }
    } else {
      connectionRows.push(
        <tr key={Date.now()}>
          <td colSpan={5} style={{ textAlign: 'center', padding: '30px' }}>
            No results found
          </td>
        </tr>,
      );
    }
    return connectionRows;
  };

  return (
    <div className="sales-page__connection">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >

        <ConnectionsTabs
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          addSalesTab={connection ? true : false}
        />

        <div className="sales-page__connection-buttons">
          {(sale || connection || project) && (
            <div style={{ width: '155px' }}>
              <HighlightNavyAutoStyledButton
                onClick={handleAddClick}
              >
                Add Connection
              </HighlightNavyAutoStyledButton>
            </div>
          )}

          {/* CREATE CONNECTION BUTTON - ALL FUNCTIONALITY IS IMPLEMENTED IN THE "ADD CONNECTION" BUTTON */}
          {createButtonNeeded && (
            <div style={{ width: '155px' }}>
              <HighlightNavyAutoStyledButton onClick={handleCreateClick}>
                Create Connection
              </HighlightNavyAutoStyledButton>
            </div>
          )}
        </div>
      </div>
      {searchBar && (
        <div style={{ position: 'relative' }}>
          <input
            className="contract-form-input contract-form-input-search"
            style={{
              margin: '10px 0', width: '100%', height: '40px', position: 'relative',
            }}
            placeholder={`Search ${currentTab.toLowerCase()} connection `}
            value={searchValue || ''}
            name="name"
            onChange={(e) => handleChangeSearchBar(e)}
          />
          <img
            src={Search}
            alt="ff"
            style={{
              top: '12px',
              position: 'absolute',
              right: '15px',
              height: '33px',
            }}
          />
        </div>
      )}

      {/* Contents */}
      <div className="content-container no-content-padding" style={{ height: searchBar && 'calc(100vh - 247px)' }}>
        {(loading || loadingProp) ? (
          <div className="content-loading">
            <ProgressSpinnerNavy />
          </div>
        ) : (
          <table className="contracts-table tableLayout">
            {connection && currentTab === 'Projects' && (
              <>
                <thead>{buildProjectTableHeader()}</thead>
                <tbody>{buildProjectsRows()}</tbody>
              </>
            )}
            {connection && currentTab === 'Sales' && (
              <>
                <thead>{buildSalesTableHeader()}</thead>
                <tbody>{buildSalesRows()}</tbody>
              </>
            )}
            {currentTab !== 'Projects' && currentTab !== 'Sales' && (
              <>
                <thead>{buildTableHeader()}</thead>
                <tbody>{buildConnectionRows()}</tbody>
              </>
            )}
          </table>
        )}
      </div>
      {openConnectionModal && (
        <SalesConnectionCreate
          handleClose={() => setOpenConnectionModal(false)}
          open={openConnectionModal}
          creatingStep={creatingStep}
          sale={sale}
          getSale={getSale}
          connection={connection}
          getConnection={getConnection}
          getConnections={getAllConnections}
          title={title}
          project={project}
          getProject={getProject}
        />
      )}
      {openNotesModal && (
        <NewBaseModal
          dialogContent={<ViewNotes modalOpened={openNotesModal} notes={currentConnection.notes} />}
          onClose={() => setOpenNotesModal(false)}
          open={openNotesModal}
          title={'View notes'}
          colorPalette="navy"
        />
      )}
    </div>
  );
};
