import React, { useState, useEffect } from 'react';
import Toast from 'components/toast/toast';
import ProgressSpinner from 'components/progress/progress-spinner';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setCreateTypeStatus, setUpdateTypeStatus } from 'store/tags/slice';
import {
  createTagTypeStatusSelector,
  tagsLoadingSelector,
  tagTypesSelector,
  updateTagTypeStatusSelector,
} from 'store/tags/selectors';
import { createTagType, getTagTypes, updateTagType } from 'store/tags/thunk';
import { IconButton, Tooltip } from '@mui/material';
import deleteButton from 'assets/delete.png';
import { ContractTagsDelete } from 'components/contracts/contracts-tags/contract-tags-delete';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

const initialErrors = {
  validation: false,
  server: false,
};

export const ContractsTagsTypes = () => {
  const [inputNameType, setInputNameType] = useState('');
  const [selectedType, setSelectedType] = useState({});
  const [message, setMessage] = useState('');
  const [isCreating, setIsCreating] = useState(true);
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const [deleteTagOptionOpenModal, setDeleteTagOptionOpenModal] = useState(false);

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();
  const loading = useAppSelector(tagsLoadingSelector);
  const createStatus = useAppSelector(createTagTypeStatusSelector);
  const updateStatus = useAppSelector(updateTagTypeStatusSelector);
  const typeList = useAppSelector(tagTypesSelector);

  useEffect(() => {
    dispatch(getTagTypes());
  }, []);

  useEffect(() => {
    if (updateStatus === 'fulfilled') {
      setMessage('Tags Type is successfully updated.');
      setOpenToast(true);
      dispatch(setUpdateTypeStatus(''));
      dispatch(getTagTypes());
      setIsCreating(true);
      setInputNameType('');
    } else if (updateStatus === 'rejected') {
      setErrors({
        ...errors,
        server: true,
      });
      setMessage('Something went wrong. Please try again');
      dispatch(setUpdateTypeStatus(''));
    } else if (updateStatus === '') {
      setErrors({
        ...errors,
        server: false,
      });
    }
  },[updateStatus]);

  useEffect(() => {
    if (createStatus === 'fulfilled') {
      setInputNameType('');
      setOpenToast(true);
      setMessage('New Tags Type successfully created.');
      dispatch(setCreateTypeStatus(''));
      dispatch(getTagTypes());
    } else if (createStatus === 'rejected') {
      setErrors({
        ...errors,
        server: true,
      });
      setMessage('Something went wrong. Please try again');
      dispatch(setCreateTypeStatus(''));
    } else if (createStatus === '') {
      setErrors({
        ...errors,
        server: false,
      });
    }
  }, [createStatus]);

  const handleItemClick = (item) => {
    setIsCreating(false);
    setSelectedType(item);
    setInputNameType(item.title);
    setErrors({
      server: false,
      validation: false,
    });
    setMessage('');
  };

  const handleInputChange = (e) => {
    if (!e.target.value) {
      setMessage('Please enter type name.');
      setErrors({
        ...errors,
        validation: true,
      });
    } else if (
      isCreating
        && typeList.find(item => item.title === e.target.value)
    ) {
      setMessage('Tags type already exists');
      setErrors({
        ...errors,
        validation: true,
      });
    } else if (
      !isCreating
        && typeList.find(item => {
          return item.title === e.target.value && item.id !== selectedType.id;
        })
    ) {
      setMessage('Tags type already exists');
      setErrors({
        ...errors,
        validation: true,
      });
    } else {
      setMessage(`Tags type is successfully ${isCreating ? 'created' : 'updated'}`);
      setErrors({
        ...errors,
        validation: false,
      });
    }
    setInputNameType(e.target.value);
  };

  const handleCreate = () => {
    if (isCreating) {
      if (!inputNameType || errors.validation) {
        setMessage('Please enter valid type name.');
        setErrors({
          ...errors,
          validation: true,
        });
      } else {
        dispatch(createTagType({ body: { title: inputNameType } }));
      }
    } else {
      setIsCreating(true);
      setInputNameType('')
    }
  };

  const handleUpdate = () => {
    if (!errors.validation) {
      setIsCreating(true);
      dispatch(updateTagType({
        body: { title: inputNameType },
        id: selectedType.id,
      }));
      setInputNameType('');
    }
  };

  const clearInput = () => {
    setIsCreating(true);
    setInputNameType('')
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '10px' }}>
        <div className="content-container" style={{ height: 'calc(100vh - 236px)' }}>
          <div className="manage__group">
            <h3>Choose option:</h3>
            {!isCreating && (
              <button
                className={classNames('create-button', {
                  'create-button-blue': pathname.includes('applicant'),
                })}
                style={{ padding: '12px 20px' }}
                onClick={handleUpdate}
                disabled={loading}
              >
                Update
              </button>
            )}
            <button
              className={classNames('create-button', {
                'create-button-blue': pathname.includes('applicant'),
              })}
              style={{ padding: '12px 20px' }}
              onClick={handleCreate}
              disabled={loading}
            >
              {isCreating ? 'Create' : 'Create new one'}
            </button>

            {Object.values(errors).includes(true) && (
              <p className="manage__error">
                {message}
              </p>
            )}
          </div>

          <input
            className="sale-form-input"
            placeholder="Enter type name..."
            value={inputNameType || ''}
            onChange={handleInputChange}
            style={{ width: '100%' }}
          />
        </div>
        <div
          className="content-container"
          style={{
            height: 'calc(100vh - 236px)',
          }}
        >
          {loading ? (
            <div className="content-loading">
              <ProgressSpinner />
            </div>
          ) : (
            <>
              {typeList?.length ? (
                <h3 style={{ margin: '0 10px 10px' }}>All types:</h3>
              ) : <h3>First, you need to add types</h3>}
              {typeList?.map((item) => (
                <div
                  onClick={() => handleItemClick(item)}
                  key={item?.id}
                  className="sales__tagType-item"
                >
                  <h3>{item.title}</h3>
                  <Tooltip title="Delete tag type">
                    <IconButton
                      edge="start"
                      aria-label="delete-division"
                      sx={{
                        padding: 0,
                        margin: 0,
                        color: '#3e453d',
                      }}
                      onClick={() => {
                        setDeleteTagOptionOpenModal(true);
                      }}
                    >
                      <img
                        src={deleteButton}
                        width="20px"
                        height="auto"
                        alt="delete-button"
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={message}
        />
      )}
      {deleteTagOptionOpenModal && (
        <ContractTagsDelete
          deleteTagOptionOpenModal={deleteTagOptionOpenModal}
          setDeleteTagOptionOpenModal={setDeleteTagOptionOpenModal}
          type={true}
          selected={selectedType}
          setSelected={setSelectedType}
          clear={clearInput}
          setMessage={setMessage}
          setOpenToast={setOpenToast}
        />
      )}
    </>
  );
};
