import React, { useState } from "react";
import Table from "components/generic/table";
import TableItem from "components/generic/table-item";
import { formatPhoneNumber } from "shared/formatting";
import API from "API";
import Toast from "components/toast/toast";
import { useLocation, useNavigate } from 'react-router-dom';

export default function VendorsTable(props) {
  const { templates, vendors } = props;
  const { pathname } = useLocation();
  
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [openToast, setOpenToast] = useState(false);
  const [error, setError] = useState(null);

  const token = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();
  
  const handleVerifyEmail = async (vendorId, templateId) => {
    await fetch(`${API.endpointURL}/api/send_email_template`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        template_id: Number(templateId),
        vendor_id: vendorId,
      }),
    })
      .then((response) => {
        setOpenToast(true);
      })
      .catch((error) => setError(error));
  };
    const navigateVendorProfile = (vendorId) => {
      navigate(`/vendor-portal/${vendorId}`);
    };


  const renderExpandedView = (vendor) => {
    let templateOptions = [];
    templates?.forEach((template) => {
      templateOptions.push(
        <option key={template.id} value={template.id}>
          {template.template_name}
        </option>
      );
    });

    return (
      <div>
        <div className="row">
          <div className="cell auto">
            <div
              onClick={() => {
                navigateVendorProfile(vendor.id)
              }}
            >
              <i className="fa-regular fa-building"></i>
              View Profile
            </div>
          </div>
          <div className="cell auto">
            <b>POC Email</b>
            <p>{vendor.email}</p>
          </div>
          <div className="cell auto">
            <p>Set Email Template</p>
            <select
              value={selectedTemplate}
              onChange={(e) => setSelectedTemplate(e.target.value)}
            >
              <option value="">Select the dropdown for options...</option>
              {templateOptions}
            </select>
          </div>
          <div className="cell auto">
            <p>Send Verification Email</p>
            {selectedTemplate === null || selectedTemplate === "" ? (
              <button
                className="big-button"
                disabled
                onClick={() => handleVerifyEmail(vendor.id, selectedTemplate)}
              >
                Must Select A Template
              </button>
            ) : (
              <button
                className="big-button"
                onClick={() => handleVerifyEmail(vendor.id, selectedTemplate)}
              >
                Verify
              </button>
            )}
          </div>
          {openToast && (
            <Toast
              open={openToast}
              onClose={() => setOpenToast(false)}
              message="Email Sent Succesfully"
            />
          )}
        </div>
      </div>
    );
  };

  const renderTable = () => {
    let vendorsList = [];
    if (vendors) {
      vendors.forEach((vendor) => {
        vendorsList.push(
          <TableItem key={vendor.id} panel={renderExpandedView(vendor)}>
            <p>{vendor.name ? vendor.name : "N/A"}</p>
            <p>No Status</p>
            <p>{`${vendor.first_name} ${vendor.last_name}`}</p>
            <p>{vendor.phone ? formatPhoneNumber(vendor.phone) : "N/A"}</p>
          </TableItem>
        );
      });
      return <div>{vendorsList}</div>;
    }
  };

  return (
    <Table pathname={pathname} columns={["Company Name", "Alert Status", "POC Name", "POC Phone"]}>
      {vendors && renderTable()}
    </Table>
  );
}
