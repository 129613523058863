import { createAsyncThunk } from '@reduxjs/toolkit';
import { user } from 'services/user';

export const getEmailTemplatesByUserId = createAsyncThunk(
  'emailTemplatesByUserId',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await user.getEmailTemplatesByUserId(id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const sendEmail = createAsyncThunk(
  'sendEmail',
  async ({ url, body }, { rejectWithValue }) => {
    try {
      return await user.sendEmail(url, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCurrentUserData = createAsyncThunk(
  'currentUserData',
  async (_, { rejectWithValue }) => {
    try {
      return await user.getCurrentUserData();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editUser = createAsyncThunk(
  'editUser',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      return await user.editUser(id, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUsers = createAsyncThunk(
  'getUsers',
  async (_, { rejectWithValue }) => {
    try {
      return await user.getUsers();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
