import React, { useState } from 'react';
import { ManageMilestoneTemplates } from './manage-milestone-templates';
import { ManageServiceType } from './manage-service-type';
import { SalesDivisions } from 'components/sales/sales-divisions';
import { ManageBidTypes } from './manage-bid-types';

export const ManageSalesMenu = () => {
  const [view, setView] = useState("menu");

  return (
    <>
      <h1 className="content-header">
        {view === "menu" ? "Manage" : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className={`fa-solid fa-angle-left navy-arrow analytics-back-button`}
              onClick={() => setView("menu")}
            />
            <h1>
              {view === "milestones" && 'Manage Milestones'}
              {view === "service types" && 'Manage Service Types'}
              {view === "sales divisions" && 'Manage Sales Divisions'}
              {view === "bid types" && 'Manage Bid Types'}
            </h1>
          </div>
        )}
      </h1>
      <div className="metrics-content-container">
        {view === "menu" && (
          <>
            <button
              onClick={() => setView("bid types")}
              className="sales__manage-button"
            >
              Bid Types
              <i
                className="fa-solid fa-angle-right navy-arrow"
                style={{ marginLeft: "12px", color: "#A4F2F1" }}
              />
            </button>
            <button
              onClick={() => setView("milestones")}
              className="sales__manage-button"
            >
              Milestones
              <i
                className="fa-solid fa-angle-right navy-arrow"
                style={{ marginLeft: "12px", color: "#A4F2F1" }}
              />
            </button>
            <button
              onClick={() => setView("sales divisions")}
              className="sales__manage-button"
            >
              Sales Divisions
              <i
                className="fa-solid fa-angle-right navy-arrow"
                style={{ marginLeft: "12px", color: "#A4F2F1" }}
              />
            </button>
            <button
              onClick={() => setView("service types")}
              className="sales__manage-button"
            >
              Service Types
              <i
                className="fa-solid fa-angle-right navy-arrow"
                style={{ marginLeft: "12px", color: "#A4F2F1" }}
              />
            </button>
          </>
        )}
        {view === "milestones" && <ManageMilestoneTemplates />}
        {view === "service types" && <ManageServiceType />}
        {view === "sales divisions" && <SalesDivisions />}
        {view === "bid types" && <ManageBidTypes />}
      </div>
    </>
  );
};
