import React, { useState } from 'react';
import { TextField } from '@mui/material';

export const ProjectsUploadSingleModal = ({ onUpload, onClose }) => {
  const [file, setFile] = useState(null);
  const [fileDisplayName, setFileDisplayName] = useState('');
  const [error, setError] = useState(null);

  const handleClick = () => {
    if (!fileDisplayName) {
      setError('Error. Please enter File Name.');
    } else if (!file) {
      setError('Error. Please choose file.');
    } else {
      onUpload({
        file: file,
        name: fileDisplayName
      });
      onClose();
    }
  };

  return (
    <div
      style={{
        maxHeight: '100%',
        overflow: 'auto',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
        }}
      >
        <div className="contract-form-set-full">
          <div className="form-row-label">Document Name</div>
          <TextField
            value={fileDisplayName}
            onChange={(e) => setFileDisplayName(e.target.value)}
            fullWidth
            sx={{
              backgroundColor: '#fff',
              border: '1px solid #a5af96',
              borderRadius: '8px',
              padding: '6px 10px 5px',
              marginBottom: 0,
            }}
            placeholder="Enter Document Name..."
            multiline={true}
            rows={1}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </div>

        <div className="belles-file-upload">
          <input
            type="file"
            name="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>
      </div>
      {error && (
        <div
          style={{
            color: '#a20601',
            fontFamily: 'Korolev Medium',
            fontSize: '14px',
            marginBottom: '16px',
            textAlign: 'left',
          }}
        >
          {error}
        </div>
      )}
      <div
        style={{
          textAlign: 'left',
          width: '100%',
        }}
      >

        <button
          className="create-button"
          style={{
            marginRight: '14px',
            backgroundColor: '#141A66',
            borderColor: '#EFEFEF80',
          }}
          onClick={handleClick}
        >
          Upload
        </button>

      </div>
    </div>
  );
};
