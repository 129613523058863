import React, { useEffect, useState } from "react";
import AccountingOverview from "./accounting-overview";
import AccountingDetailView from "./accounting-detail-view";
import API from "API";

export default function AccountingDashboard() {

  const token = JSON.parse(localStorage.getItem("token"));

  const [accountingView, setAccountingView] = useState("overview");
  const [lastUpdate, setLastUpdate] = useState(null);

  useEffect(() => {
    getLastUpdate();
  }, []);

  const getLastUpdate = () => {
    fetch(`${API.endpointURL}/api/get_ledger_time`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setLastUpdate(json);
      })
      .catch((error) => console.log(error, "Error fetching update"));
  };

  return (
    <>
      {accountingView === "overview" && (
        <AccountingOverview
          setAccountingView={setAccountingView}
          getLastUpdate={getLastUpdate}
          lastUpdate={lastUpdate}
        />
      )}
      {accountingView === "income statement" && (
        <AccountingDetailView
          detailType={accountingView}
          setAccountingView={setAccountingView}
          lastUpdate={lastUpdate}
        />
      )}
      {accountingView === "balance sheet" && (
        <AccountingDetailView
          detailType={accountingView}
          setAccountingView={setAccountingView}
          lastUpdate={lastUpdate}
        />
      )}
    </>
  );
};
