import React, { useEffect, useRef, useState } from "react";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { ProgressSpinnerNavy } from "components/progress/progress-spinner-navy";
import API from "API";
import { useAppSelector } from 'hooks/reduxHooks';
import { applicantSelector } from 'store/applicants/selectors';

export default function ProfileNotes() {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const selectedUser = useAppSelector(applicantSelector);

  const token = JSON.parse(localStorage.getItem("token"));

  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);

  const [loadingNotes, setLoadingNotes] = useState(false);
  const [errorLoadingNotes, setErrorLoadingNotes] = useState(false);

  const [addingNewNote, setAddingNewNote] = useState(false);
  const [errorAddingNote, setErrorAddingNote] = useState(false);

  const notesEndRef = useRef(null);

  useEffect(() => {
    if (selectedUser.id) {
      setLoadingNotes(true);
      retrieveNotes();
    }
  },[]);

  useEffect(() => {
    scrollToBottom();
    setNote("");
  }, [notes]);


  const retrieveNotes = async () => {
    setErrorLoadingNotes(false);
    try {
      const response = await fetch(`${API.endpointURL}/api/user_notes/${selectedUser.id}`, {
      method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
  
      if (!response.ok) {
        setNotes([]);
        setLoadingNotes(false);
        setErrorLoadingNotes('Error getting Notes.');
        return;
      }
  
      const json = await response.json();
      setNotes([...json.data].sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        return dateA - dateB;
      }));
      setLoadingNotes(false);
      setErrorLoadingNotes(false);
    } catch (error) {
      setNotes([]);
      setLoadingNotes(false);
      setErrorLoadingNotes('Error getting Notes.');
    }
  };


  const handleSubmitNote = async () => {
    setAddingNewNote(true);
    setErrorAddingNote(false);
    try {
      const response = await fetch(`${API.endpointURL}/api/notes`, {
        method: 'POST',
        body: JSON.stringify({
          user_id: selectedUser.id,
          body: note.length > 0 ? note : "",
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
          Accept: 'application/json',
        },
      });
  
      if (!response.ok) {
        setAddingNewNote(false);
        setErrorAddingNote('An error has occured while trying to create your note. Please try again.');
        return;
      }
  
      setNote("");
      retrieveNotes();
      setAddingNewNote(false);
      setErrorAddingNote(false);
    } catch (error) {
      setNotes([]);
      setAddingNewNote(false);
      setErrorAddingNote('An error has occured while trying to create your note. Please try again.');
    }
  };



  const scrollToBottom = () => {
    notesEndRef.current?.scrollIntoView({ behavior: "smooth", block: 'end' });
  };

  const handleChangeText = (newValue) => {
    setNote(newValue);
  };

  const formatDateTime = (timestamp) => {
    const dateTime = new Date(timestamp);
    return dateTime.toLocaleString();
  };

  const keyPress = (e) => {
    if (e.keyCode === 13 && note.trim() !== '') {
      handleSubmitNote();
    }
  };


  const buildComms = () => {
    const textBubbles = notes.map((message, index) => (
      <Box
        key={index}
        display="flex"
        flexDirection="column"
        sx={{
          marginBottom: "8px",
          textAlign: "left",
          width: "auto",
        }}
      >
        <Typography
          sx={{
            color: "EFEFEF",
            fontSize: "9.5px",
            marginBottom: "2px",
            paddingLeft: "8px",
            textTransform: "capitalize",
          }}
        >
          {message.admin_first_name === currentUser.first_name && "You"}
          {message.admin_first_name &&
            message.admin_last_name !== currentUser.last_name &&
            `${message.admin_first_name} ${message.admin_last_name}`}
        </Typography>
        <Typography
          sx={{
            bgcolor: "#EFEFEF40",
            border: 0,
            borderRadius: "6px",
            fontFamily: "Korolev Medium",
            color: "EFEFEF",
            padding: "10px 16px",
            overflowWrap: "break-word",
          }}
        >
          {message.body}
        </Typography>
        <Typography
          sx={{
            color: "#EFEFEF",
            fontSize: "9.5px",
            marginTop: "2px",
            paddingRight: "8px",
            textAlign: "right",
          }}
        >
          {formatDateTime(message.created_at)}
        </Typography>
      </Box>
    ));

    return (
      <Box
        style={{
          boxShadow: 0,
          maxHeight: "450px",
          overflowY: "auto",
        }}
      >
        {textBubbles}
        <div ref={notesEndRef} />
      </Box>
    );
  };

  return (
    <>
      <h1 className="content-header">Notes</h1>
      <div className="content-container">
        {loadingNotes && !errorLoadingNotes &&
        <ProgressSpinnerNavy/>
        }
        {!loadingNotes && errorLoadingNotes &&
        <div style={{marginTop:'20px', marginBottom: '20px'}}>{errorLoadingNotes}</div>
        }
        {!loadingNotes && !errorLoadingNotes && notes && notes.length > 0 && 
        buildComms()
        }
        <Box
          sx={{
            backgroundColor: "#EFEFEF",
            border: "1px solid #EFEFEF80",
            borderRadius: "8px",
            color: "#171717",
            width: "auto",
            height: "auto",
          }}
        >
          <TextField
            value={note}
            onChange={(e) => handleChangeText(e.currentTarget.value)}
            onKeyDown={keyPress}
            fullWidth
            sx={{
              border: "0",
              padding: "6px 10px 5px",
            }}
            placeholder="Add Note..."
            id="fullWidth"
            multiline={true}
            rows={2}
            variant="standard"
            disabled={addingNewNote}
            InputProps={{
              disableUnderline: true,
            }}
          />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              padding: "5px 10px 10px",
            }}
          >
            <IconButton
              onClick={() => handleSubmitNote()}
              disabled={!note}
              sx={{
                padding: 0,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <ArrowCircleUpIcon
                sx={{
                  color: "26183E",
                  "&:hover": {
                    color: "#141A66",
                  },
                }}
              />
            </IconButton>
          </Box>
        </Box>
        {errorAddingNote && <div style={{marginTop:'20px'}}>{errorAddingNote}</div>}
      </div>
    </>
  );
}
