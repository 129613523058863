import React, { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from '@mui/material';

import 'css/sales.scss';

import Toast from 'components/toast/toast';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { numberWithCommas } from 'shared/formatting';

import { updateProject } from 'store/projects/thunk';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { projectsCoordinatorsSelector, projectSelector, projectsManagersSelector } from 'store/projects/selectors';

const initialInfo = {
  project_name: '',
  project_manager_id: '',
  project_coordinator_id: '',
  description: '',
  estimated_value: '',
  status: '',
  project_start_date: '',
  project_end_date: '',
};

const initialErrors = {
  project_name: false,
  project_manager_id: false,
  project_coordinator_id: false,
  description: false,
  estimated_value: false,
  status: false,
  project_start_date: false,
  project_end_date: false,
  
  server_error: false,
};

export const ProjectInformation = () => {
  const dispatch = useAppDispatch();
  const { project, getProjectLoading, getProjectError } = useAppSelector(projectSelector);
  const { projectCoordinators, loadingProjectCoordinators, errorProjectCoordinators } = useAppSelector(projectsCoordinatorsSelector);
  const { projectManagers, loadingProjectManagers, errorProjectManagers } = useAppSelector(projectsManagersSelector);
  const { updateProjectLoading } = useAppSelector(projectSelector);
  
  const [information, setInformation] = useState(initialInfo);
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const [projectNameBeforeUpdate, setProjectNameBeforeUpdate] = useState(false);
  
  useEffect(() => {
    if (project) {
      setInformation({
        project_name: project.project_name,
        status: project.status,
        project_manager_id: project.project_manager_data == null ? null : project.project_manager_data.id,
        project_coordinator_id: project.project_coordinator_data == null ? null : project.project_coordinator_data.id,
        description: project.description,
        project_start_date: project.project_start_date,
        project_end_date: project.project_end_date,
        estimated_value: project.estimated_value,
      });
      setProjectNameBeforeUpdate(project.project_name);
    }
  }, [project]);
  
  const handleChange = (event) => {
    setInformation({
      ...information,
      [event.target.name]: event.target.value,
    });
    if (!event.target.value.length) {
      setErrors({
        ...errors,
        [event.target.name]: true,
      });
    } else {
      setErrors({
        ...errors,
        [event.target.name]: false,
      });
    }
  };
  
  const handleNumberChange = (event) => {
    const value = event.target.value;
    
    // Process the input value
    const decimalCount = (value.match(/\./g) || []).length;
    let rawNumericValue;
    
    if (decimalCount <= 1) {
      rawNumericValue = value.replace(/[^\d.]/g, '');
    } else {
      rawNumericValue = value.replace(/[^\d.]/g, '').replace(/\.(?=.*\.)/g, '');
    }
    
    // Trim if there are more than two characters after the dot
    if (rawNumericValue.includes('.')) {
      const parts = rawNumericValue.split('.');
      if (parts[1].length > 2) {
        rawNumericValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
      }
    }
    
    setInformation({
      ...information,
      [event.target.name]: rawNumericValue,
    });
  };
  
  const handleUpdate = () => {
    if (
      information.project_name &&
      information.project_manager_id &&
      information.status &&
      information.project_start_date &&
      information.project_end_date
    ) {
      let projectForm;
      if (projectNameBeforeUpdate === information.project_name) {
        projectForm = {
          project_manager_id: information.project_manager_id,
          project_coordinator_id: information.project_coordinator_id,
          description: information.description,
          estimated_value: information.estimated_value,
          status: information.status,
          project_start_date: information.project_start_date,
          project_end_date: information.project_end_date,
        };
      } else {
        projectForm = {
          project_name: information.project_name,
          project_manager_id: information.project_manager_id,
          project_coordinator_id: information.project_coordinator_id,
          description: information.description,
          estimated_value: information.estimated_value,
          status: information.status,
          project_start_date: information.project_start_date,
          project_end_date: information.project_end_date,
        };
      }
      if (information?.project_manager_id) {
        projectForm.project_manager_id = parseInt(information.project_manager_id);
      }
      if (information?.project_coordinator_id) {
        projectForm.project_coordinator_id = parseInt(information.project_coordinator_id);
      }
      dispatch(updateProject({ projectId: project.id, body: projectForm }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setOpenToast(true);
            setErrors({
              ...errors,
              server_error: false,
            });
          } else {
            setOpenToast(true);
            setErrors({
              ...errors,
              server_error: true,
            });
          }
        });
    } else {
      setErrors({
        ...errors,
        project_name: !information.project_name,
        project_manager_id: !information.project_manager_id,
        status: !information.status,
        project_start_date: !information.project_start_date,
        project_end_date: !information.project_end_date,
      });
    }
  };
  
  
  const renderFetchedOptions = (type) => {
    let loadingOptions;
    let errorOptions;
    let fetchedData;
    
    if (type === 'Coordinator') {
      loadingOptions = loadingProjectCoordinators;
      errorOptions = errorProjectCoordinators;
      fetchedData = projectCoordinators;
    } else if (type === 'Manager') {
      loadingOptions = loadingProjectManagers;
      errorOptions = errorProjectManagers;
      fetchedData = projectManagers;
    }
    
    if (!loadingOptions && errorOptions) {
      return <option value="">Error loading {type}s...</option>;
    } else if (loadingOptions && !errorOptions) {
      return <option value="">Loading {type}s...</option>;
    } else if (!loadingOptions && !errorOptions && fetchedData) {
      return (
        <>
          <option value="">Select {type}...</option>
          {fetchedData.map((element) => (
            <option key={element.id} value={element.id}>
              {`${element.first_name} ${element.last_name}`}
            </option>
          ))}
        </>
      );
    }
    return '';
  };
  
  return (
    <section>
      <h1 className="content-header">Project Information</h1>
      <div className="content-container">
        <div className="contract-form-section-header" style={{ marginBottom: '10px' }}>
          General
        </div>
        {!getProjectLoading && getProjectError.length > 0
          ?
          <div className="content-loading">
            {getProjectError}
          </div>
          :
          getProjectLoading && getProjectError.length === 0
            ?
            <div className="content-loading">
              <ProgressSpinnerNavy/>
            </div>
            :
            !getProjectLoading && getProjectError.length === 0 && project
              ?
              (
                <>
                  <div className="sale-form-section-content">
                    <div className="sale-form-set">
                      <div className="sale-form-label">Project Name</div>
                      <input
                        className="sale-form-input"
                        placeholder="N/A"
                        value={information.project_name || ''}
                        name="project_name"
                        onChange={handleChange}
                      />
                      {errors.project_name && <p className="sale-form-error">* Please enter data</p>}
                    </div>
                    
                    <div className="sale-form-set">
                      <div className="sale-form-label">Project Manager</div>
                      <select
                        className="sale-form-input"
                        placeholder="Select Manager..."
                        value={information.project_manager_id || ''}
                        name="project_manager_id"
                        onChange={handleChange}
                        style={{
                          color: !information.project_manager_id ? '#EFEFEF80' : '#EFEFEF',
                          padding: '7px 12px',
                        }}
                      >
                        {renderFetchedOptions('Manager')}
                      </select>
                      {errors.project_manager_id && <p className="sale-form-error">* Please enter data</p>}
                    </div>
                    
                    <div className="sale-form-set">
                      <div className="sale-form-label">Project Coordinator</div>
                      <select
                        className="sale-form-input"
                        placeholder="Select Coordinator..."
                        value={information.project_coordinator_id || ''}
                        name="project_coordinator_id"
                        onChange={handleChange}
                        style={{
                          color: !information.project_coordinator_id ? '#EFEFEF80' : '#EFEFEF',
                          padding: '7px 12px',
                        }}
                      >
                        {renderFetchedOptions('Coordinator')}
                      </select>
                    </div>
                    
                    <div className="sale-form-set sale-form-set-full">
                      <div className="sale-form-label">Project Description</div>
                      <textarea
                        className="sale-form-input sale-form-input-textarea"
                        placeholder="N/A"
                        value={information.description || ''}
                        name="description"
                        onChange={handleChange}
                      />
                    </div>
                    
                    
                    <div className="sale-form-set">
                      <div className="sale-form-label sale-form-label">Estimated Value</div>
                      <TextField
                        value={information.estimated_value ? numberWithCommas(information.estimated_value) : ''}
                        name="estimated_value"
                        onChange={handleNumberChange}
                        sx={{
                          backgroundColor: '#EFEFEF40',
                          border: '1px solid #EFEFEF',
                          borderRadius: '6px',
                          padding: '2px 10px 1px',
                          marginBottom: 0,
                          width: '95%',
                          color: '#EFEFEF',
                          '& .MuiInputBase-input': {
                            color: '#EFEFEF',
                            borderRadius: 0,
                            fontFamily: 'Korolev Medium',
                          },
                        }}
                        placeholder="N/A"
                        multiline={false}
                        rows={1}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position="start" sx={{ '& p': { color: '#EFEFEF' } }}>
                              $
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    
                    
                    <div className="sale-form-set">
                      <div className="sale-form-label">Status</div>
                      <select
                        className="sale-form-input"
                        placeholder="Enter Sales Stage..."
                        value={information.status || ''}
                        name="status"
                        onChange={handleChange}
                        style={{ color: !information.status ? '#EFEFEF80' : '#EFEFEF' }}
                      >
                        <option value="">Select Project Status...</option>
                        <option value="pending">Pending</option>
                        <option value="current">Current</option>
                        <option value="completed">Completed</option>
                        <option value="archived">Archived</option>
                      </select>
                      {errors.status && <p className="sale-form-error">* Please enter data</p>}
                    </div>
                  </div>
                  
                  <div className="contract-form-section-header" style={{ marginBottom: '10px' }}>
                    Dates
                  </div>
                  
                  <div className="sale-form-section-content">
                    <div className="sale-form-set">
                      <div className="sale-form-label">Project Start Date</div>
                      <DatePicker
                        className="sale-form-input"
                        selected={
                          information.project_start_date
                            ? new Date(information.project_start_date.slice(0, 10))
                            : null
                        }
                        placeholderText={information.project_start_date ? undefined : 'N/A'}
                        onChange={(date) => {
                          if (date) {
                            const dd = String(date.getDate()).padStart(2, '0');
                            const mm = String(date.getMonth() + 1).padStart(2, '0');
                            const yyyy = date.getFullYear();
                            const dateString = yyyy + '-' + mm + '-' + dd;
                            setInformation({
                              ...information,
                              project_start_date: dateString,
                            });
                          }
                        }}
                        dateFormat="MM/dd/yyyy"
                      />
                      {errors.project_start_date && <p className="sale-form-error">* Please enter data</p>}
                    </div>
                    
                    <div className="sale-form-set">
                      <div className="sale-form-label">Project End Date</div>
                      <DatePicker
                        className="sale-form-input"
                        selected={
                          information.project_end_date
                            ? new Date(information.project_end_date.slice(0, 10))
                            : null
                        }
                        placeholderText={information.project_end_date ? undefined : 'N/A'}
                        onChange={(date) => {
                          if (date) {
                            const dd = String(date.getDate()).padStart(2, '0');
                            const mm = String(date.getMonth() + 1).padStart(2, '0');
                            const yyyy = date.getFullYear();
                            const dateString = yyyy + '-' + mm + '-' + dd;
                            setInformation({
                              ...information,
                              project_end_date: dateString,
                            });
                          }
                        }}
                        dateFormat="MM/dd/yyyy"
                      />
                      {errors.project_end_date && <p className="sale-form-error">* Please enter data</p>}
                    </div>
                  </div>
                  
                  <div className="sale-form-section-content">
                    <div className="sale-form-button-container">
                      {(updateProjectLoading &&
                        !errors.project_name &&
                        !errors.project_manager_id &&
                        !errors.status &&
                        !errors.project_start_date &&
                        !errors.project_end_date) && (
                        <div className="sale-form-spinner">
                          <ProgressSpinnerNavy/>
                        </div>
                      )}
                      <button
                        className="create-button"
                        onClick={handleUpdate}
                        disabled={
                          updateProjectLoading ||
                          errors.project_name ||
                          errors.project_manager_id ||
                          errors.status ||
                          errors.project_start_date ||
                          errors.project_end_date
                        }
                      >
                        Update
                      </button>
                      {(
                          errors.project_name ||
                          errors.project_manager_id ||
                          errors.status ||
                          errors.project_start_date ||
                          errors.project_end_date
                        )
                        && <p className="sale-form-error">* Please enter valid data...</p>}
                    </div>
                  </div>
                </>
              )
              :
              ''
        }
      </div>
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={errors.server_error
            ? 'Something went wrong, try again later...'
            : 'Sale information successfully updated'
          }
        />
      )}
    </section>
  );
};
