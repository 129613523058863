import React, { useEffect, useState } from 'react';
import DivisionsForm from "components/contracts/divisions-form";
import SubdivisionsForm from "components/contracts/subdivisions-form";
import SubdivisionDetailsForm from "components/contracts/subdivision-details-form";
import Toast from "components/toast/toast";
import EmailDialog from "components/email/email-dialog";
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { selectedDivisionSelector } from 'store/divisions/selectors';
import { selectedSubdivisionSelector } from 'store/subdivisions/selectors';
import { setSelectedSubdivision } from 'store/subdivisions/slice';
import { setSelectedDivision } from 'store/divisions/slice';

export default function DivisionsSubdivisions({ currentContract }) {
  const dispatch = useAppDispatch();
  const selectedDivision = useAppSelector(selectedDivisionSelector);
  const selectedSubdivision = useAppSelector(selectedSubdivisionSelector);

  const [openEmailDivDialog, setOpenEmailDivDialog] = useState(false);
  const [openEmailSubdivDialog, setOpenEmailSubdivDialog] = useState(false);
  const [openEmailToast, setOpenEmailToast] = useState(false);

  const [currentDivisionId, setCurrentDivisionId] = useState(null);
  const [currentSubdivisionId, setCurrentSubdivisionId] = useState(null);
  
  useEffect(() => {
    if (currentContract) {
      dispatch(setSelectedSubdivision(null));
      dispatch(setSelectedDivision(null));
    }
  }, [currentContract]);

  return (
    <>
      <h1 className="content-header">Divisions & Subdivisions</h1>
      <div className="content-container">
        <DivisionsForm
          setCurrentDivisionId={setCurrentDivisionId}
          setOpenEmailDivDialog={setOpenEmailDivDialog}
        />
        {selectedDivision && (
          <SubdivisionsForm
            setCurrentSubdivisionId={setCurrentSubdivisionId}
            setOpenEmailSubdivDialog={setOpenEmailSubdivDialog}
          />
        )}
        {selectedDivision && selectedSubdivision && (
          <SubdivisionDetailsForm />
        )}
      </div>

      {openEmailDivDialog && (
        <EmailDialog
          title={'division'}
          handleClose={() => setOpenEmailDivDialog(false)}
          open={openEmailDivDialog}
          openEmailToast={() => setOpenEmailToast(true)}
          currentDivisionId={currentDivisionId}
        />
      )}
      {openEmailSubdivDialog && (
        <EmailDialog
          title={'subdivision'}
          handleClose={() => setOpenEmailSubdivDialog(false)}
          open={openEmailSubdivDialog}
          openEmailToast={() => setOpenEmailToast(true)}
          currentSubdivisionId={currentSubdivisionId}
        />
      )}
      {openEmailToast && (
        <Toast
          open={openEmailToast}
          onClose={() => setOpenEmailToast(false)}
          message="Email sent"
        />
      )}
    </>
  );
};
