import { axiosService } from 'services/axiosServices';

export const tasks = {
  updateTask: async (taskId, body) => {
    const res = await axiosService.patch(`/api/tasks/${taskId}`, body);
    return res.data;
  },
  createTask: async (body) => {
    const res = await axiosService.post(`/api/tasks`, body);
    return res.data;
  },
}
