import "../../css/application.scss";
import React, { useState } from "react";
import logo from "../../assets/logo.png";
import ApplicationCompleted from "../../components/applications/application-completed";
import ApplicationError from "../../components/applications/application-error";
import ApplicationFiles from "../../components/applications/application-files";
import GeneralInfoForm from "../../components/applications/general-info-form";
import ScreeningForm from "../../components/applications/screening-form";

export default function UCTransportLogsApplication() {
  const [view, setView] = useState("generalInfoForm");
  const [applicantData, setApplicantData] = useState(null);
  const [errors, setErrors] = useState(null);

  return (
    <div className="background">
      <img className="rd-logo" src={logo} />
      {view === "generalInfoForm" && (
        <GeneralInfoForm
          applicantData={applicantData}
          nextView={"screeningForm"}
          setApplicantData={setApplicantData}
          setView={setView}
        />
      )}
      {view === "screeningForm" && (
        <ScreeningForm
          applicantData={applicantData}
          setApplicantData={setApplicantData}
          setView={setView}
          contractId={71}
        />
      )}
      {view === "files" && (
        <ApplicationFiles
          applicantData={applicantData}
          prevView={"screeningForm"}
          setApplicantData={setApplicantData}
          setView={setView}
          setErrors={setErrors}
        />
      )}
      {view === "error" && <ApplicationError setView={setView} errors={errors} />}
      {view === "completed" && (
        <ApplicationCompleted
          applicantEmail={applicantData?.email ? applicantData.email : null}
        />
      )}
      <div className="copyright">©2023 Responsive Deployment</div>
    </div>
  );
}
