import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Toast from 'components/toast/toast';
import ProgressSpinner from 'components/progress/progress-spinner';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { contractsSelector, contractTemplatesSelector, loadingTemplatesSelector } from 'store/contracts/selectors';
import { divisionsByContractIdSelector } from 'store/divisions/selectors';
import { getDivisionsByContractId } from 'store/divisions/thunk';
import { subdivisionsByDivisionIdSelector } from 'store/subdivisions/selectors';
import { getSubdivisionsByDivisionId } from 'store/subdivisions/thunk';
import { resetSubdivisionsByDivisionId } from 'store/subdivisions/slice';
import { resetDivisionsByContractId } from 'store/divisions/slice';
import { applicantEditStatusSelector, currentApplicantSelector } from 'store/applicants/selectors';
import { editApplicant, getApplicant } from 'store/applicants/thunk';
import API from 'API';
import { useGetApplicantsByStage } from 'hooks/getApplicantsByStage';
import { useGetSearchedApplicants } from 'hooks/getSearchedApplicants';
import { isSearchedApplicantsSelector } from 'store/staffingSelectedOptions/selectors';
import { setApplicantEditStatus, setEditedApplicantId } from 'store/applicants/slice';

export default function NewApplicantsAutomation(props) {
  const {
    reviewFile,
    setButton,
    loadingSendEmail,
    setBlockLoading,
    applicantData,
  } = props;
  
  const dispatch = useAppDispatch();
  const contracts = useAppSelector(contractsSelector);
  const allTemplates = useAppSelector(contractTemplatesSelector);
  const divisions = useAppSelector(divisionsByContractIdSelector);
  const subdivisions = useAppSelector(subdivisionsByDivisionIdSelector);
  const loadingTemplates = useAppSelector(loadingTemplatesSelector);
  const currentApplicant = useAppSelector(currentApplicantSelector);
  const isSearched = useAppSelector(isSearchedApplicantsSelector);
  const editStatus = useAppSelector(applicantEditStatusSelector);

  const getApplicants = useGetApplicantsByStage();
  const searchApplicants = useGetSearchedApplicants();

  const [vendor, setVendor] = useState(null);
  const [contractOptions, setContractOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [subdivisionOptions, setSubdivisionOptions] = useState([]);
  const [contract, setContract] = useState(null);
  const [division, setDivision] = useState(null);
  const [subdivision, setSubdivision] = useState(null);
  const [openErrorToast, setOpenErrorToast] = useState(false);

  const templates = allTemplates.filter(item => item?.template_type.includes('new_applicant'));
  
  useEffect(() => {
    if (currentApplicant?.id) {
      setBlockLoading(false);
    }
  }, [currentApplicant?.id]);
  
  const updateApplicant = (id, body) => {
    setBlockLoading(true);
    dispatch(setEditedApplicantId(currentApplicant.id));
    dispatch(editApplicant({ applicantId: id, body: body }));
  };

  useEffect(() => {
    if (editStatus === 'fulfilled') {
      dispatch(getApplicant({ applicantId: currentApplicant.id }));
      dispatch(setEditedApplicantId(currentApplicant.id));
      if (isSearched) {
        searchApplicants();
      } else {
        getApplicants();
      }
      dispatch(setApplicantEditStatus(''));
    }
  }, [editStatus]);

  const token = JSON.parse(localStorage.getItem('token'));
  const updateUser = async (field, newValue) => {
    setBlockLoading(true);
    dispatch(setEditedApplicantId(currentApplicant.id));
    await fetch(`${API.endpointURL}/api/users/${currentApplicant.id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        [field]: newValue,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data['errors']) {
        } else {
          if (isSearched) {
            searchApplicants();
          } else {
            getApplicants();
          }
          dispatch(getApplicant({ applicantId: currentApplicant?.id }));
        }
      })
  };

  useEffect(() => {
    const contractId = applicantData?.application?.contract_id;
    const divisionId = applicantData?.application?.region_id;
    const subdivisionId = applicantData?.application?.site_id;
    if (currentApplicant) {
      setVendor(applicantData.company);
      setContract(contractId);
      setDivision(divisionId);
      setSubdivision(subdivisionId);
    }
    if (contractId !== 'null' && contractId) {
      dispatch(getDivisionsByContractId({ contractId: contractId }));
    } else {
      dispatch(resetDivisionsByContractId());
      dispatch(resetSubdivisionsByDivisionId());
      return;
    }
    if (divisionId) {
      dispatch(getSubdivisionsByDivisionId({ divisionId: divisionId }));
    } else {
      dispatch(resetSubdivisionsByDivisionId());
    }
  }, [applicantData, currentApplicant]);

  useEffect(() => {
    let options = [];
    if (contracts?.length > 0) {
      contracts.forEach((contract) => {
        if (contract.status === 'active') {
          options.push(
            <MenuItem value={contract.id} key={contract.id}>
              {contract.title || 'No Title'}
            </MenuItem>,
          );
        }
      });
    }
    options.push(<MenuItem value={''} key={Math.random()}>No Contract</MenuItem>);
    setContractOptions(options);
  }, [contracts]);

  useEffect(() => {
    const options = divisions.map((division) => (
      <MenuItem value={division.id} key={division.id}>
        {division.title || 'No Title'}
      </MenuItem>
    ));

    options.push(<MenuItem value={''} key={Math.random()}>No Division</MenuItem>);
    setDivisionOptions(options);
  }, [divisions]);

  useEffect(() => {
    const options = subdivisions.map((subdivision) => (
      <MenuItem value={subdivision.id} key={subdivision.id}>
        {subdivision.title || 'No Title'}
      </MenuItem>
    ));

    options.push(<MenuItem value={''} key={Math.random()}>No Subdivision</MenuItem>);
    setSubdivisionOptions(options);
  }, [subdivisions]);

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
      <Box sx={{ width: '50%' }}>
        <Typography
          sx={{
            color: '#EFEFEF80',
            fontFamily: 'MagistralCondW08-Medium',
            marginBottom: '12px',
            textTransform: 'uppercase',
          }}
        >
          Review Applicant
        </Typography>
        {applicantData?.application?.resume_review_date !== null ? (
          <Typography
            sx={{ fontFamily: 'Korolev Medium', textAlign: 'left' }}
          >
            <Checkbox
              key={'resumeReviewDisabled'}
              defaultChecked
              onClick={() => reviewFile('resume_review_date', null, 'resume')}
              sx={{
                color: '#EFEFEF',
                '&.Mui-checked': {
                  color: '#62AB37',
                },
                '&.Mui-disabled': {
                  color: '#EFEFEF40',
                },
              }}
            />
            Reviewed Resume
          </Typography>
        ) : (
          <Typography
            sx={{ fontFamily: 'Korolev Medium', textAlign: 'left' }}
          >
            <Checkbox
              key={'resumeReviewEnabled'}
              onClick={() => reviewFile('resume_review_date', new Date().toISOString(), 'resume')}
              sx={{
                color: '#EFEFEF',
                '&.Mui-checked': {
                  color: '#62AB37',
                },
                '&.Mui-disabled': {
                  color: '#EFEFEF40',
                },
              }}
            />
            Review Resume
          </Typography>
        )}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            alignItems: 'center',
            marginBottom: 1.5,
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Korolev Medium',
              marginLeft: '12px',
              marginRight: 1,
              textAlign: 'left',
            }}
          >
            Vendor
          </Typography>
          <FormControl sx={{ width: '100%' }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              value={vendor || ''}
              onChange={(e) => {
                if (e.target.value !== vendor) {
                  updateUser('company', e.target.value);
                }
              }}
              sx={{
                backgroundColor: '#EFEFEF',
                border: 0,
                borderRadius: '8px',
                fontFamily: 'Korolev Medium',
              }}
            >
              <MenuItem value={'RD'}>RD</MenuItem>
              <MenuItem value={'KE'}>KE</MenuItem>
              <MenuItem value={'LOY'}>LOY</MenuItem>
              <MenuItem value={'LRG'}>LRG</MenuItem>
              <MenuItem value={'MED'}>MED</MenuItem>
              <MenuItem value={'NASS'}>NASS</MenuItem>
              <MenuItem value={'WS'}>WS</MenuItem>
              <MenuItem value={''}>No Vendor</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ width: '50%' }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            alignItems: 'center',
            marginBottom: 1.5,
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Korolev Medium',
              marginLeft: '12px',
              marginRight: 1,
              textAlign: 'left',
            }}
          >
            Contract
          </Typography>
          <FormControl sx={{ width: '60%' }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              value={contract || ''}
              onChange={(e) => {
                if (e.target.value !== contract) {
                  updateApplicant(applicantData?.application?.id, {
                    contract_id: e.target.value,
                    region_id: null,
                    site_id: null,
                    recruiter_id: null,
                  });
                }
                if (!e.target.value) {
                  setDivisionOptions([]);
                  setSubdivisionOptions([]);
                }
              }}
              sx={{
                backgroundColor: '#EFEFEF',
                border: 0,
                borderRadius: '8px',
                fontFamily: 'Korolev Medium',
              }}
            >
              {contractOptions}
            </Select>
          </FormControl>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            alignItems: 'center',
            marginBottom: 1.5,
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Korolev Medium',
              marginLeft: '12px',
              marginRight: 1,
              textAlign: 'left',
            }}
          >
            Division
          </Typography>
          <FormControl sx={{ width: '60%' }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              value={division || ''}
              onChange={(e) => {
                if (e.target.value !== division) {
                  updateApplicant(applicantData?.application?.id, {
                    region_id: e.target.value,
                    site_id: null,
                  });
                }
              }}
              sx={{
                backgroundColor: '#EFEFEF',
                border: 0,
                borderRadius: '8px',
                fontFamily: 'Korolev Medium',
              }}
            >
              {divisionOptions}
            </Select>
          </FormControl>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            alignItems: 'center',
            marginBottom: 1.5,
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Korolev Medium',
              marginLeft: '12px',
              marginRight: 1,
              textAlign: 'left',
            }}
          >
            Subdivision
          </Typography>
          <FormControl sx={{ width: '60%' }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              value={subdivision || ''}
              onChange={(e) => {
                if (e.target.value !== subdivision) {
                  updateApplicant(applicantData?.application?.id, {
                    site_id: e.target.value,
                  });
                }
              }}
              sx={{
                backgroundColor: '#EFEFEF',
                border: 0,
                borderRadius: '8px',
                fontFamily: 'Korolev Medium',
              }}
            >
              {subdivisionOptions}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {loadingTemplates ? (
        <div className="contracts-loading-spinner">
          <ProgressSpinner />
        </div>
      ) : (
        <Box display="flex" flexDirection="column" gap="5px" position="relative">
          {templates[0]?.active === true &&
            setButton(
              0,
              'new_applicant_button_1',
              'new_applicant_button_1_confirm',
              setOpenErrorToast,
            )}
          {templates[1]?.active === true &&
            setButton(
              1,
              'new_applicant_button_2',
              'new_applicant_button_2_confirm',
              setOpenErrorToast,
            )}
          {templates[2]?.active === true &&
            setButton(
              2,
              'new_applicant_button_3',
              'new_applicant_button_3_confirm',
              setOpenErrorToast,
            )}
          {templates[3]?.active === true &&
            setButton(
              3,
              'new_applicant_button_4',
              'new_applicant_button_4_confirm',
              setOpenErrorToast,
            )}
          {templates[4]?.active === true &&
            setButton(
              4,
              'new_applicant_button_5',
              'new_applicant_button_5_confirm',
              setOpenErrorToast,
            )}
          {loadingSendEmail && (
            <span style={{ position: 'absolute', bottom: '-40px', right: '-60px' }}>
            <ProgressSpinner />
          </span>
          )}
        </Box>
      )}
      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message="There was an issue sending your email. Please try again later."
        />
      )}
    </Box>
  );
}
