import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  Box,
  Checkbox,
  Typography,
} from '@mui/material';
import EqipButton from '../buttons/eqip.button';
import Toast from '../toast/toast';
import { formatUTCDateTime } from 'shared/formatting';
import { useAppSelector } from 'hooks/reduxHooks';
import { applicantSelector, currentApplicantSelector } from 'store/applicants/selectors';
import { contractTemplatesSelector, loadingTemplatesSelector } from 'store/contracts/selectors';
import ProgressSpinner from 'components/progress/progress-spinner';

export default function SecurityAutomation(props) {
  const {
    updateApplicant,
    handleUpdateApplicants,
    setButton,
  } = props;

  const allTemplates = useAppSelector(contractTemplatesSelector);
  const loadingTemplates = useAppSelector(loadingTemplatesSelector);
  const [selectedDate, setSelectedDate] = useState(null);
  const [openEmailToast, setOpenEmailToast] = useState(false);
  const [openErrorToast, setOpenErrorToast] = useState(false);

  const currentApplicant = useAppSelector(currentApplicantSelector);
  const applicantData = useAppSelector(applicantSelector);

  useEffect(() => {
    setSelectedDate(null);
  }, [currentApplicant]);

  const templates = allTemplates.filter(item => item?.template_type.includes('security'));

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box width="75%" display="flex" flexDirection="column" gap="5px" justifyContent="space-between">
          {loadingTemplates ? (
            <div className="contracts-loading-spinner">
              <ProgressSpinner />
            </div>
          ) : (
            <>
              {templates[0]?.active === true &&
                setButton(
                  0,
                  'security_button_1',
                  'security_button_1_confirm',
                  setOpenErrorToast,
                )}
              {templates[1]?.active === true &&
                setButton(
                  1,
                  'security_button_2',
                  'security_button_2_confirm',
                  setOpenErrorToast,
                )}
              {templates[2]?.active === true &&
                setButton(
                  2,
                  'security_button_3',
                  'security_button_3_confirm',
                  setOpenErrorToast,
                )}
              {templates[3]?.active === true &&
                setButton(
                  3,
                  'security_button_4',
                  'security_button_4_confirm',
                  setOpenErrorToast,
                )}
              {templates[4]?.active === true &&
                setButton(
                  4,
                  'security_button_5',
                  'security_button_5_confirm',
                  setOpenErrorToast,
                )}
            </>
          )}
          {applicantData?.application?.review_security ? (
            <Box
              display="flex"
              flexDirection="row"
              sx={{ alignItems: 'center' }}
            >
              <Checkbox
                key={'securityAwardReviewDisabled'}
                defaultChecked
                onClick={() => updateApplicant('review_security', null)}
                sx={{
                  color: '#EFEFEF',
                  '&.Mui-checked': {
                    color: '#62AB37',
                  },
                  '&.Mui-disabled': {
                    color: '#EFEFEF40',
                  },
                }}
              />
              <Typography
                sx={{
                  fontFamily: 'Korolev Medium',
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                Review Security Award:{' '}
                {formatUTCDateTime(
                  applicantData?.application?.review_security,
                )}
              </Typography>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="row"
              sx={{ alignItems: 'center' }}
            >
              <Checkbox
                key={'securityAwardReviewEnabled'}
                onClick={() =>
                  updateApplicant(
                    'review_security',
                    new Date(selectedDate).toISOString(),
                  )
                }
                sx={{
                  color: '#EFEFEF',
                  '&.Mui-checked': {
                    color: '#62AB37',
                  },
                  '&.Mui-disabled': {
                    color: '#EFEFEF40',
                  },
                }}
              />
              <Typography
                sx={{
                  fontFamily: 'Korolev Medium',
                  marginRight: '5px',
                  textAlign: 'left',
                  width: '55%',
                }}
              >
                Review Security Award
              </Typography>
              <DatePicker
                wrapperClassName="date-picker"
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
              />
            </Box>
          )}
        </Box>
        <EqipButton
          user={applicantData}
          handleUpdateApplicants={handleUpdateApplicants}
        />
      </Box>
      {openEmailToast && (
        <Toast
          open={openEmailToast}
          onClose={() => setOpenEmailToast(false)}
          message="Email sent"
        />
      )}
      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message="There was an issue sending your email. Please try again later."
        />
      )}
    </>
  );
}
