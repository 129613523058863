import { axiosService } from 'services/axiosServices';
import axios from 'axios';

let cancelProjectsToken = null;

export const projects = {
  getProjectsByStatus: async (status, currentPage) => {
    if (cancelProjectsToken) {
      cancelProjectsToken.cancel('Request canceled');
    }
    
    cancelProjectsToken = axiosService.CancelToken.source();
    try {
      const res = await axiosService.get(`/api/filter/projects?status=${status}&page=${currentPage}`, {
        cancelToken: cancelProjectsToken.token,
      });
      return res.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle cancellation error
      } else {
        // Handle other errors
        throw error;
      }
    }
  },
  getProjectsAnalytics: async (selectedManager, selectedCoordinator) => {
    const res = await axiosService.get(`/api/metrics/projects/per-status?project_manager_id=${selectedManager}&project_coordinator_id=${selectedCoordinator}`);
    return res.data;
  },
  getProjectsCoordinators: async () => {
    const res = await axiosService.get(`/api/filter/users?role=project coordinator`);
    return res.data.data;
  },
  getProjectsManagers: async () => {
    const res = await axiosService.get(`/api/filter/users?role=project manager`);
    return res.data.data;
  },
  getTotalActiveProjects: async () => {
    const res = await axiosService.get(`/api/metrics/projects/total-active`);
    return res.data;
  },
  getTotalProjectsValue: async () => {
    const res = await axiosService.get(`/api/metrics/projects/total-estimated-value`);
    return res.data;
  },
  createProject: async (body) => {
    const res = await axiosService.post(`/api/projects`, body);
    return res.data.data;
  },
  uploadProjectFile: async (body) => {
    const res = await axiosService.post(`/api/projects_files/upload`, body);
    return res.data.data;
  },
  getProject: async (projectId) => {
    const res = await axiosService.get(`/api/projects/${projectId}`);
    return res.data.data;
  },
  updateProject: async (projectId, body) => {
    const res = await axiosService.patch(`/api/projects/${projectId}`, body);
    return res.data.data;
  },
  getProjectTasks: async (projectId, statusFilter, page) => {
    const res = await axiosService.get(`/api/filter/tasks?taskable_type=project&taskable_id=${projectId}${statusFilter}&page=${page}`);
    return res.data;
  },
  getProjectFiles: async (projectId) => {
    const res = await axiosService.get(`/api/projects_files/filter?project_id=${projectId}`);
    return res.data.data;
  },
  getProjectFilesByCategory: async (projectId, typeName, category) => {
    const res = await axiosService.get(`/api/projects_files/filter?project_id=${projectId}&type_name=${typeName}&category=${category}`);
    return res.data.data;
  },
  getProjectsFileFolders: async (projectId) => {
    const res = await axiosService.get(`/api/projects_files/types/list-project-types?project_id=${projectId}`);
    return res.data.data;
  },
  getProjectsFileTypes: async (projectId) => {
    const res = await axiosService.get(`/api/projects_files/types/list-project-types?project_id=${projectId}`);
    return res.data.data;
  },
  createProjectsFileType: async (body) => {
    const res = await axiosService.post(`/api/projects_files/types`, body);
    return res.data.data;
  },
  deleteProjectFile: async (fileId) => {
    const res = await axiosService.delete(`/api/projects_files/${fileId}`);
    return res.data.data;
  },
  editProjectFile: async (fileId, body) => {
    const res = await axiosService.patch(`/api/projects_files/${fileId}`, body);
    return res.data.data;
  },
}
