import React from "react";

const VendorPortalPanelMenu = (props) => {
  const { currentPage, handleContentChange, vendor } = props;
  const currentUser = JSON.parse(localStorage.getItem("user"));

  return (
    <div>
      <div
        className={currentPage === "general" ? "sidebar__item-selected" : "sidebar__item"}
        onClick={() => handleContentChange("general")}
      >
        <p>General Information</p>
        <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
      </div>
      <div
        className={currentPage === "dropbox" ? "sidebar__item-selected" : "sidebar__item"}
        onClick={() => handleContentChange("dropbox")}
      >
        <p>Dropbox</p>
        <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
      </div>
      <div
        className={currentPage === "far" ? "sidebar__item-selected" : "sidebar__item"}
        onClick={(e) => handleContentChange("far")}
      >
        <p>FAR 19.7 DATA</p>
        <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
      </div>

      {(vendor?.is_staffing_customer == true) && 
      <div
        className={currentPage === "staffing-request" ? "sidebar__item-selected" : "sidebar__item"}
        onClick={() => handleContentChange("staffing-request")}
      >
        <p>Staffing Request</p>
        <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
      </div>
      }

      {(vendor?.is_staffing_customer == true) &&
      <div
        className={currentPage === "staffing-requests" ? "sidebar__item-selected" : "sidebar__item"}
        onClick={() => handleContentChange("staffing-requests")}
      >
        <p>Staffing Requests</p>
        <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
      </div>
      }

      {currentUser.role !== "vendor" &&
        <React.Fragment>
          <div
            className={currentPage === "notes" ? "sidebar__item-selected" : "sidebar__item"}
            onClick={(e) => handleContentChange("notes")}
          >
            <p>Notes</p>
            <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
          </div>
          <div
            className={currentPage === "alerts" ? "sidebar__item-selected" : "sidebar__item"}
            onClick={(e) => handleContentChange("alerts")}
          >
            <p>Alerts</p>
            <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}></i>
          </div>
        </React.Fragment>
      }
    </div>
  );
};

export default VendorPortalPanelMenu;
