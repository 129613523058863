import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import AppBar from "../../components/app-bar/updated-app-bar-component";
import VendorPortalPanelMenu from "../../components/widgets/vendor-portal-panel-menu";
import VendorGeneralPageContent from "components/widgets/vendor-general-page-content";
import VendorUserProfilePanel from "components/widgets/vendor-user-profile-panel";
import VendorDropBoxPageContent from "components/widgets/vendor-dropbox-page-content";
import VendorFARPageContent from "components/widgets/vendor-FAR-page-container";
import VendorNotes from "components/notes/vendor-notes";
import VendorAlerts from "components/alerts/vendor-alerts";
import Toast from "../../components/toast/toast";

import API from "API";
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';
import CreateStaffingRequest from "components/vendors/create-staffing-request";
import ContractsTable from 'components/contracts/contracts-table';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function VendorsPortal() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [permissionError, setPermissionError] = useState(false);
  const [currentPage, setCurrentPage] = useState("general");
  const [vendor, setVendor] = useState({});
  const [user, setUser] = useState({});
  const [accountingUsers, setAccountingUsers] = useState([]);
  const [associatedUsers, setAssociatedUsers] = useState([]);
  const [openStoredToast, setOpenStoredToast] = useState(false);
  const [openErrorToast, setOpenErrorToast] = useState(false);
  const [openSyncedToast, setOpenSyncedToast] = useState(false);
  const [error, setError] = useState(null);
  const [loadUserData, setLoadUserData] = useState(false);
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    if (loggedInUser.role === "vendor") {
      const userVendorId = loggedInUser.vendor_id.toString();
      if (userVendorId === id) {
        getVendor();
      } else {
        setPermissionError(true);
      }
    } else {
      getVendor();
    }
  }, [id]);

  useEffect(() => {
    if (vendor) {
      getUser();
    }
    getAccountingUsers();
    getAssociatedUsers();
  }, [vendor]);

  const getVendor = () => {
    fetch(`${API.endpointURL}/api/vendor?vendor_id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setVendor(json);
      })
      .catch((error) =>
        console.log(error, "An Error has occured during the Vendor fetch.")
      );
  };
  const storeDynamicsVendor = async () => {
    try {
      const response = await fetch(
        `${API.endpointURL}/api/store_dynamics_vendor`,
        {
          method: "POST",
          body: JSON.stringify({
            vendor: vendor,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.error.message ||
            "Failed to store vendor on Business Central"
        );
      }
      await response.json();
      getVendor();
      setOpenStoredToast(true);
    } catch (error) {
      setError(error);
      setOpenErrorToast(true);
    }
  };

  const updateDynamicsVendor = async () => {
    try {
      const response = await fetch(
        `${API.endpointURL}/api/update_dynamics_vendor`,
        {
          method: "POST",
          body: JSON.stringify({
            vendor: vendor,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.error.message ||
            "Failed to update vendor on Business Central"
        );
      }
      getVendor();
      setOpenSyncedToast(true);
    } catch (error) {
      setError(error);
      setOpenErrorToast(true);
    }
  };

  const getUser = () => {
    setLoadUserData(true)
    if (vendor.poc_id) {
      fetch(`${API.endpointURL}/api/users/${vendor.poc_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setUser(json.data);
          setLoadUserData(false)
        })
        .catch((error) => {
            console.log(error, "An Error has occured during the User fetch.");
            setLoadUserData(false);
          }
        );
    }
  };

  const getAccountingUsers = () => {
    fetch(`${API.endpointURL}/api/filter/users?role=accounting`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setAccountingUsers(json.data);
      })
      .catch((error) => console.log(error, "Error getting Vendors.."));
  };

  const getAssociatedUsers = () => {
    fetch(`${API.endpointURL}/api/filter/users?vendor_id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setAssociatedUsers(json.data);
      })
      .catch((error) => console.log(error, "Error getting Vendors.."));
  };

  return (
    <>
      <AppBar
        colorPalette="blue"
        menu={true}
        title="Vendor Portal"
      />
      <div className="accounting-page fixed">
        {permissionError ? (
          <div style={{ color: "white" }}>Permission denied</div>
        ) : (
          <>
            <div className="sidebar">
              <button
                className="back-button blue-hover"
                style={{marginTop: '0'}}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon
                  sx={{
                    marginRight: '5px',
                    height: '15px',
                    width: 'auto',
                  }}
                />
                Go back
              </button>
              {/* TODO: Add back when ready to sync Dynamics
              {vendor.bc_id !== null ? (
                vendor.update_needed === true ? (
                  <button
                    style={{
                      height: "50px",
                      backgroundColor: "rgb(105, 130, 92)",
                      color: "white",
                      fontSize: "18px",
                      borderRadius: "8px",
                      border: "none",
                      padding: "10px 20px",
                      cursor: "pointer",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      transition: "all 0.3s",
                    }}
                    onClick={() => updateDynamicsVendor()}
                  >
                    Update Vendor on Business Central
                  </button>
                ) : (
                  <button
                    disabled
                    style={{
                      height: "50px",
                      backgroundColor: "rgb(105, 130, 92)",
                      color: "white",
                      fontSize: "18px",
                      borderRadius: "8px",
                      border: "none",
                      padding: "10px 20px",
                      cursor: "pointer",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      transition: "all 0.3s",
                      opacity: 0.6,
                      pointerEvents: "none", 
                      filter: "grayscale(100%)",
                    }}
                  >
                    No Updates For Business Central
                  </button>
                )
              ) : (
                <button
                  style={{
                    height: "50px",
                    backgroundColor: "rgb(205, 130, 92)",
                    color: "white",
                    fontSize: "18px",
                    borderRadius: "8px",
                    border: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "all 0.3s",
                  }}
                  onClick={() => storeDynamicsVendor()}
                >
                  Sync to Business Central
                </button>
              )} */}
              {!loadUserData && user ? (
                <VendorUserProfilePanel vendor={vendor} user={user} />
              ) : (
                <div style={{ margin: "0 auto", minHeight: '320px' }}>
                   <ProgressSpinnerBlue />
                </div>
              )}
              <VendorPortalPanelMenu
                currentPage={currentPage}
                handleContentChange={setCurrentPage}
                vendor={vendor}
              />
            </div>
            <div className="page-content">
              {currentPage === "general" && (
                <VendorGeneralPageContent
                  vendor={vendor}
                  user={user}
                  associatedUsers={associatedUsers}
                  accountingUsers={accountingUsers}
                  getVendor={getVendor}
                />
              )}
              {currentPage === "dropbox" && (
                <VendorDropBoxPageContent
                  vendorId={vendor ? vendor.id : Number(id)}
                  getVendor={getVendor}
                  vendor={vendor}
                />
              )}
              {currentPage === "far" && (
                <VendorFARPageContent vendor={vendor} getVendor={getVendor} />
              )}
              {currentPage === "notes" && (
                <VendorNotes vendor={vendor} user={user} />
              )}
              {currentPage === "alerts" && <VendorAlerts vendor={vendor} />}
              
              {currentPage === "staffing-request" && <CreateStaffingRequest id={vendor?.id} />}
              {currentPage === "staffing-requests" && <ContractsTable />}
            </div>
          </>
        )}
      </div>
      {openStoredToast && (
        <Toast
          open={openStoredToast}
          onClose={() => setOpenStoredToast(false)}
          message="User has been stored on Business Central"
        />
      )}
      {openSyncedToast && (
        <Toast
          open={openSyncedToast}
          onClose={() => setOpenSyncedToast(false)}
          message="User has been updated on Business Central"
        />
      )}
      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message={`ERROR: ${error}`}
        />
      )}
    </>
  );
}
