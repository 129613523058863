import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import ProgressSpinner from "components/progress/progress-spinner";
import SyncApplicantRecruiters from "components/modal/sync-applicant-recruiters";
import Toast from "components/toast/toast";
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  assignRecruiter,
  dissociateRecruiter,
  getAssignedRecruitersByContractId,
} from 'store/recruiters/thunk';
import {
  assignedRecruitersByContractIdSelector,
  recruitersLoadingSelector,
  recruitersSelector,
} from 'store/recruiters/selectors';

export default function Recruiters(props) {
  const { currentContract } = props;

  const dispatch = useAppDispatch();
  const allRecruiters = useAppSelector(recruitersSelector);
  const assignedRecruiters = useAppSelector(assignedRecruitersByContractIdSelector);
  const loading = useAppSelector(recruitersLoadingSelector);
  
  const [sortedRecruiters, setSortedRecruiters] = useState([]);
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [openSyncToast, setOpenSyncToast] = useState(false);
  
  useEffect(() => {
    if (allRecruiters?.length > 0) {
      let myData = [...allRecruiters];
      myData.sort(function (a, b) {
        if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) return -1;
        if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) return 1;
        return 0;
      });
      setSortedRecruiters(myData);
    }
  }, [allRecruiters, assignedRecruiters]);

  const getAssignedRecruiters = () => {
    dispatch(getAssignedRecruitersByContractId({ contractId: currentContract.id }));
  };

  const associateRecruiter = (recruiterId) => {
    const body = {
      recruiter_id: recruiterId,
      contract_id: currentContract.id,
    };
    dispatch(assignRecruiter({ body }))
      .then(() => {
        getAssignedRecruiters();
      })
  };

  const dissociateRecruiters = (recruiterId) => {
    const body = {
      recruiter_id: recruiterId,
      contract_id: currentContract.id,
    };
    dispatch(dissociateRecruiter({ body }))
      .then(() => {
        getAssignedRecruiters();
      })
  };

  const buildRecruitersList = () => {
    let recruitersList = [];
    sortedRecruiters.forEach((recruiter, index) => {
      let isRecruiterAssigned = false;
      if (assignedRecruiters && assignedRecruiters?.length > 0) {
        if (assignedRecruiters.some(user => user.id === recruiter.id)) {
          isRecruiterAssigned = true;
        }
      }

      // We only display recruiters which have 
      // 'active' column set to true, meaning their
      // user is active
      const isRecruiterActive = recruiter.active === 'true' || recruiter.active === true;

      if (!isRecruiterActive) {
        return;
      }

      recruitersList.push(
        <div
          key={index}
          className="file-row third-width"
        >
          <Checkbox
            disabled={loading}
            checked={isRecruiterAssigned}
            sx={{
              color: "#EFEFEF",
              padding: 0,
              paddingRight: "10px",
              "&.Mui-checked": {
                color: "#62AB37",
              },
              "&.Mui-disabled": {
                color: "#EFEFEF40",
              },
            }}
            onClick={() => {
              // setLoading(true);
              if (isRecruiterAssigned) {
                dissociateRecruiters(recruiter.id);
              } else {
                associateRecruiter(recruiter.id);
              }
            }}
          />
          <div className="active-label">
            {recruiter.first_name} {recruiter.last_name}
          </div>
        </div>
      );
    });
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          height: "auto",
          maxHeight: "75%",
          width: "100%"
        }}
      >
        {recruitersList}
      </div>
    );
  };

  return (
    <>
      <h1 className="content-header">Recruiters</h1>
      <div className="content-container">
        {loading ? (
          <div className="contracts-loading-spinner">
            <ProgressSpinner />
          </div>
        ) : (
          <>
            <div
              style={{
                backgroundColor: "#EFEFEF40",
                borderRadius: "10px",
                fontFamily: "Korolev Medium",
                lineHeight: "25px",
                marginBottom: "24px",
                padding: "16px",
              }}
            >
              If a recruiter is removed from a contract, please click "Sync
              Applicants to Recruiters" to reassign their applicants to another
              recruiter currently on the contract. The sync may also be used to
              assign any contract applicants that are not currently tied to a
              recruiter.
            </div>
            {sortedRecruiters.length > 0 && buildRecruitersList()}
            <div className="contract-form-button-container">
              <button
                className="create-button"
                onClick={() => setOpenSyncModal(true)}
              >
                Sync Applicants to Recruiters
              </button>
            </div>
            {openSyncModal && (
              <SyncApplicantRecruiters
                contract={currentContract}
                handleClose={() => setOpenSyncModal(false)}
                open={openSyncModal}
                setOpenToast={() => setOpenSyncToast(true)}
              />
            )}
            {openSyncToast && (
              <Toast
                open={openSyncToast}
                onClose={() => setOpenSyncToast(false)}
                message="Successfully synced applicants to recruiters"
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
