import "../../css/appbar.scss";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../assets/rd-icon 1.png";
import { useAppDispatch } from 'hooks/reduxHooks';
import { resetApplicantStore } from 'store/applicants/slice';
import { resetUiStore } from 'store/ui/slice';

export default function SplashAppBar() {
  const currentPagePath = useLocation().pathname;
  const currentPage = currentPagePath.includes("applicant-portal")
    ? "/applicantPortal"
    : currentPagePath;
  const pages = {
    "/dashboard": "Dashboard",
    "/users": "Users",
    "/staffing": "Staffing",
    "/contracts": "Contracts",
    "/accounting": "Accounting",
    "/applicantPortal": "Applicant Portal",
  };
  const [anchorElPages, setAnchorElPages] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [user, setUser] = useState("");
  const [menu, setMenu] = useState(false);

  let navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    if (loggedInUser) {
      const foundUser = loggedInUser;
      setUser(foundUser);
    }
  }, []);

  const handleOpenPagesMenu = (event) => {
    setMenu(true);
    setAnchorElPages(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // This state change is not setting a re render, refreshing pushes you to login.
  // Fix dropdown buttons to figma / different onClicks.
  const handleLogout = () => {
    localStorage.clear();
    setUser("");
    navigate("/login");
    dispatch(resetApplicantStore());
    dispatch(resetUiStore());
  };

  const handleClosePagesMenu = () => {
    setMenu(false);
    setAnchorElPages(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigateDashboard = () => {
    navigate("/dashboard");
  };

  const navigateContractDetails = () => {
    navigate("/contracts");
  };

  const navigateVendors = () => {
    navigate("/accounting");
  };

  const navigateUsers = () => {
    navigate("/users");
  };

  const navigateStaffing = () => {
    navigate("/staffing");
  };

  const navigateApplicantProfile = (userId) => {
    navigate(`/applicant-portal/${userId}`);
  };

  return (
    <AppBar
      elevation={2}
      sx={{ backgroundColor: "#171717", boxShadow: "1px 1px 8px 1px black" }}
    >
      <Toolbar sx={{ padding: 1.5 }}>
        <img src={logo} alt="logo" align="left" width="250" className="logo" />
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
        <Typography>
          {currentPage && pages[currentPage] ? pages[currentPage] : null}
        </Typography>
        <Box className={"app-bar-nav-buttons"} sx={{ flexGrow: 0, md: "flex" }}>
          {user && user.role === "admin" && (
            <React.Fragment>
              <Tooltip title="Pages menu">
                <IconButton
                  className={`nav-menu-btn ${
                    menu ? "nav-menu-btn-clicked" : ""
                  }`}
                  edge="start"
                  color="inherit"
                  aria-label="pages-menu"
                  sx={{ mr: 1, color: "#fdfefe" }}
                  onClick={handleOpenPagesMenu}
                >
                  {menu ? <p className="menu-title">Menu</p> : ""}
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "38px" }}
                id="menu-pages"
                anchorEl={anchorElPages}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElPages)}
                onClose={handleClosePagesMenu}
              >
                <MenuItem
                  className="menu-item"
                  key={"staffing"}
                  onClick={(handleClosePagesMenu, navigateStaffing)}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p className="menu-option">Staffing</p>{" "}
                    <KeyboardArrowRightIcon className="icon" />
                  </Typography>
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  key={"dashboard"}
                  onClick={(handleClosePagesMenu, navigateDashboard)}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p className="menu-option">Dashboard</p>{" "}
                    <KeyboardArrowRightIcon className="icon" />
                  </Typography>
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  key={"users"}
                  onClick={(handleClosePagesMenu, navigateUsers)}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p className="menu-option">Users</p>{" "}
                    <KeyboardArrowRightIcon className="icon" />
                  </Typography>
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  key={"contractDetails"}
                  onClick={(handleClosePagesMenu, navigateContractDetails)}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p className="menu-option">Contracts</p>{" "}
                    <KeyboardArrowRightIcon className="icon" />
                  </Typography>
                </MenuItem>
                <MenuItem
                  className="menu-item"
                  key={"vendors"}
                  sx={{
                    borderBottom: "1px solid #EFEFEF",
                    width: "100%",
                  }}
                  onClick={(handleClosePagesMenu, navigateVendors)}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p className="menu-option">Vendors</p>{" "}
                    <KeyboardArrowRightIcon className="icon" />
                  </Typography>
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              key={"userName"}
              onClick={() => {
                navigateApplicantProfile(user.id);
                handleCloseUserMenu();
              }}
            >
              <Typography
                textAlign="center"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {user.first_name}
              </Typography>
            </MenuItem>
            <MenuItem
              key={"logout"}
              onClick={(handleCloseUserMenu, handleLogout)}
            >
              <Typography
                textAlign="center"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                Logout
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
