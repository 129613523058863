import { axiosService } from 'services/axiosServices';

export const divisions = {
  getAllDivisions: async () => {
    const res = await axiosService.get('/api/regions');
    return res.data.data;
  },
  getDivisionsByContractId: async (contractId) => {
    const res = await axiosService.get(`/api/filter/regions?contract_id=${contractId}`);
    return res.data.data;
  },
  getFilterableDivisions: async (contractId) => {
    const res = await axiosService.get(`/api/filter/regions?contract_id=${contractId}&is_staffing_filterable=true`);
    return res.data.data;
  },
  getDivisionById: async (divisionId) => {
    const res = await axiosService.get(`/api/regions/${divisionId}`);
    return res.data.data;
  },
  createDivision: async (body) => {
    const res = await axiosService.post(`/api/regions`, body);
    return res.data;
  },
  updateDivision: async (divisionId, body) => {
    const res = await axiosService.put(`/api/regions/${divisionId}`, body);
    return res.data;
  },
  deleteDivision: async (divisionId) => {
    const res = await axiosService.delete(`/api/regions/${divisionId}`);
    return res.data;
  },
}
