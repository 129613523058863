import "../../../css/analytics.scss";
import "../../../css/pages.scss";
import "../../../css/metrics.scss";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Checkbox, ListItemText, MenuItem, Select } from "@mui/material";
import AccountingSeriesView from "./accounting-series-view";
import { HighlightBlueAutoStyledButton } from "components/buttons/button-styles";
import { accountingDynamicsLabels } from "shared/accounting-data";
import API from "API";
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

export default function AccountingDetailView(props) {

  const { detailType, setAccountingView, lastUpdate } = props;

  const token = JSON.parse(localStorage.getItem("token"));

  const [view, setView] = useState("detail");
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [dimensionFilters, setDimensionFilters] = useState(null);
  const [selectedDimensions, setSelectedDimensions] = useState([]);
  const [codeFilters, setCodeFilters] = useState(null);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [netChange, setNetChange] = useState(null);
  const [balance, setBalance] = useState(null);
  const [breakdown, setBreakdown] = useState(null);
  const [loading, setLoading] = useState(false);

  const today = new Date();
  const maxDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [firstMonth, setFirstMonth] = useState(null);
  const [lastMonth, setLastMonth] = useState(today);

  const handleMonthSelect = (date, setter) => {
    if (setter === setFirstMonth) {
      const selectedMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      setFirstMonth(selectedMonth);
    } else if (setter === setLastMonth) {
      let selectedMonth;
      if (today.getMonth() === date.getMonth()) {
        selectedMonth = new Date();
      } else {
        selectedMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }
      setLastMonth(selectedMonth);
    }
  };

  const formatDate = (date, setter) => {
    return date != null ? `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + (setter === setFirstMonth ? 1 : date.getDate())).slice(-2)}/${date.getFullYear()}` : "";
  };

  useEffect(() => {
    getDimensionFilters();
    let startMonth;
    if (detailType === "balance sheet") {
      startMonth = new Date(today.getFullYear(), 0, 1);
    } else if (detailType === "income statement") {
      startMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    }
    setFirstMonth(startMonth);
    getBreakdown(startMonth, today);
  }, []);

  useEffect(() => {
    if (selectedSeries) {
      setView("series");
    } else {
      setView("detail");
    }
  }, [selectedSeries]);

  useEffect(() => {
    if (selectedDimensions.length > 0) {
      let availableCodes = [];
      selectedDimensions.forEach((dimension) => {
        let dimensionCodes = dimensionFilters[dimension];
        dimensionCodes.forEach((code) => {
          availableCodes.push(code);
        })
      })
      setCodeFilters(availableCodes);

      if (selectedCodes.length > 0) {
        let updatedCodeSelections = [...selectedCodes];
        selectedCodes.forEach((code) => {
          if (!availableCodes.includes(code)) {
            let updatedIndex = updatedCodeSelections.indexOf(code);
            updatedCodeSelections.splice(updatedIndex, 1);
          }
        })
        setSelectedCodes(updatedCodeSelections);
      }
    } else {
      setCodeFilters(null);
      if (selectedCodes.length > 0) {
        setSelectedCodes([]);
      }
    }
  }, [selectedDimensions]);

  const getDimensionFilters = () => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/get_dimension_filters`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        setDimensionFilters(json);
      })
      .catch((error) => console.log(error, "Error fetching filters"));
  };

  const getBreakdown = (start, end) => {
    setLoading(true);
    const startDate = start.toISOString().split('T')[0];
    const endDate = end.toISOString().split('T')[0];

    let filters = {};
    if (selectedDimensions) {
      selectedDimensions.forEach((dimension) => filters[dimension] = [])
    }
    if (selectedCodes) {
      selectedCodes.forEach((code) => {
        const key = Object.keys(dimensionFilters).find(key => {
          return dimensionFilters[key].some((item) => item.code === code);
        });
        filters[key].push(code);
      })
    }

    fetch(`${API.endpointURL}/api/ledger_filter`, {
      method: "POST",
      body: JSON.stringify({
        "type": detailType === "income statement" ? "Income Statement" : "Balance Sheet",
        "filters": filters,
        "start_date": startDate,
        "end_date": endDate,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        setNetChange(json["Net Change"]);
        setBalance(json["Balance"]);
        setBreakdown(json["Totals Breakdown"]);
      })
      .catch((error) => console.log(error, "Error fetching totals"));
  };

  const dimensionOptions = () => {
    let dimensionOptions = [];
    if (dimensionFilters) {
      for (const [key, value] of Object.entries(dimensionFilters)) {
        dimensionOptions.push(
          <MenuItem
            key={key}
            value={key}
            sx={{ fontFamily: "Korolev Medium", padding: '0 5px' }}
          >
            <Checkbox checked={selectedDimensions.indexOf(key) > -1} />
            <ListItemText primary={key} />
          </MenuItem>
        )
      }
    }
    return dimensionOptions;
  };

  const codeOptions = () => {
    let codeOptions = [];
    if (codeFilters) {
      codeFilters.forEach((code, index) => {
        codeOptions.push(
          <MenuItem
            key={index}
            value={code.code}
            sx={{
              fontFamily: "Korolev Medium",
              padding: '0 5px',
              position: 'relative',
              zIndex: 2,
              marginTop: (code.code === 'RD'
                || code.code === 'BPA'
                || code.code === 'RDSGA'
                || code.code === '70FBR423F00000001'
              )
                ? '24px'
                : '0',
            }}
          >
            {code.code === 'RD' && <p className="analytics-codes">1 BU</p>}
            {code.code === 'BPA' && <p className="analytics-codes">2 GOVT AG</p>}
            {code.code === 'RDSGA' && <p className="analytics-codes">3 STATE</p>}
            {code.code === '70FBR423F00000001' && <p className="analytics-codes">4 TASK</p>}
            <Checkbox checked={selectedCodes.indexOf(code.code) > -1} />
            <ListItemText primary={`[${code.code}] - ${code.name}`} />
          </MenuItem>
        )
      })
    }
    return codeOptions;
  };

  const renderBreakdown = () => {
    let accountBreakdown = [];
    if (breakdown) {
      for (const [key, value] of Object.entries(breakdown)) {
        if (detailType === "balance sheet") {
          if (Number(key) < 4) {
            accountBreakdown.push(
              <div className="contract-form-section-content">
                <button
                  className="stats-group-header"
                  onClick={() => setSelectedSeries(key)}
                  style={{
                    background: "transparent",
                    border: 0,
                    color: "#EFEFEF",
                    fontFamily: "MagistralCondW08-Medium",
                    fontSize: "23px",
                  }}
                >
                  {accountingDynamicsLabels[key]}
                  <i
                    className="fa-solid fa-angle-right blue-arrow"
                    style={{ marginLeft: "12px", color: "#00A5CF" }}
                  />
                </button>
                <div className="stage-stat-container" style={{ width: "auto" }}>
                  <div className="stage-stat-header">Net Change</div>
                  <div className="stage-stat-number">
                    ${value["Net Change"] && value["Net Change"].toLocaleString('en', { useGrouping:true })}
                  </div>
                </div>
                <div className="stage-stat-container" style={{ width: "auto" }}>
                  <div className="stage-stat-header">Balance</div>
                  <div className="stage-stat-number">
                    ${value["Balance"] && value["Balance"].toLocaleString('en', { useGrouping:true })}
                  </div>
                </div>
              </div>
            );
          }
        }
        if (detailType === "income statement") {
          if (Number(key) > 3) {
            accountBreakdown.push(
              <div className="contract-form-section-content">
                <button
                  className="stats-group-header"
                  onClick={() => setSelectedSeries(key)}
                  style={{
                    background: "transparent",
                    border: 0,
                    color: "#EFEFEF",
                    fontFamily: "MagistralCondW08-Medium",
                    fontSize: "23px",
                  }}
                >
                  {accountingDynamicsLabels[key]}
                  <i
                    className="fa-solid fa-angle-right blue-arrow"
                    style={{ marginLeft: "12px", color: "#00A5CF" }}
                  />
                </button>
                <div className="stage-stat-container" style={{ width: "auto" }}>
                  <div className="stage-stat-header">Net Change</div>
                  <div className="stage-stat-number">
                    ${value["Net Change"] && value["Net Change"].toLocaleString('en', { useGrouping:true })}
                  </div>
                </div>
                <div className="stage-stat-container" style={{ width: "auto" }}>
                  <div className="stage-stat-header">Balance</div>
                  <div className="stage-stat-number">
                    ${value["Balance"] && value["Balance"].toLocaleString('en', { useGrouping:true })}
                  </div>
                </div>
              </div>
            );
          }
        }
      }
    }
    return (
      <>{accountBreakdown}</>
    );
  };

  return (
    <>
      {loading && (
        <div className="contracts-loading-spinner">
          <ProgressSpinnerBlue />
        </div>
      )}
      {!loading && view === "series" && (
        <AccountingSeriesView
          breakdown={selectedSeries ? breakdown[selectedSeries] : breakdown}
          lastUpdate={lastUpdate}
          selectedSeries={selectedSeries}
          setSelectedSeries={setSelectedSeries}
        />
      )}
      {!loading && view === "detail" && (
        <>
          <div className="content-header content-header-flex">
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className={`fa-solid fa-angle-left blue-arrow analytics-back-button`}
                onClick={() => setAccountingView("overview")}
              />
              <h1>{detailType}</h1>
            </div>
            <div
              style={{
                fontFamily: "Korolev Medium",
                fontSize: "14px",
                textAlign: "right",
                textTransform: "none",
                width: "25%",
                color: '#efefef',
              }}
            >
              Last updated on {new Date(lastUpdate).toLocaleString()}
            </div>
          </div>
          <div className="metrics-content-container">
            <div style={{ margin: "0 20px 30px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                <div style={{ display: "flex", alignItems: "center", width: "45%"}}>
                  <div style={{ fontFamily: "Korolev Medium", marginRight: "12px" }}>Dimension:</div>
                  <Select
                    displayEmpty
                    multiple
                    fullWidth
                    value={selectedDimensions}
                    renderValue={(selected) => selected.join(', ')}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedDimensions(typeof value === 'string' ? value.split(',') : value);
                    }}
                    className="contract-form-input"
                    sx={{
                      backgroundColor: "#EFEFEF",
                      border: 0,
                      borderRadius: "8px",
                      fontFamily: "Korolev Medium",
                      '& .MuiOutlinedInput-input': {
                        padding: 0
                      }
                    }}
                  >
                    {dimensionOptions()}
                  </Select>
                </div>
                {selectedDimensions.length > 0 && (
                  <div style={{ display: "flex", alignItems: "center", width: "45%"}}>
                    <div style={{ fontFamily: "Korolev Medium", marginRight: "12px" }}>Codes:</div>
                    <Select
                      displayEmpty
                      multiple
                      fullWidth
                      value={selectedCodes}
                      renderValue={(selected) => selected.join(', ')}
                      onChange={(e) => {
                        const value = e.target.value;
                        setSelectedCodes(typeof value === 'string' ? value.split(',') : value);
                      }}
                      className="contract-form-input"
                      sx={{
                        backgroundColor: "#EFEFEF",
                        border: 0,
                        borderRadius: "8px",
                        fontFamily: "Korolev Medium",
                        maxWidth: 'calc(100% - 58px)',
                        '& .MuiOutlinedInput-input': {
                          padding: 0,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }
                      }}
                    >
                      {codeOptions()}
                    </Select>
                  </div>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", alignItems: "center", marginRight: "24px" }}>
                    <div style={{ fontFamily: "Korolev Medium", marginRight: "12px" }}>From:</div>
                    <DatePicker
                      wrapperClassName="date-picker"
                      selected={firstMonth}
                      onChange={(date) => handleMonthSelect(date, setFirstMonth)}
                      showMonthYearPicker
                      dateFormat={formatDate(firstMonth, setFirstMonth)}
                      dropdownMode="select"
                      placeholderText="Select first month"
                      maxDate={lastMonth}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ fontFamily: "Korolev Medium", marginRight: "12px" }}>To:</div>
                    <DatePicker
                      wrapperClassName="date-picker"
                      selected={lastMonth}
                      onChange={(date) => handleMonthSelect(date, setLastMonth)}
                      showMonthYearPicker
                      dateFormat={formatDate(lastMonth, setLastMonth)}
                      dropdownMode="select"
                      placeholderText="Select last month"
                      minDate={firstMonth}
                      maxDate={maxDate}
                    />
                  </div>
                  <div>
                  </div>
                </div>
                <HighlightBlueAutoStyledButton
                  onClick={() => getBreakdown(firstMonth, lastMonth)}
                  disabled={!firstMonth || !lastMonth}
                  style={{ marginLeft: "30px", width: "15%" }}
                >
                  Apply
                </HighlightBlueAutoStyledButton>
              </div>
            </div>
            <div className="contract-form-section">
              <div className="contract-form-section-content">
                {detailType === "income statement" && (
                  <div className="matrix-total-container" style={{ border: 0 }}>
                    <div className="total-applicants-number" style={{ lineHeight: "45px" }}>
                      Coming soon!
                    </div>
                    <div className="stage-stat-header" style={{ marginTop: "12px" }}>Budget</div>
                  </div>
                )}
                <div className="stage-stat-container" style={{ width: "auto" }}>
                  <div className="stage-stat-header">Net Change</div>
                  <div className="stage-stat-number">${netChange && netChange.toLocaleString()}</div>
                </div>
                <div className="stage-stat-container" style={{ width: "auto" }}>
                  <div className="stage-stat-header">Balance</div>
                  <div className="stage-stat-number">${balance && balance.toLocaleString()}</div>
                </div>
              </div>
            </div>
            <div className="contract-form-section">
              <div className="contract-form-section-header header-row">
                Breakdown
              </div>
              {breakdown && renderBreakdown()}
            </div>
          </div>
        </>
      )}
    </>
  );
};
