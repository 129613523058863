import { createAsyncThunk } from '@reduxjs/toolkit';
import { tasks } from 'services/tasks';
export const updateTask = createAsyncThunk(
  'updateTask',
  async ({ taskId, body }, { rejectWithValue }) => {
    try {
      return await tasks.updateTask(taskId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createTask = createAsyncThunk(
  'createTask',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await tasks.createTask(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
