import { createSlice } from '@reduxjs/toolkit';
import { createNote } from 'store/notes/thunk';

const initialState = {
  notes: {
    createNoteLoading: false,
    createNoteError: false,
    blockLoading: false,
  }
};

const notesSlice = createSlice({
  name: 'notesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNote.pending, (state) => {
      state.notes.createNoteLoading = true;
      state.notes.createNoteError = '';
      state.notes.blockLoading = true;
    });
    builder.addCase(createNote.fulfilled, (state) => {
      state.notes.createNoteLoading = false;
      state.notes.createNoteError = '';
    });
    builder.addCase(createNote.rejected, (state) => {
      state.notes.createNoteLoading = false;
      state.notes.createNoteError = 'An error has occured while trying to create your note. Please try again.';
    });
  },
});

export const { } = notesSlice.actions;

export default notesSlice.reducer;
