import React from 'react';
import TableItem from 'components/generic/table-item';

export const SalesExpandedTable = ({ firstRow, children, lastRow }) => {
  return (
    <TableItem panel={children} lastRow={lastRow || false}>
      {firstRow.map((item, index) => (
        <p key={`${item} + ${index}`}>{item || "N/A"}</p>
      ))}
    </TableItem>
  );
};