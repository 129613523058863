import React, { useEffect, useState } from "react";
import Row from "./manage.user.table";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import ProgressSpinner from 'components/progress/progress-spinner';

export default function BouncedUsersTable(props) {
  const { updateApplicantAlerts, users, allUsers, updateUsersList, loading } = props;

  const [sortedUsers, setSortedUsers] = useState(null);
  const [sortBy, setSortBy] = useState("last_name");
  const [sortOrder, setSortOrder] = useState("ascend");

  useEffect(() => {
    if (users) {
      sortUsers(users);
    }
  }, [users, sortBy, sortOrder]);

  const sortUsers = (usersList) => {
    if (usersList) {
      if (sortOrder === "ascend") {
        let myData = [...usersList];
        myData.sort(function (a, b) {
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) return -1;
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) return 1;
          return 0;
        });
        setSortedUsers(myData);
      } else if (sortOrder === "descend") {
        const myData = [...usersList];
        myData.sort(function (a, b) {
          if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) return -1;
          if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) return 1;
          return 0;
        });
        setSortedUsers(myData);
      }
    }
  };

  const renderUsers = () => {
    let selectedUsers = [];
    sortedUsers.forEach((user, index) => {
      selectedUsers.push(
        <Row
          key={user.id}
          user={user}
          firstUser={index === 0}
          updateApplicantAlerts={updateApplicantAlerts}
          updateUsersList={updateUsersList}
          allUsers={allUsers}
          bounced={true}
        />
      );
    });
    return selectedUsers;
  };
  
  
  if (loading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        className="content-container no-content-padding condensed"
      >
        <ProgressSpinner />
      </div>
    );
  }

  return (
    <TableContainer
      sx={{
        border: "1px solid #EFEFEF80",
        borderRadius: "10px",
        height: "calc(100vh - 230.5px)",
      }}
    >
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: "transparent",
                borderBottom: "1px solid #EFEFEF80",
                padding: "25px 25px 15px",
              }}
            />
            <TableCell
              align="left"
              sx={{
                backgroundColor: "transparent",
                borderBottom: "1px solid #EFEFEF80",
                color: "#efefef",
                fontFamily: "MagistralCondW08-Medium",
                fontSize: "1.25rem",
                padding: "25px 25px 15px",
                textTransform: "capitalize",
              }}
            >
              <Button
                sx={{
                  color: "#efefef",
                  fontFamily: "MagistralCondW08-Medium",
                  fontSize: "1.25rem",
                  maxHeight: "24px",
                  padding: "0",
                  textAlign: "left",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => {
                  if (sortBy === "last_name") {
                    setSortBy("first_name");
                  } else {
                    setSortBy("last_name");
                  }
                }}
              >
                {sortBy === "last_name" ? "Last Name" : "First Name"}
              </Button>
              {sortOrder === "ascend" ? (
                <i
                  className="fa-solid fa-arrow-down edit-icon green-arrow"
                  style={{ marginLeft: "12px" }}
                  onClick={() => setSortOrder("descend")}
                />
              ) : (
                <i
                  className="fa-solid fa-arrow-up edit-icon green-arrow"
                  style={{ marginLeft: "12px" }}
                  onClick={() => setSortOrder("ascend")}
                />
              )}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                backgroundColor: "transparent",
                borderBottom: "1px solid #EFEFEF80",
                color: "#efefef",
                fontFamily: "MagistralCondW08-Medium",
                fontSize: "1.25rem",
                padding: "25px 25px 15px",
                textTransform: "capitalize",
              }}
            >
              Role
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: "transparent",
                borderBottom: "1px solid #EFEFEF80",
                color: "#efefef",
                fontFamily: "MagistralCondW08-Medium",
                fontSize: "1.25rem",
                padding: "25px 25px 15px",
                textTransform: "capitalize",
              }}
            >
              Failed Row
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: "transparent",
                borderBottom: "1px solid #EFEFEF80",
                color: "#efefef",
                fontFamily: "MagistralCondW08-Medium",
                fontSize: "1.25rem",
                padding: "25px 25px 15px",
                textTransform: "capitalize",
              }}
            >
              Upload Type
            </TableCell>
            <TableCell
              align="center"
              sx={{
                backgroundColor: "transparent",
                borderBottom: "1px solid #EFEFEF80",
                color: "#efefef",
                fontFamily: "MagistralCondW08-Medium",
                fontSize: "1.25rem",
                padding: "25px 25px 15px",
                textTransform: "capitalize",
              }}
            >
              User Checked
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsers && sortedUsers.length > 0 ? (
            renderUsers()
          ) : (
            <React.Fragment>
              <TableCell sx={{ borderBottom: "0" }} />
              <TableCell sx={{ borderBottom: "0" }}>
                <Typography
                  sx={{
                    color: "#EFEFEF",
                    fontFamily: "Korolev Medium",
                    fontSize: "0.875rem",
                  }}
                >
                  No users
                </Typography>
              </TableCell>
            </React.Fragment>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
