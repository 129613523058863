import "../../css/application.scss";
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import fileIcon from "../../assets/fileIcon.png";
import API from "API";
import ProgressSpinner from 'components/progress/progress-spinner';

export default function ApplicationFiles(props) {
  const {
    applicantData,
    prevView,
    setApplicantData,
    setView,
    setErrors,
    contractId,
    applicantAnswers,
  } = props;

  const token = JSON.parse(localStorage.getItem("token"));

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFileBase64, setSelectedFileBase64] = useState(null);


  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedFileBase64(event.target.result.split(',')[1]);
      };
      reader.readAsDataURL(file);
    }
  };


  const submitApplication = async () => {
    setErrors(null)
    const requestBody = new FormData();
    Object.keys(applicantData).forEach((key) => {
      let value = applicantData[key];
      requestBody.append(key, value);
    });
    setLoading(true);

    // Append uploaded resume to 
    // endpoint request
    if (selectedFile) {
      requestBody.append("resume", selectedFile);
    }

    try {
      const response = await fetch(`${API.endpointURL}/api/apply`, {
      method: 'POST',
      body:requestBody,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      const data = await response.json();
      let successSendingAnswers;
     
      if (data["errors"]) {
        setErrors(data["errors"]);
        setView("error");
        return;
      } else if (data["message"]) {
        setErrors(data["message"]);
        setView("error");
        return;
      }
      
      //Attach screeng answers
      if (Object.values(applicantAnswers).length > 0) {
        successSendingAnswers = await storeAnswers(data.user.application_id);
        if ( !successSendingAnswers ) {
          setErrors('Error attaching screening answers to the application');
          setView("error");
          return;
        }
      }
      
      setApplicantData({ email: applicantData.email });
      setView("completed");
      setLoading(false);
    } catch (error) {
      setErrors('Error uploading file');
      setView("error");
      setLoading(false);
    }
  };

  const storeAnswers = async (id) => {
    try {
      const response =
        await fetch(
          `${API.endpointURL}/api/contracts/${contractId}/application-answers/mass-store`,{
            method: "POST",
            body: JSON.stringify({
              answers: Object.entries(applicantAnswers).map(item => ({
                applicant_id: id,
                application_question_id: Number(item[0]),
                answer: item[1],
              }))
            }),
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        );
      if (!response.ok) {
        return false;
      }
      const responseData = await response.json();
      if(responseData){
        return true;
      }else{
        return false;
      }
    } catch (e) {
      return false;
    }
  }


  return (
    <Box
      sx={{
        backgroundColor: "#262a25",
        borderRadius: "28px",
        boxShadow: "0px 0px 16px 27px rgba(0, 0, 0, 0.25)",
        margin: "0 auto",
        padding: "32px",
        maxWidth: "700px",
      }}
    >
      <Typography
        sx={{
          color: "#dae0bc",
          fontFamily: "MagistralCondW08-Medium",
          fontSize: "35px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "50px",
          margin: "0 auto 16px",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        Upload Resume
      </Typography>
      <Typography
        sx={{
          color: "#EFEFEF",
          fontFamily: "Korolev Medium",
          fontSize: "16px",
          textAlign: "center",
        }}
      >
        Please upload your resume below (optional) and click "Submit" to
        complete your application.
      </Typography>
      <Box
        sx={{
          border: "2px dashed #a5af96",
          borderRadius: "8px",
          padding: "35px",
          marginTop: "24px",
          textAlign: "center",
        }}
      >
        <img src={fileIcon} width="50px" height="auto" alt="approve-button" />
        <Typography
          sx={{
            color: "#dae0bc",
            fontFamily: "Korolev Medium",
            fontSize: "1.25rem",
            marginTop: "8px",
          }}
        >
          Upload a file
        </Typography>
        <div style={{ marginTop: "20px" }}>
          <input
  type="file"
  name="file"
  onChange={handleFileChange}
  style={{
    color: "#EFEFEF",
    fontFamily: "Korolev Medium",
  }}
/>
        </div>
        <Typography
          sx={{
            color: "#EFEFEF",
            fontFamily: "Korolev Medium",
            fontSize: "0.85rem",
            marginTop: "16px",
          }}
        >
          DOCX or PDF up to 5MB
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "30px",
          position: 'relative',
        }}
      >
        {loading && (
          <div style={{
            position: 'absolute',
            left: '260px',
            bottom: '-41px',
          }}>
            <ProgressSpinner />
          </div>
        )}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            color: "#a5af96",
            fontFamily: "MagistralCondW08-Medium",
            fontSize: "18px",
            padding: "5px",
            width: "150px",
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
              color: "#dae0bc",
            },
          }}
          onClick={() => setView(prevView)}
          disabled={loading}
        >
          Back
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#dae0bc",
            color: "#3e453d",
            fontFamily: "MagistralCondW08-Medium",
            fontSize: "18px",
            width: "175px",
            "&:hover": { backgroundColor: "#a5af96", color: "#3e453d" },
          }}
          onClick={() => submitApplication()}
          disabled={loading}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}
