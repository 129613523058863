import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/login.scss";
import logo from "../assets/logo.png";
import { Button, Card, TextField, Typography } from "@mui/material";
import API from "API";
import AppBar from 'components/app-bar/app-bar-splash-component';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { resetPasswordSelector } from 'store/user/selectors';
import { setResetEmail } from 'store/user/slice';
import ProgressSpinnerBlack from 'components/progress/progress-spinner-black';

export default function ResetPassword() {
  const [mode, setMode] = useState("resetPassword");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { email } = useAppSelector(resetPasswordSelector);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const submitPasswordReset = async () => {
    if (!email || !token || !password) {
      setError("Please enter your email, reset token and a new password.");
    } else if (password.length < 9) {
      setError("Please enter a password with at least 9 characters.");
    } else {
      setError('');
      setLoading(true);
      let requestError = false;
      await fetch(`${API.endpointURL}/api/reset_password`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          forget_token: token,
          new_password: password,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Accept: 'application/json',
        },
      })
        .then((response) => {
          if (!response.ok) {
            requestError = true;
          }
          return response.json();
        })
        .then((json) => {
          setLoading(false);
          if (requestError) {
            if (json.message) {
              setError(json.message);
            } else {
              setError('Something went wrong, try again later.')
            }
          } else {
            setMode("success");
          }
        });
    }
  };

  const renderResetScreen = () => {
    return (
      <React.Fragment>
        <h1 className="login-form-title">
          <span className="login-form-title-green">RESET </span>PASSWORD
        </h1>
        <TextField
          fullWidth
          required
          className="login-form-inputs"
          id="email-textfield"
          label="Enter Email"
          margin="dense"
          sx={{ marginBottom: "15px" }}
          type="email"
          value={email}
          onChange={(e) => dispatch(setResetEmail(e.currentTarget.value))}
        />
        <TextField
          fullWidth
          required
          className="login-form-inputs"
          id="token-textfield"
          label="Enter Token"
          margin="dense"
          sx={{ marginBottom: "15px" }}
          value={token}
          onChange={(e) => setToken(e.currentTarget.value)}
        />
        <TextField
          fullWidth
          required
          className="login-form-inputs"
          id="password-textfield"
          label="Enter New Password"
          margin="dense"
          value={password}
          error={password.length > 0 && password.length < 9}
          onChange={(e) => setPassword(e.currentTarget.value)}
          helperText={
            password.length > 0 && password.length < 9
            && "Password must be at least 9 characters."
          }
        />
        {error && <div className="login-error">{error}</div>}

        {loading && (
          <div className="reset-loader-container">
            <div className="reset-loader" style={{ top: 0 }}>
              <ProgressSpinnerBlack />
            </div>
          </div>
        )}

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#556155",
            color: "#EFEFEF",
            fontSize: "18px",
            marginTop: "45px",
            width: "175px",
            "&:hover": { backgroundColor: "#3e453d", color: "#dae0bc" },
          }}
          onClick={submitPasswordReset}
        >
          Submit
        </Button>
      </React.Fragment>
    );
  };

  const renderSuccessScreen = () => {
    return (
      <React.Fragment>
        <h1 className="login-form-title">SUCCESS</h1>
        <Typography
          sx={{
            color: "#3e453d",
            fontFamily: "Korolev Medium",
            fontSize: "1rem",
            lineHeight: "1.5rem",
            marginBottom: "45px",
          }}
        >
          Your password has been reset. Please log in with your username and new
          password.
        </Typography>
        <Link to={"/login"} style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#556155",
              color: "#EFEFEF",
              fontSize: "18px",
              width: "175px",
              "&:hover": { backgroundColor: "#3e453d", color: "#dae0bc" },
            }}
          >
            Log In
          </Button>
        </Link>
      </React.Fragment>
    );
  };

  return (
    <>
      <AppBar />
      <div className="login-background">
        <img src={logo} alt="logo" align="left" className="logo" />
        <div className="login-form-container">
          <div className="directions-box">
            <h1>Reset your password.</h1>
            <p>Please use the token received by mail.</p>
          </div>
          <Card
            className="login-form-card"
            sx={{
              borderRadius: "28px",
              boxShadow: "0px 0px 16px 27px rgba(0, 0, 0, 0.25)",
            }}
          >
            {mode === "resetPassword" && renderResetScreen()}
            {mode === "success" && renderSuccessScreen()}
          </Card>
        </div>
      </div>
    </>
  );
}