import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { ReorderIcon } from 'components/reorder/ReorderIcon';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';

import { numberWithCommas } from 'shared/formatting';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { openCardsOnSalePortalSelector } from 'store/ui/selectors';
import { quoteCategoriesSelector } from 'store/quotes/selectors';
import { updateSaleQuote } from 'store/sales/thunk';
import { salesQuotesSelector } from 'store/sales/selectors';
import { setSelectedQuote } from 'store/sales/slice';

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);
  
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  
  if (!enabled) {
    return null;
  }
  
  return <Droppable {...props}>{children}</Droppable>;
};
export const SalesActiveQuotes = ({ activeQuotes, loading, handleQuoteClick, toggleCard, getQuotes, handleCreate }) => {
  const dispatch = useAppDispatch();
  const openCards = useAppSelector(openCardsOnSalePortalSelector);
  const categories = useAppSelector(quoteCategoriesSelector);
  const { selectedQuote } = useAppSelector(salesQuotesSelector);
  
  const [columns, setColumns] = useState({});
  const [openQuotes, setOpenQuotes] = useState([]);
  
  useEffect(() => {
    const sortedCategories = [...categories]?.sort((a, b) => a.category_name.toUpperCase().localeCompare(b.category_name.toUpperCase()));
    const cellsObject = Object.fromEntries(
      sortedCategories?.map((category) => [
        category.category_name, {
          name: category.category_name,
          id: category.id,
          list: [activeQuotes.filter((quote) => quote.quote_category?.id === category.id)],
        },
      ]),
    );
    
    setColumns(cellsObject);
  }, [activeQuotes, categories]);
  
  useEffect(() => {
    const categoryNames = [...categories]?.map((category) => category.category_name)
    setOpenQuotes([...categoryNames, 'general'])
  }, [categories]);
  
  const toggleQuote = (id) => {
    if (openQuotes.includes(id)) {
      setOpenQuotes(openQuotes.filter((cardId) => cardId !== id));
    } else {
      setOpenQuotes([...openQuotes, id]);
    }
  };
  
  const onDragEnd = ({ source, destination }) => {
    if (destination === undefined || destination === null) return null;
    
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;
    
    const copyColumns = {...columns}
    
    const start = Object.values(copyColumns).filter((column) => (
      column.id.toString() === source.droppableId.toString()
    ))
    const end = Object.values(copyColumns).filter((column) => (
      column.id.toString() === destination.droppableId.toString()
    ))
    
    if (source.droppableId === destination.droppableId) {
      const newList = Array.from([...end[0].list[0]]);
      const [reorderedItem] = newList.splice(source.index, 1);
      newList.splice(destination.index, 0, reorderedItem);
      
      const newCol = {
        id: end[0].id,
        name: end[0].name,
        list: [newList],
      };
 
      setColumns(state => ({ ...state, [newCol.name]: newCol }));
      return null;
    } else {
      const body = {
        quote_category_id: destination.droppableId === 'null' ? null : +destination.droppableId,
      };
      dispatch(updateSaleQuote({ quoteId: selectedQuote.id, body }))
        .then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
              getQuotes();
              handleCreate();
            }
          },
        );
      
      const newStartList = start[0].list[0].filter(
        (_, idx) => idx !== source.index,
      );
      
      const newStartCol = {
        id: start[0].id,
        name: start[0].name,
        list: [newStartList],
      };

      const newEndList = end[0].list[0];

      newEndList.splice(destination.index, 0, start[0].list[0][source.index]);
      
      const newEndCol = {
        id: end[0].id,
        name: end[0].name,
        list: [newEndList],
      };
      
      setColumns(state => ({
        ...state,
        [newStartCol.name]: newStartCol,
        [newEndCol.name]: newEndCol,
      }));
      return null;
    }
  };
  
  
  if (loading && openCards.includes('active')) {
    return (
      <div
        className="sales__quote--container"
        style={{ maxHeight: '680px' }}
      >
        <div
          className="sales__container"
          style={{ gap: '5px', padding: '15px' }}
        >
          {activeQuotes?.length > 0 && activeQuotes?.filter((category) => category?.quote_category !== null)?.length > 0 && (
            <div className="sales__quote--row sales__quote--row--active-header">
              <p className="sales__quote--title">Service Description</p>
              <p className="sales__quote--title">Quoted price</p>
              <p className="sales__quote--title">Profit margin</p>
            </div>
          )}
          <div className="content-loading">
            <ProgressSpinnerNavy/>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div
      className={
        classNames(
          'sales__quote--container',
          {
            'sales__quote--container--hidden': !openCards.includes('active'),
          }
        )
      }
      style={{ maxHeight: openCards.includes('active') ? 'calc(100vh - 294px)' : '0px' }}
    >
      <div className="sales__container">
        {activeQuotes?.length > 0 && activeQuotes?.filter((category) => category?.quote_category !== null)?.length > 0 && (
          <div className="sales__quote--row sales__quote--row--active-header">
            <p className="sales__quote--title">Service Description</p>
            <p className="sales__quote--title">Quantity</p>
            <p className="sales__quote--title">Price per Unit ($)</p>
            <p className="sales__quote--title">Cost to Company ($)</p>
            <p className="sales__quote--title">Quoted price ($)</p>
            <p className="sales__quote--title">Profit margin (%)</p>
          </div>
        )}
        {activeQuotes?.length > 0 && activeQuotes?.filter((category) => category?.quote_category !== null)?.length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="sales__active-container">
              {Object.values(columns).map(col => (
                <StrictModeDroppable
                  key={Number.isInteger(col.id) ? col.id.toString() : col.id}
                  droppableId={Number.isInteger(col.id) ? col.id.toString() : col.id}
                >
                  {(provided) => (
                    <div
                      className="sales__active"
                      style={{ maxHeight: openQuotes.includes(col.name) ? 'inherit' : '45px' }}
                    >
                      <div className="sales__active-header">
                        <p className="sales__active-category">{col.name}</p>
                        <IconButton
                          edge="start"
                          color="inherit"
                          sx={{ color: '#fdfefe', padding: 0, width: '25px' }}
                          onClick={() => toggleQuote(col.name)}
                        >
                          <KeyboardArrowDownIcon
                            sx={{
                              transform: openQuotes.includes(col.name) ? 'rotate(180deg)' : '',
                              transition: 'transform 0.3s ease-in-out',
                            }}
                          />
                        </IconButton>
                      </div>
                      <div
                        className={classNames({
                          'sales__quote--container sales__active-list': openQuotes.includes(col.name),
                          'sales__quote--container sales__quote--container--hidden': !openQuotes.includes(col.name),
                        })}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {col?.list[0]?.map((quote, index) => {
                          return (
                            <Draggable key={quote.id} draggableId={quote.id.toString()} index={index}>
                              {(provided2) => (
                                <div
                                  className="sales__active-listItem"
                                  ref={provided2.innerRef}
                                  {...provided2.draggableProps}
                                  {...provided2.dragHandleProps}
                                  onMouseDown={() => dispatch(setSelectedQuote(quote))}
                                >
                                  <button
                                    style={{ width: '100%' }}
                                    className="sales__quote--row sales__quote--row--extended"
                                    onClick={() => {
                                      handleQuoteClick(quote);
                                      if (!openCards.includes('form')) {
                                        toggleCard('form');
                                      }
                                    }}
                                  >
                                    <p>{quote.service_description}</p>
                                    <p>{numberWithCommas(quote?.quantity)}</p>
                                    <p>{numberWithCommas(quote?.price_per_unit)}</p>
                                    <p>{numberWithCommas(quote?.cost_to_company)}</p>
                                    <p>
                                      {numberWithCommas(quote.cost_quoted)}
                                    </p>
                                    <p>{Math.round(quote.profit_margin * 100) / 100}</p>
                                  </button>
                                  <ReorderIcon color="#A4F2F1"/>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </StrictModeDroppable>
              ))}
            </div>
          </DragDropContext>
        ) : (
          <p>No Active Quotes</p>
        )}
      </div>
    </div>
  );
};
