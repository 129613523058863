import React, { useState } from 'react';
import { Divider, IconButton, Typography } from '@mui/material';
import fileIcon from 'assets/uploaded_file.svg';
import { uploadTypeToFile } from 'shared/vendor-data';
import { SalesUploadFileModal } from './sales-upload-modal';
import { SalesNewFileTypeModal } from './sales-new-type-modal';
import NewBaseModal from '../modal/new-base-modal';
import '../../css/sales-files-list.scss';

export default function SaleViewFile(props) {
  const {
    vendorFiles,
    view,
    setView,
    retrieveFiles,
    id,
    paramToReq,
    setParamToReq,
    specificFiles,
    refreshFilteredFiles,
    refreshFileFolders,
  } = props;

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openNewFileTypeModal, setOpenNewFileTypeModal] = useState(false);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const buildAllFilesList = () => {

    const filesList = [];
    vendorFiles?.forEach((file, index) => {
        filesList.push(
          <div
            key={index}
            onClick={() => openInNewTab('/sales-viewer/' + file.id)}
            className={'sales-file'}
          >
            <IconButton sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
              <img
                src={fileIcon}
                width="57px"
                height="auto"
                alt="open-file-button"
              />
            </IconButton>
            <Typography
              key={index}
              sx={{
                color: '#EFEFEF',
                fontFamily: 'Korolev Medium',
                fontSize: '0.85rem',
                margin: '0 auto',
              }}
            >
              {file.display_name
                ? file.display_name
                : `${uploadTypeToFile[file.type]} v${file.version}`}
            </Typography>
          </div>,
        );
      },
    );

    return <React.Fragment>{filesList}</React.Fragment>;
  };

  const buildTypeFilesList = () => {

    const renderFiles = [];

    specificFiles?.forEach((file, index) => {
      renderFiles.push(
        <div
          key={index}
          onClick={() => openInNewTab('/sales-viewer/' + file.id)}
          className={'sales-file'}
        >
          <IconButton sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
            <img
              src={fileIcon}
              width="57px"
              height="auto"
              alt="approve-button"
            />
          </IconButton>
          <Typography
            key={index}
            sx={{
              color: '#EFEFEF',
              fontFamily: 'Korolev Medium',
              fontSize: '0.85rem',
              margin: '0 auto',
              maxWidth: '100px',
              textAlign: 'center',
            }}
          >
            {file.display_name
              ? file.display_name
              : `${uploadTypeToFile[file.type]} v${file.version}`}
          </Typography>
        </div>,
      );
    });

    return <React.Fragment>{renderFiles}</React.Fragment>;
  };

  return (
    <div
      className="sales-viewFile"
      style={{
        height: '100%',
        padding: '33px 45px 27px',
        position: 'relative',
        width: '70%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'MagistralCondW08-Medium',
          fontSize: '25px',
          justifyContent: 'space-between',
        }}
      >

        {/* {view === "All" ? "View All Files" : view} */}
        {paramToReq.type_name ? paramToReq.type_name : 'All Files'}
        <button
          className="create-button"
          onClick={() => {
            setView('All');
            setParamToReq({
              type_name: '',
              category: '',
            });
          }}
        >
          View All Files
        </button>
      </div>
      <Divider
        sx={{ borderColor: '#8b8b8b', marginBottom: '40px', marginTop: '25px' }}
      />
      <div className={'sales-files-list'}>
        {vendorFiles && view === 'All' && buildAllFilesList()}
        {vendorFiles &&
          view !== 'All' &&
          view !== 'Upload File' &&
          buildTypeFilesList()}

        {/* Modal - upload a new file */}
        <NewBaseModal
          dialogContent={
            <SalesUploadFileModal
              id={id}
              refreshFiles={retrieveFiles}
              refreshFilteredFiles={refreshFilteredFiles}
            />}
          onClose={() => setOpenUploadModal(false)}
          open={openUploadModal}
          title={'Add new file'}
          colorPalette="navy"
        />
        {/* Modal - add new file type */}
        <NewBaseModal
          dialogContent={<SalesNewFileTypeModal id={id} refreshFileFolders={refreshFileFolders}/>}
          onClose={() => setOpenNewFileTypeModal(false)}
          open={openNewFileTypeModal}
          title={'Add new file type'}
          colorPalette="navy"
        />
      </div>
      {view !== 'Upload File' && view !== 'Verification' && (
        <div
          style={{
            position: 'absolute',
            bottom: '27px',
            right: '45px',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <button
            className="create-button "
            style={{ marginRight: '14px' }}
            onClick={() => {
              setOpenNewFileTypeModal(true);
            }}
          >
            Create Custom Folder
          </button>

          <button
            className=" create-button "
            onClick={() => {
              setOpenUploadModal(true);
            }}
          >
            Upload File
          </button>
        </div>
      )
      }
    </div>
  );
}
