import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { Button, Switch } from '@mui/material';

import "../../css/contracts.scss";

import ContractGeneralInfoForm from "../contracts/contract-general-info-form";
import Toast from "components/toast/toast";
import { DeleteModal } from 'components/modal/delete-modal';
import ProgressSpinner from 'components/progress/progress-spinner';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { deleteContractById, editContractById } from 'store/contracts/thunk';
import {
  contractActionStatusSelector,
  contractsLoadingSelector,
  deleteContractErrorSelector,
  deleteContractLoadingSelector,
  selectedContractSelector,
} from 'store/contracts/selectors';
import { setContractActionStatus } from 'store/contracts/slice';

export default function GeneralInformation() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentContract = useAppSelector(selectedContractSelector);
  const contractActionStatus = useAppSelector(contractActionStatusSelector);
  const loadingDelete = useAppSelector(deleteContractLoadingSelector);
  const deleteError = useAppSelector(deleteContractErrorSelector);
  const loading = useAppSelector(contractsLoadingSelector);
  
  const [status, setStatus] = useState("");
  const [staffingFilter, setStaffingFilter] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteContract, setDeleteContract] = useState(false);
  
  useEffect(() => {
    if (currentContract) {
      setStatus(currentContract.status);
      setStaffingFilter(currentContract.is_staffing_filterable);
    }
  }, [currentContract]);
  
  useEffect(() => {
    if (contractActionStatus === 'fulfilled') {
      setOpen(true);
      dispatch(setContractActionStatus(''));
    }
  }, [contractActionStatus]);

  const handleDelete = () => {
    dispatch(deleteContractById({ contractId: currentContract.id }))
      .then(() => {
        navigate('/contracts');
      })
  };

  const handleStatusUpdate = () => {
    const body = {
      status: status,
      is_staffing_filterable: staffingFilter,
    }
    dispatch(editContractById({ contractId: currentContract.id, body }))
      .then(() => setOpen(true));
  };
  
  return (
    <>
      <h1 className="content-header">General Information</h1>
      <div className="content-container">
        <ContractGeneralInfoForm currentContract={currentContract} />
        <div className="contract-form-section" style={{ margin: 0 }}>
          <div className="contract-form-section-header">Contract Management</div>
          <div className="contract-form-section-content">
            <div className="contract-form-set">
              <div className="contract-form-label">Status</div>
              <select
                className="contract-form-input"
                placeholder="Enter Status..."
                value={status}
                onChange={(e) => setStatus(e.currentTarget.value)}
              >
                <option value="pending">Pending</option>
                <option value="active">Active</option>
                <option value="completed">Completed</option>
                <option value="archived">Archived</option>
              </select>
            </div>
            <div
              className="contract-form-set-full"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "-25px",
              }}
            >
              <div
                className="contract-form-label"
                style={{
                  marginBottom: 0,
                  marginRight: "8px",
                }}
              >
                Staffing Filter
              </div>
              <Switch
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#62AB37',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#62AB37',
                  },
                  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                    backgroundColor: '#c7c7c7',
                  },
                }}
                checked={staffingFilter}
                onChange={() => setStaffingFilter(!staffingFilter)}
              />
            </div>
          </div>
        </div>
        <div className="contract-form-button-container">
          {(loading || loadingDelete) && (
            <div className="sale-form-spinner" style={{ right: '325px' }}>
              <ProgressSpinner />
            </div>
          )}
          <Button
            variant="contained"
            disabled={loadingDelete}
            className="add-user-button"
            sx={{
              backgroundColor: "#a20601",
              color: "#EFEFEF",
              fontSize: "14px",
              width: "175px",
              border: '1px solid #a20601',
              borderRadius: '10px',
              textTransform: 'capitalize',
              transition: 'all 0.3s',
              height: '45px',
              marginRight: '20px',

              "&:hover": {
                backgroundColor: "transparent",
                border: '1px solid #EFEFEF80'
              },
            }}
            onClick={() => setDeleteContract(true)}
          >
            Delete Contract
          </Button>
          <button
            disabled={loading}
            className="create-button"
            onClick={handleStatusUpdate}
          >
            Update
          </button>
        </div>

        {open && (
          <Toast
            open={open}
            onClose={() => setOpen(false)}
            message="Contract saved"
          />
        )}

        {deleteContract && (
          <DeleteModal
            open={deleteContract}
            handleClose={() => setDeleteContract(false)}
            title="Delete"
            type="Contract"
            name={currentContract?.title}
            errorMessage={deleteError}
            handleDelete={handleDelete}
            loading={loadingDelete}
          />
        )}
      </div>
    </>
  );
}
