import React from "react";
import ImageIconHexagon from "assets/user-hexagon.png";
import ImageIconSite from "assets/site.png";
import ImageIconEmail from "assets/email.png";
import ImageIconPhone from "assets/phone.png";
import ImageIconFax from "assets/fax.png";
import { formatPhoneNumber } from "shared/formatting";

export default function VendorUserProfilePanel(props) {
  const { user, vendor } = props;

  return (
    <div className="sidebar__profile">
      <div className="row">
        <img className="sidebar__profile-icon" src={ImageIconHexagon} alt="icon" />
        <div className="sidebar__profile-name">
          <h3>{vendor.name}</h3>
        </div>
      </div>
      <div className="sidebar__profile-details">
        <div className="row">
          <img src={ImageIconPhone} alt="phone" />
          <p>{user.phone ? formatPhoneNumber(user.phone) : "N/A"}</p>
        </div>
        <div className="row">
          <img src={ImageIconEmail} alt="email" />
          <p>{user.email ? user.email : "N/A"}</p>
        </div>
        <div className="row">
          <img src={ImageIconFax} alt="fax" />
          <p>{vendor.fax ? vendor.fax : "N/A"}</p>
        </div>
        <div className="row">
          <img src={ImageIconSite} alt="web" />
          <p>{vendor.website ? vendor.website : "N/A"}</p>
        </div>
      </div>
    </div>
  );
}
