import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";

export default function ViewNotes(props) {
  const { notes, modalOpened } = props;
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const notesEndRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
        scrollToBottom();
      }, 100);
      return () => clearTimeout(timeout);
  }, [modalOpened]);

  const scrollToBottom = () => {
    notesEndRef.current?.scrollIntoView({ behavior: "smooth", block: 'end' });
  };

  const formatDateTime = (timestamp) => {
    const dateTime = new Date(timestamp);
    return dateTime.toLocaleString();
  };

  const buildComms = () => {
    const textBubbles = notes.map((message, index) => (
      <Box
        key={index}
        display="flex"
        flexDirection="column"
        sx={{
          marginBottom: "8px",
          textAlign: "left",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: "#171717",
            fontSize: "13px",
            marginBottom: "2px",
            paddingLeft: "8px",
            textTransform: "capitalize",
          }}
        >
          {message.admin_first_name === loggedInUser.first_name && "You"}
          {message.admin_first_name &&
            message.admin_last_name !== loggedInUser.last_name &&
            `${message.admin_first_name} ${message.admin_last_name}`}
        </Typography>
        <Typography
          sx={{
            bgcolor: "white",
            border: 0,
            fontSize: "16px",
            borderRadius: "6px",
            fontFamily: "Korolev Medium",
            color: "#171717",
            padding: "10px 16px",
            overflowWrap: "break-word",
          }}
        >
          {message.body}
        </Typography>
        <Typography
          sx={{
            color: "#171717",
            fontSize: "13px",
            marginTop: "2px",
            paddingRight: "8px",
            textAlign: "right",
          }}
        >
          {formatDateTime(message.created_at)}
        </Typography>
      </Box>
    ));

    return (
      <Box
        style={{
          boxShadow: 0,
        }}
      >
        {textBubbles}
        <div style={{paddingBottom:'20px'}} ref={notesEndRef} />
      </Box>
    );
  };

  return (
    <div style={{width:'550px', maxHeight:'550px'}}>
        {buildComms()}
    </div>
  );
}
