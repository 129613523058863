import React, { useEffect, useState } from "react";
import "../css/splash-screen.scss";
import bgImage from "../assets/hexagon-bg-full-screen.jpg";
import { useNavigate } from "react-router-dom";
import staffingIcon from "../assets/RS-staffing-icon-03.svg";
import salesIcon from "../assets/RS-sales-icon.svg";
import accountingIcon from "../assets/hexagon-icon-bg-02 2.png";
import AppBar from "../components/app-bar/updated-app-bar-component";
import { Grid, Button } from "@mui/material";

const SplashScreen = () => {
  const navigate = useNavigate();
  const loggedInUser = JSON.parse(localStorage.getItem("user"));

  const [salesEnabled, setSalesEnabled] = useState(false);
  const [staffingEnabled, setStaffingEnabled] = useState(false);
  const [accountingEnabled, setAccountingEnabled] = useState(false);
  const [projectsEnabled, setProjectsEnabled] = useState(false);

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.role === "admin" || loggedInUser.role === "sales") {
        setSalesEnabled(true);
      }
      if (loggedInUser.role === "admin" || loggedInUser.role === "recruiter" || loggedInUser.role === "staffing") {
        setStaffingEnabled(true);
      }
      if (loggedInUser.role === "admin" || loggedInUser.role === "project manager" || loggedInUser.role === "project coordinator") {
        setProjectsEnabled(true);
      }
      if (loggedInUser.role === "admin" || loggedInUser.role === "accounting") {
        setAccountingEnabled(true);
      }
    }
  }, [loggedInUser]);

  return (
    <React.Fragment>
      <AppBar menu={false} />
      <Grid
        container
        className="splash-content-container"
        sx={{
          backgroundImage: `url("${bgImage}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          imageRendering: "-webkit-optimize-contrast",
        }}
      >
        <Grid container className="card-container">
          {accountingEnabled ? (
            <Grid item className="card enabled" onClick={() => navigate("/accounting")}>
              <Grid className="card-details">
                <div className="card-icon">
                  <img
                    src={accountingIcon}
                    alt="accounting-icon"
                    onClick={() => navigate("/accounting")}
                  />
                </div>
                <div className="card-title">ACCOUNTING</div>
                <Button
                  sx={{
                    fontFamily: "Korolev Medium",
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item className="card">
              <Grid className="card-details">
                <div className="card-icon">
                  <img
                    src={accountingIcon}
                    alt="accounting-icon"
                  />
                </div>
                <div className="card-title">ACCOUNTING</div>
                <div className="restricted">Restricted</div>
              </Grid>
            </Grid>
          )}
          {projectsEnabled ? (
            <Grid item className="card enabled" onClick={() => navigate("/projects")}>
              <Grid className="card-details">
                <div className="card-icon">
                  <img
                    src={salesIcon}
                    alt="sales-icon"
                    onClick={() => navigate("/projects")}
                  />
                </div>
                <div className="card-title">PROJECTS</div>
                <Button
                  sx={{
                    fontFamily: "Korolev Medium",
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item className="card">
              <Grid className="card-details">
                <div className="card-icon">
                  <img
                    src={salesIcon}
                    alt="sales-icon"
                  />
                </div>
                <div className="card-title">PROJECTS</div>
                <div className="restricted">Restricted</div>
              </Grid>
            </Grid>
          )}
          {salesEnabled ? (
            <Grid item className="card enabled" onClick={() => navigate("/sales")}>
              <Grid className="card-details">
                <div className="card-icon">
                  <img
                    src={salesIcon}
                    alt="sales-icon"
                    onClick={() => navigate("/sales")}
                  />
                </div>
                <div className="card-title">SALES</div>
                <Button
                  sx={{
                    fontFamily: "Korolev Medium",
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item className="card">
              <Grid className="card-details">
                <div className="card-icon">
                  <img
                    src={salesIcon}
                    alt="sales-icon"
                  />
                </div>
                <div className="card-title">SALES</div>
                <div className="restricted">Restricted</div>
              </Grid>
            </Grid>
          )}
          {staffingEnabled ? (
            <Grid item className="card enabled" onClick={() => navigate("/staffing")}>
              <Grid className="card-details">
                <div className="card-icon">
                  <img
                    src={staffingIcon}
                    alt="staffing-icon"
                    onClick={() => navigate("/staffing")}
                  />
                </div>
                <div className="card-title">STAFFING</div>
                <Button
                  sx={{
                    fontFamily: "Korolev Medium",
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item className="card">
              <Grid className="card-details">
                <div className="card-icon">
                  <img
                    src={staffingIcon}
                    alt="staffing-icon"
                  />
                </div>
                <div className="card-title">STAFFING</div>
                <div className="restricted">Restricted</div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SplashScreen;
