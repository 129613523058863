import "../../css/toast.scss";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { SnackbarContent } from "@mui/material";

export default function FileToast(props) {
  const { open, onClose, message } = props;

  const action = (
    <IconButton size="small" aria-label="close" onClick={onClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <div>
      <Snackbar open={open} autoHideDuration={5000} onClose={onClose}>
        <SnackbarContent
          action={action}
          message={message}
          sx={{
            backgroundColor: props.type ? "#60992d" : "#a20601",
            color: "#171717",
          }}
        />
      </Snackbar>
    </div>
  );
}
