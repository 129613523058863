import { createAsyncThunk } from '@reduxjs/toolkit';
import { notes } from 'services/notes';
export const createNote = createAsyncThunk(
  'createNote',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await notes.createNote(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
