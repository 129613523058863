import '../../css/filter.scss';
import React, { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Search } from 'components/search-box/search-box';
import { IconButton, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { contractFiltersSelector } from 'store/contracts/selectors';
import { filterableDivisionsSelector } from 'store/divisions/selectors';
import { getFilterableDivisions } from 'store/divisions/thunk';
import { filterableSubdivisionsSelector } from 'store/subdivisions/selectors';
import { getFilterableSubdivisions } from 'store/subdivisions/thunk';
import {
  companyFilterSelector,
  contractFilterIdSelector,
  currentFilterViewSelector, currentPageSelector,
  currentSortOptionSelector,
  currentSortOrderSelector,
  isSearchedApplicantsSelector,
  psqAlertFilterSelector,
  recruiterFilterIdSelector,
  regionFilterIdSelector,
  searchFilterSelector,
  searchInputSelector,
  siteFilterIdSelector,
  textAlertFilterSelector,
} from 'store/staffingSelectedOptions/selectors';
import {
  setCompanyFilter,
  setContractFilterId,
  setCurrentFilterView, setIsSearchedApplicants,
  setRegionFilterId,
  setSearchFilter,
  setSearchInput,
  setSiteFilterId,
  togglePsqAlertFilter,
  toggleTextAlertFilter,
} from 'store/staffingSelectedOptions/slice';
import { useGetSearchedApplicants } from 'hooks/getSearchedApplicants';
import { setContractTemplates } from 'store/contracts/slice';
import { setApplicantState } from 'store/applicants/slice';
import { FilterNestedDropdown } from 'components/filter-items/filter-nested-dropdown';

export default function Filter({ getApplicantsByStage }) {
  const dispatch = useAppDispatch();
  const contracts = useAppSelector(contractFiltersSelector);
  const divisions = useAppSelector(filterableDivisionsSelector);
  const subdivisions = useAppSelector(filterableSubdivisionsSelector);
  const contractId = useAppSelector(contractFilterIdSelector);
  const divisionId = useAppSelector(regionFilterIdSelector);
  const subDivisionId = useAppSelector(siteFilterIdSelector);
  const company = useAppSelector(companyFilterSelector);
  const textAlert = useAppSelector(textAlertFilterSelector);
  const psqAlert = useAppSelector(psqAlertFilterSelector);
  const currentPage = useAppSelector(currentPageSelector);

  const recruiterFilterId = useAppSelector(recruiterFilterIdSelector);
  const currentSortOption = useAppSelector(currentSortOptionSelector);
  const currentSortOrder = useAppSelector(currentSortOrderSelector);
  const searchInput = useAppSelector(searchInputSelector);
  const searchFilter = useAppSelector(searchFilterSelector);
  
  const currentFilterView = useAppSelector(currentFilterViewSelector);
  const isSearchedApplicants = useAppSelector(isSearchedApplicantsSelector);

  const searchApplicants = useGetSearchedApplicants();

  useEffect(() => {
    if (contractId && contractId !== 'null') {
      dispatch(getFilterableDivisions({ contractId }));
    }
  }, [contractId]);

  useEffect(() => {
    if (divisionId && divisionId !== 'null') {
      dispatch(getFilterableSubdivisions({ divisionId }));
    }
  }, [divisionId]);
  
  useEffect(() => {
    if (isSearchedApplicants) {
      searchApplicants();
    }
  }, [currentPage, currentSortOption, currentSortOrder, recruiterFilterId]);

  const buildCategoriesMenu = () => {
    return (
      <>
        <button
          className={
            searchFilter === 'last_name'
              ? 'category-button-selected'
              : 'category-button'
          }
          onClick={() => dispatch(setSearchFilter('last_name'))}
        >
          Last Name
        </button>
        <button
          className={
            searchFilter === 'first_name'
              ? 'category-button-selected'
              : 'category-button'
          }
          onClick={() => dispatch(setSearchFilter('first_name'))}
        >
          First Name
        </button>
        <button
          className={
            searchFilter === 'email'
              ? 'category-button-selected'
              : 'category-button'
          }
          onClick={() => dispatch(setSearchFilter('email'))}
        >
          Email Address
        </button>
        <button
          className={
            searchFilter === 'phone'
              ? 'category-button-selected last-button'
              : 'category-button last-button'
          }
          onClick={() => dispatch(setSearchFilter('phone'))}
        >
          Phone Number
        </button>
      </>
    );
  };

  const buildFiltersMenu = () => {
    return (
      <>
        <FilterNestedDropdown
          classValue={
            textAlert || psqAlert
              ? 'filter-button-selected dropdown'
              : 'filter-button dropdown'
          }
          label={
            textAlert && psqAlert
              ? 'Alerts: Texts & Documents'
              : textAlert
                ? 'Alerts: Texts'
                : psqAlert
                  ? 'Alerts: Documents'
                  : 'Alerts'
          }
          buttons={[
            {
              label: 'Text Alerts',
              className: textAlert ? 'filter-button-selected' : 'filter-button',
              onClick: () => {
                dispatch(toggleTextAlertFilter());
              },
            },
            {
              label: 'Document Alerts',
              className: psqAlert ? 'filter-button-selected' : 'filter-button',
              onClick: () => {
                dispatch(togglePsqAlertFilter());
              },
            },
          ]}
        />
        <FilterNestedDropdown
          classValue={
            contractId ? 'filter-button-selected dropdown' : 'filter-button dropdown'
          }
          label={contractId ? `Contract: ${calculateContractName()}` : 'Contracts'}
          buttons={generateContractOptions()}
        />
        {contractId && contractId !== 'null' && (
          <FilterNestedDropdown
            classValue={
              divisionId ? 'filter-button-selected dropdown' : 'filter-button dropdown'
            }
            label={divisionId ? `Division: ${calculateDivisionName()}` : 'Divisions'}
            buttons={generateDivisionOptions()}
          />
        )}
        {divisionId && divisionId !== 'null' && (
          <FilterNestedDropdown
            classValue={
              subDivisionId ? 'filter-button-selected dropdown' : 'filter-button dropdown'
            }
            label={subDivisionId ? `Subdivision: ${calculateSubdivisionName()}` : 'Subdivisions'}
            buttons={generateSubdivisionOptions()}
          />
        )}
        <FilterNestedDropdown
          classValue={company ? 'filter-button-selected dropdown' : 'filter-button dropdown'}
          label={company ? `Vendor: ${calculateCompanyName()}` : 'Vendors'}
          buttons={generateCompanyOptions()}
        />
      </>
    );
  };

  const clearSearchAndFetch = () => {
    dispatch(setIsSearchedApplicants(false));
    getApplicantsByStage();
  };
  
  const calculateContractName = () => {
    let contractName = '';
    if (contractId && contracts) {
      const foundContract = contracts.find(({ id }) => id === +contractId);
      if (foundContract) {
        contractName = foundContract.title;
      } else {
        contractName = 'Not Assigned';
      }
    }
    
    return contractName;
  }
  
  const calculateCompanyName = () => {
    let companyName = '';
    if (company) {
      if (company !== 'null') {
        companyName = company;
      } else {
        companyName = 'Not Assigned';
      }
    }
    
    return companyName;
  }
  
  const calculateDivisionName = () => {
    let divisionName = '';
    if (divisionId && divisions) {
      const foundDivision = divisions.find(({ id }) => id === +divisionId);
      if (foundDivision) {
        divisionName = foundDivision.title;
      } else {
        divisionName = 'Not Assigned';
      }
    }
    
    return divisionName;
  }
  
  const calculateSubdivisionName = () => {
    let subdivisionName = '';
    if (subDivisionId && subdivisions) {
      const foundSubdivision = subdivisions.find(
        ({ id }) => id === +subDivisionId,
      );
      if (foundSubdivision) {
        subdivisionName = foundSubdivision.title;
      } else {
        subdivisionName = 'Not Assigned';
      }
    }
    
    return subdivisionName;
  }
  
  const generateContractOptions = () => {
    const options = [
      {
        label: 'Not Assigned',
        key: 'not-assigned-contract',
        className: contractId === 'null' ? 'filter-button-selected' : 'filter-button',
        onClick: () => {
          dispatch(setContractFilterId('null'));
          dispatch(setRegionFilterId(''));
          dispatch(setSiteFilterId(''));
          dispatch(setContractTemplates([]));
          dispatch(setApplicantState(null));
        },
      },
    ];
    
    if (contracts) {
      contracts.forEach((contract) => {
        options.push({
          label: contract.title,
          key: contract.id,
          className: contractId === String(contract.id) ? 'filter-button-selected' : 'filter-button',
          onClick: () => {
            dispatch(setContractFilterId(String(contract.id)));
            dispatch(setRegionFilterId(''));
            dispatch(setSiteFilterId(''));
          },
        });
      });
    }
    
    return options;
  }
  
  const generateDivisionOptions = () => {
    let options = [];
    
    if (divisions.length > 0) {
      options.push(
        {
          label: 'Not Assigned',
          key: 'not-assigned-division',
          className: divisionId === 'null' ? 'filter-button-selected' : 'filter-button',
          onClick: () => {
            dispatch(setRegionFilterId('null'));
            dispatch(setSiteFilterId(''));
          },
        }
      );
    }
    
    if (divisions) {
      divisions.forEach((division) => {
        options.push({
          label: division.title,
          key: division.id,
          className: divisionId === String(division.id) ? 'filter-button-selected' : 'filter-button',
          onClick: () => {
            dispatch(setRegionFilterId(String(division.id)));
            dispatch(setSiteFilterId(''));
          },
        });
      });
    }
    
    if (!divisions.length) {
      options.push({
        label: 'No divisions',
        key: 'none-division',
        className: 'filter-button',
        disabled: true,
      });
    }
    
    return options;
  }
  
  function generateSubdivisionOptions() {
    let options = [];
    
    if (subdivisions.length > 0) {
      options.push({
        label: 'Not Assigned',
        key: 'not-assigned-subdivision',
        className: subDivisionId === 'null' ? 'filter-button-selected' : 'filter-button',
        onClick: () => {
          dispatch(setSiteFilterId('null'));
        },
      });
    }
    
    if (subdivisions) {
      subdivisions.forEach((subdivision) => {
        options.push({
          label: subdivision.title,
          key: subdivision.id,
          className: subDivisionId === String(subdivision.id) ? 'filter-button-selected' : 'filter-button',
          onClick: () => {
            dispatch(setSiteFilterId(String(subdivision.id)));
          },
        });
      });
    }
    
    if (!subdivisions.length) {
      options.push({
        label: 'No subdivisions',
        key: 'none-subdivision',
        className: 'filter-button',
        disabled: true,
      });
    }
    
    return options;
  }
  
  function generateCompanyOptions() {
    const options = [
      {
        label: 'Not Assigned',
        key: 'not-assigned-company',
        className: company === 'null' ? 'filter-button-selected' : 'filter-button',
        onClick: () => {
          dispatch(setCompanyFilter('null'));
        },
      },
    ];
    
    ['RD', 'KE', 'LOY', 'LRG', 'MED', 'NASS', 'WS'].forEach((item) => {
      const lastButton = item === 'WS' ? 'last-button' : '';
      options.push({
        label: item,
        key: item,
        className: company === item ? `filter-button-selected ${lastButton}` : `filter-button ${lastButton}`,
        onClick: () => {
          dispatch(setCompanyFilter(item));
        },
      });
    });
    
    return options;
  }
  
  return (
    <>
      {currentFilterView === 'categories' && (
        <>
          <Search sx={{ padding: 0, width: '100%' }}>
            <TextField
              placeholder="Search…"
              autoFocus={true}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  searchApplicants();
                }
              }}
              value={searchInput}
              sx={{
                width: '100%',
                backgroundColor: '#3e453d',
                borderRadius: '4px',
                color: '#EFEFEF',
                fontFamily: 'Korolev Medium',
                input: {
                  width: '100%',
                  color: '#EFEFEF',
                  fontFamily: 'Korolev Medium',
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px #3e453d inset',
                    WebkitTextFillColor: '#EFEFEF',
                  },
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#a5af96',
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#a5af96',
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>
                    <IconButton onClick={searchApplicants}>
                      <SearchIcon sx={{ color: '#EFEFEF' }}/>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                dispatch(setSearchInput(e.target.value));
              }}
            />
          </Search>
          <div className="clear-button" onClick={() => clearSearchAndFetch()}>
            Clear
          </div>
        </>
      )}
      <div className="options-container">
        <div className="options-header">
          <button
            className={
              currentFilterView === 'filters'
                ? 'options-header-button-selected'
                : 'options-header-button left'
            }
            onClick={() => dispatch(setCurrentFilterView('filters'))}
          >
            Filters
          </button>
          <button
            className={
              currentFilterView === 'categories'
                ? 'options-header-button-selected'
                : 'options-header-button right'
            }
            onClick={() => dispatch(setCurrentFilterView('categories'))}
          >
            Search
          </button>
        </div>
        {currentFilterView === 'filters' && buildFiltersMenu()}
        {currentFilterView === 'categories' && buildCategoriesMenu()}
      </div>
    </>
  );
}
