import React, { useEffect } from 'react';

import "../../css/pages.scss";

import AppBar from "components/app-bar/updated-app-bar-component";
import SidebarTabs from "components/tabs/sidebar-tabs";
import CreateNewContract from "components/contracts/create-new-contract";
import ContractsTable from "components/contracts/contracts-table";
import ArchivedApplicantsTable from "components/table/archived-applicants-table";
import AllContractsAnalytics from 'components/contracts-analytics/allContractsAnalytics';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  contractsStatusSelector,
  currentContractsTabSelector,
  currentPageSelector,
} from 'store/contracts/selectors';
import { getAllContractMetrics, getContracts, getContractsByStatus } from 'store/contracts/thunk';
import { getArchivedApplicants } from 'store/applicants/thunk';
import { setCurrentContractsTab } from 'store/contracts/slice';
import { ContractsTagSystem } from 'components/contracts/contracts-tag-system';
import { archivedApplicantsPageSelector } from 'store/applicants/selectors';

export default function Contracts() {
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(currentContractsTabSelector);
  const currentPage = useAppSelector(currentPageSelector);
  const contractsStatus = useAppSelector(contractsStatusSelector);
  const currentApplicantPage = useAppSelector(archivedApplicantsPageSelector);
  
  useEffect(() => {
    dispatch(getAllContractMetrics());
  }, [])

  useEffect(() => {
    dispatch(getArchivedApplicants({ page: currentApplicantPage }));
  }, [currentApplicantPage]);
  
  useEffect(() => {
    if (contractsStatus.length > 0) {
      if (contractsStatus === 'All Contracts') {
        dispatch(getContracts({ currentPage }));
      } else {
        dispatch(getContractsByStatus({ contractsStatus: contractsStatus.toLowerCase(), currentPage }))
      }
    }
  }, [currentPage, contractsStatus])
  
  const handleTabChange = (tab) => {
    dispatch(setCurrentContractsTab(tab));
  };

  return (
    <>
      <AppBar colorPalette="green" menu={true} title="Contracts" />
      <div className="staffing-page">
        <div className="sidebar-content" style={{ width: '20%' }}>
          <div className="user-info-container">
            <div
              style={{
                color: "#EFEFEF",
                fontFamily: "MagistralCondW08-Medium",
                fontSize: "35px",
                fontStyle: "normal",
                fontWeight: "500",
              }}
            >
              Option Panel
            </div>
          </div>
          <div className="tabs-container">
            <SidebarTabs
              colorPalette={"green"}
              currentTab={currentTab}
              setCurrentTab={handleTabChange}
              tabs={[
                "contracts",
                "create new contract",
                "archived applicants",
                'analytics',
                'tag system',
              ]}
            />
          </div>
        </div>
        <div className="page-content" style={{ width: '80%' }}>
          {currentTab === "contracts" && (
            <ContractsTable />
          )}
          {currentTab === "create new contract" && (
            <CreateNewContract getContracts={() => dispatch(getContracts({ currentPage }))} />
          )}
          {currentTab === "archived applicants" && (
            <ArchivedApplicantsTable />
          )}
          {currentTab === "analytics" && (
            <AllContractsAnalytics color={'green'} />
          )}
          {currentTab === 'tag system' && (
            <ContractsTagSystem />
          )}
        </div>
      </div>
    </>
  );
}
