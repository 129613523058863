import "../../css/pages.scss";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import AppBar from "../../components/app-bar/updated-app-bar-component";
import SalesProfilePanel from "components/sales/sales-profile-panel";
import SidebarTabs from "components/tabs/sidebar-tabs";
import SalesDropBoxPageContent from "components/drop-box/sales-drop-box";
import { SalesConnections } from "components/sales/sales-connections";
import SalesNotes from "components/notes/sales-notes";
import CreateNewTask from "components/sales/create-new-task"
import SalesTasks from "components/sales/sales-tasks";
import { SalesMilestones } from 'components/sales/sales-milestones';
import { SalesInformation } from 'components/sales/sales-information';
import { SalesQuoteCreator } from 'components/sales/sales-quote-creator';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { saleSelector } from 'store/sales/selectors';
import {
  getBidTypes,
  getSale, getSaleNotes,
  getSaleQuotes,
  getSalesDivisions, getSalesMilestonesTasks,
  getSalesReps, getSalesTasks,
} from 'store/sales/thunk';
import { getQuoteCategories } from 'store/quotes/thunk';
import { setTasksPage, setTasksTab } from 'store/sales/slice';

export default function SalesPortal() {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { sale, loading, tasksTab, tasksPage } = useAppSelector(saleSelector);
  const [currentTab, setCurrentTab] = useState("general information");
  const [isLoadedQuotes, setIsLoadedQuotes] = useState(false);
  const [isLoadedNotes, setIsLoadedNotes] = useState(false);
  const [isLoadedBidTypes, setIsLoadedBidTypes] = useState(false);
  const [isLoadedDivisions, setIsLoadedDivisions] = useState(false);
  const [isLoadedMilestones, setIsLoadedMilestones] = useState(false);

  useEffect(() => {
    if (id) {
      getSaleInfo();
      dispatch(getSalesReps());
      dispatch(setTasksPage(1));
      dispatch(setTasksTab('assigned'));
    }
  }, [id]);

  const resetTasks = () => {
    if (tasksTab === 'current') {
      dispatch(getSalesTasks({
        id: id,
        status: [
          'in progress',
          'requested approval',
          'returned',
          'blocked',
        ],
        page: tasksPage,
      }));
    } else {
      dispatch(getSalesTasks({
        id: id,
        status: [tasksTab],
        page: tasksPage,
      }));
    }
  }

  useEffect(() => {
    resetTasks();
  }, [tasksTab, tasksPage]);

  useEffect(() => {
    if (currentTab === 'quotes' && !isLoadedQuotes) {
      dispatch(getSaleQuotes({ saleId: id }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setIsLoadedQuotes(true);
          }
        });
      dispatch(getQuoteCategories({ saleId: id }));
    }
    if (currentTab === 'notes' && !isLoadedNotes) {
      dispatch(getSaleNotes({ id: id }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setIsLoadedNotes(true);
          }
        });
    }
    if ((currentTab === 'milestones') && !isLoadedMilestones) {
      dispatch(getSalesMilestonesTasks({ id: id }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setIsLoadedMilestones(true);
          }
        });
    }
    if (currentTab === 'general information') {
      if (!isLoadedBidTypes) {
        dispatch(getBidTypes())
          .then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
              setIsLoadedBidTypes(true);
            }
          });
      }
      if (!isLoadedDivisions) {
        dispatch(getSalesDivisions())
          .then((res) => {
            if (res.meta.requestStatus === 'fulfilled') {
              setIsLoadedDivisions(true);
            }
          });
      }
    }
  }, [currentTab]);

  const getSaleInfo = () => {
    dispatch(getSale({ saleId: id }))
  };

  return (
    <>
      <AppBar colorPalette="navy" menu={true} title="Sales Portal" />
      <div className="sales-page">
        <div className="sidebar-content">
          <div className="portal-preview">
            <button
              className="back-button navy-hover"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon
                sx={{
                  marginRight: '5px',
                  height: '15px',
                  width: 'auto',
                }}
              />
              Go back
            </button>
            <SalesProfilePanel sale={sale} loading={loading} />
            <div className="tabs-container">
              <SidebarTabs
                colorPalette={"navy"}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                tabs={[
                  "general information",
                  "milestones",
                  "tasking",
                  "create new task",
                  "connections",
                  "quotes",
                  "notes",
                  "drop box",
                ]}
              />
            </div>
          </div>
        </div>
        {(currentTab === "tasking" || currentTab === "connections")
          ? (
            <div className="page-content" style={{ paddingTop: "0px" }}>
              {currentTab === "tasking" && <SalesTasks />}
              {currentTab === "connections" &&
                <SalesConnections
                  sale={sale}
                  getSale={getSaleInfo}
                  createButtonNeeded={false}
                  loadingProp={loading}
                />
              }
            </div>
          ) : (
            <div className="page-content">
              {currentTab === "general information" &&
                <SalesInformation id={id} />
              }
              {currentTab === "drop box" && <SalesDropBoxPageContent id={id} />}
              {currentTab === "notes" && <SalesNotes id={id} />}
              {currentTab === "create new task" &&
                <CreateNewTask resetTasks={() => {
                  setIsLoadedMilestones(false);
                  resetTasks();
                }} />}
              {currentTab === "milestones" && <SalesMilestones />}
              {currentTab === "quotes" && <SalesQuoteCreator id={id} />}
            </div>
          )
        }
      </div>
    </>
  );
}
