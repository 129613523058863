import React, { useEffect, useRef, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import API from 'API';
import ProgressSpinner from 'components/progress/progress-spinner';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import SentIcon from 'assets/sent.svg';
import DeliveredIcon from 'assets/delivered.svg';
import ErrorIcon from 'assets/error-icon.svg';
import QueuedIcon from 'assets/queued.svg';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { userErrorSelector, userLoadingSelector } from 'store/user/selectors';
import { applicantEditStatusSelector } from 'store/applicants/selectors';
import { editApplicant, getApplicant } from 'store/applicants/thunk';
import { setApplicantEditStatus } from 'store/applicants/slice';

export default function CommsModule(props) {
  const { selectedUser } = props;
  const token = JSON.parse(localStorage.getItem('token'));
  const dispatch = useAppDispatch();

  const userError = useAppSelector(userErrorSelector);
  const userLoading = useAppSelector(userLoadingSelector);
  const applicantEditStatus = useAppSelector(applicantEditStatusSelector);

  const [textMessage, setTextMessage] = useState('');
  const [errors, setErrors] = useState('');
  const [sendLoading, setSendLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const echoRef = useRef(null);
  const [canReceiveMessage, setCanReceiveMessage] = useState(selectedUser.can_receive_messages);

  useEffect(() => {
    setCanReceiveMessage(selectedUser?.can_receive_messages);
  }, [selectedUser]);

  // userChats and messages are two variables with 
  // different purpose. userChats is supposed to hold
  // list of IDs that belong to a user. Based on these IDs we 
  // subscribe to corresponding channels. 
  // messages variable holds actual chat objects
  const [userChats, setUserChats] = useState([]);
  const [messages, setMessages] = useState([]);

  /**
   * Set up Echo / Pusher objects. Those are essential
   * for any websockets functionality.
   */

  function initLaravelEcho() {

    // Uncomment if detailed logging is needed
    // Pusher.log = function(logMessage) {
    //   console.log(logMessage);
    // };

    const echo = new Echo({
      broadcaster: 'pusher',
      key: 'cd730a850f3fc17904d0',
      cluster: 'us3',
      encrypted: true,
      authEndpoint: `${API.endpointURL}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      },
      client: new Pusher('cd730a850f3fc17904d0', {
        cluster: 'us3',
        encrypted: true,
        debug: false,
        authEndpoint: `${API.endpointURL}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        },

        // Uncomment if detailed logging is needed
        // log: function(logMessage) {
        //   console.log(logMessage);
        // },
      }),
    });

    return echoRef.current = echo;
  }


  /**
   * Sort messages by created_at date
   *
   * @param Array messagesToSort
   * @returns Array Sorted list of chats
   */
  function sortMessagesByCreatedAt(messagesToSort) {
    const copiedMessages = [...messagesToSort];
    const result = copiedMessages.sort((a, b) => {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    });

    return result;
  }

  /**
   * Update messages array
   *
   * @param newMessage - chat object to be added
   */
  const updateMessages = (newMessage) => {
    setMessages((prevMessages) => {
      const messageExists = prevMessages.some(message => message.id === newMessage.id);
      if (!messageExists) {
        return [...prevMessages, newMessage];
      }
      return prevMessages;
    });
  };
  const updateUserInfo = (user) => {
    setCanReceiveMessage(user.user.can_receive_messages);
  };

  /**
   * This is inital chats/chat id set up.
   * Get user chats from the server and set
   * corresponding variables
   *
   * @returns
   */
  async function getUserChats() {

    const response = await fetch(`${API.endpointURL}/api/user_chats/${selectedUser.id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });

    const statusCode = response.status;
    if (!(statusCode >= 200 && statusCode < 300)) {
      setMessages([]);
      setUserChats([]);
      await Promise.resolve();
      setErrors('An error occurred when fetching user chats.');
      return null;
    }

    const chats = await response.json();
    const chatIds = chats.data.map(chat => chat.id);

    setMessages(sortMessagesByCreatedAt(chats.data));
    setUserChats(chatIds);

    await Promise.resolve();
    return chatIds;
  }


  /**
   * Connect to each chat channel that belong to the user
   *
   * @param Array chats - this is supposed to be an arrays
   * of chat IDs that belong to selectedUser.
   */
  const subscribeToChannels = (chats) => {

    chats.forEach((chatId) => {
      const channel = echoRef.current.private(`chats.${chatId}`);

      // We enter this block when server sends an updated chat object.
      // newMessage variable holds the actual object that was sent from 
      // BE. At the moment, it should be chat entry.
      channel.listen('.event.chats.status.updated', (newMessage) => {

        const updatedChat = newMessage;

        updateChatStatus(updatedChat.id, updatedChat);
      });
    });
  };

  /**
   * Connect to specific chat channel
   */
  const subscribeToSingleChannel = (chatId) => {

    const channel = echoRef.current.private(`chats.${chatId}`);
    userChats.push(chatId);

    // We enter this block when server sends an updated chat object.
    // newMessage variable holds the actual object that was sent from 
    // BE. At the moment, it should be chat entry.
    channel.listen('.event.chats.status.updated', (newMessage) => {
      updateChatStatus(newMessage.id, newMessage);
    });
  };

  /**
   * Subscribe to chats channel to receive any
   * messages applicants may sent from their devices
   * @returns
   */
  const subscribeToReceivingMessages = () => {
    const channel = echoRef.current.private(`chats`);

    channel.listen('.event.chats.created', (newMessage) => {
      if (newMessage.user_id === selectedUser.id) {
        updateMessages(newMessage);
      }
    });
  };

  const subscribeToUpdatingUser = () => {
    const channel = echoRef.current.private(`users.${selectedUser.id}`);

    channel.listen('.event.users.can-receive-messages.changed', (newValue) => {
      updateUserInfo(newValue);
    });
  };


  /**
   * This is supposed to be triggered once server
   * sends an update on message status. We're grabbing
   * the updated object and swapping the old one with it.
   * That way, we always have most recent
   *
   * @param {*} chatId
   */
  const updateChatStatus = (chatId, updatedChat) => {

    setMessages((prevMessages) => {
      const messageExists = prevMessages.some(message => message.id === chatId);

      if (messageExists) {

        // If the message with this id already exists, update it
        const index = prevMessages.findIndex((message) => message.id === chatId);
        const newMessages = [...prevMessages];

        newMessages.splice(index, 1, updatedChat);
        return newMessages;

      } else {
        // If the message with this id doesn't exist, append it
        return [...prevMessages, updatedChat];
      }
    });
  };


  /**
   * Initial echo and chats set up
   */
  useEffect(() => {
    setLoading(true);

    initLaravelEcho();

    subscribeToReceivingMessages();
    subscribeToUpdatingUser();

    // Fetch user chats and subscribe to 
    // all chat channels
    getUserChats()
      .then(chatIds => {
        subscribeToChannels(chatIds);
        setLoading(false);
      })
      .catch((error) => {
        setErrors('Failed to subscribe to chat channels');
        setLoading(false);
      });

    // Unsubscribe from chats when component unmounts
    return () => {
      if (echoRef.current) {
        userChats.forEach((chatId) => {
          echoRef.current.leave(`chat.${chatId}`);
        });
      }
    };
  }, []);


  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (userError) {
      setErrors('Error updating applicant info.');
    }
  }, [userError]);

  useEffect(() => {
    if (applicantEditStatus === 'fulfilled') {
      dispatch(getApplicant({ applicantId: selectedUser?.id }));
      dispatch(setApplicantEditStatus(''));
    }
  }, [applicantEditStatus]);

  useEffect(() => {
    dispatch(getApplicant({ applicantId: selectedUser?.id }));
  }, [canReceiveMessage]);

  const handleSendText = async () => {
    setSendLoading(true);
    setErrors('');
    try {
      const response = await fetch(`${API.endpointURL}/api/chats/`, {
        method: 'POST',
        body: JSON.stringify({
          user_id: selectedUser.id,
          body: textMessage.length > 0 ? textMessage : 'This is a test message',
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json; charset=UTF-8',
          Accept: 'application/json',
        },
      });

      const data = await response.json();
      
      if (data.success === false) {
        setSendLoading(false);
        setErrors(data.message ? data.message : 'There was an error sending SMS. Please try again later.');
      } else {
        
        // Push new message data to corresponding
        // arrays 
        updateMessages(data.data);
        
        // Subscribe to newly created chat entry,
        // so that we are aware when this 
        // chat's status is updated by BE. 
        await subscribeToSingleChannel(data.data.id);
        
        setUserChats((prevUserChats) => [...prevUserChats, data.data.id]);
        
        setSendLoading(false);
        setTextMessage('');
        
      }
    } catch (err) {
      setSendLoading(false);
      setErrors(err.message);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (selectedUser?.application?.text_alert) {
      dispatch(editApplicant({ applicantId: selectedUser?.application?.id, body: { text_alert: false } }));
    }
  }, []);

  const handleChangeText = (newValue) => {
    setTextMessage(newValue);
  };

  const formatDateTime = (timestamp) => {
    const dateTime = new Date(timestamp);
    return dateTime.toLocaleString();
  };

  const keyPress = (e) => {
    // Submits text on enter
    if (e.keyCode === 13 && !sendLoading) {
      handleSendText();
    }
  };
  

  const buildComms = () => {
    let textBubbles = [];
    messages.length > 0 && messages.forEach((message) => {
      let bubble = <div></div>;
      {
        message.admin_id
          ? (bubble = (
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                marginBottom: '8px',
                marginLeft: 'auto',
                textAlign: 'left',
                width: '50%',
              }}
              key={message?.id}
            >
              <Typography
                sx={{
                  color: '#556155',
                  fontSize: '9.5px',
                  marginBottom: '2px',
                  paddingLeft: '15px',
                  textTransform: 'capitalize',
                }}
              >
                {`${message?.admin_first_name} ${message?.admin_last_name}`}
              </Typography>
              <Typography
                sx={{
                  position: 'relative',
                  bgcolor: '#3e453d',
                  border: 0,
                  borderRadius: '12px',
                  fontFamily: 'Korolev Medium',
                  color: '#EFEFEF',
                  padding: '10px 32px 10px 16px',
                  overflowWrap: 'break-word',
                }}
              >
                {message?.body}
                {message?.i_message?.status === 'queued' && (
                  <span className="staffing-comms__status">
                    <Tooltip title={'Queued'}>
                      <img src={QueuedIcon} alt="Queued"/>
                    </Tooltip>
                  </span>
                )}
                {message?.i_message?.status === 'sent' && (
                  <span className="staffing-comms__status">
                    <Tooltip title={'Sent'}>
                      <img src={SentIcon} alt="Sent"/>
                    </Tooltip>
                  </span>
                )}
                {message?.i_message?.status === 'delivered' && (
                  <span className="staffing-comms__status staffing-comms__status--delivered">
                    <Tooltip title={'Delivered'}>
                      <img src={DeliveredIcon} alt="Delivered"/>
                    </Tooltip>
                  </span>
                )}
                {message?.i_message?.status === 'error' && (
                  <span className="staffing-comms__status">
                    <Tooltip title={'Error'}>
                      <img src={ErrorIcon} alt="Error"/>
                    </Tooltip>
                  </span>
                )}
              </Typography>
              <Typography
                sx={{
                  color: '#556155',
                  fontSize: '9.5px',
                  marginTop: '2px',
                  paddingRight: '15px',
                  textAlign: 'right',
                }}
              >
                {formatDateTime(message.created_at)}
              </Typography>
            </Box>
          ))
          : (bubble = (
            <Box
              display="flex"
              flexDirection="column"
              sx={{ marginBottom: '8px', textAlign: 'left', width: '50%' }}
              key={message?.id}
            >
              <Typography
                sx={{
                  color: '#556155',
                  fontSize: '9.5px',
                  marginBottom: '2px',
                  paddingLeft: '15px',
                  textTransform: 'capitalize',
                }}
              >
                {`${message?.user_first_name} ${message?.user_last_name}`}
              </Typography>
              <Typography
                sx={{
                  bgcolor: '#dae0bc',
                  border: 0,
                  borderRadius: 5,
                  fontFamily: 'Korolev Medium',
                  color: '#3e453d',
                  padding: '10px 16px',
                  overflowWrap: 'break-word',
                }}
              >
                {message?.body}
              </Typography>
              <Typography
                sx={{
                  color: '#556155',
                  fontSize: '9.5px',
                  marginTop: '2px',
                  paddingRight: '15px',
                  textAlign: 'right',
                }}
              >
                {formatDateTime(message?.created_at)}
              </Typography>
            </Box>
          ));
      }
      textBubbles.push(bubble);
    });

    return (
      <Box
        style={{
          backgroundColor: '#EFEFEF',
          border: '1px solid #a5af96',
          borderBottom: 0,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          boxShadow: 0,
          height: '65%',
          overflow: 'auto',
          padding: '10px',
        }}
      >
        {textBubbles}
        <div ref={messagesEndRef}/>
      </Box>
    );
  };

  return (
    <div style={{ height: '100%' }}>
      {loading ? (
        <div className="content-loading">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          {messages && messages.length > 0 && buildComms()}
          <Box
            sx={
              messages && messages.length > 0
                ? {
                  backgroundColor: '#fff',
                  border: '1px solid #a5af96',
                  borderBottomLeftRadius: '8px',
                  borderBottomRightRadius: '8px',
                  width: '100%',
                  height: '30%',
                  color: 'black',
                  '&:hover': {
                    cursor: canReceiveMessage ? 'inherit' : 'not-allowed',
                  },
                }
                : {
                  backgroundColor: '#fff',
                  border: '1px solid #a5af96',
                  borderRadius: '8px',
                  width: '100%',
                  height: '30%',
                  color: 'black',
                  '&:hover': {
                    cursor: canReceiveMessage ? 'inherit' : 'not-allowed',
                  },
                }
            }
          >
            <TextField
              value={canReceiveMessage ? textMessage : 'Applicant is not opted in for texting.'}
              onChange={(e) => {
                if (canReceiveMessage ) {
                  handleChangeText(e.currentTarget.value);
                }
              }}
              onKeyDown={keyPress}
              fullWidth
              sx={{
                border: '0',
                padding: '6px 0 5px 10px',
                height: '100%',
                '&:hover': {
                  cursor: canReceiveMessage ? 'inherit' : 'not-allowed',
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#171717",
                },
              }}
              placeholder={'Message...'}
              id="fullWidth"
              multiline={true}
              rows={6}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                disabled: !canReceiveMessage,
              }}
              disabled={sendLoading || !canReceiveMessage}
            />
            <Box
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                padding: '5px 10px 10px',
                marginTop: '-40px',
              }}
            >
              <IconButton
                onClick={() => {
                  if (!sendLoading && !loading  && !userLoading) {
                    handleSendText();
                  }
                }}
                disabled={!textMessage || !canReceiveMessage}
                sx={{
                  padding: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    cursor: canReceiveMessage ? 'pointer' : 'not-allowed',
                  },
                }}
              >
                <SendIcon
                  sx={{
                    color: '#a5af96',
                    '&:hover': {
                      color: '#3e453d',
                      cursor: canReceiveMessage ? 'pointer' : 'not-allowed',
                    },
                  }}
                />
              </IconButton>
              {(sendLoading || loading || userLoading) && (
                <div className="dialog-spinner">
                  <ProgressSpinner/>
                </div>
              )}
              <p
                style={{
                  position: 'absolute',
                  color: 'red',
                  bottom: '-42px',
                  fontSize: '14px',
                  textAlign: 'end',
                  width: 'fit-content'
                }}
              >
                {errors}
              </p>
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
