import "../../css/task.scss";
import "../../css/sales.scss";
import React, { useState } from "react";
import Table from "components/generic/table";
import TableItem from "components/generic/table-item";
import SalesTaskModal from 'components/modal/sales-task-modal';
import SalesTabs from 'components/tabs/sales-tabs';
import Toast from 'components/toast/toast';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { formatUTCDate } from 'shared/formatting';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { saleSelector } from 'store/sales/selectors';
import { useLocation } from 'react-router-dom';
import { Pagination, Stack } from '@mui/material';
import { setTasksPage } from 'store/sales/slice';

export default function SalesTasks() {
  const { pathname } = useLocation();
  const {
    saleTasks,
    saleTasksLoading,
    loading,
    tasksTab: currentTab,
    totalTasksPages,
    tasksPage,
  } = useAppSelector(saleSelector);

  const [selectedTask, setSelectedTask] = useState(null);
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useAppDispatch();

  const renderTable = () => {
    return [...saleTasks]
      .sort((a, b) => {
        if (!a.date_due && !b.date_due) {
          return 0;
        } else if (!a.date_due) {
          return 1
        } else if (!b.date_due) {
          return -1
        }
        return b.date_due.localeCompare(a.date_due);
      })
      .map((task) => (
        <TableItem key={task.id}>
          <p>{task.name ? task.name : "N/A"}</p>
          <p>{task.date_assigned ? formatUTCDate(task.date_assigned)  : "N/A"}</p>
          <p>{task.date_due ? formatUTCDate(task.date_due)  : "N/A"}</p>
          <p>
            {
              task.personnel_assigned_data && (task.personnel_assigned_data.first_name || task.personnel_assigned_data.last_name)
                ? `${task.personnel_assigned_data.first_name || 'N/A'} ${task.personnel_assigned_data.last_name || 'N/A'}`
                : 'N/A'
            }

          </p>
          <div className="cell auto">
            <button
              className="navy-button"
              onClick={() => {
                setSelectedTask(task);
                setOpenTaskModal(true);
              }}
            >
              Manage
            </button>
          </div>
        </TableItem>
      ));
  };

  return (
    <>
      <SalesTabs
        currentTab={currentTab}
        tabs={['assigned', 'current', 'completed', 'archived']}
      />
      <div className="content-container content-container-tasks no-content-padding">
        {(loading || saleTasksLoading) ? (
          <div className="content-loading">
            <ProgressSpinnerNavy />
          </div>
        ) : (
          <Table
            pathname={pathname}
            columns={[
              "Task Name",
              "Date Assigned",
              "Date Due",
              "Personnel Assigned",
              "",
            ]}
          >
            {saleTasks && renderTable()}
          </Table>
        )}
      </div>
      <Stack
        sx={{
          alignItems: 'center',
          marginTop: 1.5,
          marginBottom: '-16px',
        }}
      >
        <Pagination
          count={+totalTasksPages}
          page={+tasksPage}
          onChange={(e, value) => dispatch(setTasksPage(value))}
          color="primary"
          size="large"
        />
      </Stack>
      {openTaskModal && (
        <SalesTaskModal
          handleClose={() => {
            setSelectedTask(null);
            setOpenTaskModal(false);
          }}
          open={openTaskModal}
          task={selectedTask}
          setServerError={setError}
        />
      )}
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={error
            ? 'Something went wrong, try again later...'
            : 'Successfully Updated'
          }
        />
      )}
    </>
  );
}
