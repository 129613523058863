import React, { useEffect, useState } from 'react';

import 'css/applicantCards.scss';

import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditUserDetail from '../modal/edit-user-detail';
import EditApplicantDetail from '../modal/edit-applicant-detail';
import EditApplicantPlacement from '../modal/edit-applicant-placement';
import EditApplicantScreening from '../modal/edit-applicant-screening';
import EditApplicantContacts from '../modal/edit-applicant-contacts';
import EditUserInfo from '../modal/edit-user-info';
import EditUserAddress from '../modal/edit-user-address';
import { referralTypes } from 'shared/applicant-data';
import { formatPhoneNumber } from 'shared/formatting';
import notAllowedMessages from 'assets/bxs-message-rounded-check.svg';
import allowedMessages from 'assets/bxs-message-rounded-x.svg';
import { ApplicantCard } from 'components/user-portal/applicant-card';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { contractSelector } from 'store/contracts/selectors';
import { subdivisionSelector } from 'store/subdivisions/selectors';
import { divisionSelector } from 'store/divisions/selectors';
import { getApplicantsQuestions } from 'store/applicants/thunk';
import { applicantQuestionsSelector } from 'store/applicants/selectors';
import EditUserTextOptIn from 'components/modal/edit-user-textOptIn';

export default function PersonalData(props) {
  const {
    applicant,
    getApplicant,
    portalType,
    view,
    setOpenToast,
  } = props;
  const formattedDOB = applicant.dob ? new Date(applicant.dob).toLocaleDateString('en-US') : null;

  const dispatch = useAppDispatch();
  const applicantContract = useAppSelector(contractSelector);
  const applicantDivision = useAppSelector(divisionSelector);
  const applicantSubdivision = useAppSelector(subdivisionSelector);
  const { initialQuestions, loading } = useAppSelector(applicantQuestionsSelector);
  
  const [focusedField, setFocusedField] = useState(null);
  const [openCanReceiveMessagesModal, setOpenCanReceiveMessagesModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [openEditApplicantModal, setOpenEditApplicantModal] = useState(false);
  const [openEditUserInfoModal, setOpenEditUserInfoModal] = useState(false);
  const [openEditUserAddressModal, setOpenEditUserAddressModal] = useState(false);
  const [openEditApplicantPlacementModal, setOpenEditApplicantPlacementModal] = useState(false);
  const [openEditApplicantScreeningModal, setOpenEditApplicantScreeningModal] = useState(false);
  const [openEditApplicantContactsModal, setOpenEditApplicantContactsModal] = useState(false);
  
  useEffect(() => {
    if (applicant?.application?.contract_id) {
      getQuestions();
    }
  }, [applicant]);
  
  const getQuestions = () => {
    dispatch(getApplicantsQuestions({ contractId: applicant?.application?.contract_id, applicantId: applicant.application.id}))
  };
  
  const tooltip = (onClick, title, height) => {
    return (
      <Tooltip title={title}>
        <IconButton
          edge="start"
          sx={{
            ml: 0,
            padding: 0,
            mb: height === '17px' ? 1 : 0,
            marginLeft: height === '16px' || height === '17px' ? '10px' : '0',
            '&:hover': { backgroundColor: 'transparent' },
          }}
          onClick={onClick}
        >
          <EditIcon
            sx={{
              color: '#fdfefe',
              height: height,
              '&:hover': { color: '#00A5CF' },
            }}
          />
        </IconButton>
      </Tooltip>
    );
  };
  
  const qpplicantInfoItem = (label, value, key) => (
    <li key={key || undefined} className="applicantCard__item">
      <div className="applicantCard__item-content">
        <b className="applicantCard__item-name">{label}</b>
        <p className="applicantCard__item-value">{value}</p>
      </div>
    </li>
  );
  
  return (
    <>
      <h1 className="content-header">Personal Information</h1>
      <div className="applicantCards__container">
        <section className="applicantCards">
          <div className="applicantCards__block">
            <ApplicantCard
              title="General"
              id="general"
              horizontal={false}
              edit={tooltip(() => setOpenEditUserInfoModal(true), 'Edit user data', '20px')}
            >
              <>
                {qpplicantInfoItem(
                  'First Name',
                  applicant.first_name ? applicant.first_name : 'Requested',
                )}
                {qpplicantInfoItem(
                  'Middle Initial',
                  applicant.middle_name ? applicant.middle_name : 'Requested',
                )}
                {qpplicantInfoItem(
                  'Last Name',
                  applicant.last_name ? applicant.last_name : 'Requested',
                )}
                {qpplicantInfoItem(
                  'Date of Birth',
                  applicant.dob ? formattedDOB : 'Requested',
                )}
                {qpplicantInfoItem(
                  'Email Address',
                  applicant.email ? applicant.email : 'Requested',
                )}
                {qpplicantInfoItem(
                  'Phone Number',
                  applicant.phone
                    ? <>
                      {formatPhoneNumber(applicant.phone)}
                    </>
                    : 'Requested',
                )}
                {qpplicantInfoItem(
                  'Text Opt-In',
                  <>
                    {applicant.can_receive_messages ? 'Yes' : 'No'}
                    <Tooltip title={applicant.can_receive_messages ? 'Allowed' : 'Not Allowed'}>
                      <IconButton
                        sx={{
                          padding: 0,
                          ml: 1,
                          mb: 0.5,
                          '&:hover': { backgroundColor: 'transparent', cursor: 'inherit' },
                        }}
                      >
                        <img
                          src={applicant.can_receive_messages ? allowedMessages : notAllowedMessages}
                          className="profile-icon--messages"
                          alt={applicant.can_receive_messages ? 'Allowed' : 'Not Allowed'}
                        />
                      </IconButton>
                    </Tooltip>
                    {view === 'applicant' && portalType === 'applicant' &&
                      tooltip(
                        () => {
                          setOpenCanReceiveMessagesModal(true);
                        },
                        'Edit Text Opt-In',
                        '17px',
                      )}
                  </>,
                )}
                {qpplicantInfoItem(
                  'Password',
                  '*****',
                )}
                {portalType === 'applicant' && (
                  <>
                    {qpplicantInfoItem(
                      'How did you hear about us?',
                      applicant.application.referral_type
                        ? referralTypes[applicant.application.referral_type] || applicant.application.referral_type
                        : 'Requested',
                    )}
                    {(applicant.application.referral_type === 'referral' || applicant.application.referral_type === 'other') && (
                      qpplicantInfoItem(
                        'Please elaborate...',
                        applicant.application.referral_input
                          ? applicant.application.referral_input
                          : 'Requested',
                      )
                    )}
                  </>
                )}
              </>
            </ApplicantCard>
            <ApplicantCard
              title="Home Address"
              id="address"
              horizontal={false}
              edit={tooltip(() => setOpenEditUserAddressModal(true), 'Edit home address', '20px')}
            >
              <>
                {qpplicantInfoItem(
                  'Address',
                  applicant.address ? applicant.address : 'Requested',
                )}
                {qpplicantInfoItem(
                  'City',
                  applicant.city ? applicant.city : 'Requested',
                )}
                {qpplicantInfoItem(
                  'State',
                  applicant.state ? applicant.state : 'Requested',
                )}
                {qpplicantInfoItem(
                  'ZIP Code',
                  applicant.zip ? applicant.zip : 'Requested',
                )}
              </>
            </ApplicantCard>
            {portalType === 'applicant' && (
              <ApplicantCard
                title="Emergency Contacts"
                id="emergency"
                horizontal={true}
                edit={tooltip(
                  () => {
                    setOpenEditApplicantContactsModal(true);
                  },
                  'Edit emergency contracts',
                  '20px',
                )}
              >
                <>
                  <div className="applicantCard__item--horizontal">
                    <li className="applicantCard__item">
                      <div className="applicantCard__item-content">
                        <b style={{ width: '100%' }} className="applicantCard__item-name">Primary Contact</b>
                      </div>
                    </li>
                    {qpplicantInfoItem(
                      'Name',
                      applicant.application?.ec_primary_name ? applicant.application?.ec_primary_name : 'Requested',
                    )}
                    {qpplicantInfoItem(
                      'Relationship',
                      applicant.application?.ec_primary_relation ? applicant.application?.ec_primary_relation : 'Requested',
                    )}
                    {qpplicantInfoItem(
                      'Phone Number',
                      applicant.application?.ec_primary_phone
                        ? formatPhoneNumber(applicant.application.ec_primary_phone)
                        : 'Requested',
                    )}
                  </div>
                  <div className="applicantCard__item--horizontal">
                    <li className="applicantCard__item">
                      <div className="applicantCard__item-content">
                        <b style={{ width: '100%' }} className="applicantCard__item-name">Secondary Contact</b>
                      </div>
                    </li>
                    {qpplicantInfoItem(
                      'Name',
                      applicant.application?.ec_secondary_name ? applicant.application?.ec_secondary_name : 'Requested',
                    )}
                    {qpplicantInfoItem(
                      'Relationship',
                      applicant.application?.ec_secondary_relation ? applicant.application?.ec_secondary_relation : 'Requested',
                    )}
                    {qpplicantInfoItem(
                      'Phone Number',
                      applicant.application?.ec_secondary_phone
                        ? formatPhoneNumber(applicant.application.ec_secondary_phone)
                        : 'Requested',
                    )}
                  </div>
                </>
              </ApplicantCard>
            )}
          </div>
          <div className="applicantCards__block">
            {view === 'admin' && portalType === 'applicant' && (
              <ApplicantCard
                title="Placement"
                id="placement"
                horizontal={false}
                edit={null}
              >
                <>
                  {qpplicantInfoItem(
                    'Contract',
                    <>
                      {applicantContract?.title ? applicantContract?.title : 'None'}
                      {tooltip(
                        () => {
                          setFocusedField('contract_id');
                          setOpenEditApplicantModal(true);
                        },
                        'Edit contract',
                        '16px',
                      )}
                    </>,
                  )}
                  {qpplicantInfoItem(
                    'Division',
                    <>
                      {applicantDivision?.title ? applicantDivision?.title : 'None'}
                      {tooltip(
                        () => {
                          setFocusedField('region_id');
                          setOpenEditApplicantModal(true);
                        },
                        'Edit division',
                        '16px',
                      )}
                    </>,
                  )}
                  {qpplicantInfoItem(
                    'Subdivision',
                    <>
                      {applicantSubdivision?.title ? applicantSubdivision?.title : 'None'}
                      {tooltip(
                        () => {
                          setFocusedField('site_id');
                          setOpenEditApplicantModal(true);
                        },
                        'Edit subdivision',
                        '16px',
                      )}
                    </>,
                  )}
                  {qpplicantInfoItem(
                    'Company',
                    <>
                      {applicant?.company ? applicant?.company : 'None'}
                      {tooltip(
                        () => {
                          setFocusedField('company');
                          setOpenEditUserModal(true);
                        },
                        'Edit company',
                        '16px',
                      )}
                    </>,
                  )}
                </>
              </ApplicantCard>
            )}
            {portalType === 'applicant' && initialQuestions.length > 0 && !loading && (
              <ApplicantCard
                title="Screening"
                id="screening"
                horizontal={false}
                edit={
                  view === 'admin' &&
                  portalType === 'applicant' &&
                  initialQuestions
                    .filter(item => item.application_question !== null).length > 0 && (
                    tooltip(
                      () => setOpenEditApplicantScreeningModal(true),
                      'Edit screening data',
                      '20px')
                  )}
              >
                <>
                  {initialQuestions.length > 0 && (
                    initialQuestions.map((item) => (
                      qpplicantInfoItem(
                        item.question,
                        <>
                          {item.answer === 'true' && 'Yes'}
                          {item.answer === 'false' && 'No'}
                          {item.answer !== 'true' && item.answer !== 'false' && item.answer}
                        </>,
                        item.id,
                      )),
                    ))}
                </>
              </ApplicantCard>
            )}
          </div>
        </section>
      </div>
      {
        openEditUserModal && focusedField &&
        <EditUserDetail
          applicant={applicant}
          getApplicant={getApplicant}
          handleClose={() => setOpenEditUserModal(false)}
          open={openEditUserModal}
          setOpenToast={setOpenToast}
        />
      }
      {
        openEditApplicantModal && focusedField &&
        <EditApplicantDetail
          applicant={applicant}
          field={focusedField}
          getApplicant={getApplicant}
          handleClose={() => setOpenEditApplicantModal(false)}
          open={openEditApplicantModal}
          setOpenToast={setOpenToast}
        />
      }
      {
        openEditUserInfoModal &&
        <EditUserInfo
          applicant={applicant}
          getApplicant={getApplicant}
          handleClose={() => setOpenEditUserInfoModal(false)}
          open={openEditUserInfoModal}
          setOpenToast={setOpenToast}
        />
      }
      {
        openCanReceiveMessagesModal &&
        <EditUserTextOptIn
          applicant={applicant}
          getApplicant={getApplicant}
          handleClose={() => setOpenCanReceiveMessagesModal(false)}
          open={openCanReceiveMessagesModal}
          setOpenToast={setOpenToast}
          view={view}
        />
      }
      {
        openEditUserAddressModal &&
        <EditUserAddress
          applicant={applicant}
          getApplicant={getApplicant}
          handleClose={() => setOpenEditUserAddressModal(false)}
          open={openEditUserAddressModal}
          setOpenToast={setOpenToast}
        />
      }
      {
        openEditApplicantPlacementModal &&
        <EditApplicantPlacement
          applicant={applicant}
          getApplicant={getApplicant}
          handleClose={() => setOpenEditApplicantPlacementModal(false)}
          open={openEditApplicantPlacementModal}
          setOpenToast={setOpenToast}
        />
      }
      {
        openEditApplicantScreeningModal &&
        <EditApplicantScreening
          applicant={applicant}
          getApplicant={getApplicant}
          handleClose={() => setOpenEditApplicantScreeningModal(false)}
          open={openEditApplicantScreeningModal}
          setOpenToast={setOpenToast}
          initialQuestions={initialQuestions}
        />
      }
      {
        openEditApplicantContactsModal &&
        <EditApplicantContacts
          applicant={applicant}
          getApplicant={getApplicant}
          handleClose={() => setOpenEditApplicantContactsModal(false)}
          open={openEditApplicantContactsModal}
          setOpenToast={setOpenToast}
        />
      }
    </>
  );
};
