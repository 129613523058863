export const currentTabSelector = (state) => state.staffing.currentTab;
export const recruiterFilterIdSelector = (state) => state.staffing.recruiterFilterId;
export const companyFilterSelector = (state) => state.staffing.companyFilter;
export const siteFilterIdSelector = (state) => state.staffing.siteFilterId;
export const regionFilterIdSelector = (state) => state.staffing.regionFilterId;
export const contractFilterIdSelector = (state) => state.staffing.contractFilterId;
export const textAlertFilterSelector = (state) => state.staffing.textAlertFilter;
export const psqAlertFilterSelector = (state) => state.staffing.psqAlertFilter;
export const currentSortOptionSelector = (state) => state.staffing.currentSortOption;
export const currentSortOrderSelector = (state) => state.staffing.currentSortOrder;
export const selectedTagsSelector = (state) => state.staffing.selectedTags;
export const selectedGroupsSelector = (state) => state.staffing.selectedGroups;
export const searchInputSelector = (state) => state.staffing.searchInput;
export const searchFilterSelector = (state) => state.staffing.searchFilter;
export const currentFilterViewSelector = (state) => state.staffing.currentFilterView;
export const filteredTagsSelector = (state) => state.staffing.filteredTags;
export const currentPageSelector = (state) => state.staffing.currentPage;
export const isSearchedApplicantsSelector = (state) => state.staffing.isSearchedApplicants;
