export const formatPhoneNumber = (phoneNumber) => {
  let cleanedPhone = ('' + phoneNumber).replace(/\D/g, '');
  let match = cleanedPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  };
  return phoneNumber;
};

export const formatUTCDateTime = (dateTime) => {
  const originalDateTime = new Date(dateTime);
  const utcYear = originalDateTime.getFullYear();
  const utcMonth = originalDateTime.getMonth();
  const utcDate = originalDateTime.getDate();
  const utcHour = originalDateTime.getHours();
  const utcMinute = originalDateTime.getMinutes();
  const UTCDate = new Date(Date.UTC(utcYear, utcMonth, utcDate, utcHour, utcMinute));
  return UTCDate.toLocaleString();
};

export const formatUTCDate = (date) => {
  const originalDate = new Date(date);
  const utcYear = originalDate.getFullYear();
  const utcMonth = originalDate.getMonth();
  const utcDate = originalDate.getDate();
  const UTCDate = new Date(Date.UTC(utcYear, utcMonth, utcDate));
  return UTCDate.toLocaleDateString();
};

export function formatDateOnly(date) {
  return new Date(date).toLocaleDateString(); // returns the date string in localized format
}

/**
 * Returns input number into a string
 * that has commas for thousands, millions,
 * etc. 
 */
export function numberWithCommas(number) {
  if (isNaN(number)) {
    return 'N/A';
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}