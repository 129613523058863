import React from "react";
import { Divider, IconButton, Typography } from "@mui/material";
import fileIcon from "assets/uploaded_file.svg";
import { fileToUploadType, uploadTypeToFile } from "shared/vendor-data";
import UploadFile from "./upload-file";
import VendorSpreadSheet from "./vendor-spreadsheet";

import API from "API";

export default function ViewVendorFiles(props) {
  const { vendorFiles, vendor, view, setView, retrieveFiles } = props;
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const retrievePdf = (file) => {
    window.open(
      `${API.endpointURL}/api/cloud_location?location=${file.file_name}`
    );
  };

  const buildAllFilesList = () => {
    let filesList = [];
    vendorFiles.forEach((file, index) => {
      filesList.push(
        <div
          key={index}
          onClick={() => retrievePdf(file)}
          style={{
            marginBottom: "21px",
            marginRight: "21px",
            textAlign: "center",
            width: "73px",
          }}
        >
          <IconButton sx={{ "&:hover": { backgroundColor: "transparent" } }}>
            <img
              src={fileIcon}
              width="57px"
              height="auto"
              alt="open-file-button"
            />
          </IconButton>
          <Typography
            key={index}
            sx={{
              color: "#EFEFEF",
              fontFamily: "Korolev Medium",
              fontSize: "0.85rem",
              margin: "0 auto",
            }}
          >
            {file.display_name
              ? file.display_name
              : `${uploadTypeToFile[file.type]} v${file.version}`}
          </Typography>
        </div>
      );
    });

    return <React.Fragment>{filesList}</React.Fragment>;
  };

  const buildTypeFilesList = () => {
    let filesList = [];
    let renderFiles = [];
    const fileType = fileToUploadType[view];

    vendorFiles.forEach((file) => {
      if (file.type === fileType) {
        filesList.push(file);
      }
    });

    filesList.forEach((file, index) => {
      renderFiles.push(
        <div
          key={index}
          onClick={() => retrievePdf(file)}
          sx={{
            marginBottom: "16px",
            marginRight: "16px",
          }}
        >
          <IconButton sx={{ "&:hover": { backgroundColor: "transparent" } }}>
            <img
              src={fileIcon}
              width="57px"
              height="auto"
              alt="approve-button"
            />
          </IconButton>
          <Typography
            key={index}
            sx={{
              color: "#EFEFEF",
              fontFamily: "Korolev Medium",
              fontSize: "0.85rem",
              margin: "0 auto",
              maxWidth: "100px",
              textAlign: "center",
            }}
          >
            {file.display_name
              ? file.display_name
              : `${uploadTypeToFile[file.type]} v${file.version}`}
          </Typography>
        </div>
      );
    });

    return <React.Fragment>{renderFiles}</React.Fragment>;
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "33px 45px 27px",
        position: "relative",
        width: "70%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: "MagistralCondW08-Medium",
          fontSize: "25px",
        }}
      >
        {view !== "All" && (
          <button
            onClick={() => setView("All")}
            style={{
              cursor: "pointer",
              background: "transparent",
              border: 0,
              color: "white",
              marginRight: "22px",
            }}
          >
            <i className="fa-solid fa-xl fa-angle-left" />
          </button>
        )}
        {view === "All" ? "View All Files" : view}
      </div>
      <Divider
        sx={{ borderColor: "#8b8b8b", marginBottom: "40px", marginTop: "25px" }}
      />
      <div
        className="TEST"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          maxHeight: "80%",
          width: "100%",
        }}
      >
        {vendorFiles && view === "All" && buildAllFilesList()}
        {vendorFiles &&
          view !== "All" &&
          view !== "Upload File" &&
          buildTypeFilesList()}
        {view === "Upload File" && (
          <UploadFile vendorId={vendor.id} retrieveFiles={retrieveFiles} />
        )}
      </div>
      {view === "Verification" ? (
        <VendorSpreadSheet
          files={vendorFiles}
          vendorId={vendor.id}
          vendor={vendor}
          retrieveFiles={retrieveFiles}
        />
      ) : (
        ""
      )}
      {view !== "Upload File" && view !== "Verification" && (
        <div
          style={{
            position: "absolute",
            bottom: "27px",
            right: "45px",
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <button
            className="submit-btn"
            style={{ marginRight: "14px" }}
            onClick={() => setView("All")}
          >
            View All
          </button>

          {loggedInUser.role === "accounting" ||
          loggedInUser.role === "admin" ? (
            <button
              className="submit-btn"
              style={{ marginRight: "14px" }}
              onClick={() => setView("Verification")}
            >
              Verification
            </button>
          ) : (
            ""
          )}
          <button className="submit-btn" onClick={() => setView("Upload File")}>
            Upload File
          </button>
        </div>
      )}
    </div>
  );
}
