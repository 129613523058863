import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { TextField, Select, MenuItem } from '@mui/material';

import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { projectFileSelector, projectFilesSelector } from 'store/projects/selectors';
import { editProjectFile, getProjectsFileTypes } from 'store/projects/thunk';

export const ProjectsEditFileModal = ({
  fileId,
  type,
  name,
  retrieveFiles,
  close,
  refreshFileFolders,
  setMessage,
  setOpenToast,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { editProjectFileLoading, editProjectFileError } = useAppSelector(projectFileSelector);
  const { getProjectFileTypesLoading, getProjectFileTypesError, projectFileTypes } = useAppSelector(projectFilesSelector);
 
  const [fileDisplayName, setFileDisplayName] = useState(name || '');
  const [fileType, setFileType] = useState(type);
  const [dropboxUploadKey, setDropboxUploadKey] = useState(Date.now());
  
  useEffect(() => {
    dispatch(getProjectsFileTypes({ projectId: id }));
  }, []);

  const editFile = () => {
    const body = {};
    if (fileDisplayName !== name) {
      body.display_name = fileDisplayName;
    }
    if (fileType !== type) {
      body.type_id = fileType;
    }
    
    dispatch(editProjectFile({ fileId: fileId, body }))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          setDropboxUploadKey(Date.now());
          retrieveFiles();
          refreshFileFolders();
          close();
          setOpenToast(true);
          setMessage('File successfully updated!');
        }
      })
  };

  return (
    <div
      style={{
        maxHeight: '100%',
        overflow: 'auto',
        width: '100%',
      }}
    >
      {getProjectFileTypesLoading ? (
        <div className="contracts-loading-spinner" style={{ height: '265px' }}>
          <ProgressSpinnerDarkNavy/>
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left',
            }}
          >
            <div className="contract-form-set-full">
              <div className="form-row-label">Document Name</div>
              <TextField
                value={fileDisplayName}
                onChange={(e) => setFileDisplayName(e.target.value)}
                fullWidth
                sx={{
                  backgroundColor: '#fff',
                  border: '1px solid #a5af96',
                  borderRadius: '8px',
                  padding: '6px 10px 5px',
                  marginBottom: 0,
                }}
                placeholder="Enter Document Type..."
                key={`file-name-${dropboxUploadKey}`}
                multiline={true}
                rows={1}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="contract-form-set-full">
              <div className="form-row-label">Document Type</div>
              <Select
                key={`file-type-${dropboxUploadKey}`}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fileType}
                onChange={(e) => setFileType(e.target.value)}
                sx={{
                  backgroundColor: '#fff',
                  border: 0,
                  borderRadius: '8px',
                  textAlign: 'left',
                  width: '100%',
                  '& .MuiSelect-outlined.MuiOutlinedInput-input': {
                    paddingTop: '10.8px',
                    paddingBottom: '11px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #a5af96',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #a5af96',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#a5af96',
                  },
                }}
                displayEmpty
              >
                {projectFileTypes.length > 0 ? projectFileTypes?.map((file) => (
                  <MenuItem key={file.id} value={file.id}>
                    {file.type_name
                      .split('_')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')}
                  </MenuItem>
                )) : (
                  <MenuItem key="select-option" value="">Select Document Type...</MenuItem>
                )}
              </Select>

            </div>
          </div>
          
          <div
            style={{ margin: 0 }}
            className="contractsTags__tags__modal-buttonContainer"
          >
            {editProjectFileLoading && (
              <div
                className="contractsTags__tags__modal-loading"
                style={{ top: '-35px', right: '140px' }}
              >
                <ProgressSpinnerDarkNavy/>
              </div>
            )}
            {editProjectFileError.length > 0 && (
              <p className="contractsTags__tags__modal-error--add">{editProjectFileError}</p>
            )}
            {getProjectFileTypesError.length > 0 && (
              <p className="contractsTags__tags__modal-error--add">{getProjectFileTypesError}</p>
            )}
            <button
              className="connection__modal-button"
              onClick={editFile}
              disabled={editProjectFileLoading || getProjectFileTypesError.length > 0}
            >
              Save
            </button>
          </div>
        </>
      )}
    </div>
  );
};
