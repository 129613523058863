import React, { useEffect, useState } from 'react';
import Tag from "./tag";
import classNames from 'classnames';

export default function TagSpreadSheet(props) {
  const {
    tags,
    selectedTag,
    setSelectedTag,
  } = props;
  
  const [sortOption, setSortOption] = useState('group');
  const [sortOrder, setSortOrder] = useState('ascending');
  const [sortedTags, setSortedTags] = useState([]);
  
  const toggleSort = (option) => {
    if (option === sortOption) {
      setSortOrder(sortOrder === 'ascending' ? 'descending' : 'ascending');
    } else {
      setSortOption(option);
      setSortOrder('ascending');
    }
  };
  
  useEffect(() => {
    if (Array.isArray(tags)) {
      const sortTags = [...tags].sort((a, b) => {
        if (sortOption === 'name') {
          return sortOrder === 'ascending' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        } else if (sortOption === 'group') {
          return sortOrder === 'ascending' ? a.group?.title.localeCompare(b.group?.title) : b.group?.title.localeCompare(a.group?.title);
        } else if (sortOption === 'type') {
          return sortOrder === 'ascending' ? a.type?.title.localeCompare(b.type?.title) : b.type?.title.localeCompare(a.type?.title);
        }
        return 0;
      });
      
      setSortedTags(sortTags);
    } else {
      setSortedTags([]);
    }
  }, [sortOption, sortOrder, tags]);

  return (
    <table className="spreadsheet">
      <thead className="tableLayout__th-div tableLayout__th-div--darkGreen">
        <tr className="ss-headers">
          <td className={`first-td`}>
            Group
            <i
              className={classNames( '', {
                'fa-solid fa-arrow-up up arrow':
                  sortOption === 'group' &&
                  sortOrder === 'ascending',
                'fa-solid fa-arrow-down down arrow':
                  sortOption !== 'group' ||
                  sortOrder !== 'ascending',
              })}
              onClick={() => toggleSort('group')}
            />
          </td>
          <td>
            Name
            <i
              className={classNames( '', {
                'fa-solid fa-arrow-up up arrow':
                  sortOption === 'name' &&
                  sortOrder === 'ascending',
                'fa-solid fa-arrow-down down arrow':
                  sortOption !== 'name' ||
                  sortOrder !== 'ascending',
              })}
              onClick={() => toggleSort('name')}
            />
          </td>
          <td className="last-td">
            Type
            <i
              className={classNames( '', {
                'fa-solid fa-arrow-up up arrow':
                  sortOption === 'type' &&
                  sortOrder === 'ascending',
                'fa-solid fa-arrow-down down arrow':
                  sortOption !== 'type' ||
                  sortOrder !== 'ascending',
              })}
              onClick={() => toggleSort('type')}
            />
          </td>
          <td className="last-td">Description</td>
        </tr>
      </thead>
      <tbody>
      {
        sortedTags?.map((tag) => (
          <Tag
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            tag={tag}
            key={tag.id}
          />
        ))
      }
      </tbody>
    </table>
  );
}
