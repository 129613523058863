export const tagsSelector = (state) => state.tags.tags;
export const selectedTagSelector = (state) => state.tags.selectedTag;
export const tagSelector = (state) => state.tags.tag;
export const tagLoadingSelector = (state) => state.tags.tagLoading;
export const deleteTagSelector = (state) => state.tags.deleteTag;
export const createTagSelector = (state) => state.tags.createTag;
export const editTagSelector = (state) => state.tags.editTag;
export const selectedTypeSelector = (state) => state.tags.selectedType;
export const selectedGroupSelector = (state) => state.tags.selectedGroup;
export const tagsLoadingSelector = (state) => state.tags.isLoading;
export const allTagsLoadingSelector = (state) => state.tags.tagsLoading;
export const tagTypesSelector = (state) => state.tags.types;
export const tagGroupsSelector = (state) => state.tags.groups;
export const createTagTypeStatusSelector = (state) => state.tags.createTypeStatus;
export const updateTagTypeStatusSelector = (state) => state.tags.updateTypeStatus;
export const deleteTypeStatusSelector = (state) => state.tags.deleteTypeStatus;
export const deleteTypeErrorSelector = (state) => state.tags.deleteTypeError;
export const deleteLoadingSelector = (state) => state.tags.deleteLoading;
export const createTagGroupStatusSelector = (state) => state.tags.createGroupStatus;
export const updateTagGroupStatusSelector = (state) => state.tags.updateGroupStatus;
export const deleteGroupStatusSelector = (state) => state.tags.deleteGroupStatus;
export const deleteGroupErrorSelector = (state) => state.tags.deleteGroupError;

export const applicantTagsSelector = (state) => state.tags.applicantTags;