import React from "react";
import logo from "../../assets/logo.png";
import "../../css/application.scss";
import Link from '@mui/material/Link';

export default function TermsOfService() {
  return (
    <div
      className="background"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <img alt='logo' className="rd-logo" src={logo} />
      <div className="policy-container">
        <div className="policy-header">SMS Terms & Conditions</div>
        <div className="policy-body">Most Recent Update: January 9, 2024</div>
        <br />
        <div className="policy-body"></div>
        <br />
        <div className="policy-section-header">Program Description</div>
        <div className="policy-body">
          When opted-in, you will receive text messages to your mobile phone number related to your job application(s), which may include real-time texts to ask and answer questions about your application or other opportunities, status updates and interview confirmations. 
        </div>
        <br />
        <div className="policy-section-header">Message Frequency</div>
        <div className="policy-body">
          The number of Responsive Deployment text messages that you receive will vary depending on your current job application status and any outstanding information required to complete your application.
        </div>
        <br />
        <div className="policy-section-header">How to Opt-Out</div>
        <div className="policy-body">
          You can cancel the SMS service at any time by replying back with STOP. After you send the SMS message STOP to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us. If you want to resubscribe to texts from us, just visit your Applicant Portal at {' '}
          <Link
            sx={{
              color: '#a5af96',
              transition: 'all, 0.3s',
              ':hover': {
                textDecoration: 'underline',
              },
              ':not(.ql-editor a)': {
                display: 'inline'
              }
            }}
            href="https://responsivemanagement.org"
            target="_blank"
            rel="noreferrer noopener"
          ><b>https://responsivemanagement.org</b></Link>
          {' '} and update your Text Opt-In settings.
        </div>
        <br />
        <div className="policy-section-header">Help</div>
        <div className="policy-body">
          If you are experiencing issues with the SMS service you can reply with the keyword HELP for more assistance, or you can get help directly at {' '}
          <Link
            sx={{
              color: '#a5af96',
              transition: 'all, 0.3s',
              ':hover': {
                textDecoration: 'underline',
              },
              ':not(.ql-editor a)': {
                display: 'inline'
              }
            }}
            href="mailto:rdmstaffing@responsivedeployment.com"
            target="_blank"
            rel="noreferrer noopener"
          ><b>rdmstaffing@responsivedeployment.com</b></Link>.
        </div>
        <br />
        <div className="policy-section-header">Cost</div>
        <div className="policy-body">
          Message and data rates may apply for any messages sent to you from us and to us from you. If you have any questions about your text plan or data plan, please contact your wireless provider.
        </div>
        <br />
        <div className="policy-section-header">Interruption</div>
        <div className="policy-body">
          Delivery of information and content to a mobile device may fail due to a variety of circumstances or conditions. You understand and acknowledge that network services, including but not limited to mobile network services, are outside of our control, and Responsive Deployment is not responsible or liable for issues arising from them.
        </div>
        <br />
        <div className="policy-section-header">Privacy</div>
        <div className="policy-body">
          If you have any questions regarding privacy, please read our {' '}
          <Link
            sx={{
              color: '#a5af96',
              transition: 'all, 0.3s',
              ':hover': {
                textDecoration: 'underline',
              },
              ':not(.ql-editor a)': {
                display: 'inline'
              }
            }}
            href="https://responsivemanagement.org/privacy-policy"
            target="_blank"
            rel="noreferrer noopener"
          ><b>Privacy Policy</b></Link>.
        </div>
      </div>
      <div style={{ marginTop: 'auto', textAlign: 'center', width: '100%' }}>
        <div className="copyright">©2023 Responsive Deployment</div>
      </div>
    </div>
  );
};
