import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'components/generic/table';
import { SalesExpandedTable } from 'components/sales/sales-expanded-table';
import { HighlightNavyAutoStyledButton } from 'components/buttons/button-styles';
import NewBaseModal from 'components/modal/new-base-modal';
import { EditSaleModal } from 'components/modal/edit-sales-data';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { formatUTCDate } from 'shared/formatting';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  salesCurrentPageSelector,
  salesLoadingSelector,
  salesSelector,
  salesTotalPagesSelector,
} from 'store/sales/selectors';
import { Pagination, Stack } from '@mui/material';
import { setCurrentPage } from 'store/sales/slice';

export const SalesBusinessDevelopmentTable = () => {
  let navigate = useNavigate();
  const sales = useAppSelector(salesSelector);
  const loading = useAppSelector(salesLoadingSelector);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [saleToEdit, setSaleToEdit] = useState(null);
  const currentPage = useAppSelector(salesCurrentPageSelector);
  const totalPages = useAppSelector(salesTotalPagesSelector);
  const dispatch = useAppDispatch();

  const setColumns = () => {
    return [
      'Opportunity Name',
      'Suspense Date',
      'Date Modified',
      'Agency',
      'Business Line',
      'Stage',
      'Sam.gov #',
      'GovWin #',
      'Solicitation #',
      'Bid Type',
      'Total Contract Value',
      'Incumbent',
      'Place Of Performance',
      'Base Period Of Performance',
      'Option periods',
      'Prime Bidder',
      'Anticipated RFP Date',
      'Submission Deadline',
      'Anticipated Award',
      'Anticipated Contract Start',
    ];
  };

  const openEditModalHandler = (sale) => {
    setOpenEditModal(true);
    if (sale) {
      setSaleToEdit(sale);
    }
  };

  const buildSalesRows = () => {
    let salesRows = [];
    if (sales) {
      sales?.forEach((sale) => {
        salesRows.push(
          <SalesExpandedTable
            firstRow={(() => {
              return [
                sale.opportunity_name,
                formatUTCDate(
                  sale.suspense_date,
                ),
                formatUTCDate(sale.updated_at),
                sale.agency,
                sale.division ? sale.division.name : 'N/A',
                sale.stage,
                sale.sam_gov_number,
                sale.gov_win_number,
                sale.solicitation_number,
                sale.bid_type ? sale.bid_type.name : 'N/A',
                sale.total_contract_value,
                sale.incumbent,
                sale.place_of_performance,
                sale.base_period_of_performance,
                sale.option_periods,
                sale.prime_bidder,
                formatUTCDate(sale.anticipated_rfp_date),
                formatUTCDate(sale.submission_deadline),
                formatUTCDate(sale.anticipated_award),
                formatUTCDate(sale.anticipated_contract_start),
              ];
            })()}
            key={sale.id}
          >
            <div className="sales__sale-expand">
              <div className="table-button">
                <HighlightNavyAutoStyledButton
                  sx={{
                    width: '120px',
                    minWidth: '120px',
                    height: '42.5px',
                    marginRight: 'calc(20% - 134px)',
                    padding: '8px 0',
                    alignSelf: 'center',
                  }}
                  onClick={() => {
                    openEditModalHandler(sale);
                  }}
                >
                  Edit Data
                </HighlightNavyAutoStyledButton>
              </div>
              <div className="table-button">
                <HighlightNavyAutoStyledButton
                  sx={{
                    width: '120px',
                    minWidth: '120px',
                    height: '42.5px',
                    marginRight: 'calc(33.33% - 134px)',
                    padding: '8px 0',
                    alignSelf: 'center',
                  }}
                  onClick={() => navigate(`/sales-portal/${sale.id}`)}
                >
                  Sale Portal
                </HighlightNavyAutoStyledButton>
              </div>
            </div>
          </SalesExpandedTable>,
        );
      });
    }
    return <>{salesRows}</>;
  };

  return (
    <>
      {/* Modal - edit and view data */}
      <NewBaseModal
        dialogContent={<EditSaleModal sale={saleToEdit}/>}
        onClose={() => setOpenEditModal(false)}
        open={openEditModal}
        title={'Edit Sale Data'}
        colorPalette="navy"
      />
      <div className="header-content">
        <h1 className="content-header">Business Development</h1>
      </div>
      <div
        className="content-container no-content-padding scrollable-table"
        style={{ height: 'calc(100vh - 234px)' }}
      >
        {loading ? (
          <div className="content-loading">
            <ProgressSpinnerNavy />
          </div>
        ) : (
          <Table columns={setColumns()}>
            {buildSalesRows()}
          </Table>
        )}
      </div>
      {!loading && sales.length > 0 && (
        <Stack
          sx={{
            alignItems: 'center',
            marginTop: 1.5,
            marginBottom: '-26px',
          }}
        >
          <Pagination
            count={+totalPages}
            page={+currentPage}
            onChange={(e, value) => dispatch(setCurrentPage(value))}
            color="primary"
            size="large"
          />
        </Stack>
      )}
    </>
  );
};
