import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTab: 'direct sales pipeline',
  salesStage: 'Lead',
  currentPage: 1,
  filterView: 'filters',
  searchType: 'opportunity_name',
  searchInput: '',
  isSearched: false,
  businessLines: [],
  salesReps: [],
  connections: [],
};

export const salesSelectedOptionsSlice = createSlice({
  name: 'salesSelectedOptions',
  initialState,
  reducers: {
    setCurrentSalesTab(state, { payload }) {
      state.currentTab = payload;
      state.currentPage = 1;
    },
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    setSalesStage(state, { payload }) {
      state.salesStage = payload;
    },
    setSalesFilterView(state, { payload }) {
      state.filterView = payload;
    },
    setSalesSearchType(state, { payload }) {
      state.searchType = payload;
    },
    setSalesSearchInput(state, { payload }) {
      state.searchInput = payload;
    },
    setIsSearchedSales(state, { payload }) {
      state.isSearched = payload;
    },
    setSelectedBusinessLines (state, { payload }) {
      if (state.businessLines.some((item) => item.id === payload.id)) {
        state.businessLines = state.businessLines.filter((item) => item.id !== payload.id);
      } else {
        state.businessLines = [...state.businessLines, payload];
      }
    },
    setSelectedSalesReps(state, { payload }) {
      if (state.salesReps.some((item) => item.id === payload.id)) {
        state.salesReps = state.salesReps.filter((item) => item.id !== payload.id);
      } else {
        state.salesReps = [...state.salesReps, payload];
      }
    },
    setSelectedConnections (state, { payload }) {
      if (state.connections.some((item) => item.id === payload.id)) {
        state.connections = state.connections.filter((item) => item.id !== payload.id);
      } else {
        state.connections = [...state.connections, payload];
      }
    },
  },
});

export const {
  setCurrentSalesTab,
  setCurrentPage,
  setSelectedBusinessLines,
  setSalesStage,
  setSalesFilterView,
  setSelectedSalesReps,
  setSelectedConnections,
  setSalesSearchType,
  setSalesSearchInput,
  setIsSearchedSales,
} = salesSelectedOptionsSlice.actions;

export default salesSelectedOptionsSlice.reducer;
