import React from 'react';
import "css/analytics.scss";
import "css/pages.scss";
import "css/metrics.scss";
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  salesAnalyticsLoadingSelector,
  salesAnalyticsPerStageSelector,
  salesRepsSelector,
  salesRepStageAnalyticsLoadingSelector,
  salesRepStageAnalyticsSelector,
  selectedSalesRepAnalyticsSelector,
} from 'store/sales/selectors';
import { setIsLoadedUserAnalytics, setSelectedAnalyticsRep } from 'store/sales/slice';
import { connectionsAnalyticsSelector } from 'store/connections/selectors';

export const SalesAnalytics = () => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(salesAnalyticsLoadingSelector);
  const saleAnalytics = useAppSelector(salesAnalyticsPerStageSelector);
  const { connectionsAnalytics } = useAppSelector(connectionsAnalyticsSelector);
  const salesReps = useAppSelector(salesRepsSelector);
  const selectedSalesRep = useAppSelector(selectedSalesRepAnalyticsSelector);
  const salesRepStageAnalytics = useAppSelector(salesRepStageAnalyticsSelector);
  const loadingAnalytic = useAppSelector(salesRepStageAnalyticsLoadingSelector);

  return (
    <>
      <h1 className="content-header">Sales Analytics</h1>
      <div className="metrics-content-container">
        {(loading || loadingAnalytic) ? (
          <div className="content-loading">
            <ProgressSpinnerNavy />
          </div>
        ) : (
          <>
            <div className="stats-group-container">
              <div className="stats-group-header">Sales per stage</div>
              <div className="contract-totals-row">
                <div className="stage-stat-container">
                  <div className="stage-stat-header">Capturing</div>
                  <div className="stage-stat-number">{saleAnalytics && saleAnalytics.Lead}</div>
                  <div className="stage-stat-unit">
                    {saleAnalytics && saleAnalytics.Lead === 1 ? "sale" : "sales"}
                  </div>
                </div>
                <div className="stage-stat-container">
                  <div className="stage-stat-header">In Work</div>
                  <div className="stage-stat-number">{saleAnalytics && saleAnalytics.Opportunity}</div>
                  <div className="stage-stat-unit">
                    {saleAnalytics && saleAnalytics.Opportunity === 1 ? "sale" : "sales"}
                  </div>
                </div>
                <div className="stage-stat-container">
                  <div className="stage-stat-header">Submitted</div>
                  <div className="stage-stat-number">{saleAnalytics && saleAnalytics.Contract}</div>
                  <div className="stage-stat-unit">
                    {saleAnalytics && saleAnalytics.Contract === 1 ? "sale" : "sales"}
                  </div>
                </div>
                <div className="stage-stat-container">
                  <div className="stage-stat-header">Awarded To</div>
                  <div className="stage-stat-number">{saleAnalytics && saleAnalytics.Award}</div>
                  <div className="stage-stat-unit">
                    {saleAnalytics && saleAnalytics.Award === 1 ? "sale" : "sales"}
                  </div>
                </div>
              </div>
            </div>
            <div className="stats-group-container">
              <div className="stats-group-header">Connections per type</div>
              <div className="contract-totals-row">
                <div className="stage-stat-container">
                  <div className="stage-stat-header">Personal</div>
                  <div className="stage-stat-number">{connectionsAnalytics && connectionsAnalytics.personal_count}</div>
                  <div className="stage-stat-unit">{connectionsAnalytics && connectionsAnalytics.personal_count === 1 ? "connection" : "connections"}</div>
                </div>
                <div className="stage-stat-container">
                  <div className="stage-stat-header">Company</div>
                  <div className="stage-stat-number">{connectionsAnalytics && connectionsAnalytics.company_count}</div>
                  <div className="stage-stat-unit">{connectionsAnalytics && connectionsAnalytics.company_count === 1 ? "connection" : "connections"}</div>
                </div>
              </div>
            </div>
            <div className="contract-form-section">
              <div className="contract-form-section-header header-row">
                Sales Representative Analytics
                {salesReps && (
                  <select
                    className="contract-form-input"
                    style={{ width: "35%" }}
                    placeholder="Select Sales Representative"
                    value={selectedSalesRep || ''}
                    onChange={(e) => {
                      dispatch(setSelectedAnalyticsRep(e.currentTarget.value));
                      dispatch(setIsLoadedUserAnalytics(false));
                    }}
                  >

                    {salesReps.map((salesRep) => {
                      return <option key={salesRep.id} value={salesRep.id}>{salesRep.first_name} {salesRep.last_name}</option>
                    })}
                  </select>
                )}
              </div>
              <div className="contract-form-section-content">
                <div className="recruiter-stats-container">
                  <div className="stats-group-container">
                    <div className="stats-group-header">Sales per stage</div>
                    <div className="contract-totals-row">
                      <div className="stage-stat-container">
                        <div className="stage-stat-header">Capturing</div>
                        <div className="stage-stat-number">{salesRepStageAnalytics && salesRepStageAnalytics.Lead}</div>
                        <div className="stage-stat-unit">{salesRepStageAnalytics && salesRepStageAnalytics.Lead === 1 ? "sale" : "sales"}</div>
                      </div>
                      <div className="stage-stat-container">
                        <div className="stage-stat-header">In Work</div>
                        <div className="stage-stat-number">{salesRepStageAnalytics && salesRepStageAnalytics.Opportunity}</div>
                        <div className="stage-stat-unit">{salesRepStageAnalytics && salesRepStageAnalytics.Opportunity === 1 ? "sale" : "sales"}</div>
                      </div>
                      <div className="stage-stat-container">
                        <div className="stage-stat-header">Submitted</div>
                        <div className="stage-stat-number">{salesRepStageAnalytics && salesRepStageAnalytics.Contract}</div>
                        <div className="stage-stat-unit">{salesRepStageAnalytics && salesRepStageAnalytics.Contract === 1 ? "sale" : "sales"}</div>
                      </div>
                      <div className="stage-stat-container">
                        <div className="stage-stat-header">Awarded To</div>
                        <div className="stage-stat-number">{salesRepStageAnalytics && salesRepStageAnalytics.Award}</div>
                        <div className="stage-stat-unit">{salesRepStageAnalytics && salesRepStageAnalytics.Award === 1 ? "sale" : "sales"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      </div>
    </>
  );
};