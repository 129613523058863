import React, { useEffect, useRef, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { openApplicantCardsSelector } from 'store/ui/selectors';
import { setOpenApplicantCards } from 'store/ui/slice';

export const ApplicantCard = ({ id, children, edit, title, horizontal }) => {
  const blockRef = useRef(null);
  const [blockHeight, setBlockHeight] = useState(0);

  const openCards = useAppSelector(openApplicantCardsSelector);
  const dispatch = useAppDispatch();
  
  const toggleCard = (id) => {
    if (openCards.includes(id)) {
      dispatch(setOpenApplicantCards(openCards.filter((cardId) => cardId !== id)));
    } else {
      dispatch(setOpenApplicantCards([...openCards, id]));
    }
  };
  
  useEffect(() => {
    if (blockRef.current) {
      if (openCards.includes(id)) {
        setBlockHeight(blockRef.current.scrollHeight);
      } else {
        setBlockHeight(60);
      }
    }
  }, [openCards, id]);

  
  return (
    <article
      ref={blockRef}
      id={id}
      style={{ maxHeight: openCards.includes(id) ? blockHeight + 'px' : '60px' }}
      className={blockHeight === 0 ? 'applicantCard--hidden' : 'applicantCard'}
    >
      <div className="applicantCard__header-container">
        <div className="applicantCard__header">
          <h2>{title}</h2>
          {edit && openCards.includes(id) && edit}
        </div>
        <IconButton
          edge="start"
          color="inherit"
          sx={{ color: '#fdfefe', padding: 0, width: '25px' }}
          onClick={() => toggleCard(id)}
        >
          <KeyboardArrowDownIcon
            sx={{
              transform: openCards.includes(id) ? 'rotate(180deg)' : '',
              transition: 'transform 0.3s ease-in-out',
            }}
          />
        </IconButton>
      </div>
      <div className='applicantCard__container'>
        <ul className={horizontal ? 'applicantCard__list--horizontal' : 'applicantCard__list'}>
          {children}
        </ul>
      </div>
    </article>
  );
};
