import React from "react";

import ImageIconEmail from "assets/email.png";
import ImageIconPhone from "assets/phone.png";
import ImageIconUser from "assets/executiveUser.png";
import { formatPhoneNumber } from "shared/formatting";

import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';

import { useAppSelector } from 'hooks/reduxHooks';
import { projectSelector } from 'store/projects/selectors';
import { notesSelector } from 'store/notes/selectors';

export const PortalProfilePanel = () => {
  const { project, getProjectLoading, getProjectError } = useAppSelector(projectSelector);
  const { blockLoading } = useAppSelector(notesSelector);
  
  if (getProjectLoading && !blockLoading) {
    return (
      <div style={{ height: '385px' }} className="sidebar-loading sidebar-loading-portal">
        <ProgressSpinnerNavy />
      </div>
    )
  }
  
  if (getProjectError.length > 0) {
    return (
      <div className="sidebar-loading sidebar-loading-portal">
        {getProjectError}
      </div>
    )
  }
  
  return (
    project && (
      <div className="user-info-container" style={{ padding: 0 }}>
        <div className="user-info-header">
          <div>
            <div className="user-info-name">
              {project?.project_name ? project.project_name : "No project name"}
            </div>
          </div>
        </div>
        
        {/* WHITE HORIZONAL LINE THAT SEPARATES TWO BLOCKS */}
        <div style={{ height: '1px', width: '100%', backgroundColor: 'rgba(239, 239, 239, 0.5019607843)', 'marginBottom': '13px' , 'marginRight' : '33px'}} />
        
        {/* PROJECT COORDINATOR */}
        <div className="row" style={{ marginBottom: 0 }}>
          <div className="section-data">
            PROJECT COORDINATOR
          </div>
        </div>
        <div className="row">
          <img className="icon" src={ImageIconUser} alt=""/>
          <div className="section-data">
            {project?.project_coordinator_data?.first_name
              ? `${project.project_coordinator_data.first_name} ${project?.project_coordinator_data?.last_name}`
              : "N/A"
            }
          </div>
        </div>
        
        <div className="row">
          <img className="icon" src={ImageIconPhone} alt=""/>
          <div className="section-data">
            {project?.project_coordinator_data?.phone ? formatPhoneNumber(project.project_coordinator_data.phone) : "N/A"}
          </div>
        </div>
        <div className="row">
          <img className="icon" src={ImageIconEmail} alt="" />
          <div
            className="section-data"
            style={{ maxWidth: 'calc(100% - 42px)', wordWrap: 'break-word' }}
          >
            {project?.project_coordinator_data?.email ? project.project_coordinator_data.email : "N/A"}
          </div>
        </div>
        
        {/* WHITE HORIZONAL LINE THAT SEPARATES TWO BLOCKS */}
        <div style={{ height: '1px', width: '100%', backgroundColor: 'rgba(239, 239, 239, 0.5019607843)', 'marginBottom': '13px' , 'marginRight' : '33px'}} />
        
        {/* PROJECT MANAGER */}
        <div className="row" style={{ marginBottom: 0 }}>
          <div className="section-data">
            PROJECT MANAGER
          </div>
        </div>
        <div className="row">
          <img className="icon" src={ImageIconUser} alt=""/>
          <div className="section-data">
            {project?.project_manager_data?.first_name
              ? `${project.project_manager_data.first_name} ${project?.project_manager_data?.last_name}`
              : "N/A"
            }
          </div>
        </div>
        
        <div className="row">
          <img className="icon" src={ImageIconPhone} alt=""/>
          <div className="section-data">
            {project?.project_manager_data?.phone ? formatPhoneNumber(project.project_manager_data.phone) : "N/A"}
          </div>
        </div>
        <div className="row">
          <img className="icon" src={ImageIconEmail} alt="" />
          <div
            className="section-data"
            style={{ maxWidth: 'calc(100% - 42px)', wordWrap: 'break-word' }}
          >
            {project?.project_manager_data?.email ? project.project_manager_data.email : "N/A"}
          </div>
        </div>
      </div>
    )
  )
}
