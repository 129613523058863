import "../../css/toast.scss";
import React from 'react';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent } from '@mui/material';


export default function Toast(props) {
  
  const { open, onClose, message} = props;

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      onClick={onClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    // Use <Fragment> instead of <div> to avoid 
    // creating additional block when this component is
    // beign rendered
    <>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={onClose}
      >
        <SnackbarContent
          action={action}
          message={message}
          sx={{
            backgroundColor: "#EFEFEF40",
            color: "#EFEFEF",
          }}
        />
      </Snackbar>
    </>
  );
}
