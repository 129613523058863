import React, { useState } from "react";
import API from "API";
import { fileToUploadType } from "shared/vendor-data";
import ProgressSpinner from "components/progress/progress-spinner";
import Toast from "components/toast/toast";

export default function UploadFile(props) {
  const { vendorId, retrieveFiles } = props;
  const token = JSON.parse(localStorage.getItem("token"));

  const [selectedFile, setSelectedFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [dropboxUploadKey, setDropboxUploadKey] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openToast, setOpenToast] = useState(false);

  const uploadFile = () => {
    if (!selectedFile || !fileType || !fileName) {
      setError("Please select a file and provide a file type and name.");
    } else {
      setLoading(true);
      const formData = new FormData();

      formData.append("vendor_id", vendorId);
      formData.append("file", selectedFile);
      formData.append("type", fileType);
      formData.append("display_name", fileName);

      fetch(`${API.endpointURL}/api/vendor_upload`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          Connection: "keep-alive",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setLoading(false);
          setOpenToast(true);
          setDropboxUploadKey(Date.now());
          setSelectedFile(null);
          setFileType(null);
          setFileName(null);
          setError(null);
          retrieveFiles();
        })
        .catch((error) => {
          setLoading(false);
          setError("Please select a file and try again.");
        });
    }
  };

  const generateOptions = () => {
    let options = [];
    for (const [key, value] of Object.entries(fileToUploadType)) {
      options.push(
        <option key={value} value={value}>
          {key}
        </option>
      );
    }
    return (
      <select
        key={`file-type-${dropboxUploadKey}`}
        className="vendor-input"
        value={fileType}
        onChange={(e) => setFileType(e.target.value)}
      >
        <option value="" disabled selected>
          Select the dropdown for options
        </option>
        {options}
      </select>
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <ProgressSpinner />
      ) : (
        <React.Fragment>
          <div className="row cell auto" style={{ marginTop: 0 }}>
            <div className="input-box">
              <div className="input-title">Document Name</div>
              <input
                type="text"
                key={`file-name-${dropboxUploadKey}`}
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                className="field"
                placeholder="Enter Document Name..."
              />
            </div>
          </div>
          <div
            className="row cell auto"
            style={{ marginTop: "19px", paddingLeft: 0 }}
          >
            <div className="input-box">
              <div className="input-title">Document Type</div>
              {generateOptions()}
            </div>
          </div>
          <div className="belles-file-upload" style={{ width: "100%" }}>
            <input
              type="file"
              name="file"
              key={`selected-file-${dropboxUploadKey}`}
              onChange={(event) => setSelectedFile(event.target.files[0])}
              style={{ marginBottom: "50px", marginTop: "19px" }}
            />
          </div>
          {error && (
            <div
              style={{
                color: "#B84A32",
                fontFamily: "Korolev Medium",
                fontSize: "14px",
                marginBottom: "16px",
                width: "100%",
              }}
            >
              {error}
            </div>
          )}
          <button className="submit-btn" onClick={() => uploadFile()}>
            Upload File
          </button>
        </React.Fragment>
      )}
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message="File successfully uploaded"
        />
      )}
    </React.Fragment>
  );
}
