import "../../../css/analytics.scss";
import "../../../css/pages.scss";
import "../../../css/metrics.scss";
import React, { useEffect, useState } from "react";
import { accountingDynamicsLabels } from "shared/accounting-data";
import AccountingGroupingView from "./accounting-grouping-view";

export default function AccountingSeriesView(props) {

  const {
    breakdown,
    lastUpdate,
    selectedSeries,
    setSelectedSeries
  } = props;

  const [view, setView] = useState("series");
  const [selectedGrouping, setSelectedGrouping] = useState(null);

  useEffect(() => {
    if (selectedGrouping) {
      setView("grouping");
    } else {
      setView("series");
    }
  }, [selectedGrouping]);

  const renderBreakdown = () => {
    let accountBreakdown = [];
    if (breakdown && breakdown["Groupings"]) {
      for (const [key, value] of Object.entries(breakdown["Groupings"])) {
        accountBreakdown.push(
          <div className="contract-form-section-content">
            <button
              className="stats-group-header"
              onClick={() => setSelectedGrouping(key)}
              style={{
                background: "transparent",
                border: 0,
                color: "#EFEFEF",
                fontFamily: "MagistralCondW08-Medium",
                fontSize: "23px",
              }}
            >
              {key}
              <i
                className="fa-solid fa-angle-right blue-arrow"
                style={{ marginLeft: "12px", color: "#00A5CF" }}
              />
            </button>
            <div className="stage-stat-container" style={{ width: "auto" }}>
              <div className="stage-stat-header">Net Change</div>
              <div className="stage-stat-number">
                ${value["netChange"] && value["netChange"].toLocaleString('en', { useGrouping:true })}
              </div>
            </div>
            <div className="stage-stat-container" style={{ width: "auto" }}>
              <div className="stage-stat-header">Total</div>
              <div className="stage-stat-number">
                ${value["total"] && value["total"].toLocaleString('en', { useGrouping:true })}
              </div>
            </div>
          </div>
        );
      }
    }
    return (
      <>{accountBreakdown}</>
    );
  };

  return (
    <>
      {view === "series" && (
        <>
          <div className="content-header content-header-flex">
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                className={`fa-solid fa-angle-left blue-arrow analytics-back-button`}
                onClick={() => setSelectedSeries(null)}
              />
              <h1>{accountingDynamicsLabels[selectedSeries]}</h1>
            </div>
            <div
              style={{
                fontFamily: "Korolev Medium",
                fontSize: "14px",
                textAlign: "right",
                textTransform: "none",
                width: "25%",
                color: '#efefef',
              }}
            >
              Last updated on {new Date(lastUpdate).toLocaleString()}
            </div>
          </div>
          <div className="metrics-content-container">
            <div className="contract-form-section">
              <div className="contract-form-section-content">
                <div className="stage-stat-container" style={{ width: "auto" }}>
                  <div className="stage-stat-header">Net Change</div>
                  <div className="stage-stat-number">
                    ${breakdown["Net Change"] && breakdown["Net Change"].toLocaleString('en', { useGrouping:true })}
                  </div>
                </div>
                <div className="stage-stat-container" style={{ width: "auto" }}>
                  <div className="stage-stat-header">Balance</div>
                  <div className="stage-stat-number">
                    ${breakdown["Balance"] && breakdown["Balance"].toLocaleString('en', { useGrouping:true })}
                  </div>
                </div>
              </div>
            </div>
            <div className="contract-form-section">
              <div className="contract-form-section-header header-row">
                Breakdown
              </div>
              {breakdown && renderBreakdown()}
            </div>
          </div>
        </>
      )}
      {view === "grouping" && (
        <AccountingGroupingView
          breakdown={selectedGrouping ? breakdown["Groupings"][selectedGrouping] : breakdown}
          lastUpdate={lastUpdate}
          selectedGrouping={selectedGrouping}
          setSelectedGrouping={setSelectedGrouping}
        />
      )}
    </>
  );
};
