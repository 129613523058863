import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import API from "API";
import {ProgressSpinnerBlue} from "../progress/progress-spinner-blue";

export default function VendorAlerts(props) {
  const { vendor } = props;
  const token = JSON.parse(localStorage.getItem("token"));

  const [alerts, setAlerts] = useState([]);
  const [loadAlerts, setLoadAlerts] = useState(false);

  useEffect( () => {
    if (vendor) {
      retrieveActions(vendor.id);
    }
  }, []);

  const retrieveActions = async (id) => {
    setLoadAlerts(true);
    await fetch(`${API.endpointURL}/api/vendor_actions/filter/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.data.length > alerts.length) {
          setAlerts(json.data);
        }
        setLoadAlerts(false);
      })
      .catch((e) => {
        console.log(e);
        setLoadAlerts(false);
      })
  };

  const formatDateTime = (timestamp) => {
    const dateTime = new Date(timestamp);
    return dateTime.toLocaleString();
  };

  const buildActionHistory = () => {
    let textBubbles = [];
    alerts.forEach((message, index) => {
      let bubble = <div></div>;
      bubble = (
        <Box
          key={index}
          display="flex"
          flexDirection="column"
          sx={{
            marginBottom: "8px",
            textAlign: "left",
            width: "auto",
          }}
        >
          <Typography
            sx={{
              bgcolor: "#EFEFEF",
              border: 0,
              borderRadius: "6px",
              fontFamily: "Korolev Medium",
              color: "black",
              padding: "8px 13px",
              overflowWrap: "break-word",
            }}
          >
            {message.body}
          </Typography>
          <Typography
            sx={{
              color: "#fcfcfc",
              fontSize: "9.5px",
              marginTop: "2px",
              paddingRight: "15px",
              textAlign: "right",
            }}
          >
            {formatDateTime(message.created_at)}
          </Typography>
        </Box>
      );
      textBubbles.push(bubble);
    });

    return (
      <Box
        className="scroll"
        style={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          boxShadow: 0,
          overflowY: textBubbles.length <= 5 ? "hidden" : "scroll",
          padding: "10px",
        }}
      >
        {textBubbles}
      </Box>
    );
  };

  if (loadAlerts && !alerts.length) {
    return (
      <>
        <h1 className="content-header">Alerts</h1>
        <div className="content-container">
          <div className="spinner-fullHeight">
            <ProgressSpinnerBlue />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <h1 className="content-header">Alerts</h1>
      <div className="content-container">
        {alerts && alerts.length > 0 && buildActionHistory()}
      </div>
    </>
  );
}
