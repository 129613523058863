import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

// Gauge.jsx

// Like a speedometer. Start by passing a value and setting a maxValue for the range

// - props -
// maxValue = number, scaleMidPercent = 0-100, scaleHighPercent = 0-100, REQUIRED value = number, subtitle = null | string

export default class Gauge extends PureComponent {
  constructor(props) {
    super(props);
    this.preState = {
      overAngle: 20,
      maxValue: this.props.maxValue ?? 100,
      maxValueOverride: null,
      scaleMidPercent: this.props.scaleMidPercent ?? 50,
      scaleHighPercent: this.props.scaleHighPercent ?? 90,
      value: this.props.value ?? -1,
      colorsScale: ['#f53b57', '#ffc048', '#0be881'],
      colorsValue: ['rgba(255, 255, 255, 0.3)', 'transparent'],
    };

    this.state = {
      ...this.preState,

      dataScale: [
        { name: 'low', value: this.preState.maxValue * (this.preState.scaleMidPercent/100) },
        { name: 'low', value: this.preState.maxValue * ((this.preState.scaleHighPercent - this.preState.scaleMidPercent)/100) },
        { name: 'Group B', value: this.preState.maxValue * ((100 - this.preState.scaleHighPercent)/100)},
      ],
      dataValue: [
        { name: 'Group A', value: this.preState.value ?? 40 },
        { name: 'Group A', value: this.preState.maxValue - this.preState.value ?? -5 },
      ],
    };
  }

  componentDidUpdate(prevState, prevProps) {
    if(prevProps.value != this.props.value) {
      console.log('update');
      let preState = {
        overAngle: 20,
        maxValue: this.props.maxValue ?? 100,
        scaleMidPercent: this.props.scaleMidPercent ?? 50,
        scaleHighPercent: this.props.scaleHighPercent ?? 90,
        value: this.props.value ?? -1,
        colorsScale: ['#f53b57', '#ffc048', '#0be881'],
        colorsValue: ['rgba(255, 255, 255, 0.3)', 'transparent'],
      };
  
      this.setState({
        ...preState,
  
        dataScale: [
          { name: 'low', value: (prevState.maxValueOverride ?? preState.maxValue) * (preState.scaleMidPercent/100) },
          { name: 'low', value: (prevState.maxValueOverride ?? preState.maxValue) * ((preState.scaleHighPercent - preState.scaleMidPercent)/100) },
          { name: 'Group B', value: (prevState.maxValueOverride ?? preState.maxValue) * ((100 - preState.scaleHighPercent)/100)},
        ],
        dataValue: [
          { name: 'Group A', value: preState.value ?? 40 },
          { name: 'Group A', value: (prevState.maxValueOverride ?? preState.maxValue) - preState.value ?? -5 },
        ],
      });
    }
  }

  update(maxValue = null) {
    if(maxValue != null) {
      this.setState({maxValueOverride: maxValue});
    }
    
    let maxVal = maxValue ?? this.state.maxValue;
    
    this.setState({
      dataScale: [
        { name: 'low', value: maxVal * (this.state.scaleMidPercent/100) },
        { name: 'low', value: maxVal * ((this.state.scaleHighPercent - this.state.scaleMidPercent)/100) },
        { name: 'Group B', value: maxVal * ((100 - this.state.scaleHighPercent)/100)},
      ],
      dataValue: [
        { name: 'Group A', value: this.state.value ?? 40 },
        { name: 'Group A', value: maxVal - this.state.value ?? -5 },
      ],
    });
  }

  resolveColor() {
    let maxValue = this.state.maxValueOverride ?? this.state.maxValue;
    if (this.state.value > (maxValue * (this.state.scaleHighPercent/100))) {
      return this.state.colorsScale[2];
    }else if (this.state.value > (maxValue * (this.state.scaleMidPercent/100))) {
      return this.state.colorsScale[1];
    }
    return this.state.colorsScale[0];
  }

  render() {
    return (
      <div className='gauge'>
        <p>{this.props.title}</p>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart className='gauge__pie' onMouseEnter={this.onPieEnter}>
            <Pie
              data={this.state.dataValue}
              cx={'50%'}
              cy={'50%'}

              startAngle={180 + this.state.overAngle}
              endAngle={0 - this.state.overAngle}

              // innerRadius={60}
              // outerRadius={80}
              innerRadius={'60%'}
              outerRadius={'80%'}

              fill="#8884d8"
              stroke={"none"}
              // paddingAngle={5}
              dataKey="value"
              animationDuration={300}
            >
              {this.state.dataValue.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={this.state.colorsValue[index % this.state.colorsScale.length]} />
              ))}
            </Pie>
            <Pie
              data={this.state.dataScale}
              cx={'50%'}
              cy={'50%'}

              startAngle={180 + this.state.overAngle}
              endAngle={0 - this.state.overAngle}

              innerRadius={'82%'}
              outerRadius={'90%'}
              fill="#8884d8"
              stroke={"none"}

              // paddingAngle={5}
              dataKey="value"
              animationDuration={320}
            >
              {this.state.dataScale.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={this.state.colorsScale[index % this.state.colorsScale.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <h1 style={{ color: this.resolveColor() }}>{this.props.value ?? 'NULL'}</h1>
        <p>{this.props.subtitle ?? ''}</p>
        {/* <input type="number" name="limit" id="limit" onChange={(e) => {this.update(e.target.value);}} value={this.state.maxValueOverride} /> */}
      </div>
    );
  }
}
