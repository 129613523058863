import React, { useEffect, useState } from "react";
import CustomDialog from "./custom-dialog";
import UploadFiles from "../drop-box/upload-files";
import { GrayAutoStyledButton, HighlightGreenAutoStyledButton } from "components/buttons/button-styles";
import ProgressSpinner from "components/progress/progress-spinner";
import Toast from "components/toast/toast";
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { currentApplicantSelector } from 'store/applicants/selectors';
import {
  applicantFilesSelector,
  filesErrorSelector,
  filesLoadingSelector,
  uploadStatusSelector,
} from 'store/files/selectors';
import { getApplicantFiles, uploadFile } from 'store/files/thunk';
import { setFileError, setUploadStatus } from 'store/files/slice';
import { contractTemplatesSelector } from 'store/contracts/selectors';
import { templatesLoadingSelector } from 'store/user/selectors';
import { getContractTemplates } from 'store/contracts/thunk';
import { getApplicant } from 'store/applicants/thunk';

export default function StaffingDocumentsModal(props) {
  const { handleClose, open, user } = props;

  const dispatch = useAppDispatch();
  const applicant = useAppSelector(currentApplicantSelector);
  const applicantFiles = useAppSelector(applicantFilesSelector);
  const templates = useAppSelector(contractTemplatesSelector);
  const templatesLoading = useAppSelector(templatesLoadingSelector);
  const loading = useAppSelector(filesLoadingSelector);
  const error = useAppSelector(filesErrorSelector);
  const uploadStatus = useAppSelector(uploadStatusSelector);

  const [view, setView] = useState("all");
  const [file, setFile] = useState(null);
  const [fileDisplayName, setFileDisplayName] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [dropboxUploadKey, setDropboxUploadKey] = useState(Date.now());
  const [openToast, setOpenToast] = useState(false);

  useEffect(() => {
    dispatch(getApplicantFiles({ id: applicant?.id }));
    if (applicant.application.contract_id) {
      getTemplates();
    }
  }, [applicant]);

  const closeDocumentModal = () => {
    handleClose();
  };

  const getTemplates = () => {
    dispatch(getContractTemplates({ contractId: applicant?.application?.contract_id }));
  };
  const handleUploadFile = () => {
    if (!file || !fileType) {
      dispatch(setFileError('Please select a file and provide a file type.'));
    } else {
      const formData = new FormData();

      formData.append("file", file);
      if (fileDisplayName && fileDisplayName.length > 0) {
        formData.append("display_name", fileDisplayName);
      }
      formData.append("type", fileType);
      formData.append("user_id", applicant.id);

      dispatch(uploadFile({ body: formData }))
        .then(() => {
          handleClose();
          dispatch(getApplicantFiles({ id: applicant?.id }));
        });
    }
  };

  useEffect(() => {
    if (uploadStatus === 'fulfilled') {
      setFile(null);
      setFileDisplayName(null);
      setFileType(null);
      setDropboxUploadKey(Date.now());
      setOpenToast(true);
      dispatch(getApplicantFiles({ id: applicant?.id }));
      dispatch(getApplicant({ applicantId: applicant?.id }));
      dispatch(setUploadStatus(''));
    }
  }, [uploadStatus]);
  
  const renderFileTypeOptions = () => {
    let options = [];
    options.push(
      <option value="" key="none">Select Document Type...</option>
    );
    options.push(
      <option value="resume" key="resume">Resume</option>
    );
    options.push(
      <option value="other" key="other">Other</option>
    );
    templates && templates?.forEach((template) => {
      if (template.active && template.file_active === true && template.template_type !== "has_resume" && template.template_type !== "no_resume") {
        options.push(
          <option key={template.template_name} value={template.template_type}>{template.template_name}</option>
        );
      }
    });
    return (
      <>{options}</>
    );
  };

  const buildForm = () => {
    return (
      <div
        style={{
          maxHeight: "100%",
          overflow: "auto",
          width: "100%",
        }}
      >
        {loading ? (
          <div className="contracts-loading-spinner">
            <ProgressSpinner />
          </div>
        ) : (
          <>
            <GrayAutoStyledButton
              onClick={() => setView("all")}
              style={{
                margin: "12px 0 32px",
                width: "50%"
              }}
            >
              View All
            </GrayAutoStyledButton>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "50px",
                textAlign: "left",
              }}
            >
              <div className="contract-form-set-full">
                <div className="contract-form-label">Document Name</div>
                <input
                  key={`file-name-${dropboxUploadKey}`}
                  className="contract-form-input"
                  placeholder="Enter Document Name..."
                  value={fileDisplayName || ''}
                  onChange={(e) => setFileDisplayName(e.target.value)}
                />
              </div>
              <div className="contract-form-set-full">
                <div className="contract-form-label">Document Type *</div>
                <select
                  key={`file-type-${dropboxUploadKey}`}
                  className="contract-form-input"
                  placeholder="Enter Document Type..."
                  value={fileType || ''}
                  onChange={(e) => setFileType(e.currentTarget.value)}
                >
                  {renderFileTypeOptions()}
                </select>
              </div>
              <div className="belles-file-upload">
                <input
                  key={`selected-file-${dropboxUploadKey}`}
                  type="file"
                  name="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
            </div>
            {error && (
              <div
                style={{
                  color: "#EFEFEF",
                  fontFamily: "Korolev Medium",
                  fontSize: "14px",
                  marginBottom: "16px",
                  textAlign: "left",
                }}
              >
                {error}
              </div>
            )}
            <div
              style={{
                textAlign: "left",
                width: "100%",
              }}
            >
              <HighlightGreenAutoStyledButton
                onClick={() => handleUploadFile()}
                style={{ width: "33%" }}
              >
                Upload
              </HighlightGreenAutoStyledButton>
            </div>
          </>
        )}
        {openToast && (
          <Toast
            open={openToast}
            onClose={() => setOpenToast(false)}
            message="Document uploaded"
          />
        )}
      </div>
    );
  };

  const buildTable = () => {
    return (
      <>
       <GrayAutoStyledButton
          onClick={() => setView("upload")}
          style={{
            margin: "12px 0",
            width: "50%"
          }}
        >
          Upload Document
        </GrayAutoStyledButton>
        {templatesLoading ? (
          <div>
            <ProgressSpinner />
          </div>
        ) : (
          <UploadFiles
            applicant={applicant}
            currentUser={user}
            templates={templates}
            applicantFiles={applicantFiles}
          />
        )}

      </>
    );
  };

  return (
    <CustomDialog
      colorPalette={"gray"}
      title={"Documents"}
      dialogContent={view === "upload" ? buildForm() : buildTable()}
      onClose={closeDocumentModal}
      open={open}
    />
  );
}
