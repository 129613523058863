import "css/analytics.scss";
import React, { useEffect, useState } from "react";
import FillBarSolid from "components/widgets/fill-bar-solid";
import API from "API";
import ProgressSpinner from 'components/progress/progress-spinner';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';
import { useAppSelector } from 'hooks/reduxHooks';
import { recruiterContractsLoadingSelector } from 'store/recruiters/selectors';

export default function RecruiterContractAnalytics(props) {
  const { contract, recruiter, color } = props;
  const token = JSON.parse(localStorage.getItem("token"));
  const loadingContracts = useAppSelector(recruiterContractsLoadingSelector);
  
  const [applicantsPerStage, setApplicantsPerStage] = useState(null);
  const [timePerStage, setTimePerStage] = useState(null);
  const [totalRecruiterApplicants, setTotalRecruiterApplicants] = useState(null);
  const [eqipMetrics, setEqipMetrics] = useState(null);
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  useEffect(() => {
    if (contract && recruiter) {
      fetchData();
    }
  }, [contract, recruiter]);
  
  useEffect(() => {
    if (applicantsPerStage) {
      let totalApplicants =
        applicantsPerStage["stage_1"] +
        applicantsPerStage["stage_2"] +
        applicantsPerStage["stage_3"] +
        applicantsPerStage["stage_4"] +
        applicantsPerStage["stage_5"] +
        applicantsPerStage["stage_6"] +
        applicantsPerStage["stage_7"] +
        applicantsPerStage["stage_8"] +
        applicantsPerStage["stage_9"];
      if (totalApplicants !== totalRecruiterApplicants) {
        setTotalRecruiterApplicants(totalApplicants);
      }
    }
  }, [applicantsPerStage]);
  
  const stageTitles = {
    1: "New Applicants",
    2: "Pre-Screening",
    3: "Interview",
    4: "USG Documents",
    5: "Security",
    6: "Finalize",
  };
  
  const fetchData = async () => {
    setLoading(true);
    try {
      const applicantsPerStagePromise = fetch(`${API.endpointURL}/api/metrics/all_applicants_per_recruiter?recruiter_id=${recruiter}&contract_id=${contract}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }).then((response) => response.json());
      
      const timePerStagePromise = fetch(`${API.endpointURL}/api/metrics/average_spent_per_recruiter?recruiter_id=${recruiter}&contract_id=${contract}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }).then((response) => response.json());
      
      const eqipMetricsPromise = fetch(`${API.endpointURL}/api/metrics/applicants_eqip_breakdown?recruiter_id=${recruiter}&contract_id=${contract}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }).then((response) => response.json());
      
      const [applicantsPerStageData, timePerStageData, eqipMetricsData] = await Promise.all([
        applicantsPerStagePromise,
        timePerStagePromise,
        eqipMetricsPromise,
      ]);
      
      setApplicantsPerStage(applicantsPerStageData);
      setTimePerStage(timePerStageData);
      setEqipMetrics(eqipMetricsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(`Error fetching My Contracts data`)
    }
  };
  
  if (loading || loadingContracts) {
    return (
      <div className="recruiter-stats-container">
        <div className="content-loading" style={{ height: '100vh' }}>
          {color === 'green' ?  <ProgressSpinner /> : <ProgressSpinnerBlue /> }
        </div>
      </div>
    )
  }
  
  if (error.length > 0) {
    return (
      <div className="stats-group-header">{error}</div>
    );
  }
  
  return (
    <div className="recruiter-stats-container">
      <div className="stats-group-container">
        <div className="stats-group-subheader">
          Average time spent per stage
        </div>
        <div className="contract-totals-row">
          {Object.keys(stageTitles).map((stage, index) => (
            <div className="stage-stat-container" key={`${stage}-${stageTitles[stage]}-time-${index}`}>
              <p className="stage-stat-header">{stageTitles[stage]}</p>
              <div className={`stage-stat-number stage-stat-number--${color}`}>
                {timePerStage && timePerStage.averages[stage] ? (Math.round(timePerStage.averages[stage] * 100) / 100) : 0}
              </div>
              <p className="stage-stat-unit">
                {timePerStage && timePerStage.averages[stage] === 1 ? "day" : "days"}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="stats-group-container">
        <div className="stats-group-subheader">
          Applicants per stage
        </div>
        <div className="contract-totals-row">
          {Object.keys(stageTitles).map((stage, index) => (
            <div className="stage-stat-container" key={`${stage}-${stageTitles[stage]}-applicants=${index}`}>
              <p className="stage-stat-header">{stageTitles[stage]}</p>
              <div className={`stage-stat-number stage-stat-number--${color}`}>
                {applicantsPerStage && applicantsPerStage[`stage_${stage}`]}
              </div>
              <p className="stage-stat-unit">
                {applicantsPerStage && applicantsPerStage[`stage_${stage}`] === 1 ? "applicant" : "applicants"}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="stats-group-container">
        <div className="stats-group-subheader">
          Hired and Dispositioned
        </div>
        <div className="fill-stat-container">
          <div className="stage-stat-header">Applicants Hired</div>
          {applicantsPerStage &&
            <FillBarSolid
              color={color}
              value={applicantsPerStage["stage_7"]}
              maxValue={totalRecruiterApplicants}
            />}
        </div>
        <div className="fill-stat-container">
          <div className="stage-stat-header">Applicants Dispositioned</div>
          {applicantsPerStage &&
            <FillBarSolid
              color={color}
              value={applicantsPerStage["stage_8"]}
              maxValue={totalRecruiterApplicants}
            />}
        </div>
      </div>
      <div className="stats-group-container">
        <div className="stats-group-subheader">
          EQIP Metrics
        </div>
        <div className="fill-stat-container">
          <div className="stage-stat-header">Security Package Uploaded to Staging</div>
          {eqipMetrics?.stage_counts &&
            <FillBarSolid
              color={color}
              value={eqipMetrics.stage_counts["bi_packet_sent"]}
              maxValue={totalRecruiterApplicants}
            />}
        </div>
        <div className="fill-stat-container">
          <div className="stage-stat-header">Security Package Submitted to USG</div>
          {eqipMetrics?.stage_counts &&
            <FillBarSolid
              color={color}
              value={eqipMetrics.stage_counts["bi_packet_submitted"]}
              maxValue={totalRecruiterApplicants}
            />}
        </div>
        <div className="fill-stat-container">
          <div className="stage-stat-header">USG eQIP Invite Sent</div>
          {eqipMetrics?.stage_counts &&
            <FillBarSolid
              color={color}
              value={eqipMetrics.stage_counts["eqip_invite_sent"]}
              maxValue={totalRecruiterApplicants}
            />}
        </div>
        <div className="fill-stat-container">
          <div className="stage-stat-header">eQIP Done</div>
          {eqipMetrics?.stage_counts &&
            <FillBarSolid
              color={color}
              value={eqipMetrics.stage_counts["eqip_completed"]}
              maxValue={totalRecruiterApplicants}
            />}
        </div>
        <div>
          <div className="stage-stat-header">Fingerprints Submitted</div>
          {eqipMetrics?.stage_counts &&
            <FillBarSolid
              color={color}
              value={eqipMetrics.stage_counts["fingerprints_date_completed"]}
              maxValue={totalRecruiterApplicants}
            />}
        </div>
      </div>
    </div>
  );
};
