import "../../css/modal.scss";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

export default function CustomDialog(props) {
  const { colorPalette, dialogContent, onClose, open, title, titleHighlight } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={ colorPalette === "gray" ? {
          sx: {
            backgroundColor: "#2A3D1F",
            borderRadius: "28px",
            minWidth: "425px",
          }
        } : colorPalette === "purple-gradient" ? {
          sx: {
            backgroundImage: 'linear-gradient(#26183E, #171717)',
            backgroundColor: 'green',
            borderRadius: '28px',
            minWidth: '425px',
          },
        } : {
          sx: {
            backgroundColor: "#EFEFEF",
            borderRadius: "28px",
            minWidth: "425px",
          }
        }}
      >
        <DialogTitle sx={
          colorPalette === "purple-gradient"
            ? { display: "flex", padding: "10px 35px 0" }
            : { display: "flex", padding: "30px 35px 20px" }
          }
        >
          <div className={`dialog-title ${colorPalette}`}>
            {title}
            <span className="dialog-title-highlight"> {titleHighlight}</span>
          </div>
          <IconButton
            onClick={onClose}
            sx={{
              padding: 0,
              top: colorPalette === "purple-gradient" ? "0" : "-20px",
              color: colorPalette === "purple-gradient" ? "white" : "inherit",
              "&:hover": {
                backgroundColor: "transparent",
                color: colorPalette === "purple-gradient" ? "#531CB3" : "#62AB37"
              },
            }}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0 35px 35px" }}>
          {dialogContent}
        </DialogContent>
      </Dialog>
    </>
  );
}
