import React, { useEffect, useState } from 'react';

import 'css/projects.scss';
import 'css/table-layout.scss';

import AppBar from 'components/app-bar/updated-app-bar-component';
import SidebarTabs from 'components/tabs/sidebar-tabs';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { AllProjectsTab } from 'components/projects/all-projects-tab';
import { CreateProjectTab } from 'components/projects/create-project-tab';
import { ManageProjectsTab } from 'components/projects/manage-projects-tab';
import { SalesConnections } from 'components/sales/sales-connections';
import { ProjectsAnalytics } from 'components/projects/projects-analytics';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import { projectsMetricsSelector, projectsSelector } from 'store/projects/selectors';
import {
  getProjectsByStatus,
  getProjectsCoordinators,
  getProjectsManagers,
  getTotalActiveProjects,
  getTotalProjectsValue,
} from 'store/projects/thunk';
import { resetCurrentPage } from 'store/projects/slice';

export const Projects = () => {
  const dispatch = useAppDispatch();
  const { projectsTabStatus, currentPage } = useAppSelector(projectsSelector);
  const { totalActiveProjects, loadingTotalActiveProjects, totalProjectsValue, loadingTotalProjectsValue } = useAppSelector(projectsMetricsSelector);

  const [currentTab, setCurrentTab] = useState('all projects');

  useEffect(() => {
    dispatch(getTotalActiveProjects());
    dispatch(getTotalProjectsValue());
    dispatch(getProjectsCoordinators());
    dispatch(getProjectsManagers());
    dispatch(resetCurrentPage());
  }, []);
  
  useEffect(() => {
    getProjects();
  }, [projectsTabStatus, currentPage]);
  
  const getProjects = () => {
    dispatch(getProjectsByStatus({ status: projectsTabStatus.toLowerCase(), currentPage: currentPage }));
  };

  return (
    <>
      <AppBar colorPalette="navy" menu={true} title="Projects"/>
      <div className="projects-page">
        <div className="sidebar-content">
          <div className="portal-preview">
            <div className="projects__sidebar">
              <div className="projects__sidebar-container">
                {(loadingTotalActiveProjects || loadingTotalProjectsValue) ? (
                  <div className="projects__sidebar-spinner">
                    <ProgressSpinnerNavy />
                  </div>
                ) : (
                  <>
                    <div className="projects__sidebar-info">
                      <p>Active Projects:</p>
                      <p>{totalActiveProjects}</p>
                    </div>
                    <div className="projects__sidebar-info">
                      <p>Total Project Value:</p>
                      <p>${totalProjectsValue?.toLocaleString('en', { useGrouping: true })}</p>
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  color: '#EFEFEF',
                  fontFamily: 'MagistralCondW08-Medium',
                  fontSize: '35px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                }}
              >
                Option Panel
              </div>
            </div>
            <div className="tabs-container">
              <SidebarTabs
                colorPalette={'navy'}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                tabs={[
                  'all projects',
                  'create project',
                  'connections',
                  'analytics',
                  'manage'
                ]}
              />
            </div>
          </div>
        </div>
        <div
          className="projects__content"
          style={{ paddingTop: currentTab === 'connections' ? 0 : '30px' }}
        >
          {currentTab === 'all projects' && (
            <AllProjectsTab />
          )}
          {currentTab === 'create project' && (
            <CreateProjectTab getProjects={getProjects} />
          )}
          {currentTab === 'connections' && (
            <SalesConnections createButtonNeeded={true} searchBar={true} />
          )}
          {currentTab === 'manage' && (
            <ManageProjectsTab />
          )}
          {currentTab === 'analytics' && (
            <ProjectsAnalytics />
          )}
        </div>
      </div>
    </>
  );
};