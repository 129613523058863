import React, { useState } from 'react';
import API from 'API';
import Toast from 'components/toast/toast';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getSalesServiceTypes } from 'store/sales/thunk';
import { serviceTypesLoadingSelector, serviceTypesSelector } from 'store/sales/selectors';

const initialErrors = {
  validation: false,
  server: false,
};

export const ManageServiceType = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  const dispatch = useAppDispatch();

  const [inputNameService, setInputNameService] = useState('');
  const [serviceName, setServiceName] = useState({});
  const [message, setMessage] = useState('Please enter service name');
  const [isCreating, setIsCreating] = useState(true);
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const [loading, setLoading] = useState(false);

  const serviceList = useAppSelector(serviceTypesSelector);
  const serviceLoading = useAppSelector(serviceTypesLoadingSelector);

  // click on right table
  const handleItemClick = (item) => {
    setIsCreating(false);
    setServiceName(item);
    setInputNameService(item.name);
  };

  const handleInputChange = (e) => {
    if (!e.target.value) {
      setMessage('Please enter service name');
      setErrors({
        ...errors,
        validation: true,
      });
    } else if (serviceList.find(item => item.name === e.target.value)) {
      setMessage('Service already exists');
      setErrors({
        ...errors,
        validation: true,
      });
    } else {
      setMessage(`Service is successfully ${isCreating ? 'created' : 'updated'}`);
      setErrors({
        ...errors,
        validation: false,
      });
    }
    setInputNameService(e.target.value);
  };

  const handleCreate = () => {
    if (isCreating) {
      if (!errors.validation && inputNameService) {
        setLoading(true);
        createService({ name: inputNameService });
        setInputNameService('');
      } else {
        if (!inputNameService) {
          setMessage('Please enter valid service name...')
        }
        setOpenToast(true);
      }
    } else {
      setIsCreating(true);
      setInputNameService('')
    }
  };

  const handleUpdate = () => {
    setOpenToast(true);
    if (!errors.validation) {
      setLoading(true);
      setIsCreating(true);
      updateService({ name: inputNameService, id: serviceName.id });
      setInputNameService('');
    }
  };

  // update new service
  const updateService = (data) => {
    let requestError = false;

    try {
      fetch(`${API.endpointURL}/api/service_types/${data.id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            requestError = true;
          }
          return res;
        })
        .then((res) => res.json())
        .then(() => {
          !requestError && dispatch(getSalesServiceTypes());
        })
        .then(() => {
          setOpenToast(true);
          setLoading(false);
          if (requestError) {
            setErrors({
              ...errors,
              server: true,
            });
          } else {
            setErrors({
              ...errors,
              server: false,
            });
          }
        });
    } catch (e) {
      setMessage(e.message);
      setLoading(false);
    }
  };

  // create new service
  const createService = (data) => {
    setLoading(true);
    let requestError = false;
    try {
      fetch(`${API.endpointURL}/api/service_types`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            requestError = true;
          }
          return res;
        })
        .then((res) => res.json())
        .then(() => {
          !requestError && dispatch(getSalesServiceTypes());
        })
        .then(() => {
          setOpenToast(true);
          if (requestError) {
            setLoading(false);
            setErrors({
              ...errors,
              server: true,
            });
          } else {
            setLoading(false);
            setErrors({
              ...errors,
              server: false,
            });
          }
        });
    } catch (e) {
      setMessage(e.message);
      setLoading(false);
    }

  };

  return (
    <>
      <div style={{ display: 'flex', gap: '10px' }}>
        <div className="content-container" style={{ height: 'calc(100vh - 236px)' }}>
          <div
            className="service-buttons"
            style={{ display: 'flex', alignItems: 'center', padding: '0 0 15px', gap: '10px' }}
          >
            <h3>Choose option:</h3>
            {!isCreating && (
              <button
                className="create-button create-button--dark"
                onClick={handleUpdate}
                disabled={loading}
              >
                Update
              </button>
            )}
            <button
              className="create-button create-button--dark"
              onClick={handleCreate}
              disabled={loading}
            >
              {isCreating ? 'Create' : 'Create new one'}
            </button>
          </div>

          <input
            className="sale-form-input"
            placeholder="Enter service name..."
            value={inputNameService || ''}
            onChange={handleInputChange}
            style={{ width: '100%' }}
          />
        </div>
        <div className="content-container" style={{ height: 'calc(100vh - 236px)' }}>
          {(loading || serviceLoading) ? (
            <div className="content-loading">
              <ProgressSpinnerNavy />
            </div>
          ) : (
            <>
              {serviceList?.length ? (
                <h3 style={{ margin: '0 10px 10px' }}>All services:</h3>
              ) : <h3>First, you need to add services</h3>}
              {serviceList?.map((item) => (
                <div
                  onClick={() => handleItemClick(item)}
                  key={item?.id}
                  className="sales-division"
                >
                  <h3>{item.name}</h3>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={message}
        />
      )}
    </>
  );
};
