import React from 'react';

export const ManageVendorStatus = () => {
  return (
    <section>
      <h1 className="content-header">Vendor Status</h1>
      <div style={{
        width: '100%',
        height: 'calc(100vh - 300px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <p style={{ fontSize: '30px' }}>Pending Accounting module productization</p>
      </div>
    </section>
  );
};
