export const applicantFilesList = {
  "Legacy Information": [
    "Head Shot",
    "Offer Letter",
    "Pre-Screening Questionnaire",
    "Background Check Confirmation",
    "Drug Test Results",
    "Bi-Lingual Test Results",
    "Document 01",
    "Document 02",
    "Document 03",
    "Acceptance Letter",
    "Direct Deposit Form",
  ],
  "Template Information": [
    "Resume",
    "Other",
    "New Applicant 1",
    "New Applicant 2",
    "New Applicant 3",
    "New Applicant 4",
    "New Applicant 5",
    "Pre Screen 1",
    "Pre Screen 2",
    "Pre Screen 3",
    "Pre Screen 4",
    "Pre Screen 5",
    "Interview 1",
    "Interview 2",
    "Interview 3",
    "Interview 4",
    "Interview 5",
    "USG Documents 1",
    "USG Documents 2",
    "USG Documents 3",
    "USG Documents 4",
    "USG Documents 5",
    "Security 1",
    "Security 2",
    "Security 3",
    "Security 4",
    "Security 5",
    "Finalize 1",
    "Finalize 2",
    "Finalize 3",
    "Finalize 4",
    "Finalize 5",
    "Hired 1",
    "Hired 2",
    "Hired 3",
    "Hired 4",
    "Hired 5",
  ],
};

export const fileToUploadType = {
  "Head Shot": "headshot",
  "Resume": "resume",
  "Other": "other",
  "Offer Letter": "offer_letter",
  "Pre-Screening Questionnaire": "NDA",
  "Background Check Confirmation": "background_check",
  "Drug Test Results": "drug_test",
  "Bi-Lingual Test Results": "bilingual_check",
  "Document 01": "misc_1",
  "Document 02": "misc_2",
  "Document 03": "misc_3",
  "Acceptance Letter": "acceptance_letter",
  "Direct Deposit Form": "direct_deposit",
  "New Applicant 1": "new_applicant_1",
  "New Applicant 2": "new_applicant_2",
  "New Applicant 3": "new_applicant_3",
  "New Applicant 4": "new_applicant_4",
  "New Applicant 5": "new_applicant_5",
  "Pre Screen 1": "pre_screening_1",
  "Pre Screen 2": "pre_screening_2",
  "Pre Screen 3": "pre_screening_3",
  "Pre Screen 4": "pre_screening_4",
  "Pre Screen 5": "pre_screening_5",
  "Interview 1": "interview_1",
  "Interview 2": "interview_2",
  "Interview 3": "interview_3",
  "Interview 4": "interview_4",
  "Interview 5": "interview_5",
  "USG Documents 1": "usg_documents_1",
  "USG Documents 2": "usg_documents_2",
  "USG Documents 3": "usg_documents_3",
  "USG Documents 4": "usg_documents_4",
  "USG Documents 5": "usg_documents_5",
  "Security 1": "security_1",
  "Security 2": "security_2",
  "Security 3": "security_3",
  "Security 4": "security_4",
  "Security 5": "security_5",
  "Finalize 1": "finalize_1",
  "Finalize 2": "finalize_2",
  "Finalize 3": "finalize_3",
  "Finalize 4": "finalize_4",
  "Finalize 5": "finalize_5",
  "Hired 1": "hired_1",
  "Hired 2": "hired_2",
  "Hired 3": "hired_3",
  "Hired 4": "hired_4",
  "Hired 5": "hired_5",
};


export const uploadTypeToFile = {
  "headshot": "Head Shot",
  "resume": "Resume",
  "other": "Other",
  "offer_letter": "Offer Letter",
  "NDA": "Pre-Screening Questionnaire",
  "background_check": "Background Check Confirmation",
  "drug_test": "Drug Test Results",
  "bilingual_check": "Bi-Lingual Test Results",
  "misc_1": "Document 01",
  "misc_2": "Document 02",
  "misc_3": "Document 03",
  "misc_4": "Document 04",
  "misc_5": "Document 05",
  "acceptance_letter": "Acceptance Letter",
  "direct_deposit": "Direct Deposit Form",
  "pre_screening_1": "Pre Screen 1",
  "pre_screening_2": "Pre Screen 2",
  "pre_screening_3": "Pre Screen 3",
  "pre_screening_4": "Pre Screen 4",
  "pre_screening_5": "Pre Screen 5",
  "interview_1": "Interview 1",
  "interview_2": "Interview 2",
  "interview_3": "Interview 3",
  "interview_4": "Interview 4",
  "interview_5": "Interview 5",
  "usg_documents_1": "USG Documents 1",
  "usg_documents_2": "USG Documents 2",
  "usg_documents_3": "USG Documents 3",
  "usg_documents_4": "USG Documents 4",
  "usg_documents_5": "USG Documents 5",
  "security_1": "Security 1",
  "security_2": "Security 2",
  "security_3": "Security 3",
  "security_4": "Security 4",
  "security_5": "Security 5",
  "finalize_1": "Finalize 1",
  "finalize_2": "Finalize 2",
  "finalize_3": "Finalize 3",
  "finalize_4": "Finalize 4",
  "finalize_5": "Finalize 5",
  "hired_1": "Hired 1",
  "hired_2": "Hired 2",
  "hired_3": "Hired 3",
  "hired_4": "Hired 4",
  "hired_5": "Hired 5"
};

export const referralTypes = {
  "indeed": "Indeed",
  "linkedin": "LinkedIn",
  "company_website": "Company Website",
  "flyer_door_hang": "Flyer/Door hang",
  "social_media": "Other social media",
  "in_person_hiring_event": "In-Person Hiring Event",
  "referral": "Referral",
  "other": "Other"
};

export const sortOptions = {
  "created_at": "Date Applied",
  "first_name": "First Name",
  "last_name": "Last Name",
  "email": "Email",
  "contract_id": "Contract",
};
