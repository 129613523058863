import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import TagSpreadSheet from 'components/widgets/tag-spreadsheet';
import arrowLeft from 'assets/arrow-left.svg';
import arrowRight from 'assets/arrow-right.svg';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { applicantSelector } from 'store/applicants/selectors';
import { applicantTagsSelector } from 'store/tags/selectors';
import { setTagStatus } from 'store/tags/slice';
import {
  addActiveTagToApplicant,
  getActiveApplicantTags,
  getAvailableApplicantTags,
  removeActiveTagFromApplicant,
} from 'store/tags/thunk';
import { HighlightBlueAutoStyledButton } from 'components/buttons/button-styles';
import { ContractsTagSystem } from 'components/contracts/contracts-tag-system';

export default function ApplicantTags() {
  const dispatch = useAppDispatch();
  
  const applicant = useAppSelector(applicantSelector);
  const {
    activeTags,
    availableTags,
    tagsStatus,
    loadingTags,
    errorTags,
  } = useAppSelector(applicantTagsSelector);
  
  const [selectedTag, setSetSelectedTag] = useState({});
  const [view, setView] = useState('assign');
  
  useEffect(() => {
    if (view === 'assign') {
      dispatch(getActiveApplicantTags({ applicantId: applicant.application.id }));
      dispatch(getAvailableApplicantTags({ applicantId: applicant.application.id }));
    }
  }, [view]);
  
  useEffect(() => {
    if (tagsStatus === 'fulfilled') {
      dispatch(getActiveApplicantTags({ applicantId: applicant.application.id }));
      dispatch(getAvailableApplicantTags({ applicantId: applicant.application.id }));
      dispatch(setTagStatus(''));
    }
  }, [tagsStatus]);
  
  const removeTag = () => {
    const body = {
      applicant_id: applicant.application.id,
      tag_id: selectedTag.id,
    };
    dispatch(removeActiveTagFromApplicant({ body }));
  };
  
  const addTag = () => {
    const previousTags = Object.values(activeTags).map((tag) => tag.id);
    previousTags.push(selectedTag.id);
    
    const body = {
      applicant_id: applicant.application.id,
      tags: previousTags,
    };
    
    dispatch(addActiveTagToApplicant({ applicantId: applicant?.application?.id, body }));
  };
  
  return (
    <>
      <HighlightBlueAutoStyledButton
        onClick={() => {
          setView(view === 'manage' ? 'assign' : 'manage')
        }}
        style={{ position: "absolute", right: '40px', width: '170px' }}
      >
        {view === 'manage' ? 'Assign Tags' : 'Manage Tags'}
      </HighlightBlueAutoStyledButton>
      {view === 'manage' ? (
        <ContractsTagSystem onClick={() => setView('assign')} />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 className="content-header">Tags</h1>
            {errorTags.length > 0 && (<p className="add-user-error">{errorTags}</p>)}
          </div>
          <div className="content-container">
            <div className="tag-main-container">
              <div className="box-container">
                <div className="available-box box">
                  <div className="header">
                    <h2>Available Tags</h2>
                  </div>
                  <div className="active tags">
                    <TagSpreadSheet
                      tags={availableTags}
                      setSelectedTag={setSetSelectedTag}
                      selectedTag={selectedTag}
                    />
                  </div>
                </div>
              </div>

              {loadingTags ? (
                <div className="box-container" style={{ width: '110px' }}>
                  <div className="icons--direction">
                    <ProgressSpinnerNavy />
                  </div>
                </div>
              ) : (
                <div className="box-container" style={{ width: '110px' }}>
                  <div className="icons--direction">
                    <button
                      disabled={Object.keys(selectedTag).length === 0}
                      className={classNames('arrow-btn add-btn', {
                        'arrow-btn arrow-btn--disabled add-btn': Object.keys(selectedTag).length === 0,
                      })}
                      onClick={() => addTag()}>
                      <img src={arrowRight} alt="add"/>
                    </button>
                    <button
                      disabled={Object.keys(selectedTag).length === 0}
                      className={classNames('arrow-btn remove-btn', {
                        'arrow-btn arrow-btn--disabled remove-btn': Object.keys(selectedTag).length === 0,
                      })}
                      onClick={() => removeTag()}>
                      <img src={arrowLeft} alt="remove"/>
                    </button>
                  </div>
                </div>
              )}

              <div className="box-container">
                <div className="active-box box">
                  <div className="header">
                    <h2>Active Tags</h2>
                  </div>
                  <div className="active tags tableLayout">
                    <TagSpreadSheet
                      tags={activeTags}
                      setSelectedTag={setSetSelectedTag}
                      selectedTag={selectedTag}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
