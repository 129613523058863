import "../../css/modal.scss";
import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CustomDialogRedesign from "./custom-dialog-redesign";
import { adminErrorMessages } from 'shared/error-messages';
import API from "API";
import { useLocation } from 'react-router-dom';
import ProgressSpinner from 'components/progress/progress-spinner';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { uniqueSelector } from 'store/auth/selectors';
import { checkUniqueness } from 'store/auth/thunk';
import { setStatus } from 'store/auth/slice';

export default function AddUserModal(props) {
  const { open, openSuccessToast, handleClose, updateUsersList, uploadType } =
    props;

  const token = JSON.parse(localStorage.getItem("token"));
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  
  const dispatch = useAppDispatch();
  const { emailUnique, phoneUnique, emailError, phoneError, loadingUniqueness, status } = useAppSelector(uniqueSelector);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [active, setActive] = useState("");
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [allUsers, setAllUsers] = useState(null);

  const [creatingLoading, setCreatingLoading] = useState(false);
  const [addPlacement, setAddPlacement] = useState(false);

  const [contracts, setContracts] = useState([]);
  const [contractsLoading, setContractsLoading] = useState(false);
  const [selectedContract, setSelectedContract] = useState('');

  const [divisions, setDivisions] = useState([]);
  const [divisionsLoading, setDivisionsLoading] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState('');

  const [subdivisions, setSubdivisions] = useState([]);
  const [subdivisionsLoading, setSubdivisionsLoading] = useState(false);
  const [selectedSubdivision, setSelectedSubdivision] = useState('');

  const [recruiters, setRecruiters] = useState([]);
  const [recruitersLoading, setRecruitersLoading] = useState(false);
  const [selectedRecruiter, setSelectedRecruiter] = useState('');
  const [selectedResume, setSelectedResume] = useState(null);

  const { pathname } = useLocation();

  const isStaffingPage = pathname.toLowerCase() === '/staffing';

  const getContracts = async () => {
    setContractsLoading(true);
    await fetch(`${API.endpointURL}/api/contracts`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setContractsLoading(false);
        const availableContracts = json.data.filter(contract => contract.status === 'active')
        setContracts(availableContracts);
      })
      .catch(() => {
        setContractsLoading(false);
      });
  };

  const getDivisions = async () => {
    setDivisionsLoading(true);
    await fetch(
      `${API.endpointURL}/api/filter/regions?contract_id=${selectedContract}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        setDivisions(json.data);
        setDivisionsLoading(false);
      })
      .catch(() => {
        setDivisionsLoading(false);
      });
  };

  const getSubdivisions = async () => {
    setSubdivisionsLoading(true);
    await fetch(
      `${API.endpointURL}/api/filter/sites?region_id=${selectedDivision}&contract_id=${selectedContract}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        setSubdivisions(json.data);
        setSubdivisionsLoading(false);
      })
      .catch(() => {
        setSubdivisionsLoading(false);
      });
  };

  const getAssignedRecruiters = async () => {
    setRecruitersLoading(true);
    await fetch(`${API.endpointURL}/api/staffing/contracts/get_recruiters?contract_id=${selectedContract}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setRecruiters(json);
        setRecruitersLoading(false);
      })
      .catch(() => {
        setRecruitersLoading(false);
      });
  };

  useEffect(() => {
    getAllUsers();
    if (isStaffingPage) {
      getContracts();
    }
  }, []);

  useEffect(() => {
    if (selectedContract) {
      getDivisions();
      getAssignedRecruiters();
    }
  }, [selectedContract]);

  useEffect(() => {
    if (selectedDivision) {
      getSubdivisions();
    } else {
      setSubdivisions([]);
      setSelectedSubdivision('');
    }
  }, [selectedDivision]);

  const getAllUsers = async () => {
    await fetch(`${API.endpointURL}/api/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setAllUsers(json.data);
      });
  };

  const clearData = () => {
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setPassword("");
    setRole("");
    setActive("");
    setError(null);
  };

  const createApplicant = async () => {
    if (!selectedContract) {
      setError('* Error. Contract is required field')
    } else {
      let requestError = false;
      setError('');
      setCreatingLoading(true);
      const formData = new FormData();

      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("middle_name", middleName);
      formData.append("email", email);
      formData.append("phone", phoneNumber);
      formData.append("password", password);
      formData.append("contract_id", selectedContract);
      formData.append("division_id", selectedDivision);
      formData.append("subdivision_id", selectedSubdivision);

      if (selectedRecruiter) {
        formData.append("recruiter_id", selectedRecruiter);
      }

      if (selectedResume) {
        formData.append("resume", selectedResume);
      }

      await fetch(`${API.endpointURL}/api/manual-apply`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      })
        .then((response) => {
          if (!response.ok) {
            requestError = true;
          }
        })
        .then(() => {
          setCreatingLoading(false);
          if (requestError) {
            setError('Error creating applicant. Try again later...');
          } else {
            clearData();
            handleClose();
            if (openSuccessToast) {
              openSuccessToast();
            }
            if (updateUsersList) {
              updateUsersList();
            }
          }
        })
        .catch(() => {
          setCreatingLoading(false);
          setError("Error creating applicant. Try again later...");
        });
    }
  }

  const register = async () => {
    if (!firstName || !lastName || !email || !phoneNumber) {
      setError("* Please fill out all required fields.");
    } else if (password.length > 0 && password.length < 9) {
      setError("Please enter a password with at least 9 characters.");
    } else {
      setCreatingLoading(true);
      let registerEndpoint = role === "admin" ? `${API.endpointURL}/api/admin/register` : `${API.endpointURL}/api/register`;
      await fetch(registerEndpoint, {
        method: "POST",
        body: JSON.stringify({
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          phone: phoneNumber,
          email: email.toLowerCase(),
          password: password,
          role: uploadType === "applicant" ? "applicant" : role,
          active: "true",
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCreatingLoading(false);
          if (data.success === false) {
            if (data.message) {
              setError(data.message);
            } else if (data.error) {
              determineErrorMessage(data.error);
            }
          } else if (data.user) {
            clearData();
            handleClose();
            if (openSuccessToast) {
              openSuccessToast();
            }
            if (updateUsersList) {
              updateUsersList();
            }
          }
        })
        .catch(() => {
          setCreatingLoading(false);
        });
    }
  };
  
  const checkUniqueEmailAndPhone = () => {
    if (email && phoneNumber) {
      const body = {
        email: email,
        phone: phoneNumber,
      }
      dispatch(checkUniqueness({ body }))
    }
  }
  
  
  useEffect(() => {
    if (status === 'fulfilled') {
      handleAdd();
      dispatch(setStatus(''));
    }
  }, [status]);
  
  const handleAdd = () => {
    if (isStaffingPage) {
      if (!addPlacement) {
        if (firstName &&
          lastName &&
          email &&
          phoneNumber &&
          password &&
          emailUnique &&
          phoneUnique) {
          setError('');
          setAddPlacement(true);
        } else {
          setError('* Please fill out all required fields.');
        }
      } else {
        createApplicant();
      }
    } else {
      register();
    }
  };

  const determineErrorMessage = (errors) => {
    let errorMessage = "";
    let emailError = errors["email"] ? adminErrorMessages[errors["email"]] : "";
    let emailUser = null;
    let phoneError = errors["phone"] ? adminErrorMessages[errors["phone"]] : "";
    let phoneUser = null;
    if (
      emailError &&
      emailError === "This email is already linked to another user"
    ) {
      emailUser = allUsers.find(
        (person) => person.email === email.toLowerCase()
      );
    }
    if (
      phoneError &&
      phoneError === "This phone number is already linked to another user"
    ) {
      phoneUser = allUsers.find((person) => person.phone === phoneNumber);
    }
    if (emailUser && phoneUser && emailUser.id === phoneUser.id) {
      errorMessage = `This email and phone number are already linked to another user: ${emailUser.first_name} ${emailUser.last_name}.`;
    } else {
      if (emailError) {
        if (emailUser) {
          errorMessage += `${emailError}: ${emailUser.first_name} ${emailUser.last_name}. `;
        } else {
          errorMessage += `${emailError}. `;
        }
      }
      if (phoneError) {
        if (phoneUser) {
          errorMessage += `${phoneError}: ${phoneUser.first_name} ${phoneUser.last_name}.`;
        } else {
          errorMessage += `${phoneError}.`;
        }
      }
    }
    setError(errorMessage);
  };

  const handleSubmission = () => {
    const formData = new FormData();

    formData.append("csv", selectedFile);

    fetch(`${API.endpointURL}/api/mass_create`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        Connection: "keep-alive",
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then(() => {
        clearData();
        handleClose();
      })
      .catch(() => {
        handleClose();
      });
  };

  const addUserForm = () => {
    return (
      <React.Fragment key="add-user-form">
        {/* TODO: Add back after mass import update
        <div>
          <div className="mass-import-label">Mass Import</div>
          <input type="file" name="file" onChange={changeHandler} />
          {fileError && <div className="add-user-error">{fileError}</div>}
          <div className="button-container">
            <Button
              onClick={handleSubmission}
              variant="contained"
              className="add-user-button"
              sx={{
                backgroundColor: "#556155",
                color: "#EFEFEF",
                fontSize: "18px",
                width: "175px",
                "&:hover": { backgroundColor: "#a5af96", color: "#3e453d" },
              }}
            >
              Submit
            </Button>
          </div>
        </div>
        <Divider sx={{ borderColor: "#a5af96", margin: "10px 0" }} /> */}
        <div>
          {addPlacement ? (
            <>
              <div style={{ textAlign: "left" }} className="form-row-label">
                Contract *
                {contractsLoading && (
                  <div className="form-row-spinner">
                    <ProgressSpinner />
                  </div>
                )}
              </div>
              <FormControl fullWidth>
                <Select
                  id="edit-user-detail"
                  key="edit-user-detail"
                  displayEmpty
                  onChange={(event) => {
                    setSelectedContract(event.target.value);
                    setSelectedDivision('');
                    setSelectedSubdivision('');
                    setSelectedRecruiter('');
                  }}
                  sx={{
                    backgroundColor: "#EFEFEF",
                    border: 0,
                    borderRadius: "8px",
                    fontFamily: "Korolev Medium",
                    textAlign: "left",
                  }}
                  value={selectedContract}
                >
                  <MenuItem key={'none'} value={''}>
                    Select Contract
                  </MenuItem>
                  {contracts.length ? contracts?.map(contract => (
                    <MenuItem key={contract.id} value={contract.id}>
                      {contract.title || "No Title"}
                    </MenuItem>
                  )) : (
                    <MenuItem value={'empty'} disabled>
                      {contractsLoading ? 'Loading...' : 'No Contracts.'}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <div style={{ textAlign: "left" }} className="form-row-label">
                Division
                {divisionsLoading && (
                  <div className="form-row-spinner">
                    <ProgressSpinner />
                  </div>
                )}
              </div>
              <FormControl fullWidth>
                <Select
                  id="edit-user-detail"
                  key="edit-user-detail"
                  displayEmpty
                  onChange={(event) => {
                    setSelectedDivision(event.target.value);
                    setSelectedSubdivision('');
                  }}
                  sx={{
                    backgroundColor: "#EFEFEF",
                    border: 0,
                    borderRadius: "8px",
                    fontFamily: "Korolev Medium",
                    textAlign: "left",
                  }}
                  value={selectedDivision}
                >
                  <MenuItem key={'none'} value={''}>
                    Select Division
                  </MenuItem>
                  {divisions.length ? divisions?.map(division => (
                    <MenuItem key={division.id} value={division.id}>
                      {division.title || "No Title"}
                    </MenuItem>
                  )) : (
                    <MenuItem value={'empty'} disabled>
                      No Divisions for this Contract
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <div style={{ textAlign: "left" }} className="form-row-label">
                Subdivision
                {subdivisionsLoading && (
                  <div className="form-row-spinner">
                    <ProgressSpinner />
                  </div>
                )}
              </div>
              <FormControl fullWidth>
                <Select
                  id="edit-user-detail"
                  key="edit-user-detail"
                  displayEmpty
                  onChange={(event) => {
                    setSelectedSubdivision(event.target.value);
                  }}
                  sx={{
                    backgroundColor: "#EFEFEF",
                    border: 0,
                    borderRadius: "8px",
                    fontFamily: "Korolev Medium",
                    textAlign: "left",
                  }}
                  value={selectedSubdivision}
                >
                  <MenuItem key={'none'} value={''}>
                    Select Subdivision
                  </MenuItem>
                  {subdivisions.length ? subdivisions?.map(subdivision => (
                    <MenuItem key={subdivision.id} value={subdivision.id}>
                      {subdivision.title || "No Title"}
                    </MenuItem>
                  )) : (
                    <MenuItem value={'empty'} disabled>
                      No Subdivisions for this Division
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <div style={{ textAlign: "left" }} className="form-row-label">
                Recruiter
                {recruitersLoading && (
                  <div className="form-row-spinner">
                    <ProgressSpinner />
                  </div>
                )}
              </div>
              <FormControl fullWidth>
                <Select
                  id="edit-user-detail"
                  key="edit-user-detail"
                  displayEmpty
                  onChange={(event) => {
                    setSelectedRecruiter(event.target.value);
                  }}
                  sx={{
                    backgroundColor: "#EFEFEF",
                    border: 0,
                    borderRadius: "8px",
                    fontFamily: "Korolev Medium",
                    textAlign: "left",
                  }}
                  value={selectedRecruiter}
                >
                  <MenuItem key={'none'} value={''}>
                    Select Recruiter
                  </MenuItem>
                  {recruiters.length ? recruiters?.map(recruiter => (
                    <MenuItem key={recruiter.id} value={recruiter.id}>
                      {`${recruiter.first_name} ${recruiter.last_name}`}
                    </MenuItem>
                  )) : (
                    <MenuItem value={'empty'} disabled>
                      No Recruiters for this Contract
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              <div style={{ textAlign: "left" }} className="form-row-label">
                Resume
              </div>
              <input
                type="file"
                name="file"
                onChange={(e) => setSelectedResume(e.target.files[0])}
                style={{ width: '100%' }}
              />
            </>
          ) : (
            <>
              <div className="form-row-label">Name</div>
                  <TextField
                    fullWidth
                    className="add-user-form-inputs"
                    id="add-user-first-name"
                    key="add-user-first-name"
                    label="First Name"
                    variant="outlined"
                    margin="dense"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.currentTarget.value)}
                  />
                  <TextField
                    fullWidth
                    className="add-user-form-inputs"
                    id="add-user-middle-name"
                    key="add-user-middle-name"
                    label="Middle Initial "
                    variant="outlined"
                    margin="dense"
                    value={middleName}
                    onChange={(e) => setMiddleName(e.currentTarget.value)}
                  />
                  <TextField
                    fullWidth
                    className="add-user-form-inputs"
                    id="add-user-last-name"
                    key="add-user-last-name"
                    label="Last Name"
                    variant="outlined"
                    margin="dense"
                    required
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.currentTarget.value);
                    }}
                  />
              <div className="form-row-label">Contact</div>
                  <TextField
                    fullWidth
                    className="add-user-form-inputs"
                    id="add-user-email"
                    key="add-user-email"
                    label="Email"
                    variant="outlined"
                    margin="dense"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                  />
                  <TextField
                    fullWidth
                    className="add-user-form-inputs"
                    id="add-user-phone-number"
                    key="add-user-phone-number"
                    label="Phone Number"
                    variant="outlined"
                    margin="dense"
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.currentTarget.value)}
                  />
              <div className="form-row-label">Password</div>
                  <TextField
                    fullWidth
                    className="add-user-form-inputs"
                    id="add-user-temp-password"
                    key="add-user-temp-password"
                    label={isStaffingPage ? 'Temporary Password *' : "Temporary Password"}
                    type="password"
                    variant="outlined"
                    margin="dense"
                    value={password}
                    error={password.length > 0 && password.length < 9}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    helperText={
                      password.length > 0 && password.length < 9
                      && "Password must be at least 9 characters."
                    }
                  />
              {uploadType === "all" && (
                <>
                  <div className="form-row-label">User Type</div>
                      <FormControl
                        sx={{ margin: "8px 0 4px", width: "100%" }}
                        size="small"
                      >
                        <InputLabel id="demo-select-small">Role</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="add-user-role"
                          key="add-user-role"
                          value={role}
                          label="Role"
                          onChange={(e) => setRole(e.target.value)}
                        >
                          <MenuItem value={"accounting"}>Accounting</MenuItem>
                          {loggedInUser.role === "admin" && <MenuItem value={"admin"}>Admin</MenuItem>}
                          <MenuItem value={"applicant"}>Applicant</MenuItem>
                          <MenuItem value={"recruiter"}>Recruiter</MenuItem>
                          <MenuItem value={"sales"}>Sales</MenuItem>
                          <MenuItem value={"staffing"}>Staffing</MenuItem>
                          <MenuItem value={"project manager"}>Project Manager</MenuItem>
                          <MenuItem value={"project coordinator"}>Project Coordinator</MenuItem>
                        </Select>
                      </FormControl>
                    {/* TODO: Add back when implementing active/inactive
                <Grid item sx={{ margin: "8px 0 4px", width: "50%" }}>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <InputLabel id="demo-select-small">Active</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="add-user-active"
                      key="add-user-active"
                      value={active}
                      label="Active"
                      onChange={(e) => setActive(e.target.value)}
                    >
                      <MenuItem value={"true"}>Active</MenuItem>
                      <MenuItem value={"false"}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                </>
              )}
            </>
          )}
        </div>
        {error && <div className="add-user-error">{error}</div>}
        {emailError && <div className="add-user-error">{emailError}</div>}
        {phoneError && <div className="add-user-error">{phoneError}</div>}
        {creatingLoading && (
          <div className={addPlacement ? 'add-user-spinner-placement' : 'add-user-spinner'} >
            <ProgressSpinner />
          </div>
        )}
        {/* <Link to={"/manage"} sx={{ textDecoration: "none" }}> */}
        <div className="button-container">
          {addPlacement && (
            <Button
              variant="contained"
              className="add-user-button"
              sx={{
                backgroundColor: "#7a7a7a",
                border: "1px solid #7a7a7a",
                borderRadius: "10px",
                color: "#EFEFEF",
                fontFamily: "MagistralCondW08-Medium",
                fontSize: "18px",
                minHeight: "25px",
                padding: "13px 20px",
                marginRight: '10px',
                textTransform: "capitalize",
                width: "175px",
                "&:hover": {
                  backgroundColor: "transparent",
                  border: "1px solid #7a7a7a",
                  boxShadow: "none",
                  color: "#7a7a7a",
                },
              }}
              onClick={() => setAddPlacement(false)}
              disabled={creatingLoading}
            >
              Back
            </Button>
          )}
          {loadingUniqueness && !addPlacement && (
            <div style={{ position: 'absolute', right: '220px', bottom: 0 }}>
              <ProgressSpinner />
            </div>
          )}
          <Button
            variant="contained"
            className="add-user-button"
            sx={{
              backgroundColor: "#62AB37",
              border: "1px solid #62AB37",
              borderRadius: "10px",
              color: "#EFEFEF",
              fontFamily: "MagistralCondW08-Medium",
              fontSize: "18px",
              minHeight: "25px",
              padding: "13px 20px",
              textTransform: "capitalize",
              width: "175px",
              "&:hover": {
                backgroundColor: "transparent",
                border: "1px solid #62AB37",
                boxShadow: "none",
                color: "#62AB37",
              },
            }}
            onClick={() => {
              checkUniqueEmailAndPhone();
            }}
            disabled={creatingLoading}
          >
            Add
          </Button>
        </div>
        {/* </Link> */}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <CustomDialogRedesign
        colorPalette="green"
        dialogContent={addUserForm()}
        onClose={handleClose}
        open={open}
        title={"ADD"}
        titleHighlight={
          uploadType === "applicant" ? "NEW APPLICANT" : "NEW USER"
        }
      />
    </React.Fragment>
  );
}
