import "../../css/modal.scss";
import React, { useEffect, useState } from 'react';
import { Box, Button, MenuItem, Select, TextField } from "@mui/material";
import CustomDialog from "../modal/custom-dialog";
import ProgressSpinner from 'components/progress/progress-spinner';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  applicantEditStatusSelector,
  applicantErrorSelector,
  applicantLoadingSelector,
  applicantSelector,
} from 'store/applicants/selectors';
import { editApplicant, getApplicant } from 'store/applicants/thunk';
import { isSearchedApplicantsSelector } from 'store/staffingSelectedOptions/selectors';
import { useGetApplicantsByStage } from 'hooks/getApplicantsByStage';
import { useGetSearchedApplicants } from 'hooks/getSearchedApplicants';
import { setEditedApplicantId } from 'store/applicants/slice';

const stages = {
  1: "New Applicants",
  2: "Pre-Screening",
  3: "Interview",
  4: "USG Documents",
  5: "Security",
  6: "Finalize",
  7: "Hired",
  8: "Disposition",
  9: "Archived",
};

export default function DispositionDialog(props) {
  const {
    handleClose,
    open,
    setOpenToast,
    setBlockLoading,
  } = props;

  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector(applicantSelector);
  const error = useAppSelector(applicantErrorSelector);
  const loading = useAppSelector(applicantLoadingSelector);
  const editStatus = useAppSelector(applicantEditStatusSelector);
  const isSearched = useAppSelector(isSearchedApplicantsSelector);

  const getApplicants = useGetApplicantsByStage();
  const searchApplicants = useGetSearchedApplicants();
  const [stage, setStage] = useState(
    selectedUser.application.stage ? selectedUser.application.stage : "1"
  );
  const [rdStatus, setRdStatus] = useState(
    selectedUser.application.rd_status
      ? selectedUser.application.rd_status
      : null
  );
  const [usgStatus, setUsgStatus] = useState(
    selectedUser.application.status ? selectedUser.application.status : null
  );
  const [candidateStatus, setCandidateStatus] = useState(
    selectedUser.application.candidate_status ? selectedUser.application.candidate_status : null
  );
  const [nextStep, setNextStep] = useState(
    selectedUser.application.next_step ? selectedUser.application.next_step : null
  );

  useEffect(() => {
    if (editStatus === 'fulfilled') {
      setBlockLoading(false);
      dispatch(getApplicant({ applicantId: selectedUser.id }));
      if (isSearched) {
        searchApplicants();
      } else {
        getApplicants();
      }
    }
  }, [editStatus]);
  
  const editApplicant2 = async () => {
    if (
      selectedUser.application.stage !== stage ||
      selectedUser.application.rd_status !== rdStatus ||
      selectedUser.application.status !== usgStatus ||
      selectedUser.application.candidate_status !== candidateStatus ||
      selectedUser.application.next_step !== nextStep
    ) {
      let responseBody = {};
      responseBody.stage = stage;
      responseBody.rd_status = rdStatus;
      responseBody.status = usgStatus;
      responseBody.candidate_status = candidateStatus;
      responseBody.next_step = nextStep;
      if (selectedUser.application.stage !== stage) {
        responseBody.step = null;
      }
      try {
        await dispatch(editApplicant({ applicantId: selectedUser.application.id, body: responseBody }));
        dispatch(setEditedApplicantId(selectedUser.id));
        getApplicants();
        handleClose();
        setOpenToast();
      } catch (e) {
        setOpenToast();
      }

    } else {
      handleClose();
    }
  };

  const buildForm = () => {
    let stageOptions = [];
    for (const [key, value] of Object.entries(stages)) {
      stageOptions.push(<MenuItem key={key} value={key}>{value}</MenuItem>);
    }
    return (
      <Box>
        <p className="form-row-label" style={{ textAlign: "left" }}>
          Stage
        </p>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={stage || ''}
          onChange={(event) => setStage(event.target.value)}
          sx={{
            backgroundColor: "#fff",
            border: 0,
            borderRadius: "8px",
            fontFamily: "Korolev Medium",
            marginBottom: "8px",
            textAlign: "left",
            width: "100%",
          }}
        >
          {stageOptions}
        </Select>
        <p className="form-row-label" style={{ textAlign: "left" }}>
          RD Status
        </p>
        <TextField
          value={rdStatus || ''}
          onChange={(e) => setRdStatus(e.currentTarget.value)}
          fullWidth
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #a5af96",
            borderRadius: "8px",
            padding: "6px 10px 5px",
            marginBottom: 1,
            width: "100%",
          }}
          placeholder="Status..."
          rows={1}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <p className="form-row-label" style={{ textAlign: "left" }}>
          USG Status
        </p>
        <TextField
          value={usgStatus || ''}
          onChange={(e) => setUsgStatus(e.currentTarget.value)}
          fullWidth
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #a5af96",
            borderRadius: "8px",
            padding: "6px 10px 5px",
            marginBottom: 1,
            width: "100%",
          }}
          placeholder="Status..."
          rows={1}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
          <p className="form-row-label" style={{ textAlign: "left" }}>
            Candidate Status
          </p>
          <p className="form-public-msg">
            <b style={{ fontSize: '16px' }}>Public!</b> {' '}
            (This field will be viewable by the candidate)
          </p>
        </div>
       
        <TextField
          value={candidateStatus || ''}
          onChange={(e) => setCandidateStatus(e.currentTarget.value)}
          fullWidth
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #a5af96",
            borderRadius: "8px",
            padding: "6px 10px 5px",
            marginBottom: 1,
            width: "100%",
            input: {
              fontFamily: "Korolev Medium",
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 1000px #fff inset",
                WebkitTextFillColor: '#171717'
              }
            },
          }}
          placeholder="Candidate Status..."
          rows={1}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
          <p className="form-row-label" style={{ textAlign: "left" }}>
            Next Step
          </p>
          <p className="form-public-msg">
            <b style={{ fontSize: '16px' }}>Public!</b> {' '}
            (This field will be viewable by the candidate)
          </p>
        </div>
  
        <TextField
          value={nextStep || ''}
          onChange={(e) => setNextStep(e.currentTarget.value)}
          fullWidth
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #a5af96",
            borderRadius: "8px",
            padding: "6px 10px 5px",
            marginBottom: 1,
            width: "100%",
            input: {
              fontFamily: "Korolev Medium",
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 1000px #fff inset",
                WebkitTextFillColor: '#171717'
              }
            },
          }}
          placeholder="Next Step..."
          rows={1}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
        {error && <div className="add-user-error">{error}</div>}
        <div className="button-container">
          {loading && (
            <div style={{ position: 'relative', width: '100px', overflow: 'hidden' }}>
              <div style={{ position: 'absolute', top: -35, left: 0 }}><ProgressSpinner/></div>
            </div>
          )}
          <Button
            variant="contained"
            className="add-user-button"
            disabled={loading}
            sx={{
              backgroundColor: "#556155",
              color: "#EFEFEF",
              fontSize: "18px",
              width: "175px",
              "&:hover": { backgroundColor: "#a5af96", color: "#3e453d" },
            }}
            onClick={() => editApplicant2()}
          >
            Submit
          </Button>
        </div>
      </Box>
    );
  };

  return (
    <CustomDialog
      dialogContent={buildForm()}
      onClose={handleClose}
      open={open}
      title={"STATUS"}
    />
  );
}
