import React, { useEffect, useState } from 'react';
import API from 'API';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';
import classNames from 'classnames';

export const ExecutiveSales = () => {
  const [saleAnalytics, setSaleAnalytics] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem('token'));

  useEffect(() => {
    getSaleAnalytics();
  }, []);

  const getSaleAnalytics = async () => {
    setLoading(true);
    await fetch(`${API.endpointURL}/api/metrics/sales/per_stage`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setSaleAnalytics(res);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <div style={{ position: 'relative' }}>
      <h2 className="content-header">Sales</h2>
      {loading && (
        <div className="spinner-analytics" style={{ height: '100%' }}>
          <ProgressSpinnerBlue/>
        </div>
      )}
      <div
        className={classNames('metrics-content-container', {
          'metrics-content-container-transparent': loading,
        })}
        style={{ height: 'fit-content' }}
      >
        <div className="stats-group-container" >
          <div className="contract-totals-row">
            <div className="stage-stat-container">
              <div className="stage-stat-header">Capturing</div>
              <div className="stage-stat-number">{saleAnalytics ? saleAnalytics.Lead : 'N/A'}</div>
              <div className="stage-stat-unit">
                {saleAnalytics && saleAnalytics.Lead === 1 ? "sale" : "sales"}
              </div>
            </div>
            <div className="stage-stat-container">
              <div className="stage-stat-header">In Work</div>
              <div className="stage-stat-number">{saleAnalytics ? saleAnalytics.Opportunity : 'N/A'}</div>
              <div className="stage-stat-unit">
                {saleAnalytics && saleAnalytics.Opportunity === 1 ? "sale" : "sales"}
              </div>
            </div>
            <div className="stage-stat-container">
              <div className="stage-stat-header">Submitted</div>
              <div className="stage-stat-number">{saleAnalytics ? saleAnalytics.Contract : 'N/A'}</div>
              <div className="stage-stat-unit">
                {saleAnalytics && saleAnalytics.Contract === 1 ? "sale" : "sales"}
              </div>
            </div>
            <div className="stage-stat-container">
              <div className="stage-stat-header">Awarded To</div>
              <div className="stage-stat-number">{saleAnalytics ? saleAnalytics.Award : 'N/A'}</div>
              <div className="stage-stat-unit">
                {saleAnalytics && saleAnalytics.Award === 1 ? "sale" : "sales"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
