import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { StyledFolderTab } from './tab-styles';
import { useAppSelector } from 'hooks/reduxHooks';
import { currentApplicantSelector } from 'store/applicants/selectors';

const stages = {
  1: "New Applicants",
  2: "Pre-Screening",
  3: "Interview",
  4: "USG Documents",
  5: "Security",
  6: "Finalize",
  7: "Hired",
  8: "Disposition",
};


export default function StaffingModuleTabs(props) {

  const { currentTab, setCurrentTab } = props;

  const currentApplicant = useAppSelector(currentApplicantSelector);

  const currentStage = currentApplicant?.application?.stage ? stages[currentApplicant.application.stage] : null;

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        sx={{ minHeight: "44px" }}
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
      >
        <StyledFolderTab
          label={currentStage ? currentStage : 'Automation'}
          value={0}
          firsttab={'true'}
        />
        <StyledFolderTab label='Notes' value={1} />
        <StyledFolderTab label='Alerts' value={2} />
        <StyledFolderTab label='Dropbox' value={3} lasttab={'true'} />
      </Tabs>
    </Box>
  );
};
