import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { tagGroupsSelector, tagsSelector } from 'store/tags/selectors';
import { openTagGroupFilterSelector } from 'store/ui/selectors';
import {
  filteredTagsSelector,
  isSearchedApplicantsSelector,
  selectedGroupsSelector,
  selectedTagsSelector,
} from 'store/staffingSelectedOptions/selectors';
import { clearTags, setFilteredTags, setSelectedGroups, setSelectedTags } from 'store/staffingSelectedOptions/slice';
import { getTagGroups } from 'store/tags/thunk';
import { useGetApplicantsByStage } from 'hooks/getApplicantsByStage';
import { useGetSearchedApplicants } from 'hooks/getSearchedApplicants';
import { FilterNestedDropdown } from 'components/filter-items/filter-nested-dropdown';

export default function TagFilter() {
  const dispatch = useAppDispatch();
  const allTags = useAppSelector(tagsSelector);
  const selectedTags = useAppSelector(selectedTagsSelector);
  const selectedGroups = useAppSelector(selectedGroupsSelector);
  const openTagGroupFilter = useAppSelector(openTagGroupFilterSelector);
  const groupTags = useAppSelector(filteredTagsSelector);
  const groups = useAppSelector(tagGroupsSelector);
  const getApplicantsByStage = useGetApplicantsByStage();
  const getSearchedApplicants = useGetSearchedApplicants();
  const isSearched = useAppSelector(isSearchedApplicantsSelector);

  useEffect(() => {
    dispatch(getTagGroups());
  }, []);
  
  useEffect(() => {
    if (groupTags.length > 0) {
      const disponibleTagNames = groupTags?.map((tag) => tag.name);
      disponibleTagNames.length > 0 && dispatch(setSelectedTags(selectedTags?.filter(tag => disponibleTagNames?.includes(tag))))
    } else {
      dispatch(setSelectedTags([]));
    }
  }, [groupTags, selectedTags.length]);

  useEffect(() => {
    dispatch(setFilteredTags(allTags
      ?.filter((tag) => selectedGroups.includes(tag?.group?.title))));
  }, [allTags, selectedGroups]);

  const handleClear = () => {
    dispatch(clearTags());
    if (isSearched) {
      getSearchedApplicants('clearTags');
    } else {
      getApplicantsByStage('clearTags');
    }
  };

  const handleSubmit = () => {
    if (isSearched) {
      getSearchedApplicants();
    } else {
      getApplicantsByStage();
    }
  }
  
  const generateGroupOptions = () => {
    return groups.map((group) => ({
      label: group?.title,
      key: group?.id,
      className: !selectedGroups.includes(group?.title) ? 'filter-button' : 'filter-button-selected',
      onClick: () => dispatch(setSelectedGroups(group?.title)),
    }));
  }
  
  const generateTagOptions = () => {
    if (groupTags.length === 0) {
      return [{
        label: 'No tags for selected groups.',
        key: 'none-tags',
        className: 'filter-button',
        disabled: true,
      }];
    }
    
    return groupTags.map((tag) => ({
      label: tag.name,
      key: tag.id,
      className: !selectedTags.includes(tag.name) ? 'filter-button' : 'filter-button-selected',
      onClick: () => dispatch(setSelectedTags(tag.name)),
    }));
  }
  
  return (
    <div
      className="options-container"
      style={{
        marginTop: '15px',
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    >
      <FilterNestedDropdown
        classValue={`dropdown left right
            ${
          openTagGroupFilter || selectedGroups.length > 0
            ? 'filter-button-selected'
            : 'filter-button'
        }
          `}
        label={selectedGroups.length > 0 ? `Tag Groups: ${selectedGroups.join(', ')}` : 'Tag Groups'}
        buttons={generateGroupOptions()}
      />
      {selectedGroups.length > 0 && (
        <FilterNestedDropdown
          classValue={`dropdown
            ${selectedTags.length > 0
              ? 'filter-button-selected'
              : 'filter-button'}`}
          label={selectedTags.length > 0 ? `Tags: ${selectedTags.join(', ')}` : 'Tags'}
          buttons={generateTagOptions()}
        />
      )}
      <button
        className="submit"
        onClick={handleSubmit}
      >
        Submit
      </button>
      {(selectedTags.length > 0 || selectedGroups.length > 0) && (
        <button
          className="category-button"
          style={{
            fontSize: '16px',
            fontFamily: '"MagistralCondW08-Medium", sans-serif',
            justifyContent: 'center',
            border: 'none',
          }}
          onClick={handleClear}
        >
          Clear Tags
        </button>
      )}
    </div>
  );
}
