import React, { useEffect, useState } from "react";
import RecruiterContractAnalytics from 'components/contracts-analytics/recruiter/recruiter-contract-analytics';
import RecruiterStats from 'components/contracts-analytics/recruiter/recruiter-stats';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  recruiterContractsLoadingSelector,
  recruiterContractsSelector,
  recruitersSelector,
} from 'store/recruiters/selectors';
import { getRecruiterContracts } from 'store/recruiters/thunk';

export default function RecruiterDashboard(props) {
  const { currentRecruiter, setCurrentRecruiter, color } = props;
  
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  
  const dispatch = useAppDispatch();
  const recruiters = useAppSelector(recruitersSelector);
  const recruiterContracts = useAppSelector(recruiterContractsSelector);
  const loading = useAppSelector(recruiterContractsLoadingSelector);
  
  const [currentContract, setCurrentContract] = useState(null);

  useEffect(() => {
    if (currentRecruiter) {
      dispatch(getRecruiterContracts({ recruiterId: currentRecruiter }));
    }
  }, [currentRecruiter]);
  
  useEffect(() => {
    if (recruiterContracts.length > 0) {
      setCurrentContract(recruiterContracts[0].id);
    } else {
      setCurrentContract(null);
    }
  }, [currentRecruiter]);
  
  const contractOptions = () => {
    return (
      <>
        {recruiterContracts.length > 0 && recruiterContracts.map((contract) => (
          <option key={contract.id} value={contract.id}>
            {contract.title}
          </option>
        ))}
      </>
    );
  };
  
  const recruiterOptions = () => {
    return (
      <>
        {recruiters.length > 0 && recruiters.map((recruiter) => (
          <option key={recruiter.id} value={recruiter.id}>
            {recruiter.first_name} {recruiter.last_name}
          </option>
        ))}
      </>
    );
  };
  
  return (
    <>
      <div className="content-header content-header-flex">
        <div style={{ display: "flex", alignItems: "center" }}>
          {(loggedInUser.role === "admin" || loggedInUser.role === "staffing") && recruiters.length > 0 && (
            <i
              className={`fa-solid fa-angle-left blue-arrow ${color}-arrow analytics-back-button`}
              onClick={() => {
                setCurrentContract(null);
                setCurrentRecruiter(null);
              }}
            />
          )}
          <h1>Recruiter Analytics</h1>
        </div>
        {(loggedInUser.role === "admin" || loggedInUser.role === "staffing") && recruiters.length > 0 && (
          <div className="applicants-hired-per-interval">
            <select
              name="recruiter" id="recruiter"
              className="contract-form-input"
              value={currentRecruiter}
              onChange={(e) => setCurrentRecruiter(e.currentTarget.value)}
            >
              {recruiterOptions()}
            </select>
          </div>
        )}
      </div>
      <div className="metrics-content-container">
        <div className="contract-form-section">
          <div className="contract-form-section-header header-row">
            My Contracts
            {recruiterContracts && (
              <select
                className="contract-form-input"
                style={{ width: "35%" }}
                placeholder="Select Contract"
                value={currentContract}
                onChange={(e) => setCurrentContract(e.currentTarget.value)}
              >
                {contractOptions()}
              </select>
            )}
          </div>
          <div className="contract-form-section-content">
            <RecruiterContractAnalytics
              color={color}
              contract={currentContract}
              recruiter={currentRecruiter}
            />
            {!currentContract && recruiterContracts && (
              <div style={{ fontFamily: "Korolev Medium" }}>Please select a contract.</div>
            )}
            {!currentContract && !recruiterContracts && !loading && (
              <div style={{ fontFamily: "Korolev Medium" }}>Recruiter is not associated with any contracts.</div>
            )}
          </div>
        </div>
        {!loading && (
          <div className="contract-form-section">
            <div className="contract-form-section-header header-row">My Stats</div>
            <div className="contract-form-section-content">
              {<RecruiterStats color={color} recruiter={currentRecruiter} />}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
