import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import fileIcon from "assets/uploaded_file.svg";
import DissociateFile from "components/modal/dissociate-file";
import Toast from "components/toast/toast";
import { fileToUploadType, uploadTypeToFile } from 'shared/applicant-data';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import NewBaseModal from 'components/modal/new-base-modal';
import { EditFileModal } from 'components/modal/edit-file-modal';

export default function ViewFiles(props) {
  const {
    applicantFiles,
    currentUser,
    retrieveFiles,
    view,
    userRole,
    loading,
    templates,
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const [openDissociateModal, setOpenDissociateModal] = useState(false);
  const [openDissociateToast, setOpenDissociateToast] = useState(false);
  const [openEditFileModal, setOpenEditFileModal] = useState(false);
  const [message, setMessage] = useState('');

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const buildAllFilesList = () => {
    if (currentUser === null) {
      return;
    }
    let filesList = [];
    applicantFiles.forEach((file, index) => {
      if (userRole === 'applicant' && file.type.toLowerCase().includes('interview')) {
        return;
      }
      const isNotPublic = templates?.some((item) => item.template_type === file.type && item.is_file_public);
      if (userRole === 'applicant' && !isNotPublic && file.type !== 'resume' && file.type !== 'other') {
        return;
      }
      filesList.push(
        <div
          key={index}
          style={{
            position: 'relative',
            zIndex: 2,
            display: "flex",
            flexDirection: "column",
            width: "125px",
          }}
        >
          {currentUser.role !== "applicant" && (
            <IconButton
              onClick={() => {
                setSelectedFile(file);
                setOpenDissociateModal(true);
              }}
              sx={{
                color: "#EFEFEF",
                justifyContent: "flex-end",
                padding: "0",
                width: "fit-content",
                marginLeft: '101px',
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#a20601",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              setSelectedFile(file);
              setOpenEditFileModal(true);
            }}
            sx={{
              position: 'absolute',
              top: '24px',
              right: '2px',
              zIndex: 2,
              color: '#EFEFEF',
              justifyContent: 'flex-end',
              padding: '0',
              width: 'fit-content',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#60992d',
              },
            }}
          >
            <BorderColorIcon fontSize="small"/>
          </IconButton>
          <IconButton
            onClick={() => openInNewTab("/viewer/" + file.id)}
            sx={{
              display: "flex",
              flexDirection: "column",
              "&:hover": { backgroundColor: "transparent" }
            }}
          >
            <img
              src={fileIcon}
              width="57px"
              height="auto"
              alt="open-file-button"
            />
            <div
              style={{
                color: "#EFEFEF",
                fontFamily: "Korolev Medium",
                fontSize: "0.85rem",
                lineHeight: "1rem",
                marginTop: "8px",
                textAlign: "center",
                width: "100%",
                wordWrap: "break-word",
              }}
            >
              {file.display_name
                ? file.display_name
                : `${uploadTypeToFile[file.type]} v${file.version}`}
            </div>
          </IconButton>
        </div>
      );
    });

    return <>{filesList}</>;
  };

  const buildTypeFilesList = () => {
    if(currentUser === null){
      return;
    }
    let filesList = [];
    let renderFiles = [];
    const fileType = fileToUploadType[view];

    applicantFiles.forEach((file) => {
      if (file.type === fileType) {
        filesList.push(file);
      }
    });

    filesList.forEach((file, index) => {
      renderFiles.push(
        <div
          key={index}
          style={{
            position: 'relative',
            display: "flex",
            flexDirection: "column",
            width: "125px",
          }}
        >
          {currentUser.role !== "applicant" && (
            <IconButton
              onClick={() => {
                setSelectedFile(file);
                setOpenDissociateModal(true);
              }}
              sx={{
                color: "#EFEFEF",
                justifyContent: "flex-end",
                padding: "0",
                width: "fit-content",
                marginLeft: '101px',
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#a20601",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              setSelectedFile(file);
              setOpenEditFileModal(true);
            }}
            sx={{
              position: 'absolute',
              top: '24px',
              right: '2px',
              zIndex: 2,
              color: '#EFEFEF',
              justifyContent: 'flex-end',
              padding: '0',
              width: 'fit-content',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#60992d',
              },
            }}
          >
            <BorderColorIcon fontSize="small"/>
          </IconButton>
          <IconButton
            onClick={() => openInNewTab("/viewer/" + file.id)}
            sx={{
              display: "flex",
              flexDirection: "column",
              "&:hover": { backgroundColor: "transparent" }
            }}
          >
            <img
              src={fileIcon}
              width="57px"
              height="auto"
              alt="open-file-button"
            />
            <div
              style={{
                color: "#EFEFEF",
                fontFamily: "Korolev Medium",
                fontSize: "0.85rem",
                lineHeight: "1rem",
                marginTop: "8px",
                textAlign: "center",
                width: "100%",
                wordWrap: "break-word",
              }}
            >
              {file.display_name
                ? file.display_name
                : `${uploadTypeToFile[file.type]} v${file.version}`}
            </div>
          </IconButton>
        </div>
      );
    });

    return <>{renderFiles}</>;
  };

  return (
    <>
      {loading ? (
        <div className="loading-spinner__content" style={{ height: 'calc(100% - 200px)' }}>
          <ProgressSpinnerBlue />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            maxHeight: "75%",
            overflow: "auto",
            width: "100%",
          }}
        >
          {applicantFiles && view === "all" && buildAllFilesList()}
          {applicantFiles && view !== "all" && buildTypeFilesList()}
          {currentUser === null && <div style={{ fontFamily: "Korolev Medium" }}>Under maintenance</div>}
          <NewBaseModal
            dialogContent={
              <EditFileModal
                name={selectedFile?.display_name}
                type={selectedFile?.type}
                fileId={selectedFile?.id}
                retrieveFiles={retrieveFiles}
                close={() => setOpenEditFileModal(false)}
                setOpenToast={setOpenDissociateToast}
                setMessage={setMessage}
                templates={templates}
                applicantFiles={applicantFiles}
                userRole={userRole}
              />
            }
            onClose={() => setOpenEditFileModal(false)}
            open={openEditFileModal}
            title={'Edit File'}
            colorPalette="blue"
          />
          {openDissociateModal && (
            <DissociateFile
              handleClose={() => setOpenDissociateModal(false)}
              open={openDissociateModal}
              retrieveFiles={retrieveFiles}
              selectedFile={selectedFile}
              setOpenToast={setOpenDissociateToast}
              setMessage={setMessage}
            />
          )}
          {openDissociateToast && (
            <Toast
              open={openDissociateToast}
              onClose={() => setOpenDissociateToast(false)}
              message={message}
            />
          )}
        </div>
      )}
    </>
  );
}
