import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import 'css/sales.scss';

import { numberWithCommas } from 'shared/formatting';

import { SalesQuoteCreatorForm } from 'components/sales/sales-quote-creator-form';
import Toast from 'components/toast/toast';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { HighlightNavyAutoStyledButton } from 'components/buttons/button-styles';
import { SalesQuoteManageModal } from 'components/sales/sales-quote-manage-modal';
import { SalesActiveQuotes } from 'components/sales/sales-active-quotes';

import { createSaleQuote, getSaleQuotes, updateSaleQuote } from 'store/sales/thunk';
import { saleSelector, salesQuotesSelector } from 'store/sales/selectors';
import { openCardsOnSalePortalSelector } from 'store/ui/selectors';
import { setOpenCardsOnSalePortal } from 'store/ui/slice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { SalesQuoteManageTimeModal } from 'components/sales/sales-quote-manage-time-modal';
import API from 'API';
import { quoteCategoriesSelector } from 'store/quotes/selectors';

const initialQuoteForm = {
  service_description: '',
  internal_justification: '',
  cost_to_company: 0,
  profit_margin: 0,
  cost_quoted: 0,
  active: true,
  quote_category_id: null,
  calculated: 'cost_quoted',
  quote_type: 'time_based',
  quantity: 0,
  price_per_unit: 0,
};

const initialErrors = {
  service_description: false,
  internal_justification: false,
  cost_to_company: false,
  profit_margin: false,
  cost_quoted: false,
  server: false,
  calculated: true,
  quantity: false,
  price_per_unit: false,
};

export const SalesQuoteCreator = ({ id }) => {
  const dispatch = useAppDispatch();
  const openCards = useAppSelector(openCardsOnSalePortalSelector);
  const { quotes, loading } = useAppSelector(salesQuotesSelector);
  const { sale } = useAppSelector(saleSelector);
  const categories = useAppSelector(quoteCategoriesSelector);

  const token = JSON.parse(localStorage.getItem('token'));
  const [quoteForm, setQuoteForm] = useState(initialQuoteForm);
  const [isCreating, setIsCreating] = useState(true);
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [openManageModal, setOpenManageModal] = useState(false);
  const [openManageTimeModal, setOpenManageTimeModal] = useState(false);
  const [errorExport, setErrorExport] = useState('');
  
  const [activeQuotes, setActiveQuotes] = useState([]);
  const [removedQuotes, setRemovedQuotes] = useState([]);
  
  useEffect(() => {
    if (quotes.length > 0) {
      setActiveQuotes(quotes.filter((item) => item.active));
      setRemovedQuotes(quotes.filter((item) => !item.active));
    } else {
      setActiveQuotes([]);
      setRemovedQuotes([]);
    }
  }, [quotes]);

  useEffect(() => {
    if (isCreating && categories.length > 0) {
      setQuoteForm({
        ...quoteForm,
        quote_category_id: categories.find(item => item.is_default)?.id,
      });
    }
  }, [categories]);
  
  const getQuotes = () => {
    dispatch(getSaleQuotes({ saleId: id }));
  };
  
  const handleCreate = () => {
    setIsCreating(true);
    setQuoteForm(initialQuoteForm);
    setErrors(initialErrors);
  };
  
  const handleUpdate = () => {
    if (!quoteForm.service_description
      || !quoteForm.internal_justification
      || !quoteForm.cost_to_company
      || !quoteForm.cost_quoted
      || !quoteForm.quantity
      || !quoteForm.price_per_unit
    ) {
      setErrors({
        ...errors,
        service_description: !quoteForm.service_description,
        internal_justification: !quoteForm.internal_justification,
        cost_to_company: !quoteForm.cost_to_company,
        cost_quoted: !quoteForm.cost_quoted,
        quantity: !quoteForm.quantity,
        price_per_unit: !quoteForm.price_per_unit,
      });
    } else {
      setLoadingButton(true);
      let costToCompany;
      if (quoteForm.quote_type === 'time_based' && sale?.total_time_amount) {
        costToCompany = Math.round(quoteForm.quantity * quoteForm.price_per_unit * sale?.total_time_amount * 100) / 100;
      } else {
        costToCompany = Math.round(quoteForm.quantity * quoteForm.price_per_unit * 100) / 100;
      }
      const body = {
        sales_id: id,
        service_description: quoteForm.service_description,
        quote_category_id: quoteForm.quote_category_id,
        internal_justification: quoteForm.internal_justification,
        cost_to_company: costToCompany,
        profit_margin: quoteForm.calculated === 'profit_margin'
          ? Math.round(((quoteForm.cost_quoted / costToCompany) - 1) * 10000) / 100
          : Math.round(quoteForm.profit_margin * 100) / 100,
        cost_quoted: quoteForm.calculated === 'cost_quoted'
          ? Math.round(costToCompany * ((quoteForm.profit_margin / 100) + 1) * 100) / 100
          : Math.round(quoteForm.cost_quoted * 100) / 100,
        active: quoteForm.active,
        quantity: quoteForm.quantity,
        price_per_unit: quoteForm.price_per_unit,
        quote_type: quoteForm.quote_type,
      };
      isCreating ?
        dispatch(createSaleQuote({ body }))
          .then((res) => {
            if (res.meta.requestStatus !== 'fulfilled') {
              setLoadingButton(false);
              setErrors({
                ...errors,
                server: true,
              });
              setOpenToast(true);
            } else {
              setLoadingButton(false);
              getQuotes();
              setQuoteForm(initialQuoteForm);
              setErrors({
                ...errors,
                server: false,
              });
              setIsCreating(true);
            }
          })
        :
        dispatch(updateSaleQuote({ quoteId: quoteForm.id, body }))
          .then((res) => {
              if (res.meta.requestStatus !== 'fulfilled') {
                setLoadingButton(false);
                setErrors({
                  ...errors,
                  server: true,
                });
                setOpenToast(true);
              } else {
                setLoadingButton(false);
                getQuotes();
                setQuoteForm(initialQuoteForm);
                setErrors({
                  ...errors,
                  server: false,
                });
                setIsCreating(true);
              }
            },
          );
    }
  };
  
  const handleQuoteClick = (value) => {
    setIsCreating(false);
    setQuoteForm({
      ...value,
      calculated: 'cost_quoted',
      quote_category_id: value.quote_category?.id,
    });
    setErrors(initialErrors);
  };
  
  const toggleCard = (id) => {
    if (openCards.includes(id)) {
      dispatch(setOpenCardsOnSalePortal(openCards.filter((cardId) => cardId !== id)));
    } else {
      dispatch(setOpenCardsOnSalePortal([...openCards, id]));
    }
  };
  
  const handleDownload = useCallback(async () => {
    try {
      setErrorExport('')
      const response = await fetch(
        `${API.endpointURL}/api/quotes/${id}/exports/pdf`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const blob = await response.blob();
      
      // Create a Blob URL for the blob
      const blobUrl = URL.createObjectURL(blob);
      
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `quote_${id}.pdf`;
      
      // Append the link to the document body
      document.body.appendChild(link);
      
      // Trigger a click on the link to start the download
      link.click();
      
      // Remove the link from the document body
      document.body.removeChild(link);
    } catch (error) {
      setErrorExport('Error downloading file.')
    }
  }, [id]);
  
  return (
    <section style={{ position: 'relative' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1 className="content-header">
          Quote creator
        </h1>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
          <div>
            <HighlightNavyAutoStyledButton
              onClick={() => setOpenManageModal(true)}
            >
              Manage quote categories
            </HighlightNavyAutoStyledButton>
          </div>
          <div>
            <HighlightNavyAutoStyledButton
              onClick={() => setOpenManageTimeModal(true)}
            >
              Manage Time
            </HighlightNavyAutoStyledButton>
          </div>
        </div>
      </div>
      <div
        className="sales__content"
        style={{
          borderTop: '1px solid #EFEFEF80',
          padding: '20px 12px 0 0',
          height: 'calc(100vh - 206px)',
          overflow: 'auto',
        }}
      >
        <div className="sales__quote--left">
          <SalesQuoteCreatorForm
            quoteForm={quoteForm}
            setQuoteForm={setQuoteForm}
            handleUpdate={handleUpdate}
            isCreating={isCreating}
            errors={errors}
            setErrors={setErrors}
            handleCreate={handleCreate}
            loadingButton={loadingButton}
          />
          <div className="sales__quote sales__quote-remove" id="remove">
            <div className="sales__quote__header-container">
              <div className="sales__quote__header">
                <p className="sales__quote--title">Removed Quotes</p>
              </div>
              <IconButton
                edge="start"
                color="inherit"
                sx={{ color: '#fdfefe', padding: 0, width: '25px' }}
                onClick={() => toggleCard('remove')}
              >
                <KeyboardArrowDownIcon
                  sx={{
                    transform: openCards.includes('remove') ? 'rotate(180deg)' : '',
                    transition: 'transform 0.3s ease-in-out',
                  }}
                />
              </IconButton>
            </div>
            <div
              className={
                classNames(
                  'sales__quote--container', {
                    'sales__quote--container--hidden': !openCards.includes('remove'),
                    'sales__quote--container--full': !openCards.includes('form')
                      && openCards.includes('remove'),
                  },
                )
              }
              style={{
                maxHeight: openCards.includes('remove') ? 'unset' : '0px',
                minHeight: openCards.includes('remove') ? 'calc(100vh - 950px)' : '0',
              }}
            >
              <div className="sales__container">
                {removedQuotes?.length > 0 && (
                  <div className="sales__quote--row sales__quote--row-header">
                    <p className="sales__quote--title">Service Description</p>
                    <p className="sales__quote--title">Quoted price</p>
                    <p className="sales__quote--title">Profit margin</p>
                  </div>
                )}
                {loading && openCards.includes('remove') ? (
                  <div className="content-loading">
                    <ProgressSpinnerNavy/>
                  </div>
                ) : removedQuotes?.length > 0 ?
                  removedQuotes.map((quote, index) => (
                    <button
                      className="sales__quote--row"
                      onClick={() => {
                        handleQuoteClick(quote);
                        if (!openCards.includes('form')) {
                          toggleCard('form');
                        }
                      }}
                      key={`${quote.name} ${index}`}
                    >
                      <p>{quote.service_description}</p>
                      <p><span className="sales__dollar_sign">$</span>{numberWithCommas(quote.cost_quoted)}</p>
                      <p>{Math.round(quote.profit_margin * 100) / 100}%</p>
                    </button>
                  ))
                  : <p>No Removed Quotes</p>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="sales__quote--right">

          <div className="sales__quote sales__quote-total">
            <div>
              <p>Total Cost to Company:</p>
              <p>Total Quoted Amount:</p>
              <p>Average Profit Margin:</p>
            </div>
            <div>

              <p>
                <span className="sales__dollar_sign">$</span>
                {numberWithCommas(activeQuotes
                  .reduce((costToCompanySum, value) => {
                    return costToCompanySum + Number(value.cost_to_company);
                  }, 0).toFixed(2), // Display 2 numbers after the dot
                )}
              </p>
              <p>
                <span className="sales__dollar_sign">$</span>
                {numberWithCommas(activeQuotes
                  .reduce((sum, item) => {
                    return sum + Number(item.cost_quoted);
                  }, 0).toFixed(2), // Display 2 numbers after the dot
                )}
              </p>
              <p>
                {
                  // Avoid displaying NaN - check if quotes are empty
                  Object.keys(activeQuotes).length < 1
                    ? 'N/A'
                    : numberWithCommas(Math.round(activeQuotes.reduce((sum, item) => {
                    return sum + Number(item.profit_margin);
                  }, 0) * 100 / activeQuotes.length) / 100) + ' %'
                }
              </p>
            </div>
          </div>
          <div className="sales__quote sales__quote-active" id="active">
            <div className="sales__quote__header-container">
              <div className="sales__quote__header">
                <p className="sales__quote--title">Active Quotes</p>
                {openCards.includes('active') && activeQuotes?.filter((category) => category?.quote_category !== null)?.length > 0 && (
                  <HighlightNavyAutoStyledButton
                    onClick={handleDownload}
                    style={{
                      width: '105px',
                      padding: '2px',
                      height: '37px',
                      fontSize: '18px',
                      lineHeight: '18px',
                      marginLeft: '10px',
                    }}
                  >
                    Export
                  </HighlightNavyAutoStyledButton>
                )}
                {errorExport.length > 0 && (
                  <p
                    className="sales__quote--form-error"
                    style={{ right: '0', top: '-12px' }}
                  >
                    {errorExport}
                  </p>
                )}
              </div>
              <IconButton
                edge="start"
                color="inherit"
                sx={{ color: '#fdfefe', padding: 0, width: '25px' }}
                onClick={() => toggleCard('active')}
              >
                <KeyboardArrowDownIcon
                  sx={{
                    transform: openCards.includes('active') ? 'rotate(180deg)' : '',
                    transition: 'transform 0.3s ease-in-out',
                  }}
                />
              </IconButton>
            </div>
            <SalesActiveQuotes
              activeQuotes={activeQuotes}
              loading={loading}
              handleQuoteClick={handleQuoteClick}
              toggleCard={toggleCard}
              getQuotes={getQuotes}
              handleCreate={handleCreate}
              quotes={quotes}
            />
          </div>
        </div>
      </div>
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={errors.server
            ? 'Something went wrong, try again later...'
            : 'New Quote Successfully Created'
          }
        />
      )}
      {openManageModal && (
        <SalesQuoteManageModal
          open={openManageModal}
          getQuotes={getQuotes}
          onClose={() => setOpenManageModal(false)}
        />
      )}
      {openManageTimeModal && (
        <SalesQuoteManageTimeModal
          open={openManageTimeModal}
          onClose={() => setOpenManageTimeModal(false)}
        />
      )}
    </section>
  );
};
