import React, { useEffect, useRef, useState } from "react";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Box, IconButton, TextField, Typography } from "@mui/material";

import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';
import { ProgressSpinnerNavy } from "components/progress/progress-spinner-navy";

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { projectSelector } from 'store/projects/selectors';
import { setProjectNotes } from 'store/projects/slice';
import { createNote } from 'store/notes/thunk';
import { notesSelector } from 'store/notes/selectors';

export default function ProjectNotes({ id, retrieveNotes }) {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const notesEndRef = useRef(null);
  
  const dispatch = useAppDispatch();
  const { getProjectLoading, getProjectError, projectNotes } = useAppSelector(projectSelector);
  const { createNoteLoading, createNoteError } = useAppSelector(notesSelector);
  
  const [note, setNote] = useState("");
  const [addingNewNote, setAddingNewNote] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false);
  
  useEffect(() => {
    scrollToBottom();
    setNote("");
  }, [projectNotes]);
  
  const handleSubmitNote = () => {
    const body = {
      project_id: id,
      body: note.length > 0 ? note : "",
    };
    
    setBlockLoading(true);
    setAddingNewNote(true);
    
    dispatch(createNote({ body }))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          setNote("");
          retrieveNotes();
          setAddingNewNote(false);
        } else {
          setAddingNewNote(false);
          dispatch(setProjectNotes([]));
        }
      })
  };

  const scrollToBottom = () => {
    notesEndRef.current?.scrollIntoView({ behavior: "smooth", block: 'end' });
  };

  const handleChangeText = (newValue) => {
    setNote(newValue);
  };

  const formatDateTime = (timestamp) => {
    const dateTime = new Date(timestamp);
    return dateTime.toLocaleString();
  };

  const keyPress = (e) => {
    if (e.keyCode === 13 && note.trim() !== '') {
      handleSubmitNote();
    }
  };
  
  const buildComms = () => {
    const textBubbles = projectNotes?.map((message, index) => (
      <Box
        key={index}
        display="flex"
        flexDirection="column"
        sx={{
          marginBottom: "8px",
          textAlign: "left",
          width: "auto",
        }}
      >
        <Typography
          sx={{
            color: "EFEFEF",
            fontSize: "13px",
            marginBottom: "2px",
            paddingLeft: "8px",
            textTransform: "capitalize",
          }}
        >
          {message.admin_first_name === loggedInUser.first_name && "You"}
          {message.admin_first_name &&
            message.admin_last_name !== loggedInUser.last_name &&
            `${message.admin_first_name} ${message.admin_last_name}`}
        </Typography>
        <Typography
          sx={{
            bgcolor: "#EFEFEF40",
            border: 0,
            fontSize: "16px",
            borderRadius: "6px",
            fontFamily: "Korolev Medium",
            color: "EFEFEF",
            padding: "10px 16px",
            overflowWrap: "break-word",
          }}
        >
          {message.body}
        </Typography>
        <Typography
          sx={{
            color: "#EFEFEF",
            fontSize: "13px",
            marginTop: "2px",
            paddingRight: "8px",
            textAlign: "right",
          }}
        >
          {formatDateTime(message.created_at)}
        </Typography>
      </Box>
    ));

    return (
      <Box
        style={{
          boxShadow: 0,
          maxHeight: "450px",
          overflowY: "auto",
        }}
      >
        {textBubbles}
        <div ref={notesEndRef} />
      </Box>
    );
  };

  return (
    <>
      <h1 className="content-header">Notes</h1>
      <div className="content-container">
        {getProjectLoading && getProjectError.length === 0 && !blockLoading &&
        <ProgressSpinnerNavy/>
        }
        {!getProjectLoading && getProjectError.length > 0 &&
        <div style={{marginTop:'20px', marginBottom: '20px'}}>{getProjectError}</div>
        }
        {getProjectError.length === 0 && projectNotes && projectNotes.length > 0 &&
        buildComms()
        }
        {createNoteLoading && (
          <div style={{ position: 'relative'}}>
            <div
              className="contractsTags__tags__modal-loading"
              style={{ top: '-25px', right: '15px' }}
            >
              <ProgressSpinnerDarkNavy/>
            </div>
          </div>
        )}
        <Box
          sx={{
            backgroundColor: "#EFEFEF",
            border: "1px solid #EFEFEF80",
            borderRadius: "8px",
            color: "#171717",
            width: "auto",
            height: "auto",
          }}
        >
          <TextField
            value={note}
            onChange={(e) => handleChangeText(e.currentTarget.value)}
            onKeyDown={keyPress}
            fullWidth
            sx={{
              border: "0",
              padding: "6px 10px 5px",
            }}
            placeholder="Add Note..."
            id="fullWidth"
            multiline={true}
            rows={2}
            variant="standard"
            disabled={addingNewNote}
            InputProps={{
              disableUnderline: true,
            }}
          />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              padding: "5px 10px 10px",
            }}
          >
            <IconButton
              onClick={() => handleSubmitNote()}
              disabled={!note}
              sx={{
                padding: 0,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <ArrowCircleUpIcon
                sx={{
                  color: "26183E",
                  "&:hover": {
                    color: "#531CB3",
                  },
                }}
              />
            </IconButton>
          </Box>
        </Box>
        {createNoteError.length > 0 && <div style={{marginTop:'20px'}}>{createNoteError}</div>}
      </div>
    </>
  );
}
