import { createAsyncThunk } from '@reduxjs/toolkit';
import { tags } from 'services/tags';
export const getTags = createAsyncThunk(
  'tags',
  async (_, { rejectWithValue }) => {
    try {
      return await tags.getAllTags();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getTagTypes = createAsyncThunk(
  'getTypes',
  async (_, { rejectWithValue }) => {
    try {
      return await tags.getTypes();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateTagType = createAsyncThunk(
  'updateType',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      return await tags.updateType(id, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteType = createAsyncThunk(
  'deleteType',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await tags.deleteType(id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createTagType = createAsyncThunk(
  'createType',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await tags.createType(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getTagGroups = createAsyncThunk(
  'getGroup',
  async (_, { rejectWithValue }) => {
    try {
      return await tags.getGroups();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateTagGroup = createAsyncThunk(
  'updateGroup',
  async ({ id, body }, { rejectWithValue }) => {
    try {
      return await tags.updateGroup(id, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createTagGroup = createAsyncThunk(
  'createGroup',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await tags.createGroup(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteGroup = createAsyncThunk(
  'deleteGroup',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await tags.deleteGroup(id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActiveApplicantTags = createAsyncThunk(
  'activeApplicantTags',
  async ({ applicantId }, { rejectWithValue }) => {
    try {
      return await tags.getActiveApplicantTags(applicantId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getAvailableApplicantTags = createAsyncThunk(
  'availableApplicantTags',
  async ({ applicantId }, { rejectWithValue }) => {
    try {
      return await tags.getAvailableApplicantTags(applicantId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const addActiveTagToApplicant = createAsyncThunk(
  'addActiveTagToApplicant',
  async ({ applicantId, body }, { rejectWithValue }) => {
    try {
      return await tags.addActiveTagToApplicant(applicantId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const removeActiveTagFromApplicant = createAsyncThunk(
  'removeActiveTagFromApplicant',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await tags.removeActiveTagFromApplicant(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createTag = createAsyncThunk(
  'createTag',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await tags.createTag(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getTag = createAsyncThunk(
  'getTag',
  async ({ tagId }, { rejectWithValue }) => {
    try {
      return await tags.getTag(tagId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteTag = createAsyncThunk(
  'deleteTag',
  async ({ tagId }, { rejectWithValue }) => {
    try {
      return await tags.deleteTag(tagId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateTag = createAsyncThunk(
  'updateTag',
  async ({ tagId, body }, { rejectWithValue }) => {
    try {
      return await tags.editTag(tagId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const filterTags = createAsyncThunk(
  'filterTags',
  async ({ groupId = '', typeId = '' }, { rejectWithValue }) => {
    try {
      return await tags.filterTags(groupId, typeId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);