import "../../css/application.scss";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import ApplicationCompleted from "../../components/applications/application-completed";
import ApplicationError from "../../components/applications/application-error";
import ApplicationFiles from "../../components/applications/application-files";
import GeneralInfoForm from "../../components/applications/general-info-form";
import ScreeningForm from "../../components/applications/screening-form";
import API from "API";
import { Typography, Box } from "@mui/material";
import ScreeningConsent from "../../components/applications/screeningConsent";

export default function ContractApplication() {
  const { alias } = useParams();

  const token = JSON.parse(localStorage.getItem("token"));

  const [contractId, setContractId] = useState(null);
  const [contractData, setContractData] = useState(null);
  const [loadingContractData, setLoadingContractData] = useState(true);
  const [view, setView] = useState("generalInfoForm");
  const [applicantData, setApplicantData] = useState(null);
  const [applicantAnswers, setApplicantAnswers] = useState({});
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (alias) {
      getContractByAlias();
    }
  }, [alias]);

  useEffect(() => {
    if (contractId) {
      getContractData();
    }
  }, [contractId]);

  const getContractByAlias = async () => {
    await fetch(`${API.endpointURL}/api/applicant/${alias}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setContractId(json.contract_id);
      });
  };

  const getContractData = async () => {
    setLoadingContractData(true);
    if (contractId) {
      await fetch(`${API.endpointURL}/api/show-single-contract?contract_id=${contractId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setContractData(json.data);
          setLoadingContractData(false);
        })
        .catch(() => setLoadingContractData(false))
    }
  };

  if (contractData && contractData.status !== 'active') {
    return (
      <div className="background">
        <img alt='logo' className="rd-logo" src={logo} />
        <Box
          sx={{
            backgroundColor: "#262a25",
            borderRadius: "28px",
            boxShadow: "0px 0px 16px 27px rgba(0, 0, 0, 0.25)",
            margin: "0 auto",
            padding: "32px",
            maxWidth: "700px",
          }}
        >
          <Typography
            sx={{
              color: "#dae0bc",
              fontFamily: "MagistralCondW08-Medium",
              fontSize: "35px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "50px",
              margin: "0 auto 16px",
              textAlign: "center",
              textTransform: "uppercase",
          }}
          >
            THIS APPLICATION IS CLOSED
          </Typography>
          <Typography
            sx={{
              color: "#EFEFEF",
              fontFamily: "Korolev Medium",
              fontSize: "0.95rem",
              fontStyle: "normal",
              fontWeight: "500",
              margin: "5px 0",
            }}
          >
            Thank you for your interest in <b style={{ color: '#a5af96' }}>{contractData.title}</b>!
            We are not currently accepting new applications for this project, but we would love to consider you for other opportunities.
            Please reach out to us at
            <a
              style={{ color: '#a5af96', display: 'inline' }}
              href="mailto:rdmstaffing@responsivedeployment.com"
              target="_blank"
              rel="noreferrer noopener"
            > rdmstaffing@responsivedeployment.com </a>
            for more information or fill out our 
            <a
              style={{ color: '#a5af96', display: 'inline' }}
              href="/general-application"
              target="_blank"
              rel="noreferrer noopener"
            > general application</a>.
          </Typography>
          <Typography
            sx={{
              color: "#a5af96",
              fontFamily: "MagistralCondW08-Medium",
              fontSize: "20px",
              fontWeight: "500",
              margin: "15px 0 8px",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            We look forward to working with you!
          </Typography>
        </Box>
        <div className="copyright">©2023 Responsive Deployment</div>
      </div>
    )
  }


  return (
    <div className="background">
      <img alt='logo' className="rd-logo" src={logo} />
      {view === "generalInfoForm" && (
        <GeneralInfoForm
          loading={loadingContractData}
          applicantData={applicantData}
          nextView={"screeningForm"}
          setApplicantData={setApplicantData}
          setView={setView}
          contractDescription={contractData && contractData.contract_description ? contractData.contract_description : ''}
        />
      )}
      {view === "screeningForm" && (
        <ScreeningForm
          applicantData={applicantData}
          setApplicantData={setApplicantData}
          setView={setView}
          contractId={contractId}
        />
      )}
      {view === "screeningConsent" && (
        <ScreeningConsent
          setView={setView}
          contractId={contractId}
          applicantAnswers={applicantAnswers}
          setApplicantAnswers={setApplicantAnswers}
        />
      )}
      {view === "files" && (
        <ApplicationFiles
          applicantData={applicantData}
          prevView={"screeningConsent"}
          setApplicantData={setApplicantData}
          setView={setView}
          setErrors={setErrors}
          contractId={contractId}
          applicantAnswers={applicantAnswers}
        />
      )}
      {view === "error" && <ApplicationError setView={setView} errors={errors} />}
      {view === "completed" && (
        <ApplicationCompleted
          applicantEmail={applicantData?.email ? applicantData.email : null}
        />
      )}
      <div className="copyright">©2023 Responsive Deployment</div>
    </div>
  );
}
