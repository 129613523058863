export const contractsSelector = (state) => state.contracts.contracts;
export const currentPageSelector = (state) => state.contracts.currentPage;
export const totalPagesSelector = (state) => state.contracts.totalPages;
export const contractsErrorSelector = (state) => state.contracts.contractsError;
export const contractsStatusSelector = (state) => state.contracts.contractsStatus;
export const filteredContractsByStatusSelector = (state) => state.contracts.filteredContractsByStatus;
export const currentContractsTabSelector = (state) => state.contracts.currentContractsTab;
export const contractsLoadingSelector = (state) => state.contracts.isLoading;
export const contractFiltersSelector = (state) => state.contracts.contractFilters;
export const contractTemplatesSelector = (state) => state.contracts.contractTemplates;
export const loadingTemplatesSelector = (state) => state.contracts.loadingTemplates;
export const currentContractTemplateSelector = (state) => state.contracts.currentContractTemplate;
export const contractSelector = (state) => state.contracts.contract;
export const newContractSelector = (state) => state.contracts.newContract;
export const deleteContractLoadingSelector = (state) => state.contracts.deleteContractLoading;
export const deleteContractErrorSelector = (state) => state.contracts.deleteContractError;
export const editContractErrorSelector = (state) => state.contracts.editContractError;
export const selectedContractSelector = (state) => state.contracts.selectedContract;
export const contractActionStatusSelector = (state) => state.contracts.contractActionStatus;
export const customerContractsSelector = (state) => state.contracts.customerContracts;
export const allContractsMetricsSelector = (state) => state.contracts.allContractsMetrics;
export const contractMetricsMetricsSelector = (state) => state.contracts.contractMetrics;
export const contractQuestionsSelector = (state) => state.contracts.contractQuestions;
