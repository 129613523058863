import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';


export const StyledTab = styled(Tab)(() => ({
  borderBottom: "7px solid transparent",
  color: '#efefef',
  fontFamily: 'MagistralCondW08-Medium',
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '31.37px',
  padding: '20px 20px 15px',
  textTransform: 'capitalize',
  '&:hover': {
    background: "#efefef40",
  },
  '&.Mui-selected': {
    background: 'linear-gradient(to top, #62AB3757, #efefef00)',
    borderBottom: "7px solid #efefef",
    color: '#efefef',
  },
}));

export const StyledSalesTab = styled(Tab)(() => ({
  borderBottom: "7px solid transparent",
  color: '#efefef',
  fontFamily: 'MagistralCondW08-Medium',
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '31.37px',
  padding: '20px 20px 15px',
  textTransform: 'capitalize',
  '&:hover': {
    background: "#efefef40",
  },
  '&.Mui-selected': {
    background: 'linear-gradient(to top, #141a66, #efefef00)',
    borderBottom: "7px solid #a4f2f1",
    color: '#efefef',
  },
}));

export const StyledProjectsTab = styled(Tab)(() => ({
  borderBottom: "7px solid transparent",
  color: '#efefef',
  fontFamily: 'MagistralCondW08-Medium',
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '31.37px',
  padding: '20px 20px 15px',
  textTransform: 'capitalize',
  '&:hover': {
    background: "#efefef40",
  },
  '&.Mui-selected': {
    background: 'linear-gradient(to top, #141A66, #efefef00)',
    borderBottom: "7px solid #A4F2F1",
    color: '#efefef',
  },
}));

export const StyledExecutiveTab = styled(Tab)(({ lasttab }) => ({
  borderRight: lasttab === 'true' ? '0' : '1px solid #7a7a7a',
  color: '#fcfcfc',
  fontFamily: 'MagistralCondW08-Medium',
  fontSize: '20px',
  padding: '17.5px 70px',
  '&:hover': {
    backgroundColor: 'rgba(229, 229, 229, .25)',
  },
  '&.Mui-selected': {
    backgroundColor: '#EFEFEF',
    borderBottom: '0',
    color: '#050606',
  },
}));

export const StyledFolderTab = styled(Tab)(({ firsttab, lasttab }) => ({
  background: 'transparent',
  borderBottom: "1px solid #efefef80",
  borderTop: "1px solid #efefef80",
  color: '#efefef',
  fontFamily: 'MagistralCondW08-Medium',
  fontSize: '14px',
  lineHeight: "19px",
  minHeight: '49px',
  textTransform: 'capitalize',
  width: '25%',
  '&:hover': {
    background: "#efefef40",
    color: '#efefef',
  },
  '&.Mui-selected': {
    background: 'linear-gradient(to top, #62AB3757, #efefef00)',
    border: "1px solid #efefef80",
    borderLeft: firsttab === 'true' ? "0" : "1px solid #efefef80",
    borderRight: lasttab === 'true' ? "0" : "1px solid #efefef80",
    color: '#efefef',
  },
}));

export const StyledDropBoxTab = styled(Tab)({
  backgroundColor: '#EFEFEF40',
  borderRadius: '8px',
  color: '#EFEFEF',
  fontFamily: 'Korolev Medium',
  fontSize: '1rem',
  marginRight: '8px',
  textTransform: 'capitalize',
  '&.Mui-selected': {
    backgroundColor: '#62AB37',
    color: '#EFEFEF',
  },
});
