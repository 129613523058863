import React from 'react';
import { StyledProjectsTab } from 'components/tabs/tab-styles';
import Tabs from '@mui/material/Tabs';

export const ProjectTabs = ({
  currentTab,
  setCurrentTab,
  tabs,
}) => {


  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Tabs
      value={currentTab}
      onChange={handleChange}
      variant='scrollable'
      scrollButtons="auto"
      TabIndicatorProps={{
        style: { display: 'none' }
      }}
      TabScrollButtonProps={{
        style: {
          margin: "0 10px",
          width: "10px",
        }
      }}
      sx={{
        marginTop: '-30px',
      }}
    >
      {tabs.map(tab => (
        <StyledProjectsTab label={tab} value={tab} key={tab} />
      ))}
    </Tabs>
  );
};
