import React,{ useState } from 'react';
import API from 'API';

export const VendorGroupingsExpanded = ({
  grouping,
  getGroupings,
}) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const [editingGrouping, setEditingGrouping] = useState(null);
  const [newAccountNumber, setNewAccountNumber] = useState('');
  const [addAccountButton, setAddAccountButton] = useState(true);
  const [error, setError] = useState(false);
  const regex = /^[0-9\b]*$/;
  const isEditing = editingGrouping && editingGrouping.id === grouping.id;

  const updateGrouping = async (id, title, numbers) => {
    await fetch(`${API.endpointURL}/api/update_grouping`, {
      method: "POST",
      body: JSON.stringify({
        grouping_id: id,
        title: title,
        account_numbers: numbers,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(() => {
        getGroupings();
      })
      .catch((e) => {
        console.log(e, 'Error updating grouping');
      });
  };

  const deleteGrouping = async (id) => {
    await fetch(`${API.endpointURL}/api/delete_grouping`, {
      method: "POST",
      body: JSON.stringify({
        grouping_id: id,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(() => {
        getGroupings();
      })
      .catch((e) => {
        console.log(e, 'Error delete grouping');
      });
  };

  const deleteAccountNumber = (number) => {
    setEditingGrouping({
      ...editingGrouping,
      account_numbers:
        editingGrouping.account_numbers.filter((item) => item !== number)
    })
  }


  return (
    <div className="vendor-grouping__expanded">
      {isEditing && (
        <div className="vendor-grouping__container">
          <div className="vendor-grouping__name">
            <p className="vendor-grouping__title">
              Title
            </p>
            <input
              type="text"
              onChange={(e) => {
                setEditingGrouping({
                  ...editingGrouping,
                  title: e.target.value,
                })
              }}
              value={editingGrouping.title}
              className="vendor-grouping__input"
            />
          </div>
          <div className="vendor-grouping__box">
            <p className="vendor-grouping__title">
              Account numbers
            </p>
            <div className="vendor-grouping__accounts">
              <div className="vendor-grouping__accounts--container">
                {editingGrouping.account_numbers.map((number, index) => (
                  <div className="vendor-grouping__account">
                    <span key={`${number * 3} ${index}`}>
                      {number}
                    </span>
                    <button
                      className="vendor-grouping__account--dismiss"
                      onClick={() => {
                        deleteAccountNumber(number)
                      }}
                    >
                      x
                    </button>
                  </div>
                ))}
              </div>
              {addAccountButton ? (
                <button
                  onClick={() => setAddAccountButton(false)}
                  className="vendor-grouping__account--create"
                >
                  Add account
                </button>
              ) : (
                <div className="vendor-grouping__account--number">
                  <input
                    onChange={(e) => {
                      if (regex.test(e.target.value)) {
                        setNewAccountNumber(e.target.value);
                      }
                      setError(false);
                    }}
                    value={newAccountNumber}
                    className="vendor-grouping__input"
                  />{error && (
                  <p className="vendor-grouping__account--number--error">
                    *please enter a five-digit number
                  </p>
                )}
                  <button
                    onClick={() => {
                      if (newAccountNumber.length !== 5) {
                        setError(true)
                      } else {
                        setEditingGrouping({
                          ...editingGrouping,
                          account_numbers: [
                            ...editingGrouping.account_numbers,
                            newAccountNumber
                          ]
                        })
                        setAddAccountButton(true);
                        setNewAccountNumber('');
                      }
                    }}
                    className="vendor-grouping__account--add"
                  >
                    +
                  </button>
                  <button
                    onClick={() => {
                      setAddAccountButton(true);
                      setNewAccountNumber('');
                    }}
                    className="vendor-grouping__account--delete"
                  >
                    -
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="vendor-grouping__buttons">
        {isEditing && (
          <button
            className="vendor-grouping__account--create"
            onClick={() => {
              updateGrouping(
                editingGrouping.id,
                editingGrouping.title,
                editingGrouping.account_numbers,
              );
              setEditingGrouping(null);
            }}
          >
            Update
          </button>
        )}
        <button
          className="vendor-grouping__account--create"
          onClick={
            (e) => {
              e.preventDefault();
              isEditing
                ? setEditingGrouping(null)
                : setEditingGrouping(grouping)
            }
          }
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </button>
        <button
          className="vendor-grouping__account--create"
          onClick={() => deleteGrouping(grouping.id)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};