import React, { useState } from 'react';
import { Box, Checkbox } from '@mui/material';
import { GrayAutoStyledButton } from '../buttons/button-styles';
import Toast from 'components/toast/toast';
import { useAppSelector } from 'hooks/reduxHooks';
import { applicantSelector } from 'store/applicants/selectors';
import { contractTemplatesSelector, loadingTemplatesSelector } from 'store/contracts/selectors';
import ProgressSpinner from 'components/progress/progress-spinner';

export default function FinalizeAutomation(props) {
  const { updateApplicant, setButton } = props;

  const applicantData = useAppSelector(applicantSelector);
  const allTemplates = useAppSelector(contractTemplatesSelector);
  const loadingTemplates = useAppSelector(loadingTemplatesSelector);

  const [openErrorToast, setOpenErrorToast] = useState(false);

  const templates = allTemplates.filter(item => item?.template_type.includes('finalize'));

  return (
    <>
      {loadingTemplates ? (
        <div className="contracts-loading-spinner">
          <ProgressSpinner />
        </div>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          gap="5px"
          justifyContent="space-between"
          marginBottom="5px"
        >
          {templates[0]?.active === true &&
            setButton(
              0,
              'finalize_button_1',
              'finalize_button_1_confirm',
              setOpenErrorToast,
            )}
          {templates[1]?.active === true &&
            setButton(
              1,
              'finalize_button_2',
              'finalize_button_2_confirm',
              setOpenErrorToast,
            )}
          {templates[2]?.active === true &&
            setButton(
              2,
              'finalize_button_3',
              'finalize_button_3_confirm',
              setOpenErrorToast,
            )}
          {templates[3]?.active === true &&
            setButton(
              3,
              'finalize_button_4',
              'finalize_button_4_confirm',
              setOpenErrorToast,
            )}
          {templates[4]?.active === true &&
            setButton(
              4,
              'finalize_button_5',
              'finalize_button_5_confirm',
              setOpenErrorToast,
            )}
        </Box>
      )}
      {applicantData?.application?.sent_to_hr ? (
        <Box>
          <Checkbox
            key={'sentToHrEnabled'}
            defaultChecked
            sx={{
              color: '#EFEFEF',
              '&.Mui-checked': {
                color: '#62AB37',
              },
              '&.Mui-disabled': {
                color: '#EFEFEF40',
              },
            }}
            onClick={() => updateApplicant('sent_to_hr', null)}
          />
          <GrayAutoStyledButton disabled>
            Hired
          </GrayAutoStyledButton>
        </Box>
      ) : (
        <Box>
          <Checkbox
            key={'sentToHrDisabled'}
            disabled
            sx={{
              color: '#EFEFEF',
              '&.Mui-checked': {
                color: '#62AB37',
              },
              '&.Mui-disabled': {
                color: '#EFEFEF40',
              },
            }}
          />
          <GrayAutoStyledButton
            onClick={() =>
              updateApplicant('sent_to_hr', new Date().toISOString())
            }
          >
            Hired
          </GrayAutoStyledButton>
        </Box>
      )}
      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message="There was an issue sending your email. Please try again later."
        />
      )}
    </>
  );
}
