import { createAsyncThunk } from '@reduxjs/toolkit';
import { connections } from 'services/connections';

export const getConnectionsAnalytics = createAsyncThunk(
  'getConnectionsAnalytics',
  async (_, { rejectWithValue }) => {
    try {
      return await connections.getConnectionsAnalytics();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);