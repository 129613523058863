import React, { useEffect, useState } from 'react';
import API from 'API';
import { Select, TextField, MenuItem } from '@mui/material';

const initialBudget = {
  account: '',
  dimensionOne: '',
  dimensionTwo: '',
  dimensionThree: '',
  dimensionFour: '',
}

const initialError = {
  account: false,
  dimensionOne: false,
  dimensionTwo: false,
  dimensionThree: false,
  creatingError: false,
  uploadError: false,
}

export const VendorCreateBudget = ({ getBudgets, close }) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const [dimensionFilters, setDimensionFilters] = useState(null);
  const [error, setError] = useState(initialError)
  const [loading, setLoading] = useState(false);
  const [newBudget, setNewBudget] = useState(initialBudget);
  const [isMassImport, setIsMassImport] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const regex = /^[0-9\b]*$/;

  const createBudget = async () => {
    try {
      const response = await fetch(`${API.endpointURL}/api/create_budget_entry`, {
        method: "POST",
        body: JSON.stringify({
          accountNumber: newBudget.account,
          dimension1: newBudget.dimensionOne,
          dimension2: newBudget.dimensionTwo,
          dimension3: newBudget.dimensionThree,
          dimension4: newBudget.dimensionFour || null,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      await response.json();
      getBudgets();
      close();
    } catch (e) {
      setError({
        ...error,
        creatingError: true,
      });
    }
  };

  const uploadBudgets = async () => {
    const formData = new FormData();

    formData.append("csv", uploadedFile);
    try {
      const response = await fetch(`${API.endpointURL}/api/mass_import`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          Connection: "keep-alive",
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      await response.json();
      getBudgets();
      close();
    } catch (e) {
      setError({
        ...error,
        uploadError: true,
      })
    }

  };

  const getDimensionFilters = () => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/get_dimension_filters`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setDimensionFilters(json);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDimensionFilters();
  }, []);

  const handleSubmit = () => {
    if (isMassImport) {
      if (!uploadedFile) {
        setError({
          ...error,
          uploadError: true,
        });
      } else {
        uploadBudgets();
      }
    } else if (newBudget.account.length !== 5
      || !newBudget.account
      || !newBudget.dimensionOne
      || !newBudget.dimensionTwo
      || !newBudget.dimensionThree
    ) {
      setError({
        account: newBudget.account.length !== 5
          || ! newBudget.account,
        dimensionOne: !newBudget.dimensionOne,
        dimensionTwo: !newBudget.dimensionTwo,
        dimensionThree: !newBudget.dimensionThree,
      })
    } else {
      createBudget();
    }
  }

  return (
    <div className="vendor-budgets-create">
      <div
        className={`
          vendor-budgets-create-tabs 
          ${isMassImport && 'vendor-budgets-create-tabs-import'}
        `}
      >
        <button
          onClick={() => setIsMassImport(false)}
          className={`
            vendor-grouping__modal-submit
            ${isMassImport
              ? 'vendor-grouping__modal-import'
              : 'vendor-grouping__modal-create'
            }
          `}
          disabled={!isMassImport}
        >
          Create budget form
        </button>
        <button
          onClick={() => setIsMassImport(true)}
          className={`
            vendor-grouping__modal-submit
            ${isMassImport
              ? 'vendor-grouping__modal-import'
              : 'vendor-grouping__modal-create'
            }
          `}
          disabled={isMassImport}
        >
          Import budgets
        </button>
      </div>
      {isMassImport ? (
        <>
          {error.uploadError && (
            <p className="vendor-budgets-error--6">
              {uploadedFile ? '* Error uploading budgets' : '* Please select csv file'}
            </p>
          )}
          <label
            htmlFor="csvFileInput"
            className="form-row-label"
            style={{ marginBottom: '20px', alignSelf: 'center' }}
          >
            Select a CSV file:
          </label>
          <input
            type="file"
            id="csvFileInput"
            accept=".csv"
            onChange={(e) => {
              setUploadedFile(e.target.files[0]);
              setError({
                ...error,
                uploadError: false,
              })
            }}
            style={{
              marginBottom: '30px',
              alignSelf: 'center',
              marginLeft: '90px',
            }}
          />
        </>
      ) : (
        <>
          <div className="form-row-label" style={{ textAlign: "left" }}>
            Account number
          </div>
          <TextField
            value={newBudget.account}
            onChange={(e) => {
              if (regex.test(e.target.value)) {
                setNewBudget({
                  ...newBudget,
                  account: e.target.value,
                });
              }
              setError({
                ...error,
                account: false,
                creatingError: false,
              })
            }}
            sx={{
              backgroundColor: "#fff",
              border: "1px solid #a5af96",
              borderRadius: "8px",
              padding: "6px 10px 5px",
              marginBottom: 1,
              width: "100%",
            }}
            placeholder="Account number"
            rows={1}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />
          {error.account && (
            <p className="vendor-budgets-error--1">* enter valid account number</p>
          )}
          {error.dimensionOne && (
            <p className="vendor-budgets-error--2">* required field</p>
          )}
          {error.dimensionTwo && (
            <p className="vendor-budgets-error--3">* required field</p>
          )}
          {error.dimensionThree && (
            <p className="vendor-budgets-error--4">* required field</p>
          )}
          {error.creatingError && (
            <p className="vendor-budgets-error--5">* Error</p>
          )}
          <p className="vendor-budgets-error--5">* Error</p>
          <div className="form-row-label" style={{ textAlign: "left" }}>
            Select dimensions
          </div>
          <div className="vendor-fields">
            <div className="vendor-field">
              <div className="form-row-label" style={{ textAlign: "left" }}>
                BU
              </div>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newBudget.dimensionOne}
                onChange={(event) => {
                  setNewBudget({
                    ...newBudget,
                    dimensionOne: event.target.value,
                  });
                  setError({
                    ...error,
                    dimensionOne: false,
                    creatingError: false,
                  })
                }}
                sx={{
                  backgroundColor: "#fff",
                  border: 0,
                  borderRadius: "8px",
                  textAlign: "left",
                  width: "100%",
                  "& .MuiSelect-outlined.MuiOutlinedInput-input": {
                    paddingTop: '10.8px',
                    paddingBottom: '11px',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #a5af96",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #a5af96",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#a5af96",
                  },
                }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span
                        style={{
                          color: "rgba(0,0,0,0.4)",
                          fontFamily: "Roboto, sans-serif"
                        }}
                      >
                {loading ? 'Loading...' : 'Select code'}
              </span>
                    );
                  }

                  return selected;
                }}
              >
                {dimensionFilters &&
                  dimensionFilters['1 BU'].map((item) => (
                    <MenuItem key={item.name} value={item.code}>
                      {`[${item.code}] - ${item.name}`}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
          </div>
          <div className="vendor-fields">
            <div className="vendor-field">
              <div className="form-row-label" style={{ textAlign: "left" }}>
                GOVT AG
              </div>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newBudget.dimensionTwo}
                onChange={(event) => {
                  setNewBudget({
                    ...newBudget,
                    dimensionTwo: event.target.value,
                  });
                  setError({
                    ...error,
                    dimensionTwo: false,
                    creatingError: false,
                  })
                }}
                sx={{
                  backgroundColor: "#fff",
                  border: 0,
                  borderRadius: "8px",
                  textAlign: "left",
                  width: "100%",
                  "& .MuiSelect-outlined.MuiOutlinedInput-input": {
                    paddingTop: '10.8px',
                    paddingBottom: '11px',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #a5af96",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #a5af96",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#a5af96",
                  },
                }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span
                        style={{
                          color: "rgba(0,0,0,0.4)",
                          fontFamily: "Roboto, sans-serif"
                        }}
                      >
                {loading ? 'Loading...' : 'Select code'}
              </span>
                    );
                  }

                  return selected;
                }}
              >
                {dimensionFilters &&
                  dimensionFilters['2 GOVT AG'].map((item) => (
                    <MenuItem key={item.name} value={item.code}>
                      {`[${item.code}] - ${item.name}`}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
          </div>

          <div className="vendor-fields">
            <div className="vendor-field">
              <div className="form-row-label" style={{ textAlign: "left" }}>
                STATE
              </div>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newBudget.dimensionThree}
                onChange={(event) => {
                  setNewBudget({
                    ...newBudget,
                    dimensionThree: event.target.value,
                  });
                  setError({
                    ...error,
                    dimensionThree: false,
                    creatingError: false,
                  })
                }}
                sx={{
                  backgroundColor: "#fff",
                  border: 0,
                  borderRadius: "8px",
                  textAlign: "left",
                  width: "100%",
                  "& .MuiSelect-outlined.MuiOutlinedInput-input": {
                    paddingTop: '10.8px',
                    paddingBottom: '11px',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #a5af96",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #a5af96",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#a5af96",
                  },
                }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span
                        style={{
                          color: "rgba(0,0,0,0.4)",
                          fontFamily: "Roboto, sans-serif"
                        }}
                      >
                {loading ? 'Loading...' : 'Select code'}
              </span>
                    );
                  }

                  return selected;
                }}
              >
                {dimensionFilters &&
                  dimensionFilters['3 STATE'].map((item) => (
                    <MenuItem key={item.name} value={item.code}>
                      {`[${item.code}] - ${item.name}`}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
          </div>

          <div className="vendor-fields">
            <div className="vendor-field">
              <div className="form-row-label" style={{ textAlign: "left" }}>
                TASK
              </div>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newBudget.dimensionFour}
                onChange={(event) => {
                  setNewBudget({
                    ...newBudget,
                    dimensionFour: event.target.value,
                  });
                  setError({
                    ...error,
                    creatingError: false,
                  })
                }}
                sx={{
                  backgroundColor: "#fff",
                  border: 0,
                  borderRadius: "8px",
                  textAlign: "left",
                  width: "100%",
                  "& .MuiSelect-outlined.MuiOutlinedInput-input": {
                    paddingTop: '10.8px',
                    paddingBottom: '11px',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #a5af96",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #a5af96",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#a5af96",
                  },
                }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <span
                        style={{
                          color: "rgba(0,0,0,0.4)",
                          fontFamily: "Roboto, sans-serif"
                        }}
                      >
                {loading ? 'Loading...' : 'Select code'}
              </span>
                    );
                  }

                  return selected;
                }}
              >
                {dimensionFilters &&
                  dimensionFilters['4 TASK'].map((item) => (
                    <MenuItem key={item.name} value={item.code}>
                      {`[${item.code}] - ${item.name}`}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
          </div>
        </>
      )}

      <button
        onClick={handleSubmit}
        className="vendor-grouping__modal-submit"
        style={{ marginTop: '20px' }}
      >
        {isMassImport ? 'Upload budgets' : 'Create budget'}
      </button>
    </div>
  );
};