export const divisionsSelector = (state) => state.divisions.divisions;
export const divisionsLoadingSelector = (state) => state.divisions.isLoading;
export const divisionsErrorSelector = (state) => state.divisions.divisionsError;
export const divisionsByContractIdSelector = (state) => state.divisions.divisionsByContractId;
export const filterableDivisionsSelector = (state) => state.divisions.filterableDivisions;
export const divisionSelector = (state) => state.divisions.division;
export const selectedDivisionSelector = (state) => state.divisions.selectedDivision;
export const divisionsActionMessageSelector = (state) => state.divisions.divisionsActionMessage;


