import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  salesFilterViewSelector,
  salesSearchInputSelector,
  salesSearchTypeSelector,
  selectedBusinessLinesSelector,
  selectedConnectionsSelector,
  selectedSalesRepsSelector,
} from 'store/salesSelectedOptions/selectors';
import classNames from 'classnames';
import {
  setIsSearchedSales,
  setSalesFilterView,
  setSalesSearchInput,
  setSalesSearchType,
  setSelectedBusinessLines,
  setSelectedConnections,
  setSelectedSalesReps,
} from 'store/salesSelectedOptions/slice';
import {
  openConnectionsFilterSelector,
  openBusinessFilterSelector,
  openSalesRepsFilterSelector,
} from 'store/ui/selectors';
import {
  setOpenBusinessFilter,
  setOpenConnectionsFilter,
  setOpenSalesRepsFilter
} from 'store/ui/slice';
import {
  businessLinesLoadingSelector,
  businessLinesSelector,
  companyConnectionsLoadingSelector,
  companyConnectionsSelector,
  salesRepsLoadingSelector,
  salesRepsSelector,
} from 'store/sales/selectors';
import { Search } from 'components/search-box/search-box';
import { IconButton, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useGetSalesByFilter } from 'hooks/getSalesByFilter';
import { resetCurrentPage } from 'store/sales/slice';

export const SalesFilter = () => {
  const dispatch = useAppDispatch();
  const getSales = useGetSalesByFilter();

  const filterView = useAppSelector(salesFilterViewSelector);

  const businessLines = useAppSelector(businessLinesSelector);
  const openBusiness = useAppSelector(openBusinessFilterSelector);
  const selectedBusinessLine = useAppSelector(selectedBusinessLinesSelector);
  const businessLoading = useAppSelector(businessLinesLoadingSelector);

  const salesReps = useAppSelector(salesRepsSelector);
  const openSalesReps = useAppSelector(openSalesRepsFilterSelector);
  const selectedSalesReps = useAppSelector(selectedSalesRepsSelector);
  const salesRepsLoading = useAppSelector(salesRepsLoadingSelector);

  const companyConnections = useAppSelector(companyConnectionsSelector);
  const openConnections = useAppSelector(openConnectionsFilterSelector);
  const selectedConnections = useAppSelector(selectedConnectionsSelector);
  const connectionsLoading = useAppSelector(companyConnectionsLoadingSelector);

  const searchInput = useAppSelector(salesSearchInputSelector);
  const searchType = useAppSelector(salesSearchTypeSelector);

  const setBusinessLineTitle = () => {
    if (selectedBusinessLine.length > 0) {
      return `Business Lines: ${selectedBusinessLine.map(item => item.name).join(' & ')}`
    }
    return 'Business Line'
  };
  const setSalesRepsTitle = () => {
    if (selectedSalesReps.length > 0) {
      return `Sales Reps: ${selectedSalesReps
        .map(item => item.first_name + ' ' + item.last_name)
        .join(' & ')}`
    }
    return 'Sales Rep'
  };
  const setConnectionsTitle = () => {
    if (selectedConnections.length > 0) {
      return `Primary Company on the Sale: ${selectedConnections
        .map(item => item.full_name)
        .join(' & ')}`
    }
    return 'Primary Company on the Sale'
  };

  return (
    <>
      {filterView === 'search' && (
        <>
          <Search
            sx={{
              padding: 0,
              width: 'calc(100% - 50px)',
              margin: '0 25px',
            }}
          >
            <TextField
              placeholder="Search…"
              autoFocus={true}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  getSales('search');
                  dispatch(setIsSearchedSales(true));
                }
              }}
              value={searchInput}
              sx={{
                width: '100%',
                backgroundColor: 'rgba(239,239,239,0.02)',
                borderRadius: '4px',
                color: '#EFEFEF',
                fontFamily: 'Korolev Medium',
                input: {
                  width: '100%',
                  color: '#EFEFEF',
                  fontFamily: 'Korolev Medium',
                  '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 1000px #3e453d inset',
                    WebkitTextFillColor: '#EFEFEF',
                  },
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#EFEFEF80',
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'rgba(239,239,239,0.32)',
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>
                    <IconButton
                      onClick={() => {
                        getSales('search');
                        dispatch(setIsSearchedSales(true));
                      }}
                    >
                      <SearchIcon sx={{ color: '#EFEFEF' }}/>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                dispatch(setSalesSearchInput(e.target.value));
              }}
            />
          </Search>
          <div
            className="clear-button"
            style={{ paddingRight: '31px' }}
            onClick={() => {
              getSales('clear');
              dispatch(setSalesSearchInput(''));
            }}
          >
            Clear
          </div>
        </>
      )}
      <div className="filter">
        <div className="filter__header">
          <button
            className={classNames(
              'filter__option',
              {
                'filter__option--active': filterView === 'filters'
              }
            )}
            onClick={() => {
              dispatch(setSalesFilterView('filters'));
              dispatch(resetCurrentPage());
            }}
          >
            Filters
          </button>
          <button
            className={classNames(
              'filter__option',
              {
                'filter__option--active': filterView === 'search'
              }
            )}
            onClick={() => {
              dispatch(setSalesFilterView('search'));
              dispatch(resetCurrentPage());
            }}
          >
            Search
          </button>
        </div>
        {filterView === 'filters' && (
          <>
            <button
              className="filter__button filter__button--main"
              onClick={() => {dispatch(setOpenBusinessFilter(!openBusiness))}}
            >
            <span>
              {setBusinessLineTitle()}
            </span>
              <i
                className={
                  openBusiness
                    ? 'fa-solid fa-angle-down navy-arrow'
                    : 'fa-solid fa-angle-right navy-arrow'
                }
              />
            </button>
            {openBusiness && !businessLoading && businessLines.length === 0 && (
              <button
                className="filter__button"
                disabled
              >
                No Business Lines yet
              </button>
            )}
            {openBusiness && businessLoading && (
              <button
                className="filter__button"
                disabled
              >
                Loading...
              </button>
            )}
            {openBusiness && !businessLoading && businessLines.map((item) => (
              <button
                className={classNames('filter__button', {
                  'filter__button--active': selectedBusinessLine.some(filter => filter.id === item.id),
                })}
                onClick={() => {
                  dispatch(setIsSearchedSales(false));
                  dispatch(setSelectedBusinessLines(item));
                  dispatch(setSalesSearchInput(''));
                  dispatch(resetCurrentPage());
                }}
                key={item.id + item.name}
              >
                {item.name}
              </button>
            ))}

            <button
              className="filter__button filter__button--main"
              onClick={() => {dispatch(setOpenSalesRepsFilter(!openSalesReps))}}
            >
            <span>
              {setSalesRepsTitle()}
            </span>
              <i
                className={
                  openSalesReps
                    ? 'fa-solid fa-angle-down navy-arrow'
                    : 'fa-solid fa-angle-right navy-arrow'
                }
              />
            </button>
            {openSalesReps && !salesRepsLoading && salesReps.length === 0 && (
              <button
                className="filter__button"
                disabled
              >
                No Sales Reps yet
              </button>
            )}
            {openSalesReps && salesRepsLoading && (
              <button
                className="filter__button"
                disabled
              >
                Loading...
              </button>
            )}
            {openSalesReps && !salesRepsLoading && salesReps.map((item) => (
              <button
                className={classNames('filter__button', {
                  'filter__button--active': selectedSalesReps.some(filter => filter.id === item.id),
                })}
                onClick={() => {
                  dispatch(setIsSearchedSales(false));
                  dispatch(setSelectedSalesReps(item));
                  dispatch(setSalesSearchInput(''));
                  dispatch(resetCurrentPage());
                }}
                key={item.id}
              >
                {item.first_name} {item.last_name}
              </button>
            ))}

            <button
              className="filter__button filter__button--main"
              onClick={() => {dispatch(setOpenConnectionsFilter(!openConnections))}}
            >
            <span>
              {setConnectionsTitle()}
            </span>
              <i
                className={
                  openConnections
                    ? 'fa-solid fa-angle-down navy-arrow'
                    : 'fa-solid fa-angle-right navy-arrow'
                }
              />
            </button>
            {openConnections && !connectionsLoading && companyConnections.length === 0 && (
              <button
                className="filter__button"
                disabled
              >
                No Sales Reps yet
              </button>
            )}
            {openConnections && connectionsLoading && (
              <button
                className="filter__button"
                disabled
              >
                Loading...
              </button>
            )}
            {openConnections && !connectionsLoading && companyConnections.map((item) => (
              <button
                className={classNames('filter__button', {
                  'filter__button--active': selectedConnections.some(filter => filter.id === item.id),
                })}
                onClick={() => {
                  dispatch(setIsSearchedSales(false));
                  dispatch(setSelectedConnections(item));
                  dispatch(setSalesSearchInput(''));
                  dispatch(resetCurrentPage());
                }}
                key={item.id}
              >
                {item.full_name}
              </button>
            ))}
          </>
        )}
        {filterView === 'search' && (
          <>
            <button
              className={classNames('filter__button', {
                'filter__button--active': searchType === 'opportunity_name',
              })}
              onClick={() => dispatch(setSalesSearchType('opportunity_name'))}
            >
              Opportunity Name
            </button>
            <button
              className={classNames('filter__button', {
                'filter__button--active': searchType === 'agency',
              })}
              onClick={() => dispatch(setSalesSearchType('agency'))}
            >
              Agency
            </button>
          </>
        )}
      </div>
    </>
  );
};
