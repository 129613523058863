// use it when we are updating info inside forms
export const setRequestBody = (prevState, currentState) => {
  const changedFields = {};

  // check if we change field, and add only changed fields to request body
  Object.keys(currentState)?.forEach((key) => {
    if (currentState[key] !== prevState[key]) {
      if (prevState[key] === null && currentState[key] === '') return;
      changedFields[key] = currentState[key];
    }
  });

  // request body object with changed fields only
  return changedFields;
};
