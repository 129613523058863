import React, { useState } from 'react';

import '../../css/modal.scss';
import { Box, Button, TextField } from '@mui/material';

import CustomDialog from './custom-dialog';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { editApplicant } from 'store/applicants/thunk';
import { applicantErrorSelector, applicantLoadingSelector } from 'store/applicants/selectors';

export default function EditApplicantContacts(props) {
  const { applicant, getApplicant, handleClose, open, setOpenToast } = props;
  const dispatch = useAppDispatch();
  const error = useAppSelector(applicantErrorSelector);
  const loading = useAppSelector(applicantLoadingSelector);
  
  const [primaryContactName, setPrimaryContactName] = useState(
    applicant.application?.ec_primary_name
      ? applicant.application.ec_primary_name
      : null,
  );
  const [primaryContactRelationship, setPrimaryContactRelationship] = useState(
    applicant.application?.ec_primary_relation
      ? applicant.application.ec_primary_relation
      : null,
  );
  const [primaryContactPhone, setPrimaryContactPhone] = useState(
    applicant.application?.ec_primary_phone
      ? applicant.application.ec_primary_phone
      : null,
  );
  const [secondaryContactName, setSecondaryContactName] = useState(
    applicant.application?.ec_secondary_name
      ? applicant.application.ec_secondary_name
      : null,
  );
  const [secondaryContactRelationship, setSecondaryContactRelationship] =
    useState(
      applicant.application?.ec_secondary_relation
        ? applicant.application.ec_secondary_relation
        : null,
    );
  const [secondaryContactPhone, setSecondaryContactPhone] = useState(
    applicant.application?.ec_secondary_phone
      ? applicant.application.ec_secondary_phone
      : null,
  );
  
  const editUser = async () => {
    const body = {
      ec_primary_name: primaryContactName,
      ec_primary_relation: primaryContactRelationship,
      ec_primary_phone: primaryContactPhone,
      ec_secondary_name: secondaryContactName,
      ec_secondary_relation: secondaryContactRelationship,
      ec_secondary_phone: secondaryContactPhone,
    };
    dispatch(editApplicant({ applicantId: applicant.application.id, body }))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          getApplicant();
          handleClose();
          setOpenToast(true);
        }
      });
  };
  
  const editContactsForm = () => {
    return (
      <React.Fragment>
        <div
          className="form-row-label"
          style={{
            color: '#a5af96',
            fontSize: '20px',
            marginTop: 0,
          }}
        >
          Primary Emergency Contact
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: '48%' }}>
            <div style={{ textAlign: 'left' }} className="form-row-label">
              Name
            </div>
            <TextField
              fullWidth
              id="edit-primary-name"
              key="edit-primary-name"
              placeholder={`Contact's Name`}
              variant="outlined"
              margin="dense"
              required
              value={primaryContactName}
              onChange={(e) => setPrimaryContactName(e.currentTarget.value)}
            />
          </Box>
          <Box sx={{ width: '48%' }}>
            <div style={{ textAlign: 'left' }} className="form-row-label">
              Relationship
            </div>
            <TextField
              fullWidth
              id="edit-primary-relation"
              key="edit-primary-relation"
              placeholder={`Contact's Relationship`}
              variant="outlined"
              margin="dense"
              required
              value={primaryContactRelationship}
              onChange={(e) =>
                setPrimaryContactRelationship(e.currentTarget.value)
              }
            />
          </Box>
          <Box sx={{ width: '48%' }}>
            <div style={{ textAlign: 'left' }} className="form-row-label">
              Phone Number
            </div>
            <TextField
              fullWidth
              id="edit-primary-phone"
              key="edit-primary-phone"
              placeholder={`Contact's Phone Number`}
              variant="outlined"
              margin="dense"
              required
              value={primaryContactPhone}
              onChange={(e) => setPrimaryContactPhone(e.currentTarget.value)}
            />
          </Box>
        </Box>
        <div
          className="form-row-label"
          style={{
            color: '#a5af96',
            fontSize: '20px',
            marginTop: '20px',
          }}
        >
          Secondary Emergency Contact
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: '48%' }}>
            <div style={{ textAlign: 'left' }} className="form-row-label">
              Name
            </div>
            <TextField
              fullWidth
              id="edit-secondary-name"
              key="edit-secondary-name"
              placeholder={`Contact's Name`}
              variant="outlined"
              margin="dense"
              required
              value={secondaryContactName}
              onChange={(e) => setSecondaryContactName(e.currentTarget.value)}
            />
          </Box>
          <Box sx={{ width: '48%' }}>
            <div style={{ textAlign: 'left' }} className="form-row-label">
              Relationship
            </div>
            <TextField
              fullWidth
              id="edit-secondary-relation"
              key="edit-secondary-relation"
              placeholder={`Contact's Relationship`}
              variant="outlined"
              margin="dense"
              required
              value={secondaryContactRelationship}
              onChange={(e) =>
                setSecondaryContactRelationship(e.currentTarget.value)
              }
            />
          </Box>
          <Box sx={{ width: '48%' }}>
            <div style={{ textAlign: 'left' }} className="form-row-label">
              Phone Number
            </div>
            <TextField
              fullWidth
              id="edit-secondary-phone"
              key="edit-secondary-phone"
              placeholder={`Contact's Phone Number`}
              variant="outlined"
              margin="dense"
              required
              value={secondaryContactPhone}
              onChange={(e) => setSecondaryContactPhone(e.currentTarget.value)}
            />
          </Box>
        </Box>
        {error && <div className="add-user-error">{error}</div>}
        <div className="button-container" style={{ overflow: 'hidden', paddingTop: '5px' }}>
          <Button
            variant="contained"
            className="add-user-button"
            sx={{
              backgroundColor: '#556155',
              color: '#EFEFEF',
              fontSize: '18px',
              width: '175px',
              '&:hover': { backgroundColor: '#a5af96', color: '#3e453d' },
            }}
            onClick={() => editUser()}
          >
            Edit
            {loading && (
              <div style={{ position: 'absolute', left: -55 }}>
                <ProgressSpinnerBlue/>
              </div>
            )}
          </Button>
        </div>
      </React.Fragment>
    );
  };
  
  return (
    <CustomDialog
      dialogContent={editContactsForm()}
      onClose={handleClose}
      open={open}
      title={'EDIT'}
      titleHighlight={'CONTACTS'}
    />
  );
}
