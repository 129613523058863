import React from "react";

export default function Tag(props) {
  const { selectedTag, setSelectedTag, tag } = props;

  return (
    <tr
      className={`ss-content ${selectedTag?.id === tag?.id ? "active-tag" : ""}`}
      key={tag?.id}
      onClick={() => {
        setSelectedTag(tag);
      }}
    >
      <td
        style={{
          backgroundColor: tag?.group?.color,
          width: "5px",
          height: '100%',
        }}
      ></td>
      <td
        style={{
          backgroundColor: "transparent",
          width: "100%",
        }}
      >
        {tag?.group?.title}
      </td>
      <td>{tag?.name}</td>
      <td>{tag?.type?.title}</td>
      <td>{tag?.description}</td>
    </tr>
  );
}
