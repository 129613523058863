export const archivedApplicantsSelector = (state) => state.applicants.archivedApplicants;
export const archivedApplicantsPageSelector = (state) => state.applicants.archivedApplicantsPage;
export const totalArchivedApplicantsPagesSelector = (state) => state.applicants.totalApplicantsByStagePages;
export const selectedArchivedApplicantSelector = (state) => state.applicants.selectedArchivedApplicant;
export const archivedApplicantsLoadingSelector = (state) => state.applicants.isLoading;
export const applicantsByStageSelector = (state) => state.applicants.applicantsByStage;
export const totalApplicantsByStageSelector = (state) => state.applicants.totalApplicantsByStage;
export const totalApplicantsByStagePagesSelector = (state) => state.applicants.totalApplicantsByStagePages;
export const currentApplicantSelector = (state) => state.applicants.currentApplicant;
export const applicantSelector = (state) => state.applicants.applicantState;
export const applicantsLoadingSelector = (state) => state.applicants.isLoading;
export const applicantsErrorSelector = (state) => state.applicants.error;
export const applicantErrorSelector = (state) => state.applicants.applicantError;
export const applicantLoadingSelector = (state) => state.applicants.applicantLoading;
export const applicantEditStatusSelector = (state) => state.applicants.applicantEditStatus;
export const applicantQuestionsSelector = (state) => state.applicants.applicantQuestions;
export const editedApplicantIdSelector = (state) => state.applicants.editedApplicantId;
