import React, { useEffect } from 'react';

import "../../css/contracts.scss";
import ContractGeneralInfoForm from "./contract-general-info-form";
import DivisionsForm from "./divisions-form";
import SubdivisionsForm from "./subdivisions-form";
import SubdivisionDetailsForm from "./subdivision-details-form";
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { newContractSelector } from 'store/contracts/selectors';
import { selectedDivisionSelector } from 'store/divisions/selectors';
import { selectedSubdivisionSelector } from 'store/subdivisions/selectors';
import { setSelectedSubdivision } from 'store/subdivisions/slice';
import { setSelectedDivision } from 'store/divisions/slice';

export default function CreateNewContract({ getContracts }) {
  const dispatch = useAppDispatch();
  const newContract = useAppSelector(newContractSelector);
  const selectedDivision = useAppSelector(selectedDivisionSelector);
  const selectedSubdivision = useAppSelector(selectedSubdivisionSelector);
  
  useEffect(() => {
    if (newContract) {
      dispatch(setSelectedSubdivision(null));
      dispatch(setSelectedDivision(null));
    }
  }, [newContract]);
  
  return (
    <>
      <h1 className="content-header">Create New Contract</h1>
      <div className="content-container">
        <ContractGeneralInfoForm
          currentContract={newContract}
          getContracts={getContracts}
        />
        {newContract && (<DivisionsForm />)}
        {newContract && selectedDivision && (<SubdivisionsForm />)}
        {newContract && selectedDivision && selectedSubdivision && (
          <SubdivisionDetailsForm />
        )}
      </div>
    </>
  );
};
