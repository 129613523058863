import React, { useState, useEffect } from 'react'
import { TextField, Select, MenuItem } from '@mui/material';
import API from "API";
import { useParams } from 'react-router-dom';
import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';


export const ProjectsUploadModal = ({ refreshFiles, setOpenToast, setMessage }) => {
  let { id } = useParams();
  const [file, setFile] = useState(null);
  const [fileDisplayName, setFileDisplayName] = useState('');
  const [fileType, setFileType] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dropboxUploadKey, setDropboxUploadKey] = useState(Date.now());
  const [fileTypes, setFileTypes] = useState([]);
  const token = JSON.parse(localStorage.getItem("token"));

  const uploadFile = () => {
    if (!file || !fileDisplayName || !fileType) {
      setError("Please select a file and provide a file name and type.");
    } else {
      setLoading(true);
      const formData = new FormData();

      formData.append("display_name", fileDisplayName);
      formData.append("file", file);
      formData.append("type_id", fileType);
      formData.append("project_id", id);

      fetch(`${API.endpointURL}/api/projects_files/upload`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          Connection: "keep-alive",
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then(() => {
          setLoading(false);
          setError(null);
          setFile(null);
          setFileDisplayName('');
          setFileType("");
          setDropboxUploadKey(Date.now());
          setOpenToast(true);
          setMessage('File successfully added!');

          // Refresh files list
          refreshFiles();
        })
        .catch(() => {
          setLoading(false);
          setError("Please select a file and try again.");
        });
    }
  };

  const getAvailableFileTypes = async () => {
    try {
      const response = await fetch(`${API.endpointURL}/api/projects_files/types/list-project-types?project_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          }
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch available file types. HTTP status: ${response.status}`);
      }
      const availableFileTypes = await response.json();
      setFileTypes(availableFileTypes['data']);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // fetching all available document file types for upload
  useEffect(() => {
    getAvailableFileTypes();
  }, []);

  const renderFileTypeOptions = () => {
    let options = [];

    options.push(
      <MenuItem key="select-option" value="">Select Document Type...</MenuItem>
    );

    if(fileTypes.length > 0){
      for (let i = 0; i < fileTypes.length; i++){
        options.push(
          <MenuItem key={fileTypes[i].id} value={fileTypes[i].id}>
            {fileTypes[i].type_name
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
          </MenuItem>
        );
      }
    }

    return options;
  };

  return (
    <div
      style={{
        maxHeight: "100%",
        overflow: "auto",
        width: "100%",
      }}
    >
      {loading ? (
        <div className="contracts-loading-spinner" style={{ height: '318px' }}>
          <ProgressSpinnerDarkNavy />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <div className="contract-form-set-full">
              <div className="form-row-label">Document Name</div>
              <TextField
                value={fileDisplayName}
                onChange={(e) => setFileDisplayName(e.target.value)}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  border: "1px solid #a5af96",
                  borderRadius: "8px",
                  padding: "6px 10px 5px",
                  marginBottom: 0,
                }}
                placeholder="Enter Document Type..."
                key={`file-name-${dropboxUploadKey}`}
                multiline={true}
                rows={1}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div className="contract-form-set-full">
              <div className="form-row-label">Document Type</div>
              <Select
                key={`file-type-${dropboxUploadKey}`}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fileType}
                onChange={(e)=> setFileType(e.target.value)}
                sx={{
                  backgroundColor: "#fff",
                  border: 0,
                  borderRadius: "8px",
                  textAlign: "left",
                  width: "100%",
                  "& .MuiSelect-outlined.MuiOutlinedInput-input": {
                    paddingTop: '10.8px',
                    paddingBottom: '11px',
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #a5af96",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #a5af96",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#a5af96",
                  },
                }}
                displayEmpty

              >
                {renderFileTypeOptions()}
              </Select>

            </div>
            <div className="belles-file-upload">
              <input
                key={`selected-file-${dropboxUploadKey}`}
                type="file"
                name="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
          </div>
          {error && (
            <div
              style={{
                color: "#a20601",
                fontFamily: "Korolev Medium",
                fontSize: "14px",
                marginBottom: "16px",
                textAlign: "left",
              }}
            >
              {error}
            </div>
          )}
          <div
            style={{
              textAlign: "left",
              width: "100%",
            }}
          >

            <button
              className="create-button"
              style={{
                marginRight: "14px",
                backgroundColor: '#141A66',
                borderColor: '#EFEFEF80'
            }}
              onClick={uploadFile}
            >
              Upload
            </button>

          </div>
        </>
      )}
    </div>
  );
}
