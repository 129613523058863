import "../css/pages.scss";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppBar from "../components/app-bar/updated-app-bar-component";
import SidebarPreview from "../components/user-portal/sidebar-preview";
import PersonalData from "../components/user-portal/personal-data";
import EmployeeAlerts from "components/alerts/employee-alerts";
import API from "API";
import { EmailTemplates } from 'components/employee-portal/email-templates/email-templates';
import AllContractsAnalytics from 'components/contracts-analytics/allContractsAnalytics';
import { ExecutiveAnalytics } from 'components/employee-portal/executive-analytics/executive-analytics';


export default function EmployeePortal() {
  const { id } = useParams();
  const token = JSON.parse(localStorage.getItem("token"));

  const [employee, setEmployee] = useState(null);
  const [view, setView] = useState(null);
  const [currentTab, setCurrentTab] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    if (loggedInUser.role === "admin") {
      setView("admin");
      getUser();
      setCurrentTab("personal information");
    } else if (loggedInUser.role !== "admin" && loggedInUser.role !== "applicant" && loggedInUser.id.toString() === id) {
      setView("applicant");
      getUser();
      setCurrentTab("personal information");
    } else {
      setView("permission error");
    }
  }, [id]);

  const getUser = () => {
    fetch(`${API.endpointURL}/api/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["errors"]) {
          setError(data["error"]);
        } else {
          setEmployee(data.data);
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <>
      <AppBar
        colorPalette="blue"
        menu={true}
        title="My Dashboard"
      />
      <div className="accounting-page">
        {(view === "admin" || view === "applicant") && employee && (
          <>
            <div className="sidebar-content">
              <SidebarPreview
                currentTab={currentTab}
                portalType={"employee"}
                setCurrentTab={setCurrentTab}
                user={employee}
                view={view}
              />
            </div>
            <div className="page-content">
              {currentTab === "personal information" && (
                <PersonalData
                  applicant={employee}
                  getApplicant={getUser}
                  portalType={"employee"}
                  view={view}
                />
              )}
              {currentTab === "alerts" && <EmployeeAlerts />}
              {currentTab === "analytics" && (
                <AllContractsAnalytics color={'blue'} />
              )}
              {currentTab === "executive analytics" && (
                <ExecutiveAnalytics />
              )}
              {currentTab === "my email templates" && <EmailTemplates />}
            </div>
          </>
        )}
        {view === "permission error" && (
          <div style={{ color: "white" }}>Permission denied</div>
        )}
      </div>
    </>
  );
};
