import React, { useEffect, useState } from "react";
import "../../css/vendor-dropbox.scss";
import API from "API";
import VendorFilesSidebar from "components/vendor-dropbox/vendor-files-sidebar";
import ViewVendorFiles from "components/vendor-dropbox/view-vendor-files";
import {ProgressSpinnerBlue} from "../progress/progress-spinner-blue";

const VendorDropBoxPageContent = (props) => {
  const { vendorId } = props;

  const token = JSON.parse(localStorage.getItem("token"));

  const [view, setView] = useState("All");
  const [vendorFiles, setVendorFiles] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    retrieveFiles();
  }, []);

  const retrieveFiles = () => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/vendor_files?vendor_id=${vendorId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setVendorFiles(json.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <>
      <h1 className="content-header">Dropbox</h1>
      <div className="dropbox-content-container">
        <VendorFilesSidebar view={view} setView={setView} />
        {loading ? (
          <div style={{ margin: "auto" }}>
            <ProgressSpinnerBlue />
          </div>
        ) : (
          <ViewVendorFiles
            vendor={props.vendor}
            view={view}
            setView={setView}
            vendorFiles={vendorFiles}
            retrieveFiles={retrieveFiles}
          />
        )}
      </div>
    </>
  );
};

export default VendorDropBoxPageContent;
