import React, { useEffect, useState } from "react";
import API from "API";
import AccountingDashboard from "../employee-portal/accounting-dashboard/accounting-dashboard";
import RecruiterDashboard from 'components/contracts-analytics/recruiter/recruiter-dashboard';


export default function VendorsAnalyticsDashboard() {

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const token = JSON.parse(localStorage.getItem("token"));

  const [view, setView] = useState("");
  const [currentRecruiter, setCurrentRecruiter] = useState(null);
  const [recruiters, setRecruiters] = useState(null);

  useEffect(() => {
    if (loggedInUser.role === "admin" || loggedInUser.role === "staffing" || loggedInUser.role === "accounting") {
      setView("staffing");
      getAllRecruiters();
    } else {
      setView("recruiter");
      setCurrentRecruiter(loggedInUser.id);
    }
  }, []);

  const getAllRecruiters = () => {
    fetch(`${API.endpointURL}/api/get_recruiters`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setRecruiters(json);
      });
  };

  return (
    <>
      {view === "staffing" && (
        <AccountingDashboard />
      )}
      {view === "recruiter" && (
        <RecruiterDashboard
          color={'blue'}
          currentRecruiter={currentRecruiter}
          recruiters={recruiters}
          setCurrentRecruiter={setCurrentRecruiter}
        />
      )}
    </>
  );
};
