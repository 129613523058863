import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

import Toast from '../toast/toast';
import { useAppSelector } from 'hooks/reduxHooks';
import { contractTemplatesSelector, loadingTemplatesSelector } from 'store/contracts/selectors';
import ProgressSpinner from 'components/progress/progress-spinner';

export default function PreScreeningAutomation(props) {
  const {
    setButton,
  } = props;
  
  const allTemplates = useAppSelector(contractTemplatesSelector);
  const loadingTemplates = useAppSelector(loadingTemplatesSelector);
  const [openEmailToast, setOpenEmailToast] = useState(false);
  const [openErrorToast, setOpenErrorToast] = useState(false);

  const templates = allTemplates.filter(item => item?.template_type.includes('pre_screening'));

  return (
    <>
      <Typography
        sx={{
          color: '#EFEFEF80',
          fontFamily: 'MagistralCondW08-Medium',
          marginBottom: '12px',
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        Pre-Screen Materials
      </Typography>
      {loadingTemplates ? (
        <div className="contracts-loading-spinner">
          <ProgressSpinner />
        </div>
      ) : (
        <Box display="flex" flexDirection="column" gap="5px" justifyContent="space-between">
          {templates[0]?.active === true &&
            setButton(
              0,
              'pre_screen_button_1',
              'pre_screen_button_1_confirm',
              setOpenErrorToast,
            )}
          {templates[1]?.active === true &&
            setButton(
              1,
              'pre_screen_button_2',
              'pre_screen_button_2_confirm',
              setOpenErrorToast,
            )}
          {templates[2]?.active === true &&
            setButton(
              2,
              'pre_screen_button_3',
              'pre_screen_button_3_confirm',
              setOpenErrorToast,
            )}
          {templates[3]?.active === true &&
            setButton(
              3,
              'pre_screen_button_4',
              'pre_screen_button_4_confirm',
              setOpenErrorToast,
            )}
          {templates[4]?.active === true &&
            setButton(
              4,
              'pre_screen_button_5',
              'pre_screen_button_5_confirm',
              setOpenErrorToast,
            )}
        </Box>
      )}

      {openEmailToast && (
        <Toast
          open={openEmailToast}
          onClose={() => setOpenEmailToast(false)}
          message="Email sent"
        />
      )}
      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message="There was an issue sending your email. Please try again later."
        />
      )}
    </>
  );
}
