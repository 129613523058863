export const referralTypesArray = [
  { value: 'Indeed' },
  { value: 'LinkedIn' },
  { value: 'Company Website' },
  { value: 'Flyer/Door hang' },
  { value: 'Other social media' },
  { value: 'In-Person Hiring Event' },
  { value: 'Referral' },
  { value: 'Other' },
]
