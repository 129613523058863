import React, { useEffect, useState } from 'react';

import 'css/analytics.scss';
import 'css/pages.scss';
import 'css/metrics.scss';

import FillBarSolid from 'components/widgets/fill-bar-solid';

import API from 'API';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';
import classNames from 'classnames';

export const ExecutiveContracts = () => {

  const token = JSON.parse(localStorage.getItem('token'));

  const [hiresPer, setHiresPer] = useState(0);
  const [totalApplicants, setTotalApplicants] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  // total metrics for all contracts
  const [totalHiresPerDay, setTotalHiresPerDay] = useState(0);
  const [totalHiresPerWeek, setTotalHiresPerWeek] = useState(0);
  const [totalHiresPerMonth, setTotalHiresPerMonth] = useState(0);
  const [totalHiresAllTime, setTotalHiresAllTime] = useState(0);
  const [totalActiveContracts, setTotalActiveContracts] = useState(0);
  const [totalCompletedContracts, setTotalCompletedContracts] = useState(0);
  const [totalArchivedContracts, setTotalArchivedContracts] = useState(0);
  const [totalApplicantsDispositioned, setTotalApplicantsDispositioned] = useState(0);
  const [totalPendingApplicants, setTotalPendingApplicants] = useState(0);

  useEffect(() => {
    getAllContractMetrics();
  }, []);

  useEffect(() => {
    const updatedTotal = totalHiresAllTime + totalApplicantsDispositioned + totalPendingApplicants;
    setTotalApplicants(updatedTotal);
  }, [totalHiresAllTime, totalApplicantsDispositioned, totalPendingApplicants]);

  const getAllContractMetrics = async () => {
    setLoading(true);
    await fetch(`${API.endpointURL}/api/metrics/all_contract_metrics`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data['errors']) {
          setError(data['error']);
        } else {
          const {
            total_hires_per_day,
            total_hires_per_week,
            total_hires_per_month,
            total_hires_all_time,
            total_active_contracts,
            total_completed_contracts,
            total_archived_contracts,
            total_applicants_dispositioned,
            total_pending_applicants,
          } = data;

          setTotalHiresPerDay(total_hires_per_day);
          setTotalHiresPerWeek(total_hires_per_week);
          setTotalHiresPerMonth(total_hires_per_month);
          setTotalHiresAllTime(total_hires_all_time);
          setTotalActiveContracts(total_active_contracts);
          setTotalCompletedContracts(total_completed_contracts);
          setTotalArchivedContracts(total_archived_contracts);
          setTotalApplicantsDispositioned(total_applicants_dispositioned);
          setTotalPendingApplicants(total_pending_applicants);
          setHiresPer(total_hires_per_day);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError('Error getting contract metrics.');
      });
  };

  if (error.length > 0) {
    return (
      <>
        <h1 className="content-header">All Contracts Overview</h1>
        <div className="metrics-content-container">
          <div className="stats-group-header">{error}</div>
        </div>
      </>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <h2 className="content-header">Contracts</h2>
      {loading && (
        <div className="spinner-analytics">
          <ProgressSpinnerBlue />
        </div>
      )}
      <div
        className={classNames('metrics-content-container', {
          'metrics-content-container-transparent': loading,
        })}
        style={{ height: 'fit-content' }}
      >
        <div className="stats-group-container">
          <div className="contract-totals-row">
            <div className="stage-stat-container">
              <div className="stage-stat-header">Active</div>
              <div className={`stage-stat-number stage-stat-number--blue`}>{totalActiveContracts}</div>
              <div
                className="stage-stat-unit">{totalActiveContracts && totalActiveContracts === 1 ? 'contract' : 'contracts'}</div>
            </div>
            <div className="stage-stat-container">
              <div className="stage-stat-header">Completed</div>
              <div className={`stage-stat-number stage-stat-number--blue`}>{totalCompletedContracts}</div>
              <div
                className="stage-stat-unit">{totalCompletedContracts && totalCompletedContracts === 1 ? 'contract' : 'contracts'}</div>
            </div>
            <div className="stage-stat-container">
              <div className="stage-stat-header">Archived</div>
              <div className={`stage-stat-number stage-stat-number--blue`}>{totalArchivedContracts}</div>
              <div
                className="stage-stat-unit">{totalArchivedContracts && totalArchivedContracts === 1 ? 'contract' : 'contracts'}</div>
            </div>
          </div>
        </div>
        <div className="stats-group-container">
          <div className="applicants-hired-per-header">
            Applicants Hired per
            <div className="applicants-hired-per-interval">
              <select
                name="timeframe" id="timeframe"
                className="contract-form-input"
                defaultValue={totalHiresPerDay}
                onChange={(e) => setHiresPer(e.currentTarget.value)}
              >
                <option key={`day-${totalHiresPerDay}`} value={totalHiresPerDay}>Day</option>
                <option key={`week-${totalHiresPerWeek}`} value={totalHiresPerWeek}>Week</option>
                <option key={`month-${totalHiresPerMonth}`} value={totalHiresPerMonth}>Month</option>
                <option key={`all-${totalHiresAllTime}`} value={totalHiresAllTime}>All Time</option>
              </select>
            </div>
          </div>
          <div className={`applicants-hired-per-number applicants-hired-per-number--blue`}>
            {hiresPer.toLocaleString()}
          </div>
        </div>
        <div className="stats-group-container">
          <div className="stats-group-header">Hiring Matrix - All Contracts</div>
          <div className="hiring-matrix-container">
            <div className="matrix-total-container">
              <div className={`total-applicants-number total-applicants-number--blue`}>
                {totalApplicants.toLocaleString()}
              </div>
              <p className="stage-stat-header">Total Applicants</p>
            </div>
            <div className="matrix-breakdown-container">
              <div className="fill-stat-container">
                <div className="stage-stat-header">Applicants Hired</div>
                {totalHiresAllTime &&
                  <FillBarSolid color={'blue'} value={totalHiresAllTime} maxValue={totalApplicants}/>}
              </div>
              <div className="fill-stat-container">
                <div className="stage-stat-header">Applicants Dispositioned/Archived</div>
                {totalApplicantsDispositioned &&
                  <FillBarSolid color={'blue'} value={totalApplicantsDispositioned} maxValue={totalApplicants}/>}
              </div>
              <div className="fill-stat-container">
                <div className="stage-stat-header">Applicants Pending</div>
                {totalPendingApplicants &&
                  <FillBarSolid color={'blue'} value={totalPendingApplicants} maxValue={totalApplicants}/>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
