import { clearSearchInput } from 'store/staffingSelectedOptions/slice';
import { getApplicantsByStage } from 'store/applicants/thunk';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  companyFilterSelector,
  contractFilterIdSelector, currentPageSelector,
  currentSortOptionSelector,
  currentSortOrderSelector,
  currentTabSelector,
  psqAlertFilterSelector,
  recruiterFilterIdSelector,
  regionFilterIdSelector,
  selectedGroupsSelector,
  selectedTagsSelector,
  siteFilterIdSelector,
  textAlertFilterSelector,
} from 'store/staffingSelectedOptions/selectors';
import { tagGroupsSelector } from 'store/tags/selectors';

export const useGetApplicantsByStage = () => {

  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(currentTabSelector);
  const recruiterFilterId = useAppSelector(recruiterFilterIdSelector);
  const companyFilter = useAppSelector(companyFilterSelector);
  const siteFilterId = useAppSelector(siteFilterIdSelector);
  const regionFilterId = useAppSelector(regionFilterIdSelector);
  const contractFilterId = useAppSelector(contractFilterIdSelector);
  const textAlertFilter = useAppSelector(textAlertFilterSelector);
  const psqAlertFilter = useAppSelector(psqAlertFilterSelector);
  const currentSortOption = useAppSelector(currentSortOptionSelector);
  const currentSortOrder = useAppSelector(currentSortOrderSelector);
  const selectedTags = useAppSelector(selectedTagsSelector);
  const selectedGroups = useAppSelector(selectedGroupsSelector);
  const allGroups = useAppSelector(tagGroupsSelector);
  const currentPage = useAppSelector(currentPageSelector);
  const getApplicants = (value = '') => {
    dispatch(clearSearchInput());

    let sort = `${currentSortOption}.${currentSortOrder}`;
    let alertsFilter = '';
    let groupsFilter = '';
    let tagsFilter = '';
    if (textAlertFilter && psqAlertFilter) {
      alertsFilter = '&text_alert=true&document_alert=true';
    } else if (textAlertFilter && !psqAlertFilter) {
      alertsFilter = '&text_alert=true';
    } else if (!textAlertFilter && psqAlertFilter) {
      alertsFilter = '&document_alert=true';
    }
    if (selectedGroups.length > 0 && value !== 'clearTags') {
      const selected = allGroups.filter(group => selectedGroups.includes(group.title))
      const groupParams = selected.map((tag, index) => {
        return `&tag_group_ids[${index}]=${tag.id}`;
      });
      groupsFilter = groupParams.join('');
    }
    if (selectedTags.length > 0 && value !== 'clearTags') {
      const tagParams = selectedTags.map((tag) => {
        return `"${tag}"`;
      });
      tagsFilter = `&tag_name=[${tagParams}]`;
    }

    dispatch(
      getApplicantsByStage({
        currentTab,
        recruiterId: recruiterFilterId,
        company: companyFilter,
        siteId: siteFilterId,
        regionId: regionFilterId,
        contractId: contractFilterId,
        alertsFilter,
        groupsFilter,
        tagsFilter,
        sort,
        currentPage,
      }));
  };

  return getApplicants;
}