import '../../css/modal.scss';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomCommsDialog from 'components/modal/custom-comms-dialog';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getCurrentUserData, getEmailTemplatesByUserId, sendEmail } from 'store/user/thunk';
import {
  emailLoadingSelector,
  emailTemplatesSelector, sendEmailErrorSelector, sendEmailStatusSelector,
  templatesErrorSelector,
  templatesLoadingSelector, userErrorSelector, userLoadingSelector,
  userSelector,
} from 'store/user/selectors';
import { setEmailError, setEmailStatus } from 'store/user/slice';
import { useLocation } from 'react-router-dom';
import ProgressSpinner from 'components/progress/progress-spinner';

export default function EmailDialog(props) {
  const {
    handleClose,
    open,
    openEmailToast,
    contractId,
    currentApplicant,
    currentDivisionId,
    currentSubdivisionId,
    sidebarCollapse,
  } = props;
  const [sendTo, setSendTo] = useState('all');
  const loggedInUser = JSON.parse(localStorage.getItem('user'));

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const contractPortal = pathname.includes('contract-portal');
  
  const allowedDomain = /^[a-zA-Z0-9._%+-]+@responsivedeployment\.[a-zA-Z]{2,}$/;
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [emailSender, setEmailSender] = useState('rdmstaffing@responsivedeployment.com');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [fileInputCounter, setFileInputCounter] = useState(1);
  const [file1Name, setFile1Name] = useState('');
  const [file1Attachment, setFile1Attachment] = useState(null);
  const [file2Name, setFile2Name] = useState('');
  const [file2Attachment, setFile2Attachment] = useState(null);
  const [file3Name, setFile3Name] = useState('');
  const [file3Attachment, setFile3Attachment] = useState(null);
  const [file4Name, setFile4Name] = useState('');
  const [file4Attachment, setFile4Attachment] = useState(null);
  const [file5Name, setFile5Name] = useState('');
  const [file5Attachment, setFile5Attachment] = useState(null);
  
  const emailStatus = useAppSelector(sendEmailStatusSelector);
  const error  = useAppSelector(sendEmailErrorSelector);
  const templates = useAppSelector(emailTemplatesSelector);
  const loadingTemplates = useAppSelector(templatesLoadingSelector);
  const errorLoadingTemplates = useAppSelector(templatesErrorSelector);
  const userData = useAppSelector(userSelector);
  const loadingUserData = useAppSelector(userLoadingSelector);
  const errorLoadingUserData = useAppSelector(userErrorSelector);
  const loading = useAppSelector(emailLoadingSelector);
  
  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],
      ['link'],
      [{ 'align': [] }],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: true,
    },
  };

  useEffect(() => {
    dispatch(getEmailTemplatesByUserId({ id: loggedInUser.id }));
    dispatch(getCurrentUserData());
  }, []);

  const handleSelectTemplate = (event) => {
    setSelectedTemplateId(event.target.value);
    if (templates && event.target.value) {
      const templateContent = templates.find(template => Number(template.id) === Number(event.target.value)).template_body;
      setEmailBody(templateContent);
    } else {
      setEmailBody('');
    }
  };

  const handleSenderChange = (event) => {
    setEmailSender(event.target.value);
  };
  
  const handleSendToChange = (event) => {
    setSendTo(event.target.value);
  };

  const renderFetchedOptions = (type) => {
    let loadingOptions;
    let errorOptions;
    let fetchedData;

    if (type === 'Templates') {
      loadingOptions = loadingTemplates;
      errorOptions = errorLoadingTemplates;
      fetchedData = templates;
    } else if (type === 'User Data') {
      loadingOptions = loadingUserData;
      errorOptions = errorLoadingUserData;
      fetchedData = userData;
    }

    if (!loadingOptions && errorOptions) {
      return <option value="">Error loading {type}...</option>;
    } else if (loadingOptions && !errorOptions) {
      return <option value="">Loading {type}...</option>;
    } else if (!loadingOptions && !errorOptions && fetchedData) {
      if (type === 'Templates') {
        return (
          <>
            <option value="">Use Email Template</option>
            {fetchedData.map((element) => (
              <option key={element.id} value={element.id}>
                {element.template_name}
              </option>
            ))}
          </>
        );
      } else if (type === 'User Data') {
        if (allowedDomain.test(userData.email)) {
          return <option value={'authed_user'}>{userData.email}</option>;
        }
      }
    }
    return '';
  };


  const validateForm = () => {
    let validForm = true;
    if (emailBody === '') validForm = false;
    if (emailSubject === '') validForm = false;
    if (file1Name && !file1Attachment) validForm = false;
    if (!file1Name && file1Attachment) validForm = false;
    if (file2Name && !file2Attachment) validForm = false;
    if (!file2Name && file2Attachment) validForm = false;
    if (file3Name && !file3Attachment) validForm = false;
    if (!file3Name && file3Attachment) validForm = false;
    if (file4Name && !file4Attachment) validForm = false;
    if (!file4Name && file4Attachment) validForm = false;
    if (file5Name && !file5Attachment) validForm = false;
    if (!file5Name && file5Attachment) validForm = false;

    return validForm;
  };

  const buildSendTo = () => {
    if (contractId || currentDivisionId || currentSubdivisionId) {
      return (
        <div>
          <div className="form-row-label" style={{ textAlign: 'left' }}>
            Send To
          </div>
          <Select
            value={sendTo}
            defaultValue="all"
            onChange={handleSendToChange}
            fullWidth
            sx={{
              backgroundColor: '#fff',
              border: '1px solid #a5af96',
              borderRadius: '8px',
              padding: '6px 10px 5px',
              marginBottom: 1,
            }}
            variant="standard"
            disableUnderline
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="applicants">
              Prospective Applicants (Stages 1-6)
            </MenuItem>
            <MenuItem value="hired">Hired (Stage 7)</MenuItem>
            <MenuItem value="dispotition">Disposition (Stage 8)</MenuItem>
            <MenuItem value="archive">Archive (Stage 9)</MenuItem>
          </Select>
        </div>
      );
    }
  };

  const handleSendEmail = async () => {
    const validForm = validateForm();
    if (validForm) {
      const formData = new FormData();
      let url = `/api/send_email`;

      if (contractId) {
        formData.append('contract_id', contractId);
        url = `/api/send_mass_email/`;
      } else if (currentDivisionId) {
        formData.append('division_id', currentDivisionId);
        url = `/api/send_mass_email/by-division`;
      } else if (currentSubdivisionId) {
        formData.append('sub_division_id', currentSubdivisionId);
        url = `/api/send_mass_email/by-sub-division`;
      } else {
        formData.append('addressee_id', currentApplicant.id);
      }
      if ((contractId || currentDivisionId || currentSubdivisionId) && sendTo) {
        formData.append('send_to', sendTo);
      }
      formData.append('sender_email', emailSender);
      formData.append('body', emailBody);
      formData.append('subject', emailSubject);
      if (file1Name && file1Attachment) {
        formData.append('file_1', file1Attachment);
        formData.append('file_name_1', file1Name);
      }
      if (file2Name && file2Attachment) {
        formData.append('file_2', file2Attachment);
        formData.append('file_name_2', file2Name);
      }
      if (file3Name && file3Attachment) {
        formData.append('file_3', file3Attachment);
        formData.append('file_name_3', file3Name);
      }
      if (file4Name && file4Attachment) {
        formData.append('file_4', file4Attachment);
        formData.append('file_name_4', file4Name);
      }
      if (file5Name && file5Attachment) {
        formData.append('file_5', file5Attachment);
        formData.append('file_name_5', file5Name);
      }

      dispatch(sendEmail({ url: url, body: formData }));
    } else {
      dispatch(setEmailError('Please provide an email subject, body and file name for each attachment.'));
    }
  };

  useEffect(() => {
    if (emailStatus === 'fulfilled' && !error) {
      openEmailToast();
      dispatch(setEmailStatus(''));
      setSelectedTemplateId(null);
      setEmailSender('rdmstaffing@responsivedeployment.com');
      setEmailSubject('');
      setEmailBody('');
      setFileInputCounter(1);
      setFile1Name('');
      setFile1Attachment(null);
      setFile2Name('');
      setFile2Attachment(null);
      setFile3Name('');
      setFile3Attachment(null);
      setFile4Name('');
      setFile4Attachment(null);
      setFile5Name('');
      setFile5Attachment(null);
      if (!contractPortal) {
        handleClose();
      }
    }
  }, [emailStatus]);


  const renderFileInputRow = (counter) => {

    let fileName;
    let setFileName;
    let setFileAttachment;

    if (counter === 1) {
      fileName = file1Name;
      setFileName = setFile1Name;
      setFileAttachment = setFile1Attachment;
    } else if (counter === 2) {
      fileName = file2Name;
      setFileName = setFile2Name;
      setFileAttachment = setFile2Attachment;
    } else if (counter === 3) {
      fileName = file3Name;
      setFileName = setFile3Name;
      setFileAttachment = setFile3Attachment;
    } else if (counter === 4) {
      fileName = file4Name;
      setFileName = setFile4Name;
      setFileAttachment = setFile4Attachment;
    } else if (counter === 5) {
      fileName = file5Name;
      setFileName = setFile5Name;
      setFileAttachment = setFile5Attachment;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <TextField
          value={fileName}
          onChange={(e) => setFileName(e.currentTarget.value)}
          fullWidth
          sx={{
            backgroundColor: '#fff',
            border: '1px solid #a5af96',
            borderRadius: '8px',
            padding: '6px 10px 5px',
            marginBottom: 1,
            marginRight: '2%',
            width: '50%',
          }}
          placeholder={`File ${counter} name...`}
          rows={1}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <div className="belles-file-upload" style={{ width: '48%' }}>
          <input
            type="file"
            name="file"
            onChange={(event) => setFileAttachment(event.target.files[0])}
            style={{ marginTop: '8px' }}
          />
        </div>
      </Box>
    );
  };


  const buildEmailForm = () => {
    return (
      <Box>
        <div className="korolev-medium-font-form">
          {buildSendTo()}
          <div className="form-row-label" style={{ textAlign: 'left' }}>
            Email Subject
          </div>
          <TextField
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.currentTarget.value)}
            fullWidth
            sx={{
              backgroundColor: '#fff',
              border: '1px solid #a5af96',
              borderRadius: '8px',
              padding: '6px 10px 5px',
              marginBottom: 1,
            }}
            placeholder="Subject..."
            rows={1}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />

          <div className="sale-form-set-full content-dark-color">
            <div className="form-row-label">Email Sender</div>
            <select
              className="sale-form-input "
              placeholder="Select Email Sender..."
              value={emailSender}
              name="email_sender"
              onChange={handleSenderChange}
              style={{
                color: '#171717',
                padding: '7px 12px',
                width: '100%',
              }}
            >
              <option value={'rdmstaffing@responsivedeployment.com'}>{'rdmstaffing@responsivedeployment.com'}</option>
              <option value={'solutions@responsivedeployment.com'}>{'solutions@responsivedeployment.com'}</option>
              <option value={'assigned_recruiter'}>{'Recruiter'}</option>
              {renderFetchedOptions('User Data')}
            </select>
            {emailSender === 'assigned_recruiter' && contractPortal &&
              <p style={{ color: 'black' }} className="add-user-error">The letter will be sent from the applicant's designated recruiters.</p>
            }
          </div>

          <div className={'content-dark-color'}
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="form-row-label" style={{ textAlign: 'left' }}>
              Email Body
            </div>
            <div className="sale-form-set" style={{ margin: '10px 0px' }}>
              <select
                className="sale-form-input "
                placeholder="Select Template..."
                value={selectedTemplateId || ''}
                name="email_body"
                onChange={handleSelectTemplate}
                style={{
                  color: !selectedTemplateId ? '#B5B5B5' : '#171717',
                  padding: '7px 12px',
                  width: '100%',
                }}
              >
                {renderFetchedOptions('Templates')}
              </select>
            </div>
          </div>

          <div className="rich-editor">
            <ReactQuill
              theme="snow"
              value={emailBody}
              onChange={setEmailBody}
              modules={modules}
            />
          </div>

          <div className="form-row-label" style={{ textAlign: 'left' }}>
            Email File Attachments
            {fileInputCounter < 5 && (
              <Tooltip title="Add another file">
                <IconButton
                  color="inherit"
                  aria-label="add-file-input"
                  sx={{
                    alignItems: 'flex-start',
                    height: '20px',
                    marginLeft: '8px',
                    paddingBottom: '0',
                    paddingTop: '0',
                    width: '20px',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() => {
                    setFileInputCounter(fileInputCounter + 1);
                  }}
                >
                  <AddIcon
                    sx={{
                      height: '18px',
                      color: '#556155',
                      width: '18px',
                      '&:hover': {
                        color: '#a5af96',
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>

          {renderFileInputRow(1)}
          {fileInputCounter >= 2 && renderFileInputRow(2)}
          {fileInputCounter >= 3 && renderFileInputRow(3)}
          {fileInputCounter >= 4 && renderFileInputRow(4)}
          {fileInputCounter === 5 && renderFileInputRow(5)}

          {error?.length > 0  && <div className="add-user-error">{error}</div>}
          <div className="button-container">
            {loading && (
              <ProgressSpinner margin="0 10px 0 0" />
            )}
            <Button
              variant="contained"
              className="add-user-button"
              sx={{
                backgroundColor: '#556155',
                color: '#EFEFEF',
                fontSize: '18px',
                width: '175px',
                '&:hover': { backgroundColor: '#a5af96', color: '#3e453d' },
              }}
              onClick={() => handleSendEmail()}
            >
              Send
            </Button>
          </div>
        </div>
      </Box>
    );
  };

  // RD requested left screen placement on staffing page with transparent background
  // to be able to view applicant profile on right - need to refactor to not use same placement on Contracts
  return (
    <CustomCommsDialog
      dialogContent={buildEmailForm()}
      onClose={handleClose}
      open={open}
      title={'SEND EMAIL'}
      colorPalette="green"
      sidebarCollapse={sidebarCollapse}
    />
  );
}
