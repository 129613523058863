import React, { useState } from 'react';
import 'css/sales.scss';
import API from 'API';
import Toast from 'components/toast/toast';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { setIsLoadedAnalytics } from 'store/sales/slice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  bidTypesSelector,
  companyConnectionsLoadingSelector,
  companyConnectionsSelector,
  personalConnectionsLoadingSelector,
  personalConnectionsSelector,
  salesDivisionsSelector,
  salesRepsLoadingSelector,
  salesRepsSelector,
} from 'store/sales/selectors';

const initialInfo = {
  opportunity_name: '',
  project_name: '',
  division_id: '',
  bid_type: '',
  stage: '',
  description: '',
  sales_rep_id: '',
  personal_note: '',
  company_note: '',
  sale_type: '',
}

const initialErrors = {
  opportunity_name: false,
  project_name: false,
  division_id: false,
  bid_type: false,
  stage: false,
  description: false,
  sales_rep_id: false,
  validation: false,
  server_error: false,
  sale_type: false,
}

export const SalesCreateLead = () => {
  const token = JSON.parse(localStorage.getItem("token"));

  const [leadInformation, setLeadInformation] = useState(initialInfo);
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const dispatch = useAppDispatch();

  const personalConnections = useAppSelector(personalConnectionsSelector);
  const personalLoading = useAppSelector(personalConnectionsLoadingSelector);
  const companyConnections = useAppSelector(companyConnectionsSelector);
  const companyLoading = useAppSelector(companyConnectionsLoadingSelector);

  const salesUsers = useAppSelector(salesRepsSelector);
  const divisions = useAppSelector(salesDivisionsSelector);
  const bidTypes = useAppSelector(bidTypesSelector);
  const loadingReps = useAppSelector(salesRepsLoadingSelector);

  const handleChange = (event) => {
    setLeadInformation({
      ...leadInformation,
      [event.target.name]: event.target.value,
    })
    if (!event.target.value.length) {
      setErrors({
        ...errors,
        [event.target.name]: true,
      })
    } else {
      setErrors({
        ...errors,
        [event.target.name]: false,
      })
    }
  };

  const linkConnection = async (sale, id, noteID) => {
    try {
      const requestBody = {
        connection_id: id,
        is_primary: true,
        stage: sale.stage,
      };
      if (noteID) {
        requestBody.note_id = noteID;
      }
      const res = await fetch(`${API.endpointURL}/api/sales/${sale.id}/connections`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
          Accept: 'application/json'
        },
      });
  
      if (!res.ok) {
        return false;
      }
      
      const data = await res.json();
      if(data){
        return true;
      }
     
    } catch (e) {
      return false;
    }
  };

  const createNote = (noteBody) => {
    let requestError = false;
    return fetch(`${API.endpointURL}/api/notes`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
        Accept: 'application/json',
      },
      body: JSON.stringify({
        body: noteBody,
      })
    })
      .then((res) => {
        if (!res.ok) {
          requestError = true;
        }
        return res.json();
      })
      .then((res) => {
        if (!requestError) {
          return res.id;
        } else {
          setLoading(false);
          setErrors({
            ...errors,
            validation: false,
            server_error: false,
          });
        }
      })
      .catch(() => {
          setLoading(false);
          setErrors({
            ...errors,
            validation: false,
            server_error: false,
          });
        }
      );
  };

  // Create new sales entry
  const handleCreate = async () => {
    const isValidLength =
      Object.entries(leadInformation).every((item) => {
        if (item[0] === 'division_id') {
          return item[1].length < 26;
        } else {
          return item[1].length < 256;
        }
      });
    if (
      !isValidLength ||
      !leadInformation.stage ||
      !leadInformation.opportunity_name ||
      !leadInformation.sale_type
    ) {
      setErrors({
        ...errors,
        stage: !leadInformation.stage,
        opportunity_name: !leadInformation.opportunity_name,
        sale_type: !leadInformation.sale_type,
        validation: true,
      })
    } else {
      try {
        setLoadingCreate(true);
        const saleForm = {
          opportunity_name: leadInformation.opportunity_name,
          project_name: leadInformation.project_name,
          division_id: leadInformation.division_id,
          bid_types_id: leadInformation.bid_type_id,
          stage: leadInformation.stage,
          description: leadInformation.description,
          value: 0,
          sale_type: leadInformation.sale_type,
        };
  
        if (leadInformation.sales_rep_id) {
          saleForm.sales_rep_id = leadInformation.sales_rep_id;
        }
  
        const res = await fetch(`${API.endpointURL}/api/sales`, {
          method: "POST",
          body: JSON.stringify(saleForm),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
            Accept: 'application/json',
          },
        });

        if (!res.ok) {
          setErrors({
            ...errors,
            validation: true,
          });
          setLoadingCreate(false);
        } else {
          const data = await res.json();

          let linkedCompanyConnection = false;
          let linkedPrimaryConnection = false;

          if (leadInformation.primary_company_connection_id) {
            if (leadInformation.company_note) {
              const noteID = await createNote(leadInformation.company_note);
              linkedCompanyConnection = await linkConnection(data, leadInformation.primary_company_connection_id, noteID);
            } else {
              linkedCompanyConnection = await linkConnection(data, leadInformation.primary_company_connection_id);
            }
          }
          if (leadInformation.primary_connection_id) {
            if (leadInformation.personal_note) {
              const noteID = await createNote(leadInformation.personal_note);
              linkedPrimaryConnection = await linkConnection(data, leadInformation.primary_connection_id, noteID);
            } else {
              linkedPrimaryConnection = await linkConnection(data, leadInformation.primary_connection_id);
            }
          }

          if (
            (leadInformation.primary_company_connection_id && !linkedCompanyConnection) 
            ||
            (leadInformation.primary_connection_id && !linkedPrimaryConnection)
          ) {
            setErrors({
              ...errors,
              server_error: true,
            });
            setOpenToast(true);
          } else {
            setErrors({
              ...errors,
              validation: false,
              server_error: false,
            });
            setOpenToast(true);
          }

          setLoadingCreate(false);
          setLeadInformation(initialInfo);
          dispatch(setIsLoadedAnalytics(false));
        }
      } catch (e) {
        setLoadingCreate(false);
        setErrors({
          ...errors,
          server_error: true,
        });
        setOpenToast(true);
      }
    }
  };
  
  return (
    <section>
      <h1 className="content-header">Create New Sale</h1>
      <div className="content-container">
        {(loading || loadingReps || personalLoading || companyLoading) ? (
          <div className="content-loading">
            <ProgressSpinnerNavy />
          </div>
        ) : (
          <div className="sale-form-section-content">
            <div className="sale-form-set">
              <div className="sale-form-label">Opportunity Name</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={leadInformation.opportunity_name || ''}
                name="opportunity_name"
                onChange={handleChange}
              />
              {errors.opportunity_name && <p className="sale-form-error">* Please enter data</p>}
              {leadInformation.opportunity_name.length > 255
                && <p className="sale-form-error">* Max length is 255 characters...</p>}
            </div>
            <div className="sale-form-set">
              <div className="sale-form-label">Project Name</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={leadInformation.project_name || ''}
                name="project_name"
                onChange={handleChange}
              />
              {leadInformation.project_name.length > 255
                && <p className="sale-form-error">* Max length is 255 characters...</p>}
            </div>
            <div className="sale-form-set">
              <div className="sale-form-label">Business Line</div>
              <select
                className="sale-form-input"
                placeholder="Select Business Line..."
                value={leadInformation.division_id || ''}
                name="division_id"
                onChange={handleChange}
                style={{
                  color: !leadInformation.division_id ? '#EFEFEF80' : '#EFEFEF',
                  padding: '7px 12px',
                }}
              >
                <option value="">Select Business Line...</option>
                {divisions?.map((division) => (
                  <option key={division.id} value={division.id}>
                    {division.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="sale-form-set">
              <div className="sale-form-label">Bid Type</div>
              <select
                className="sale-form-input"
                placeholder="Select Bid Type..."
                value={leadInformation.bid_type_id || ''}
                name="bid_type_id"
                onChange={handleChange}
                style={{
                  color: !leadInformation.bid_type_id ? '#EFEFEF80' : '#EFEFEF',
                  padding: '7px 12px',
                }}
              >
                <option value="">Select Bid Type...</option>
                {bidTypes?.map((bidType) => (
                  <option key={bidType.id} value={bidType.id}>
                    {bidType.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="sale-form-set">
              <div className="sale-form-label">Stage</div>
              <select
                className="sale-form-input"
                placeholder="Enter Stage..."
                value={leadInformation.stage || ''}
                name="stage"
                onChange={handleChange}
                style={{ color: !leadInformation.stage ? '#EFEFEF80' : '#EFEFEF'}}
              >
                <option value="">Select Stage...</option>
                <option value="Business Development">Business Development</option>
                <option value="Lead">Capturing</option>
                <option value="Opportunity">In Work</option>
                <option value="Contract">Submitted</option>
                <option value="Award">Awarded To</option>
                <option value="Archive">Archive</option>
              </select>
              {errors.stage && <p className="sale-form-error">* Please enter data</p>}
            </div>
            <div className="sale-form-set">
              <div className="sale-form-label">Sales Rep</div>
              <select
                className="sale-form-input"
                placeholder="Enter Sales Rep..."
                value={leadInformation.sales_rep_id || ''}
                name="sales_rep_id"
                onChange={handleChange}
                style={{ color: !leadInformation.sales_rep_id ? '#EFEFEF80' : '#EFEFEF'}}
              >
                <option value="">Select Sales Rep...</option>
                {salesUsers?.length && salesUsers.map((user) => (
                  <option key={user.id} value={user.id}>
                    {`${user.first_name} ${user.last_name}`}
                  </option>
                ))}
              </select>
            </div>

            {/* Select primary connection input  */}
            <div className="sale-form-set">
              <div className="sale-form-label">Primary Point of Contact</div>
              <select
                className="sale-form-input"
                placeholder="Select Primary Point of Contact..."
                value={leadInformation.primary_connection_id || ''}
                name="primary_connection_id"
                onChange={handleChange}
                style={{ color: !leadInformation.primary_connection_id ? '#EFEFEF80' : '#EFEFEF'}}
              >
                <option value="">Select Primary Point of Contact...</option>
                {personalConnections?.length > 0 && personalConnections.map((connection) => (
                  <option key={connection.id} value={connection.id}>
                    {`${connection.full_name}`}
                  </option>
                ))}
              </select>
            </div>

            {/* Select primary connection input  */}
            <div className="sale-form-set">
              <div className="sale-form-label">Primary Company on the Sale</div>
              <select
                className="sale-form-input"
                placeholder="Select Primary Connection..."
                value={leadInformation.primary_company_connection_id || ''}
                name="primary_company_connection_id"
                onChange={handleChange}
                style={{ color: !leadInformation.primary_company_connection_id ? '#EFEFEF80' : '#EFEFEF'}}
              >
                <option value="">Select Primary Connection...</option>
                {companyConnections?.length > 0 && companyConnections.map((connection) => (
                  <option key={connection.id} value={connection.id}>
                    {`${connection.full_name}`}
                  </option>
                ))}
              </select>
            </div>

            <div className="sale-form-set">
              <div className="sale-form-label">Sales Type</div>
              <select
                className="sale-form-input"
                placeholder="Enter Sales Type..."
                value={leadInformation.type || ''}
                name="sale_type"
                onChange={handleChange}
                style={{ color: !leadInformation.sale_type ? '#EFEFEF80' : '#EFEFEF'}}
              >
                <option value="">Select Sales Type...</option>
                <option value={'direct'}>
                  Direct
                </option>
                <option value={'rental'}>
                  Rental
                </option>
              </select>
              {errors.sale_type && <p className="sale-form-error">* Please enter data</p>}
            </div>

            <div className="sale-form-set">
              <div className="sale-form-label">Notes to Primary Point of Contact</div>
              <textarea
                className="sale-form-input"
                placeholder={
                  leadInformation.primary_connection_id
                    ? 'N/A'
                    : 'Select Primary Point of Contact first'
                }
                value={leadInformation.personal_note || ''}
                name="personal_note"
                onChange={handleChange}
                disabled={!leadInformation.primary_connection_id}
              />
              {leadInformation.personal_note?.length > 255
                && <p className="sale-form-error" style={{ top: '86px' }}>* Max length is 255 characters...</p>}
            </div>

            <div className="sale-form-set">
              <div className="sale-form-label">Notes to Primary Company on the Sale</div>
              <textarea
                className="sale-form-input"
                placeholder={
                  leadInformation.primary_company_connection_id
                    ? 'N/A'
                    : 'Select Primary Company on the Sale first'
                }
                value={leadInformation.company_note || ''}
                name="company_note"
                onChange={handleChange}
                disabled={!leadInformation.primary_company_connection_id}
              />
              {leadInformation.company_note?.length > 255
                && <p className="sale-form-error" style={{ top: '86px' }}>* Max length is 255 characters...</p>}
            </div>

            <div className="sale-form-set sale-form-set-full">
              <div className="sale-form-label">Description</div>
              <textarea
                className="sale-form-input sale-form-input-textarea"
                placeholder="N/A"
                value={leadInformation.description || ''}
                name="description"
                onChange={handleChange}
              />
              {leadInformation.description.length > 255
                && <p className="sale-form-error">* Max length is 255 characters...</p>}
            </div>
            <div className="sale-form-button-container">
              {loadingCreate && (
                <div className="sale-form-spinner">
                  <ProgressSpinnerNavy />
                </div>
              )}
              <button
                className="create-button create-button--dark"
                onClick={handleCreate}
                disabled={loadingCreate}
              >
                Create
              </button>
              {errors.validation
                && <p className="sale-form-error">* Please enter valid data...</p>}
            </div>
          </div>
        )}

      </div>
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={errors.server_error
            ? 'Something went wrong, try again later...'
            : 'New Lead Successfully Created'
          }
        />
      )}
    </section>
  );
};