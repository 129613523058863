import React from "react";
import logo from "../../assets/logo.png";
import "../../css/application.scss";
import Link from '@mui/material/Link';

export default function PrivacyPolicy() {
  return (
    <div
      className="background"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img alt='logo' className="rd-logo" src={logo} />
      <div className="policy-container">
        <div className="policy-header">Privacy Policy</div>
        <div className="policy-body">Most Recent Update: January 9, 2024</div>
        <br/>
        <div className="policy-body">
          This Privacy Policy explains how Responsive Deployment, LLC collects, uses and discloses personal information of its employees and applicants for employment, our customers, and visitors to its website at {' '}
          <Link
            sx={{
              color: '#a5af96',
              transition: 'all, 0.3s',
              ':hover': {
                textDecoration: 'underline',
              },
              ':not(.ql-editor a)': {
                display: 'inline'
              }
            }}
            href="https://responsivemanagement.org"
            target="_blank"
            rel="noreferrer noopener"
          ><b>https://responsivemanagement.org</b></Link>.
        </div>
        <br />
        <div className="policy-section-header">COLLECTION OF PERSONAL INFORMATION</div>
        <div className="policy-body">
          Information collected directly from you: We may collect personal information directly from you, for example through web forms, during an online or in-person application, while setting up an account or when you contact us for customer support. Personal information we collect directly from you may include first, middle and last names, date of birth, address, email address, and phone number.
        </div>
        <br/>
        <div className="policy-body">
          Information collected from your device: Our website may use tracking technologies to automatically collect certain information from your device, including for example your browser and operating system information, and other information about how you interact with the website.
        </div>
        <br />
        <div className="policy-section-header">USE OF PERSONAL INFORMATION</div>
        <div className="policy-body">
          We use information collected directly from you to provide you with updated information related to your job application(s) and other opportunities, including by email and text message subject to your consent.
        </div>
        <br/>
        <div className="policy-body">
          We use information collected automatically from your device to provide our website to you with optimizations for your device(s).
        </div>
        <br />
        <div className="policy-section-header">DISCLOSURE OF PERSONAL INFORMATION</div>
        <div className="policy-body">
          We may use third-party service providers to assist us with providing our services to you and we may share your information with such third parties for these limited purposes.
        </div>
        <br/>
        <div className="policy-body">
          We use Twilio SendGrid for our email and text message communications. For more information about how we may use your information with Twilio SendGrid and the information that may be collected through our communications, see Twilio’s Privacy Notice at {' '}
          <Link
            sx={{
              color: '#a5af96',
              transition: 'all, 0.3s',
              ':hover': {
                textDecoration: 'underline',
              },
              ':not(.ql-editor a)': {
                display: 'inline'
              }
            }}
            href="https://www.twilio.com/en-us/legal/privacy"
            target="_blank"
            rel="noreferrer noopener"
          ><b>https://www.twilio.com/en-us/legal/privacy</b></Link>.
        </div>
        <br/>
        <div className="policy-body">
          We may also share your personal information if necessary to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
        </div>
        <br />
        <div className="policy-section-header">TEXT MESSAGE COMMUNICATIONS</div>
        <div className="policy-body">
          If you wish to stop receiving text messages from us, reply STOP to any text message sent from us or update your opt-in preferences via your Applicant Portal at {' '}
          <Link
            sx={{
              color: '#a5af96',
              transition: 'all, 0.3s',
              ':hover': {
                textDecoration: 'underline',
              },
              ':not(.ql-editor a)': {
                display: 'inline'
              }
            }}
            href="https://responsivemanagement.org"
            target="_blank"
            rel="noreferrer noopener"
          ><b>https://responsivemanagement.org</b></Link>. For more information, see our Terms & Conditions available at {' '}
          <Link
            sx={{
              color: '#a5af96',
              transition: 'all, 0.3s',
              ':hover': {
                textDecoration: 'underline',
              },
              ':not(.ql-editor a)': {
                display: 'inline'
              }
            }}
            href="https://responsivemanagement.org/terms-of-service"
            target="_blank"
            rel="noreferrer noopener"
          ><b>https://responsivemanagement.org/terms-of-service</b></Link>.
        </div>
        <br />
        <div className="policy-section-header">YOUR PRIVACY RIGHTS</div>
        <div className="policy-body">
          You may have the right to request access to the personal information we hold about you, to port it to a new service, or to request that your personal information be corrected or deleted. To exercise any of these rights, please contact us at {' '}
          <Link
            sx={{
              color: '#a5af96',
              transition: 'all, 0.3s',
              ':hover': {
                textDecoration: 'underline',
              },
              ':not(.ql-editor a)': {
                display: 'inline'
              }
            }}
            href="mailto:rdmstaffing@responsivedeployment.com"
            target="_blank"
            rel="noreferrer noopener"
          ><b>rdmstaffing@responsivedeployment.com</b></Link>.
        </div>
        <br />
        <div className="policy-section-header">CHANGES TO THIS PRIVACY POLICY</div>
        <div className="policy-body">
          We may update this Privacy Policy at any time. Please review it frequently.
        </div>
        <br />
        <div className="policy-section-header">CONTACT INFORMATION</div>
        <div className="policy-body">
          If you have any questions about this policy or our privacy practices, please contact us at {' '}
          <Link
            sx={{
              color: '#a5af96',
              transition: 'all, 0.3s',
              ':hover': {
                textDecoration: 'underline',
              },
              ':not(.ql-editor a)': {
                display: 'inline'
              }
            }}
            href="mailto:rdmstaffing@responsivedeployment.com"
            target="_blank"
            rel="noreferrer noopener"
          ><b>rdmstaffing@responsivedeployment.com</b></Link>.
        </div>
      </div>
      <div style={{ marginTop: 'auto', textAlign: 'center', width: '100%' }}>
        <div className="copyright">©2023 Responsive Deployment</div>
      </div>
    </div>
  );
};
