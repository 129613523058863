import { createSlice } from '@reduxjs/toolkit';
import { approveAllFiles, approveFiles, getApplicantFiles, uploadFile } from 'store/files/thunk';

const initialState = {
  applicantFiles: [],
  applicantFilesError: '',
  isLoading: false,
  approveStatus: '',
  filesError: '',
  uploadStatus: '',
};

const filesSlice = createSlice({
  name: 'filesSlice',
  initialState,
  reducers: {
    setApproveStatus (state, { payload }) {
      state.approveStatus = payload;
    },
    setUploadStatus (state, { payload }) {
      state.uploadStatus = payload;
    },
    setFileError (state, { payload }) {
      state.filesError = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getApplicantFiles.pending, (state) => {
      state.isLoading = true;
      state.applicantFilesError = '';
    });
    builder.addCase(getApplicantFiles.fulfilled, (state, { payload }) => {
      state.applicantFiles = payload;
      state.isLoading = false;
      state.applicantFilesError = '';
    });
    builder.addCase(getApplicantFiles.rejected, (state) => {
      state.isLoading = false;
      state.applicantFilesError = 'Failed to fetch files.';
    });
    builder.addCase(approveFiles.pending, (state) => {
      state.isLoading = true;
      state.approveStatus = 'pending';
    });
    builder.addCase(approveFiles.fulfilled, (state) => {
      state.isLoading = false;
      state.approveStatus = 'fulfilled';
    });
    builder.addCase(approveFiles.rejected, (state) => {
      state.isLoading = false;
      state.approveStatus = 'rejected';
    });
    builder.addCase(approveAllFiles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approveAllFiles.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.applicantFiles = payload;
    });
    builder.addCase(approveAllFiles.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(uploadFile.pending, (state) => {
      state.isLoading = true;
      state.filesError = '';
      state.uploadStatus = 'pending';
    });
    builder.addCase(uploadFile.fulfilled, (state) => {
      state.isLoading = false;
      state.filesError = '';
      state.uploadStatus = 'fulfilled';
    });
    builder.addCase(uploadFile.rejected, (state) => {
      state.isLoading = false;
      state.filesError = 'Please select a file and try again.';
      state.uploadStatus = 'rejected';
    });
  },
});

export const { setApproveStatus, setUploadStatus, setFileError } = filesSlice.actions;

export default filesSlice.reducer;