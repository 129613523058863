import { createSlice } from '@reduxjs/toolkit';
import {
  createDivision,
  deleteDivision,
  getDivisionById,
  getDivisions,
  getDivisionsByContractId,
  getFilterableDivisions,
  updateDivision,
} from 'store/divisions/thunk';

const initialState = {
  divisions: [],
  filterableDivisions: [],
  filterableDivisionsLoading: false,
  filterableDivisionsError: '',
  
  divisionsByContractId: [],
  selectedDivision: null,
  divisionsActionMessage: '',
  divisionsError: '',
  isLoading: false,
  division: null,
};

const divisionsSlice = createSlice({
  name: 'divisionsSlice',
  initialState,
  reducers: {
    resetDivisionsByContractId (state) {
      state.divisionsByContractId = [];
    },
    resetDivision (state) {
      state.division = null;
    },
    setSelectedDivision (state, { payload }) {
      state.selectedDivision = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDivisions.pending, (state) => {
      state.divisionsError = '';
      state.isLoading = true;
    });
    builder.addCase(getDivisions.fulfilled, (state, action) => {
      state.divisions = action.payload;
      state.divisionsError = '';
      state.isLoading = false;
    });
    builder.addCase(getDivisions.rejected, (state) => {
      state.divisionsError = 'Error getting divisions.';
      state.isLoading = false;
    });
   
    builder.addCase(getDivisionsByContractId.pending, (state) => {
      state.divisionsError = '';
      state.isLoading = true;
    });
    builder.addCase(getDivisionsByContractId.fulfilled, (state, action) => {
      state.divisionsByContractId = action.payload;
      state.divisionsError = '';
      state.isLoading = false;
    });
    builder.addCase(getDivisionsByContractId.rejected, (state) => {
      state.divisionsError = 'Error getting divisions.';
      state.isLoading = false;
    });
    
    builder.addCase(getDivisionById.pending, (state) => {
      state.divisionsError = '';
      state.isLoading = true;
    });
    builder.addCase(getDivisionById.fulfilled, (state, action) => {
      state.division = action.payload;
      state.divisionsError = '';
      state.isLoading = false;
    });
    builder.addCase(getDivisionById.rejected, (state) => {
      state.divisionsError = 'Error getting divisions.';
      state.isLoading = false;
    });
    
    builder.addCase(createDivision.pending, (state) => {
      state.isLoading = true;
      state.divisionsActionMessage = '';
    });
    builder.addCase(createDivision.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedDivision = action.payload;
      state.divisionsActionMessage = 'Division successfully created.';
    });
    builder.addCase(createDivision.rejected, (state) => {
      state.isLoading = false;
      state.selectedDivision = null;
      state.divisionsActionMessage = 'Error creating division. Try again later.';
    });
    
    builder.addCase(updateDivision.pending, (state) => {
      state.isLoading = true;
      state.divisionsActionMessage = '';
    });
    builder.addCase(updateDivision.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedDivision = action.payload;
      state.divisionsActionMessage = 'Division successfully updated.';
    });
    builder.addCase(updateDivision.rejected, (state) => {
      state.isLoading = false;
      state.selectedDivision = null;
      state.divisionsActionMessage = 'Error updating division. Try again later.';
    });
    
    builder.addCase(deleteDivision.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteDivision.fulfilled, (state) => {
      state.isLoading = false;
      state.selectedDivision = null;
    });
    builder.addCase(deleteDivision.rejected, (state) => {
      state.isLoading = false;
    });
    
    builder.addCase(getFilterableDivisions.pending, (state) => {
      state.filterableDivisionsError = '';
      state.filterableDivisionsLoading = true;
    });
    builder.addCase(getFilterableDivisions.fulfilled, (state, action) => {
      state.filterableDivisions = action.payload;
      state.filterableDivisionsError = '';
      state.filterableDivisionsLoading = false;
    });
    builder.addCase(getFilterableDivisions.rejected, (state) => {
      state.filterableDivisionsError = 'Error getting divisions.';
      state.filterableDivisionsLoading = false;
    });
  },
});

export const {
  resetDivisionsByContractId,
  resetDivision,
  setSelectedDivision,
} = divisionsSlice.actions;

export default divisionsSlice.reducer;