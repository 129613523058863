import React from 'react';
import Tabs from '@mui/material/Tabs';
import { StyledSalesTab } from './tab-styles';
import { useAppDispatch } from 'hooks/reduxHooks';
import { setSalesStage } from 'store/salesSelectedOptions/slice';
import { setTasksTab } from 'store/sales/slice';


export default function SalesTabs(props) {

  const { currentTab, tabs, page } = props;

  const dispatch = useAppDispatch();

  const handleChange = (event, newValue) => {
    if (page === 'sales') {
      dispatch(setSalesStage(newValue));
    } else {
      dispatch(setTasksTab(newValue));
    }
  };
  
  const findTabLabel = (tab) => {
    switch (tab) {
      case 'Lead':
        return 'Capturing';
      case 'Opportunity':
        return 'In Work';
      case 'Contract':
        return 'Submitted';
      case 'Award':
        return 'Awarded To';
      default:
        return tab;
    }
  };

  return (
    <Tabs
      value={currentTab}
      onChange={handleChange}
      variant='scrollable'
      scrollButtons="auto"
      TabIndicatorProps={{
        style: { display: 'none' }
      }}
      TabScrollButtonProps={{
        style: {
          margin: "0 10px",
          width: "10px",
        }
      }}
    >
      {tabs.map(tab => (
        <StyledSalesTab label={findTabLabel(tab)} value={tab} key={tab} />
      ))}
    </Tabs>
  );
};
