import '../../css/contracts.scss';
import React, { useEffect, useState } from 'react';
import Toast from '../toast/toast';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getSubdivisionByDivisionAndContractId, updateSubdivision } from 'store/subdivisions/thunk';
import { selectedSubdivisionSelector } from 'store/subdivisions/selectors';
import { selectedContractSelector } from 'store/contracts/selectors';
import { selectedDivisionSelector } from 'store/divisions/selectors';

export default function SubdivisionDetailsForm() {
  const dispatch = useAppDispatch();
  const selectedSubdivision = useAppSelector(selectedSubdivisionSelector);
  const currentContract = useAppSelector(selectedContractSelector);
  const selectedDivision = useAppSelector(selectedDivisionSelector);
  
  const [backgroundTestUrl, setBackgroundTestUrl] = useState('');
  const [biLingualTestUrl, setbiLingualTestUrl] = useState('');
  const [drugTestUrl, setDrugTestUrl] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [openToast, setOpenToast] = useState(false);
  
  useEffect(() => {
    if (selectedSubdivision) {
      setBackgroundTestUrl(
        selectedSubdivision.background_test_url
          ? selectedSubdivision.background_test_url
          : '',
      );
      setbiLingualTestUrl(
        selectedSubdivision.bi_lingual_test_url
          ? selectedSubdivision.bi_lingual_test_url
          : '',
      );
      setDrugTestUrl(
        selectedSubdivision.drug_test_url
          ? selectedSubdivision.drug_test_url
          : '',
      );
      setEmailMessage(
        selectedSubdivision.email_message
          ? selectedSubdivision.email_message
          : '',
      );
    } else {
      setBackgroundTestUrl('');
      setbiLingualTestUrl('');
      setDrugTestUrl('');
      setEmailMessage('');
    }
  }, [selectedSubdivision]);
  
  const updateSubdivisionDetails = async () => {
    const body = {
      background_test_url: backgroundTestUrl,
      bi_lingual_test_url: biLingualTestUrl,
      drug_test_url: drugTestUrl,
      email_message: emailMessage,
    };
    dispatch(updateSubdivision({ subdivisionId: selectedSubdivision.id, body }))
      .then(() => {
        setOpenToast(true);
        if (selectedDivision?.id && currentContract?.id) {
          dispatch(getSubdivisionByDivisionAndContractId({
            divisionId: selectedDivision.id,
            contractId: currentContract.id,
          }));
        }
      });
  };
  
  return (
    <div className="contract-form-section">
      <div className="contract-form-section-header">Subdivision Details</div>
      <div className="contract-form-section-content">
        <div className="contract-form-set">
          <div className="contract-form-label">Background Test</div>
          <input
            className="contract-form-input"
            placeholder="Enter URL..."
            value={backgroundTestUrl}
            onChange={(e) => setBackgroundTestUrl(e.target.value)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Bi-Lingual Test</div>
          <input
            className="contract-form-input"
            placeholder="Enter URL..."
            value={biLingualTestUrl}
            onChange={(e) => setbiLingualTestUrl(e.target.value)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Drug Test</div>
          <input
            className="contract-form-input"
            placeholder="Enter URL..."
            value={drugTestUrl}
            onChange={(e) => setDrugTestUrl(e.target.value)}
          />
        </div>
        <div className="contract-form-set-full">
          <div className="contract-form-label">Email Message</div>
          <textarea
            className="contract-form-input-email"
            placeholder="Enter Email Message..."
            value={emailMessage}
            onChange={(e) => setEmailMessage(e.target.value)}
            rows={5}
          />
        </div>
      </div>
      <div className="contract-form-button-container">
        <button
          className="create-button"
          onClick={() => updateSubdivisionDetails()}
        >
          Update
        </button>
      </div>
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message="Subdivision details saved"
        />
      )}
    </div>
  );
};
