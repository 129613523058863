import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { ProjectTabs } from 'components/projects/project-tabs';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';

import { numberWithCommas } from 'shared/formatting';
import { formatUTCDate } from 'shared/formatting';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { projectsSelector } from 'store/projects/selectors';
import { setCurrentPage, setProjectsTabStatus, setSelectedProject } from 'store/projects/slice';
import { Pagination, Stack } from '@mui/material';

export const AllProjectsTab = () => {
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  
  const dispatch = useAppDispatch();
  const {
    projects,
    projectsLoading,
    projectsTabStatus,
    selectedProject,
    currentPage,
    totalPages,
  } = useAppSelector(projectsSelector);
  
  const handleChangeStatus = (status) => {
    dispatch(setProjectsTabStatus(status));
  };
  
  useEffect(() => {
    if (selectedProject && projects.length > 0) {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    } else {
      // dispatch(setSelectedProject(projects[0]));
    }
  }, [scrollRef?.current, projects]);
  
  return (
    <>
      <ProjectTabs
        currentTab={projectsTabStatus}
        setCurrentTab={handleChangeStatus}
        tabs={['Pending', 'Current', 'Completed', 'Archived']}
      />
      <div
        className="projects__content-container"
        style={{ height: 'calc(100vh - 220px)' }}
      >
        {projectsLoading ? (
          <div className="content-loading">
            <ProgressSpinnerNavy/>
          </div>
        ) : (
          <table className="contracts-table tableLayout">
            <thead>
            <tr className="row-border">
              <th scope="col" className="contracts-header tableLayout__th tableLayout__th--blue">
                Project Name
              </th>
              <th scope="col" className="contracts-header tableLayout__th tableLayout__th--blue">
                Project Manager
              </th>
              <th scope="col" className="contracts-header tableLayout__th tableLayout__th--blue">
                Project Start Date
              </th>
              <th scope="col" className="contracts-header tableLayout__th tableLayout__th--blue">
                Project End Date
              </th>
              <th scope="col" className="contracts-header tableLayout__th tableLayout__th--blue">
                Estimated Value
              </th>
              <th scope="col" className="arrow tableLayout__th tableLayout__th--blue"/>
            </tr>
            </thead>
            <tbody>
            {projects.length > 0 && projects?.map((project) => (
              <tr
                ref={selectedProject && project?.id === selectedProject?.id ? scrollRef : null}
                key={project?.id}
                className={classNames('projects__row 1projects__row', {
                  'projects__row--selected': project?.id === selectedProject?.id,
                })}
                onClick={() => {
                  dispatch(setSelectedProject(project));
                  navigate(`/project-portal/${project?.id}`);
                }}
              >
                <td className="contract-data">
                  {project?.project_name}
                </td>
                <td className="contract-data">
                  {`${project?.project_manager_data?.first_name} ${project?.project_manager_data?.last_name}`}
                </td>
                <td className="contract-data">
                  {formatUTCDate(project?.project_start_date)}
                </td>
                <td className="contract-data">
                  {formatUTCDate(project?.project_end_date)}
                </td>
                <td className="contract-data">
                  {project?.estimated_value ? numberWithCommas(project?.estimated_value) : 'N/A'}
                </td>
                <td className="contract-data">
                  <i className={`fa-solid fa-angle-right tab-arrow-icon navy-arrow`}/>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        )}
      </div>
      <Stack
        sx={{
          alignItems: 'center',
          marginTop: 1.5,
          marginBottom: '-26px',
        }}
      >
        <Pagination
          count={+totalPages}
          page={+currentPage}
          onChange={(e, value) => dispatch(setCurrentPage(value))}
          color="primary"
          size="large"
        />
      </Stack>
    </>
  );
};
