import '../../css/contracts.scss';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressSpinner from 'components/progress/progress-spinner';
import { formatPhoneNumber } from 'shared/formatting';
import {
  archivedApplicantsLoadingSelector, archivedApplicantsPageSelector,
  archivedApplicantsSelector,
  selectedArchivedApplicantSelector, totalArchivedApplicantsPagesSelector,
} from 'store/applicants/selectors.js';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import classNames from 'classnames';
import { setArchivedApplicantsPage, setSelectedArchivedApplicant } from 'store/applicants/slice';
import { Pagination, Stack } from '@mui/material';

export default function ArchivedApplicantsTable() {
  const scrollRef = useRef(null);
  
  const dispatch = useAppDispatch();
  const archivedApplicants = useAppSelector(archivedApplicantsSelector);
  const selectedArchivedApplicant = useAppSelector(selectedArchivedApplicantSelector);
  const archivedApplicantsLoading = useAppSelector(archivedApplicantsLoadingSelector);
  const currentPage = useAppSelector(archivedApplicantsPageSelector);
  const totalPages = useAppSelector(totalArchivedApplicantsPagesSelector);

  const navigate = useNavigate();
  
  useEffect(() => {
    if (selectedArchivedApplicant) {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  }, [scrollRef?.current]);
  
  return (
    <>
      <h1 className="content-header">Archived Applicants</h1>
      <div
        className="content-container no-content-padding"
        style={{
          height: +totalPages > 1 ? 'calc(100vh - 244px)' : 'calc(100vh - 206px)',
        }}
      >
        {archivedApplicantsLoading ? (
          <div className="contracts-loading-spinner">
            <ProgressSpinner/>
          </div>
        ) : (
          <table className="contracts-table tableLayout">
            <thead>
            <tr className="row-border">
              <th scope="col" className="contracts-header tableLayout__th">
                Name
              </th>
              <th scope="col" className="contracts-header tableLayout__th">
                Phone Number
              </th>
              <th scope="col" className="contracts-header url tableLayout__th">
                Email Address
              </th>
              <th scope="col" className="arrow tableLayout__th"/>
            </tr>
            </thead>
            <tbody>
            {archivedApplicants?.map((applicant, index) => (
              <tr
                ref={selectedArchivedApplicant && applicant?.id === selectedArchivedApplicant?.id ? scrollRef : null}
                key={applicant?.id}
                className={classNames('contract-row', {
                  'row-border': index !== archivedApplicants.length - 1,
                  'contract-row--selected': selectedArchivedApplicant?.id === applicant?.id,
                })}
                onClick={() => {
                  dispatch(setSelectedArchivedApplicant(applicant));
                  navigate(`/applicant-portal/${applicant?.id}`);
                }}
              >
                <td className="contract-data">
                  {applicant?.first_name} {applicant?.last_name}
                </td>
                <td className="contract-data">
                  {formatPhoneNumber(applicant?.phone)}
                </td>
                <td className="contract-data">
                  {applicant?.email}
                </td>
                <td className="contract-data">
                  <i className={`fa-solid fa-angle-right tab-arrow-icon green-arrow`}/>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        )}
      </div>
      {+totalPages > 1 && (
        <Stack
          sx={{
            alignItems: 'center',
            marginTop: 1.5,
            marginBottom: '-16px',
          }}
        >
          <Pagination
            count={+totalPages}
            page={+currentPage}
            onChange={(e, value) => dispatch(setArchivedApplicantsPage(value))}
            color="primary"
            size="large"
          />
        </Stack>
      )}
    </>
  );
}
