import { createSlice } from '@reduxjs/toolkit';
import {
  createProjectsFileType,
  deleteProjectFile,
  editProjectFile,
  getProject,
  getProjectFiles,
  getProjectFilesByCategory,
  getProjectsAnalytics,
  getProjectsByStatus,
  getProjectsCoordinators,
  getProjectsFileFolders,
  getProjectsFileTypes,
  getProjectsManagers,
  getProjectTasks,
  getTotalActiveProjects,
  getTotalProjectsValue,
  updateProject,
} from 'store/projects/thunk';

const initialState = {
 projects: {
   projectsTabStatus: 'Pending',
   projects: [],
   projectsLoading: false,
   getProjectsError: '',
   selectedProject: null,
   currentPage: 1,
   totalPages: 1,
 },
  projectsAnalytics: {
    projectsAnalytics: null,
    loadingAnalytics: false,
    errorAnalytics: '',
  },
  projectsCoordinators: {
    projectCoordinators: [],
    loadingProjectCoordinators: false,
    errorProjectCoordinators: '',
    selectedCoordinator: '',
  },
  projectsManagers: {
    projectManagers: [],
    loadingProjectManagers: false,
    errorProjectManagers: '',
    selectedManager: '',
  },
  projectsMetrics: {
    totalActiveProjects: 0,
    loadingTotalActiveProjects: false,
    totalProjectsValue: 0,
    loadingTotalProjectsValue: false,
  },
  project: {
    project: null,
    projectNotes: [],
    getProjectLoading: false,
    getProjectError: '',
    updateProjectLoading: false,
    updateProjectError: '',
  },
  projectTasks: {
    projectTasks: [],
    getProjectTasksLoading: false,
    getProjectTasksError: '',
    page: 1,
    totalPages: 1,
    currentTab: 'assigned',
  },
  projectFiles: {
    projectFiles: [],
    getProjectFilesLoading: false,
    getProjectFilesError: '',
    projectFilesByCategory: [],
    getProjectFilesByCategoryLoading: false,
    getProjectFilesByCategoryError: "",
    projectFileFolders: [],
    getProjectFileFoldersLoading: false,
    getProjectFileFoldersError: '',
    projectFileTypes: [],
    getProjectFileTypesLoading: false,
    getProjectFileTypesError: '',
    createProjectFileTypeLoading: false,
    createProjectFileTypeError: '',
  },
  projectFile: {
   deleteProjectFileLoading: false,
   deleteProjectFileError: '',
   editProjectFileLoading: false,
   editProjectFileError: '',
  }
};

const projectsSlice = createSlice({
  name: 'projectsSlice',
  initialState,
  reducers: {
    setProjectsTabStatus(state, { payload }) {
      state.projects.projectsTabStatus = payload;
    },
    setSelectedProject(state, { payload }) {
      state.projects.selectedProject = payload;
    },
    setSelectedProjectManager(state, { payload }) {
      state.projectsManagers.selectedManager = payload;
    },
    setSelectedProjectCoordinator(state, { payload }) {
      state.projectsCoordinators.selectedCoordinator = payload;
    },
    setProjectNotes(state, { payload }) {
      state.project.projectNotes = payload;
    },
    resetCurrentPage(state) {
      state.projects.currentPage = 1;
    },
    setCurrentPage(state, { payload }) {
      state.projects.currentPage = payload;
    },
    setCurrentTaskTab(state, { payload }) {
      state.projectTasks.currentTab = payload;
    },
    setCurrentTaskPage(state, { payload }) {
      state.projectTasks.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectsByStatus.pending, (state) => {
      state.projects.projectsLoading = true;
      state.projects.getProjectsError = '';
    });
    builder.addCase(getProjectsByStatus.fulfilled, (state, { payload }) => {
      if (payload) {
        state.projects.projectsLoading = false;
        state.projects.getProjectsError = '';
        state.projects.projects = payload.data;
        state.projects.totalPages = payload.meta.last_page;
      }
    });
    builder.addCase(getProjectsByStatus.rejected, (state) => {
      state.projects.projectsLoading = false;
      state.projects.getProjectsError = 'Error Getting Projects';
      state.projects.projects = [];
    });
   
    builder.addCase(getProjectsAnalytics.pending, (state) => {
      state.projectsAnalytics.loadingAnalytics = true;
      state.projectsAnalytics.getProjectsError = '';
    });
    builder.addCase(getProjectsAnalytics.fulfilled, (state, { payload }) => {
      state.projectsAnalytics.loadingAnalytics = false;
      state.projectsAnalytics.errorAnalytics = '';
      state.projectsAnalytics.projectsAnalytics = payload;
    });
    builder.addCase(getProjectsAnalytics.rejected, (state) => {
      state.projectsAnalytics.loadingAnalytics = false;
      state.projectsAnalytics.errorAnalytics = 'Error Getting Project Analytics';
      state.projectsAnalytics.projectsAnalytics = null;
    });
    
    builder.addCase(getProjectsCoordinators.pending, (state) => {
      state.projectsCoordinators.loadingProjectCoordinators = true;
      state.projectsCoordinators.errorProjectCoordinators = '';
    });
    builder.addCase(getProjectsCoordinators.fulfilled, (state, { payload }) => {
      state.projectsCoordinators.loadingProjectCoordinators = false;
      state.projectsCoordinators.errorProjectCoordinators = '';
      state.projectsCoordinators.projectCoordinators = payload;
    });
    builder.addCase(getProjectsCoordinators.rejected, (state) => {
      state.projectsCoordinators.loadingProjectCoordinators = false;
      state.projectsCoordinators.errorProjectCoordinators = 'Error Getting Project Coordinators';
      state.projectsCoordinators.projectCoordinators = '';
    });
    
    builder.addCase(getProjectsManagers.pending, (state) => {
      state.projectsManagers.loadingProjectManagers = true;
      state.projectsManagers.errorProjectManagers = '';
    });
    builder.addCase(getProjectsManagers.fulfilled, (state, { payload }) => {
      state.projectsManagers.loadingProjectManagers = false;
      state.projectsManagers.errorProjectManagers = '';
      state.projectsManagers.projectManagers = payload;
    });
    builder.addCase(getProjectsManagers.rejected, (state) => {
      state.projectsManagers.loadingProjectManagers = false;
      state.projectsManagers.errorProjectManagers = 'Error Getting Project Managers';
      state.projectsManagers.projectManagers = '';
    });
    
    builder.addCase(getTotalActiveProjects.pending, (state) => {
      state.projectsMetrics.loadingTotalActiveProjects = true;
    });
    builder.addCase(getTotalActiveProjects.fulfilled, (state, { payload }) => {
      state.projectsMetrics.loadingTotalActiveProjects = false;
      state.projectsMetrics.totalActiveProjects = payload.total_active;
    });
    builder.addCase(getTotalActiveProjects.rejected, (state) => {
      state.projectsMetrics.loadingTotalActiveProjects = false;
      state.projectsMetrics.totalActiveProjects = 0;
    });
    
    builder.addCase(getTotalProjectsValue.pending, (state) => {
      state.projectsMetrics.loadingTotalProjectsValue = true;
    });
    builder.addCase(getTotalProjectsValue.fulfilled, (state, { payload }) => {
      state.projectsMetrics.loadingTotalProjectsValue = false;
      state.projectsMetrics.totalProjectsValue = payload.total_estimated_value;
    });
    builder.addCase(getTotalProjectsValue.rejected, (state) => {
      state.projectsMetrics.loadingTotalProjectsValue = false;
      state.projectsMetrics.totalProjectsValue = 0;
    });
 
    builder.addCase(getProject.pending, (state) => {
      state.project.getProjectLoading = true;
      state.project.getProjectError = '';
    });
    builder.addCase(getProject.fulfilled, (state, { payload }) => {
      state.project.getProjectLoading = false;
      state.project.getProjectError = '';
      state.project.project = payload;
      state.project.projectNotes = payload.notes;
    });
    builder.addCase(getProject.rejected, (state) => {
      state.project.getProjectLoading = false;
      state.project.getProjectError = 'Error getting project.';
    });
    
    builder.addCase(updateProject.pending, (state) => {
      state.project.updateProjectLoading = true;
      state.project.updateProjectError = '';
    });
    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      state.project.updateProjectLoading = false;
      state.project.updateProjectError = '';
      state.project.project = payload;
    });
    builder.addCase(updateProject.rejected, (state) => {
      state.project.updateProjectLoading = false;
      state.project.updateProjectError = 'Error updating project.';
    });
    
    builder.addCase(getProjectTasks.pending, (state) => {
      state.projectTasks.getProjectTasksLoading = true;
      state.projectTasks.getProjectTasksError = '';
    });
    builder.addCase(getProjectTasks.fulfilled, (state, { payload }) => {
      state.projectTasks.getProjectTasksLoading = false;
      state.projectTasks.getProjectTasksError = '';
      state.projectTasks.totalPages = payload.meta.last_page;
      state.projectTasks.projectTasks = [...payload.data].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    });
    builder.addCase(getProjectTasks.rejected, (state) => {
      state.projectTasks.getProjectTasksLoading = false;
      state.projectTasks.getProjectTasksError = 'Error getting project tasks.';
      state.projectTasks.projectTasks = [];
    });
    
    builder.addCase(getProjectFiles.pending, (state) => {
      state.projectFiles.getProjectFilesLoading = true;
      state.projectFiles.getProjectFilesError = '';
    });
    builder.addCase(getProjectFiles.fulfilled, (state, { payload }) => {
      state.projectFiles.getProjectFilesLoading = false;
      state.projectFiles.getProjectFilesError = '';
      state.projectFiles.projectFiles = payload;
    });
    builder.addCase(getProjectFiles.rejected, (state) => {
      state.projectFiles.getProjectFilesLoading = false;
      state.projectFiles.getProjectFilesError = 'Error getting project files.';
      state.projectFiles.projectFiles = [];
    });
    
    builder.addCase(getProjectFilesByCategory.pending, (state) => {
      state.projectFiles.getProjectFilesByCategoryLoading = true;
      state.projectFiles.getProjectFilesByCategoryError = '';
    });
    builder.addCase(getProjectFilesByCategory.fulfilled, (state, { payload }) => {
      state.projectFiles.getProjectFilesByCategoryLoading = false;
      state.projectFiles.getProjectFilesByCategoryError = '';
      state.projectFiles.projectFilesByCategory = payload;
    });
    builder.addCase(getProjectFilesByCategory.rejected, (state) => {
      state.projectFiles.getProjectFilesByCategoryLoading = false;
      state.projectFiles.getProjectFilesByCategoryError = 'Error getting project files by category.';
      state.projectFiles.projectFilesByCategory = [];
    });
    
    builder.addCase(getProjectsFileFolders.pending, (state) => {
      state.projectFiles.getProjectFileFoldersLoading = true;
      state.projectFiles.getProjectFileFoldersError = '';
    });
    builder.addCase(getProjectsFileFolders.fulfilled, (state, { payload }) => {
      state.projectFiles.getProjectFileFoldersLoading = false;
      state.projectFiles.getProjectFileFoldersError = '';
      state.projectFiles.projectFileFolders = payload;
    });
    builder.addCase(getProjectsFileFolders.rejected, (state) => {
      state.projectFiles.getProjectFileFoldersLoading = false;
      state.projectFiles.getProjectFileFoldersError = 'Error getting project file folders.';
      state.projectFiles.projectFileFolders = [];
    });
   
    builder.addCase(deleteProjectFile.pending, (state) => {
      state.projectFile.deleteProjectFileLoading = true;
      state.projectFile.deleteProjectFileError = '';
    });
    builder.addCase(deleteProjectFile.fulfilled, (state) => {
      state.projectFile.deleteProjectFileLoading = false;
      state.projectFile.deleteProjectFileError = '';
    });
    builder.addCase(deleteProjectFile.rejected, (state) => {
      state.projectFile.deleteProjectFileLoading = false;
      state.projectFile.deleteProjectFileError = 'Failed to delete file.';
    });
    
    builder.addCase(editProjectFile.pending, (state) => {
      state.projectFile.editProjectFileLoading = true;
      state.projectFile.editProjectFileError = '';
    });
    builder.addCase(editProjectFile.fulfilled, (state) => {
      state.projectFile.editProjectFileLoading = false;
      state.projectFile.editProjectFileError = '';
    });
    builder.addCase(editProjectFile.rejected, (state) => {
      state.projectFile.editProjectFileLoading = false;
      state.projectFile.editProjectFileError = 'Failed to edit file.';
    });
    
    builder.addCase(getProjectsFileTypes.pending, (state) => {
      state.projectFiles.getProjectFileTypesLoading = true;
      state.projectFiles.getProjectFileTypesError = '';
    });
    builder.addCase(getProjectsFileTypes.fulfilled, (state, { payload }) => {
      state.projectFiles.getProjectFileTypesLoading = false;
      state.projectFiles.getProjectFileTypesError = '';
      state.projectFiles.projectFileTypes = payload;
    });
    builder.addCase(getProjectsFileTypes.rejected, (state) => {
      state.projectFiles.getProjectFileTypesLoading = false;
      state.projectFiles.getProjectFileTypesError = 'Failed to get file types. Please, try later.';
      state.projectFiles.projectFileTypes = [];
    });
    
    builder.addCase(createProjectsFileType.pending, (state) => {
      state.projectFiles.createProjectFileTypeLoading = true;
      state.projectFiles.createProjectFileTypeError = '';
    });
    builder.addCase(createProjectsFileType.fulfilled, (state) => {
      state.projectFiles.createProjectFileTypeLoading = false;
      state.projectFiles.createProjectFileTypeError = '';
    });
    builder.addCase(createProjectsFileType.rejected, (state) => {
      state.projectFiles.createProjectFileTypeLoading = false;
      state.projectFiles.createProjectFileTypeError = 'Failed to create file type.';
    });
  },
});

export const {
  setProjectsTabStatus,
  setSelectedProject,
  setSelectedProjectManager,
  setSelectedProjectCoordinator,
  setProjectNotes,
  resetCurrentPage,
  setCurrentPage,
  setCurrentTaskPage,
  setCurrentTaskTab,
} = projectsSlice.actions;

export default projectsSlice.reducer;
