import "../../css/modal.scss";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useLocation } from 'react-router-dom';

export default function CustomCommsDialog(props) {
  const {
    dialogContent,
    onClose,
    open,
    title,
    titleHighlight,
    sidebarCollapse
  } = props;

  const { pathname } = useLocation();
  const contractPortal = pathname.includes('contract-portal');

  // RD requested left screen placement with transparent background
  // to be able to view applicant profile on right
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        componentsProps={{
          backdrop: {
            style: { backgroundColor: "transparent" }
          }
        }}
        PaperProps={{
          sx: {
            backgroundColor: "#EFEFEF",
            borderRadius: "28px",
            minWidth: sidebarCollapse ? '50%' : "55%",
            position: "fixed",
            height: "95%",
            left: contractPortal ? '35%' : 50,
            m: 0
          }
        }}
      >
        <DialogTitle sx={{ display: "flex", padding: "30px 35px 20px" }}>
          <div className={"dialog-title"}>
            {title}
            <span className="dialog-title-highlight"> {titleHighlight}</span>
          </div>
          <IconButton
            onClick={onClose}
            sx={{
              padding: 0,
              top: "-20px",
              color: "inherit",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#62AB37"
              },
            }}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0 35px 35px" }}>
          {dialogContent}
        </DialogContent>
      </Dialog>
    </>
  );
}
