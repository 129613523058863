import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import 'css/analytics.scss';
import 'css/pages.scss';
import 'css/metrics.scss';

import API from 'API';

import { HighlightBlueAutoStyledButton } from 'components/buttons/button-styles';
import { ProgressSpinnerBlue } from '../../progress/progress-spinner-blue';
import classNames from 'classnames';

export const ExecutiveAccounting = ({
  setAccountingView,
  getLastUpdate,
}) => {
  const token = JSON.parse(localStorage.getItem('token'));

  const [incomeNetChange, setIncomeNetChange] = useState(null);
  const [incomeBalance, setIncomeBalance] = useState(null);
  const [balanceSheetNetChange, setBalanceSheetNetChange] = useState(null);
  const [balanceSheetBalance, setBalanceSheetBalance] = useState(null);
  const [loading, setLoading] = useState(false);

  const today = new Date();
  const maxDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const [firstMonth, setFirstMonth] = useState(null);
  const [lastMonth, setLastMonth] = useState(today);

  const handleMonthSelect = (date, setter) => {
    if (setter === setFirstMonth) {
      const selectedMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      setFirstMonth(selectedMonth);
    } else if (setter === setLastMonth) {
      let selectedMonth;
      if (today.getMonth() === date.getMonth()) {
        selectedMonth = new Date();
      } else {
        selectedMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }
      setLastMonth(selectedMonth);
    }
  };

  const formatDate = (date, setter) => {
    return date != null ? `${('0' + (date.getMonth() + 1)).slice(-2)}/${('0' + (setter === setFirstMonth ? 1 : date.getDate())).slice(-2)}/${date.getFullYear()}` : '';
  };

  useEffect(() => {
    const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    setFirstMonth(firstOfMonth);
    filterLedger(firstOfMonth, today);
  }, []);

  const filterLedger = (start, end) => {
    const startDate = start.toISOString().split('T')[0];
    const endDate = end.toISOString().split('T')[0];
    getIncomeStatement(startDate, endDate);
    getBalanceSheet(startDate, endDate);
  };

  const getIncomeStatement = (startDate, endDate) => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/ledger_filter`, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'Income Statement',
        'start_date': startDate,
        'end_date': endDate,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        setIncomeNetChange(json['Net Change']);
        setIncomeBalance(json['Balance']);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getBalanceSheet = (startDate, endDate) => {
    setLoading(true);
    fetch(`${API.endpointURL}/api/ledger_filter`, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'Balance Sheet',
        'start_date': startDate,
        'end_date': endDate,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        setBalanceSheetNetChange(json['Net Change']);
        setBalanceSheetBalance(json['Balance']);
      })
      .catch((error) => setLoading(false));
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className="content-header content-header-flex">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2>Accounting</h2>
        </div>
      </div>

      {loading && (
        <div className="spinner-analytics">
          <ProgressSpinnerBlue />
        </div>
      )}
      <div
        className={classNames('metrics-content-container', {
          'metrics-content-container-transparent': loading,
        })}
        style={{ height: 'fit-content' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 20px 30px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '24px' }}>
              <div style={{ fontFamily: 'Korolev Medium', marginRight: '12px' }}>From:</div>
              <DatePicker
                wrapperClassName="date-picker"
                selected={firstMonth}
                onChange={(date) => handleMonthSelect(date, setFirstMonth)}
                showMonthYearPicker
                dateFormat={formatDate(firstMonth, setFirstMonth)}
                dropdownMode="select"
                placeholderText="Select first month"
                maxDate={lastMonth}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ fontFamily: 'Korolev Medium', marginRight: '12px' }}>To:</div>
              <DatePicker
                wrapperClassName="date-picker"
                selected={lastMonth}
                onChange={(date) => handleMonthSelect(date, setLastMonth)}
                showMonthYearPicker
                dateFormat={formatDate(lastMonth, setLastMonth)}
                dropdownMode="select"
                placeholderText="Select last month"
                minDate={firstMonth}
                maxDate={maxDate}
              />
            </div>
            <HighlightBlueAutoStyledButton
              onClick={() => filterLedger(firstMonth, lastMonth)}
              disabled={!firstMonth || !lastMonth}
              style={{ marginLeft: '30px', width: '15%' }}
            >
              Apply
            </HighlightBlueAutoStyledButton>
          </div>
        </div>
        <div className="contract-form-section">
          <div className="contract-form-section-header header-row" style={{ padding: 0 }}>
            <button
              onClick={() => setAccountingView('balance sheet')}
              style={{
                background: 'transparent',
                border: 0,
                color: '#EFEFEF',
                fontFamily: 'MagistralCondW08-Medium',
                fontSize: '25px',
                lineHeight: '34px',
              }}
            >
              Balance Sheet
              <i
                className="fa-solid fa-angle-right blue-arrow"
                style={{ marginLeft: '12px', color: '#00A5CF' }}
              />
            </button>
          </div>
          <div className="contract-form-section-content" style={{ margin: '30px 0 50px' }}>
            <div className="stage-stat-container" style={{ width: 'auto' }}>
              <div className="stage-stat-header">Net Change</div>
              <div className="stage-stat-number">
                ${balanceSheetNetChange && balanceSheetNetChange.toLocaleString('en', { useGrouping: true })}
              </div>
            </div>
            <div className="stage-stat-container" style={{ width: 'auto' }}>
              <div className="stage-stat-header">Balance</div>
              <div className="stage-stat-number">
                ${balanceSheetBalance && balanceSheetBalance.toLocaleString('en', { useGrouping: true })}
              </div>
            </div>
          </div>
        </div>
        <div className="contract-form-section">
          <div className="contract-form-section-header header-row" style={{ padding: 0 }}>
            <button
              onClick={() => setAccountingView('income statement')}
              style={{
                background: 'transparent',
                border: 0,
                color: '#EFEFEF',
                fontFamily: 'MagistralCondW08-Medium',
                fontSize: '25px',
                lineHeight: '34px',
              }}
            >
              Income Statement
              <i
                className="fa-solid fa-angle-right blue-arrow"
                style={{ marginLeft: '12px', color: '#00A5CF' }}
              />
            </button>
          </div>
          <div className="contract-form-section-content" style={{ margin: '30px 0 50px' }}>
            <div className="matrix-total-container" style={{ border: 0, padding: '12px 0', width: '224px' }}>
              <div className="total-applicants-number" style={{ lineHeight: '45px' }}>
                Coming soon!
              </div>
              <div className="stage-stat-header" style={{ marginTop: '12px' }}>Budget</div>
            </div>
            <div className="stage-stat-container" style={{ width: 'auto' }}>
              <div className="stage-stat-header">Net Change</div>
              <div className="stage-stat-number">
                ${incomeNetChange && incomeNetChange.toLocaleString('en', { useGrouping: true })}
              </div>
            </div>
            <div className="stage-stat-container" style={{ width: 'auto' }}>
              <div className="stage-stat-header">Balance</div>
              <div className="stage-stat-number">
                ${incomeBalance && incomeBalance.toLocaleString('en', { useGrouping: true })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
