import React, { useEffect, useState } from 'react';

import '../../css/contracts.scss';

import { IconButton, Tooltip } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import CrudContractDivisionSubdivisionModal from '../modal/crud-contract-division-subdivision';
import editButton from '../../assets/edit.png';
import deleteButton from 'assets/delete.png';

import { FilterDropdown } from 'components/filter-fropdown/filter-fropdown';
import { DeleteModal } from 'components/modal/delete-modal';
import Toast from 'components/toast/toast';
import ProgressSpinner from 'components/progress/progress-spinner';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { selectedDivisionSelector } from 'store/divisions/selectors';
import { deleteSubdivision, getSubdivisionByDivisionAndContractId } from 'store/subdivisions/thunk';
import { setSelectedSubdivision } from 'store/subdivisions/slice';
import {
  selectedSubdivisionSelector,
  subdivisionsByDivisionAndContractIdSelector,
  subdivisionsLoadingSelector,
  subdivisionsMessageSelector,
} from 'store/subdivisions/selectors';
import { selectedContractSelector } from 'store/contracts/selectors';

export default function SubdivisionsForm(props) {
  const {
    setCurrentSubdivisionId,
    setOpenEmailSubdivDialog,
  } = props;
  
  const dispatch = useAppDispatch();
  const currentContract = useAppSelector(selectedContractSelector);
  const selectedDivision = useAppSelector(selectedDivisionSelector);
  const subdivisions = useAppSelector(subdivisionsByDivisionAndContractIdSelector);
  const selectedSubdivision = useAppSelector(selectedSubdivisionSelector);
  const loading = useAppSelector(subdivisionsLoadingSelector);
  const subdivisionsMessage = useAppSelector(subdivisionsMessageSelector);
  
  const [addSubdivision, setAddSubdivision] = useState(false);
  const [editSubdivision, setEditSubdivision] = useState(false);
  const [deleteSubdivisionAction, setDeleteSubdivisionAction] = useState(false);
  const [subdivisionsFilter, setSubdivisionsFilter] = useState('open');
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState('');
  
  useEffect(() => {
    if (selectedDivision) {
      if (selectedSubdivision) {
        dispatch(setSelectedSubdivision(null));
      }
      getSubdivisions();
    }
  }, [selectedDivision]);
  
  const getSubdivisions = () => {
    if (selectedDivision?.id && currentContract?.id) {
      dispatch(getSubdivisionByDivisionAndContractId({ divisionId: selectedDivision.id, contractId: currentContract.id }))
    }
  };

  const handleDelete = async () => {
    dispatch(deleteSubdivision({ subdivisionId: selectedSubdivision.id }))
      .then(() => {
        setOpenToast(true);
        getSubdivisions();
        setDeleteSubdivisionAction(false);
        setMessage(subdivisionsMessage);
      })
  };

  const openEmailModalHandler = (id) => {
    setOpenEmailSubdivDialog(true);
    setCurrentSubdivisionId(id);
  };


  const buildSubdivisionsList = () => {
    let subdivisionsList = [];
    if (subdivisions.length > 0) {
      subdivisions
        .filter((subdivision) => {
          if (subdivisionsFilter === 'all subdivisions') {
            return subdivision;
          }
          return subdivision.status === subdivisionsFilter;
        })
        .forEach((subdivision) => {
          subdivisionsList.push(
            <div
              key={subdivision?.id}
              className={selectedSubdivision && subdivision.id === selectedSubdivision.id ? 'division-subdivision selected' : 'division-subdivision'}
              onClick={() => dispatch(setSelectedSubdivision(subdivision))}
            >
              {subdivision.title}
              <Tooltip title="Edit subdivision">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="edit-subdivision"
                  sx={{
                    ml: 1,
                    pt: 0,
                    color: '#3e453d',
                    padding: '0px 5px 3px 0px',
                    marginLeft: '20px',
                  }}
                  onClick={() => {
                    dispatch(setSelectedSubdivision(subdivision));
                    setEditSubdivision(true);
                  }}
                >
                  <img
                    src={editButton}
                    width="20px"
                    height="auto"
                    alt="edit-button"
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete subdivision">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="delete-subdivision"
                  sx={{
                    ml: 1,
                    pt: 0,
                    color: '#3e453d',
                    padding: '0px 5px 2px',
                    marginLeft: '5px',
                  }}
                  onClick={() => {
                    dispatch(setSelectedSubdivision(subdivision));
                    setDeleteSubdivisionAction(true);
                  }}
                >
                  <img
                    src={deleteButton}
                    width="20px"
                    height="auto"
                    alt="edit-button"
                  />
                </IconButton>
              </Tooltip>

              <div className="divisions-email-btn">
                <Tooltip title="Send Email">
                  <IconButton
                    onClick={() => openEmailModalHandler(subdivision.id)}
                    disabled={!selectedDivision}
                    sx={{
                      padding: '0px 5px',
                      marginLeft: '5px',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <MailOutlineIcon
                      sx={{
                        width: '25px',
                        color: '#EFEFEF',
                        '&:hover': {
                          color: '#62AB37',
                        },
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>,
          );
        });
    }

    return (
      <>{subdivisionsList}</>
    );
  };
  
  return (
    <div className="contract-form-section">
      <div className="contract-form-section-header">
        Subdivisions
        <FilterDropdown
          onChange={setSubdivisionsFilter}
          value={subdivisionsFilter}
          options={['open', 'closed', 'archived', 'all subdivisions']}
          style={{ position: 'absolute', top: '-5px', right: 0, width: '25%' }}
        />
      </div>
      <div className="contract-form-section-content">
        {subdivisions.length > 0 && !loading && (buildSubdivisionsList())}
        {loading && <ProgressSpinner />}
        <button
          className="add-button"
          onClick={() => setAddSubdivision(true)}
        >
          + Add Subdivision
        </button>
      </div>
      {addSubdivision && (
        <CrudContractDivisionSubdivisionModal
          action={'add'}
          getSubdivisions={getSubdivisions}
          handleClose={() => setAddSubdivision(false)}
          open={addSubdivision}
          type={'Subdivision'}
          setMessage={setMessage}
          setOpenTost={setOpenToast}
        />
      )}
      {editSubdivision && (
        <CrudContractDivisionSubdivisionModal
          action={'edit'}
          getSubdivisions={getSubdivisions}
          handleClose={() => setEditSubdivision(false)}
          open={editSubdivision}
          type={'Subdivision'}
          setMessage={setMessage}
          setOpenTost={setOpenToast}
        />
      )}
      {deleteSubdivisionAction && (
        <DeleteModal
          open={deleteSubdivisionAction}
          handleClose={() => setDeleteSubdivisionAction(false)}
          title="Delete"
          type="Subdivision"
          name={selectedSubdivision?.title}
          handleDelete={handleDelete}
          loading={loading}
        />
      )}
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={message}
        />
      )}
    </div>
  );
};
