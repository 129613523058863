import React, { useEffect, useState } from "react";

import "../../css/contracts.scss";
import Toast from "../toast/toast";
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { editContractById } from 'store/contracts/thunk';
import { selectedContractSelector } from 'store/contracts/selectors';

export default function ContractVariablesForm() {
  const dispatch = useAppDispatch();
  const currentContract = useAppSelector(selectedContractSelector);
  
  const [totalBudget, setTotalBudget] = useState(null);
  const [goalPhaseOne, setGoalPhaseOne] = useState(null);
  const [goalPhaseTwo, setGoalPhaseTwo] = useState(null);
  const [goalPhaseThree, setGoalPhaseThree] = useState(null);
  const [goalPhaseFour, setGoalPhaseFour] = useState(null);
  const [goalHiresPerWeek, setGoalHiresPerWeek] = useState(null);
  const [goalDailyActions, setGoalDailyActions] = useState(null);
  const [goalDailyHires, setGoalDailyHires] = useState(null);
  const [goalTotalPersonnel, setGoalTotalPersonnel] = useState(null);
  const [goalStageOne, setGoalStageOne] = useState(null);
  const [goalStageTwo, setGoalStageTwo] = useState(null);
  const [goalStageThree, setGoalStageThree] = useState(null);
  const [goalStageFour, setGoalStageFour] = useState(null);
  const [goalStageFive, setGoalStageFive] = useState(null);
  const [goalStageSix, setGoalStageSix] = useState(null);
  const [goalStageSeven, setGoalStageSeven] = useState(null);

  const [changed, setChanged] = useState(false);
  const [error, setError] = useState(null);
  const [openToast, setOpenToast] = useState(false);
  const [openErrorToast, setOpenErrorToast] = useState(false);

  useEffect(() => {
    if (currentContract) {
      setTotalBudget(currentContract.total_budget);
      setGoalPhaseOne(currentContract.goal_phase_one);
      setGoalPhaseTwo(currentContract.goal_phase_two);
      setGoalPhaseThree(currentContract.goal_phase_three);
      setGoalPhaseFour(currentContract.goal_phase_four);
      setGoalHiresPerWeek(currentContract.goal_hires_per_week);
      setGoalDailyActions(currentContract.goal_daily_actions);
      setGoalDailyHires(currentContract.goal_daily_hires);
      setGoalTotalPersonnel(currentContract.personel_need);
      setGoalStageOne(currentContract.goal_stage_one);
      setGoalStageTwo(currentContract.goal_stage_two);
      setGoalStageThree(currentContract.goal_stage_three);
      setGoalStageFour(currentContract.goal_stage_four);
      setGoalStageFive(currentContract.goal_stage_five);
      setGoalStageSix(currentContract.goal_stage_six);
      setGoalStageSeven(currentContract.goal_stage_seven);
    }
  }, [currentContract]);

  const updateContract = () => {
    const fieldsToUpdate = {
      total_budget: totalBudget,
      goal_phase_one: goalPhaseOne,
      goal_phase_two: goalPhaseTwo,
      goal_phase_three: goalPhaseThree,
      goal_phase_four: goalPhaseFour,
      goal_hires_per_week: goalHiresPerWeek,
      goal_daily_actions: goalDailyActions,
      goal_daily_hires: goalDailyHires,
      personel_need: goalTotalPersonnel,
      goal_stage_one: goalStageOne,
      goal_stage_two: goalStageTwo,
      goal_stage_three: goalStageThree,
      goal_stage_four: goalStageFour,
      goal_stage_five: goalStageFive,
      goal_stage_six: goalStageSix,
      goal_stage_seven: goalStageSeven,
    };
    
    const body = {};
    
    for (const key in fieldsToUpdate) {
      if (fieldsToUpdate[key]) {
        body[key] = +fieldsToUpdate[key];
      }
    }
    
    dispatch(editContractById({ contractId: currentContract.id, body }))
      .then(() => {
        setChanged(false);
        setOpenToast(true);
        setError(null);
      })
  };

  const handleChangedData = (e, setField) => {
    setChanged(true);
    setField(e.target.value);
  };

  return (
    <div className="contract-form-section">
      <div
        className="contract-form-section-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          whiteSpace: "nowrap",
          alignItems: "center"
        }}
      >
        Contract Variables
        {changed === true ? (
          <div className="contract-form-button-container">
            <button className="create-button" onClick={() => updateContract()}>
              Update
            </button>
          </div>
        ) : (
          <div className="contract-form-button-container">
            <button
              className="create-button"
              disabled
              style={{
                opacity: 0.6,
                filter: "grayscale(100%)",
                pointerEvents: "none",
              }}
              onClick={() => updateContract()}
            >
              Update
            </button>
          </div>
        )}
      </div>
      <div className="contract-form-section-content">
        <div className="contract-form-set">
          <div className="contract-form-label">Total Budget</div>
          <input
            className="contract-form-input"
            placeholder="Enter Total Budget..."
            type="number"
            min="0"
            value={totalBudget?.toString() || ''}
            onChange={(e) => handleChangedData(e, setTotalBudget)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Phase One Goal</div>
          <input
            className="contract-form-input"
            placeholder="Enter Phase One Goal..."
            type="number"
            min="0"
            value={goalPhaseOne?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalPhaseOne)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Phase Two Goal</div>
          <input
            className="contract-form-input"
            placeholder="Enter Phase Two Goal..."
            type="number"
            min="0"
            value={goalPhaseTwo?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalPhaseTwo)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Phase Three Goal</div>
          <input
            className="contract-form-input"
            placeholder="Enter Phase Three Goal..."
            type="number"
            min="0"
            value={goalPhaseThree?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalPhaseThree)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Phase Four Goal</div>
          <input
            className="contract-form-input"
            placeholder="Enter Phase Four Goal..."
            type="number"
            min="0"
            value={goalPhaseFour?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalPhaseFour)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Hires Per Week Goal</div>
          <input
            className="contract-form-input"
            placeholder="Enter Hires Per Week Goal..."
            type="number"
            min="0"
            value={goalHiresPerWeek?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalHiresPerWeek)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Daily Actions Goal</div>
          <input
            className="contract-form-input"
            placeholder="Enter Daily Actions Goal..."
            type="number"
            min="0"
            value={goalDailyActions?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalDailyActions)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Daily Hires Goal</div>
          <input
            className="contract-form-input"
            placeholder="Enter Daily Hires Goal..."
            type="number"
            min="0"
            value={goalDailyHires?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalDailyHires)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Total Personnel Needed</div>
          <input
            className="contract-form-input"
            placeholder="Enter Total Personnel Needed"
            type="number"
            min="0"
            value={goalTotalPersonnel?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalTotalPersonnel)}
          />
        </div>
      </div>
      {/* New section here */}
      <div className="contract-form-section-header">
        Stage Threshhold
        <p style={{ fontSize: "15px", fontStyle: "italic" }}>
          Enter the amount of days an applicant should spend In each stage to
          meet project deadlines.
        </p>
      </div>
      <div className="contract-form-section-content">
        <div className="contract-form-set">
          <div className="contract-form-label">New Applicant Stage</div>
          <input
            className="contract-form-input"
            placeholder="Enter New Applicant Stage Goal"
            type="number"
            min="0"
            value={goalStageOne?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalStageOne)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Pre-Screen Stage</div>
          <input
            className="contract-form-input"
            placeholder="Enter Pre-Screen Stage Goal"
            type="number"
            min="0"
            value={goalStageTwo?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalStageTwo)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Interview Stage</div>
          <input
            className="contract-form-input"
            placeholder="Enter Interview Stage Goal"
            type="number"
            min="0"
            value={goalStageThree?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalStageThree)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">USG Documents Stage</div>
          <input
            className="contract-form-input"
            placeholder="Enter USG Documents Stage Goal"
            type="number"
            min="0"
            value={goalStageFour?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalStageFour)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Security Stage</div>
          <input
            className="contract-form-input"
            placeholder="Enter Security Stage Goal"
            type="number"
            min="0"
            value={goalStageFive?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalStageFive)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Finalize Stage</div>
          <input
            className="contract-form-input"
            placeholder="Enter Finalize Stage Goal"
            type="number"
            min="0"
            value={goalStageSix?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalStageSix)}
          />
        </div>
        <div className="contract-form-set">
          <div className="contract-form-label">Hired Stage</div>
          <input
            className="contract-form-input"
            placeholder="Enter Hired Stage Goal"
            type="number"
            min="0"
            value={goalStageSeven?.toString() || ''}
            onChange={(e) => handleChangedData(e, setGoalStageSeven)}
          />
        </div>
      </div>
      {changed === true ? (
        <div className="contract-form-button-container">
          <button className="create-button" onClick={() => updateContract()}>
            Update
          </button>
        </div>
      ) : (
        <div className="contract-form-button-container">
          <button
            className="create-button"
            disabled
            style={{
              opacity: 0.6,
              filter: "grayscale(100%)",
              pointerEvents: "none",
            }}
            onClick={() => updateContract()}
          >
            Update
          </button>
        </div>
      )}

      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message={
            error ||
            "There was an issue sending your email. Please try again later."
          }
        />
      )}
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message="Variables Updated"
        />
      )}
    </div>
  );
}
