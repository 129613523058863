import React, { Component } from 'react';
import classNames from 'classnames';

class Table extends Component {
    constructor(props) {
        // columns: ['Item', ...] - determines columns for item
        // children: content for inside table object, enables dropbutton
        super(props);
    }
    
    renderHeader() {
        let c = [];

        this.props.columns.forEach((col, index) => {
            c.push(
                <div key={index} className="table__auto-spaced">{col}</div>
            );
        });
        
        return (
            <div
              className={classNames('table__header tableLayout__th-div tableLayout__th-div--blue', {
                  'tableLayout__th-div--darkGreen': this.props.pathname && this.props.pathname.includes('accounting'),
              })}
              >
                {c}
            </div>
        );
    }

    render() { 
        return (
            <div className="table tableLayout">
                {this.renderHeader()}
                <div className="table__data">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
 
export default Table;