import React from 'react';
import "css/tabs.scss";
import { resetNewContract } from 'store/contracts/slice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setCurrentSalesTab, setSalesStage } from 'store/salesSelectedOptions/slice';
import { salesStageSelector } from 'store/salesSelectedOptions/selectors';
import { resetCurrentPage } from 'store/sales/slice';

export default function SidebarTabs(props) {
  const dispatch = useAppDispatch();
  const { colorPalette, currentTab, setCurrentTab, tabs, page } = props;

  const salesStage = useAppSelector(salesStageSelector);

  const renderTabs = () => {
    let tabsList = [];
    tabs.forEach((tab, index) => {
      tabsList.push(
        <div
          key={index}
          className={index === 0 ? "tab-container first-tab" : "tab-container"}
        >
          <button
            className={currentTab === tab ? `tab-button ${colorPalette}-active` : "tab-button"}
            style={tab.charAt(0) === "*" ? { color: "yellow" } : {}}
            onClick={() => {
              if (tab === 'create new contract') {
                dispatch(resetNewContract());
              }
              if (tab === 'business development') {
                dispatch(setSalesStage('Business Development'));
              }
              if (salesStage === 'Business Development'
                && (tab.includes('pipeline'))) {
                dispatch(setSalesStage('Lead'));
              }
              if (page === 'sales') {
                dispatch(setCurrentSalesTab(tab));
                dispatch(resetCurrentPage());
              } else if (setCurrentTab) {
                setCurrentTab(tab);
              }
            }}
          >
            {tab.charAt(0) === "*" ? tab.substring(1) : tab}
            <i className={`fa-solid fa-angle-right tab-arrow-icon ${colorPalette}-arrow`} />
          </button>
        </div>
      );
    });
    return tabsList;
  }

  return (
    <>{renderTabs()}</>
  );
};
