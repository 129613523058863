import '../../css/modal.scss';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

export default function NewBaseModal(props) {
  const { colorPalette, dialogContent, onClose, open, title, titleHighlight } = props;

  const setIconColor = () => {
    if (colorPalette === 'green') {
      return '#62AB37';
    } else if (colorPalette === 'purple') {
      return '#531CB3';
    } else if (colorPalette === 'blue') {
      return '#00A5CF';
    } else if (colorPalette === 'navy') {
      return '#141A66';
    } else {
      return '#3d3d3d'
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            backgroundColor: '#EFEFEF',
            borderRadius: '28px',
            maxWidth: 'fit-content',
            minWidth: '425px',
            maxHeight: 'calc(100% - 200px)'
          }
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            margin: '20px 35px 20px',
            padding: '0',
          }}
        >
          <div className="new-modal__title">
            {title}
            {titleHighlight && (
              <span className={`new-modal__title--${colorPalette}`}>
                {titleHighlight}
              </span>
            )}
          </div>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: '12px',
              top: '12px',
              zIndex: 2,
              padding: 0,
              '&:hover': { backgroundColor: 'transparent', color: setIconColor() },
            }}
            aria-label='Close'
          >
            <CloseIcon sx={{ fontSize: '2rem' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: '0 35px 35px' }}>
          {dialogContent}
        </DialogContent>
      </Dialog>
    </>
  );
}
