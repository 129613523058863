import React, { useState } from "react";
import { HighlightBlueAutoStyledButton } from "components/buttons/button-styles";
import ProgressSpinner from "components/progress/progress-spinner";
import Toast from "components/toast/toast";
import API from "API";

export default function UploadFilesForm(props) {
  const { applicant, retrieveFiles, templates, userRole } = props;
  const token = JSON.parse(localStorage.getItem("token"));

  const [file, setFile] = useState(null);
  const [fileDisplayName, setFileDisplayName] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [openToast, setOpenToast] = useState(false);
  const [dropboxUploadKey, setDropboxUploadKey] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const uploadFile = () => {
    if (!file || !fileType) {
      setError("Please select a file and provide a file type.");
    } else {
      setLoading(true);
      const formData = new FormData();

      formData.append("file", file);
      if (fileDisplayName && fileDisplayName.length > 0) {
        formData.append("display_name", fileDisplayName);
      }
      formData.append("type", fileType);
      formData.append("user_id", applicant.id);

      fetch(`${API.endpointURL}/api/upload`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          Connection: "keep-alive",
        },
      })
        .then((response) => response.json())
        .then(() => {
          setLoading(false);
          setError(null);
          setFile(null);
          setFileDisplayName(null);
          setFileType(null);
          setDropboxUploadKey(Date.now());
          setOpenToast(true);
          retrieveFiles();
        })
        .catch(() => {
          setLoading(false);
          setError("Please select a file and try again.");
        });
    }
  };

  const renderFileTypeOptions = () => {
    let options = [];
    options.push(
      <option value="" key="none">Select Document Type...</option>
    );
    options.push(
      <option value="resume" key="resume">Resume</option>
    );
    options.push(
        <option value="other" key="other">Other</option>
    );
    templates && templates?.forEach((template) => {
      if (template.active && template.file_active === true && template.template_type !== "has_resume" && template.template_type !== "no_resume") {
        if (userRole === 'applicant' && template.is_file_public) {
          options.push(
            <option value={template.template_type} key={template.id}>
              {template.template_name}
            </option>
          );
        } else if (userRole !== 'applicant') {
          options.push(
            <option value={template.template_type} key={template.id}>
              {template.template_name}
            </option>
          );
        }
      }
    });
    return (
      <>{options}</>
    );
  };

  return (
    <div
      style={{
        maxHeight: "75%",
        overflow: "auto",
        width: "100%",
      }}
    >
      {loading ? (
        <div className="contracts-loading-spinner">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "50px", 
            }}
          >
            <div className="contract-form-set-full">
              <div className="contract-form-label">Document Name</div>
              <input
                key={`file-name-${dropboxUploadKey}`}
                className="contract-form-input"
                placeholder="Enter Document Name..."
                value={fileDisplayName || ''}
                onChange={(e) => setFileDisplayName(e.target.value)}
              />
            </div>
            <div className="contract-form-set-full">
              <div className="contract-form-label">Document Type *</div>
              <select
                key={`file-type-${dropboxUploadKey}`}
                className="contract-form-input"
                placeholder="Enter Document Type..."
                value={fileType || ''}
                onChange={(e) => setFileType(e.currentTarget.value)}
              >
                {renderFileTypeOptions()}
              </select>
            </div>
            <div className="belles-file-upload">
              <input
                key={`selected-file-${dropboxUploadKey}`}
                type="file"
                name="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
          </div>
          {error && (
            <div
              style={{
                color: "#EFEFEF",
                fontFamily: "Korolev Medium",
                fontSize: "14px",
                marginBottom: "16px",
              }}
            >
              {error}
            </div>
          )}
          <HighlightBlueAutoStyledButton
            onClick={() => uploadFile()}
            style={{ width: "33%" }}
          >
            Upload
          </HighlightBlueAutoStyledButton>
        </>
      )}
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message="Document uploaded"
        />
      )}
    </div>
  );
}
