import React, { useEffect, useState } from 'react';
import { TextField, Select, MenuItem, Divider } from '@mui/material';
import Switch from '@mui/material/Switch';
import { HighlightNavyAutoStyledButton } from 'components/buttons/button-styles';
import InputAdornment from '@mui/material/InputAdornment';
import { numberWithCommas } from 'shared/formatting';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { openCardsOnSalePortalSelector } from 'store/ui/selectors';
import { setOpenCardsOnSalePortal } from 'store/ui/slice';
import classNames from 'classnames';
import { quoteCategoriesSelector } from 'store/quotes/selectors';
import { saleSelector } from 'store/sales/selectors';

export const SalesQuoteCreatorForm = ({
  quoteForm,
  setQuoteForm,
  handleCreate,
  isCreating,
  errors,
  setErrors,
  handleUpdate,
  loadingButton,
}) => {

  /* Usage
   * For company:
   * <input onChange={(e) => handleChange(e, 'cost_to_company')} ... />
   * For margin:
   * <input onChange={(e) => handleChange(e, 'profit_margin')} ... />
   * For quoted:
   * <input onChange={(e) => handleChange(e, 'cost_quoted')} ... />
   */
  const dispatch = useAppDispatch();
  const openCards = useAppSelector(openCardsOnSalePortalSelector);
  const categories = useAppSelector(quoteCategoriesSelector);
  
  const [displayValueMargin, setDisplayValueMargin] = useState(quoteForm.profit_margin);
  const [displayValueQuoted, setDisplayValueQuoted] = useState(quoteForm.cost_quoted);
  const [displayValueQuantity, setDisplayValueQuantity] = useState(quoteForm.quantity);
  const [displayValuePricePerUnit, setDisplayValuePricePerUnit] = useState(quoteForm.price_per_unit);

  const { sale } = useAppSelector(saleSelector);

  useEffect(() => {
    if (sale?.total_time_amount) {
      setErrors({
        ...errors,
        time: false,
      });
    }
  }, [sale?.total_time_amount]);

  useEffect(() => {
    if (quoteForm.quote_type === 'other') {
      setErrors({
        ...errors,
        time: false,
      })
    }
  }, [quoteForm.quote_type]);

  useEffect(() => {
    setDisplayValueQuoted(quoteForm.cost_quoted || '');
    setDisplayValueMargin(quoteForm.profit_margin || '');
    setDisplayValueQuantity(quoteForm.quantity || '');
    setDisplayValuePricePerUnit(quoteForm.price_per_unit || '');
  }, [quoteForm?.service_description]);
  
  const handlersMap = {
    cost_quoted: {
      displaySetter: setDisplayValueQuoted,
      errorKey: 'cost_quoted',
    },
    profit_margin: {
      displaySetter: setDisplayValueMargin,
      errorKey: 'profit_margin',
    },
    quantity: {
      displaySetter: setDisplayValueQuantity,
      errorKey: 'quantity',
    },
    price_per_unit: {
      displaySetter: setDisplayValuePricePerUnit,
      errorKey: 'price_per_unit',
    },
  };

  /* Responsible for setting value
   * displayed to a user (with commas for thousands)
   */
  const handleChange = (e, fieldKey) => {
    const value = e.target.value;

    // Process the input value
    const rawNumericValue = value.replace(/[^\d.]/g, '');
    let formattedValue;
    if (rawNumericValue.includes('.')) {
      const index = rawNumericValue.indexOf('.');
      formattedValue = numberWithCommas(rawNumericValue.slice(0, index + 3));
    } else {
      formattedValue = numberWithCommas(rawNumericValue);
    }

    // Use the handlersMap to get specific setters and error keys
    const { displaySetter, errorKey } = handlersMap[fieldKey];

    // Set the value
    displaySetter(formattedValue);
    setQuoteForm(prevState => ({ ...prevState, [fieldKey]: rawNumericValue }));

    // If the processed value is empty, set error, else clear error
    if (!rawNumericValue) {
      setErrors(prevErrors => ({ ...prevErrors, [errorKey]: true }));
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [errorKey]: false }));
    }
  };

  /**
   * This is change handle for non-numeric
   * fields in the QuoteCreator form. It should
   * only be used with string fields
   */
  const handleChangeDefaultField = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (value.length === 0) {
      setErrors({
        ...errors,
        [name]: true,
      });
    } else {
      setErrors({
        ...errors,
        [name]: false,
      });
    }

    setQuoteForm({
      ...quoteForm,
      [name]: value,
    });
  };

  /**
   * Calculate the value for cost_quoted/profit_margin
   */
  const handleCalculate = () => {
    if (!quoteForm.cost_to_company
      || !quoteForm.quantity
      || !quoteForm.price_per_unit
      || quoteForm.profit_margin === ''
    ) {
      setErrors({
        ...errors,
        cost_to_company: !quoteForm.cost_to_company,
        quantity: !quoteForm.quantity,
        price_per_unit: !quoteForm.price_per_unit,
        profit_margin: quoteForm.profit_margin === '',
      });
    } else {
      let computedCostQuoted = 0;

      if (quoteForm.calculated === 'cost_quoted') {
        if (quoteForm.cost_to_company) {
          computedCostQuoted = Math.round((Number(quoteForm.cost_to_company)
            * Number(quoteForm.profit_margin)
            / 100
            + Number(quoteForm.cost_to_company)) * 100) / 100;
          setQuoteForm({
            ...quoteForm,
            cost_quoted: computedCostQuoted,
          });
          setDisplayValueQuoted(numberWithCommas(computedCostQuoted));
          setErrors({
            ...errors,
            cost_quoted: false,
          });
        } else {
          setErrors({
            ...errors,
            cost_quoted: true,
          });
        }
      } else {
        if (quoteForm.cost_quoted) {
          setQuoteForm({
            ...quoteForm,
            profit_margin:
              Math.round(((Number(quoteForm.cost_quoted) / Number(quoteForm.cost_to_company)) - 1) * 10000) / 100,
          });
          setErrors({
            ...errors,
            profit_margin: false,
          });
        } else {
          setErrors({
            ...errors,
            cost_quoted: true,
          });
        }
      }
    }
  };

  const handleCalculateCost = () => {
    if (!quoteForm.quantity || !quoteForm.price_per_unit) {
      setErrors({
        ...errors,
        quantity: !quoteForm.quantity,
        price_per_unit: !quoteForm.price_per_unit,
      })
    } else {
      setErrors({
        ...errors,
        cost_to_company: false,
      })
      if (quoteForm.quote_type === 'time_based') {
        if (sale?.total_time_amount) {
          setQuoteForm({
            ...quoteForm,
            cost_to_company:
              Math.round(quoteForm.quantity * quoteForm.price_per_unit * sale?.total_time_amount * 100) / 100,
          })
        } else {
          setErrors({
            ...errors,
            time: true,
          })
        }

      } else {
        setQuoteForm({
          ...quoteForm,
          cost_to_company: Math.round(quoteForm.quantity * quoteForm.price_per_unit * 100) / 100,
        })
      }
    }
  }

  const handleClick = () => {
    handleUpdate();
  };

  const toggleCard = (id) => {
    if (openCards.includes(id)) {
      dispatch(setOpenCardsOnSalePortal(openCards.filter((cardId) => cardId !== id)));
    } else {
      dispatch(setOpenCardsOnSalePortal([...openCards, id]));
    }
  };

  return (
    <div className="sales__quote" id="form">
      <div className="sales__quote__header-container">
        <div className="sales__quote__header">
          <div className="sales__quote--title">
            <p>{isCreating ? 'Create Quote' : 'Update Quote'}</p>
            {!isCreating && openCards.includes('form') && (
              <HighlightNavyAutoStyledButton
                onClick={handleCreate}
                style={{
                  width: '105px',
                  padding: '2px',
                  height: '37px',
                  fontSize: '18px',
                  lineHeight: '18px',
                }}
              >
                Create
              </HighlightNavyAutoStyledButton>
            )}
          </div>
        </div>
        <IconButton
          edge="start"
          color="inherit"
          sx={{ color: '#fdfefe', padding: 0, width: '25px' }}
          onClick={() => toggleCard('form')}
        >
          <KeyboardArrowDownIcon
            sx={{
              transform: openCards.includes('form') ? 'rotate(180deg)' : '',
              transition: 'transform 0.3s ease-in-out',
            }}
          />
        </IconButton>
      </div>
      <div
        className={classNames({
          'sales__quote--form': openCards.includes('form'),
          'sales__quote--form sales__quote--form--hidden': !openCards.includes('form'),
        })}
        style={{ maxHeight: !openCards.includes('form') ? '0px' : 'fit-content' }}
      >
        <div className="sales__container">
          <p className="sales__quote--title">General Quote Information</p>
          <div className="sales__quote--field">
            <p>Service Description</p>
            <TextField
              value={quoteForm.service_description}
              name="service_description"
              onChange={(e) => handleChangeDefaultField(e)}
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '1px 10px 0px',
                marginBottom: 0,
                width: '50%',
              }}
              placeholder="Service Description..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.service_description && (
              <p
                className="sales__quote--form-error"
                style={{ right: '0', top: '-12px' }}
              >
                <b>* Please enter data...</b>
              </p>
            )}
          </div>
          <div className="sales__quote--field sales__quote--field-column">
            <p>Internal Justification</p>
            <TextField
              value={quoteForm.internal_justification}
              name="internal_justification"
              onChange={(e) => handleChangeDefaultField(e)}
              fullWidth
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '1px 10px 0px',
                marginBottom: 0,
              }}
              placeholder="Internal Justification..."
              multiline={true}
              rows={5}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.internal_justification && (
              <p
                className="sale-form-error"
                style={{ right: '3px', top: '15px' }}
              >
                <b>* Please enter data...</b>
              </p>
            )}
          </div>
          <div className="sales__quote--field">
            <p>Category</p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="quote_category_id"
              value={
                Number(quoteForm?.quote_category_id
                ? quoteForm?.quote_category_id
                : categories.find(item => item.is_default)?.id)
              }
              onChange={(e) => handleChangeDefaultField(e)}
              sx={{
                backgroundColor: '#fff',
                border: 0,
                borderRadius: '8px',
                textAlign: 'left',
                width: '50%',
                textTransform: 'capitalize',
                '& .MuiSelect-outlined.MuiOutlinedInput-input': {
                  paddingTop: '6px',
                  paddingBottom: '6px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #a5af96',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #a5af96',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#a5af96',
                },
              }}
            >
              {categories?.map((category) => (
                <MenuItem
                  key={category.id}
                  value={category.id}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {category.category_name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="sales__quote--field sales__quote--field-start">
            <p>Active Quote</p>
            <Switch
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: '#2129bb',
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: '#1f2a9b',
                },
                '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                  backgroundColor: '#c7c7c7',
                },
              }}
              checked={quoteForm.active}
              onChange={() => setQuoteForm({ ...quoteForm, active: !quoteForm.active })}
            />
          </div>

          <Divider
            sx={{ borderBottom: '1px solid #EFEFEF80', width: '100%', margin: '0 0 5px' }}
          />

          <p className="sales__quote--title">Calculate Cost to Company</p>


          <div className="sales__quote--field">
            <p>Quote Type</p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="quote_type"
              value={quoteForm.quote_type}
              onChange={(e) => handleChangeDefaultField(e)}
              sx={{
                backgroundColor: '#fff',
                border: 0,
                borderRadius: '8px',
                textAlign: 'left',
                width: '50%',
                '& .MuiSelect-outlined.MuiOutlinedInput-input': {
                  paddingTop: '6px',
                  paddingBottom: '6px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #a5af96',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #a5af96',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#a5af96',
                },
              }}
            >
              <MenuItem value={'time_based'}>
                Time-based
              </MenuItem>
              <MenuItem value={'other'}>
                Other
              </MenuItem>
            </Select>
          </div>
          <div className="sales__quote--field">
            <p>Quantity</p>
            <TextField
              value={displayValueQuantity}//{quoteForm.cost_to_company}
              name="quantity"
              onChange={(e) => handleChange(e, 'quantity')}
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '1px 10px 0px',
                marginBottom: 0,
                width: '50%',
              }}
              placeholder="Quantity..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.quantity && (
              <p
                className="sales__quote--form-error"
                style={{ right: '0', top: '-12px' }}
              >
                <b>* Please enter data...</b>
              </p>
            )}
          </div>
          <div className="sales__quote--field">
            <p>
              Price per Unit
            </p>
            <TextField
              value={displayValuePricePerUnit}
              name="price_per_unit"
              onChange={(e) => {
                handleChange(e, 'price_per_unit');
              }}
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '1px 10px 0px',
                marginBottom: 0,
                width: '50%',
              }}
              placeholder={'Price per Unit...'}
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
            {errors.price_per_unit && (
              <p
                className="sales__quote--form-error"
                style={{ right: '0', top: '-12px' }}
              >
                <b>* Please enter data...</b>
              </p>
            )}
          </div>
          <div className="sales__quote--field">
            <p>Cost to company</p>
            <TextField
              value={!quoteForm.cost_to_company ? '' : numberWithCommas(quoteForm.cost_to_company)}//{quoteForm.cost_to_company}
              name="cost_to_company"
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '1px 10px 0px',
                marginBottom: 0,
                width: '50%',
              }}
              placeholder="Cost to company..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <HighlightNavyAutoStyledButton
              style={{
                width: '240px',
                padding: '3px',
                height: '37px',
                fontSize: '16px',
                lineHeight: '16px',
              }}
              onClick={handleCalculateCost}
            >
              Calculate
            </HighlightNavyAutoStyledButton>
            {(errors.cost_to_company && !errors.time) && (
              <p
                className="sales__quote--form-error"
                style={{ right: '0', top: '-12px' }}
              >
                <b>* Please press the button</b>
              </p>
            )}
            {errors.time && (
              <p
                className="sales__quote--form-error"
                style={{ right: '0', top: '-12px' }}
              >
                <b>
                  * Please select total amount of time
                </b>
              </p>
            )}
          </div>

          <Divider
            sx={{ borderBottom: '1px solid #EFEFEF80', width: '100%', margin: '5px 0' }}
          />

          <p className="sales__quote--title">Calculate Quote Amount / Profile Margin</p>
          <div className="sales__quote--field">
            <p>Value to calculate</p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="calculated"
              value={quoteForm.calculated}
              onChange={(e) => handleChangeDefaultField(e)}
              sx={{
                backgroundColor: '#fff',
                border: 0,
                borderRadius: '8px',
                textAlign: 'left',
                width: '50%',
                '& .MuiSelect-outlined.MuiOutlinedInput-input': {
                  paddingTop: '6px',
                  paddingBottom: '6px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #a5af96',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #a5af96',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#a5af96',
                },
              }}
            >
              <MenuItem value={'cost_quoted'}>
                Cost quoted
              </MenuItem>
              <MenuItem value={'profit_margin'}>
                Profit margin
              </MenuItem>
            </Select>
          </div>
          <div className="sales__quote--field">
            <p>
              {quoteForm.calculated === 'cost_quoted'
                ? 'Profit margin'
                : 'Cost quoted'
              }
            </p>
            <TextField
              value={quoteForm.calculated === 'cost_quoted' ? displayValueMargin : displayValueQuoted}
              name={quoteForm.calculated === 'cost_quoted' ? 'profit_margin' : 'cost_quoted'}
              onChange={(e) => {
                const fieldKey = quoteForm.calculated === 'cost_quoted' ? 'profit_margin' : 'cost_quoted';
                handleChange(e, fieldKey);
              }}
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '1px 10px 0px',
                marginBottom: 0,
                width: '50%',
              }}
              placeholder={quoteForm.calculated === 'cost_quoted'
                ? 'Profit margin...'
                : 'Cost quoted...'
              }
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    {quoteForm.calculated === 'cost_quoted' ? '%' : '$'}
                  </InputAdornment>
                ),
              }}
            />
            {(quoteForm.calculated === 'cost_quoted'
              ? errors.profit_margin
              : errors.cost_quoted) && (
              <p
                className="sales__quote--form-error"
                style={{ right: '0', top: '-12px' }}
              >
                <b>* Please enter data...</b>
              </p>
            )}
          </div>
          <div className="sales__quote--field">
            <p>
              {quoteForm.calculated === 'cost_quoted'
                ? 'Cost quoted'
                : 'Profit margin'
              }
            </p>
            {/* OUTPUT */}
            <TextField
              className={`
                    sales__quote--info
                    ${(quoteForm.calculated === 'cost_quoted'
                ? !quoteForm.cost_quoted
                : !quoteForm.profit_margin) && 'sales__quote--info--placeholder'}
                `}
              value={(quoteForm.calculated === 'cost_quoted'
                ? `${numberWithCommas(quoteForm.cost_quoted)}`
                : `${numberWithCommas(quoteForm.profit_margin)}`) || ''
              }
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '1px 10px 0px',
                marginBottom: 0,
                width: '40%',
              }}
              placeholder={quoteForm.calculated === 'cost_quoted'
                ? ''
                : ''
              }
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    {quoteForm.calculated === 'cost_quoted' ? '$' : '%'}
                  </InputAdornment>
                ),
              }}
            />

            <HighlightNavyAutoStyledButton
              style={{
                width: '140px',
                padding: '3px',
                height: '37px',
                fontSize: '16px',
                lineHeight: '16px',
              }}
              onClick={handleCalculate}
            >
              Calculate
            </HighlightNavyAutoStyledButton>
            {(quoteForm.calculated === 'cost_quoted'
              ? errors.cost_quoted
              : errors.profit_margin) && (
              <p
                className="sales__quote--form-error"
                style={{ right: '0', top: '-12px' }}
              >
                <b>* Please press the button</b>
              </p>
            )}
          </div>
          {loadingButton && (
            <div className="sale-form-spinner-quote">
              <ProgressSpinnerNavy/>
            </div>
          )}
          <HighlightNavyAutoStyledButton
            style={{
              display: 'flex',
              alignSelf: 'end',
              width: '165px',
              padding: '4px',
              fontSize: '18px',
              margin: '8px 0 -8px'
            }}
            onClick={handleClick}
            disabled={loadingButton}
          >
            {isCreating ? 'Create' : 'Submit'}
          </HighlightNavyAutoStyledButton>
        </div>
      </div>
    </div>
  );
};