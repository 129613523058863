import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import 'css/contracts.scss';
import 'css/table.scss';
import 'css/table-layout.scss';
import classNames from 'classnames';

import ProgressSpinner from 'components/progress/progress-spinner';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getCustomerContracts } from 'store/contracts/thunk';
import {
  setContractsStatus,
  setCurrentContractsPage,
  setFilteredContractsByStatus,
  setSelectedContract,
} from 'store/contracts/slice';
import {
  contractsLoadingSelector,
  contractsSelector,
  contractsStatusSelector,
  currentPageSelector,
  customerContractsSelector,
  filteredContractsByStatusSelector,
  selectedContractSelector,
  totalPagesSelector,
} from 'store/contracts/selectors';
import { Pagination, Stack } from '@mui/material';

export default function ContractsTable() {
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const vendorPortal = pathname.includes('vendor');
  
  const dispatch = useAppDispatch();
  const allContracts = useAppSelector(contractsSelector);
  const totalPages = useAppSelector(totalPagesSelector);
  const currentPage = useAppSelector(currentPageSelector);
  const filteredContractsByStatus = useAppSelector(filteredContractsByStatusSelector);
  const contractsStatus = useAppSelector(contractsStatusSelector);
  const selectedContract = useAppSelector(selectedContractSelector);
  const customerContracts = useAppSelector(customerContractsSelector);
  const contractsLoading = useAppSelector(contractsLoadingSelector);
  
  useEffect(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
  }, [scrollRef.current, contractsStatus]);
  
  useEffect(() => {
    if (vendorPortal) {
      dispatch(setContractsStatus('All Contracts'));
      dispatch(getCustomerContracts({ vendorId: pathname.slice(15) }));
    } else if (!vendorPortal && contractsStatus === '') {
      dispatch(setContractsStatus('Active'));
    }
  }, []);
  
  useEffect(() => {
    const visibleContracts = vendorPortal ? customerContracts : allContracts;
    if (contractsStatus === 'All Contracts') {
      dispatch(setFilteredContractsByStatus(visibleContracts));
    } else {
      const filtered = visibleContracts?.filter(
        (contract) => contract.status === contractsStatus?.toLowerCase(),
      );
      dispatch(setFilteredContractsByStatus(filtered));
    }
  }, [allContracts, contractsStatus, customerContracts]);
  
  return (
    <>
      <div className="header-content">
        <h1 className="content-header">
          {vendorPortal ? 'Staffing requests' : 'Contracts'}
        </h1>
        {!vendorPortal && (
          <div className="contract-filter">
            <select
              id="filter-status"
              className="contract-form-input"
              value={contractsStatus.toString()}
              onChange={(e) => {
                dispatch(setContractsStatus(e.target.value));
                dispatch(setSelectedContract(null))
                dispatch(setCurrentContractsPage(1));
              }}
            >
              <option value="Pending">Pending</option>
              <option value="Active">Active</option>
              <option value="Completed">Completed</option>
              <option value="Archived">Archived</option>
              <option value="All Contracts">All Contracts</option>
            </select>
          </div>
        )}
      </div>
      <div
        className="content-container no-content-padding"
        style={{
          position: 'relative',
          height: totalPages > 1 ? 'calc(100vh - 246px)' : 'calc(100vh - 206px)',
        }}
      >
        {contractsLoading ? (
          <div className="contracts-loading-spinner">
            {vendorPortal ? (
              <ProgressSpinnerBlue/>
            ) : (
              <ProgressSpinner/>
            )}
          </div>
        ) : (
          <table className="contracts-table tableLayout">
            <thead>
            <tr
              ref={selectedContract ? null : scrollRef}
              className="row-border"
            >
              <th scope="col" className="contracts-header title tableLayout__th">
                Title
              </th>
              {contractsStatus === 'All Contracts' ? (
                <th scope="col" className="contracts-header deadline tableLayout__th">
                  Status
                </th>
              ) : (
                ''
              )}
              <th scope="col" className="contracts-header url tableLayout__th">
                Application URL
              </th>
              <th scope="col" className="contracts-header personnel tableLayout__th">
                Personnel Needed
              </th>
              <th scope="col" className="contracts-header deadline tableLayout__th">
                Deadline
              </th>
              
              <th scope="col" className="arrow tableLayout__th"/>
            </tr>
            </thead>
            <tbody>
            {filteredContractsByStatus?.map((contract, index) => (
              <tr
                ref={selectedContract && contract?.id === selectedContract?.id ? scrollRef : null}
                key={contract.id}
                className={classNames('contract-row', {
                  'row-border': index !== filteredContractsByStatus.length - 1,
                  'contract-row--selected': contract?.id === selectedContract?.id
                })}
                onClick={() => {
                  navigate(`/contract-portal/${contract.id}`);
                  dispatch(setSelectedContract(contract))
                }}
              >
                <td className="contract-data">{contract.title}</td>
                {contractsStatus === 'All Contracts'
                  ? (
                    <td className="contract-data">
                      {`${contract?.status[0].toUpperCase()}${contract?.status.slice(1)}`}
                    </td>
                  ) : ('')}
                <td className="contract-data">{contract?.contract_alias}</td>
                <td className="contract-data">
                  {contract?.personel_need &&
                    contract.personel_need.toLocaleString() || 'N/A'}
                </td>
                <td className="contract-data">
                  {contract?.contract_deadline &&
                    new Date(contract.contract_deadline).toLocaleDateString() || 'N/A'}
                </td>
                
                <td className="contract-data">
                  <i
                    className={classNames(
                      'fa-solid fa-angle-right tab-arrow-icon', {
                        'green-arrow': !vendorPortal,
                        'blue-arrow': vendorPortal,
                      },
                    )}
                  />
                </td>
              </tr>
            ))}
            </tbody>
            {vendorPortal && filteredContractsByStatus.length === 0 && !contractsLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                There are no staffing requests for this vendor yet.
              </div>
            )}
          </table>
        )}
      </div>
      {totalPages > 1 && (
        <Stack
          sx={{
            alignItems: 'center',
            marginTop: 1.5,
          }}
        >
          <Pagination
            count={+totalPages}
            page={+currentPage}
            onChange={(e, value) => dispatch(setCurrentContractsPage(value))}
            color="primary"
            size="large"
          />
        </Stack>
      )}
    </>
  );
}
