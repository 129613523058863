import { axiosService } from 'services/axiosServices';
import axios from 'axios';

const serializableHeaders = {
  'cache-control': 'no-cache, private',
  "Content-type": "application/json; charset=UTF-8",
  Accept: "application/json",
};

let cancelTokenSource = null;
let cancelApplicantsToken = null;
let cancelApplicantToken = null;

export const applicants = {
  getArchivedApplicants: async (page)  => {
    const res =  await axiosService.get(`/api/filter/applicants?stage=9&sort=created_at.desc&page=${page}`);
    return res.data;
  },
  getApplicantsByStage: async (currentTab, recruiterId, company, siteId, regionId, contractId, alertsFilter, tagsFilter, groupsFilter,  sort, currentPage) => {
    // Cancel previous request (if any)
    if (cancelApplicantsToken) {
      cancelApplicantsToken.cancel('Request canceled');
    }

    // Create a new cancel token source
    cancelApplicantsToken = axiosService.CancelToken.source();

    try {
      const res = await axiosService.get(
        `/api/filter/applicants` +
        `?stage=${currentTab}` +
        `&recruiter_id=${recruiterId}` +
        `&company=${company}` +
        `&site_id=${siteId}` +
        `&region_id=${regionId}` +
        `&contract_id=${contractId}` +
        `${alertsFilter}` +
        `${groupsFilter}` +
        `${tagsFilter}&sort=${sort}&page=${currentPage}`,
        {
          cancelToken: cancelApplicantsToken.token,
        }
      );

      return res.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle cancellation error
      } else {
        // Handle other errors
        throw error;
      }
    }
  },
  getSearchedApplicants: async (currentTab, recruiterId, company, siteId, regionId, contractId, alertsFilter, groupsFilter, tagsFilter, searchFilter, searchInput, sort, currentPage) => {
    // Cancel previous request (if any)
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Request canceled');
    }

    // Create a new cancel token source
    cancelTokenSource = axiosService.CancelToken.source();

    try {
      const res = await axiosService.get(
        `/api/filter/applicants` +
        `?stage=${currentTab}` +
        `&recruiter_id=${recruiterId}` +
        `&company=${company}` +
        `&site_id=${siteId}` +
        `&region_id=${regionId}` +
        `&contract_id=${contractId}` +
        `${alertsFilter}` +
        `${groupsFilter}` +
        `${tagsFilter}` +
        `&${searchFilter}=${searchInput}` +
        `&sort=${sort}&page=${currentPage}`,
        {
          cancelToken: cancelTokenSource.token,
        }
      );

      return res.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle cancellation error
      } else {
        // Handle other errors
        throw error;
      }
    }
  },
  getApplicant: async (applicantId) => {
    // Cancel previous request (if any)
    if (cancelApplicantToken) {
      cancelApplicantToken.cancel('Request canceled');
    }

    // Create a new cancel token source
    cancelApplicantToken = axiosService.CancelToken.source();
    try {
      const res =  await axiosService.get(`/api/users/${applicantId}`,
        {
          cancelToken: cancelApplicantToken.token,
        });
      return res.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Handle cancellation error
      } else {
        // Handle other errors
        throw error;
      }
    }

  },
  editApplicant: async (applicantId, body) => {
    const res = await axiosService.patch(`/api/applicants/${applicantId}`, body, { headers: serializableHeaders });
    return res.data;
  },
  getApplicantsQuestions: async (contractId, applicantId) => {
    const res = await axiosService.get(`/api/filter/application-answers?page=1&sort[column]=sort_order&sort[direction]=asc&contract_id=${contractId}&applicant_id=${applicantId}`);
    return res.data.data;
  },
}