import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MenuItem, Select, TextField } from '@mui/material';

import NewBaseModal from 'components/modal/new-base-modal';
import Toast from 'components/toast/toast';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { getSale, getSaleQuotes, updateSale } from 'store/sales/thunk';
import { saleSelector, salesQuotesSelector } from 'store/sales/selectors';
import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';
import { setUpdateError } from 'store/sales/slice';

const initialSaleForm = {
  time_rate: null,
  total_time_amount: null,
  stage: '',
};
export const SalesQuoteManageTimeModal = ({ open, onClose }) => {
  const { id } = useParams();
  
  const dispatch = useAppDispatch();
  const { sale, loading, updateError, updateLoading, error } = useAppSelector(saleSelector);
  const { quotes } = useAppSelector(salesQuotesSelector);
  
  const [saleForm, setSaleForm] = useState(initialSaleForm);
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({
    time_rate: false,
    total_time_amount: false,
    type: false,
  });
  
  useEffect(() => {
    dispatch(setUpdateError(''));
  }, []);
  
  useEffect(() => {
    if (sale) {
      setSaleForm({
        ...saleForm,
        time_rate: sale?.time_rate ? sale?.time_rate : '',
        total_time_amount: sale?.total_time_amount ? sale?.total_time_amount : '',
        stage: sale?.stage,
      });
    }
  }, [sale]);
  
  const handleCheckErrors = (name, value, saleForm) => {
    const updatedErrors = { ...errors };
    updatedErrors[name] = value === null || value === '' || (typeof value === 'number' && isNaN(value));
    
    if (name === 'time_rate' && value === '' && saleForm.total_time_amount === '') {
      updatedErrors.time_rate = false;
      updatedErrors.total_time_amount = false;
    } else if (name === 'total_time_amount' && value === '' && saleForm.time_rate === '') {
      updatedErrors.time_rate = false;
      updatedErrors.total_time_amount = false;
    } else if (name === 'time_rate' && value === '' && saleForm.total_time_amount !== '') {
      updatedErrors.time_rate = true;
      updatedErrors.total_time_amount = false;
    } else if (name === 'total_time_amount' && value === '' && saleForm.time_rate !== '') {
      updatedErrors.time_rate = false;
      updatedErrors.total_time_amount = true;
    } else if (name === 'time_rate' && value !== '' && saleForm.total_time_amount === '') {
      updatedErrors.time_rate = false;
      updatedErrors.total_time_amount = true;
    } else if (name === 'total_time_amount' && value !== '' && saleForm.time_rate === '') {
      updatedErrors.time_rate = true;
      updatedErrors.total_time_amount = false;
    }
    
    return updatedErrors;
  };
  
  const handleChangeSale = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    
    const updatedErrors = handleCheckErrors(name, value, saleForm);
    
    setSaleForm({
      ...saleForm,
      [name]: value,
    });
    
    setErrors(updatedErrors);
  };
  
  const checkForErrors = () => {
    let hasErrors;
    const form = saleForm;
    
    hasErrors = (form.time_rate === '' && form.total_time_amount !== '') ||
      (form.time_rate !== '' && form.total_time_amount === '');
    return hasErrors;
  };

  const isTimeBasedQuotes = quotes.some(quote => quote.quote_type === 'time_based');

  const handleUpdate = () => {
    const hasErrors = checkForErrors();

    if (isTimeBasedQuotes && (!saleForm.time_rate || !saleForm.total_time_amount)) {
      return setErrors({
        ...errors,
        type: true,
      });
    }

    setErrors({
      ...errors,
      type: false,
    })
    
    if (!hasErrors) {
      dispatch(updateSale({
        saleId: id,
        body: {
          time_rate: saleForm?.time_rate === '' ? null : saleForm.time_rate,
          total_time_amount: saleForm?.total_time_amount === '' ? null : saleForm?.total_time_amount,
          stage: saleForm?.stage,
        },
      }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setOpenToast(true);
            setMessage('Time is successfully updated.');
            dispatch(getSaleQuotes({ saleId: id }));
          } else {
            dispatch(getSale({ saleId: id }));
          }
        });
    }
  };
  
  const quoteCategories = () => {
    return (
      <div className="sales__quote--modal">
        {!loading && error.length > 0 && (
          <p className="sales__quote--error">
            {error}
          </p>
        )}
        {loading ? (
          <div className="content-loading" style={{ height: '200px' }}>
            <ProgressSpinnerDarkNavy/>
          </div>
        ) : (
          <div className="tag-modal-container" style={{ width: '100%' }}>
            <div className="tag-modal">
              <div className="modal-content">
                
                <div style={{ width: '100%', position: 'relative' }}>
                  <div style={{ textAlign: 'left' }} className="form-row-label">
                    Total Amount of Time
                  </div>
                  <TextField
                    fullWidth
                    id="total_time_amount"
                    key="total_time_amount"
                    name="total_time_amount"
                    type="number"
                    sx={{
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#141A66',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: errors.total_time_amount ? '#a20601' : 'inherit',
                        },
                        '&:hover fieldset': {
                          borderColor: 'gray',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: errors.total_time_amount ? '#a20601' : '#141A66',
                        },
                      },
                    }}
                    className="contractsTags__tags__modal-input"
                    placeholder="Total Amount of Time..."
                    variant="outlined"
                    required
                    value={saleForm?.total_time_amount || ''}
                    onChange={(e) => handleChangeSale(e)}
                  />
                  {errors.total_time_amount && (
                    <p className="contractsTags__tags__modal-error">Enter time amount</p>
                  )}
                </div>
                
                
                <div style={{ width: '100%', position: 'relative' }}>
                  <div style={{ textAlign: 'left' }} className="form-row-label">Time Rate</div>
                  <Select
                    id="time_rate"
                    name="time_rate"
                    key="time_rate"
                    displayEmpty
                    value={saleForm?.time_rate ? saleForm?.time_rate : ''}
                    onChange={(e) => handleChangeSale(e)}
                    sx={{
                      backgroundColor: '#EFEFEF',
                      border: 0,
                      borderRadius: '8px',
                      fontFamily: 'Korolev Medium',
                      textAlign: 'left',
                      width: '100%',
                      '&.MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: errors.time_rate ? '#a20601' : 'inherit',
                        },
                        '&:hover fieldset': {
                          borderColor: 'gray',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: errors.time_rate ? '#a20601' : '#141A66',
                        },
                      },
                    }}
                  >
                    <MenuItem key={'null'} value={''}>
                      None
                    </MenuItem>
                    <MenuItem key={'annually'} value={'annually'}>
                      Per Year
                    </MenuItem>
                    <MenuItem key={'monthly'} value={'monthly'}>
                      Per Month
                    </MenuItem>
                    <MenuItem key={'weekly'} value={'weekly'}>
                      Per Week
                    </MenuItem>
                    <MenuItem key={'daily'} value={'daily'}>
                      Per Day
                    </MenuItem>
                  </Select>
                  {errors.time_rate && (
                    <p className="contractsTags__tags__modal-error">Select time rate</p>
                  )}
                </div>
              </div>
              
              <div className="contractsTags__tags__modal-buttonContainer">
                {updateLoading && (
                  <div
                    className="contractsTags__tags__modal-loading"
                    style={{ top: '-35px', right: '140px' }}
                  >
                    <ProgressSpinnerDarkNavy/>
                  </div>
                )}
                {updateError.length > 0 && (
                  <p className="contractsTags__tags__modal-error--add">{updateError}</p>
                )}
                {errors.type && (
                  <p className="contractsTags__tags__modal-error--add" style={{ textAlign: 'start' }}>
                    You already have time-based quotes.
                    <br />
                    Please enter valid data.
                  </p>
                )}
                <button
                  className="connection__modal-button"
                  onClick={handleUpdate}
                  disabled={loading || updateLoading}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        {openToast && (
          <Toast
            open={openToast}
            message={message}
            onClose={() => setOpenToast(false)}
          />
        )}
      </div>
    );
  };
  return (
    <NewBaseModal
      open={open}
      onClose={onClose}
      title="Manage Time"
      colorPalette="navy"
      dialogContent={quoteCategories()}
    />
  );
};
