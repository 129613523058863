import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import API from 'API';
import Toast from 'components/toast/toast';
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { milestonesLoadingSelector, milestonesSelector } from 'store/sales/selectors';
import { getSalesMilestones } from 'store/sales/thunk';

const initialMilestone = {
  name: '',
  description: '',
  goal_to_achieve: '',
  file_active: false,
  active: true,
};

const initialErrors = {
  name: false,
  goal_to_achieve: false,
  server: false,
};

export const ManageMilestoneTemplates = () => {
  const [milestone, setMilestone] = useState(initialMilestone);
  const [errors, setErrors] = useState(initialErrors);
  const [isCreating, setIsCreating] = useState(true);
  const [openToast, setOpenToast] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const dispatch = useAppDispatch();

  const allMilestones = useAppSelector(milestonesSelector);
  const loading = useAppSelector(milestonesLoadingSelector);

  const token = JSON.parse(localStorage.getItem('token'));

  const handleChange = (event) => {
    setMilestone({
      ...milestone,
      [event.target.name]: event.target.value,
    });
    if (!event.target.value.length) {
      setErrors({
        ...errors,
        [event.target.name]: true,
      });
    } else {
      setErrors({
        ...errors,
        [event.target.name]: false,
      });
    }
  };

  const handleCreate = () => {
    if (!isCreating) {
      setIsCreating(true);
      setMilestone(initialMilestone);
    } else {
      if (!milestone.name || !milestone.goal_to_achieve) {
        setErrors({
          name: !milestone.name,
          goal_to_achieve: !milestone.goal_to_achieve,
          server: false,
        });
      } else {
        setLoadingUpdate(true);
        try {
          let requestError = false;
          fetch(`${API.endpointURL}/api/milestone_templates`, {
            method: 'POST',
            body: JSON.stringify({
              name: milestone.name,
              description: milestone.description,
              active: milestone.active,
              file_active: milestone.file_active,
              goal_to_achieve: milestone.goal_to_achieve,
            }),
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-type': 'application/json; charset=UTF-8',
            },
          })
            .then((res) => {
              if (!res.ok) {
                requestError = true;
              }
              return res.json();
            })
            .then(() => {
              setOpenToast(true);
              setLoadingUpdate(false);
              if (requestError) {
                setErrors({
                  ...errors,
                  server: true,
                });
              } else {
                dispatch(getSalesMilestones());
                setMilestone(initialMilestone);
                setErrors({
                  ...errors,
                  server: false,
                });
              }
            });
        } catch (e) {
          setErrors({
            ...errors,
            server: true,
          });
          setOpenToast(true);
          setLoadingUpdate(false);
        }
      }
    }
  };

  const handleMilestoneClick = (value) => {
    setIsCreating(false);
    setMilestone(value);
  };

  const handleUpdate = () => {
    if (!milestone.name || !milestone.goal_to_achieve) {
      setErrors({
        name: !milestone.name,
        goal_to_achieve: !milestone.goal_to_achieve,
        server: false,
      });
    } else {
      setLoadingUpdate(true);
      try {
        let requestError = false;
        fetch(`${API.endpointURL}/api/milestone_templates/${milestone.id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            id: milestone.id,
            name: milestone.name,
            description: milestone.description,
            active: milestone.active,
            file_active: milestone.file_active,
            goal_to_achieve: milestone.goal_to_achieve,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
          .then((res) => {
            if (!res.ok) {
              requestError = true;
            }
            return res.json();
          })
          .then(() => {
            setOpenToast(true);
            if (requestError) {
              setErrors({
                ...errors,
                server: true,
              });
            } else {
              dispatch(getSalesMilestones());
              setMilestone(initialMilestone);
              setErrors({
                ...errors,
                server: false,
              });
              setIsCreating(true);
            }
            setLoadingUpdate(false);
          });
      } catch (e) {
        setErrors({
          ...errors,
          server: true,
        });
        setOpenToast(true);
        setLoadingUpdate(false);
      }
    }
  };

  return (
    <>
      <div className="sales__content">
        <div className="sales__manage--left">
          <div className="sales__manage--top">
            <div className="stats-group-header">
              {isCreating ? 'Create Milestone' : 'Update Milestone'}
            </div>
            <div className="sale-form-set-half">
              <div className="sale-form-label">Milestone Name</div>
              <input
                className="sale-form-input"
                placeholder="Enter milestone name..."
                value={milestone.name || ''}
                name="name"
                onChange={handleChange}
              />
              {errors.name && <p className="sale-form-error">* Please Enter Milestone Name</p>}
            </div>
            <div className="sale-form-set-half">
              <div className="sale-form-label">Goal to Achieve</div>
              <select
                className="sale-form-input"
                placeholder="Select Goal to Achieve..."
                value={milestone.goal_to_achieve || ''}
                name="goal_to_achieve"
                onChange={handleChange}
                style={{ color: !milestone.goal_to_achieve && '#EFEFEF80' }}
              >
                <option value="">Select Goal to Achieve</option>
                <option value="Opportunity">Opportunity</option>
                <option value="Contract">Contract</option>
                <option value="Award">Award</option>
              </select>
              {errors.goal_to_achieve && <p className="sale-form-error">* Please Select Goal to Achieve</p>}
            </div>
            <div className="sale-form-set sale-form-set-full">
              <div className="sale-form-label">Description</div>
              <textarea
                className="sale-form-input sale-form-input-textarea"
                placeholder="Enter description..."
                value={milestone.description || ''}
                name="description"
                onChange={handleChange}
              />
            </div>
            <div className="sale-form-set sale-form-set-row">
              <p className="sale-form-label sale-form-label-row">Active</p>
              <Switch
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#A4F2F1',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#A4F2F1',
                  },
                  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                    backgroundColor: '#c7c7c7',
                  },
                }}
                checked={milestone.active}
                onChange={() => setMilestone({ ...milestone, active: !milestone.active })}
              />
            </div>
            <div className="sale-form-set sale-form-set-row">
              <p className="sale-form-label sale-form-label-row">File active</p>
              <Switch
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#A4F2F1',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#A4F2F1',
                  },
                  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                    backgroundColor: '#c7c7c7',
                  },
                }}
                checked={milestone.file_active}
                onChange={() => setMilestone(
                  {
                    ...milestone,
                    file_active: !milestone.file_active,
                  },
                )}
              />
            </div>
            <div className="sale-form-button-container">
              {loadingUpdate && (
                <div className="sale-form-spinner-milestone">
                  <ProgressSpinnerNavy />
                </div>
              )}
              <button
                className="create-button create-button--dark"
                onClick={handleCreate}
              >
                {isCreating ? 'Create' : 'Create Milestone'}
              </button>
              {!isCreating && (
                <button
                  className="create-button"
                  onClick={handleUpdate}
                >
                  Update
                </button>
              )}
            </div>
          </div>
          <div className="sales__manage--bottom">
            <div className="stats-group-header">Inactive Milestones</div>
            <div className="sales__manage--head">
              <p>Milestone Name</p>
              <p>Goal to Achieve</p>
              <p>Description</p>
            </div>
            <div className="sales__manage--container">
              {loading ? (
                <div className="content-loading">
                  <ProgressSpinnerNavy/>
                </div>
              ) : (
                <>
                  {allMilestones
                    .filter((item) => !item.active)
                    .map((milestone, index) => (
                      <button
                        className="sales__manage--row"
                        onClick={() => {
                          handleMilestoneClick(milestone);
                        }}
                        key={`${milestone.name} ${index}`}
                      >
                        <p>{milestone.name}</p>
                        <p>{milestone.goal_to_achieve}</p>
                        <p>{milestone.description}</p>
                      </button>
                    ))
                  }
                </>
              )}
            </div>
          </div>
        </div>
        <div className="sales__manage--right">
          <div className="stats-group-header">Active Milestones</div>
          <div className="sales__manage--head">
            <p>Milestone Name</p>
            <p>Goal to Achieve</p>
            <p>Description</p>
          </div>
          <div className="sales__manage--container">
            {loading ? (
              <div className="content-loading">
                <ProgressSpinnerNavy />
              </div>
            ) : (
              <>
                {allMilestones
                  .filter((item) => item.active)
                  .map((milestone, index) => (
                    <button
                      className="sales__manage--row"
                      onClick={() => {
                        handleMilestoneClick(milestone);
                      }}
                      key={`${milestone.name} ${index}`}
                    >
                      <p>{milestone.name}</p>
                      <p>{milestone.goal_to_achieve}</p>
                      <p>{milestone.description}</p>
                    </button>
                  ))
                }
              </>
            )}

          </div>
        </div>
      </div>
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={errors.server
            ? 'Something went wrong, try again later...'
            : 'New Milestone Successfully Created'
          }
        />
      )}
    </>
  );
};
