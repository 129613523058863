import React, { useEffect, useState } from "react";
import CustomDialogEqip from "./custom-dialog-eqip";
import CondensedNotesModule from "../notes/condensed-notes";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import API from "API";

export default function EqipModal(props) {
  const { handleClose, open, user, handleUpdateApplicants } = props;

  const token = JSON.parse(localStorage.getItem("token"));

  const [updatedApplicant, setUpdatedApplicant] = useState(user);
  const [applicantActions, setApplicantActions] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [errors, setErrors] = useState(null);
  const [openEqipSent, setOpenEqipSent] = useState(false);
  const [openEqipCompleted, setOpenEqipCompleted] = useState(false);

  const eqipItemsToFields = {
    "Security Package Uploaded to Staging": "bi_packet_sent",
    "Security Package Submitted to USG": "bi_packet_submitted",
    "USG eQIP Invite Sent": "eqip_invite_sent",
    "USG Correction Notice 1": "usg_corrections_1",
    "USG Correction Notice 2": "usg_corrections_2",
    "USG Correction Notice 3": "usg_corrections_3",
    "USG Correction Notice 4": "usg_corrections_4",
    "USG Correction Notice 5": "usg_corrections_5",
    "eQIP Done": "eqip_completed",
    "eQIP Corrections Complete 1": "usg_corrections_completed_1",
    "eQIP Corrections Complete 2": "usg_corrections_completed_2",
    "eQIP Corrections Complete 3": "usg_corrections_completed_3",
    "eQIP Corrections Complete 4": "usg_corrections_completed_4",
    "Fingerprints Submitted": "fingerprints_date_completed",
    "Fingerprints Type": "finger_prints_type",
  };

  useEffect(() => {
    getApplicantActions();
    if (updatedApplicant) {
      setSelectedValue(updatedApplicant.application.finger_prints_type);
    }
  }, []);

  const getApplicantActions = () => {
    fetch(`${API.endpointURL}/api/actions/filter/${user.application.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.data.length > 0) {
          setApplicantActions(json.data);
        }
      });
  };

  const getUpdatedApplicant = () => {
    fetch(`${API.endpointURL}/api/users/${user.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setUpdatedApplicant(json.data);
        setSelectedValue(json.data.application.finger_prints_type);
        getApplicantActions();
      });
  };

  const editApplication = async (field, newInput, id) => {
    await fetch(`${API.endpointURL}/api/applicants/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        [field]: newInput,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["errors"]) {
          setErrors(data["errors"]);
        } else {
          getUpdatedApplicant();
          if (handleUpdateApplicants) {
            handleUpdateApplicants();
          }
        }
      })
      .catch((err) => {
        setErrors(err.messages);
      });
  };

  const determineItemStyles = (field) => {
    const styles = {
      backgroundColor: "#3e453d",
      border: "0",
      color: "#a5af96",
      fontFamily: "Korolev Medium",
      padding: "10px",
      paddingLeft: "24px",
    };
    if (field.includes("usg_corrections")) {
      styles.paddingLeft = "46px";
    }
    return styles;
  };

  const determineDialogTitle = () => {
    let title = "USG Status: N/A";
    if (updatedApplicant.application.status) {
      title = `USG Status: ${updatedApplicant.application.status}`;
    } else if (updatedApplicant.application.eqip_completed) {
      title = "USG Status: Pending Clearance";
    }
    return title;
  };

  const buildEqipRow = (key, value, valueSelect, recruiterName) => {
    return (
      <TableRow key={key} sx={{ borderBottom: "none" }}>
        <TableCell sx={determineItemStyles(value)}>
          {key === "USG eQIP Invite Sent" && (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <IconButton
                edge="start"
                aria-label="collapse-eqip-sent-menu"
                sx={{
                  color: "#a5af96",
                  padding: 0,
                  ml: "-6px",
                  mr: 0.5,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#dae0bc",
                  },
                }}
                onClick={() => setOpenEqipSent(!openEqipSent)}
              >
                {openEqipSent ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
              <Typography
                sx={{
                  color: "#a5af96",
                  fontFamily: "Korolev Medium",
                  fontSize: "1rem",
                }}
              >
                {key}
              </Typography>
            </Box>
          )}
          {key === "eQIP Done" && (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <IconButton
                edge="start"
                aria-label="collapse-eqip-done-menu"
                sx={{
                  color: "#a5af96",
                  padding: 0,
                  ml: "-6px",
                  mr: 0.5,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#dae0bc",
                  },
                }}
                onClick={() => setOpenEqipCompleted(!openEqipCompleted)}
              >
                {openEqipCompleted ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
              <Typography
                sx={{
                  color: "#a5af96",
                  fontFamily: "Korolev Medium",
                  fontSize: "1rem",
                }}
              >
                {key}
              </Typography>
            </Box>
          )}
          {key !== "USG eQIP Invite Sent" && key !== "eQIP Done" && key}
        </TableCell>
        <TableCell
          sx={{
            backgroundColor: "#3e453d",
            border: "0",
            color: "#a5af96",
            fontFamily: "Korolev Medium",
            padding: "10px",
          }}
        >
          {valueSelect}
        </TableCell>
        <TableCell
          sx={{
            backgroundColor: "#3e453d",
            border: "0",
            color: "#a5af96",
            fontFamily: "Korolev Medium",
            padding: "10px 24px 10px 10px",
            textTransform: "capitalize",
          }}
        >
          {recruiterName}
        </TableCell>
      </TableRow>
    );
  };

  const buildTable = () => {
    let components = [];
    for (const [key, value] of Object.entries(eqipItemsToFields)) {
      let valueSelect = <div></div>;
      let recruiterName = "";
      if (key === "Fingerprints Type") {
        valueSelect = (
          <FormControl sx={{ width: "calc(100% - 32.5px)" }}>
            <Select
              id="edit-fingerprint-type"
              key="edit-fingerprint-type"
              displayEmpty
              onChange={(event) =>
                editApplication(
                  value,
                  event.target.value,
                  updatedApplicant.application.id
                )
              }
              sx={{
                backgroundColor: "#EFEFEF",
                border: 0,
                borderRadius: "8px",
                fontFamily: "Korolev Medium",
                textAlign: "left",
              }}
              value={selectedValue}
            >
              <MenuItem key={"digital"} value={"digital"}>
                Digital
              </MenuItem>
              <MenuItem key={"card"} value={"card"}>
                Card
              </MenuItem>
              <MenuItem key={"on_file"} value={"on_file"}>
                Already on file
              </MenuItem>
            </Select>
          </FormControl>
        );
      }
      if (updatedApplicant.application[value]) {
        recruiterName = "N/A";
        if (applicantActions) {
          let action = applicantActions.find(
            ({ key_changed }) => key_changed === value
          );
          if (action) {
            recruiterName = `${action.first_name} ${action.last_name}`;
          }
        }
        if (key !== "Fingerprints Type") {
          valueSelect = (
            <Typography
              sx={{ fontFamily: "Korolev Medium", textAlign: "left" }}
            >
              <Box display="flex">
                <DatePicker
                  wrapperClassName="date-picker"
                  selected={new Date(updatedApplicant.application[value])}
                  onChange={(date) =>
                    editApplication(
                      value,
                      date ? date.toISOString() : null,
                      updatedApplicant.application.id
                    )
                  }
                  dateFormat="MM/dd/yyyy"
                />
                <IconButton
                  sx={{
                    color: "#EFEFEF",
                    marginLeft: "8px",
                    padding: "0",
                    "&:hover": {
                      background: "transparent",
                      color: "#EFEFEF80",
                    }
                  }}
                  onClick={() =>
                    editApplication(
                      value,
                      null,
                      updatedApplicant.application.id
                    )
                  }
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Typography>
          );
        }
      } else {
        if (key !== "Fingerprints Type") {
          valueSelect = (
            <Typography
              sx={{ fontFamily: "Korolev Medium", textAlign: "left" }}
            >
              <Box display="flex">
                <DatePicker
                  wrapperClassName="date-picker"
                  selected={selectedDate}
                  onChange={(date) =>
                    editApplication(
                      value,
                      date ? date.toISOString() : null,
                      updatedApplicant.application.id
                    )
                  }
                  dateFormat="MM/dd/yyyy"
                />
                <div style={{ width: "40px" }} />
              </Box>
            </Typography>
          );
        }
      }
      if (key.includes("USG Correction Notice") && openEqipSent) {
        components.push(buildEqipRow(key, value, valueSelect, recruiterName));
      } else if (
        key.includes("eQIP Corrections Complete") &&
        openEqipCompleted
      ) {
        components.push(buildEqipRow(key, value, valueSelect, recruiterName));
      } else if (
        !key.includes("USG Correction Notice") &&
        !key.includes("eQIP Corrections Complete")
      ) {
        components.push(buildEqipRow(key, value, valueSelect, recruiterName));
      }
    }

    return (
      <Box sx={{ width: "100%" }}>
        <TableContainer
          sx={{
            border: "0",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            height: "auto",
            "& td": {
              fontSize: "1rem",
            },
            "& th": {
              fontSize: "1.2rem",
            },
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    backgroundColor: "#a5af96",
                    border: "0",
                    color: "#3e453d",
                    fontFamily: "MagistralCondW08-Medium",
                    paddingLeft: "24px",
                    textTransform: "capitalize",
                  }}
                >
                  Item Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    backgroundColor: "#a5af96",
                    border: "0",
                    color: "#3e453d",
                    fontFamily: "MagistralCondW08-Medium",
                    paddingLeft: "10px",
                    textTransform: "capitalize",
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    backgroundColor: "#a5af96",
                    border: "0",
                    color: "#3e453d",
                    fontFamily: "MagistralCondW08-Medium",
                    paddingLeft: "10px",
                    textTransform: "capitalize",
                  }}
                >
                  Recruiter Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{components}</TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ marginTop: 3 }}>
          <Typography
            sx={{
              backgroundColor: "#3e453d",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              color: "#dae0bc",
              fontFamily: "MagistralCondW08-Medium",
              fontSize: "1.2rem",
              padding: "15px 24px",
              textAlign: "left",
            }}
          >
            Applicant Notes
          </Typography>
          <CondensedNotesModule selectedUser={user}></CondensedNotesModule>
        </Box>
      </Box>
    );
  };

  return (
    <CustomDialogEqip
      title={determineDialogTitle()}
      dialogContent={buildTable()}
      onClose={handleClose}
      open={open}
    />
  );
}
