import React, { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import "../../css/create-new-vendor.scss";
import API from "API";

export default function CreateNewVendor(props) {
  const {
    accountingUsers,
    setCurrentView,
    setError,
    setOpenErrorToast,
    setOpenCreateToast,
    getPartners,
  } = props;
  const token = JSON.parse(localStorage.getItem("token"));

  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("vendor");
  const [accountManager, setAccountManager] = useState("");
  const [isStaffingCustomer, setIsStaffingCustomer] = useState(false);
  const [notes, setNotes] = useState("");
  
  const handleSubmit = async () => {
    try {
      const response = await fetch(`${API.endpointURL}/api/create_vendor`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
          "Accept": "application/json"
        },
        body: JSON.stringify({
          company_name: companyName,
          first_name: firstName,
          last_name: lastName,
          phone: phone,
          email: email,
          notes: notes,
          type: role,
          is_staffing_customer: isStaffingCustomer,
        }),
      });
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.error.message ||
            "Failed to store vendor on Business Central"
        );
      }
      await response.json();
      setCurrentView(role === "customer" ? "Customers" : "Vendors");
      getPartners();
      setOpenCreateToast(true);
    } catch (error) {
        setError(error);
        setOpenErrorToast(true);
      }
  };

  const handleRoleChange = (event) => {
    setRole(event.currentTarget.value);
    setIsStaffingCustomer(false);
  }

  const generateAccountManagerOptions = () => {
    const options = accountingUsers.map((user) => {
      return (
        <option key={user.id} value={user.id}>
          {user.first_name} {user.last_name}
        </option>
      );
    });
    return (
      <select
        className="vendor-input"
        name="account-manager-users"
        value={accountManager}
        onChange={(e) => setAccountManager(e.target.value)}
      >
        <option value={null}>Select the dropdown for options</option>
        {options}
      </select>
    );
  };

  return (
    <>
      <h1 className="content-header">Create New Vendor</h1>
      <div className="content-container">
        <div className="vendor-info">
          <div className="vendor-info-input">
            Company Name
            <input
              className="field"
              type="text"
              placeholder="Enter Company Name..."
              defaultValue={companyName}
              onChange={(e) => setCompanyName(e.currentTarget.value)}
            />
          </div>
          <div className="vendor-info-input" />
          <div className="vendor-info-input">
            First Name
            <input
              className="field"
              type="text"
              placeholder="Enter First Name..."
              defaultValue={firstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />
          </div>
          <div className="vendor-info-input">
            Last Name
            <input
              className="field"
              type="text"
              placeholder="Enter Last Name..."
              defaultValue={lastName}
              onChange={(e) => setLastName(e.currentTarget.value)}
            />
          </div>
          <div className="vendor-info-input">
            Phone Number
            <input
              className="field"
              type="text"
              placeholder="Enter Phone Number..."
              defaultValue={phone}
              onChange={(e) => setPhone(e.currentTarget.value)}
            />
          </div>
          <div className="vendor-info-input">
            Email Address
            <input
              className="field"
              type="text"
              placeholder="Enter Email..."
              defaultValue={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </div>
          <div className="vendor-info-input">
            Vendor Role
            <select
              className="field"
              placeholder="Enter Role..."
              value={role}
              onChange={(e) => handleRoleChange(e)}
            >
              <option value="vendor">Vendor</option>
              <option value="customer">Customer</option>
            </select>
          </div>

          {/* Only display the toggle if vendor's role is set to 'customer'. */}
          { role === 'customer' && (
            <div 
            className="vendor-info-input" 
            style={{
                marginTop: '1.7em',
            }}
            >
              Staffing Customer
              <Switch
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#00A5CF',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#00A5CF',
                  },
                  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                    backgroundColor: '#c7c7c7',
                  },
                }}
                checked={isStaffingCustomer}
                onChange={() => setIsStaffingCustomer(!isStaffingCustomer)}
              />
            </div>
          )}
    
          {/* <div className="vendor-info-input">
            Account Manager
            <select
              className="field"
              type="text"
              placeholder="Account Manager"
            >
              <option>Account Manager</option>
            </select>
          </div> */}

          <div className="vendor-notes">
            Notes
            <textarea
              className="notes"
              type="text"
              placeholder="Enter notes here..."
              defaultValue={notes}
              onChange={(e) => setNotes(e.currentTarget.value)}
            />
          </div>
        </div>
        <button
          className="accounting-primary-button"
          onClick={() => handleSubmit()}
        >
          Submit
        </button>
      </div>

    </>
  );
}
