import React, { useState } from 'react';
import Toast from '../toast/toast';
import { TextField } from '@mui/material';
import API from 'API';
import { useParams } from 'react-router-dom';
import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';

export const SalesNewFileTypeModal = ({ refreshFileFolders }) => {
  let { id } = useParams();
  const [newFileType, setFileTypeName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dropboxUploadKey, setDropboxUploadKey] = useState(Date.now());
  const [openToast, setOpenToast] = useState(false);
  const token = JSON.parse(localStorage.getItem('token'));

  const addNewFileType = () => {
    if (newFileType === '') {
      setError('New type name field can\'t be empty');
    } else {
      setLoading(true);
      const formData = new FormData();

      formData.append('type_name', newFileType);
      formData.append('sales_id', id);
      formData.append('category', 'custom');

      fetch(`${API.endpointURL}/api/sales_files/types`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          Connection: 'keep-alive',
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then(() => {
          setLoading(false);
          setError(null);
          setFileTypeName('');
          setDropboxUploadKey(Date.now());
          setOpenToast(true);

          // Refresh folders list
          refreshFileFolders();

        })
        .catch(() => {
          setLoading(false);
          setError('Something went wrong, please try again.');
        });
    }
  };

  return (
    <div
      style={{
        maxHeight: '100%',
        overflow: 'auto',
        width: '100%',
      }}
    >
      {loading ? (
        <div className="contracts-loading-spinner">
          <ProgressSpinnerDarkNavy />
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '50px',
              textAlign: 'left',
            }}
          >
            <div className="contract-form-set-full">
              <div className="form-row-label">New Folder Name</div>
              <TextField
                value={newFileType}
                onChange={(e) => setFileTypeName(e.target.value)}
                fullWidth
                sx={{
                  backgroundColor: '#fff',
                  border: '1px solid #a5af96',
                  borderRadius: '8px',
                  padding: '6px 10px 5px',
                  marginBottom: 0,
                }}
                placeholder="Enter New Folder Name..."
                key={`file-name-${dropboxUploadKey}`}

                multiline={true}
                rows={1}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>


          </div>
          {error && (
            <div
              style={{
                color: 'black',
                fontFamily: 'Korolev Medium',
                fontSize: '14px',
                marginBottom: '16px',
                textAlign: 'left',
              }}
            >
              {error}
            </div>
          )}
          <div
            style={{
              textAlign: 'left',
              width: '100%',
            }}
          >

            <button
              className="create-button"
              style={{
                backgroundColor: '#141A66',
                borderColor: '#EFEFEF80'
              }}
              onClick={() => {
                addNewFileType();
              }}
            >
              Add Custom Folder
            </button>

          </div>
        </>
      )}
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message="New folder added"
        />
      )}
    </div>
  );
};
