import React, { useState } from 'react';

import { Divider } from '@mui/material';

import 'css/sales-files-list.scss';

import NewBaseModal from 'components/modal/new-base-modal';

import { ProjectsUploadModal } from 'components/modal/projects-upload-modal';
import { ProjectsNewTypeModal } from 'components/modal/projects-new-type-modal';
import ProjectDeleteFile from 'components/modal/project-delete-file';
import { ProjectsEditFileModal } from 'components/modal/projects-edit-file-modal';
import { ProjectFile } from 'components/project-portal/project-file';

export default function ProjectsViewFiles(props) {
  const {
    vendorFiles,
    view,
    setView,
    retrieveFiles,
    retrieveAllFiles,
    paramToReq,
    setParamToReq,
    specificFiles,
    refreshFileFolders,
    setOpenDissociateToast,
    setMessage,
  } = props;

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openNewFileTypeModal, setOpenNewFileTypeModal] = useState(false);
  const [openDissociateModal, setOpenDissociateModal] = useState(false);
  const [openEditFileModal, setOpenEditFileModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleAllClick = () => {
    setView('All');
    setParamToReq({
      type_name: '',
      category: '',
    });
    if (view !== 'All') {
      retrieveAllFiles();
    }
  };

  return (
    <div className="projects__dropbox-files">
      <div
        className="projects__dropbox-files-top">
        {paramToReq.type_name ?
          paramToReq.type_name
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ') : 'All Files'}
        <button
          className="create-button"
          onClick={handleAllClick}
        >
          View All Files
        </button>
      </div>
      <Divider sx={{ borderColor: '#8b8b8b' }}/>
      <div className={'projects__dropbox-files-container'}>
        <div className={'projects__dropbox-files-list'}>
          {vendorFiles && view === 'All' && (
            vendorFiles?.map((file) => (
              <ProjectFile
                key={file.id}
                file={file}
                setSelectedFile={setSelectedFile}
                setOpenDissociateModal={setOpenDissociateModal}
                setOpenEditFileModal={setOpenEditFileModal}
              />
            ))
          )}
          {specificFiles &&
            view !== 'All' &&
            view !== 'Upload File' && (
              specificFiles?.map((file) => (
                <ProjectFile
                  key={file.id}
                  file={file}
                  setSelectedFile={setSelectedFile}
                  setOpenDissociateModal={setOpenDissociateModal}
                  setOpenEditFileModal={setOpenEditFileModal}
                />
              ))
            )}
        </div>
      </div>
      {view !== 'Upload File' && view !== 'Verification' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <button
            className="create-button"
            style={{ marginRight: '14px' }}
            onClick={() => {
              setOpenNewFileTypeModal(true);
            }}
          >
            Create Custom Folder
          </button>
          <button
            className="create-button"
            style={{ marginRight: '20px' }}
            onClick={() => {
              setOpenUploadModal(true);
            }}
          >
            Upload File
          </button>
        </div>
      )}
      {/* Modal - upload a new file */}
      <NewBaseModal
        dialogContent={
          <ProjectsUploadModal
            refreshFiles={retrieveFiles}
            setOpenToast={setOpenDissociateToast}
            setMessage={setMessage}
          />
        }
        onClose={() => setOpenUploadModal(false)}
        open={openUploadModal}
        title={'Add new file'}
        colorPalette="navy"
      />
      {/* Modal - add new file type */}
      <NewBaseModal
        dialogContent={
          <ProjectsNewTypeModal
            refreshFileFolders={refreshFileFolders}
            close={() => setOpenNewFileTypeModal(false)}
            setOpenToast={setOpenDissociateToast}
            setMessage={setMessage}
          />
        }
        onClose={() => setOpenNewFileTypeModal(false)}
        open={openNewFileTypeModal}
        title={'Add new file type'}
        colorPalette="navy"
      />
      {/* Modal - edit file */}
      <NewBaseModal
        dialogContent={
          <ProjectsEditFileModal
            fileId={selectedFile?.id}
            type={selectedFile?.type?.id}
            name={selectedFile?.display_name}
            retrieveFiles={retrieveFiles}
            close={() => setOpenEditFileModal(false)}
            setOpenToast={setOpenDissociateToast}
            refreshFileFolders={refreshFileFolders}
            setMessage={setMessage}
          />
        }
        onClose={() => setOpenEditFileModal(false)}
        open={openEditFileModal}
        title={'Edit File'}
        colorPalette="navy"
      />
      {openDissociateModal && (
        <ProjectDeleteFile
          handleClose={() => setOpenDissociateModal(false)}
          open={openDissociateModal}
          retrieveFiles={retrieveFiles}
          selectedFile={selectedFile}
          setOpenToast={setOpenDissociateToast}
          setMessage={setMessage}
        />
      )}
    </div>
  );
}
