import React, { useEffect, useState } from "react";
import "css/vendor-dropbox.scss";
import "css/sales-files-list.scss";
import API from "API";
import SalesFilesSidebar from "./sales-file-sidebar";
import SaleViewFile from "./sale-view-files";
import { ProgressSpinnerNavy } from 'components/progress/progress-spinner-navy';

const initial = {
  type_name: '',
  category: ''
}

const SalesDropBoxPageContent = (props) => {
  const { id } = props;
  const token = JSON.parse(localStorage.getItem("token"));
  const [view, setView] = useState("All");
  const [paramToReq, setParamToReq] = useState(initial)
  /**
   * saleFiles - all files that are associated with current Sale
   * 
   * filesByCategory - all files that are associated with current Sale
   * AND sorted by categories: milestones, tasks, custom
   */
  const [saleFiles, setSaleFiles] = useState(null);
  const [filesByCategory, setFilesByCategory] = useState([])
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [errorLoadingFiles, setErrorLoadingFiles] = useState(false);

  const [saleFileFolders, setSaleFileFolders] = useState([]);
  const [loadingFolders, setLoadingFolders] = useState(false);
  const [errorLoadingFolders, setErrorLoadingFolders] = useState(false);

  // Get all files associated with a sale
  const getSaleFiles = async () => {
    setLoadingFiles(true);
    setErrorLoadingFiles(false);
    await fetch(`${API.endpointURL}/api/sales_files/filter?sales_id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => response.json())
    .then((json) => {
      setSaleFiles(json.data);
      setLoadingFiles(false);
      setErrorLoadingFiles(false);
    })
    .catch(() => {
      setLoadingFiles(false);
      setErrorLoadingFiles("Error loading files");
    });
  };

  // Get sale files sorted by categories
  const getFilteredFiles = async () => {
    setLoadingFiles(true);
    setErrorLoadingFiles(false);
    await fetch(`${API.endpointURL}/api/sales_files/filter?sales_id=${id}&type_name=${paramToReq.type_name}&category=${paramToReq.category}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => response.json())
    .then((json) => {
      setFilesByCategory(json.data);
      setLoadingFiles(false);
      setErrorLoadingFiles(false);
    })
    .catch(() => {
      setLoadingFiles(false);
      setErrorLoadingFiles("Error loading files");
    });
  }

  // Get folders
  const getFileFoldersForSale = () => {
    setLoadingFolders(true);
    setErrorLoadingFolders(false);
    fetch(`${API.endpointURL}/api/sales_files/types/list-sale-types?sales_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      })
    .then((response) => response.json())
    .then((json) => {
      setSaleFileFolders(json.data);
      setLoadingFolders(false);
      setErrorLoadingFolders(false);
    })
    .catch(() => {
      setLoadingFolders(false);
      setErrorLoadingFolders("Error loading folders");
    });
  }

   // Get files on component init
   useEffect(() => {
    getSaleFiles();
  }, []);

  // Get filtered files when requested
  useEffect(() => {
    if(paramToReq.type_name && paramToReq.category){
      getFilteredFiles();
    }
  }, [paramToReq])

  // Get folders on component init
  useEffect(() => {
    getFileFoldersForSale();
  }, []);

  return (
    <>
      <h1 className="content-header">Dropbox</h1>
      <div className="dropbox-content-container">

        {/* SIDEBAR */}
        <SalesFilesSidebar
          view={view}
          setView={setView}
          saleFiles={saleFiles}
          setParamToReq={setParamToReq}
          saleFileFolders={saleFileFolders}
          loadingFolders={loadingFolders}
          errorLoadingFolders={errorLoadingFolders}
        />

        {/* VIEW FILE SECTION */}
        {errorLoadingFiles && (
          <p style={{padding:"40px 20px"}}>{errorLoadingFiles}</p>
        )}
        {loadingFiles && (
          <div className="contracts-loading-spinner">
            <ProgressSpinnerNavy />
          </div>
        )}
        {!loadingFiles && !errorLoadingFiles && (
          <SaleViewFile
            setParamToReq={setParamToReq}
            view={view}
            paramToReq={paramToReq}
            setView={setView}
            vendorFiles={saleFiles}
            retrieveFiles={getSaleFiles}
            specificFiles={filesByCategory}
            refreshFilteredFiles={getFilteredFiles}
            refreshFileFolders={getFileFoldersForSale}
          />
        )}
      </div>
    </>
  );
};


export default SalesDropBoxPageContent;

