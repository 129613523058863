import React, { useState } from 'react';

import {
  Button,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';

import CustomDialog from './custom-dialog';
import "../../css/modal.scss";

import { editUser } from 'store/user/thunk';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { userErrorSelector, userLoadingSelector } from 'store/user/selectors';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

export default function EditUserDetail(props) {
  const { applicant, getApplicant, handleClose, open, setOpenToast } =
    props;
  
  const dispatch = useAppDispatch();
  const loading = useAppSelector(userLoadingSelector);
  const error = useAppSelector(userErrorSelector);
  
  const [company, setCompany] = useState(applicant?.company ? applicant?.company : null);
  const editUserInfo = async () => {
    const requestBody = {
      company: company,
    };
    
    dispatch(editUser({ id: applicant.id, body: requestBody }))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          getApplicant();
          handleClose();
          setOpenToast(true);
        }
      });
  };
  
  const editUserDetailForm = () => {
    return (
      <React.Fragment key="edit-user-detail-form">
        <div style={{ textAlign: 'left' }} className="form-row-label">
          Company
        </div>
        <FormControl fullWidth>
          <Select
            id="edit-user-detail"
            key="edit-user-detail"
            displayEmpty
            onChange={(event) => {
              setCompany(event.target.value);
            }}
            sx={{
              backgroundColor: '#EFEFEF',
              border: 0,
              borderRadius: '8px',
              fontFamily: 'Korolev Medium',
              textAlign: 'left',
            }}
            value={company || ''}
          >
            <MenuItem value={'RD'}>RD</MenuItem>
            <MenuItem value={'KE'}>KE</MenuItem>
            <MenuItem value={'LOY'}>LOY</MenuItem>
            <MenuItem value={'LRG'}>LRG</MenuItem>
            <MenuItem value={'MED'}>MED</MenuItem>
            <MenuItem value={'NASS'}>NASS</MenuItem>
            <MenuItem value={'WS'}>WS</MenuItem>
            <MenuItem value={''}>No Company</MenuItem>
          </Select>
        </FormControl>
        {error && <div className="add-user-error">{error}</div>}
        <div className="button-container" style={{ overflow: 'hidden', paddingTop: '5px' }}>
          <Button
            variant="contained"
            className="add-user-button"
            disabled={loading}
            sx={{
              backgroundColor: '#556155',
              color: '#EFEFEF',
              fontSize: '18px',
              width: '175px',
              '&:hover': { backgroundColor: '#a5af96', color: '#3e453d' },
            }}
            onClick={() => editUserInfo()}
          >
            Edit
            {loading && (
              <div style={{ position: 'absolute', left: -55 }}>
                <ProgressSpinnerBlue/>
              </div>
            )}
          </Button>
        </div>
      </React.Fragment>
    );
  };
  
  return (
    <CustomDialog
      dialogContent={editUserDetailForm()}
      onClose={handleClose}
      open={open}
      title={'EDIT'}
      titleHighlight={'COMPANY'}
    />
  );
}
