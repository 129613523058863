import "../css/login.scss";
import AppBar from "../components/app-bar/app-bar-splash-component";
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import API from "API";
import {
  Card,
  Button,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
import ProgressSpinnerBlack from 'components/progress/progress-spinner-black';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { resetPasswordSelector } from 'store/user/selectors';
import { setResetEmail } from 'store/user/slice';

export default function LoginPage() {
  const token = JSON.parse(localStorage.getItem("token"));

  const [mode, setMode] = useState("login");
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  // BEM BEM BEM sdsds
  useEffect(() => {
    setPassword("");
  }, [mode]);

  const { email } = useAppSelector(resetPasswordSelector);
  const dispatch = useAppDispatch();

  const login = async () => {
    await fetch(`${API.endpointURL}/api/login`, {
      method: "POST",
      body: JSON.stringify({
        email: email.toLowerCase(),
        password: password,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["message"]) {
          handleErrors(data["message"]);
        } else {
          setCurrentUser(data["user"]);
          localStorage.setItem("user", JSON.stringify(data["user"]));
          localStorage.setItem("token", JSON.stringify(data["token"]));
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const requestPasswordReset = async () => {
    if (email.length > 0) {
      setError('');
      setLoading(true);
      await fetch(`${API.endpointURL}/api/forget_password`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }).then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setMode("resetPasswordSuccess");
          if (error) {
            setError(null);
          }
        } else {
          setError(
            "There was an issue sending your reset password link. Please try again later."
          );
        }
      });
    } else {
      setError("Please enter your email to receive your reset password link.");
    }
  };

  const handleErrors = (errors) => {
    if (errors === "Password mismatch") {
      setError("You have entered an invalid email address or password.");
    } else {
      setError("Please enter a valid email address and password.");
    }
  };

  const renderLoginScreen = () => {
    return (
      <React.Fragment>
        <h1 className="login-form-title">Sign In</h1>
        <h3 className="input-label">Email</h3>
        <TextField
          fullWidth
          required
          className="login-form-inputs"
          id="email-textfield-login"
          placeholder="Enter your email address"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            required: false,
          }}
          margin="dense"
          sx={{ marginBottom: "15px" }}
          type="email"
          value={email}
          onChange={(e) => dispatch(setResetEmail(e.currentTarget.value))}
        />
        <h3 className="input-label">Password</h3>
        <TextField
          fullWidth
          required
          className="login-form-inputs"
          id="password-textfield-login"
          placeholder="Enter your Password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <KeyIcon />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
            required: false,
          }}
          margin="dense"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <div className={error ? "login-error" : "login-passing"}>
          {error ? error : "Please enter a valid email address and password."}
        </div>
        <Stack className="login-form-buttons" spacing={2}>
          <Button className="sign-in-btn" variant="contained" onClick={login}>
            Sign In
          </Button>
          <Button
            sx={{
              color: "#171717",
              fontFamily: "Korolev Medium",
              fontSize: "14px",
              marginTop: "20px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#a5af96",
                textDecoration: "none",
              },
            }}
            onClick={() => setMode("forgotPassword")}
          >
            Forgot password?
          </Button>
        </Stack>
        {currentUser && currentUser.role === "admin" && (
          <Navigate to="/welcome" replace={true} />
        )}
        {currentUser && currentUser.role === "accounting" && (
          <Navigate to="/welcome" replace={true} />
        )}
        {currentUser && currentUser.role === "recruiter" && (
          <Navigate to="/welcome" replace={true} />
        )}
        {currentUser && currentUser.role === "staffing" && (
          <Navigate to="/welcome" replace={true} />
        )}
        {currentUser && currentUser.role === "sales" && (
          <Navigate to="/welcome" replace={true} />
        )}
        {currentUser && currentUser.role === "vendor" && (
          <Navigate
            to={`/vendor-portal/${currentUser.vendor_id}`}
            replace={true}
          />
        )}
        {currentUser && currentUser.role === "applicant" && (
          <Navigate to={`/applicant-portal/${currentUser.id}`} replace={true} />
        )}
      </React.Fragment>
    );
  };

  const renderForgotPasswordScreen = () => {
    return (
      <React.Fragment>
        <h1 className="login-form-title forgot-password-btn">
          FORGOT PASSWORD?
        </h1>
        <TextField
          fullWidth
          required
          className="login-form-inputs"
          id="email-textfield-reset-password"
          label="Enter Email"
          margin="dense"
          sx={{ marginBottom: "20px" }}
          type="email"
          onChange={(e) => dispatch(setResetEmail(e.currentTarget.value))}
          value={email}
        />
        {error && <div className="login-error">{error}</div>}

        {loading && (
          <div className="reset-loader-container">
            <div className="reset-loader">
              <ProgressSpinnerBlack />
            </div>
          </div>
        )}

        <Stack className="login-form-buttons" spacing={2}>
          <Button
            onClick={() => requestPasswordReset()}
            className="sign-in-btn"
            variant="contained"
            disabled={loading}
          >
            Send
          </Button>
          <Button
            sx={{
              color: "#171717",
              fontFamily: "Korolev Medium",
              fontSize: "14px",
              marginTop: "20px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#3e453d",
                textDecoration: "none",
              },
            }}
            onClick={() => setMode("login")}
          >
            Back to log in
          </Button>
        </Stack>
      </React.Fragment>
    );
  };

  const renderResetPasswordSuccessScreen = () => {
    return (
      <React.Fragment>
        <h1 className="login-form-title">EMAIL SENT</h1>
        <Typography
          sx={{
            color: "#3e453d",
            fontFamily: "Korolev Medium",
            fontSize: "1rem",
            lineHeight: "1.5rem",
            marginBottom: "45px",
          }}
        >
          A link with instructions to reset your password has been sent to your
          email.
        </Typography>
        <Link to={"/reset-password"} style={{ textDecoration: "none" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#556155",
              color: "#EFEFEF",
              fontSize: "18px",
              width: "175px",
              "&:hover": { backgroundColor: "#3e453d", color: "#dae0bc" },
            }}
          >
            Reset Password
          </Button>
        </Link>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <AppBar />
      <div className="login-background">
        <div className="login-form-container">
          {mode === "login" ? (
            <div className="directions-box">
              <h1>Welcome back!</h1>
              <p>You can sign in to access with your existing account.</p>
            </div>
          ) : (
            <div className="directions-box">
              <h1>It Happens.</h1>
              <p>Enter your email address for instructions.</p>
            </div>
          )}
          <Card
            className="login-form-card"
            sx={{
              borderRadius: "28px",
            }}
          >
            {mode === "login" && renderLoginScreen()}
            {mode === "forgotPassword" && renderForgotPasswordScreen()}
            {mode === "resetPasswordSuccess" &&
              renderResetPasswordSuccessScreen()}
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}