import React, { useEffect, useRef, useState } from 'react';

function TableItem(props) {
  const endRef = useRef(null);
  const showRightArrow = props.showRightArrow;
  const classValue = props.classValue;
  const lastRow = props?.lastRow || false;

  let [isOpen, setIsOpen] = useState(false);

  let handlePanelClick = () => {
    setIsOpen(!isOpen);
  };

  let renderColumns = () => {
    let c = [];
    const showArrow = (
      <div
        className={"icon-button" + (isOpen ? " icon-button--arrow-active" : "")}
        onClick={() => handlePanelClick()}
      >
        <i className="fa-solid fa-angle-down"></i>
      </div>
    );
    React.Children.forEach(props.children, (child, i) => {
      let header = <></>;
      if (i === 0 && props.panel != null) {
        header = showArrow;
      }
      c.push(
        <div key={i} className="table__auto-spaced">
          {!showRightArrow && header}
          {child}
        </div>
      );
    });

    const z = [...c, showArrow];
    if (showRightArrow) return z;
    return c;
  };
  
  const scrollToBottom = () => {
    endRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };
  
  useEffect(() => {
    if (lastRow && isOpen) {
      setTimeout(() => {
        scrollToBottom()
      }, 100)
    }
  }, [lastRow, isOpen]);
  
  return (
    <div className="table__object">
      <div
        className={`table__object-face ${classValue ? classValue : ''}`}
        onClick={() => {
          handlePanelClick();
        }}>
        {renderColumns()}
      </div>
        <div
          ref={lastRow ? endRef : null}
          className={
            "table__object-panel" + (isOpen && props.panel ? " table__object-panel--open" : "")
          }
        >
          {props.panel}
        </div>
    </div>
  );
}

export default TableItem;
