import { createAsyncThunk } from '@reduxjs/toolkit';
import { applicants } from 'services/applicants';

export const getArchivedApplicants = createAsyncThunk(
  'archivedApplicants',
  async ({ page }, { rejectWithValue }) => {
    try {
      return await applicants.getArchivedApplicants(page);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getApplicantsByStage = createAsyncThunk(
  'applicantsByStage',
  async (
    { currentTab, recruiterId, company, siteId, regionId, contractId, alertsFilter, tagsFilter, groupsFilter, sort, currentPage },
    { rejectWithValue }) => {
    try {
      return await applicants.getApplicantsByStage(currentTab, recruiterId, company, siteId, regionId, contractId, alertsFilter, tagsFilter, groupsFilter, sort, currentPage);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSearchedApplicants= createAsyncThunk(
  'searchedApplicants',
  async (
    { currentTab, recruiterId, company, siteId, regionId, contractId, alertsFilter, groupsFilter, tagsFilter, searchFilter, searchInput, sort, currentPage },
    { rejectWithValue }) => {
    try {
      return await applicants.getSearchedApplicants(currentTab, recruiterId, company, siteId, regionId, contractId, alertsFilter, groupsFilter, tagsFilter, searchFilter, searchInput, sort, currentPage);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getApplicant = createAsyncThunk(
  'applicant',
  async ({ applicantId }, { rejectWithValue }) => {
    try {
      return await applicants.getApplicant(applicantId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editApplicant = createAsyncThunk(
  'editApplicant',
  async ({ applicantId, body }, { rejectWithValue }) => {
    try {
      return await applicants.editApplicant(applicantId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editApplicantWithReplace = createAsyncThunk(
  'editApplicantWithReplace',
  async ({ applicantId, body }, { rejectWithValue }) => {
    try {
      return await applicants.editApplicantWithReplace(applicantId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getApplicantsQuestions = createAsyncThunk(
  'getApplicantsQuestions',
  async ({ contractId, applicantId }, { rejectWithValue }) => {
    try {
      return await applicants.getApplicantsQuestions(contractId, applicantId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);