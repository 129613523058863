import "../../css/modal.scss";
import React, { useEffect, useState } from "react";
import { Box, Button, MenuItem, Select, TextField } from "@mui/material";
import CustomDialog from "./custom-dialog";
import { adminErrorMessages } from 'shared/error-messages';
import API from "API";
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { usersSelector } from 'store/user/selectors';
import { getUsers } from 'store/user/thunk';
import { editApplicant } from 'store/applicants/thunk';
import { applicantErrorSelector } from 'store/applicants/selectors';
import DatePicker from 'react-datepicker';

export default function EditUserInfo(props) {
  const { applicant, getApplicant, handleClose, open, setOpenToast } = props;

  const currentUser = JSON.parse(localStorage.getItem("user"));
  const token = JSON.parse(localStorage.getItem("token"));
  
  const dispatch = useAppDispatch();
  const { allUsers } = useAppSelector(usersSelector);
  const editApplicantError = useAppSelector(applicantErrorSelector);

  const [firstName, setFirstName] = useState(
    applicant.first_name ? applicant.first_name : null
  );
  const [middleName, setMiddleName] = useState(
    applicant.middle_name ? applicant.middle_name : null
  );
  const [lastName, setLastName] = useState(
    applicant.last_name ? applicant.last_name : null
  );
  const [email, setEmail] = useState(applicant.email ? applicant.email : null);
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState(applicant.phone ? applicant.phone : null);
  const [dob, setDob] = useState(
    applicant.dob ? applicant.dob : null
  );
  const [isEditedDate, setIsEditedDate] = useState(false);
  const [isActiveDate, setIsActiveDate] = useState(false);
  const [referralType, setReferralType] = useState(applicant.application?.referral_type ? applicant.application.referral_type : null);
  const [referralInput, setReferralInput] = useState(applicant.application?.referral_input ? applicant.application.referral_input : "");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser.role === "admin") {
      dispatch(getUsers());
    }
  }, []);

  const editUser = async () => {
    if (!firstName || !lastName || !email || !phone) {
      setError("* Please fill out all required fields.");
    } else if (password.length > 0 && password.length < 9) {
      setError("Please enter a password with at least 9 characters.");
    } else if ((referralType === "referral" || referralType === "other") && !referralInput) {
      setError("Please enter how you heard about us.");
    } else {
      let requestBody = {};
      if (applicant) {
        if (applicant?.first_name !== firstName) {
          requestBody.first_name = firstName;
        }
        if (applicant?.middle_name !== middleName) {
          requestBody.middle_name = middleName;
        }
        if (applicant?.last_name !== lastName) {
          requestBody.last_name = lastName;
        }
        if (applicant?.dob !== dob && isEditedDate) {
          const month = dob.split('/')[0];
          if(+month > 12) {
            setError("Invalid date format.");
            return;
          } else {
            requestBody.dob = new Date(dob);
          }
        }
        if (applicant?.phone !== phone) {
          requestBody.phone = phone;
        }
        if (applicant?.email !== email) {
          requestBody.email = email.toLowerCase();
        }
      } else {
        requestBody = {
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          dob: dob,
          phone: phone,
          email: email,
        };
      }
      if (password.length > 0) {
        requestBody.password = password;
      }
      setLoading(true);
      await fetch(`${API.endpointURL}/api/users/${applicant.id}`, {
        method: "PUT",
        body: JSON.stringify(requestBody),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data["error"]) {
            const dataError = data["error"];
            if (currentUser.role === "admin") {
              determineAdminErrorMessage(dataError);
            } else {
              determineApplicantErrorMessage(dataError);
            }
          } else {
            if (applicant.role === "applicant" && (referralType !== applicant.application.referral_type || referralInput !== applicant.application.referral_input)) {
              editReferralInfo();
            } else {
              setPassword("");
              setError(null);
              getApplicant();
              handleClose();
              setOpenToast(true);
            }
          }
          setLoading(false);
        })
        .catch(() => setLoading(false))
    }
  };

  const editReferralInfo = async () => {
    const body = {
      referral_type: referralType,
      referral_input: referralInput,
    };
    dispatch(editApplicant({applicantId: applicant.application.id, body }))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          setPassword("");
          setError(null);
          getApplicant();
          handleClose();
          setOpenToast(true);
        }
      });
  };

  const determineApplicantErrorMessage = (errors) => {
    let errorMessage = "";
    let emailError = errors["email"] ? adminErrorMessages[errors["email"]] : "";
    let phoneError = errors["phone"] ? adminErrorMessages[errors["phone"]] : "";
    if (emailError) {
      errorMessage += `${emailError}. `;
    }
    if (phoneError) {
      errorMessage += `${phoneError}.`;
    }
    setError(errorMessage);
  };

  const determineAdminErrorMessage = (errors) => {
    let errorMessage = "";
    let emailError = errors["email"] ? adminErrorMessages[errors["email"]] : "";
    let emailUser = null;
    let phoneError = errors["phone"] ? adminErrorMessages[errors["phone"]] : "";
    let phoneUser = null;
    if (
      emailError &&
      emailError === "This email is already linked to another user"
    ) {
      emailUser = allUsers.find((person) => person.email === email);
    }
    if (
      phoneError &&
      phoneError === "This phone number is already linked to another user"
    ) {
      phoneUser = allUsers.find((person) => person.phone === phone);
    }
    if (emailUser && phoneUser && emailUser.id === phoneUser.id) {
      errorMessage = `This email and phone number are already linked to another user: ${emailUser.first_name} ${emailUser.last_name}.`;
    } else {
      if (emailError) {
        if (emailUser) {
          errorMessage += `${emailError}: ${emailUser.first_name} ${emailUser.last_name}. `;
        } else {
          errorMessage += `${emailError}. `;
        }
      }
      if (phoneError) {
        if (phoneUser) {
          errorMessage += `${phoneError}: ${phoneUser.first_name} ${phoneUser.last_name}.`;
        } else {
          errorMessage += `${phoneError}.`;
        }
      }
    }
    setError(errorMessage);
  };

  const editInfoForm = () => {
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "48%" }}>
            <div style={{ textAlign: "left" }} className="form-row-label">
              First Name *
            </div>
            <TextField
              fullWidth
              id="edit-first-name"
              key="edit-first-name"
              placeholder="First Name"
              variant="outlined"
              margin="dense"
              required
              value={firstName}
              onChange={(e) => setFirstName(e.currentTarget.value)}
            />
          </Box>
          <Box sx={{ width: "48%" }}>
            <div style={{ textAlign: "left" }} className="form-row-label">
              Middle Initial
            </div>
            <TextField
              fullWidth
              id="edit-middle-name"
              key="edit-middle-name"
              placeholder="Middle Initial"
              variant="outlined"
              margin="dense"
              value={middleName}
              onChange={(e) => setMiddleName(e.currentTarget.value)}
            />
          </Box>
          <Box sx={{ width: "48%" }}>
            <div style={{ textAlign: "left" }} className="form-row-label">
              Last Name *
            </div>
            <TextField
              fullWidth
              id="edit-last-name"
              key="edit-last-name"
              placeholder="Last Name"
              variant="outlined"
              margin="dense"
              required
              value={lastName}
              onChange={(e) => setLastName(e.currentTarget.value)}
            />
          </Box>
          <Box sx={{ width: "48%", position: 'relative' }}>
            {isActiveDate && (
              <p className="applicant__date-message">
                Allowed format: MM/DD/YYYY
              </p>
            )}

            <div style={{ textAlign: "left" }} className="form-row-label">
              Date of Birth
            </div>
            <DatePicker
              className="applicant__date"
              selected={
                dob
                  ? (typeof dob === 'string' ? new Date(dob) : dob)
                  : null
              }
              placeholderText={dob ? dob : 'MM/DD/YYYY'}
              onChange={(date) => {
                if (date) {
                  const dd = String(date?.getDate()).padStart(2, '0');
                  const mm = String(date?.getMonth() + 1).padStart(2, '0');
                  const yyyy = date?.getFullYear();
                  const dateString = yyyy + '-' + mm + '-' + dd;
                  setDob(dateString);
                } else {
                  setDob('');
                }
                setIsEditedDate(true);
              }}
              onFocus={() => {
                setIsActiveDate(true);
              }}
              onBlur={() => {
                setIsActiveDate(false);
              }}
            />
          </Box>
          <Box sx={{ width: "48%" }}>
            <div style={{ textAlign: "left" }} className="form-row-label">
              Phone Number *
            </div>
            <TextField
              fullWidth
              id="edit-phone"
              key="edit-phone"
              placeholder="Phone Number"
              variant="outlined"
              margin="dense"
              required
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.currentTarget.value)}
            />
          </Box>
          <Box sx={{ width: "48%" }}>
            <div style={{ textAlign: "left" }} className="form-row-label">
              Email Address *
            </div>
            <TextField
              fullWidth
              id="edit-email"
              key="edit-email"
              placeholder="Email Address"
              variant="outlined"
              margin="dense"
              required
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </Box>
          <Box sx={{ width: "48%" }}>
            <div style={{ textAlign: "left" }} className="form-row-label">
              Password Reset
            </div>
            <TextField
              fullWidth
              id="edit-password"
              key="edit-password"
              placeholder="New Password"
              variant="outlined"
              margin="dense"
              required
              value={password}
              error={password.length > 0 && password.length < 9}
              onChange={(e) => setPassword(e.currentTarget.value)}
              helperText={
                password.length > 0 && password.length < 9
                  && "Password must be at least 9 characters."
              }
            />
          </Box>
          {applicant.role === "applicant" && (
            <>
              <Box sx={{ width: "48%" }}>
                <div style={{ textAlign: "left" }} className="form-row-label">
                  How did you hear about us?
                </div>
                <Select
                  displayEmpty
                  value={referralType}
                  onChange={(e) => setReferralType(e.target.value)}
                  sx={{
                    backgroundColor: "#EFEFEF",
                    border: 0,
                    fontFamily: "Korolev Medium",
                    margin: "8px 0 4px",
                    width: "100%",
                  }}
                >
                  <MenuItem
                    value="indeed"
                    sx={{ fontFamily: "Korolev Medium" }}
                  >
                    Indeed
                  </MenuItem>
                  <MenuItem
                    value="linkedin"
                    sx={{ fontFamily: "Korolev Medium" }}
                  >
                    LinkedIn
                  </MenuItem>
                  <MenuItem
                    value="company_website"
                    sx={{ fontFamily: "Korolev Medium" }}
                  >
                    Company Website
                  </MenuItem>
                  <MenuItem
                    value="flyer_door_hang"
                    sx={{ fontFamily: "Korolev Medium" }}
                  >
                    Flyer/Door hang
                  </MenuItem>
                  <MenuItem
                    value="social_media"
                    sx={{ fontFamily: "Korolev Medium" }}
                  >
                    Other social media
                  </MenuItem>
                  <MenuItem
                    value="in_person_hiring_event"
                    sx={{ fontFamily: "Korolev Medium" }}
                  >
                    In-Person Hiring Event
                  </MenuItem>
                  <MenuItem
                    value="referral"
                    sx={{ fontFamily: "Korolev Medium" }}
                  >
                    Referral
                  </MenuItem>
                  <MenuItem
                    value="other"
                    sx={{ fontFamily: "Korolev Medium" }}
                  >
                    Other
                  </MenuItem>
                </Select>
              </Box>
              <Box sx={{ width: "48%" }}>
                {(referralType === "referral" || referralType === "other") && (
                  <>
                    <div style={{ textAlign: "left" }} className="form-row-label">
                      Please elaborate...
                    </div>
                    <TextField
                      fullWidth
                      id="add-referral-note"
                      key="add-referral-note"
                      placeholder={referralType === "referral" ? "Name" : "How did you hear about us?"}
                      variant="outlined"
                      margin="dense"
                      value={referralInput}
                      onChange={(e) => setReferralInput(e.target.value)}
                    />
                  </>
                )}
              </Box>
            </>
          )}
        </Box>
        {error && <div className="add-user-error">{error}</div>}
        {editApplicantError && <div className="add-user-error">{editApplicantError}</div>}
        <div className="button-container" style={{ overflow: 'hidden', paddingTop: '5px' }}>
          <Button
            variant="contained"
            className="add-user-button"
            disabled={loading}
            sx={{
              backgroundColor: "#556155",
              color: "#EFEFEF",
              fontSize: "18px",
              width: "175px",
              "&:hover": { backgroundColor: "#a5af96", color: "#3e453d" },
            }}
            onClick={() => editUser()}
          >
            Edit
            {loading && (
              <div style={{ position: 'absolute', left: -55 }}>
                <ProgressSpinnerBlue />
              </div>
            )}
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <CustomDialog
      dialogContent={editInfoForm()}
      onClose={handleClose}
      open={open}
      title={"EDIT"}
      titleHighlight={"USER"}
    />
  );
}
