import "../../css/modal.scss";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";


export default function CustomDialogEqip(props) {

  const { dialogContent, onClose, open, title } = props;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            backgroundColor: "#556155",
            borderRadius: "28px",
            minWidth: "800px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            margin: "30px 35px 0px",
            backgroundColor: "#3e453d",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            padding: "0"
          }}
        >
          <Box
            sx={{
              color: "#D2D9AE",
              fontFamily: "MagistralCondW08-Medium",
              fontSize: "35px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "56px",
              textTransform: "uppercase",
              width: "100%"
            }}
          >
            {title}
          </Box>
          <IconButton
            onClick={onClose}
            sx={{
              color: "#D2D9AE",
              padding: 0,
              right: "-13px",
              top: "-40px",
              width: "1%",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#EFEFEF"
              },
            }}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0 35px 35px" }}>
          {dialogContent}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
