import React, { useEffect, useRef } from 'react';
import { Document, Page } from "react-pdf";
import { Box, IconButton } from "@mui/material";
import fileIcon from "assets/uploaded_file.svg";
import { HighlightGreenAutoStyledButton } from "components/buttons/button-styles";
import { uploadTypeToFile } from 'shared/applicant-data';
import API from "API";
import ProgressSpinner from 'components/progress/progress-spinner';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { currentApplicantSelector } from 'store/applicants/selectors';
import { getApplicantFiles } from 'store/files/thunk';
import { applicantFilesErrorSelector, applicantFilesSelector, filesLoadingSelector } from 'store/files/selectors';

export default function StaffingDropBox({ setOpenDocuments }) {
  const ref = useRef(null);

  const dispatch = useAppDispatch();
  const currentApplicant = useAppSelector(currentApplicantSelector);
  const applicantFiles = useAppSelector(applicantFilesSelector);
  const loading = useAppSelector(filesLoadingSelector);
  const error = useAppSelector(applicantFilesErrorSelector);
  
  useEffect(() => {
    if (currentApplicant) {
      dispatch(getApplicantFiles({ id: currentApplicant?.id }));
    }
  }, []);
  
  
  useEffect(() => {
    if (!loading) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [loading]);
  
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const buildIcon = (file) => {
    const ext = file.file_name.split(".").pop().toLowerCase();
    if (ext == "pdf") {
      return (
        <Document
          loading={
            <img
              src={fileIcon}
              width="75px"
              height="auto"
              alt="open-file-button"
            />
          }
          key={file.file_name}
          className={"TESTING"}
          file={`${API.endpointURL}/api/cloud_location?location=${file.file_name}`}
        >
          <Page pageNumber={1} width={75} renderAnnotationLayer={false} />
        </Document>
      );
    } else if (ext == "png" || ext == "jpg" || ext == "jpeg") {
      return (
        <img
          src={`${API.endpointURL}/api/cloud_location?location=${file.file_name}`}
          width="75px"
          height="auto"
          alt="open-file-button"
        />
      );
    }
    return (
      <img
        src={fileIcon}
        width="75px"
        height="auto"
        alt="open-file-button"
      />
    );
  };

  const buildView = () => {
    let files = [];
    if (applicantFiles) {
      applicantFiles.forEach((file, index) => {
        files.push(
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "21px",
              marginRight: "21px",
              width: "125px",
            }}
            onClick={() => {
              openInNewTab("/viewer/" + file.id);
            }}
          >
            <IconButton sx={{ "&:hover": { backgroundColor: "transparent" } }}>
              {buildIcon(file)}
            </IconButton>
            <div
              style={{
                color: "#EFEFEF",
                fontFamily: "Korolev Medium",
                fontSize: "0.85rem",
                lineHeight: "1rem",
                marginTop: "8px",
                textAlign: "center",
                width: "100%",
                wordWrap: "break-word",
              }}
            >
              {file.display_name
                ? file.display_name
                : `${uploadTypeToFile[file.type]} v${file.version}`}
            </div>
          </div>
        );
      });
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          padding: "16px",
          width: "100%",
        }}
      >
        {files}
      </Box>
    );
  };
  
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ProgressSpinner/>
      </div>
    )
  }
  
  return (
    <div style={{ overflowY: 'auto', minHeight: '200px' }}>
      <div style={{ margin: "16px auto 0", width: "50%" }}>
        <HighlightGreenAutoStyledButton
          variant="contained"
          onClick={() => setOpenDocuments(true)}
        >
          Upload document
        </HighlightGreenAutoStyledButton>
      </div>
      {error.length > 0 ? error : buildView()}
      <div ref={ref} />
    </div>
  );
}
