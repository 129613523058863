import "../../css/application.scss";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "API";
export default function CBPApplication() {
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`${API.endpointURL}/api/get-alias?contract_id=${API.cbpContractId}`)
      .then((response) => {
        if (!response.ok) {
          navigate('/general-application');
        }
        return response.json();
        })
      .then((data) => {
        const contractAlias = data.contract_alias;
        navigate(`/application/${contractAlias}`);
      });
  }, []);

  return (
    <div className="background">
      <div className="copyright">©2023 Responsive Deployment</div>
    </div>
  );
}
