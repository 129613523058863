import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import 'css/analytics.scss';
import 'css/pages.scss';
import 'css/metrics.scss';

import FillBarSolid from 'components/widgets/fill-bar-solid';
import { referralTypes } from 'shared/applicant-data';
import ProgressSpinner from 'components/progress/progress-spinner';
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

import { useAppSelector } from 'hooks/reduxHooks';
import { contractMetricsMetricsSelector, contractsSelector } from 'store/contracts/selectors';

export default function ContractIdAnalytics({ currentContractId, color, setCurrentContract }) {
  const { pathname } = useLocation();
  const contractPortal = pathname.includes('contract-portal');
  
  const [totalApplicants, setTotalApplicants] = useState(0);
  
  const contracts = useAppSelector(contractsSelector);
  const {
    contractTitle,
    applicantsHired,
    applicantsNeeded,
    hiresNeededPerDay,
    outstandingHires,
    recruiterMetrics,
    referralMetrics,
    loading,
    error,
  } = useAppSelector(contractMetricsMetricsSelector);
  
  useEffect(() => {
    if (referralMetrics && referralMetrics.numbers) {
      let total = 0;
      for (const [_, value] of Object.entries(referralMetrics.numbers)) {
        total += value;
      }
      setTotalApplicants(total);
    }
  }, [referralMetrics]);
  
  const recruiterHires = () => {
    if (recruiterMetrics) {
      return recruiterMetrics.map((recruiter) => (
        <div key={recruiter.id} className="fill-stat-container">
          <p className="stage-stat-header">{`${recruiter.first_name} ${recruiter.last_name}`}</p>
          <FillBarSolid
            color={color}
            value={recruiter?.hired_applicants}
            maxValue={applicantsNeeded / recruiterMetrics.length}
          />
        </div>
      ));
    }
    return null;
  };
  
  const referrals = () => {
    if (referralMetrics && referralMetrics?.numbers) {
      return Object.entries(referralMetrics.numbers).map(([key, value]) => (
        <div key={key} className="fill-stat-container">
          <p className="stage-stat-header">{referralTypes[key] || key}</p>
          <FillBarSolid color={color} value={value} maxValue={totalApplicants}/>
        </div>
      ));
    }
    return null;
  };
  
  const contractOptions = () => {
    if (!contracts) {
      return [];
    }
    
    return contracts.map((contract) => (
      <option key={contract.id} value={contract.id}>
        {contract.title}
      </option>
    ));
  };
  
  if (error.length > 0) {
    return (
      <>
        <h1 className="content-header">Contract Analytics</h1>
        <div className="metrics-content-container">
          <div className="stats-group-header">{error}</div>
        </div>
      </>
    );
  }
  
  return (
    <>
      <div className="content-header content-header-flex">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {contracts.length > 0 && !contractPortal && (
            <i
              className={`fa-solid fa-angle-left blue-arrow ${color}-arrow analytics-back-button`}
              onClick={() => setCurrentContract(null)}
            />
          )}
          <h1>Contract Analytics</h1>
        </div>
        {contracts.length > 0 && !contractPortal && (
          <div className="applicants-hired-per-interval">
            <select
              name="contract" id="contract"
              className="contract-form-input"
              value={currentContractId}
              onChange={(e) => setCurrentContract(e.currentTarget.value)}
            >
              {contractOptions()}
            </select>
          </div>
        )}
      </div>
      <div className="metrics-content-container">
        {loading ? (
          <div className="content-loading" style={{ height: 'calc(100% - 140px)' }}>
            {color === 'green' ?  <ProgressSpinner /> : <ProgressSpinnerBlue /> }
          </div>
        ) : (
          <>
            <div className="stats-group-container">
              <h2 className="stats-group-header">Applicants Needed for {contractTitle}</h2>
              <div className="hiring-matrix-container">
                <div className="matrix-total-container">
                  <div className={`total-applicants-number total-applicants-number--${color}`}>
                    {applicantsNeeded && applicantsNeeded > 0 ? applicantsNeeded.toLocaleString() : 'N/A'}
                  </div>
                  <p className="stage-stat-header">Contracted Personnel</p>
                </div>
                <div className="matrix-breakdown-container">
                  <div className="fill-stat-container">
                    <p className="stage-stat-header">Applicants Hired</p>
                    {applicantsHired &&
                      <FillBarSolid color={color} value={applicantsHired} maxValue={applicantsNeeded}/>}
                  </div>
                  <div className="fill-stat-container">
                    <p className="stage-stat-header">Applicants Needed</p>
                    {outstandingHires &&
                      <FillBarSolid color={color} value={outstandingHires} maxValue={applicantsNeeded}/>}
                  </div>
                </div>
              </div>
            </div>
            
            <div className="stats-group-container">
              <h2 className="applicants-hired-per-header">
                Applicants Needed per Day
              </h2>
              {hiresNeededPerDay && hiresNeededPerDay > 0 ? (
                <p className={`applicants-hired-per-number applicants-hired-per-number--${color}`}>
                  {hiresNeededPerDay}
                </p>
              ) : (
                <p style={{ fontFamily: 'Korolev Medium' }}>No hires needed to meet contract deadline.</p>
              )}
            </div>
            
            {recruiterMetrics && (
              <div className="stats-group-container">
                <h2 className="stats-group-header">Total Hires per Recruiter for {contractTitle}</h2>
                <div className="hiring-matrix-container">
                  <div className="matrix-total-container">
                    <div className={`applicants-hired-per-number applicants-hired-per-number--${color}`}>
                      {applicantsNeeded &&
                      recruiterMetrics.length > 0
                        ? (Math.round((applicantsNeeded / recruiterMetrics.length) * 100) / 100)
                        : 'N/A'}
                    </div>
                    <p className="stage-stat-header">Hires per Recruiter (Target)</p>
                  </div>
                  <div className="matrix-breakdown-container">
                    {recruiterMetrics.length > 0 ? recruiterHires() : (
                      <p style={{ fontFamily: 'Korolev Medium' }}>
                        No recruiters are currently assigned to this contract.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
            
            <div className="stats-group-container">
              <h2 className="stats-group-header">How Applicants Heard About Us</h2>
              <div className="hiring-matrix-container">
                <div className="matrix-total-container">
                  <div className={`total-applicants-number total-applicants-number--${color}`}>
                    {totalApplicants}
                  </div>
                  <p className="stage-stat-header">Contract Applicants</p>
                </div>
                <div className="matrix-breakdown-container">
                  {referralMetrics && referralMetrics.numbers && totalApplicants > 0 ? referrals() : (
                    <p style={{ fontFamily: 'Korolev Medium' }}>
                      No applicants are currently assigned to this contract.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {/* TODO: Implement once we nail down thresholds? + update state with fetch
          const [contractEnded, setContractEnded] = useState(null);
          const [averageDailyHires, setAverageDailyHires] = useState(0);
          const [averageDailyNeededHires, setAverageDailyNeededHires] = useState(0);
          const [currentDailyHires, setCurrentDailyHires] = useState(0);

        <div className="stats-group-container">
          <div className="stats-group-header">Daily Hires</div>
          <div className="contract-totals-row">
            <div style={{ width: "50%" }}>
              <Gauge
                title={`${Number(averageDailyNeededHires)} Average Daily Hires Needed`}
                value={Number(averageDailyHires)}
                maxValue={Number(averageDailyNeededHires)}
              />
            </div>
            {!contractEnded && (
              <div style={{ width: "50%" }}>
                <Gauge
                  title={`${Number(hiresNeededPerDay)} Current Daily Hires Needed`}
                  value={Number(currentDailyHires)}maxValue={Number(hiresNeededPerDay)}
                />
              </div>
            )}
          </div>
        </div> */}
      </div>
    </>
  );
};
