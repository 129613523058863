import "../../css/filter.scss";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../components/search-box/search-box";

export default function SearchMenu(props) {
  const {
    getUsers,
    searchApplicants,
    searchField,
    searchFilter,
    setCurrentPage,
    setSearchField,
    setSearchFilter,
    setSearchedUsers,
  } = props;

  const buildCategoriesMenu = () => {
    return (
      <>
        <button
          className={
            searchFilter === "last_name"
              ? "category-button-selected"
              : "category-button"
          }
          onClick={() => setSearchFilter("last_name")}
        >
          Last Name
        </button>
        <button
          className={
            searchFilter === "first_name"
              ? "category-button-selected"
              : "category-button"
          }
          onClick={() => setSearchFilter("first_name")}
        >
          First Name
        </button>
        <button
          className={
            searchFilter === "email"
              ? "category-button-selected"
              : "category-button"
          }
          onClick={() => setSearchFilter("email")}
        >
          Email Address
        </button>
        <button
          className={
            searchFilter === "phone"
              ? "category-button-selected last-button"
              : "category-button last-button"
          }
          onClick={() => setSearchFilter("phone")}
        >
          Phone Number
        </button>
      </>
    );
  };

  return (
    <>
      <div>
        <Search>
          <StyledInputBase
            placeholder="Search…"
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                setCurrentPage(1);
                searchApplicants();
              }
            }}
            value={searchField}
            inputProps={{ "aria-label": "search" }}
            sx={{ color: "#EFEFEF", fontFamily: "Korolev Medium" }}
            onChange={(e) => setSearchField(e.target.value)}
          />
          <SearchIconWrapper>
            <SearchIcon
              sx={{ color: "#EFEFEF", height: "21px", width: "auto" }}
            />
          </SearchIconWrapper>
        </Search>
        <div
          className="clear-button"
          onClick={() => {
            setCurrentPage(1);
            setSearchField("");
            setSearchedUsers(null);
            getUsers();
          }}
        >
          Clear
        </div>
      </div>
      <div className="options-container">
        <div className="options-header">
          <div className="options-header-single-option">
            Search
          </div>
        </div>
        {buildCategoriesMenu()}
      </div>
    </>
  );
}
