import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Box, Checkbox, Typography } from '@mui/material';
import Toast from 'components/toast/toast';
import { formatUTCDateTime } from 'shared/formatting';
import { useAppSelector } from 'hooks/reduxHooks';
import { applicantSelector, currentApplicantSelector } from 'store/applicants/selectors';
import { contractTemplatesSelector, loadingTemplatesSelector } from 'store/contracts/selectors';
import ProgressSpinner from 'components/progress/progress-spinner';


export default function InterviewAutomation(props) {
  const { updateApplicant, setButton } = props;

  const allTemplates = useAppSelector(contractTemplatesSelector);
  const loadingTemplates = useAppSelector(loadingTemplatesSelector);
  const currentApplicant = useAppSelector(currentApplicantSelector);
  const applicantData = useAppSelector(applicantSelector);

  const [selectedDate, setSelectedDate] = useState(null);
  const [openEmailToast, setOpenEmailToast] = useState(false);
  const [openErrorToast, setOpenErrorToast] = useState(false);

  const templates = allTemplates.filter(item => item?.template_type.includes('interview'));

  useEffect(() => {
    setSelectedDate(null);
  }, [currentApplicant]);

  const filteredTemplates = templates.filter(item => item?.template_type.includes('interview'));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {loadingTemplates ? (
        <div className="contracts-loading-spinner">
          <ProgressSpinner />
        </div>
      ) : (
        <Box display="flex" flexDirection="column" gap="5px" justifyContent="space-between">
          {filteredTemplates[0]?.active === true &&
            setButton(
              0,
              'interview_button_1',
              'interview_button_1_confirm',
              setOpenErrorToast,
            )}
          {filteredTemplates[1]?.active === true &&
            setButton(
              1,
              'interview_button_2',
              'interview_button_2_confirm',
              setOpenErrorToast,
            )}
          {filteredTemplates[2]?.active === true &&
            setButton(
              2,
              'interview_button_3',
              'interview_button_3_confirm',
              setOpenErrorToast,
            )}
          {filteredTemplates[3]?.active === true &&
            setButton(
              3,
              'interview_button_4',
              'interview_button_4_confirm',
              setOpenErrorToast,
            )}
          {filteredTemplates[4]?.active === true &&
            setButton(
              4,
              'interview_button_5',
              'interview_button_5_confirm',
              setOpenErrorToast,
            )}
        </Box>
      )}
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <div className="confirm-date-section">
          {applicantData?.application?.interview_date ? (
            <Typography
              sx={{ fontFamily: 'Korolev Medium', textAlign: 'left' }}
            >
              <Checkbox
                key={'datePickerDisabled'}
                defaultChecked
                onClick={() => updateApplicant('interview_date', null)}
                sx={{
                  color: '#EFEFEF',
                  '&.Mui-checked': {
                    color: '#62AB37',
                  },
                  '&.Mui-disabled': {
                    color: '#EFEFEF40',
                  },
                }}
              />
              Confirm Date
              <Typography>
                {formatUTCDateTime(applicantData?.application?.interview_date)}
              </Typography>
            </Typography>
          ) : (
            <Box
              display="flex"
              flexDirection="row"
              sx={{ alignItems: 'center' }}
            >
              <Checkbox
                key={'datePickerEnabled'}
                onClick={() =>
                  updateApplicant(
                    'interview_date',
                    new Date(selectedDate).toISOString(),
                  )
                }
                sx={{
                  color: '#EFEFEF',
                  '&.Mui-checked': {
                    color: '#62AB37',
                  },
                  '&.Mui-disabled': {
                    color: '#EFEFEF40',
                  },
                }}
              />
              <Typography
                sx={{
                  fontFamily: 'Korolev Medium',
                  mr: '5px',
                  width: '50%',
                  textAlign: 'left',
                }}
              >
                Confirm Date
              </Typography>
              <DatePicker
                wrapperClassName="date-picker"
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
              />
            </Box>
          )}
          {applicantData?.application?.interview_status ? (
            <Typography
              sx={{ fontFamily: 'Korolev Medium', textAlign: 'left' }}
            >
              <Checkbox
                key={'interviewConductedDisabled'}
                checked
                sx={{
                  color: '#EFEFEF',
                  '&.Mui-checked': {
                    color: '#62AB37',
                  },
                  '&.Mui-disabled': {
                    color: '#EFEFEF40',
                  },
                }}
                onClick={() => updateApplicant('interview_status', null)}
              />
              Interview Conducted
            </Typography>
          ) : (
            <Typography
              sx={{ fontFamily: 'Korolev Medium', textAlign: 'left' }}
            >
              <Checkbox
                key={'interviewConductedEnabled'}
                onClick={() =>
                  updateApplicant('interview_status', new Date().toISOString())
                }
                sx={{
                  color: '#EFEFEF',
                  '&.Mui-checked': {
                    color: '#62AB37',
                  },
                  '&.Mui-disabled': {
                    color: '#EFEFEF40',
                  },
                }}
              />
              Interview Conducted
            </Typography>
          )}
        </div>
      </Box>

      {openEmailToast && (
        <Toast
          open={openEmailToast}
          onClose={() => setOpenEmailToast(false)}
          message="Email sent"
        />
      )}
      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message="There was an issue sending your email. Please try again later."
        />
      )}
    </Box>
  );
};
