import React from "react";
import TabView from "components/generic/tab-view";
import TabViewItem from "components/generic/tab-view-item";
import CustomersTable from "components/table/customers-table";

import {ProgressSpinnerBlue} from "../progress/progress-spinner-blue";

export default function Customers(props) {
  const { templates, loading, customers } = props;

  return (
    <React.Fragment>
      <TabView>
        <TabViewItem title="All Customers">
          {!loading ? (
            <CustomersTable templates={templates} customers={customers} />
          ) : (
            <div className="spinner-fullScreen">
              <ProgressSpinnerBlue />
            </div>
          )}
        </TabViewItem>
        <TabViewItem title="New Customers">
          <div style={{ marginTop: "32px", textAlign: "center" }}>
            Coming Soon to a Tab near you!
          </div>
        </TabViewItem>
      </TabView>
    </React.Fragment>
  );
}
