import React, { useEffect, useState } from 'react';
import { MenuItem, Select, TextField } from '@mui/material';
import API from 'API';
import { useLocation } from "react-router-dom";
import Switch from '@mui/material/Switch';
import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';

const initialErrors = {
  full_name: false,
  phone_number: false,
  email: false,
  address: false,
  city: false,
  state: false,
  zip_code: false,
};

export const SalesConnectionsModalFour = ({
  connectionType,
  companyConnection,
  setCompanyConnection,
  setStep,
  handleCreate,
  loading,
  isCompetitor,
  setIsCompetitor,
}) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const [errors, setErrors] = useState(initialErrors);
  const [personalConnections, setPersonalConnections] = useState([]);
  const [loadingPersonal, setLoadingPersonal] = useState(false);
  const { pathname } = useLocation();

  const handleChange = (e) => {
    setCompanyConnection({
      ...companyConnection,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: !e.target.value,
    });
  };

  const handleContinue = () => {
    return isInvalid ? setInvalid() : handleCreate();
  };

  const isInvalid = !companyConnection.full_name
    || !companyConnection.email
    || !companyConnection.phone_number
    || !companyConnection.address
    || !companyConnection.city
    || !companyConnection.state
    || !companyConnection.zip_code;

  const setInvalid = () => {
    setErrors({
      full_name: !companyConnection.full_name,
      phone_number: !companyConnection.phone_number,
      email: !companyConnection.email,
      address: !companyConnection.address,
      city: !companyConnection.city,
      state: !companyConnection.state,
      zip_code: !companyConnection.zip_code,
    });
  };

  const getPersonalConnections = () => {
    setLoadingPersonal(true);
    fetch(`${API.endpointURL}/api/filter/connections?connection_type=personal`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setPersonalConnections(res.data);
        setLoadingPersonal(false);
      })
      .catch(() => setLoadingPersonal(false));
  };

  useEffect(() => {
    getPersonalConnections();
  }, [companyConnection.personal_connection_id]);

  return (
    <div className="connection__modal-content">
      <p className="connection__modal-title connection__modal-title--italic">
        Fill Out the following information for your
        <span className="connection__modal-title--bold">
          {`  ${connectionType}  `}
        </span>
        {connectionType !== 'COMPETITOR' && 'connection:'}
      </p>
      <div className="connection__modal-inputs">
        <div className="connection__modal-fields">
          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: 'left' }}>
              Full {` ${connectionType} `} Name *
            </p>
            <TextField
              value={companyConnection.full_name}
              name="full_name"
              onChange={handleChange}
              fullWidth
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '6px 10px 5px',
                marginBottom: 0,
              }}
              placeholder="Full Name..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.full_name && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>
          {pathname.includes('portal') && (
            <div className="connection__modal-field">
              <p className="form-row-label" style={{ textAlign: "left" }}>
                Relationship
              </p>
              <TextField
                value={companyConnection.note_body}
                name="note_body"
                onChange={handleChange}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  border: "1px solid #a5af96",
                  borderRadius: "8px",
                  padding: "6px 10px 5px",
                  marginBottom: 0,
                }}
                placeholder="Relationship..."
                multiline={false}
                rows={1}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
          )}
        </div>

        <div className="connection__modal-fields">
          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: 'left' }}>
              POC Connection *
            </p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={companyConnection.personal_connection_id}
              onChange={(event) => {
                setCompanyConnection({
                  ...companyConnection,
                  personal_connection_id: event.target.value,
                });
              }}
              sx={{
                backgroundColor: '#fff',
                border: 0,
                borderRadius: '8px',
                textAlign: 'left',
                width: '100%',
                '& .MuiSelect-outlined.MuiOutlinedInput-input': {
                  paddingTop: '10.8px',
                  paddingBottom: '11px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #a5af96',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #a5af96',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#a5af96',
                },
              }}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return (
                    <span
                      style={{
                        color: 'rgba(0,0,0,0.4)',
                        fontFamily: 'Roboto, sans-serif',
                      }}
                    >
                      Select POC connection
                    </span>
                  );
                }
                return personalConnections?.find(item => item.id === selected)?.full_name;
              }}
              placeholder="choose connection"
            >
              <MenuItem value="">
                <em>Select POC connection</em>
              </MenuItem>
              {personalConnections.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.full_name}</MenuItem>
              ))}
            </Select>
          </div>

          <div className="connection__modal-field">
            <button
              className="connection__modal-button connection__modal-button--small"
              onClick={() => setStep(2)}
              style={{ whiteSpace: 'nowrap' }}
            >
              Create New
            </button>
          </div>


          {loadingPersonal && (
            <div className="connection__modal-spinner-two">
              <ProgressSpinnerDarkNavy />
            </div>
          )}
        </div>

        <div className="connection__modal-fields">
          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: 'left' }}>
              Phone number *
            </p>
            <TextField
              value={companyConnection.phone_number}
              name="phone_number"
              onChange={handleChange}
              fullWidth
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '6px 10px 5px',
                marginBottom: 0,
              }}
              placeholder="Phone..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.phone_number && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>

          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: 'left' }}>
              Email *
            </p>
            <TextField
              value={companyConnection.email}
              name="email"
              onChange={handleChange}
              fullWidth
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '6px 10px 5px',
                marginBottom: 0,
              }}
              placeholder="Email..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.email && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>
        </div>

        <div className="connection__modal-fields">
          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: 'left' }}>
              Address Line 1 *
            </p>
            <TextField
              value={companyConnection.address}
              name="address"
              onChange={handleChange}
              fullWidth
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '6px 10px 5px',
                marginBottom: 0,
              }}
              placeholder="Address Line 1..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.address && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>

          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: 'left' }}>
              City *
            </p>
            <TextField
              value={companyConnection.city}
              name="city"
              onChange={handleChange}
              fullWidth
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '6px 10px 5px',
                marginBottom: 0,
              }}
              placeholder="City..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.city && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>
        </div>

        <div className="connection__modal-fields">

          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: 'left' }}>
              State *
            </p>
            <TextField
              value={companyConnection.state}
              name="state"
              onChange={handleChange}
              fullWidth
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '6px 10px 5px',
                marginBottom: 0,
              }}
              placeholder="State..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.state && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>

          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: 'left' }}>
              ZIP Code *
            </p>
            <TextField
              value={companyConnection.zip_code}
              name="zip_code"
              onChange={handleChange}
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #a5af96',
                borderRadius: '8px',
                padding: '6px 10px 5px',
                marginBottom: 0,
              }}
              placeholder="ZIP code..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.zip_code && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>

          {(pathname !== '/sales' && pathname !== '/projects') && (
            <div className="sale-form-set sale-form-set-row sale-form-competitor">
              <p className="form-row-label" style={{ textAlign: 'left' }}>
                Competitor
              </p>
              <Switch
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#141A66',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#141A66',
                  },
                  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                    backgroundColor: '#c7c7c7',
                  },
                }}
                checked={isCompetitor}
                onChange={() => setIsCompetitor(!isCompetitor)}
              />
            </div>
          )}

        </div>
      </div>

      <div className="connection__modal-buttons">
        {loading && (
          <div className="connection__modal-spinner">
            <ProgressSpinnerDarkNavy />
          </div>
        )}
        <button
          className="connection__modal-button connection__modal-button--back"
          onClick={() => setStep(1)}
        >
          Back
        </button>
        <button
          className="connection__modal-button"
          onClick={handleContinue}
        >
          Create
        </button>
      </div>
    </div>
  );
};