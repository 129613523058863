import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import DatePicker from "react-datepicker";
import { Checkbox } from "@mui/material";

import "css/create-new-vendor.scss";

import Toast from 'components/toast/toast';
import { ProgressSpinnerDarkNavy } from "components/progress/progress-spinner-dark-navy";

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { projectsCoordinatorsSelector, projectsManagersSelector } from 'store/projects/selectors';
import { createTask } from 'store/tasks/thunk';
import { taskSelector } from 'store/tasks/selectors';
import { HighlightNavyAutoStyledButton } from 'components/buttons/button-styles';

const initialTask = {
  name: '',
  description: '',
  date_assigned: '',
  date_due: '',
  personnel_assigned_id: '',
  file_active: false,
};

const initialErrors = {
  name: false,
  description: false,
  date_assigned: false,
  date_due: false,
  personnel_assigned_id: false,
  server_error: false,
}

export default function CreateNewTask({ refreshTasks, onClose }) {
  const {id} = useParams();
  
  const dispatch = useAppDispatch();
  const { projectCoordinators } = useAppSelector(projectsCoordinatorsSelector);
  const { projectManagers } = useAppSelector(projectsManagersSelector);
  const { createTaskLoading, createTaskError } = useAppSelector(taskSelector);
  
  const [taskForm, setTaskForm] = useState(initialTask);
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const [personnel, setPersonnel] = useState([]);
  
  const getPersonnel = () => {
    setPersonnel([...projectManagers, ...projectCoordinators]);
  };
  
  useEffect(() => {
    getPersonnel();
  }, []);
  
  const handleChange = (event) => {
    setTaskForm({
      ...taskForm,
      [event.target.name]: event.target.value,
    })
    if (!event.target.value.length) {
      setErrors({
        ...errors,
        [event.target.name]: true,
      })
    } else {
      setErrors({
        ...errors,
        [event.target.name]: false,
      })
    }
  }
  
  const handleCreate = () => {
    const isValidLength =
      Object
        .entries(taskForm)
        .every((item) => {
          if (!item[0].includes('date') && !item[0].includes('active')) {
            return item[1].length < 256;
          }
          return true;
        });
    const isValidFields =
      Object
        .entries(taskForm)
        .every((item) => {
          if (!item[0].includes('active')) {
            return item[1];
          }
          return true;
        });

    if (!isValidFields) {
      setErrors({
        name: !taskForm.name,
        description: !taskForm.description,
        date_assigned: !taskForm.date_assigned,
        date_due: !taskForm.date_due,
        personnel_assigned_id: !taskForm.personnel_assigned_id,
        file_active: !taskForm.file_active,
      });
    }

    if (isValidFields && isValidLength) {
      const body = {
        name: taskForm.name,
        personnel_assigned_id: taskForm.personnel_assigned_id,
        description: taskForm.description,
        date_assigned: taskForm.date_assigned,
        date_due: taskForm.date_due,
        file_active: taskForm.file_active,
        type: 'task',
        status: 'assigned',
        project_id: id
      };
      
      dispatch(createTask({ body }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            refreshTasks();
            setOpenToast(true);
            setTaskForm(initialTask);
            setErrors({
              ...errors,
              validation: false,
              server_error: false,
            });
            onClose();
          } else {
            setErrors({
              ...errors,
              server_error: true,
            });
            setOpenToast(true);
          }
        })
    }
  };


  const renderFetchedOptions = (type)=>{
    return (
      <>
        <option value="">Select {type}...</option>
        {personnel.map((element) => (
          <option key={element.id} value={element.id}>
            {`${element.first_name} ${element.last_name}`}
          </option>
        ))}
      </>
    )
  }


  return (
    <>
      <div
        className="content-container content-dark-color navy-color-palette"
        style={{ height:'auto', padding: '0px', maxWidth:'685px' }}
      >
          <div className="sale-form-section-content" style={{ marginTop: 0 }}>
            <div className="contract-form-set input-small-bot-margin">
              <div className="form-row-label">Task Name</div>
              <input
                className="contract-form-input"
                placeholder="Enter Task Name..."
                value={taskForm.name}
                name="name"
                onChange={handleChange}
              />
              {taskForm.name.length > 255 && (
                <p className="sale-form-error">* Max length is 255 characters...</p>
              )}
              {errors.name && (
                <p className="sale-form-error">* Please enter data...</p>
              )}
            </div>
            <div className="contract-form-set input-small-bot-margin">
              <div className="form-row-label">Date Assigned</div>
              <DatePicker
                className="contract-form-input"
                selected={taskForm.date_assigned}
                name="date_assigned"
                placeholderText={taskForm.date_assigned ? undefined : "MM/dd/yyyy"}
                onChange={(date) => {
                  setTaskForm({
                    ...taskForm,
                    date_assigned: date,
                  });
                  setErrors({
                    ...errors,
                    date_assigned: date === null,
                  })
                }}
                dateFormat="MM/dd/yyyy"
                autoComplete="off"
              />
              {errors.date_assigned && (
                <p className="sale-form-error" style={{ right: '4%' }}>
                  * Please enter data...
                </p>
              )}
            </div>

            <div className="contract-form-set input-small-bot-margin">
              <div className="form-row-label">Date Due</div>
              <DatePicker
                className="contract-form-input"
                selected={taskForm.date_due}
                name="date_due"
                placeholderText={taskForm.date_due ? undefined : "MM/dd/yyyy"}
                style={{ width:'100%' }}
                onChange={(date) => {
                  setTaskForm({
                    ...taskForm,
                    date_due: date,
                  });
                  setErrors({
                    ...errors,
                    date_due: date === null,
                  })
                }}
                dateFormat="MM/dd/yyyy"
                autoComplete="off"
              />
              {errors.date_due && (
                <p className="sale-form-error" style={{ left: '89px' }}>
                  * Please enter data...
                </p>
              )}
            </div>
            <div className="contract-form-set-full input-small-bot-margin">
              <div className="form-row-label">Task Description</div>
              <textarea
                className="contract-form-input"
                style={{ width: "100%", maxWidth: "100%", minWidth: "100%" }}
                placeholder="Enter Task Description..."
                value={taskForm.description}
                name="description"
                onChange={handleChange}
                rows={3}
              />
              {taskForm.description.length > 255 && (
                <p className="sale-form-error">* Max length is 255 characters...</p>
              )}
              {errors.description && (
                <p className="sale-form-error">* Please enter data...</p>
              )}
            </div>


            <div className="contract-form-set-half input-small-bot-margin">
              <div className="form-row-label">Personnel Assigned</div>
              <select
                className="contract-form-input"
                placeholder="Select Personnel Assigned..."
                value={taskForm.personnel_assigned_id}
                name="personnel_assigned_id"
                onChange={handleChange}
                style={{ color: !taskForm.personnel_assigned_id ? '#b5b5b5' : '#171717'}}
              >
                {renderFetchedOptions('Personnel')}
              </select>
              {errors.personnel_assigned_id && (
                <p className="sale-form-error">* Please enter data...</p>
              )}
            </div>

            <div className="contract-form-set-half "/>

            <div className="file-row half-width">
              <Checkbox
                checked={taskForm.file_active}
                sx={{
                  color: "#EFEFEF",
                  padding: 0,
                  paddingRight: "10px",
                  "&.Mui-checked": {
                    color: "#531CB3",
                  },
                  "&.Mui-disabled": {
                    color: "#EFEFEF40",
                  },
                }}
                onClick={() => setTaskForm({
                  ...taskForm,
                  file_active: !taskForm.file_active,
                })}
              />
              <div className="active-label">Create New File Folder</div>
            </div>
            
          </div>
        <div
          style={{ margin: 0 }}
          className="contractsTags__tags__modal-buttonContainer"
        >
          {createTaskLoading && (
            <div
              className="contractsTags__tags__modal-loading"
              style={{ top: '-35px', right: '140px' }}
            >
              <ProgressSpinnerDarkNavy/>
            </div>
          )}
          {createTaskError.length > 0 && (
            <p className="contractsTags__tags__modal-error--add">{createTaskError}</p>
          )}
          <HighlightNavyAutoStyledButton
            onClick={handleCreate}
            disabled={createTaskLoading}
            style={{
              width: '115px',
              border: '1px solid #141A66',
              fontSize: '18px'
            }}
            sx={{
              "&:hover": {
                backgroundColor: "#EFEFEF !important",
                border: "1px solid #141A66",
                color: "#141A66",
              },
            }}
          >
            Create
          </HighlightNavyAutoStyledButton>
        </div>
      </div>
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={errors.server_error
            ? 'Something went wrong, try again later...'
            : 'New Task Successfully Created'
          }
        />
      )}
    </>
  );
}
