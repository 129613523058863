import React, { useState, useEffect } from 'react';
import ImageIconPhone from 'assets/phone.png';
import ImageIconUser from 'assets/executiveUser.png';
import ImageIconEmail from 'assets/email.png';
import ImageIconBuilding from 'assets/building-icon.svg';
import { formatPhoneNumber } from 'shared/formatting';

const findPrimaryConnection = (connections) => 
  connections.find(conn => conn.is_primary) || null;

export const FilePanel = ({ connection }) => {

  const [personalPrimaryConnection, setPersonalPrimaryConnection] = useState(null);
  const [companyPrimaryConnection, setCompanyPrimaryConnection] = useState(null);

  useEffect(() => {
    const primaryFinder = connection.connection_type === 'company' || connection.connection_type === 'competitor'
      ? setPersonalPrimaryConnection 
      : setCompanyPrimaryConnection;


    switch (connection.connection_type) {
      case 'personal':
        primaryFinder(findPrimaryConnection(connection.company_connections));
        break;
      default:
        primaryFinder(findPrimaryConnection(connection.personal_connections));
        break;
    }
  }, [connection]);
  
  const isCompanyConnection = connection.connection_type === 'company' || connection.connection_type === 'competitor';

  const primaryConnectionData = isCompanyConnection ? personalPrimaryConnection : companyPrimaryConnection;
  const Icon = isCompanyConnection ? ImageIconUser : ImageIconBuilding;

  return (
    <div className="user-info-container">
      <div className="user-info-header">
        <div>
          <div className="user-info-name">
            {connection?.full_name || "N/A"}
          </div>
          <div className="user-info-small-header">
            {connection?.connection_type}
          </div>
        </div>
      </div>
      <div className="row">
        <img className="icon" src={Icon} alt=""/>
        <div className="section-data">
          {primaryConnectionData?.full_name || "N/A"}
        </div>
      </div>
      <div className="row">
        <img className="icon" src={ImageIconPhone} alt="" />
        <div className="section-data">
          {primaryConnectionData?.phone_number ? formatPhoneNumber(primaryConnectionData.phone_number) : "No phone number"}
        </div>
      </div>
      <div className="row">
        <img className="icon" src={ImageIconEmail} alt="" />
        <div
          className="section-data"
          style={{ maxWidth: 'calc(100% - 42px)', wordWrap: 'break-word' }}
        >
          {primaryConnectionData?.email || "No email address"}
        </div>
      </div>
    </div>
  );
};
