import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'css/sales.scss';
import API from 'API';
import Toast from 'components/toast/toast';
import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';
import { formatUTCDate } from 'shared/formatting';
import { useGetSalesByFilter } from 'hooks/getSalesByFilter';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setIsLoadedAnalytics } from 'store/sales/slice';
import {
  bidTypesLoadingSelector, bidTypesSelector,
  salesDivisionsLoadingSelector,
  salesDivisionsSelector,
  salesRepsLoadingSelector,
  salesRepsSelector,
} from 'store/sales/selectors';

const initialInfo = {
  opportunity_name: '',
  project_name: '',
  division_id: '',
  bid_type: '',
  stage: '',
  description: '',
  expected_bid: '',
  anticipated_award: '',
  expected_start: '',
  value: '',
  probability_of_deal: '',
  total_contract_value: 0,
  suspense_date: '',
  submission_deadline: '',
  contract_award_date: '',
  anticipated_contract_start: '',
  sales_rep_id: '',

  company_name: '',
  agency: '',
  sam_gov_number: '',
  gov_win_number: '',
  solicitation_number: '',
  incumbent: '',
  place_of_performance: '',
  base_period_of_performance: '',
  option_periods: '',
  prime_bidder: '',
  anticipated_rfp_date: '',

  sale_type: '',
};

const initialErrors = {
  opportunity_name: false,
  project_name: false,
  division_id: false,
  bid_type: false,
  stage: false,
  description: false,
  expected_bid: false,
  anticipated_award: false,
  expected_start: false,
  value: false,
  probability_of_deal: false,
  total_contract_value: false,
  suspense_date: false,
  submission_deadline: false,
  contract_award_date: false,
  anticipated_contract_start: false,
  sales_rep_id: false,

  company_name: false,
  agency: false,
  sam_gov_number: false,
  gov_win_number: false,
  solicitation_number: false,
  incumbent: false,
  place_of_performance: false,
  base_period_of_performance: false,
  option_periods: false,
  prime_bidder: false,
  anticipated_rfp_date: false,

  server_error: false,

  sale_type: false,
};

export const EditSaleModal = ({ sale }) => {
  const token = JSON.parse(localStorage.getItem('token'));
  const getSales = useGetSalesByFilter();
  const dispatch = useAppDispatch();
  const salesUsers = useAppSelector(salesRepsSelector);

  const setInitialInfo = () => {
    if (sale) {
      return {
        opportunity_name: sale.opportunity_name,
        project_name: sale.project_name,
        division_id: sale.division_id,
        bid_type_id: sale.bid_type == null ? null : sale.bid_type.id,
        stage: sale.stage,
        description: sale.description,
        expected_bid: sale.expected_bid,
        anticipated_award: sale.anticipated_award,
        expected_start: sale.expected_start,
        value: sale.value,
        probability_of_deal: sale.probability_of_deal,
        total_contract_value: sale.total_contract_value,
        suspense_date: sale.suspense_date,
        submission_deadline: sale.submission_deadline,
        contract_award_date: sale.contract_award_date,
        anticipated_contract_start: sale.anticipated_contract_start,
        sales_rep_id: sale.sales_rep_id,

        company_name: sale.company_name,
        agency: sale.agency,
        sam_gov_number: sale.sam_gov_number,
        gov_win_number: sale.gov_win_number,
        solicitation_number: sale.solicitation_number,
        incumbent: sale.incumbent,
        place_of_performance: sale.place_of_performance,
        base_period_of_performance: sale.base_period_of_performance,
        option_periods: sale.option_periods,
        prime_bidder: sale.prime_bidder,
        anticipated_rfp_date: sale.anticipated_rfp_date,

        sale_type: sale.sale_type,
      };
    }

    return initialInfo;
  };

  const [information, setInformation] = useState(setInitialInfo());
  const [errors, setErrors] = useState(initialErrors);
  const [openToast, setOpenToast] = useState(false);
  const [personalConnections, setPersonalConnections] = useState([]);
  const [companyConnections, setCompanyConnections] = useState([]);
  const [personalPrimaryConnection, setPersonalPrimaryConnection] = useState('');
  const [companyPrimaryConnection, setCompanyPrimaryConnection] = useState('');
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [saleName, setSaleName] = useState(sale.opportunity_name);

  const divisions = useAppSelector(salesDivisionsSelector);
  const bidTypes = useAppSelector(bidTypesSelector);
  const loadingBidTypes = useAppSelector(bidTypesLoadingSelector);
  const loadingDivisions = useAppSelector(salesDivisionsLoadingSelector);
  const loadingSalesUsers = useAppSelector(salesRepsLoadingSelector);

  useEffect(() => {
    setInformation(setInitialInfo());
    if (sale) {
      const pConnections = sale.connections?.filter((item) => item.connection_type === 'personal');
      const cConnections = sale.connections?.filter((item) => item.connection_type === 'company');
      setPersonalPrimaryConnection(pConnections?.find(item => item.is_primary === true)?.id);
      setCompanyPrimaryConnection(cConnections?.find(item => item.is_primary === true)?.id);
      setPersonalConnections(pConnections);
      setCompanyConnections(cConnections);
    }
  }, [sale]);

  const handleChange = (event) => {
    setInformation({
      ...information,
      [event.target.name]: event.target.value,
    });
    if (!event.target.value.length) {
      setErrors({
        ...errors,
        [event.target.name]: true,
      });
    } else {
      setErrors({
        ...errors,
        [event.target.name]: false,
      });
    }
  };

  const setPrimary = (id) => {
    fetch(`${API.endpointURL}/api/sales/${sale.id}/connections`, {
      method: 'POST',
      body: JSON.stringify({
        connection_id: parseInt(id),
        is_primary: true,
        stage: information.stage,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
      },
    })
      .then((res) => res.json());
  };

  const handleUpdate = () => {
    if (information.opportunity_name && information.stage && information.sale_type) {
      setLoadingUpdate(true);
      const saleForm = {
        sales_id: sale.id,
        opportunity_name: information.opportunity_name,
        project_name: information.project_name,
        bid_types_id: information.bid_type_id,
        stage: information.stage,
        description: information.description,
        expected_bid: information.expected_bid,
        anticipated_award: information.anticipated_award,
        expected_start: information.expected_start,
        value: information.value || 0,
        probability_of_deal: information.probability_of_deal,
        total_contract_value: information.total_contract_value,
        submission_deadline: information.submission_deadline,
        contract_award_date: information.contract_award_date,
        anticipated_contract_start: information.anticipated_contract_start,

        company_name: information.company_name,
        agency: information.agency,
        sam_gov_number: information.sam_gov_number,
        gov_win_number: information.gov_win_number,
        solicitation_number: information.solicitation_number,
        incumbent: information.incumbent,
        place_of_performance: information.place_of_performance,
        base_period_of_performance: information.base_period_of_performance,
        option_periods: information.option_periods,
        prime_bidder: information.prime_bidder,
        anticipated_rfp_date: information.anticipated_rfp_date,

        sale_type: information.sale_type,
      };

      if (information?.division_id) {
        saleForm.division_id = parseInt(information.division_id);
      }

      if (information?.sales_rep_id) {
        saleForm.sales_rep_id = parseInt(information.sales_rep_id);
      }

      try {
        let requestError = false;

        personalPrimaryConnection && setPrimary(personalPrimaryConnection);
        companyPrimaryConnection && setPrimary(companyPrimaryConnection);

        fetch(`${API.endpointURL}/api/sales/${sale.id}`, {
          method: 'PATCH',
          body: JSON.stringify(saleForm),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
        })
          .then((res) => {
            if (!res.ok) {
              requestError = true;
            }
            return res.json();
          })
          .then(() => {
            setOpenToast(true);
            if (requestError) {
              setErrors({
                ...errors,
                server_error: true,
              });
            } else {
              dispatch(setIsLoadedAnalytics(false));
              getSales();
              setSaleName(information.opportunity_name);
              setErrors({
                ...errors,
                server_error: false,
              });
            }
            setLoadingUpdate(false);
          });
      } catch (e) {
        setLoadingUpdate(false);
        setOpenToast(true);
        setErrors({
          ...errors,
          server_error: true,
        });
      }
    } else {
      setLoadingUpdate(false);
      setErrors({
        ...errors,
        opportunity_name: !information.opportunity_name,
        stage: !information.stage,
        sale_type: !information.sale_type,
      });
    }
  };

  const getColorForDate = () => {
    if (information.suspense_date) {
      const currentDate = new Date();
      const targetDate = new Date(information.suspense_date);

      // Set hours, minutes, seconds, and milliseconds to 0 to compare dates only
      currentDate.setHours(0, 0, 0, 0);
      targetDate.setHours(0, 0, 0, 0);

      const differenceInDays = Math.floor((targetDate - currentDate) / (24 * 60 * 60 * 1000));

      if (differenceInDays <= 0) {
        return 'red';
      } else if (differenceInDays <= 5) {
        return 'yellow';
      } else {
        return 'green';
      }
    }
    return '';
  };

  return (
    <section>
      <h1 className="content-header" style={{ color: '#141A66' }}>
        {saleName}
      </h1>
      <div className="content-container content-dark-color"
           style={{ border: 'none', height: '100%', overflow: 'hidden' }}>

        <>
          <div className="contract-form-section-header contract-form-section-header-dark"
               style={{ marginBottom: '10px', position: 'relative' }}>
            General Information
            <p className="sale-form-date sale-form-date-black">
              Suspense date:
              {' '}
              <span className={`sale-form-date-black sale-form-date-${getColorForDate()}`}>
              {information.suspense_date ? formatUTCDate(information.suspense_date) : 'N/A'}
            </span>
            </p>
          </div>
          <div className="sale-form-section-content">
            <div className="sale-form-set">
              <div className="form-row-label">Opportunity Name</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.opportunity_name || ''}
                name="opportunity_name"
                onChange={handleChange}
              />
              {errors.opportunity_name && <p className="sale-form-error">* Please enter data</p>}
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Project Name</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.project_name || ''}
                name="project_name"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Company name</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.company_name || ''}
                name="company_name"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Agency</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.agency || ''}
                name="agency"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Business Line</div>
              <select
                className="sale-form-input"
                placeholder="Select Business Line..."
                value={information.division_id || ''}
                name="division_id"
                onChange={handleChange}
                style={{
                  color: !information.division_id ? '#B5B5B5' : '#171717',
                  padding: '7px 12px',
                }}
              >
                {loadingDivisions
                  ? <option value="">Loading Business Line...</option>
                  : (
                    <>
                      <option value="">Select Business Line...</option>
                      {divisions.map((division) => (
                        <option key={division.id} value={division.id}>
                          {division.name}
                        </option>
                      ))}
                    </>
                  )
                }
              </select>
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Bid Type</div>
              <select
                className="sale-form-input"
                placeholder="Select Bid Type..."
                value={information.bid_type_id || ''}
                name="bid_type_id"
                onChange={handleChange}
                style={{
                  color: !information.bid_type_id ? '#B5B5B5' : '#171717',
                  padding: '7px 12px',
                }}
              >
                {loadingBidTypes
                  ? <option value="">Loading Bid Type...</option>
                  : (
                    <>
                      <option value="">Select Bid Type...</option>
                      {bidTypes.map((bidType) => (
                        <option key={bidType.id} value={bidType.id}>
                          {bidType.name}
                        </option>
                      ))}
                    </>
                  )
                }
              </select>
            </div>
          </div>

          <div className="contract-form-section-header contract-form-section-header-dark"
               style={{ marginBottom: '10px' }}>
            Sales Table
          </div>

          <div className="sale-form-section-content">
            <div className="sale-form-set">
              <div className="form-row-label">Stage</div>
              <select
                className="sale-form-input"
                placeholder="Enter Sales Stage..."
                value={information.stage || ''}
                name="stage"
                onChange={handleChange}
                style={{ color: !information.stage ? '#B5B5B5' : '#171717' }}
              >
                <option value="">Select Sales Stage...</option>
                <option value="Business Development">Business Development</option>
                <option value="Lead">Capturing</option>
                <option value="Opportunity">In Work</option>
                <option value="Contract">Submitted</option>
                <option value="Award">Awarded To</option>
                <option value="Archive">Archive</option>
              </select>
              {errors.stage && <p className="sale-form-error">* Please enter data</p>}
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Sales Type</div>
              <select
                className="sale-form-input"
                placeholder="Enter Sales Type..."
                value={information.sale_type || ''}
                name="sale_type"
                onChange={handleChange}
                style={{ color: !information.sale_type ? '#B5B5B5' : '#171717' }}
              >
                <option value="">Select Sales Type...</option>
                <option value="direct">Direct</option>
                <option value="rental">Rental</option>
              </select>
              {errors.sale_type && <p className="sale-form-error">* Please enter data</p>}
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Sam.gov Number</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.sam_gov_number || ''}
                name="sam_gov_number"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">GovWin Number</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.gov_win_number || ''}
                name="gov_win_number"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Solicitation Number</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.solicitation_number || ''}
                name="solicitation_number"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Total Contract Value</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                type="number"
                min="0"
                value={information.total_contract_value || ''}
                name="total_contract_value"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Incumbent</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.incumbent || ''}
                name="incumbent"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Place Of Performance</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.place_of_performance || ''}
                name="place_of_performance"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Base Period Of Performance</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.base_period_of_performance || ''}
                name="base_period_of_performance"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Option periods</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.option_periods || ''}
                name="option_periods"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Prime Bidder</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.prime_bidder || ''}
                name="prime_bidder"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Anticipated RFP Date</div>
              <DatePicker
                className="sale-form-input"
                selected={
                  information.anticipated_rfp_date
                    ? new Date(information.anticipated_rfp_date.slice(0, 10))
                    : null
                }
                placeholderText={information.anticipated_rfp_date ? undefined : 'N/A'}
                onChange={(date) => {
                  const dd = String(date.getDate()).padStart(2, '0');
                  const mm = String(date.getMonth() + 1).padStart(2, '0');
                  const yyyy = date.getFullYear();
                  const dateString = yyyy + '-' + mm + '-' + dd;
                  setInformation({
                    ...information,
                    anticipated_rfp_date: dateString,
                  });
                }}
                dateFormat="MM/dd/yyyy"
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Submission Deadline</div>
              <DatePicker
                className="sale-form-input"
                selected={
                  information.submission_deadline
                    ? new Date(information.submission_deadline.slice(0, 10))
                    : null
                }
                placeholderText={information.submission_deadline ? undefined : 'N/A'}
                onChange={(date) => {
                  const dd = String(date.getDate()).padStart(2, '0');
                  const mm = String(date.getMonth() + 1).padStart(2, '0');
                  const yyyy = date.getFullYear();
                  const dateString = yyyy + '-' + mm + '-' + dd;
                  setInformation({
                    ...information,
                    submission_deadline: dateString,
                  });
                }}
                dateFormat="MM/dd/yyyy"
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Anticipated Award</div>
              <DatePicker
                className="sale-form-input"
                selected={
                  information.anticipated_award
                    ? new Date(information.anticipated_award.slice(0, 10))
                    : null
                }
                placeholderText={information.anticipated_award ? undefined : 'N/A'}
                onChange={(date) => {
                  const dd = String(date.getDate()).padStart(2, '0');
                  const mm = String(date.getMonth() + 1).padStart(2, '0');
                  const yyyy = date.getFullYear();
                  const dateString = yyyy + '-' + mm + '-' + dd;
                  setInformation({
                    ...information,
                    anticipated_award: dateString,
                  });
                }}
                dateFormat="MM/dd/yyyy"
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Anticipated Contract Start</div>
              <DatePicker
                className="sale-form-input"
                selected={
                  information.anticipated_contract_start
                    ? new Date(information.anticipated_contract_start.slice(0, 10))
                    : null
                }
                placeholderText={information.anticipated_contract_start ? undefined : 'N/A'}
                onChange={(date) => {
                  const dd = String(date.getDate()).padStart(2, '0');
                  const mm = String(date.getMonth() + 1).padStart(2, '0');
                  const yyyy = date.getFullYear();
                  const dateString = yyyy + '-' + mm + '-' + dd;
                  setInformation({
                    ...information,
                    anticipated_contract_start: dateString,
                  });
                }}
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>

          <div className="contract-form-section-header contract-form-section-header-dark"
               style={{ marginBottom: '10px' }}>
            Management
          </div>

          <div className="sale-form-section-content">
            <div className="sale-form-set">
              <div className="form-row-label">Description</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                value={information.description || ''}
                name="description"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Sales Rep</div>
              <select
                className="sale-form-input"
                placeholder="Select Sales Rep..."
                value={information.sales_rep_id || ''}
                name="sales_rep_id"
                onChange={handleChange}
                style={{ color: !information.sales_rep_id ? '#B5B5B5' : '#171717' }}
              >
                {
                  loadingSalesUsers
                    ? (<option value="">Loading Sales Rep...</option>)
                    : (
                      <>
                        <option value="">Select Sales Rep...</option>
                        {salesUsers.map((user) => (
                          <option key={user.id} value={user.id}>
                            {`${user.first_name} ${user.last_name}`}
                          </option>
                        ))}
                      </>
                    )
                }
              </select>
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Probability of Deal</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                type="number"
                min="0"
                value={information.probability_of_deal || ''}
                name="probability_of_deal"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Primary Point of Contact</div>
              <select
                className="sale-form-input"
                placeholder="Select Primary Connection..."
                value={personalPrimaryConnection}
                name="primary_connection_id"
                onChange={(e) => setPersonalPrimaryConnection(e.target.value)}
                style={{ color: !personalPrimaryConnection ? '#B5B5B5' : '#171717' }}
              >
                <option value="">Select Primary Connection...</option>
                {personalConnections?.map((connection) => (
                  <option key={connection.id} value={connection.id}>
                    {`${connection.full_name}`}
                  </option>
                ))}
              </select>
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Primary Company on the Sale</div>
              <select
                className="sale-form-input"
                placeholder="Select Primary Connection..."
                value={companyPrimaryConnection}
                name="primary_connection_id"
                onChange={(e) => setCompanyPrimaryConnection(e.target.value)}
                style={{ color: !companyPrimaryConnection ? '#B5B5B5' : '#171717' }}
              >
                <option value="">Select Primary Connection...</option>
                {companyConnections?.map((connection) => (
                  <option key={connection.id} value={connection.id}>
                    {`${connection.full_name}`}
                  </option>
                ))}
              </select>
            </div>

            <div className="sale-form-set">
              <div className="form-row-label form-row-label">Expected Bid</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                type="number"
                min="0"
                value={information.expected_bid || ''}
                name="expected_bid"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Expected Start Date</div>
              <DatePicker
                className="sale-form-input"
                selected={
                  information.expected_start
                    ? new Date(information.expected_start.slice(0, 10))
                    : null
                }
                placeholderText={information.expected_start ? undefined : 'N/A'}
                onChange={(date) => {
                  const dd = String(date.getDate()).padStart(2, '0');
                  const mm = String(date.getMonth() + 1).padStart(2, '0');
                  const yyyy = date.getFullYear();
                  const dateString = yyyy + '-' + mm + '-' + dd;
                  setInformation({
                    ...information,
                    expected_start: dateString,
                  });
                }}
                dateFormat="MM/dd/yyyy"
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Contract Award Date</div>
              <DatePicker
                className="sale-form-input"
                selected={
                  information.contract_award_date
                    ? new Date(information.contract_award_date.slice(0, 10))
                    : null
                }
                placeholderText={information.contract_award_date ? undefined : 'N/A'}
                onChange={(date) => {
                  const dd = String(date.getDate()).padStart(2, '0');
                  const mm = String(date.getMonth() + 1).padStart(2, '0');
                  const yyyy = date.getFullYear();
                  const dateString = yyyy + '-' + mm + '-' + dd;
                  setInformation({
                    ...information,
                    contract_award_date: dateString,
                  });
                }}
                dateFormat="MM/dd/yyyy"
              />
            </div>

            <div className="sale-form-set">
              <div className="form-row-label">Value to Company</div>
              <input
                className="sale-form-input"
                placeholder="N/A"
                type="number"
                min="0"
                value={information.value || ''}
                name="value"
                onChange={handleChange}
              />
            </div>

            <div className="sale-form-section-content">
              <div className="sale-form-button-container">
                {loadingUpdate && (
                  <div className="sale-form-spinner sale-form-spinner-edit">
                    <ProgressSpinnerDarkNavy/>
                  </div>
                )}
                <button
                  className="create-button"
                  onClick={handleUpdate}
                  disabled={loadingUpdate || errors.opportunity_name || errors.stage || errors.sale_type}
                >
                  Update
                </button>
                {(errors.opportunity_name || errors.stage || errors.sale_type)
                  && <p className="sale-form-error" style={{ top: '-14px' }}>* Please enter valid data...</p>}
              </div>
            </div>
          </div>
        </>
      </div>

      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={errors.server_error
            ? 'Something went wrong, try again later...'
            : 'Sale information successfully updated'
          }
        />
      )}
    </section>
  );
};
