import React, { useEffect, useState } from 'react';

import CustomDialogRedesign from 'components/modal/custom-dialog-redesign';
import ProgressSpinner from 'components/progress/progress-spinner';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  deleteGroupErrorSelector,
  deleteGroupStatusSelector,
  deleteLoadingSelector,
  deleteTypeErrorSelector,
  deleteTypeStatusSelector,
  tagsSelector,
} from 'store/tags/selectors';
import { deleteGroup, deleteType, getTagGroups, getTags, getTagTypes } from 'store/tags/thunk';
import { setDeleteGroupStatus, setDeleteTypeStatus } from 'store/tags/slice';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

export const ContractTagsDelete = ({
    deleteTagOptionOpenModal,
    setDeleteTagOptionOpenModal,
    type,
    selected,
    setSelected,
    clear,
    setMessage,
    setOpenToast,
  }) => {
  const dispatch = useAppDispatch();
  
  const allTags = useAppSelector(tagsSelector);
  
  const deleteLoading = useAppSelector(deleteLoadingSelector);
  const deleteTypeStatus = useAppSelector(deleteTypeStatusSelector);
  const deleteGroupStatus = useAppSelector(deleteGroupStatusSelector);
  const deleteTypeError = useAppSelector(deleteTypeErrorSelector);
  const deleteGroupError = useAppSelector(deleteGroupErrorSelector);
  
  const [error, setError] = useState('');
  const { pathname } = useLocation();
  const handleCloseModal = () => {
    setDeleteTagOptionOpenModal(false);
  };
  
  useEffect(() => {
    dispatch(getTags());
  }, []);
  
  useEffect(() => {
    if (type) {
      if (deleteTypeStatus === 'fulfilled') {
        dispatch(setDeleteTypeStatus(''));
        handleCloseModal();
        clear();
        setSelected({});
        dispatch(getTagTypes());
        setMessage('Tag Type successfully deleted.');
        setOpenToast(true);
      }
    } else {
      if (deleteGroupStatus === 'fulfilled') {
        dispatch(setDeleteGroupStatus(''));
        handleCloseModal();
        clear();
        setSelected({});
        dispatch(getTagGroups());
        setMessage('Tag Group successfully deleted.');
        setOpenToast(true);
      }
    }
  }, [deleteTypeStatus, deleteGroupStatus]);
  
  const handleDelete = () => {
    if (type) {
      const connectedTags = allTags?.filter((tag) => tag.type.title === selected.title);
      if (connectedTags.length === 0) {
        dispatch(deleteType({ id: selected?.id }));
      } else {
        setError('This tag type is associated with an existing tag.');
      }
    } else {
      const connectedTags = allTags?.filter((tag) => tag.group.title === selected.title);
      if (connectedTags.length === 0) {
        dispatch(deleteGroup({ id: selected?.id }));
      } else {
        setError('This tag group is associated with an existing tag.');
      }
    }
  };
  
  const removeTagOption = () => {
    return (
      <>
        <div
          style={{
            fontFamily: 'Korolev Medium',
            lineHeight: '25px',
            marginTop: '30px',
            textAlign: 'center',
          }}
        >
          This will permanently remove {' '}
          <b style={{ color: pathname.includes('applicant') ? '#00A5CF' : '#62AB37' }}>
            {selected?.title}
          </b>{' '}
          from the {type ? 'types' : 'groups'} list.
          <br/>
          Are you sure you want to remove this {type ? 'type' : 'group'}?
        </div>
        {type && deleteTypeError.length > 0 && <div className="add-user-error">{deleteTypeError}</div>}
        {deleteGroupError.length > 0 && <div className="add-user-error">{deleteGroupError}</div>}
        {error.length > 0 && <div className="add-user-error">{error}</div>}
        <div className="contractsTags__tags__modal-buttonContainer">
          {deleteLoading && (
            <div className="contractsTags__tags__modal-loading">
              <ProgressSpinner/>
            </div>
          )}
          <button
            className={classNames('contractsTags__tags__modal-button', {
              'contractsTags__tags__modal-button-blue': pathname.includes('applicant'),
            })}
            onClick={() => handleDelete()}
            disabled={deleteLoading}
            style={{ fontSize: '0.875rem' }}
          >
            Remove
          </button>
        </div>
      </>
    );
  };
  
  return (
    <CustomDialogRedesign
      colorPalette={pathname.includes('applicant') ? 'blue' : 'green'}
      dialogContent={removeTagOption()}
      onClose={handleCloseModal}
      open={deleteTagOptionOpenModal}
      title={'DELETE'}
      titleHighlight={`TAG ${type ? 'type' : 'group'}`}
    />
  );
};
