import React, { useState } from 'react';
import { Box } from '@mui/material';

import Toast from 'components/toast/toast';
import { useAppSelector } from 'hooks/reduxHooks';
import { contractTemplatesSelector, loadingTemplatesSelector } from 'store/contracts/selectors';
import ProgressSpinner from 'components/progress/progress-spinner';

export default function HiredAutomation(props) {
  const { setButton } = props;

  const allTemplates = useAppSelector(contractTemplatesSelector);
  const loadingTemplates = useAppSelector(loadingTemplatesSelector);
  const [openErrorToast, setOpenErrorToast] = useState(false);

  const templates = allTemplates.filter(item => item?.template_type.includes('hired'));

  return (
    <React.Fragment>
      {loadingTemplates ? (
        <div className="contracts-loading-spinner">
          <ProgressSpinner />
        </div>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          gap="5px"
          justifyContent="space-between"
          marginBottom="5px"
        >
          {templates[0]?.active === true &&
            setButton(
              0,
              'hired_button_1',
              'hired_button_1_confirm',
              setOpenErrorToast,
            )}
          {templates[1]?.active === true &&
            setButton(
              1,
              'hired_button_2',
              'hired_button_2_confirm',
              setOpenErrorToast,
            )}
          {templates[2]?.active === true &&
            setButton(
              2,
              'hired_button_3',
              'hired_button_3_confirm',
              setOpenErrorToast,
            )}
          {templates[3]?.active === true &&
            setButton(
              3,
              'hired_button_4',
              'hired_button_4_confirm',
              setOpenErrorToast,
            )}
          {templates[4]?.active === true &&
            setButton(
              4,
              'hired_button_5',
              'hired_button_5_confirm',
              setOpenErrorToast,
            )}
        </Box>
      )}
      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message="There was an issue sending your email. Please try again later."
        />
      )}
    </React.Fragment>
  );
}
