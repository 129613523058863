import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { IconButton, Tooltip } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import '../css/recruitment-flow.scss';

import API from 'API';

import AppBar from '../components/app-bar/updated-app-bar-component';
import StaffingSidebar from '../components/sidebar/staffing-sidebar';
import StaffingApplicantTable from '../components/table/staffing-applicant-table';
import StaffingTabs from '../components/tabs/staffing-tabs';
import StaffingProfile from '../components/profile/staffing-profile';
import ProgressSpinner from 'components/progress/progress-spinner';
import { StyledTableContainer } from 'components/table/table-styles';

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import { getContractFilters, getContracts } from 'store/contracts/thunk';
import { getRecruiters } from 'store/recruiters/thunk';
import { getTags } from 'store/tags/thunk';
import { getApplicant } from 'store/applicants/thunk';
import {
  applicantsByStageSelector,
  applicantsLoadingSelector,
  currentApplicantSelector, editedApplicantIdSelector,
} from 'store/applicants/selectors';
import { setCurrentApplicant } from 'store/applicants/slice';
import { sidebarCollapseSelector } from 'store/ui/selectors';
import { setSidebarCollapse } from 'store/ui/slice';
import { useSelectedOptions } from 'hooks/selectedOptions';
import { useGetApplicantsByStage } from 'hooks/getApplicantsByStage';
import { isSearchedApplicantsSelector } from 'store/staffingSelectedOptions/selectors';
import { useGetSearchedApplicants } from 'hooks/getSearchedApplicants';
import { currentPageSelector } from 'store/contracts/selectors';

export default function Staffing() {
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const token = JSON.parse(localStorage.getItem('token'));
  const selectedOptions = useSelectedOptions();
  const getApplicants = useGetApplicantsByStage();
  const getSearchedApplicants = useGetSearchedApplicants();

  const dispatch = useAppDispatch();
  const sidebarCollapse = useAppSelector(sidebarCollapseSelector);
  const currentApplicant = useAppSelector(currentApplicantSelector);
  const editedApplicantId = useAppSelector(editedApplicantIdSelector);
  const applicants = useAppSelector(applicantsByStageSelector);
  const loading = useAppSelector(applicantsLoadingSelector);
  const currentPage = useAppSelector(currentPageSelector);
  
  const isSearchedApplicants = useAppSelector(isSearchedApplicantsSelector);

  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isSearchedApplicants) {
      getApplicants();
    } else {
      getSearchedApplicants();
    }
  }, [...selectedOptions]);

  useEffect(() => {
    dispatch(getContracts({ currentPage }));
    dispatch(getContractFilters());
    dispatch(getRecruiters());
    dispatch(getTags());
  }, []);

  useEffect(() => {
    if (applicants.length > 0 && !currentApplicant) {
      dispatch(setCurrentApplicant(applicants[0]));
    }
  }, []);

  useEffect(() => {
    if (applicants?.length > 0 && currentApplicant) {
      dispatch(getApplicant({ applicantId: currentApplicant?.id }));
    }
  }, [currentApplicant?.id]);

  const updateApplicant = async (field, newValue) => {
    await fetch(
      `${API.endpointURL}/api/applicants/${currentApplicant.application.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          [field]: newValue,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json; charset=UTF-8',
          Accept: 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        if (data['errors']) {
          setError(data['errors']);
        } else {
          dispatch(getApplicant({ applicantId: currentApplicant.id }));
        }
      })
      .catch((err) => {
        setError(err.messages);
      });
  };

  return (
    <>
      <AppBar colorPalette="green" menu={true} title="Staffing" />
      <div className="staffing-page">
        <div
          className={classNames('staffing__sidebar', {
            'staffing__sidebar--collapsed': sidebarCollapse,
          })}
        >
          <Tooltip title={sidebarCollapse ? 'Show sidebar' : 'Hide sidebar'}>
            <IconButton
              onClick={() => dispatch(setSidebarCollapse(!sidebarCollapse))}
              sx={{
                padding: 0,
                transition: 'all 0.3s',
                transform: sidebarCollapse ? 'rotate(180deg)' : '',
                position: 'absolute',
                top: '21px',
                right: sidebarCollapse ? '-20px' : '20px',
                zIndex: '2',
                width: '24px',
                height: '24px',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <KeyboardDoubleArrowLeftIcon
                sx={{
                  color: '#EFEFEF',
                  width: '40px',
                  height: '40px',
                }}
              />
            </IconButton>
          </Tooltip>

          <StaffingSidebar getApplicantsByStage={getApplicants} />
        </div>
        <div
          className={classNames('staffing__content', {
            'staffing__content--collapsed': sidebarCollapse,
          })}
        >
          <StaffingTabs />
          {loading && !editedApplicantId ? (
            <StyledTableContainer
              sx={{
                height: 'calc(100vh - 234px)',
                border: '1px solid #EFEFEF80',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="scroll"
            >
              <ProgressSpinner />
            </StyledTableContainer>
          ) : (
            <div
              className={
                classNames('staffing__container', {
                  'staffing__container--collapsed': sidebarCollapse,
                })
              }
            >
              {applicants?.length > 0 ? (
                <>
                  <StaffingApplicantTable />
                  <StaffingProfile
                    updateApplicant={updateApplicant}
                    currentUser={loggedInUser}
                  />
                </>
              ) : (
                <StyledTableContainer
                  sx={{
                    height: 'calc(100vh - 234px)',
                    border: '1px solid #EFEFEF80',
                    borderRadius: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  className="scroll"
                >
                  <p>No Applicants for this stage!</p>
                </StyledTableContainer>
              )}

            </div>
          )}
        </div>
      </div>
    </>
  );
}
