import React, { useEffect, useRef, useState } from "react";
import "../../css/vendor-notes.scss";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Box, IconButton, TextField, Typography } from "@mui/material";

import API from "API";
import {ProgressSpinnerBlue} from "../progress/progress-spinner-blue";

export default function VendorNotes(props) {
  const { vendor } = props;
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const token = JSON.parse(localStorage.getItem("token"));

  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [loadNotes, setLoadNotes] = useState(false);

  const notesEndRef = useRef(null);

  useEffect(() => {
    if (vendor) {
      retrieveNotes(vendor.id);
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [notes]);

  const retrieveNotes = (id) => {
    setLoadNotes(true);
    fetch(`${API.endpointURL}/api/vendor_notes/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.data.length > notes.length) {
          setNotes(json.data);
        }
        setLoadNotes(false);
      })
      .catch(() => setLoadNotes(false))
  };

  const handleSubmitNote = async () => {
    await fetch(`${API.endpointURL}/api/notes/`, {
      method: "POST",
      body: JSON.stringify({
        vendor_id: vendor.id,
        body: note.length > 0 ? note : "This is a test note",
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((response) => {
      response.json();
      setNote("");
      retrieveNotes(vendor.id);
    });
  };

  const scrollToBottom = () => {
    notesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleChangeText = (newValue) => {
    setNote(newValue);
  };

  const formatDateTime = (timestamp) => {
    const dateTime = new Date(timestamp);
    return dateTime.toLocaleString();
  };

  const keyPress = (e) => {
    // Submits text on enter
    if (e.keyCode === 13) {
      handleSubmitNote();
    }
  };

  const buildComms = (id) => {
    let textBubbles = [];
    notes.forEach((message, index) => {
      let bubble = (
        <Box
          key={index}
          display="flex"
          flexDirection="column"
          sx={{
            marginBottom: "8px",
            textAlign: "left",
            width: "auto",
          }}
        >
          <Typography
            sx={{
              color: "#fcfcfc",
              fontSize: "13px",
              marginBottom: "2px",
              paddingLeft: "15px",
              textTransform: "capitalize",
            }}
          >
            {message.admin_id && message.admin_id === loggedInUser.id && "You"}
            {message.admin_id &&
              message.admin_id !== loggedInUser.id &&
              `${message.admin_first_name} ${message.admin_last_name}`}
          </Typography>
          <Typography
            sx={{
              bgcolor: "#EFEFEF",
              border: 0,
              fontSize: "16px",
              borderRadius: "6px",
              fontFamily: "Korolev Medium",
              color: "black",
              padding: "8px 13px",
              overflowWrap: "break-word",
            }}
          >
            {message.body}
          </Typography>
          <Typography
            sx={{
              color: "#fcfcfc",
              fontSize: "13px",
              marginTop: "2px",
              paddingRight: "15px",
              textAlign: "right",
            }}
          >
            {formatDateTime(message.created_at)}
          </Typography>
        </Box>
      );
      textBubbles.push(bubble);
    });

    return (
      <Box
        style={{
          boxShadow: 0,
          maxHeight: "450px",
          overflowY: "scroll",
        }}
      >
        {textBubbles}
        <div ref={notesEndRef} />
      </Box>
    );
  };

  if (loadNotes && !notes.length) {
    return (
      <>
        <h1 className="content-header">Notes</h1>
        <div className="content-container">
          <div className="spinner-fullHeight">
            <ProgressSpinnerBlue />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <h1 className="content-header">Notes</h1>
      <div className="content-container">
        {notes.length > 0 && buildComms()}

        <div className="note-field">
          <Box
            sx={{
              backgroundColor: "#EFEFEF",
              border: "1px solid #7a7a7a",
              borderRadius: "8px",
              width: "auto",
              height: "auto",
            }}
          >
            <TextField
              value={note}
              onChange={(e) => handleChangeText(e.currentTarget.value)}
              onKeyDown={keyPress}
              fullWidth
              sx={{ border: "0", padding: "6px 10px 5px" }}
              placeholder="Add Note..."
              id="fullWidth"
              multiline={true}
              rows={2}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: "5px 10px 10px",
              }}
            >
              <IconButton
                onClick={() => handleSubmitNote()}
                disabled={!note}
                sx={{
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ArrowCircleUpIcon
                  sx={{
                    color: "#7a7a7a",
                    "&:hover": {
                      color: "#3e453d",
                    },
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}
