import { createAsyncThunk } from '@reduxjs/toolkit';
import { files } from 'services/files';
export const getApplicantFiles = createAsyncThunk(
  'applicantFiles',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await files.getApplicantFiles(id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const approveFiles = createAsyncThunk(
  'approveFiles',
  async ({ id, type }, { rejectWithValue }) => {
    try {
      return await files.approveFiles(id, type);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const approveAllFiles = createAsyncThunk(
  'approveAllFiles',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await files.approveAllFiles(id);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const uploadFile = createAsyncThunk(
  'uploadFile',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await files.uploadFile(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);