import React from "react";

import "css/modal.scss";
import { Button } from "@mui/material";

import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';
import CustomDialogRedesign from "components/modal/custom-dialog-redesign";

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { projectFileSelector } from 'store/projects/selectors';
import { deleteProjectFile } from 'store/projects/thunk';

export default function ProjectDeleteFile(props) {
  const { handleClose, open, retrieveFiles, selectedFile, setOpenToast, setMessage } = props;

  const dispatch = useAppDispatch();
  const { deleteProjectFileLoading, deleteProjectFileError } = useAppSelector(projectFileSelector);
  
  const disassociateFile = async () => {
    dispatch(deleteProjectFile({ fileId: selectedFile.id }))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          setMessage('File successfully deleted!');
          setOpenToast(true);
          retrieveFiles();
          handleClose();
        }
      })
  };

  const removeForm = () => {
    return (
      <>
        <div
          style={{
            fontFamily: "Korolev Medium",
            lineHeight: "25px",
            marginTop: "30px",
            textAlign: "left",
          }}
        >
          This will permanently remove <b>{selectedFile.display_name}</b> from the project's drop box.
          <br />
          Are you sure you want to remove this file?
        </div>
        <div className="contractsTags__tags__modal-buttonContainer">
          {deleteProjectFileLoading && (
            <div
              className="contractsTags__tags__modal-loading"
              style={{ top: '-30px', right: '190px' }}
            >
              <ProgressSpinnerDarkNavy/>
            </div>
          )}
          {deleteProjectFileError.length > 0 && (
            <p className="contractsTags__tags__modal-error--add">{deleteProjectFileError}</p>
          )}
          <Button
            variant="contained"
            className="add-user-button"
            disabled={deleteProjectFileLoading}
            sx={{
              backgroundColor: "#141A66",
              border: "1px solid #141A66",
              borderRadius: "10px",
              color: "#EFEFEF",
              fontFamily: "MagistralCondW08-Medium",
              fontSize: "18px",
              minHeight: "25px",
              padding: "13px 20px",
              textTransform: "capitalize",
              width: "175px",
              transition: 'all 0.3s',
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "#141A66",
              },
            }}
            onClick={() => disassociateFile()}
          >
            Remove
          </Button>
        </div>
      </>
    );
  };

  return (
    <CustomDialogRedesign
      colorPalette="navy"
      dialogContent={removeForm()}
      onClose={handleClose}
      open={open}
      title={"REMOVE"}
      titleHighlight={"DOCUMENT"}
    />
  );
}
