import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { Box, IconButton, TextField } from "@mui/material";
import Toast from "../toast/toast";
import API from "API";

export default function CondensedNotesModule(props) {
  const { selectedUser } = props;

  const token = JSON.parse(localStorage.getItem("token"));

  const [openToast, setOpenToast] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [errors, setErrors] = useState("");

  const handleSubmitMessage = async () => {
    await fetch(`${API.endpointURL}/api/notes/`, {
      method: "POST",
      body: JSON.stringify({
        user_id: selectedUser.id,
        body: textMessage.length > 0 ? textMessage : "This is a test message",
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["errors"]) {
          setErrors(data["errors"]);
        } else {
          setTextMessage("");
          setOpenToast(true);
        }
      })
      .catch((err) => {
        setErrors(err.messages);
      });
  };

  const handleChangeText = (newValue) => {
    setTextMessage(newValue);
  };

  const keyPress = (e) => {
    // Submits text on enter
    if (e.keyCode == 13) {
      handleSubmitMessage();
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundColor: "#fff",
          border: "1px solid #3e453d",
          borderTop: "0",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          width: "auto",
          height: "auto",
          color: "black",
        }}
      >
        <TextField
          value={textMessage}
          onChange={(e) => handleChangeText(e.currentTarget.value)}
          onKeyDown={keyPress}
          fullWidth
          sx={{ border: "0", padding: "6px 10px 5px" }}
          placeholder="Add Note..."
          id="fullWidth"
          multiline={true}
          rows={2}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "5px 10px 10px",
          }}
        >
          <IconButton
            onClick={() => handleSubmitMessage()}
            disabled={!textMessage}
            sx={{
              padding: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <SendIcon
              sx={{
                color: "#a5af96",
                "&:hover": {
                  color: "#3e453d",
                },
              }}
            />
          </IconButton>
        </Box>
      </Box>
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message="Applicant note saved"
        />
      )}
    </React.Fragment>
  );
}
