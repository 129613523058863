import React, { useState } from "react";
import { Box } from "@mui/material";

import EqipButton from "../buttons/eqip.button";

import Toast from "components/toast/toast";
import { useAppSelector } from 'hooks/reduxHooks';
import { applicantSelector } from 'store/applicants/selectors';
import { contractTemplatesSelector, loadingTemplatesSelector } from 'store/contracts/selectors';
import ProgressSpinner from 'components/progress/progress-spinner';

export default function USGDocsAutomation(props) {
  const { handleUpdateApplicants, setButton } = props;
  
  const allTemplates = useAppSelector(contractTemplatesSelector);
  const loadingTemplates = useAppSelector(loadingTemplatesSelector);
  const [openEmailToast, setOpenEmailToast] = useState(false);
  const [openErrorToast, setOpenErrorToast] = useState(false);
  const applicantData = useAppSelector(applicantSelector);

  const templates = allTemplates.filter(item => item?.template_type.includes('usg_documents'));

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {loadingTemplates ? (
          <div className="contracts-loading-spinner">
            <ProgressSpinner />
          </div>
        ) : (
          <Box display="flex" flexDirection="column" gap="5px" justifyContent="space-between">
            {templates[0]?.active === true &&
              setButton(
                0,
                "eqip_sent",
                "usg_documents_button_1_confirm",
                setOpenErrorToast
              )}
            {templates[1]?.active === true &&
              setButton(
                1,
                "bi_packet_sent",
                "usg_documents_button_2_confirm",
                setOpenErrorToast
              )}
            {templates[2]?.active === true &&
              setButton(
                2,
                "usg_documents_button_3",
                "usg_documents_button_3_confirm",
                setOpenErrorToast
              )}
            {templates[3]?.active === true &&
              setButton(
                3,
                "usg_documents_button_4",
                "usg_documents_button_4_confirm",
                setOpenErrorToast
              )}
            {templates[4]?.active === true &&
              setButton(
                4,
                "usg_documents_button_5",
                "usg_documents_button_5_confirm",
                setOpenErrorToast
              )}
          </Box>
        )}
        <EqipButton
          user={applicantData}
          handleUpdateApplicants={handleUpdateApplicants}
        />
      </Box>
    
      {openEmailToast && (
        <Toast
          open={openEmailToast}
          onClose={() => setOpenEmailToast(false)}
          message="Email sent"
        />
      )}
      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message="There was an issue sending your email. Please try again later."
        />
      )}
    </Box>
  );
}
