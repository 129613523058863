import '../../css/modal.scss';
import React, { useEffect, useState } from 'react';
import { Button, MenuItem, Select, TextField } from '@mui/material';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import CustomDialog from './custom-dialog';
import Switch from '@mui/material/Switch';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { createDivision, updateDivision } from 'store/divisions/thunk';
import {
  divisionsActionMessageSelector,
  divisionsLoadingSelector,
  selectedDivisionSelector,
} from 'store/divisions/selectors';
import ProgressSpinner from 'components/progress/progress-spinner';
import {
  selectedSubdivisionSelector,
  subdivisionsLoadingSelector, subdivisionsMessageSelector,
} from 'store/subdivisions/selectors';
import { createSubdivision, updateSubdivision } from 'store/subdivisions/thunk';
import { selectedContractSelector } from 'store/contracts/selectors';

export default function CrudContractDivisionSubdivisionModal(props) {
  const {
    action,
    getDivisions,
    getSubdivisions,
    handleClose,
    open,
    type,
    setMessage,
    setOpenTost,
  } = props;
  
  const dispatch = useAppDispatch();
  const selectedContract = useAppSelector(selectedContractSelector);
  const selectedDivision = useAppSelector(selectedDivisionSelector);
  const selectedSubdivision = useAppSelector(selectedSubdivisionSelector);
  const loadingDivisionActions = useAppSelector(divisionsLoadingSelector);
  const loadingSubDivisionActions = useAppSelector(subdivisionsLoadingSelector);
  const divisionsMessage = useAppSelector(divisionsActionMessageSelector);
  const subdivisionsMessage = useAppSelector(subdivisionsMessageSelector);
  
  const [title, setTitle] = useState('');
  const [errorTitle, setErrorTitle] = useState(false);
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('open');
  const [showOnApplication, setShowOnApplication] = useState(false);
  const [showOnBoard, setShowOnBoard] = useState(false);
  const [isLocation, setIsLocation] = useState(false);
  const [staffingFilter, setStaffingFilter] = useState(false);
  
  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],
      ['link'],
      [{ 'align': [] }],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: true,
    },
  };
  
  useEffect(() => {
    if (type === 'Division') {
      setMessage(divisionsMessage)
    } else {
      setMessage(subdivisionsMessage)
    }
  }, [divisionsMessage, subdivisionsMessage])
  
  useEffect(() => {
    if (type === 'Division' && selectedDivision && action !== 'add') {
      setTitle(selectedDivision?.title);
      setShowOnApplication(selectedDivision?.application_display || false);
      setIsLocation(selectedDivision?.is_location || false);
      setShowOnBoard(selectedDivision?.job_board_display || false);
      setDescription(selectedDivision?.job_description || '');
      setStatus(selectedDivision?.status);
      setStaffingFilter(selectedDivision?.is_staffing_filterable);
    }
    if (type === 'Subdivision' && selectedSubdivision && action !== 'add') {
      setTitle(selectedSubdivision?.title);
      setShowOnApplication(selectedSubdivision?.application_display || false);
      setIsLocation(selectedSubdivision?.is_location || false);
      setShowOnBoard(selectedSubdivision?.job_board_display || false);
      setDescription(selectedSubdivision?.job_description || '');
      setStatus(selectedSubdivision?.status);
      setStaffingFilter(selectedSubdivision?.is_staffing_filterable);
    }
  }, [selectedDivision, selectedSubdivision, type]);
  
  const handleSubmit = () => {
    if (!title) {
      setErrorTitle(true);
    } else {
      setErrorTitle(false);
      if (action === 'add') {
        if (type === 'Division') {
          createNewDivision();
        } else if (type === 'Subdivision') {
          createNewSubdivision();
        }
      } else if (action === 'edit') {
        if (type === 'Division') {
          updateDivisionById();
        } else if (type === 'Subdivision') {
          updateSubdivisionById();
        }
      }
    }
  };
  
  const createNewDivision = async () => {
    const body = {
      title: title,
      contract_id: selectedContract.id,
      job_board_display: showOnBoard,
      is_location: isLocation,
      application_display: showOnApplication,
      job_description: description,
      status: status,
      is_staffing_filterable: staffingFilter,
    };
    await dispatch(createDivision({ body }))
      .then(() => {
        setOpenTost(true);
        handleClose();
        getDivisions();
      });
  };
  
  const createNewSubdivision = async () => {
    const body = {
      title: title,
      region_id: selectedDivision.id,
      contract_id: selectedContract.id,
      job_board_display: showOnBoard,
      is_location: isLocation,
      application_display: showOnApplication,
      job_description: description,
      status: status,
      is_staffing_filterable: staffingFilter,
    };
    await dispatch(createSubdivision({ body }))
      .then(() => {
        setOpenTost(true);
        handleClose();
        getSubdivisions();
      });
  };
  
  const updateDivisionById = async () => {
    const body = {
      title: title,
      contract_id: selectedContract.id,
      job_board_display: showOnBoard,
      is_location: isLocation,
      application_display: showOnApplication,
      job_description: description,
      status: status,
      is_staffing_filterable: staffingFilter,
    };
    await dispatch(updateDivision({ divisionId: selectedDivision.id, body }))
      .then(() => {
        handleClose();
        setOpenTost(true);
        if (selectedContract?.id) {
          getDivisions();
        }
      });
  };
  
  const updateSubdivisionById = async () => {
    const body = {
      title: title,
      region_id: selectedDivision.id,
      contract_id: selectedContract.id,
      job_board_display: showOnBoard,
      is_location: isLocation,
      application_display: showOnApplication,
      job_description: description,
      status: status,
      is_staffing_filterable: staffingFilter,
    };
    await dispatch(updateSubdivision({ subdivisionId: selectedSubdivision.id, body }))
      .then(() => {
        handleClose();
        setOpenTost(true);
        if (selectedContract?.id && selectedDivision?.id) {
          getSubdivisions();
        }
      });
  };
  
  const crudContractDivisionSubdivisionForm = () => {
    return (
      <React.Fragment key="crud-division-form">
        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
          <div style={{ width: '50%' }}>
            <div style={{ textAlign: 'left' }} className="form-row-label">
              {type} Name
            </div>
            <TextField
              fullWidth
              className="crud-division-form-input"
              id="crud-division-name"
              key="crud-division-name"
              variant="outlined"
              required
              value={title}
              onChange={(e) => {
                setErrorTitle(!e.currentTarget.value);
                setTitle(e.currentTarget.value);
              }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' } }}
            />
          </div>
          <div style={{ width: '50%' }}>
            <div style={{ textAlign: 'left' }} className="form-row-label">
              Status
            </div>
            <Select
              displayEmpty
              placeholder="Please select status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              sx={{
                backgroundColor: '#EFEFEF',
                border: 0,
                borderRadius: '8px',
                fontFamily: 'Korolev Medium',
                width: '100%',
                textAlign: 'start',
              }}
            >
              <MenuItem
                value="open"
                sx={{ fontFamily: 'Korolev Medium' }}
              >
                Open
              </MenuItem>
              <MenuItem
                value="closed"
                sx={{ fontFamily: 'Korolev Medium' }}
              >
                Closed
              </MenuItem>
              <MenuItem
                value="archived"
                sx={{ fontFamily: 'Korolev Medium' }}
              >
                Archived
              </MenuItem>
            </Select>
          </div>
        </div>
        
        
        <div style={{ textAlign: 'left' }} className="form-row-label">
          Job Description
        </div>
        <div className="contract-quill">
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            modules={modules}
            style={{ color: '#171717', borderRadius: '10px', fontSize: '16px', height: '325px' }}
          />
        </div>
        <div className="contract-switches">
          Show on Application
          <Switch
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#62AB37',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#62AB37',
              },
              '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                backgroundColor: '#c7c7c7',
              },
            }}
            checked={showOnApplication}
            onChange={() => setShowOnApplication(!showOnApplication)}
          />
          <br/>
          Show on Job Board
          <Switch
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#62AB37',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#62AB37',
              },
              '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                backgroundColor: '#c7c7c7',
              },
            }}
            checked={showOnBoard}
            onChange={() => setShowOnBoard(!showOnBoard)}
          />
          <br/>
          Is Location
          <Switch
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#62AB37',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#62AB37',
              },
              '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                backgroundColor: '#c7c7c7',
              },
            }}
            checked={isLocation}
            onChange={() => setIsLocation(!isLocation)}
          />
          <br/>
          Staffing Filter
          <Switch
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#62AB37',
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#62AB37',
              },
              '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                backgroundColor: '#c7c7c7',
              },
            }}
            checked={staffingFilter}
            onChange={() => setStaffingFilter(!staffingFilter)}
          />
        </div>
        <div className="button-container" style={{ position: 'relative' }}>
          {loadingDivisionActions || loadingSubDivisionActions && (
            <div style={{ position: 'absolute', right: '210px', top: '-37px' }}>
              <ProgressSpinner/>
            </div>
          )}
          <Button
            variant="contained"
            className="add-user-button"
            sx={{
              backgroundColor: '#556155',
              color: '#EFEFEF',
              fontSize: '18px',
              width: '175px',
              '&:hover': { backgroundColor: '#a5af96', color: '#3e453d' },
            }}
            onClick={() => handleSubmit()}
            disabled={errorTitle || loadingDivisionActions || loadingSubDivisionActions}
          >
            {action}
          </Button>
          {errorTitle && (
            <p style={{ position: 'absolute', right: '220px', bottom: '35px', color: '#a20601' }}>
              Please enter {type} name
            </p>
          )}
        </div>
      </React.Fragment>
    );
  };
  
  return (
    <React.Fragment>
      <CustomDialog
        dialogContent={crudContractDivisionSubdivisionForm()}
        onClose={handleClose}
        open={open}
        title={action}
        titleHighlight={type}
      />
    </React.Fragment>
  );
}
