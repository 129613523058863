import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography } from '@mui/material';


export default function ApplicationCompleted(props) {

  const { applicantEmail } = props;

  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <CheckCircleIcon
        sx={{
          color: '#a5af96',
          fontSize: '56px',
          margin: '15px 0',
        }}
      />
      <Typography
        sx={{
          color: '#dae0bc',
          fontFamily: 'MagistralCondW08-Medium',
          fontSize: '35px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '50px',
          textTransform: 'uppercase',
        }}
      >
        Thank you!
      </Typography>
      <Typography
        sx={{
          color: '#EFEFEF',
          fontFamily: 'Korolev Medium',
          fontSize: '16px',
          lineHeight: '35px',
          margin: '30px auto 0',
          maxWidth: '80%',
        }}
      >
        Your application has been submitted and will be processed.
      </Typography>
      <Typography
        sx={{
          color: '#EFEFEF',
          fontFamily: 'Korolev Medium',
          fontSize: '16px',
          height: 'calc(100vh - 400px)',
          lineHeight: '35px',
          margin: '30px auto 0',
          maxWidth: '80%',
        }}
      >
        An invite will be sent to {applicantEmail ? applicantEmail : "your email"} to manage your application and continue with account registration.
      </Typography>
    </Box>
  );
};
