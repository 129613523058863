import React from "react";
import { TextField } from "@mui/material";

export const InputField = (props) => {
  const { name, value, onChange, error, type } = props;
  return (
    <div className="infoForm-inputContainer">
      <p className="infoForm-fieldName">{name}</p>
      <TextField
        fullWidth={true}
        placeholder={name !== 'Middle Initial' ? `${name} *` : name}
        variant="outlined"
        required
        value={value}
        onChange={onChange}
        error={!!error}
        type={type && type}
        sx={{
          backgroundColor: "#3e453d",
          borderRadius: "4px",
          color: "#EFEFEF",
          fontFamily: "Korolev Medium",
          input: {
            color: "#EFEFEF",
            fontFamily: "Korolev Medium",
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px #3e453d inset",
              WebkitTextFillColor: '#EFEFEF'
            }
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#a5af96',
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#a5af96',
            },
          },
        }}
      />
      {error && <p style={{ fontSize: '14px' }} className="infoForm-error">{error}</p>}
    </div>
  )
};
