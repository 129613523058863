import { createAsyncThunk } from '@reduxjs/toolkit';
import { recruiters } from 'services/recruiters';
export const getRecruiters = createAsyncThunk(
  'recruiters',
  async (_, { rejectWithValue }) => {
    try {
      return await recruiters.getAllRecruiters();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getRecruiterContracts = createAsyncThunk(
  'recruiterContracts',
  async ({ recruiterId }, { rejectWithValue }) => {
    try {
      return await recruiters.getRecruiterContracts(recruiterId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getAssignedRecruitersByContractId = createAsyncThunk(
  'assignedRecruitersByContractId',
  async ({ contractId }, { rejectWithValue }) => {
    try {
      return await recruiters.getAssignedRecruitersByContractId(contractId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const assignRecruiter = createAsyncThunk(
  'assignRecruiter',
  async ({ _, body }, { rejectWithValue }) => {
    try {
      return await recruiters.assignRecruiter(_, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const dissociateRecruiter = createAsyncThunk(
  'dissociateRecruiter',
  async ({ _, body }, { rejectWithValue }) => {
    try {
      return await recruiters.dissociateRecruiter(_, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
