import "../../css/application.scss";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function RDInternalApplication() {

  const navigate = useNavigate();
  useEffect(() => {
    navigate('/general-application');
  }, []);

  return (
    <div className="background">
      <div className="copyright">©2023 Responsive Deployment</div>
    </div>
  );
}
