import { TableCell, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';


export const StyledTableContainer = styled(TableContainer)({
  border: '1px solid #EFEFEF80',
  borderBottomLeftRadius: "10px",
  borderTopLeftRadius: "10px",
  height: 720,
  '& th': {
    fontSize: '1.25rem',
  },
});

export const StyledTableHeaderCell = styled(TableCell)(({ firstcell, lastcell }) => ({
  backgroundColor: '#2A3D1F',
  borderBottom: '1px solid #EFEFEF80',
  color: '#EFEFEF',
  fontFamily: 'MagistralCondW08-Medium',
  minHeight: '58px',
  padding: "16px 8px",
  paddingLeft: firstcell === 'true' ? "16px" : "8px",
  paddingRight: lastcell === 'true' ? "16px" : "8px",
  textAlign: 'left',
  textTransform: 'capitalize',
  verticalAlign: 'middle'
}));

export const StyledTableRow = styled(TableRow)(({ fullWidth = false }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#efefef40',
  },
  '&.Mui-selected': {
    '& > td': {
      position: 'relative',
      zIndex: 2,
      ' &:first-child:after': {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        width: fullWidth ? 'calc(51vw - 50px)' : 'calc(38vw - 50px)',
        height: '100%',
        content: "''",
        backgroundImage: 'linear-gradient(to right, #62AB3757, #efefef00)',
      },
    },
    // background: 'linear-gradient(to right, #62AB3757, #efefef00)',
    '&:hover': {
      backgroundColor: '#efefef40',
    },
  },
}));

export const StyledTableBodyCell = styled(TableCell)(({ firstcell, lastcell, lastrow }) => ({
  borderBottom: lastrow === 'true' ? '0' : '1px solid #EFEFEF80',
  color: '#EFEFEF',
  fontFamily: 'Korolev Medium',
  fontSize: '0.95rem',
  padding: "16px 8px",
  paddingLeft: firstcell === 'true' ? "16px" : "8px",
  paddingRight: lastcell === 'true' ? "16px" : "8px",
}));
