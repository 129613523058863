import { createSlice } from '@reduxjs/toolkit';
import {
  createContract,
  createContractQuestion,
  deleteContractById,
  deleteContractQuestion,
  editContractById,
  editContractTemplate,
  getAllContractMetrics,
  getContractById,
  getContractFilters,
  getContractMetrics,
  getContractQuestions,
  getContracts,
  getContractsByStatus,
  getContractTemplates,
  getCustomerContracts,
  updateContractQuestion, updateContractQuestionsOrder,
} from 'store/contracts/thunk';

const initialState = {
  contracts: [],
  currentPage: 1,
  totalPages: 1,
  filteredContractsByStatus: [],
  contractsStatus: '',
  currentContractsTab: 'contracts',
  
  contractFilters: [],
  
  contractTemplates: [],
  contractTemplatesError: '',
  loadingTemplates: false,
  currentContractTemplate: null,
  
  contractsError: '',
  isLoading: false,
  
  contract: null,
  selectedContract: null,
  newContract: null,
  deleteContractLoading: false,
  deleteContractError: false,
  editContractError: '',
  contractActionStatus: '',
  
  customerContracts: [],
  
  allContractsMetrics: {
    loading: false,
    error: '',
    totalHiresPerDay: 0,
    totalHiresPerWeek: 0,
    totalHiresPerMonth: 0,
    totalHiresAllTime: 0,
    totalActiveContracts: 0,
    totalCompletedContracts: 0,
    totalArchivedContracts: 0,
    totalApplicantsDispositioned: 0,
    totalPendingApplicants: 0,
    hiresPer: 0,
  },
  contractMetrics: {
    contractTitle: '',
    applicantsHired: 0,
    applicantsNeeded: 0,
    hiresNeededPerDay: 0,
    outstandingHires: 0,
    recruiterMetrics: [],
    referralMetrics: null,
    loading: false,
    error: '',
  },
  contractQuestions: {
    orderedQuestions: [],
    loading: false,
    error: '',
    newQuestion: null,
    editQuestion: null,
    deleteQuestion: null,
    changedOrder: false,
  }
};

const contractsSlice = createSlice({
  name: 'contractsSlice',
  initialState,
  reducers: {
    setContractTemplates(state, { payload }) {
      state.contractTemplates = payload;
    },
    setCurrentContractTemplate(state, { payload }) {
      state.currentContractTemplate = payload;
    },
    resetContract(state) {
      state.contract = null;
    },
    resetNewContract(state) {
      state.newContract = null;
    },
    setContractActionStatus(state, { payload }) {
      state.contractActionStatus = payload;
    },
    setSelectedContract(state, { payload }) {
      state.selectedContract = payload;
    },
    setContractsStatus(state, { payload }) {
      state.contractsStatus = payload;
    },
    setFilteredContractsByStatus(state, { payload }) {
      state.filteredContractsByStatus = payload;
    },
    setCurrentContractsTab(state, { payload }) {
      state.currentContractsTab = payload;
    },
    setAllContractsAnalytics(state, { payload }) {
      state.allContractsMetrics.hiresPer = payload;
    },
    setEditContractError(state, { payload }) {
      state.editContractError = payload;
    },
    setCurrentContractsPage (state, { payload }) {
      state.currentPage = payload;
    },
    setOrderedQuestions (state, { payload }) {
      state.contractQuestions.orderedQuestions = payload;
    },
    setNewQuestion (state, { payload }) {
      state.contractQuestions.newQuestion = payload;
    },
    setEditQuestion (state, { payload }) {
      state.contractQuestions.editQuestion = payload;
    },
    setDeleteQuestion (state, { payload }) {
      if ({ payload }) {
        state.contractQuestions.deleteQuestion = payload;
      }
    },
    setChangedQuestionsOrder(state, { payload }) {
      state.contractQuestions.changedOrder = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getContracts.pending, (state) => {
      state.contractsError = '';
      state.isLoading = true;
    });
    builder.addCase(getContracts.fulfilled, (state, action) => {
      state.contracts = action.payload.data;
      state.contractsError = '';
      state.isLoading = false;
      state.currentPage = action.payload.meta.current_page;
      state.totalPages = action.payload.meta.last_page;
    });
    builder.addCase(getContracts.rejected, (state) => {
      state.contractsError = 'Error fetching contracts';
      state.isLoading = false;
    });
    
    builder.addCase(getContractsByStatus.pending, (state) => {
      state.contractsError = '';
      state.isLoading = true;
    });
    builder.addCase(getContractsByStatus.fulfilled, (state, action) => {
      state.contracts = action.payload.data;
      state.contractsError = '';
      state.isLoading = false;
      state.currentPage = action.payload.meta.current_page;
      state.totalPages = action.payload.meta.last_page;
    });
    builder.addCase(getContractsByStatus.rejected, (state) => {
      state.contractsError = 'Error fetching contracts';
      state.isLoading = false;
    });
    
    builder.addCase(getContractFilters.pending, (state) => {
      state.contractsError = '';
      state.isLoading = true;
    });
    builder.addCase(getContractFilters.fulfilled, (state, action) => {
      state.contractFilters = action.payload;
      state.contractsError = '';
      state.isLoading = false;
    });
    builder.addCase(getContractFilters.rejected, (state, action) => {
      state.contractsError = action.error.message;
      state.isLoading = false;
    });
    
    builder.addCase(getContractTemplates.pending, (state) => {
      state.contractTemplatesError = '';
      state.loadingTemplates = true;
    });
    builder.addCase(getContractTemplates.fulfilled, (state, action) => {
      state.contractTemplates = action.payload;
      state.contractTemplatesError = '';
      state.loadingTemplates = false;
    });
    builder.addCase(getContractTemplates.rejected, (state) => {
      state.contractTemplatesError = 'An Error occurred getting Contract Templates';
      state.loadingTemplates = false;
    });
    
    builder.addCase(editContractTemplate.pending, (state) => {
      state.contractTemplatesError = '';
      state.loadingTemplates = true;
    });
    builder.addCase(editContractTemplate.fulfilled, (state, action) => {
      state.currentContractTemplate = action.payload;
      state.contractTemplatesError = '';
      state.loadingTemplates = false;
    });
    builder.addCase(editContractTemplate.rejected, (state) => {
      state.contractTemplatesError = 'Error editing template..';
      state.loadingTemplates = false;
    });
    
    builder.addCase(getContractById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getContractById.fulfilled, (state, action) => {
      state.contract = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getContractById.rejected, (state) => {
      state.isLoading = false;
    });
    
    builder.addCase(getCustomerContracts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerContracts.fulfilled, (state, action) => {
      state.customerContracts = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getCustomerContracts.rejected, (state) => {
      state.isLoading = false;
    });
    
    builder.addCase(getAllContractMetrics.pending, (state) => {
      state.allContractsMetrics.loading = true;
      state.allContractsMetrics.error = '';
    });
    builder.addCase(getAllContractMetrics.fulfilled, (state, action) => {
      state.allContractsMetrics.totalHiresPerDay = action.payload.total_hires_per_day;
      state.allContractsMetrics.totalHiresPerWeek = action.payload.total_hires_per_week;
      state.allContractsMetrics.totalHiresPerMonth = action.payload.total_hires_per_month;
      state.allContractsMetrics.totalHiresAllTime = action.payload.total_hires_all_time;
      state.allContractsMetrics.totalActiveContracts = action.payload.total_active_contracts;
      state.allContractsMetrics.totalCompletedContracts = action.payload.total_completed_contracts;
      state.allContractsMetrics.totalArchivedContracts = action.payload.total_archived_contracts;
      state.allContractsMetrics.totalApplicantsDispositioned = action.payload.total_applicants_dispositioned;
      state.allContractsMetrics.totalPendingApplicants = action.payload.total_pending_applicants;
      state.allContractsMetrics.hiresPer = action.payload.total_hires_per_day;
      state.allContractsMetrics.loading = false;
      state.allContractsMetrics.error = '';
    });
    builder.addCase(getAllContractMetrics.rejected, (state) => {
      state.allContractsMetrics.loading = false;
      state.allContractsMetrics.error = 'Error getting contracts metrics.';
    });
    
    builder.addCase(getContractMetrics.pending, (state) => {
      state.contractMetrics.loading = true;
      state.contractMetrics.error = '';
    });
    builder.addCase(getContractMetrics.fulfilled, (state, action) => {
      state.contractMetrics.contractTitle = action.payload.contract_title;
      state.contractMetrics.applicantsHired = action.payload.applicants_hired;
      state.contractMetrics.applicantsNeeded = action.payload.applicants_needed;
      state.contractMetrics.hiresNeededPerDay = action.payload.hires_needed_per_day;
      state.contractMetrics.outstandingHires = action.payload.outstanding_hires;
      state.contractMetrics.recruiterMetrics = action.payload.recruiter_metrics;
      state.contractMetrics.referralMetrics = action.payload.referral_percentages;
      state.contractMetrics.loading = false;
      state.contractMetrics.error = '';
    });
    builder.addCase(getContractMetrics.rejected, (state) => {
      state.contractMetrics.loading = false;
      state.contractMetrics.error = 'Error getting contract metrics.';
    });
    
    builder.addCase(deleteContractById.pending, (state) => {
      state.deleteContractLoading = true;
      state.deleteContractError = false;
    });
    builder.addCase(deleteContractById.fulfilled, (state) => {
      state.deleteContractLoading = false;
      state.deleteContractError = false;
      state.selectedContract = null;
    });
    builder.addCase(deleteContractById.rejected, (state) => {
      state.deleteContractLoading = false;
      state.deleteContractError = true;
    });
    
    builder.addCase(editContractById.pending, (state) => {
      state.isLoading = true;
      state.contractActionStatus = '';
      state.editContractError = '';
    });
    builder.addCase(editContractById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.editContractError = '';
      state.selectedContract = action.payload;
      state.newContract = action.payload;
      state.currentContract = action.payload;
      state.contractActionStatus = 'fulfilled';
    });
    builder.addCase(editContractById.rejected, (state, action) => {
      state.isLoading = false;
      state.contractActionStatus = 'rejected';
      if (action.payload.response.data.success === false) {
        if (action.payload.response.data.error_key === 'contract_alias already exists') {
          state.editContractError = 'This URL is already associated with another contract.';
        } else {
          state.editContractError = 'Error updating contract';
        }
      }
    });
    
    builder.addCase(createContract.pending, (state) => {
      state.isLoading = true;
      state.contractActionStatus = '';
      state.editContractError = '';
    });
    builder.addCase(createContract.fulfilled, (state, action) => {
      state.isLoading = false;
      state.editContractError = '';
      state.newContract = action.payload;
      state.currentContract = action.payload;
      state.selectedContract = action.payload;
      state.contractActionStatus = 'fulfilled';
    });
    builder.addCase(createContract.rejected, (state, action) => {
      state.isLoading = false;
      state.contractActionStatus = 'rejected';
      state.newContract = null;
      if (action.payload.response.data.success === false) {
        if (action.payload.response.data.error_key === 'contract_alias already exists') {
          state.editContractError = 'This URL is already associated with another contract.';
        } else {
          state.editContractError = 'Error updating contract';
        }
      }
    });
    
    builder.addCase(getContractQuestions.pending, (state) => {
      state.contractQuestions.loading = true;
      state.contractQuestions.error = '';
    });
    builder.addCase(getContractQuestions.fulfilled, (state, action) => {
      state.contractQuestions.loading = false;
      state.contractQuestions.error = '';
      // state.contractQuestions.orderedQuestions = action.payload.map(item => ({ ...item, id: String(item.id) }));
      state.contractQuestions.orderedQuestions = action.payload;
    });
    builder.addCase(getContractQuestions.rejected, (state) => {
      state.contractQuestions.loading = false;
      state.contractQuestions.orderedQuestions = [];
      state.contractQuestions.error = 'Error getting questions.';
    });
    
    builder.addCase(createContractQuestion.pending, (state) => {
      state.contractQuestions.loading = true;
      state.contractQuestions.error = '';
    });
    builder.addCase(createContractQuestion.fulfilled, (state) => {
      state.contractQuestions.loading = false;
      state.contractQuestions.error = '';
      state.contractQuestions.newQuestion = null;
    });
    builder.addCase(createContractQuestion.rejected, (state) => {
      state.contractQuestions.loading = false;
      state.contractQuestions.error = 'Error creating questions.';
    });

    builder.addCase(updateContractQuestionsOrder.pending, (state) => {
      state.contractQuestions.loading = true;
      state.contractQuestions.error = '';
    });
    builder.addCase(updateContractQuestionsOrder.fulfilled, (state) => {
      state.contractQuestions.error = '';
    });
    builder.addCase(updateContractQuestionsOrder.rejected, (state) => {
      state.contractQuestions.loading = false;
      state.contractQuestions.error = 'Error updating questions order.';
    });
    
    builder.addCase(updateContractQuestion.pending, (state) => {
      state.contractQuestions.loading = true;
      state.contractQuestions.error = '';
    });
    builder.addCase(updateContractQuestion.fulfilled, (state) => {
      state.contractQuestions.loading = false;
      state.contractQuestions.error = '';
      state.contractQuestions.editQuestion = null;
    });
    builder.addCase(updateContractQuestion.rejected, (state) => {
      state.contractQuestions.loading = false;
      state.contractQuestions.error = 'Error updating question.';
    });
    
    builder.addCase(deleteContractQuestion.pending, (state) => {
      state.contractQuestions.error = '';
    });
    builder.addCase(deleteContractQuestion.fulfilled, (state) => {
      state.contractQuestions.loading = false;
      state.contractQuestions.error = '';
      state.contractQuestions.deleteQuestion = null;
      state.contractQuestions.editQuestion = null;
    });
    builder.addCase(deleteContractQuestion.rejected, (state) => {
      state.contractQuestions.loading = false;
      state.contractQuestions.error = 'Error deleting question.';
    });
  },
});

export const {
  setContractTemplates,
  resetContract,
  setContractsStatus,
  setFilteredContractsByStatus,
  setCurrentContractsTab,
  setAllContractsAnalytics,
  setSelectedContract,
  setEditContractError,
  resetNewContract,
  setCurrentContractsPage,
  setOrderedQuestions,
  setNewQuestion,
  setEditQuestion,
  setDeleteQuestion,
  setCurrentContractTemplate,
  setContractActionStatus,
  setChangedQuestionsOrder,
} = contractsSlice.actions;

export default contractsSlice.reducer;