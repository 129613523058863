import {
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import contractsReducer from 'store/contracts/slice';
import applicantsReducer from 'store/applicants/slice';
import recruitersReducer from 'store/recruiters/slice';
import tagsReducer from 'store/tags/slice';
import divisionsReducer from 'store/divisions/slice';
import subdivisionsReducer from 'store/subdivisions/slice';
import staffingSelectedOptionsReducer from 'store/staffingSelectedOptions/slice';
import salesSelectedOptionsReducer from 'store/salesSelectedOptions/slice';
import uiReducer from 'store/ui/slice';
import userReducer from 'store/user/slice';
import filesReducer from 'store/files/slice';
import salesReducer from 'store/sales/slice';
import authReducer from 'store/auth/slice';
import quotesReducer from 'store/quotes/slice';
import projectsReducer from 'store/projects/slice';
import connectionsReducer from 'store/connections/slice';
import tasksReducer from 'store/tasks/slice';
import notesReducer from 'store/notes/slice';

const combinedReducer = combineReducers({
  contracts : contractsReducer,
  applicants: applicantsReducer,
  recruiters: recruitersReducer,
  tags: tagsReducer,
  divisions: divisionsReducer,
  subdivisions: subdivisionsReducer,
  staffing: staffingSelectedOptionsReducer,
  ui: uiReducer,
  user: userReducer,
  files: filesReducer,
  sales: salesReducer,
  salesOptions: salesSelectedOptionsReducer,
  auth: authReducer,
  quotes: quotesReducer,
  projects: projectsReducer,
  connections: connectionsReducer,
  tasks: tasksReducer,
  notes: notesReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: combinedReducer,
  });
};
