import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "css/pages.scss";
import "css/contract-email-templates.scss";

import AppBar from "components/app-bar/updated-app-bar-component";
import SidebarTabs from "components/tabs/sidebar-tabs";
import DivisionsSubdivisions from "components/contract-portal/divisions-subdivisions";
import GeneralInformation from "components/contract-portal/general-information";
import Recruiters from "components/contract-portal/recruiters";
import SendCustomEmail from "components/contract-portal/send-custom-email";
import ContractVariables from "components/contract-portal/contract-variables";
import { ApplicationManagement } from 'components/contract-portal/application-management';
import ProgressSpinner from 'components/progress/progress-spinner';
import ContractIdAnalytics from 'components/contracts-analytics/contractId-analytics';

import { getContractById, getContractMetrics, getContractQuestions, getContractTemplates } from 'store/contracts/thunk';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setCurrentContractTemplate, setSelectedContract } from 'store/contracts/slice';
import { getAssignedRecruitersByContractId, getRecruiters } from 'store/recruiters/thunk';
import { getDivisionsByContractId } from 'store/divisions/thunk';
import {
  contractSelector,
  contractsLoadingSelector,
  selectedContractSelector,
} from 'store/contracts/selectors';
import ContractEmailTemplates from 'components/contract-portal/contract-email-templates';

export default function ContractPortal() {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const loadingContract = useAppSelector(contractsLoadingSelector);
  const contract = useAppSelector(contractSelector);
  const currentContract = useAppSelector(selectedContractSelector);
  const [currentTab, setCurrentTab] = useState("general information");
  const [loadedRecruiters, setLoadedRecruiters] = useState(false);
  const [loadedTemplates, setLoadedTemplates] = useState(false);
  const [loadedQuestions, setLoadedQuestions] = useState(false);
  const [loadedAnalytics, setLoadedAnalytics] = useState(false);
  const [loadedDivisions, setLoadedDivisions] = useState(false);
  
  const getTemplates = () => {
    dispatch(getContractTemplates({ contractId: id }))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          setLoadedTemplates(true);
        }
      });
  };

  useEffect(() => {
    if (currentTab === 'recruiters' && !loadedRecruiters) {
      dispatch(getRecruiters())
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setLoadedRecruiters(true);
          }
        });
      dispatch(getAssignedRecruitersByContractId({ contractId: id }));
    }
    if ((currentTab === 'email templates') && !loadedTemplates) {
      getTemplates();
      dispatch(setCurrentContractTemplate(null));
    }
    if (currentTab === 'application management' && !loadedQuestions) {
      dispatch(getContractQuestions({ contractId: id }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setLoadedQuestions(true);
          }
        });
    }
    if (currentTab === 'analytics' && !loadedAnalytics) {
      dispatch(getContractMetrics({ contractId: id }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setLoadedAnalytics(true);
          }
        });
    }
    if (currentTab === 'divisions & subdivisions' && !loadedDivisions) {
      dispatch(getDivisionsByContractId({ contractId: id }))
        .then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setLoadedDivisions(true);
          }
        });
    }
  }, [currentTab]);

  useEffect(() => {
    if (id) {
      dispatch(getContractById({ contractId: id}));
    }
  }, [id]);
  
  useEffect(() => {
    if(contract) {
      dispatch(setSelectedContract(contract))
    }
  }, [contract]);

  return (
    <>
      <AppBar colorPalette="green" menu={true} title="Contract Portal" />
      <div className="staffing-page">
        <div className="sidebar-content" style={{ width: '20%', height: 'calc(100vh - 80px)',
          overflow: 'auto' }}>
          <button
            className="back-button green-hover"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon
              sx={{
                marginRight: "5px",
                height: "15px",
                width: "auto",
              }}
            />
            Go Back
          </button>
          <div className="user-info-container back-button-padding">
            <div className="user-info-name" style={{ position: 'relative', minHeight: '35px' }}>
              {loadingContract
                ?
                <div style={{ position: 'absolute', left: 0, top: -45 }}><ProgressSpinner /></div>
                : currentContract && currentContract.title}
            </div>
          </div>
          <div className="tabs-container">
            <SidebarTabs
              colorPalette={"green"}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              tabs={[
                "general information",
                "divisions & subdivisions",
                "recruiters",
                "email templates",
                "send custom email",
                "contract variables",
                "application management",
                "analytics",
              ]}
            />
          </div>
        </div>
        <div className="page-content" style={{ width: '80%' }}>
          {loadingContract ? (
            <>
              <h1 className="content-header">General Information</h1>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                className="content-container"
              >
                <ProgressSpinner />
              </div>
            </>
          ) : (
            <>
              {currentTab === 'general information' && (
                <GeneralInformation />
              )}
              {currentTab === 'divisions & subdivisions' && (
                <DivisionsSubdivisions currentContract={currentContract}/>
              )}
              {currentTab === 'recruiters' && (
                <Recruiters currentContract={currentContract}/>
              )}
              {currentTab === 'email templates' && (
                <ContractEmailTemplates getTemplates={getTemplates} loadedTemplates={loadedTemplates} />
              )}
              {currentTab === 'send custom email' && (
                <SendCustomEmail currentContract={currentContract}/>
              )}
              {currentTab === 'contract variables' && (
                <ContractVariables />
              )}
              {currentTab === 'application management' && (
                <ApplicationManagement />
              )}
            </>
          )}
          {currentTab === "analytics" && (
            <ContractIdAnalytics color={'green'} currentContractId={currentContract.id} />
          )}
        </div>
      </div>
    </>
  );
};
