import { axiosService } from 'services/axiosServices';

export const recruiters = {
  getAllRecruiters: async () => {
    const res = await axiosService.get('/api/get_recruiters');
    return res.data;
  },
  getRecruiterContracts: async (recruiterId) => {
    const res = await axiosService.get(`/api/staffing/recruiters/get_assigned_contracts?recruiter_id=${recruiterId}`);
    return res.data;
  },
  getAssignedRecruitersByContractId: async (contractId) => {
    const res = await axiosService.get(`/api/staffing/contracts/get_recruiters?contract_id=${contractId}`);
    return res.data;
  },
  assignRecruiter: async (_, body) => {
    const res = await axiosService.post(`/api/staffing/contracts/assign_recruiter`, body);
    return res.data;
  },
  dissociateRecruiter: async (_, body) => {
    const res = await axiosService.post(`/api/staffing/contracts/dissociate_recruiter`, body);
    return res.data;
  }
}