import React, { useState } from "react";

import API from "API";
import 'css/spinner-container.scss';

import TabView from "components/generic/tab-view";
import TabViewItem from "components/generic/tab-view-item";
import VendorsTable from "components/table/vendors-table";
import Toast from "../toast/toast";
import { ProgressSpinnerBlue } from "../progress/progress-spinner-blue";

export default function Vendors(props) {
  const { templates, vendors, loading } = props;
  const token = JSON.parse(localStorage.getItem("token"));
  const [error, setError] = useState(null);
  const [openErrorToast, setOpenErrorToast] = useState(false);
  const [openStoredToast, setOpenStoredToast] = useState(false);


  // Currently pulls vendors from dynamics to RD. Eventually this will replace with syncing RD to Dynamics.
  const syncVendorsDynamics = async () => {
    try {
      const response = await fetch(
        `${API.endpointURL}/api/sync_dynamics_vendors`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.error || "Failed to sync vendors with Business Central"
        );
      }

      await response.json();
      // getVendors();
      // func of fetching venders are in parent component
      setOpenStoredToast(true);
    } catch (error) {
      setError(error.message);
      setOpenErrorToast(true);
    }
  };

  return (
    <>
      {/* TODO: Add back when ready to sync Dynamics
      <button
        style={{
          backgroundColor: "#00A5CF",
          border: "1px solid #00A5CF",
          borderRadius: "10px",
          color: "#EFEFEF",
          fontFamily: "MagistralCondW08-Medium",
          minHeight: "25px",
          marginBottom: "8px",
          padding: "13px 20px",
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "transparent",
            border: "1px solid #EFEFEF80",
          },
        }}
        onClick={() => syncVendorsDynamics()}
      >
        Update Vendors From Business Central
      </button> */}
      {/* <div>{<button onClick={() => getAllVendors()}>get all</button>}</div> */}
      <div>
        <TabView>
          <TabViewItem title="All Vendors">
            {!loading ? (
              <VendorsTable vendors={vendors} templates={templates} />
            ) : (
              <div className="spinner-fullScreen">
                <ProgressSpinnerBlue />
              </div>
            )}
          </TabViewItem>
          <TabViewItem title="New Vendors">
            <div style={{ margin: "32px", textAlign: "center" }}>
              Coming soon!
            </div>
          </TabViewItem>
        </TabView>
      </div>
      {openStoredToast && (
        <Toast
          open={openStoredToast}
          onClose={() => setOpenStoredToast(false)}
          message="Users have been updated from Business Central"
        />
      )}
      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message={`ERROR: ${error}`}
        />
      )}
    </>
  );
}
