import React from 'react';

export default function FillBarSolid(props) {
    const { maxValue, title, value, color } = props;
    const width = Math.max(Math.min((value/maxValue)*100, 100), 0);
    
    return (
      <div className="fill-bar">
        <div className="fill-bar__details">
          <p>{title}</p>
          <p>
            {maxValue && maxValue > 0 ? (
              <>{value.toLocaleString()} ({Math.round(value/maxValue * 100)}%)</>
            ) : (
              `${value.toLocaleString()}%`
            )}
          </p>
        </div>
        <div
          className={ color === 'green'
            ? "fill-bar__fill fill-bar__fill--green--light"
            : 'fill-bar__fill fill-bar__fill--blue'
        }
          
          style={{
            width: !isNaN(width)
              ? `${width}%`
              : '0%'
          }}
        />
      </div>
    );
  };
  