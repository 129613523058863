import { createSlice } from '@reduxjs/toolkit';
import { editUser, getCurrentUserData, getEmailTemplatesByUserId, getUsers, sendEmail } from 'store/user/thunk';

const initialState = {
  userRole: '',
  user: null,
  userLoading: false,
  userError: '',
  userEditStatus: '',
  emailTemplates: [],
  templatesLoading: false,
  templatesError: '',
  sendEmailStatus: '',
  sendEmailError: '',
  emailLoading: false,

  users: {
    allUsers: [],
    loading: false,
    error: '',
  },

  resetPassword: {
    email: '',
  }
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserRole (state, { payload }) {
      state.userRole = payload;
    },
    setUser (state, { payload }) {
      state.user = payload;
    },
    setEditUserStatus (state, { payload }) {
      state.userEditStatus = payload;
    },
    setEmailError (state, { payload }) {
      state.sendEmailError = payload;
    },
    setEmailStatus (state, { payload }) {
      state.sendEmailStatus = payload;
    },
    setResetEmail (state, { payload }) {
      state.resetPassword.email = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmailTemplatesByUserId.pending, (state) => {
      state.templatesError = '';
      state.templatesLoading = true;
    });
    builder.addCase(getEmailTemplatesByUserId.fulfilled, (state, { payload }) => {
      state.emailTemplates = payload;
      state.templatesError = '';
      state.templatesLoading = false;
    });
    builder.addCase(getEmailTemplatesByUserId.rejected, (state, action) => {
      state.templatesError = action.error.message || 'Error getting templates.';
      state.templatesLoading = false;
    });
    builder.addCase(sendEmail.pending, (state) => {
      state.sendEmailStatus = 'pending';
      state.emailLoading = true;
      state.sendEmailError = '';
    });
    builder.addCase(sendEmail.fulfilled, (state, { payload }) => {
      if (Number(payload?.status_code) > 399) {
        state.sendEmailError = 'Error sending email. Try again later.';
      } else {
        state.sendEmailError = '';
      }
      state.sendEmailStatus = 'fulfilled';
      state.emailLoading = false;
    });
    builder.addCase(sendEmail.rejected, (state, action) => {
      state.sendEmailStatus = 'rejected';
      state.sendEmailError = action.error.message || 'Error sending email. Try again later.';
      state.emailLoading = false;
    });
    builder.addCase(getCurrentUserData.pending, (state) => {
      state.userError = '';
      state.userLoading = true;
    });
    builder.addCase(getCurrentUserData.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.userError = '';
      state.userLoading = false;
    });
    builder.addCase(getCurrentUserData.rejected, (state, action) => {
      state.userError = action.error.message || 'Error getting user.';
      state.userLoading = false;
    });
    builder.addCase(editUser.pending, (state) => {
      state.userError = '';
      state.userLoading = true;
      state.userEditStatus = 'pending';
    });
    builder.addCase(editUser.fulfilled, (state) => {
      state.userError = '';
      state.userLoading = false;
      state.userEditStatus = 'fulfilled';
    });
    builder.addCase(editUser.rejected, (state, action) => {
      state.userError = action.error.message || 'Error getting user.';
      state.userLoading = false;
      state.userEditStatus = 'rejected';
    });

    builder.addCase(getUsers.pending, (state) => {
      state.users.error = '';
      state.users.loading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.users.error = '';
      state.users.loading = false;
      state.users.allUsers = payload;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.users.error = 'Error getting users.';
      state.users.loading = false;
    });
  },
});

export const {
  setUserRole,
  setUser,
  setEmailError,
  setEmailStatus,
  setEditUserStatus,
  setResetEmail,
} = userSlice.actions;

export default userSlice.reducer;