import "../../css/modal.scss";
import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import CustomDialog from "./custom-dialog";
import API from "API";
import { ProgressSpinnerBlue } from 'components/progress/progress-spinner-blue';

export default function EditApplicantRecruiter(props) {
  const { applicant, getApplicant, handleClose, open, setOpenToast } = props;

  const token = JSON.parse(localStorage.getItem("token"));

  const [assignedRecruiter, setAssignedRecruiter] = useState(applicant.application?.recruiter_data
    ? applicant.application?.recruiter_data.id
    : null)
  const [contractRecruiters, setContractRecruiters] = useState([]);
  const [contractRecruitersLoading, setContractRecruitersLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (applicant.application?.contract_id) {
      getAssignedRecruiters(applicant.application.contract_id);
    }
  }, []);

  const getAssignedRecruiters = (contractId) => {
  setContractRecruitersLoading(true);
  fetch(`${API.endpointURL}/api/staffing/contracts/get_recruiters?contract_id=${contractId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => response.json())
    .then((json) => {
      if (json.success === false)  {
        setContractRecruiters([]);
        setContractRecruitersLoading(false)
      } else {
        setContractRecruiters(json);
        setContractRecruitersLoading(false);
      }
    })
    .catch(() => {
      setContractRecruitersLoading(false);
      setContractRecruiters([]);
    });
  };

  const updateApplicantRecruiter = () => {
    fetch(`${API.endpointURL}/api/staffing/applicants/assign_recruiter`, {
      method: "POST",  
      body: JSON.stringify({
        applicant_id: applicant.application.id,
        recruiter_id: assignedRecruiter,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          setError(json.error_key);
        } else {
          setError(null);
          getApplicant();
          handleClose();
          setOpenToast(true);
        }
      });
  };

  const editRecruiterForm = () => {
    let options = [];
    contractRecruiters.map((recruiter) => {
      options.push(
        <MenuItem value={recruiter.id} key={recruiter.id}>
          {recruiter.first_name} {recruiter.last_name}
        </MenuItem>
      );
    });
    if (!options.length) {
      options.push(
        <MenuItem value={'none'} disabled key={'none'}>
          {contractRecruitersLoading ? 'Loading...' : 'No Recruiters for this Contract'}
        </MenuItem>
      )
    }
    return (
      <React.Fragment key="edit-user-detail-form">
        <div style={{ textAlign: "left" }} className="form-row-label">
          Recruiter
          {contractRecruitersLoading && (
            <div className="form-row-spinner">
              <ProgressSpinnerBlue />
            </div>
          )}
        </div>
        <FormControl fullWidth>
          <Select
            id="edit-user-detail"
            key="edit-user-detail"
            displayEmpty
            onChange={(event) => {
              setAssignedRecruiter(event.target.value);
            }}
            sx={{
              backgroundColor: "#EFEFEF",
              border: 0,
              borderRadius: "8px",
              fontFamily: "Korolev Medium",
              textAlign: "left",
            }}
            value={assignedRecruiter}
          >
            {options}
          </Select>
        </FormControl>
        {error && <div className="add-user-error">{error}</div>}
        <div className="button-container">
          <Button
            variant="contained"
            className="add-user-button"
            sx={{
              backgroundColor: "#556155",
              color: "#EFEFEF",
              fontSize: "18px",
              width: "175px",
              "&:hover": { backgroundColor: "#a5af96", color: "#3e453d" },
            }}
            onClick={() => updateApplicantRecruiter()}
          >
            Update
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <CustomDialog
      dialogContent={editRecruiterForm()}
      onClose={handleClose}
      open={open}
      title={"UPDATE"}
      titleHighlight={"RECRUITER"}
    />
  );
}
