import React, { useEffect, useState } from 'react';
import NewBaseModal from 'components/modal/new-base-modal';
import API from 'API';

import 'css/connection.scss';
import { SalesConnectionsModalOne } from 'components/modal/sales-connections-modal-one';
import { SalesConnectionsModalThree } from 'components/modal/sales-connections-modal-three';
import { SalesConnectionsModalTwo } from 'components/modal/sales-connections-modal-two';
import { SalesConnectionsModalFour } from 'components/modal/sales-connections-modal-four';
import { SalesConnectionsModalFive } from 'components/modal/sales-connections-modal-five';
import Toast from 'components/toast/toast';
import { SalesConnectionsModalSix } from 'components/modal/sales-connections-modal-six';
import { SalesConnectionsModalSeven } from 'components/modal/sales-connections-modal-seven';
import { useAppDispatch } from 'hooks/reduxHooks';
import { setIsLoadedAnalytics } from 'store/sales/slice';

const initialTags = [123213, 3453245, 76575, 1543543, 13, 5345555, 11111333];
const initialConnectionForm = {
  full_name: '',
  phone_number: '',
  email: '',
  address: '',
  city: '',
  state: '',
  zip_code: '',
  note_body: '',
  title: ''
};

const initialCompanyConnectionForm = {
  full_name: '',
  phone_number: '',
  email: '',
  address: '',
  city: '',
  state: '',
  zip_code: '',
  personal_connection_id: '',
  note_body: '',
};

export const SalesConnectionCreate = ({
  handleClose,
  open,
  creatingStep,
  sale,
  getSale,
  connection,
  getConnection,
  getConnections,
  title,
  project,
  getProject,
}) => {
  const [openToast, setOpenToast] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [connectionType, setConnectionType] = useState('PERSONAL')
  const [step, setStep] = useState(creatingStep);
  const [leftColumn, setLeftColumn] = useState([]);
  const [leftPersonalColumn, setLeftPersonalColumn] = useState([]);
  const [rightColumn, setRightColumn] = useState([]);
  const [rightPersonalColumn, setRightPersonalColumn] = useState([]);
  const [rightTopColumn, setRightTopColumn] = useState('');
  const [selectedRightTopColumn, setSelectedRightTopColumn] = useState('');
  const [selectedLeftTags, setSelectedLeftTags] = useState([]);
  const [selectedLeftPersonalTags, setSelectedLeftPersonalTags] = useState([]);
  const [selectedRightTags, setSelectedRightTags] = useState([]);
  const [selectedRightPersonalTags, setSelectedRightPersonalTags] = useState([]);
  const [connectionForm, setConnectionForm] = useState(initialConnectionForm);
  const [companyConnection, setCompanyConnection] = useState(initialCompanyConnectionForm);
  const [isCreating, setIsCreating] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCompetitor, setIsCompetitor] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (creatingStep === 6) {
      setIsCreating(false);
      setIsAdding(true);
    } else {
      setIsCreating(true);
      setIsAdding(false);
    }
  }, []);

  const token = JSON.parse(localStorage.getItem("token"));

  const handleSelectLeft = (value) => {
    if (selectedLeftTags.includes(value)) {
      setSelectedLeftTags(selectedLeftTags.filter(item => item !== value));
    } else {
      setSelectedLeftTags([...selectedLeftTags, value].sort());
    }
  };
  const handleSelectPersonalLeft = (value) => {
    if (selectedLeftPersonalTags.includes(value)) {
      setSelectedLeftPersonalTags(selectedLeftPersonalTags.filter(item => item !== value));
    } else {
      setSelectedLeftPersonalTags([...selectedLeftPersonalTags, value].sort());
    }
  };
  const handleSelectRight = (value) => {
    if (selectedRightTags.includes(value)) {
      setSelectedRightTags(selectedRightTags.filter(item => item !== value));
    } else {
      setSelectedRightTags([...selectedRightTags, value].sort());
    }
  };
  const handleSelectPersonalRight = (value) => {
    if (selectedRightPersonalTags.includes(value)) {
      setSelectedRightPersonalTags(selectedRightPersonalTags.filter(item => item !== value));
    } else {
      setSelectedRightPersonalTags([...selectedRightPersonalTags, value].sort());
    }
  };
  const handleMoveRight = () => {
    setRightColumn([...rightColumn, ...selectedLeftTags].sort());
    setLeftColumn(leftColumn.filter(tag => !selectedLeftTags.includes(tag)).sort());
    setSelectedLeftTags([]);
  };
  const handleMovePersonalRight = () => {
    setRightPersonalColumn([...rightPersonalColumn, ...selectedLeftPersonalTags].sort());
    setLeftPersonalColumn(leftPersonalColumn.filter(tag => !selectedLeftPersonalTags.includes(tag)).sort());
    setSelectedLeftPersonalTags([]);
  };
  const handleMoveLeft = () => {
    setLeftColumn([...leftColumn, ...selectedRightTags].sort());
    setRightColumn(rightColumn.filter(tag => !selectedRightTags.includes(tag)).sort());
    setSelectedRightTags([]);
  };
  const handleMovePersonalLeft = () => {
    setLeftPersonalColumn([...leftPersonalColumn, ...selectedRightPersonalTags].sort());
    setRightPersonalColumn(rightPersonalColumn.filter(tag => !selectedRightPersonalTags.includes(tag)).sort());
    setSelectedRightPersonalTags([]);
  };
  const handleMoveTop = () => {
    if (rightTopColumn) {
      setLeftColumn([
        ...leftColumn.filter(tag => tag !== selectedLeftTags[0]),
        rightTopColumn
      ].sort());
    } else {
      setLeftColumn(leftColumn.filter(tag => tag !== selectedLeftTags[0]).sort())
    }
    setRightTopColumn(selectedLeftTags[0]);
    setSelectedLeftTags(selectedLeftTags.slice(1));
    setSelectedRightTopColumn('');
  };
  const handleMoveBottom = () => {
    setLeftColumn([...leftColumn, rightTopColumn].sort());
    setRightTopColumn('');
    setSelectedRightTopColumn('');
  };

  const handleConnectionCreate = (type = 'company') => {
    setLoading(true);
    let form = {};
    if (type === "personal") {
      form = connectionForm;
    } else {
      form = companyConnection;
    }
    try {
      let requestError = false;
      fetch(`${API.endpointURL}/api/connections`, {
        method: "POST",
        body: JSON.stringify({
          full_name: form.full_name,
          phone_number: form.phone_number,
          email: form.email,
          address: form.address,
          city: form.city,
          state: form.state,
          zip_code: form.zip_code,
          title: form.title,
          connection_type: step === 2 ? 'personal' : connectionType.toLowerCase(),
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (!res.ok) {
            requestError = true;
          }
          return res.json();
        })
        .then((res) => {
          setOpenToast(true);
          dispatch(setIsLoadedAnalytics(false));
          if (!requestError) {
            if (connection) {
              if (step === 4) {
                // here is CREATING and auto CONNECT to connection on Connection-portal page
                if (companyConnection.note_body) {
                  connectConnectionNote(res.id, false, companyConnection.note_body);
                } else {
                  connectConnection(res.id);
                }
                if (companyConnection.personal_connection_id) {
                  if (connectionForm.note_body) {
                    connectConnectionNote(res.id, true, connectionForm.note_body)
                  } else {
                    connectConnection(res.id, true);
                  }
                } else {
                  setTimeout(() => getConnection(), 500);
                }
              } else if (connectionType === 'PERSONAL') {
                if (connectionForm.note_body) {
                  connectConnectionNote(res.id, false, connectionForm.note_body)
                } else {
                  connectConnection(res.id);
                }
              } else {
                setStep(4);
                setCompanyConnection({
                  ...companyConnection,
                  personal_connection_id: res.id,
                });
                setLoading(false);
              }
            } else if (sale || project) {
              if (step === 4) {
                if (companyConnection.note_body) {
                  connectSaleOrProjectNote(res.id, companyConnection.note_body);
                } else {
                  linkSaleOrProject(res.id);
                }
                if (companyConnection.personal_connection_id) {
                  if (connectionForm.note_body) {
                    connectConnectionNote(res.id, true, connectionForm.note_body)
                  } else {
                    connectConnection(res.id, true);
                  }
                }
              } else if (connectionType === 'PERSONAL') {
                if (connectionForm.note_body) {
                  connectSaleOrProjectNote(res.id, connectionForm.note_body)
                } else {
                  linkSaleOrProject(res.id);
                }
              } else {
                setStep(4);
                setCompanyConnection({
                  ...companyConnection,
                  personal_connection_id: res.id,
                });
                setLoading(false);
              }
            } else {
              if (connectionType === 'PERSONAL') {
                setLoading(false);
                // here is simple Personal CREATING
                getConnections();
                handleClose();
              } else if (step === 4) {
                // here is CREATING company/competitor connection and adding primary if selected
                if (companyConnection.personal_connection_id) {
                  connectConnection(res.id, true);
                } else {
                  setLoading(false);
                }
                handleClose();
                setTimeout(() => getConnections(), 500);
              } else {
                setStep(4);
                setCompanyConnection({
                  ...companyConnection,
                  personal_connection_id: res.id,
                });
                setLoading(false);
              }
            }
          } else {
            setServerError(true);
            setLoading(false);
          }
        });
    } catch (e) {
      setOpenToast(true);
      setServerError(true);
      setLoading(false);
    }
  };

  const connectConnection = (id, isPrimary = false, noteId) => {
    setLoading(true);
    let personalKey = '';
    let type = 'personal';
    let childType = 'personal-connection';
    let connectionID = 0;
    let personalID = 0;
    if (isPrimary) {
      personalKey = 'personal_connection_id';
      type = connectionType.toLowerCase();
      connectionID = id;
      personalID = companyConnection.personal_connection_id;
    } else {
      personalKey = connection.connection_type === connectionType.toLowerCase()
        ? 'linked_' + connection.connection_type + '_connection_id'
        : connectionType.toLowerCase() + '_connection_id';
      type = connection.connection_type;
      connectionID = connection.id;
      personalID = id;
      if (connectionType.toLowerCase() !== 'personal') {
        childType = connectionType.toLowerCase();
      }
    }

    const requestBody = {
      [personalKey]: personalID,
      is_primary: isPrimary,
    };

    if (noteId) {
      requestBody.note_id = noteId;
    }

    if (isCompetitor && !isPrimary) {
      requestBody.is_competitor = true;
    }

    try {
      let requestError = false;
      fetch(`${API.endpointURL}/api/connections/${type}`
        + `-connections/${connectionID}/attach-${childType}`, {
        method: "PATCH",
        body: JSON.stringify(requestBody),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (!res.ok) {
            requestError = true;
          }
          return res.json();
        })
        .then(() => {
          setOpenToast(true);
          if (!requestError) {
            handleClose();
            if (connection) {
              getConnection();
            }
            if (sale) {
              getSale();
            }
            if (project) {
              getProject();
            }
            setServerError(false);
            setLoading(false);
          } else {
            setServerError(true);
          }
        });
    } catch (e) {
      setOpenToast(true);
      setServerError(true);
      setLoading(false);
    }
  };

  const linkSaleOrProject = (id, noteId) => {
    setLoading(true);
    try {
      let requestError = false;
      const requestBody = {
        connection_id: id,
        is_primary: false,
      }
      if (sale) {
        requestBody.stage = sale.stage;
      }
      if (isCompetitor && connectionType.toLowerCase() === 'company') {
        requestBody.is_competitor = true;
      }
      if (noteId) {
        requestBody.note_id = noteId;
      }
      fetch(`${API.endpointURL}/api/${
        sale ? 'sales' : 'projects'
      }/${sale ? sale.id : project?.id}/connections`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
          Accept: 'application/json'
        },
      })
        .then((res) => {
          if (!res.ok) {
            requestError = true;
          }
          return res.json();
        })
        .then(() => {
          setOpenToast(true);
          setLoading(false);
          if (requestError) {
            setServerError(true);
          } else {
            handleClose();
            setServerError(false);
            if (sale) {
              getSale();
            } else {
              getProject();
            }
          }
        });
    } catch (e) {
      setOpenToast(true);
      setServerError(true);
      setLoading(false);
    }
  };

  const createNote = (note) => {
    setLoading(true);
    let requestError = false;
    return fetch(`${API.endpointURL}/api/notes`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
        Accept: 'application/json',
      },
      body: JSON.stringify({
        body: note,
      })
    })
      .then((res) => {
        if (!res.ok) {
          requestError = true;
        }
        return res.json();
      })
      .then((res) => {
        if (!requestError) {
          return res.id;
        } else {
          setLoading(false);
          setServerError(true);
        }
      })
      .catch(() =>
        setLoading(false)
      );
  };

  const connectSaleOrProjectNote = async (connectionId, note) => {
    const noteID = await createNote(note);
    if (noteID) {
      await linkSaleOrProject(connectionId, noteID);
    }
  };

  const connectConnectionNote = async (connectionId, isPrimary, note) => {
    const noteID = await createNote(note);
    if (noteID) {
      await connectConnection(connectionId, isPrimary, noteID);
    }
  };

  useEffect(() => {
    setLeftColumn(initialTags.sort())
    setLeftPersonalColumn(initialTags.sort())
  }, []);

  const chooseScreen = () => {
    switch (step) {
      case (2):
        return (
          <SalesConnectionsModalTwo
            setStep={setStep}
            setConnectionForm={setConnectionForm}
            connectionType={connectionType}
            connectionForm={connectionForm}
            handleCreate={handleConnectionCreate}
            loading={loading}
          />
        );
      case (3):
        return (
          <SalesConnectionsModalThree
            connectionType={connectionType}
            setStep={setStep}
            leftColumn={leftPersonalColumn}
            rightColumn={rightPersonalColumn}
            selectedRightTags={selectedRightPersonalTags}
            selectedLeftTags={selectedLeftPersonalTags}
            handleSelectLeft={handleSelectPersonalLeft}
            handleSelectRight={handleSelectPersonalRight}
            handleMoveRight={handleMovePersonalRight}
            handleMoveLeft={handleMovePersonalLeft}
          />
        );
      case (4):
        return (
          <SalesConnectionsModalFour
            setStep={setStep}
            setCompanyConnection={setCompanyConnection}
            connectionType={connectionType}
            companyConnection={companyConnection}
            handleCreate={handleConnectionCreate}
            loading={loading}
            setIsCompetitor={setIsCompetitor}
            isCompetitor={isCompetitor}
          />
        )
      case (5):
        return (
          <SalesConnectionsModalFive
            connectionType={connectionType}
            setStep={setStep}
            leftColumn={leftColumn}
            rightColumn={rightColumn}
            selectedRightTags={selectedRightTags}
            selectedLeftTags={selectedLeftTags}
            handleSelectLeft={handleSelectLeft}
            handleSelectRight={handleSelectRight}
            handleMoveRight={handleMoveRight}
            handleMoveLeft={handleMoveLeft}
            topColumn={rightTopColumn}
            handleMoveTop={handleMoveTop}
            handleMoveBottom={handleMoveBottom}
            selectedRightTopColumn={selectedRightTopColumn}
            setSelectedRightTopColumn={setSelectedRightTopColumn}
          />
        )
      case (6):
        return (
          <SalesConnectionsModalSix
            setStep={setStep}
            setIsAdding={setIsAdding}
            isAdding={isAdding}
            sale={sale}
            project={project}
          />
        )
      case (7):
        return (
          <SalesConnectionsModalSeven
            connectionType={connectionType}
            setStep={setStep}
            sale={sale}
            getSale={getSale}
            setOpenToast={setOpenToast}
            handleClose={handleClose}
            connection={connection}
            getConnection={getConnection}
            setIsCompetitor={setIsCompetitor}
            isCompetitor={isCompetitor}
            project={project}
            getProject={getProject}
          />
        )
      default:
        return (
          <SalesConnectionsModalOne
            connectionType={connectionType}
            setConnectionType={setConnectionType}
            setStep={setStep}
            isCreating={isCreating}
            isAdding={isAdding}
          />
        );
    }
  }
  return (
    <>
      <NewBaseModal
        dialogContent={chooseScreen()}
        onClose={handleClose}
        open={open}
        title={isAdding ? (title ? title : "LINK CONNECTION TO THE SALE") : "CREATE A NEW CONNECTION"}
        colorPalette="navy"
      />
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message={serverError
            ? 'Something went wrong, try again later...'
            : 'New Connection Successfully Created'
          }
        />
      )}
    </>
  );
};