import { createAsyncThunk } from '@reduxjs/toolkit';
import { contracts } from 'services/contracts';
export const getContracts = createAsyncThunk(
  'contracts',
  async ({ currentPage }, { rejectWithValue }) => {
    try {
      return await contracts.getAllContracts(currentPage);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getContractsByStatus = createAsyncThunk(
  'contractsByStatus',
  async ({ contractsStatus, currentPage }, { rejectWithValue }) => {
    try {
      return await contracts.getContractsByStatus(contractsStatus, currentPage);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getContractFilters = createAsyncThunk(
  'contractFilters',
  async (_, { rejectWithValue }) => {
    try {
      return await contracts.getContractFilters();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getContractTemplates = createAsyncThunk(
  'contractTemplates',
  async ({ contractId }, { rejectWithValue }) => {
    try {
      return await contracts.getContractTemplates(contractId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editContractTemplate = createAsyncThunk(
  'editContractTemplate',
  async ({ templateId, body }, { rejectWithValue }) => {
    try {
      return await contracts.editContractTemplate(templateId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getContractById = createAsyncThunk(
  'contractById',
  async ({ contractId }, { rejectWithValue }) => {
    try {
      return await contracts.getContractById(contractId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteContractById = createAsyncThunk(
  'deleteContractById',
  async ({ contractId }, { rejectWithValue }) => {
    try {
      return await contracts.deleteContractById(contractId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editContractById = createAsyncThunk(
  'editContractById',
  async ({ contractId, body }, { rejectWithValue }) => {
    try {
      return await contracts.editContractById(contractId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createContract = createAsyncThunk(
  'createContract',
  async ({ _, body }, { rejectWithValue }) => {
    try {
      return await contracts.createContract(_, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCustomerContracts = createAsyncThunk(
  'customerContracts',
  async ({ vendorId }, { rejectWithValue }) => {
    try {
      return await contracts.getCustomerContracts(vendorId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getAllContractMetrics = createAsyncThunk(
  'contractsMetrics',
  async (_, { rejectWithValue }) => {
    try {
      return await contracts.getAllContractMetrics();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getContractMetrics = createAsyncThunk(
  'contractMetrics',
  async ({ contractId }, { rejectWithValue }) => {
    try {
      return await contracts.getContractMetrics(contractId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getContractQuestions = createAsyncThunk(
  'contractQuestions',
  async ({ contractId }, { rejectWithValue }) => {
    try {
      return await contracts.getContractQuestions(contractId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createContractQuestion = createAsyncThunk(
  'createContractQuestion',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await contracts.createContractQuestion(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateContractQuestion = createAsyncThunk(
  'updateContractQuestion',
  async ({ questionId, body }, { rejectWithValue }) => {
    try {
      return await contracts.updateContractQuestion(questionId, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteContractQuestion = createAsyncThunk(
  'deleteContractQuestion',
  async ({ questionId }, { rejectWithValue }) => {
    try {
      return await contracts.deleteContractQuestion(questionId);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateContractQuestionsOrder = createAsyncThunk(
  'updateContractQuestionsOrder',
  async ({ body }, { rejectWithValue }) => {
    try {
      return await contracts.updateContractQuestionsOrder(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
