import axios from 'axios';
import API from "API";

export const axiosService = axios.create({
  baseURL: API.endpointURL,
});

axiosService.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem("token"));
  
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.Accept = 'application/json';
  
  return config;
});

axiosService.CancelToken = axios.CancelToken;
