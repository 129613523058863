import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const FilterNestedDropdown = ({ buttons, classValue, label }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <button
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className={classValue}
          onClick={handleClick}
        >
          {label}
          <i
            className={
              open
                ? 'fa-solid fa-angle-left arrow-icon'
                : 'fa-solid fa-angle-right arrow-icon'
            }
          />
        </button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{ ".MuiList-root": {
          border: 0
          }}}
        PaperProps={{
          elevation: 0,
          sx: {
            overflowY: 'auto',
            overflowX: 'hidden',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            backgroundColor: '#212A1C',
            border: '1px solid #EFEFEF',
            width: '370px'
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {buttons.map((button) => (
          <MenuItem key={`${button.key}-${button.label}`} sx={{ padding: 0 }}>
            <button
              disabled={button.disabled || false}
              className={button.className}
              onClick={() => { button.onClick(); handleClose(); }}
            >
              {button.label}
            </button>
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}