import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import API from "API";
import AppBar from "components/app-bar/updated-app-bar-component";
import { uploadTypeToFile } from "shared/applicant-data";
import { useParams } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SalesFileViewer = () => {

  const [file, setFile] = useState(null); //object
  const [fileName, setFileName] = useState(null); // string
  const [error, setError] = useState(false); // error
  const [numberOfPages, setNumberOfPages] = useState(0); // int
  const [blobURL, setBlobURL] = useState(null); // string
  const [ext, setExt] = useState(null); // string
  let {fileID} = useParams();

  function getFileExtension(location) {
    // Split the location by '.' and return the last part
    const parts = location.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : null;
  }

  useEffect(() => {
    API.getSaleFile(fileID)
        .then(
        (success) => {
          let file = success['data'];

          setFile(file);
          setFileName(file.display_name);
          setExt(getFileExtension(file.location));
          
          API.createBlobFromResumeFileName(file.location).then(
            (success) => {
              setBlobURL(success);
            }
          );
        }
    );
  }, []);

  const downloadFile = () => {
    let downloadName = fileName;
    
    if (!downloadName) {
      downloadName = file.type + `-${file.id}`;
    }

    const link = document.createElement("a");
    link.href = blobURL;
    link.setAttribute("download", downloadName);
    document.body.appendChild(link);
    link.click();
  };

  const handleLoadSuccess = (pdf) => {
    setNumberOfPages(pdf.numPages);
  }

  const renderPages = () => {
    let pages = [];

    const pageParams = {
      renderAnnotationLayer: false,
      renderInteractiveForms: false,
      customTextRenderer: () => (<div></div>)
    }

    for(let i = 0; i < numberOfPages; i++) {
      const frozenIndex = i;
      pages.push(
        <Page key={frozenIndex + "_f"} pageNumber={frozenIndex+1} {...pageParams} />
      );
    }
    return pages;
  }

  const renderSidebar = () => {
    if(file) {
      return (
        <div className="sidebar__pocket-box">
          <div className="sidebar__pocket">
            <div className="row">
            <h2>{file.display_name ? file.display_name : `${uploadTypeToFile[file.type]} v${file.version}`}</h2>
            <b className="badge" style={{marginLeft: "5px"}}>{file.display_name.split(".").pop()}</b>
            </div>
            <p>Created {new Date(file.created_at).toLocaleString()}</p>
            {/* <pre>{JSON.stringify(file, null, 4)}</pre> */}

            <div className="divider"></div>
            <div className="file-inputs">
              <div className="input">
                <input
                  type="text"
                  id="displayName"
                  placeholder="Set file name on download..."
                  onChange={(e) =>
                    setFileName(
                      e.target.value === "" ? file.display_name : e.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (<div></div>)
  }

  const renderViewer = () => {
    if(file && blobURL) {
      if(ext === 'pdf') {
        return (
          <div className="pdf-viewer">
            {error ? (
              <div className="pdf-error">Only PDFs can be viewed in this window. Please download to view your file.</div>
            ) : (
              <div>
              <Document
                key={blobURL}
                file={blobURL}
                onLoadError={(e) => {setError(e);console.error(e);console.error(`${API.endpointURL}/apicloud_location?location=${file.location}`)}}
                onLoadSuccess={(pdf) => handleLoadSuccess(pdf)}
                className={"pdf"}
              >
                {renderPages()}
              </Document>
              </div>
            )}
          </div>
        );
      } else if(ext === 'docx') {
        return (
          <div>DOCX is not supported yet. Download to view this file.</div>
        );
      } else if(ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
        return (
          <div className="image-viewer">
            <img
              style={{maxHeight: "calc(100vh - 80px)"}}
              src={`${API.endpointURL}/api/cloud_location?location=${file.location}`}
              className="image-viewer__image"
              alt="viewer" />
          </div>
        );
      }
      return (
        <div>Filetype is not previewable. Download to view this file.</div>
      );
    }

    return (
      <div className="loader" style={{height: "calc(100vh - 150px)"}}>
        <div className="loading-spinner">
          <div className="loading-spinner__fill"/>
          <div className="loading-spinner__bg"/>
        </div>
      </div>
    );
  }

  return (
    <div>
      <AppBar
        colorPalette="navy"
        menu={true}
        title="Sale Document Viewer"
      />
      <div className="sales-page">
        <div className="sidebar">
          {renderSidebar()}
          <div
            className={"sidebar__item"}
            style={{backgroundColor: 'rgba(50, 168, 82, 0.1)'}}
            onClick={() => downloadFile()}
          >
            <p>Download</p>
            <i className="fa-solid fa-angle-right" style={{marginLeft: "auto"}}/>
          </div>
        </div>
        <div className="page-content" style={{maxHeight: "calc(100% - 150px)"}}>
          {renderViewer()}
        </div>
      </div>
    </div>
  );
};

export default SalesFileViewer;