import React, { useEffect, useState } from 'react';
import { Check } from '@mui/icons-material';
import {
  Divider,
  Tooltip,
  Typography,
} from '@mui/material';
import fileOutlineIcon from 'assets/file_outline.svg';
import Toast from '../toast/toast';
import {
  applicantFilesList,
  fileToUploadType,
  uploadTypeToFile,
} from 'shared/applicant-data';
import { approveFiles, getApplicantFiles } from 'store/files/thunk';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { applicantFilesSelector, approveFilesStatusSelector } from 'store/files/selectors';
import { setApproveStatus } from 'store/files/slice';
import { getApplicant } from 'store/applicants/thunk';

export default function UploadFiles(props) {
  const {
    applicant,
    setView,
    showDeprecated,
    view,
    templates,
    currentUser,
    userRole,
  } = props;

  const [openToast, setOpenToast] = useState(false);
  const [latestFiles, setLatestFiles] = useState({});
  const [hasDeprecatedFiles, setHasDeprecatedFiles] = useState(false);

  const dispatch = useAppDispatch();
  const applicantFiles = useAppSelector(applicantFilesSelector);
  const approveStatus = useAppSelector(approveFilesStatusSelector);

  useEffect(() => {
    if (approveStatus === 'fulfilled') {
      dispatch(getApplicantFiles({ id: applicant?.id }));
      dispatch(setApproveStatus(''));
      dispatch(getApplicant({ applicantId: applicant?.id }));
    }
  }, [approveStatus]);

  useEffect(() => {
    let updatedLatestFiles = {};
    let deprecated = false;
    if (applicantFiles) {
      applicantFiles.forEach((file) => {
        const fileType = uploadTypeToFile[file.type];
        
        if (!updatedLatestFiles[fileType]) {
          updatedLatestFiles[fileType] = file;
        } else if (file.version >= updatedLatestFiles[fileType].version) {
          updatedLatestFiles[fileType] = file;
        }
        
        if (!deprecated) {
          if (applicantFilesList['Legacy Information'].includes(uploadTypeToFile[file.type])) {
            deprecated = true;
          }
        }
      });
    }
    setLatestFiles(updatedLatestFiles);
    setHasDeprecatedFiles(deprecated);
  }, [applicantFiles]);
  
  const determineFileStatus = (fileType, template = null) => {
    let status = <div></div>;
    const latestFile = latestFiles[fileType];
    if (latestFile && !latestFile.approved) {
      status = (
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <span
            style={{
              color: '#DFB727',
              fontFamily: 'Korolev Medium',
              fontSize: '1rem',
              textTransform: 'capitalize',
            }}
          >
            Ready for Review
          </span>
          {currentUser && currentUser.role === 'admin' && (
            <Tooltip title="Approve">
              <Check
                sx={{
                  height: '20px',
                  color: '#EFEFEF',
                  paddingBottom: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#62AB37',
                  },
                }}
                onClick={() => {
                  dispatch(approveFiles({ id: applicant?.id, type: latestFile.type }))
                }}
              />
            </Tooltip>
          )}
        </div>
      );
    } else if (latestFile && latestFile.approved) {
      status = (
        <div
          style={{
            color: '#62AB37',
            fontFamily: 'Korolev Medium',
            fontSize: '1rem',
            textTransform: 'capitalize',
          }}
        >
          Approved
        </div>
      );
    }
    let fileStatus = (
      latestFile ? (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Typography
            sx={{
              fontFamily: 'Korolev Medium',
              fontSize: '1rem',
              textTransform: 'capitalize',
            }}>
            {template !== null ? template.template_name : fileType}: {latestFile.display_name ? latestFile.display_name : `${uploadTypeToFile[latestFile.type]} v${latestFile.version}`}
          </Typography>
          {status}
        </div>
      ) : (
        <Typography
          sx={{
            fontFamily: 'Korolev Medium',
            fontSize: '1rem',
            textTransform: 'capitalize',
          }}>
          {template !== null ? template.template_name : fileType}
        </Typography>
      )
    );
    
    return fileStatus;
  };
  
  const buildFilesMenu = (category) => {
    let filesMenu = [];
    let files = applicantFilesList[category];
    
    files.forEach((file, fileIndex) => {
      let template = null;
      if (templates !== null) {
        template = templates?.find((template) => template.template_type === fileToUploadType[file]);
      }
      if (
        ((template == null || template.file_active === true) &&
          currentUser !== null) ||
        ((template == null || template.file_active === true) && (currentUser === null && (category === 'Template Information' || file === 'Resume')))
      ) {
        // This is temporary as we intend to have them stop using legacy information.
        if (category === 'Legacy Information' && !latestFiles[file]) {
          return;
        }
        
        if (file.includes('Interview') && userRole === 'applicant') {
          return;
        }
        if (userRole === 'applicant' && template && !template.is_file_public) {
          return;
        }
        if ( template && !template.active) {
          return;
        }
        filesMenu.push(
          <button
            key={fileIndex}
            onClick={() => {
              if (setView) {
                setView(file);
              }
            }}
            style={
              view === file ? {
                alignItems: 'start',
                background: 'linear-gradient(90deg,rgba(255, 255, 255, 0.2) 0%,rgba(255, 255, 255, 0) 100%)',
                border: 0,
                borderRadius: '6px',
                color: '#EFEFEF',
                display: 'flex',
                flexDirection: 'row',
                fontFamily: 'Korolev Medium',
                padding: '6px 8px',
                textAlign: 'left',
                width: '100%',
              } : {
                alignItems: 'start',
                backgroundColor: 'transparent',
                border: 0,
                color: '#EFEFEF',
                display: 'flex',
                flexDirection: 'row',
                fontFamily: 'Korolev Medium',
                padding: '6px 8px',
                textAlign: 'left',
                width: '100%',
              }
            }
          >
            <img src={fileOutlineIcon} alt="file-icon"
                 style={{ width: '13px', height: 'auto', marginRight: '10.25px' }}/>
            {determineFileStatus(file, template)}
          </button>,
        );
      }
    });
    
    return (
      <div style={{ marginTop: 1 }}>
        {filesMenu}
      </div>
    );
  };
  
  return (
    <>
      <div
        style={{
          color: '#EFEFEF',
          fontFamily: 'Korolev Medium',
          fontSize: '20px',
          marginTop: '20px',
          textAlign: 'left',
        }}
      >
        Applicant Documents
      </div>
      <Divider
        sx={{
          borderColor: '#EFEFEF80',
          marginBottom: '20px',
          marginTop: '15px',
        }}
      />
      {applicant.application.contract_id !== null && buildFilesMenu('Template Information')}
      {showDeprecated && hasDeprecatedFiles && currentUser && (
        <>
          <div style={{ fontFamily: 'Korolev Medium', fontSize: '20px', marginTop: '20px' }}>Deprecated</div>
          <Divider sx={{ borderColor: '#EFEFEF80', marginBottom: '20px', marginTop: '15px' }}/>
          {buildFilesMenu('Legacy Information')}
        </>
      )}
      {openToast && (
        <Toast
          open={openToast}
          onClose={() => setOpenToast(false)}
          message="File uploaded"
        />
      )}
    </>
  );
}
