import React, { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import "../../css/vendor-general-tab.scss";

import API from "API";

export default function VendorGeneralPageContent(props) {
  const { accountingUsers, associatedUsers, getVendor, user, vendor } = props;
  const token = JSON.parse(localStorage.getItem("token"));
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const [editRow1, setEditRow1] = useState(false);
  const [editRow2, setEditRow2] = useState(false);
  const [editRow3, setEditRow3] = useState(false);
  const accountingRole =
    loggedInUser.role === "accounting" || loggedInUser.role === "admin"
      ? true
      : false;
  const [accountManager, setAccountManager] = useState(
    vendor.account_manager_id ?? ""
  );
  const [remittance_address_1, setRemittanceAddress_1] = useState(
    vendor.remittance_address_1 ?? ""
  );
  const [remittance_address_2, setRemittanceAddress_2] = useState(
    vendor.remittance_address_2 ?? ""
  );
  const [company_address_1, setCompanyAddress_1] = useState(
    vendor.company_address_1 ?? ""
  );
  const [company_address_2, setCompanyAddress_2] = useState(
    vendor.company_address_2 ?? ""
  );
  const [companyName, setCompanyName] = useState(vendor.name ?? "");
  const [invoiceName, setInvoiceName] = useState(
    vendor.vendor_invoice_name ?? ""
  );
  const [remittanceCity, setRemittanceCity] = useState(
    vendor.remittance_city ?? ""
  );
  const [companyCity, setCompanyCity] = useState(vendor.company_city ?? "");
  const [paymentTerms, setPaymentTerms] = useState(vendor.payment_terms ?? "");
  const [remittanceState, setRemittanceState] = useState(
    vendor.remittance_state ?? ""
  );
  const [companyState, setCompanyState] = useState(vendor.company_state ?? "");
  const [pocId, setPocId] = useState(vendor.poc_id ?? "");
  const [remittanceZip, setRemittanceZip] = useState(
    vendor.remittance_zip_code ?? ""
  );
  const [companyZip, setCompanyZip] = useState(vendor.company_zip_code ?? "");
  const [remittanceCountry, setRemittanceCountry] = useState(
    vendor.remittance_country ?? ""
  );
  const [companyCountry, setCompanyCountry] = useState(
    vendor.company_country ?? ""
  );
  const [vendorPhone, setVendorPhone] = useState(vendor.phone ?? "");
  const [vendorEmail, setVendorEmail] = useState(vendor.email ?? "");
  const [vendorName, setVendorName] = useState(
    `${user.first_name} ${user.last_name}`
  );
  const [vendorFax, setVendorFax] = useState(vendor.fax ?? "");
  const [vendorWebsite, setVendorWebsite] = useState(vendor.website ?? "");
  const [verifiedVendor, setVerifiedVendor] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [init, SetInit] = useState({});

  const [isStaffingCustomer, setIsStaffingCustomer] = useState(vendor.is_staffing_customer)

  useEffect(() => {
    setAccountManager(vendor.account_manager_id ?? "");
    setRemittanceAddress_1(vendor.remittance_address_1 ?? "");
    setRemittanceAddress_2(vendor.remittance_address_2 ?? "");
    setCompanyAddress_1(vendor.company_address_1 ?? "");
    setCompanyAddress_2(vendor.company_address_2 ?? "");
    setCompanyName(vendor.name ?? "");
    setInvoiceName(vendor.vendor_invoice_name ?? "");
    setRemittanceCity(vendor.remittance_city ?? "");
    setCompanyCity(vendor.company_city ?? "");
    setPaymentTerms(vendor.payment_terms ?? "");
    setRemittanceState(vendor.remittance_state ?? "");
    setCompanyState(vendor.company_state ?? "");
    setPocId(vendor.poc_id ?? "");
    setRemittanceZip(vendor.remittance_zip_code ?? "");
    setCompanyZip(vendor.company_zip_code ?? "");
    setRemittanceCountry(vendor.remittance_country ?? "");
    setCompanyCountry(vendor.company_country ?? "");
    setVendorPhone(user.phone ?? "");
    setVendorName(`${user.first_name} ${user.last_name}`);
    setVendorEmail(user.email ?? "");
    setVendorFax(vendor.fax ?? "");
    setVendorWebsite(vendor.website ?? "");
    setIsStaffingCustomer(vendor.is_staffing_customer ?? false);
    getVerificationStatus();

    let timer;
    if (loading) {
      timer = setTimeout(() => {
        setLoading(false);
      }, "1000");
    }
    return () => clearTimeout(timer);
  }, [vendor, user]);

  useEffect(() => {
    let timer;
    if (submitted) {
      timer = setTimeout(() => {
        setSubmitted(false);
      }, "1000");
    }
    return () => clearTimeout(timer);
  });

  const generateVendorOptions = () => {
    const options = associatedUsers.map((user) => {
      return (
        <option key={user.id} value={user.id}>
          {user.first_name} {user.last_name}
        </option>
      );
    });
    return (
      <select
        className="vendor-input"
        name="vendor-users"
        value={pocId}
        onChange={(e) => setPocId(e.target.value)}
      >
        <option value={null}>Select the dropdown for options</option>
        {options}
      </select>
    );
  };

  const generateAccountManagerOptions = () => {
    const options = accountingUsers.map((user) => {
      return (
        <option key={user.id} value={user.id}>
          {user.first_name} {user.last_name}
        </option>
      );
    });
    return (
      <select
        className="vendor-input"
        name="account-manager-users"
        value={accountManager}
        onChange={(e) => setAccountManager(e.target.value)}
      >
        <option value={null}>Select the dropdown for options</option>
        {options}
      </select>
    );
  };

  const handlePocUserUpdate = () => {
    fetch(`${API.endpointURL}/api/poc_user?vendor_id=${vendor.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        name: companyName,
        vendor_invoice_name: invoiceName,
        payment_terms: paymentTerms,
        account_manager_id: accountManager,
      }),
    })
      .then((response) => {
        response.json();
        getVendor();
        setEditRow1(!editRow1);
        setLoading(true);
      })
      .catch((error) =>
        console.log(
          error,
          "An Error has occured during the posting of POC user data."
        )
      );
  };
  const handleRemittanceInfoUpdate = () => {
    fetch(
      `${API.endpointURL}/api/remittance_address?vendor_id=${vendor.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          remittance_address_1: remittance_address_1,
          remittance_address_2: remittance_address_2,
          remittance_city: remittanceCity,
          remittance_state: remittanceState,
          remittance_zip_code: remittanceZip,
          remittance_country: remittanceCountry,
        }),
      }
    )
      .then((response) => {
        response.json();
        getVendor();
        setEditRow2(!editRow2);
        setLoading(true);
      })
      .catch((error) =>
        console.log(
          error,
          "An Error has occured during the posting of Remittance Info."
        )
      );
  };

  const handleCompanyInfoUpdate = () => {
    fetch(`${API.endpointURL}/api/company_address?vendor_id=${vendor.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        company_address_1: company_address_1,
        company_address_2: company_address_2,
        company_city: companyCity,
        company_state: companyState,
        company_zip_code: companyZip,
        company_country: companyCountry,
      }),
    })
      .then((response) => {
        response.json();
        getVendor();
        setEditRow3(!editRow3);
        setLoading(true);
      })
      .catch((error) =>
        console.log(
          error,
          "An Error has occured during the posting of Company info"
        )
      );
  };

  const getVerificationStatus = () => {
    fetch(
      `${API.endpointURL}/api/general_verification?vendor_id=${vendor.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => setVerifiedVendor(res));
  };

  const handleVerification = (row) => {
    if (verifiedVendor[1099] == null) {
      fetch(
        `${API.endpointURL}/api/verify_vendor_general?vendor_id=${vendor.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            general_data_verified: false,
            invoice: null,
            remittance_address: null,
            phone: null,
            email: null,
            fax: null,
            payment_terms: null,
            1099: null,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => SetInit(res))
        .catch((error) => console.log(error));
    }

    switch (row) {
      case 1:
        fetch(`${API.endpointURL}/api/verify?vendor_id=${vendor.id}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            invoice_name: invoiceName ? true : null,
            payment_terms: paymentTerms ? true : null,
            fax: vendorFax ? true : null,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setSubmitted(true);
            setVerifiedVendor(res);
            getVendor();
          })
          .catch((error) =>
            console.log(
              "An error occured while verifing your vendors POC information.",
              error
            )
          );
        break;
      case 2:
        fetch(`${API.endpointURL}/api/verify?vendor_id=${vendor.id}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            remittance_address: remittance_address_1 ? true : null,
            phone: vendorPhone ? true : null,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setSubmitted(true);
            setVerifiedVendor(res);
            getVendor();
          })
          .catch((error) =>
            console.log(
              "An error occured while verifing your vendors Remittance information.",
              error
            )
          );
        break;
      case 3:
        fetch(`${API.endpointURL}/api/verify?vendor_id=${vendor.id}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            email: vendorEmail ? true : null,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setSubmitted(true);
            setVerifiedVendor(res);
            getVendor();
          })
          .catch((error) =>
            console.log(
              "An error occured while verifing your vendors Company information.",
              error
            )
          );
        break;

      default:
        fetch(`${API.endpointURL}/api/verify?vendor_id=${vendor.id}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            invoice_name: invoiceName ? true : null,
            remittance_address: remittance_address_1 ? true : null,
            vendor_phone: vendorPhone ? true : null,
            email: vendorEmail ? true : null,
            fax: vendorFax ? true : null,
            payment_terms: paymentTerms ? true : null,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setSubmitted(true);
            setVerifiedVendor(res);
            getVendor();
          })
          .catch((error) =>
            console.log(
              "An error occured while verifing your vendors information.",
              error
            )
          );
    }
  };

  return (
    <>
      <div className="heading-container">
        <h1 className="content-header">GENERAL INFORMATION</h1>
        {submitted ? <div className="submitted-text">Verifying...</div> : ""}
        {loading ? <div className="submitted-text">Submitting...</div> : ""}

        {(editRow1 && accountingRole) ||
        (editRow2 && accountingRole) ||
        (editRow3 && accountingRole) ? (
          <>
            <div className="helper">
              <div className="verified-circle pass enlarged"></div>
              <h6>Verified</h6>
            </div>
            <div className="helper">
              <div className="verified-circle fail enlarged"></div>
              <h6>Unverified</h6>
            </div>
            <div className="helper">
              <div className="verified-circle unmarked enlarged"></div>
              <h6>Unmarked</h6>
            </div>
            {/* <div className="helper verify-all">
              <i
                class="fa fa-check-circle checkbox"
                aria-hidden="true"
                onClick={() => handleVerification()}
              ></i>
              <h6>Verify All</h6>
            </div> */}
          </>
        ) : (
          ""
        )}
      </div>
      <div className="vendor-general-content-container">
        <div className="row">
          <div className="auto">
            <div className="heading">
              <div className="content-title">POC User</div>
              <div className="title-icons">
                {editRow1 & accountingRole ? (
                  <i
                    class="fa fa-check-circle checkbox"
                    aria-hidden="true"
                    onClick={() => handleVerification(1)}
                  ></i>
                ) : (
                  ""
                )}
                {editRow1 ? (
                  <i
                    className="fa-solid fa-upload"
                    onClick={() => {
                      handlePocUserUpdate();
                    }}
                  ></i>
                ) : (
                  ""
                )}
                <i
                  class="fa-regular fa-pen-to-square edit-btn"
                  onClick={() => setEditRow1(!editRow1)}
                ></i>
              </div>
            </div>
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Select from Vendor Users</div>
                {editRow1 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              {generateVendorOptions()}
            </div>
          </div>

          <div className="auto">
            <div className="heading">
              <div className="content-title">Remittance Address</div>
              <div className="title-icons">
                {editRow2 & accountingRole ? (
                  <i
                    class="fa fa-check-circle checkbox"
                    aria-hidden="true"
                    onClick={() => handleVerification(2)}
                  ></i>
                ) : (
                  ""
                )}
                {editRow2 ? (
                  <i
                    className="fa-solid fa-upload"
                    onClick={() => {
                      handleRemittanceInfoUpdate();
                    }}
                  ></i>
                ) : (
                  ""
                )}
                <i
                  class="fa-regular fa-pen-to-square edit-btn"
                  onClick={() => setEditRow2(!editRow2)}
                ></i>
              </div>
            </div>
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Address Line 1</div>
                {editRow2 && accountingRole ? (
                  <div
                    className={`verified-circle ${
                      accountingRole && verifiedVendor.remittance_address
                        ? "pass"
                        : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
              </div>

              <input
                type="text"
                name="remittance-address-1"
                className="field"
                placeholder="Enter Address Line 1..."
                disabled={!editRow2}
                value={remittance_address_1}
                onChange={
                  editRow2
                    ? (e) => setRemittanceAddress_1(e.currentTarget.value)
                    : null
                }
              />
            </div>
          </div>

          <div className="auto">
            <div className="heading">
              <div className="content-title">Company Address</div>
              <div className="title-icons">
                {editRow3 & accountingRole ? (
                  <i
                    class="fa fa-check-circle checkbox"
                    aria-hidden="true"
                    onClick={() => handleVerification(3)}
                  ></i>
                ) : (
                  ""
                )}
                {editRow3 ? (
                  <i
                    className="fa-solid fa-upload"
                    onClick={() => {
                      handleCompanyInfoUpdate();
                    }}
                  ></i>
                ) : (
                  ""
                )}
                <i
                  class="fa-regular fa-pen-to-square edit-btn"
                  onClick={() => setEditRow3(!editRow3)}
                ></i>
              </div>
            </div>
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Address Line 1</div>
                {editRow3 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="remittance-address-2"
                className="field"
                placeholder="Enter Address Line 1..."
                disabled={!editRow3}
                value={company_address_1}
                onChange={
                  editRow3
                    ? (e) => setCompanyAddress_1(e.currentTarget.value)
                    : null
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Vendor Company Name</div>
                {editRow1 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="vendor-company-name"
                className="field"
                placeholder="Enter Company Name..."
                disabled={!editRow1}
                value={companyName}
                onChange={
                  editRow1 ? (e) => setCompanyName(e.currentTarget.value) : null
                }
              />
            </div>
          </div>

          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Address Line 2</div>
                {editRow2 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="company-address-1"
                className="field"
                placeholder="Enter Address Line 2..."
                disabled={!editRow2}
                value={remittance_address_2}
                onChange={
                  editRow2
                    ? (e) => setRemittanceAddress_2(e.currentTarget.value)
                    : null
                }
              />
            </div>
          </div>

          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Address Line 2</div>
                {editRow3 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="remittance-address-2"
                className="field"
                placeholder="Enter Address Line 2..."
                disabled={!editRow3}
                value={company_address_2}
                onChange={
                  editRow3
                    ? (e) => setCompanyAddress_2(e.currentTarget.value)
                    : null
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Vendor Invoice Name</div>
                {editRow1 && accountingRole ? (
                  <div
                    className={`verified-circle ${
                      verifiedVendor.invoice ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="vendor-invoice-name"
                className="field"
                placeholder="Enter Invoice Name..."
                disabled={!editRow1}
                value={invoiceName}
                onChange={
                  editRow1 ? (e) => setInvoiceName(e.currentTarget.value) : null
                }
              />
            </div>
          </div>

          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">City</div>
                {editRow2 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="remittance-city"
                className="field"
                placeholder="Enter City..."
                disabled={!editRow2}
                value={remittanceCity}
                onChange={
                  editRow2
                    ? (e) => setRemittanceCity(e.currentTarget.value)
                    : null
                }
              />
            </div>
          </div>

          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">City</div>
                {editRow3 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="company-city"
                className="field"
                placeholder="Enter City..."
                disabled={!editRow3}
                value={companyCity}
                onChange={
                  editRow3 ? (e) => setCompanyCity(e.currentTarget.value) : null
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Payment Terms</div>
                {editRow1 && accountingRole ? (
                  <div
                    className={`verified-circle ${
                      verifiedVendor.payment_terms ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="payment-terms"
                className="field"
                placeholder="Enter Payment Terms..."
                disabled={!editRow1}
                value={paymentTerms}
                onChange={
                  editRow1
                    ? (e) => setPaymentTerms(e.currentTarget.value)
                    : null
                }
              />
            </div>
          </div>

          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">State</div>
                {editRow2 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="remittance-state"
                className="field"
                placeholder="Enter State..."
                disabled={!editRow2}
                value={remittanceState}
                onChange={
                  editRow2
                    ? (e) => setRemittanceState(e.currentTarget.value)
                    : null
                }
              />
            </div>
          </div>

          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">State</div>
                {editRow3 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="company-city"
                className="field"
                placeholder="Enter State..."
                disabled={!editRow3}
                value={companyState}
                onChange={
                  editRow3
                    ? (e) => setCompanyState(e.currentTarget.value)
                    : null
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">RD Account Manager ID</div>
                {editRow1 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              {generateAccountManagerOptions()}
            </div>
          </div>

          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Zip Code</div>
                {editRow2 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="remittance-zip"
                className="field"
                placeholder="Enter Zip Code..."
                disabled={!editRow2}
                value={remittanceZip}
                onChange={
                  editRow2
                    ? (e) => setRemittanceZip(e.currentTarget.value)
                    : null
                }
              />
            </div>
          </div>

          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Zip Code</div>
                {editRow3 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="company-zip"
                className="field"
                placeholder="Enter Zip Code..."
                disabled={!editRow3}
                value={companyZip}
                onChange={
                  editRow3 ? (e) => setCompanyZip(e.currentTarget.value) : null
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="auto" />

          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Country / Region</div>
                {editRow2 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="country-region"
                className="field"
                placeholder="Enter Country / Region Code..."
                disabled={!editRow2}
                value={remittanceCountry}
                onChange={
                  editRow2
                    ? (e) => setRemittanceCountry(e.currentTarget.value)
                    : null
                }
              />
            </div>
          </div>

          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Country / Region</div>
                {editRow3 && accountingRole ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="company-zip"
                className="field"
                placeholder="Enter Country / Region Code..."
                disabled={!editRow3}
                value={companyCountry}
                onChange={
                  editRow3
                    ? (e) => setCompanyCountry(e.currentTarget.value)
                    : null
                }
              />
            </div>
          </div>
        </div>
        <div className="row header">
          <div>Quick Info</div>
        </div>
        <div className="row">
          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">POC Name</div>
                {editRow1 ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="vendor-name"
                className="field"
                placeholder="Enter Vendor Name..."
                disabled
                value={vendorName}
                // onChange={
                //   edit ? (e) => setVendorName(e.currentTarget.value) : null
                // }
              />
            </div>
          </div>

          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Phone Number</div>
                {editRow2 ? (
                  <div
                    className={`verified-circle ${
                      verifiedVendor.phone ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="vendor-phone-number"
                className="field"
                placeholder="Enter Vendor Phone Number..."
                disabled
                value={vendorPhone}
              />
            </div>
          </div>
          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Email</div>
                {editRow3 ? (
                  <div
                    className={`verified-circle ${
                      verifiedVendor.email ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="vendor-email"
                className="field"
                placeholder="Enter Email..."
                disabled
                value={vendorEmail}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Fax</div>
                {editRow1 ? (
                  <div
                    className={`verified-circle ${
                      verifiedVendor.fax ? "pass" : "fail"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="vendor-website"
                className="field"
                placeholder="Enter Website..."
                disabled
                value={vendorFax}
              />
            </div>
          </div>

          <div className="auto">
            <div className="input-box">
              <div className="heading-container">
                <div className="input-title">Website</div>
                {editRow2 ? (
                  <div className="verified-circle unmarked"></div>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                name="vendor-fax"
                className="field"
                placeholder="Enter Website..."
                disabled
                value={vendorWebsite}
              />
            </div>
          </div>
          { vendor.type === 'customer' && 
            <div 
              className="auto"
              name="spacer" 
              style={{
                  marginBottom: '0.5em',
              }}
              >
                Staffing Customer
                <Switch
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#00A5CF',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#00A5CF',
                    },
                    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                      backgroundColor: '#c7c7c7',
                    },
                  }}
                  checked={isStaffingCustomer}
                />
              </div>
          }
  
          <div className="auto" name="spacer">
            {/*Extra slot to keep columns inline.*/}
          </div>
        </div>
      </div>
    </>
  );
}
