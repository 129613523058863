import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ProgressSpinnerDarkNavy } from 'components/progress/progress-spinner-dark-navy';

const initialErrors = {
  full_name: false,
  phone_number: false,
  email: false,
  address: false,
  city: false,
  state: false,
  zip_code: false,
}

export const SalesConnectionsModalTwo = ({
  connectionType,
  connectionForm,
  setConnectionForm,
  setStep,
  handleCreate,
  loading,
}) => {
  const [errors, setErrors] = useState(initialErrors);
  const { pathname } = useLocation();

  const handleContinue = () => {
    return isInvalid ? setInvalid() : handleCreate('personal');
  };

  const handleBack = () => {
    if (connectionType === 'PERSONAL') {
      return setStep(1);
    }
    return setStep(4)
  };

  const isInvalid = !connectionForm.full_name
    || !connectionForm.email
    || !connectionForm.phone_number
    || !connectionForm.address
    || !connectionForm.city
    || !connectionForm.state
    || !connectionForm.zip_code;

  const setInvalid = () => {
    setErrors({
      full_name: !connectionForm.full_name,
      phone_number: !connectionForm.phone_number,
      email: !connectionForm.email,
      address: !connectionForm.address,
      city: !connectionForm.city,
      state: !connectionForm.state,
      zip_code: !connectionForm.zip_code,
    });
  };

  const handleChange = (e) => {
    setConnectionForm({
      ...connectionForm,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: !e.target.value,
    });
  };

  return (
    <div className="connection__modal-content">
      <p className="connection__modal-title connection__modal-title--italic">
        Fill Out the following information for your
        <span className="connection__modal-title--bold">
          {' PERSONAL '}
        </span>
        connection:
      </p>
      <div className="connection__modal-inputs">
        <div className="connection__modal-fields">
          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: "left" }}>
              Full Name *
            </p>
            <TextField
              value={connectionForm.full_name}
              name="full_name"
              onChange={handleChange}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 0,
              }}
              placeholder="Full Name..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.full_name && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>

          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: "left" }}>
              Title
            </p>
            <TextField
              value={connectionForm.title}
              name="title"
              onChange={handleChange}
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 0,
              }}
              placeholder="Title..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
        </div>

        {pathname.includes('portal') && (
          <div className="connection__modal-fields">
            <div className="connection__modal-field">
              <p className="form-row-label" style={{ textAlign: "left" }}>
                Relationship
              </p>
              <TextField
                value={connectionForm.note_body}
                name="note_body"
                onChange={handleChange}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  border: "1px solid #a5af96",
                  borderRadius: "8px",
                  padding: "6px 10px 5px",
                  marginBottom: 0,
                }}
                placeholder="Relationship..."
                multiline={false}
                rows={1}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
          </div>
        )}

        <div className="connection__modal-fields">
          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: "left" }}>
              Phone number *
            </p>
            <TextField
              value={connectionForm.phone_number}
              name="phone_number"
              onChange={handleChange}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 0,
              }}
              placeholder="Phone..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.phone_number && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>

          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: "left" }}>
              Email *
            </p>
            <TextField
              value={connectionForm.email}
              name="email"
              onChange={handleChange}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 0,
              }}
              placeholder="Email..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.email && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>
        </div>

        <div className="connection__modal-fields">
          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: "left" }}>
              Address Line 1 *
            </p>
            <TextField
              value={connectionForm.address}
              name="address"
              onChange={handleChange}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 0,
              }}
              placeholder="Address Line 1..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.address && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>

          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: "left" }}>
              City *
            </p>
            <TextField
              value={connectionForm.city}
              name="city"
              onChange={handleChange}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 0,
              }}
              placeholder="City..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.city && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>
        </div>

        <div className="connection__modal-fields">

          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: "left" }}>
              State *
            </p>
            <TextField
              value={connectionForm.state}
              name="state"
              onChange={handleChange}
              fullWidth
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 0,
              }}
              placeholder="State..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.state && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>

          <div className="connection__modal-field">
            <p className="form-row-label" style={{ textAlign: "left" }}>
              ZIP Code *
            </p>
            <TextField
              value={connectionForm.zip_code}
              name="zip_code"
              onChange={handleChange}
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #a5af96",
                borderRadius: "8px",
                padding: "6px 10px 5px",
                marginBottom: 0,
              }}
              placeholder="ZIP code..."
              multiline={false}
              rows={1}
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
            />
            {errors.zip_code && (
              <p
                className="sale-form-error"
                style={{
                  top: '19px',
                  right: '1%',
                }}
              >
                * Please enter data...
              </p>
            )}
          </div>
        </div>

      </div>

      <div className="connection__modal-buttons">
        {loading && (
          <div className="connection__modal-spinner">
            <ProgressSpinnerDarkNavy />
          </div>
        )}
        <button
          className="connection__modal-button connection__modal-button--back"
          onClick={handleBack}
        >
          Back
        </button>
        {/*<button*/}
        {/*  className="connection__modal-button"*/}
        {/*  onClick={handleContinue}*/}
        {/*>*/}
        {/*  Continue*/}
        {/*</button>*/}
        <button
          className="connection__modal-button"
          onClick={handleContinue}
          disabled={loading}
        >
          Create
        </button>
      </div>
    </div>
  )
};