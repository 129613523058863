import React, { useState } from 'react';
import { TextField } from "@mui/material";
import API from 'API';

export const VendorCreateGrouping = ({ groupings, getGroupings, close }) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const [title, setTitle] = useState('');
  const [newAccountNumber, setNewAccountNumber] = useState('');
  const [accountNumbers, setAccountNumbers] = useState([]);
  const [addAccountButton, setAddAccountButton] = useState(true);
  const [error, setError] = useState(false);
  const [emptyTitle, setEmptyTitle] = useState(false)
  const regex = /^[0-9\b]*$/;

  const isUsedTitle = groupings.some(item => item.title === title);

  const createGrouping = async (title, numbers) => {
    await fetch(`${API.endpointURL}/api/create_grouping`, {
      method: "POST",
      body: JSON.stringify({
        title: title,
        account_numbers: numbers,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(() => {
        getGroupings();
      })
      .catch((e) => {
        console.log(e, 'Error creating grouping');
      });
  };

  const handleCreate = () => {
    if (title.length > 0 && !isUsedTitle) {
      createGrouping(title, accountNumbers);
      close();
    } else if (title.length === 0) {
      setEmptyTitle(true);
    }
  }

  return (
    <div className="vendor-grouping__modal">
      <div className="vendor-grouping__modal-section">
        <p className="form-row-label" style={{ textAlign: "left" }}>
          Title
        </p>
        <TextField
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            setEmptyTitle(false);
          }}
          fullWidth
          sx={{
            backgroundColor: "#fff",
            border: "1px solid #a5af96",
            borderRadius: "8px",
            padding: "6px 10px 5px",
            marginBottom: 0,
          }}
          placeholder="State..."
          multiline={true}
          rows={1}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
        {isUsedTitle && (
          <p className="vendor-grouping__modal-error">
            * title is already in use, enter new title.
          </p>
        )}
        {emptyTitle && (
          <p className="vendor-grouping__modal-error">
            * please enter title.
          </p>
        )}
      </div>
      <div className="vendor-grouping__modal-section">
        <p className="form-row-label" style={{ textAlign: "left" }}>
          Account Numbers
        </p>
        <div>
          {accountNumbers.map((number) => (
            <div className="vendor-grouping__account">
            <span>
              {number}
            </span>
              <button
                className="vendor-grouping__account--dismiss"
                onClick={() =>
                  setAccountNumbers(accountNumbers.filter((item) => item !== number))
                }
              >
                x
              </button>
            </div>
          ))}
        </div>
      </div>
      {addAccountButton ? (
        <button
          onClick={() => setAddAccountButton(false)}
          className="vendor-grouping__account--create"
        >
          Add account
        </button>
      ) : (
        <div className="vendor-grouping__account--number">
          <TextField
            value={newAccountNumber}
            onChange={(e) => {
              if (regex.test(e.target.value)) {
                setNewAccountNumber(e.target.value);
              }
              setError(false);
            }}
            fullWidth
            sx={{
              backgroundColor: "#fff",
              border: "1px solid #a5af96",
              borderRadius: "8px",
              padding: "6px 10px 5px",
              marginBottom: 0,
            }}
            placeholder="Five-digit number"
            multiline={true}
            rows={1}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />
          {error && (
            <p className="vendor-grouping__account--number--error">
              *please enter a five-digit number
            </p>
          )}
          <button
            onClick={() => {
              if (newAccountNumber.length !== 5) {
                setError(true)
              } else {
                setAccountNumbers([...accountNumbers, newAccountNumber])
                setAddAccountButton(true);
                setNewAccountNumber('');
              }
            }}
            className="vendor-grouping__account--add"
          >
            +
          </button>
          <button
            onClick={() => {
              setAddAccountButton(true);
              setNewAccountNumber('');
            }}
            className="vendor-grouping__account--delete"
          >
            -
          </button>
        </div>
      )}
      <button
        onClick={handleCreate}
        className="vendor-grouping__modal-submit"
      >
        Create grouping
      </button>
    </div>
  );
};