import { setIsSearchedApplicants } from 'store/staffingSelectedOptions/slice';
import { getSearchedApplicants } from 'store/applicants/thunk';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  companyFilterSelector,
  contractFilterIdSelector,
  currentPageSelector,
  currentSortOptionSelector,
  currentSortOrderSelector,
  currentTabSelector,
  psqAlertFilterSelector,
  recruiterFilterIdSelector,
  regionFilterIdSelector,
  searchFilterSelector,
  searchInputSelector,
  selectedGroupsSelector,
  selectedTagsSelector,
  siteFilterIdSelector,
  textAlertFilterSelector,
} from 'store/staffingSelectedOptions/selectors';
import { tagGroupsSelector } from 'store/tags/selectors';

export const useGetSearchedApplicants = () => {
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(currentTabSelector);
  const contractId = useAppSelector(contractFilterIdSelector);
  const divisionId = useAppSelector(regionFilterIdSelector);
  const subDivisionId = useAppSelector(siteFilterIdSelector);
  const company = useAppSelector(companyFilterSelector);
  const textAlert = useAppSelector(textAlertFilterSelector);
  const psqAlert = useAppSelector(psqAlertFilterSelector);
  const currentPage = useAppSelector(currentPageSelector);

  const recruiterFilterId = useAppSelector(recruiterFilterIdSelector);
  const currentSortOption = useAppSelector(currentSortOptionSelector);
  const currentSortOrder = useAppSelector(currentSortOrderSelector);
  const selectedTags = useAppSelector(selectedTagsSelector);
  const selectedGroups = useAppSelector(selectedGroupsSelector);
  const searchInput = useAppSelector(searchInputSelector);
  const searchFilter = useAppSelector(searchFilterSelector);
  const allGroups = useAppSelector(tagGroupsSelector);
  
  const searchApplicants = (value = '') => {
    dispatch(setIsSearchedApplicants(true));
    let sort = `${currentSortOption}.${currentSortOrder}`;
    let alertsFilter = '';
    let tagsFilter = '';
    let groupsFilter = '';
    if (textAlert && psqAlert) {
      alertsFilter = '&text_alert=true&document_alert=true';
    } else if (textAlert && !psqAlert) {
      alertsFilter = '&text_alert=true';
    } else if (!textAlert && psqAlert) {
      alertsFilter = '&document_alert=true';
    }
    if (selectedGroups.length > 0 && value !== 'clearTags') {
      const selected = allGroups.filter(group => selectedGroups.includes(group.title))
      const groupParams = selected.map((tag, index) => {
        return `&tag_group_ids[${index}]=${tag.id}`;
      });
      groupsFilter = groupParams.join('');
    }
    if (selectedTags.length > 0 && value !== 'clearTags'){
      const tagParams = selectedTags.map((tag) => {
        return `"${tag}"`;
      });
      tagsFilter = `&tag_name=[${tagParams}]`;
    }

    dispatch(
      getSearchedApplicants({
        currentTab,
        recruiterId: recruiterFilterId,
        company: company,
        siteId: subDivisionId,
        regionId: divisionId,
        contractId: contractId,
        alertsFilter,
        groupsFilter,
        tagsFilter,
        searchFilter,
        searchInput,
        sort,
        currentPage,
      }));
  };

  return searchApplicants;
}