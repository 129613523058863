import React, { useEffect, useState } from "react";
import API from "API";
import AppBar from "../../components/app-bar/updated-app-bar-component";
import VendorsSidebar from "components/sidebar/vendors-sidebar";
import CreateNewVendor from "components/vendors/create-new-vendor";
import Customers from "components/vendors/customers";
import EmailTemplates from "components/vendors/email-templates";
import Vendors from "components/vendors/vendors";
import Toast from "../../components/toast/toast";
import VendorsAnalyticsDashboard from 'components/vendors/vendors-analytics-dashboard';
import { VendorGroupings } from 'components/vendors/vendor-groupings';
import { VendorBudgets } from 'components/vendors/vendor-budgets';
import {ProgressSpinnerBlue} from "../../components/progress/progress-spinner-blue";

export default function ManageVendors() {
  const [currentView, setCurrentView] = useState("Vendors");
  const [templates, setTemplates] = useState([]);
  const [openErrorToast, setOpenErrorToast] = useState(false);
  const [openCreateToast, setOpenCreateToast] = useState(false);
  const [error, setError] = useState(null);
  const [loadingEmailTemplates, setLoadingEmailTemplates] = useState(false);
  const [loadingPartners, setLoadingPartners] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [vendors, setVendors] = useState([]);

  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getPartners()
    getTemplates();
  }, []);

  const getTemplates = async () => {
    setLoadingEmailTemplates(true);
    try {
      const response = await fetch(
        `${API.endpointURL}/api/email_templates/?template_category=vendor`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.error || "Failed to sync email templates"
        );
      }

      const json = await response.json();
      setTemplates(json.data);
      setLoadingEmailTemplates(false);

    } catch (e) {
      setLoadingEmailTemplates(false);
    }
  };

  const getPartners = async () => {
    setLoadingPartners(true);
    try {
      const response = await fetch(
        `${API.endpointURL}/api/get_vendors`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(
          errorResponse.error || "Failed to sync vendors with Business Central"
        );
      }

      const json = await response.json();
      setVendors(json.filter((vendor) => vendor.type === "vendor"));
      setCustomers(json.filter((vendor) => vendor.type === "customer"));

      setLoadingPartners(false);
    } catch (error) {
      setError(error.message);
      setLoadingPartners(false);
    }
  };

  return (
    <>
      <AppBar
        colorPalette="blue"
        menu={true}
        title="Accounting"
      />
      <div className="accounting-page">
        <VendorsSidebar
          currentView={currentView}
          setCurrentView={setCurrentView}
        />
        <div className="page-content">
          {currentView === "Vendors" &&
            <Vendors
              vendors={vendors}
              loading={loadingPartners}
              templates={templates}
            />}
          {currentView === "Customers" &&
            <Customers
              customers={customers}
              loading={loadingPartners}
              templates={templates}
            />}
          {currentView === "Create New Vendor" && (
            <CreateNewVendor
              currentView={currentView}
              setCurrentView={setCurrentView}
              setOpenErrorToast={setOpenErrorToast}
              setOpenCreateToast={setOpenCreateToast}
              setError={setError}
              getPartners={getPartners}
            />
          )}
          {currentView === "Set Email Templates" &&
            <>
              {loadingEmailTemplates ? (
                <div className="spinner-fullScreen">
                  <ProgressSpinnerBlue />
                </div>
              ) : (
                <EmailTemplates templates={templates} getTemplates={getTemplates} />
              )}
            </>
          }
          {currentView === "Groupings" && (
            <VendorGroupings />
          )}
          {currentView === "Budgets" && (
            <VendorBudgets />
          )}
          {currentView === 'Analytics' && (
            <VendorsAnalyticsDashboard />
          )}
        </div>
      </div>
      {openCreateToast && (
        <Toast
          open={openCreateToast}
          onClose={() => setOpenCreateToast(false)}
          message="Vendor Added to Responsive Deployment"
        />
      )}
      {openErrorToast && (
        <Toast
          open={openErrorToast}
          onClose={() => setOpenErrorToast(false)}
          message={`ERROR: ${error}`}
        />
      )}
    </>
  );
}
