import { axiosService } from 'services/axiosServices';

export const tags = {
  getAllTags: async () => {
    const res = await axiosService.get('/api/tags');
    return res.data.data;
  },
  getTypes: async () => {
    const res = await axiosService.get('/api/tag-types');
    return res.data.data;
  },
  createType: async (body) => {
    const res = await axiosService.post('/api/tag-types', body);
    return res.data;
  },
  updateType: async (id, body) => {
    const res = await axiosService.put(`/api/tag-types/${id}`, body);
    return res.data;
  },
  deleteType: async (id) => {
    const res = await axiosService.delete(`/api/tag-types/${id}`);
    return res.data;
  },
  getGroups: async () => {
    const res = await axiosService.get('/api/tag-groups');
    return res.data.data;
  },
  createGroup: async (body) => {
    const res = await axiosService.post('/api/tag-groups', body);
    return res.data;
  },
  updateGroup: async (id, body) => {
    const res = await axiosService.patch(`/api/tag-groups/${id}`, body);
    return res.data;
  },
  deleteGroup: async (id) => {
    const res = await axiosService.delete(`/api/tag-groups/${id}`);
    return res.data;
  },
  getActiveApplicantTags: async (applicantId) => {
    const res = await axiosService.get(`/api/applicants/applicant_tags/${applicantId}`);
    return res.data.data;
  },
  getAvailableApplicantTags: async (applicantId) => {
    const res = await axiosService.get(`/api/applicants/available_tags/${applicantId}`);
    return res.data.data;
  },
  addActiveTagToApplicant: async (applicantId, body) => {
    const res = await axiosService.put(`/api/applicants/${applicantId}`, body);
    return res.data.data;
  },
  removeActiveTagFromApplicant: async (body) => {
    const res = await axiosService.post(`/api/applicants/remove_tags`, body);
    return res.data.data;
  },
  createTag: async (body) => {
    const res = await axiosService.post(`/api/tags`, body);
    return res.data.data;
  },
  deleteTag: async (tagId) => {
    const res = await axiosService.delete(`/api/tags/${tagId}`);
    return res.data;
  },
  editTag: async (tagId, body) => {
    const res = await axiosService.put(`/api/tags/${tagId}`, body);
    return res.data.data;
  },
  getTag: async (tagId) => {
    const res = await axiosService.get(`/api/tags/${tagId}`);
    return res.data.data;
  },
  filterTags: async (groupId = '', typeId = '') => {
    let url = '';
    
    if (groupId) {
      url = `tag_group_id=${groupId}`;
    }
    
    if (typeId) {
      if (url) {
        url += '&';
      }
      url += `tag_type_id=${typeId}`;
    }
    const res = await axiosService.get(`/api/filter/tags?${url}`);
    return res.data.data;
  },
}